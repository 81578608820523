import { NgModule } from "@angular/core";
import { CommonModule } from "common";
import { DocumentRoutingModule } from "./document-routing.module";
import { SharedModule } from "../shared/shared.module";
import { DocumentListFilterDialogComponent } from "./document-list-filter-dialog/document-list-filter-dialog.component";
import { UppyAngularDashboardModule } from "@uppy/angular";
import { ApplicationDocumentUploadComponent } from "./document-upload/application-document-upload.component";
import { AccountDocumentListComponent } from "./document-list/account-document-list.component";
import { ApplicationDocumentListComponent } from "./document-list/application-document-list.component";

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    DocumentRoutingModule,
    UppyAngularDashboardModule,
  ],
  declarations: [
    AccountDocumentListComponent,
    ApplicationDocumentListComponent,
    DocumentListFilterDialogComponent,
    ApplicationDocumentUploadComponent,
  ],
})
export class DocumentModule {}
