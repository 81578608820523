import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import {
  AppBarAction,
  AppBarActionsService,
  AppBarTitleService, AppPageService,
  OfferBundleCheckoutRequirementService
} from "common";
import { OfferBundleService } from "projects/application/src/app/shared/offer-bundle.service";
import { BehaviorSubject, Observable, of, zip } from "rxjs";
import { map } from "rxjs/operators";
import { OfferDocumentsComponentData, OfferDocumentsComponentParams } from "../../offer.model";
import { ProgressBarDialogComponent } from "./components/progress-bar-dialog/progress-bar-dialog.component";
import { ProgressBarDialogComponentConfig, ProgressBarDialogComponentResultEnum } from "./components/progress-bar-dialog/progress-bar.model";
import { CheckoutRequirementFileUploadModel } from "./model/offer-documents.model";
import { OfferDocumentsService } from "./offer-documents.service";

@UntilDestroy()
@Component({
  selector: "ifp-offer-documents",
  templateUrl: "./offer-documents.component.html",
  styleUrls: ["./offer-documents.component.scss"],
})
export class OfferDocumentsComponent implements OnInit {
  data: OfferDocumentsComponentData;
  params: OfferDocumentsComponentParams;
  checkoutRequirementsFilesToUpload: CheckoutRequirementFileUploadModel[];
  private isSomethingToSend: BehaviorSubject<boolean>;
  isSomethingToSend$: Observable<boolean>;
  monthlyRate: number;
  term: string;

  constructor(
    private route: ActivatedRoute,
    private appBarTitleService: AppBarTitleService,
    private appBarActionsService: AppBarActionsService,
    private dialog: MatDialog,
    private checkoutRequirementService: OfferBundleCheckoutRequirementService,
    private offerBundleService: OfferBundleService,
    private appPageService: AppPageService,
    private service: OfferDocumentsService
  ) {
    this.isSomethingToSend = new BehaviorSubject<boolean>(false);
    this.isSomethingToSend$ = this.isSomethingToSend.asObservable();
  }

  ngOnInit() {
    this.route.data
      .pipe(untilDestroyed(this))
      .subscribe(this.initData.bind(this));

    this.route.queryParams
      .pipe(untilDestroyed(this))
      .subscribe(this.initParams.bind(this));

    this.isSomethingToSend$
      .pipe(untilDestroyed(this))
      .subscribe(this.actionUpdate.bind(this));

    this.appBarActionsService.invoking
      .pipe(untilDestroyed(this))
      .subscribe(this.actionDispatch.bind(this));
  }

  private initParams(params: OfferDocumentsComponentParams) {
    this.params = params;
  }

  private initData(data: OfferDocumentsComponentData) {
    this.data = data;
    this.appBarTitleService.title = "Upload documents";
    this.actionUpdate();
    this.monthlyRate = this.service.getMonthlyRate(this.data);
    this.term = this.service.getTerm(this.data)
  }

  private actionUpdate() {
    const shouldDisable = !this.isSomethingToSend.getValue();

    this.appBarActionsService.actions = [
      {
        id: "save",
        label: "Save",
        buttonType: "button",
        disabled: shouldDisable,
      },
      {
        id: "submit",
        label: "Submit",
        buttonType: "submit",
        buttonAppearance: "flat",
        buttonColor: "primary",
        disabled: shouldDisable,
      },
    ];
  }

  private actionDispatch(action: AppBarAction) {
    const actionHandler: (action: AppBarAction) => void =
      this[action.id].bind(this);
    actionHandler(action);
  }

  save() {
    this.performSave().pipe(untilDestroyed(this)).subscribe();
  }

  private performSave(): Observable<boolean> {
    if (!this.checkoutRequirementsFilesToUpload) {
      return of(false);
    }

    const progressBarDialogComponentConfig = this.getProgressBarDialogComponentConfig();
    return ProgressBarDialogComponent.show(this.dialog, progressBarDialogComponentConfig).pipe(
      map((result: ProgressBarDialogComponentResultEnum) => {
        this.refreshComponentData();
        return result === ProgressBarDialogComponentResultEnum.Completed;
      })
    );
  }

  private getProgressBarDialogComponentConfig(): ProgressBarDialogComponentConfig {
    const progressBarDialogComponentConfig: ProgressBarDialogComponentConfig = { filesToUpload: [] };
    this.checkoutRequirementsFilesToUpload.forEach((checkoutFilesToUpload) => {
      progressBarDialogComponentConfig.filesToUpload.push(...checkoutFilesToUpload.filesToUpload);
    });
    return progressBarDialogComponentConfig;
  }

  private refreshComponentData() {
    const applicationid = this.data.application.id;
    const offerBundleId = this.data.offerBundle.id;

    zip(
      this.checkoutRequirementService.getList(offerBundleId),
      this.offerBundleService.getOfferWithCheckoutRequirements(
        applicationid,
        offerBundleId
      )
    ).subscribe((resultsArray) => {
      this.data.checkoutRequirements = resultsArray[0];
      this.data.offerBundle = resultsArray[1];
      this.checkoutRequirementsFilesToUpload.splice(
        0,
        this.checkoutRequirementsFilesToUpload.length
      );
      this.isSomethingToSend.next(false);
    });
  }

  submit() {
    this.performSave()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x) {
          this.appPageService.back();
        }
      });
  }

  onFilesToUploadChanged(filesToUpload: CheckoutRequirementFileUploadModel[]) {
    this.checkoutRequirementsFilesToUpload = filesToUpload;
    this.isSomethingToSend.next(
      this.checkoutRequirementsFilesToUpload &&
        this.checkoutRequirementsFilesToUpload.length > 0
    );
  }
}
