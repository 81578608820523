import { Injectable } from "@angular/core";
import { HttpClient, HttpContext, HttpHandler, HttpHeaders, HttpParams, HttpParamsOptions, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class RynoHttpClient extends HttpClient {
    constructor(handler: HttpHandler) {
        super(handler);
    }
    
    request(first: string|HttpRequest<any>, url?: string, options: {
        body?: any,
        headers?: HttpHeaders|{[header: string]: string | string[]},
        context?: HttpContext,
        observe?: 'body'|'events'|'response',
        params?: HttpParams|
            {[param: string]: string | number | boolean | ReadonlyArray<string|number|boolean>},
        reportProgress?: boolean,
        responseType?: 'arraybuffer'|'blob'|'json'|'text',
        withCredentials?: boolean,
    } = {}): Observable<any> {
        if(first instanceof HttpRequest<any>) {
            // AK: Do not modify params here, they are already inside HttpParams class
            return super.request(first as HttpRequest<any>);
        }
        else {
            if(!!options.params) {
                if(!(options.params instanceof HttpParams)) {
                    const values: {[param: string]: string|number|boolean|ReadonlyArray<string|number|boolean>} = {};
                    Object
                        .entries(options.params)
                        .forEach(([k, v]) => values[k] = (Array.isArray(v) ? v.map(this.valueToString) : [this.valueToString(v)]));

                    options.params = new HttpParams().appendAll(values);
                }
            }

            return super.request(first as string, url, options);
        }
    }

    private valueToString(value: any): string {
        return value instanceof Date ? value.toISOString() : `${value}`;
    }
}