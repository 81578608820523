import { Injectable } from "@angular/core";
import {
  ButtonColor,
  CloseMfaData,
  DialogActionComponent,
  DialogActionData,
  HiddenPhonePipe,
  MessageService,
  MfaActionsService,
  MfaErrorCodeType,
  MfaService,
  VerifyMfaCodeData,
} from "common";
import {
  SingInErrorResponse,
  SingInResponse,
} from "../../user/user-sign-in/models/sign-in.models";
import { ConfirmNewPhoneNumberRequest } from "../../customer/domain/models/customer.model";
import { MatDialog } from "@angular/material/dialog";
import { CustomerFacade } from "../../customer/domain/+state/customer.facade";

@Injectable()
export class MfaChangePhoneNumberService {
  constructor(
    private mfaActions: MfaActionsService,
    private customerFacade: CustomerFacade,
    private mfaService: MfaService,
    private messageService: MessageService,
    private hiddenPhonePipe: HiddenPhonePipe,
    private dialog: MatDialog
  ) {}

  verify(data: VerifyMfaCodeData) {
    const request: ConfirmNewPhoneNumberRequest = {
      customerId: data.mfaData.contextData.customerId,
      oldPhoneNumber: data.mfaData.contextData.oldPhoneNumber,
      mfaToken: data.mfaData.mfaToken,
      mfaCode: data.mfaCode,
    };

    this.customerFacade
      .confirmMobilePhoneNumberChange(request)
      .subscribe((data: SingInResponse & SingInErrorResponse) => {
        this.handleMfaLoginResponse(data);
      });
  }

  close(data: CloseMfaData) {
    const dialogData: DialogActionData<boolean> = {
      title: "Phone number not verified",
      body: `If you choose not to verify your new phone number at this time,
      we will continue to use your old phone number
      ${this.hiddenPhonePipe.transform(data.contextData.oldPhoneNumber)} to
      verify your account. To verify your new phone number press CONTINUE below.`,
      buttons: [
        {
          label: "GO TO DASHBOARD",
          buttonColor: ButtonColor.Default,
          action: true,
        },
        { label: "CONTINUE", buttonColor: ButtonColor.Primary, action: false },
      ],
    };
    DialogActionComponent.show(this.dialog, dialogData).subscribe((result) => {
      if (result) {
        this.mfaService.clearMfaData();
        this.customerFacade.redirectToDashboard();
      }
    });
  }

  private handleMfaLoginResponse(data: SingInResponse & SingInErrorResponse) {
    if (!data?.hasError) {
      this.customerFacade.redirectToDashboard();
      this.messageService.success(
        "Your new number has been successfully verified. We will use it to verify your account"
      );
      this.mfaService.handleMfaSuccess();
      return;
    }

    if (data?.errorCode === MfaErrorCodeType.InvalidMfaCode) {
      this.mfaActions.invalidCode();
      return;
    }

    this.mfaService.handleMfaError();
  }
}
