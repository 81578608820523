import { Injectable } from "@angular/core";
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { BankAccountService } from "./bank-account.service";
import { map, switchMap, take } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { CustomerCheckoutService } from "../customer/customer-checkout/customer-checkout.service";
import { EntityData } from "../../../../common/src/lib/entity/entity.model";
import { CustomerCheckoutState } from "../customer/customer-checkout/customer-checkout.model";
import { EntityFacade } from "../entity/domain/+state/entity.facade";
import { RouteHelper, parseNumber } from "common";

@Injectable({
  providedIn: "root",
})
export class BankActionRequiredGuard implements CanActivate {
  constructor(
    private router: Router,
    private bankAccountService: BankAccountService,
    private entityFacade: EntityFacade,
    private customerCheckoutService: CustomerCheckoutService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const companyId = parseNumber(RouteHelper.getQueryParamValueByName(route, "companyId"));
    return this.customerCheckoutService.getState().pipe(
      map((kbaState: CustomerCheckoutState) => kbaState),
      switchMap((kbaState) =>
        !kbaState.verifyKba
          ? this.entityFacade.getEntity$(companyId).pipe(
              take(1),
              map((entity: EntityData) => entity)
            )
          : of(null)
      ),
      switchMap((entity) =>
        entity?.id
          ? this.bankAccountService.getIsBankActionRequired(entity.id)
          : of(null)
      ),
      map((isBankActionRequired) => this.validate(isBankActionRequired))
    );
  }

  validate(isBankActionRequired): boolean {
    if (
      isBankActionRequired &&
      !JSON.parse(sessionStorage.getItem("bankActionRequiredViewed"))
    ) {
      this.router.navigate(["bank-account-action-required"], {
        replaceUrl: true,
      });
      return false;
    } else return true;
  }
}
