<section>
  <p>
    To keep your account safe, we want to make sure it's really you. A text message with a verification code was sent to {{data.phoneNumber | hiddenPhone}}.
  </p>
  <p *ngIf="data.phoneStatus === MfaPhoneStatus.Unverified && data.config.canChangePhoneNumberIfUnverified">
    Not receiving the code?
    <a class="update-phone-link"
       (click)="onClickUpdatePhoneNumber()">Update phone number</a>
  </p>
  <form [formGroup]="form">
    <div class="code">
      <mat-form-field appearance="outline"
                      class="code__field">
        <mat-label>Enter verification code</mat-label>
        <input matInput
               formControlName="code"
               type="text"
               placeholder="Enter verification code"
               [mask]="codeMask"
               inputmode="numeric" />
        <mat-error *ngIf="form.get('code').errors | hasControlError:['mfa_code_invalid']:ConditionOperator.Only">
          Wrong code. Try again.
        </mat-error>
        <mat-error *ngIf="form.get('code').errors | hasControlError:['required', 'mask']">
          Verification code must contain {{codeMask?.length}} digits.
        </mat-error>
      </mat-form-field>
    </div>
    <div class="trust-device"
         *ngIf="data.phoneStatus === MfaPhoneStatus.Verified && data.config.isTrustingDeviceAllowed">
      <mat-checkbox color="primary"
                    formControlName="trustDevice">
        <span class="trust-device__label">
          Trust this device. Do not check box if on a public device.
        </span>
      </mat-checkbox>
    </div>
  </form>
  <footer class="footer">
    <div class="actions">
      <span class="actions__text"
            [ngClass]="{'actions__text--visible': sendingNewCodeBlocked}">
        Send new code in
        <countdown #countdown
                   [config]="countdownConfig"
                   (event)="onCountdownEvent($event)">
        </countdown>
      </span>
      <div>
        <button *ngIf="data.config.isBackButtonVisible"
                mat-button>BACK</button>
        <button mat-button
                [disabled]="sendingNewCodeBlocked"
                (click)="onClickSendNewCode(data.mfaToken)">SEND NEW CODE</button>
        <button mat-flat-button
                color="primary"
                class="actions__verify"
                (click)="onClickVerify()">VERIFY</button>
      </div>
    </div>
    <p *ngIf="data.phoneStatus === MfaPhoneStatus.Verified || (data.phoneStatus === MfaPhoneStatus.Unverified && !data.config.canChangePhoneNumberIfUnverified)"
       class="info">
      Not receiving the code? Contact your Business Advisor to update the phone number.
    </p>
  </footer>
</section>
