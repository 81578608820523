import { Injectable } from "@angular/core";
import { CustomerRemote } from "../../infrastructure/customer.remote";
import {
  ConfirmNewPhoneNumberRequest,
  ConfirmNewPhoneNumberResponse,
} from "../models/customer.model";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import {
  AppPageService,
  InitMfaData,
  MessageService,
  MfaApiResponse,
  MfaContextType,
  MfaErrorCodeType,
  MfaErrorType,
  MfaService,
} from "common";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class CustomerActionsService {
  constructor(
    private readonly remote: CustomerRemote,
    private readonly messageService: MessageService,
    private readonly mfaService: MfaService,
    private readonly router: Router,
    private readonly appPageService: AppPageService
  ) {}

  redirectToDashboard() {
    if(!this.appPageService.backToIfPossible(true))
    {
      // TODO[ids] refactor needed (temp solution with hard redirect to dashboard)
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = "reload";
      this.router.navigate(["/dashboard"]);
    }
  }

  confirmMobilePhoneNumberChange(
    request: ConfirmNewPhoneNumberRequest
  ): Observable<any> {
    return this.remote
      .confirmMobilePhoneNumberChange(request)
      .pipe(
        catchError((error) =>
          this.handleConfirmNewPhoneNumberError(request, error)
        )
      );
  }

  private handleConfirmNewPhoneNumberError(
    request: ConfirmNewPhoneNumberRequest,
    response: any
  ): Observable<ConfirmNewPhoneNumberResponse> {
    if (response.status === 403 && this.hasMfaError(response?.error?.error)) {
      return this.handleMfaErrors(request, response?.error);
    }

    this.messageService.error(
      "Error occurred while trying to verify new phone number"
    );
    return of({ hasError: true });
  }

  private hasMfaError(errorType: any) {
    return Object.values(MfaErrorType).includes(errorType);
  }

  private handleMfaErrors(
    request: ConfirmNewPhoneNumberRequest,
    mfaResponse: MfaApiResponse
  ) {
    switch (mfaResponse.error) {
      case MfaErrorType.MfaRequired:
        return this.handleMfaRequiredError(request, mfaResponse);
      case MfaErrorType.MfaCodeError:
        return this.handleMfaCodeError(mfaResponse);
      default:
        console.error(`Mfa error: ${mfaResponse.error}`);
        return of({ hasError: true });
    }
  }

  private handleMfaRequiredError(
    request: ConfirmNewPhoneNumberRequest,
    mfaResponse: MfaApiResponse
  ) {
    const data: InitMfaData = {
      mfaContextType: MfaContextType.ChangePhoneNumber,
      mfaResponse,
      contextData: {
        customerId: request.customerId,
        oldPhoneNumber: request.oldPhoneNumber,
      },
    };
    this.mfaService.initMfa(data);
    return of({ hasError: true });
  }

  private handleMfaCodeError(mfaResponse: MfaApiResponse) {
    if (
      mfaResponse?.send_code_status?.error_result?.error ===
      MfaErrorCodeType.InvalidMfaCode
    ) {
      return of({ hasError: true, errorCode: MfaErrorCodeType.InvalidMfaCode });
    }
  }
}
