import { Component, Input, Output, EventEmitter } from "@angular/core";
import { LinkingStatus } from "projects/common/src/lib/bank/bank.model";

@Component({
  selector: "ifc-link-your-bank",
  templateUrl: "./link-your-bank.component.html",
  styleUrls: ["./link-your-bank.component.scss"],
})
export class LinkYourBankComponent {
  @Input() bankLinkStatus: LinkingStatus;
  @Output() linkBank: EventEmitter<void> = new EventEmitter();

  get BankLinkStatus() {
    return LinkingStatus;
  }

  onClickLinkBank(): void {
    this.linkBank.emit();
  }
}
