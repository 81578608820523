import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppPageComponent, AppBarComponent, SyncGuardHelper } from 'common';
import { DashboardContentComponent } from './dashboard-content/dashboard-content.component';
import { UserMenuComponent } from '../shared/user-menu/user-menu.component';
import { LoansResolver } from '../loan/infrastructure/loan.resolver';
import { EntitySelectComponent } from '../shared/entity-select/entity-select.component';
import { EntitiesResolver, EntityQueryResolver } from '../entity/infrastructure/entity.resolver';
import { OAuthGuard } from '../../guards/oauth.guard';
import { BankActionRequiredGuard } from '../bank-account/bank-account.guard';
import { InsuranceReminderQuoteGuard } from '../insurance/insurance.guard';
import { ReferralCodeGuard } from '../referral-code/referral-code.guard';
import { RenewalEligibleReminderGuard } from '../renewal-eligible/reminder/renewal-eligible-reminder.guard';
import { PhoneNumberConfirmationReminderComponent } from './phone-number-confirmation-reminder/phone-number-confirmation-reminder.component';
import { CustomerCurrentResolver } from '../customer/infrastructure/customer.resolver';
import { CustomerCheckoutGuard } from '../agreement/customer-checkout.guard';
import { ApplicationAgreementSignedGuard } from '../application-agreement/application-agreement-signed.guard';
import { LocAgreementSignedGuard } from '../loc-agreement/loc-agreement.guard';
import { RedirectDashboardGuard } from './guards/redirect-dashboard.guard';

const routes: Routes = [
  {
    path: 'dashboard',
    component: AppPageComponent,
    canActivate: [OAuthGuard],
    data: {
      appBar: {
        logoSize: 'logo-s',
        logoIcon: true
      },
      appPage: {
        contentClass: 'simple',
      }
    },
    resolve: {
      entities: EntitiesResolver,
      entity: EntityQueryResolver,
      loans: LoansResolver,
      customer: CustomerCurrentResolver,
    },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    children: [
      {
        path: '',
        outlet: 'app-bar',
        component: AppBarComponent,
        children: [
          {
            path: '',
            outlet: 'selector',
            component: EntitySelectComponent,
            runGuardsAndResolvers: 'paramsOrQueryParamsChange',
          },
          {
            path: '',
            outlet: 'right',
            component: UserMenuComponent
          },
          {
            path: '',
            outlet: 'reminder',
            component: PhoneNumberConfirmationReminderComponent,
          }
        ]
      },
      {
        path: '',
        component: DashboardContentComponent,
        canActivate: [SyncGuardHelper],
        data: {
          syncGuards: [
            RedirectDashboardGuard,
            CustomerCheckoutGuard,
            ApplicationAgreementSignedGuard,
            LocAgreementSignedGuard,
            BankActionRequiredGuard,
            InsuranceReminderQuoteGuard,
            ReferralCodeGuard,
            RenewalEligibleReminderGuard
          ]
        }
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
