<div class="offer-summary-widget">
  <h3>
    {{data.productName}}
  </h3>
  <mat-card class="offer-summary mat-elevation-z2"
            [ngClass]="{
    'loc-card':  data.offerType === offerType.LOC,
    'term-card':  data.offerType === offerType.Term,
    'law-card': data.offerType === offerType.Law
    }"
            (click)="onClickDisplayAllOffers(data.offerType)">
    <mat-card-content fxLayout="row"
                      fxLayoutAlign="space-between center"
                      fxLayoutGap="12px">
      <div style="position: relative; z-index:999;"
          fxLayoutAlign="center center"
          fxFlex="7">
        <mat-icon *ngIf="data.offerType === offerType.LOC"
                  class="mat-white">data_saver_off</mat-icon>
        <mat-icon *ngIf="data.offerType === offerType.Term"
                  class="mat-white">assessment</mat-icon>
        <mat-icon *ngIf="data.offerType === offerType.Law"
                  class="mat-white">work</mat-icon>
      </div>
      <div fxLayout="column"
          fxLayoutAlign="space-between left"
          fxFlex="80">
        <span class="offer-value">{{data.amount | currency:'$'}}</span>
        <span class="offer-title">{{data.amountTitle}}</span>
        <span class="offer-value">{{data.repaymentTerm}} months</span>
        <span class="offer-title">Repayment term</span>
        <div>
          <button mat-button
                  (click)="onClickDisplayAllOffers(data.offerType)">SEE ALL OFFERS</button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
