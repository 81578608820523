import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTabGroup } from "@angular/material/tabs";
import { ActivatedRoute } from "@angular/router";
import { ApplicationType, LoanStatus, ProductCode } from "common";
import { AccountPaymentTableComponent } from "../../account-payment/account-payment-table/account-payment-table.component";
import { AccountTransactionTableComponent } from "../account-transaction-table/account-transaction-table.component";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AccountTransactionTableCardFacade } from "./account-transaction-table-card.facade";
import { AccountTransactionTableCardComponentData } from "./account-transaction-table-card.model";
import { ApplicationRemote } from "../../../application/infrastructure/application.remote";
import { of, zip } from "rxjs";
import { mergeMap } from "rxjs/operators";

@UntilDestroy()
@Component({
  selector: "ifp-account-transaction-table-card",
  templateUrl: "./account-transaction-table-card.component.html",
  styleUrls: ["./account-transaction-table-card.component.scss"],
})
export class AccountTransactionTableCardComponent implements OnInit {
  @ViewChild("tabGroup", { static: true })
  tabGroup: MatTabGroup;

  @ViewChild("transactionTable")
  transactionTable: AccountTransactionTableComponent;

  @ViewChild("paymentTable")
  paymentTable: AccountPaymentTableComponent;

  data: AccountTransactionTableCardComponentData;
  loanStatus = LoanStatus;
  isInsuranceApplicable: boolean;

  constructor(private route: ActivatedRoute, private applicationRemote: ApplicationRemote, private accountTransactionTableCardFacade: AccountTransactionTableCardFacade) {}

  ngOnInit() {
    this.route.data
      .pipe(
        untilDestroyed(this),
        mergeMap(data => zip(of(data), this.applicationRemote.queryByEntity(data?.loan?.entityId)))
      )
      .subscribe(([componentData, applications] ) => {
        this.data = componentData as AccountTransactionTableCardComponentData;
        const currentApplication = applications?.values?.find(a => a.loanId === this.data.loan.id && a.applicationType == ApplicationType.LCF);
        this.isInsuranceApplicable = !(currentApplication && (currentApplication as any)?.hasPolicy) && this.data.loan.productCode == ProductCode.Law;
      });
  }

  download() {
    switch (this.tabGroup.selectedIndex) {
      case 0:
        this.transactionTable.downloadCsv();
        break;
      case 1:
        this.paymentTable.downloadCsv();
        break;
    }
  }

  insurance() {
    this.accountTransactionTableCardFacade.gotoInsurance();
  }
}
