import { TimeInBusinessPeriod } from "../account/account.model";
import { Address } from "../address/address.model";
import {
  ApplicationBaseData,
  FeeType,
  PaymentFrequency,
} from "../application/application.model";
import { BankAccount, LinkingStatus } from "../bank/bank.model";
import { parseNumber } from "../helpers/parse.helper";
import { LoanRefData } from "../loan/loan.model";
import { QueryParams, QueryResult } from "../query/query.data";

export enum EntityType {
  SoleProprietorship = "sole-proprietorship",
  LimitedLiabilityCompany = "limited-liability-company",
  Corporation = "corporation",
  LimitedPartnership = "limited-partnership",
  GeneralPartnership = "general-partnership",
  NotForProfit = "not-for-profit",
  Public = "public",
  LegalPartnership = "legal-partnership",
  Other = "other",
}

export const EntityTypeLabel = new Map<string, string>([
  [EntityType.SoleProprietorship, "Sole proprietorship"],
  [EntityType.LimitedLiabilityCompany, "Limited liability company"],
  [EntityType.Corporation, "Corporation"],
  [EntityType.LimitedPartnership, "Limited partnership"],
  [EntityType.GeneralPartnership, "General partnership"],
  [EntityType.NotForProfit, "Not for profit"],  
  [EntityType.Public, "Public"],
  [EntityType.LegalPartnership, "Legal Partnership"],
  [EntityType.Other, "Other"],
]);

export enum EntityStatus {
  Active = "active",
  Terminated = "terminated",
  Submitted = "submitted",
  Processing = "processing",
  Approved = "approved",
  Closed = "closed",
  ActiveNurture = "active-nurture",
  ActiveSalesAdmin = "active-sales-admin",
  ActiveBusinessAdvisor = "active-business-advisor",
  InReview = "in-review",
  MissingInfo = "missing-info",
  Probation = "probation",
  Modification = "modification",
  Workout = "workout",
  Bankruptcy = "bankruptcy",
  CaseSettled = "case-settled",
  Litigation = "litigation",
  Delinquent = "delinquent",
  PreferredPartner = "preferred-partner",
  Reassign = "reassign",
}

export interface EmailData {
  email?: string;
  type?: string;
}

export interface CashFlowData {
  avgMonthlyRevenue?: number;
  annualizedRevenue?: number;
  avgMonthlyBankBalance?: number;
  outstandingLoans?: number;
}

export interface CreditData {
  paynet?: number;
  equifax?: number;
}

export interface EntityMSATermsData {
  offerAmount?: number;
  interestRate?: number;
  drawTerm?: number;
  repaymentTerm?: number;
  noPaymentTerms?: number;
  interestOnlyPaymentTerms?: number;
  paymentFrequency?: PaymentFrequency;
  drawDownFee?: number;
  drawDownFeeType?: FeeType;
  maintenanceFee?: number;
  maintenanceFeeType?: FeeType;
  maintenanceFeeFrequency?: PaymentFrequency;
  originationFee?: number;
  originationFeeType?: FeeType;
  maintenanceAvailableBalance?: number;
  cutoffAvailableBalance?: number;
  currentAvailableBalance?: number;
}

export interface BankInfo {
  id?: number;
  bankName?: string;
  routingNumber?: string;
  accountNumber?: string;
  type?: any;
  accountType?: any;
  createdOn?: Date;
  createdBy?: any;
  updatedOn?: Date;
  updatedBy?: any;
}

export interface EntityOtherInfoUpdateDto {
  establishedDate: Date;
}

export interface EntityBaseData {
  id?: number;
  uniqueId?: string;
  advisorId?: number;
  brokerId?: number;
  type?: EntityType;
  status?: EntityStatus;
  entityNumber?: string;
  name?: string;
  dba?: string;
  ein?: string;
  webSite?: string;
  phone?: string;
  fax?: string;
  address?: Address;
  establishedDate?: Date;
  timeInBusiness?: TimeInBusinessPeriod;
  industry?: string;
  naics?: string;
  stateOfIncorporation?: string;
  loans?: LoanRefData[];
  applications?: ApplicationBaseData[];
  parentCompany?: string;
  contacts?: string;
  emails?: EmailData[];
  cashFlow?: CashFlowData;
  credit?: CreditData;
  active?: boolean;
  bankAccounts?: BankAccount[];
  bankLinkStatus?: LinkingStatus;
  lastLinkingAttemptDate?: Date;
  linkingAttemptCount?: number;
  successfullLinkingAttemptCount?: number;
  lastSuccessfullLinkingAttemptDate?: Date;
  bankIntegrationLastSync?: Date;
  bankLinkStatusInfo?: string[];
  linkReminderCount?: number;
  linkReminderLastDisplayedDate?: Date;
  isVerified?: boolean;
  totalMaintenancePrincipalBalance?: number;
  totalCutoffPrincipalBalance?: number;
  totalCurrentPrincipalBalance?: number;
  msaTerms?: EntityMSATermsData;
  isDrawSuspended?: boolean;
}

export interface EntityData extends EntityBaseData {
  createdOn?: Date;
  createdBy?: string;
  updatedOn?: Date;
  updatedBy?: string;
}

export interface WelcomeCall {
  required: boolean;
  isComplete: boolean;
  completedOn: string;
  ringDnaLink: string;
}

export interface EntityExData extends EntityData {
  advisorFirstName?: string;
  advisorLastName?: string;
  advisorName?: string;
  advisorEmail?: string;
  advisorPhone?: string;
  advisorCalendarLinkUrl?: string;
  advisorAvatar?: string;
  brokerName?: string;
  brokerDba?: string;
  brokerContactEmail?: string;
  brokerCommissionEmail?: string;
  brokerWebSite?: string;
  brokerPhone?: string;
  welcomeCall?: WelcomeCall;
}

export class EntityQueryParams extends QueryParams {
  customerId?: number;
  uniqueId?: string;
  advisorId?: number;
  contacts?: string[];
  types?: EntityType[];
  statuses?: EntityStatus[];
  name?: string;
  createdOnStart?: any;
  createdOnEnd?: any;
  updatedBy?: string;
  number?: string;
  bankIntegrationUsername?: string;
  businessName?: string;
  createdBy?: string;
  updatedOnStart?: any;
  updatedOnEnd?: any;
  brokerId?: number;
  bankLinkStatus?: any;
  lastLinkingAttemptDateFrom?: any;
  lastLinkingAttemptDateTo?: any;
  bankLinkAttempted?: boolean;
  isVerified?: boolean;
  ein?: string;

  constructor(params?: any) {
    super(params);
    params = params || {};

    this.customerId = parseNumber(params.customerId);
    this.uniqueId = params.uniqueId || undefined;
    this.advisorId = parseNumber(params.advisorId);
    this.contacts = params.contacts || undefined;
    this.types = params.types || undefined;
    this.statuses = params.statuses || undefined;
    this.name = params.name || undefined;
    this.createdOnStart = params.createdOnStart || undefined;
    this.createdOnEnd = params.createdOnEnd || undefined;
    this.updatedBy = params.updatedBy || undefined;
    this.number = params.number || undefined;
    this.bankIntegrationUsername = params.bankIntegrationUsername || undefined;
    this.businessName = params.businessName || undefined;
    this.createdBy = params.createdBy || undefined;
    this.updatedOnStart = params.updatedOnStart || undefined;
    this.updatedOnEnd = params.updatedOnEnd || undefined;
    this.brokerId = parseNumber(params.brokerId);
    this.bankLinkStatus = parseNumber(params.bankLinkStatus);
    this.lastLinkingAttemptDateFrom =
      params.lastLinkingAttemptDateFrom || undefined;
    this.lastLinkingAttemptDateTo =
      params.lastLinkingAttemptDateTo || undefined;
    this.bankLinkAttempted =
      params.bankLinkAttempted == null ? undefined : params.bankLinkAttempted;
    this.isVerified = params.isVerified == null ? undefined : params.isVerified;
    this.ein = params.ein || undefined;
  }
}

export interface EntityQueryResult extends QueryResult<EntityExData> {}

export interface EntityRecentActivity {
  id?: number;
  loanId?: number;
  loanNumber?: string;
  caseDescription?: string;
  event?: string;
  date?: Date;
  description?: string;
}

export enum EntityRecentActivityEvent {
  DrawFundsCommitted = "DrawFundsCommitted",
  PaymentCommitted = "PaymentCommitted",
  IdentityVerified = "IdentityVerified",
  IdentityVerificationFailed = "IdentityVerificationFailed",
  AgreementSigned = "AgreementSigned",
  AutomaticPaymentGenerated = "AutomaticPaymentGenerated",
  DocumentUploaded = "DocumentUploaded",
  EntityUpdated = "EntityUpdated",
  OriginationFeePosted = "OriginationFeePosted",
  UCCFilingFeePosted = "UCCFilingFeePosted",
}

export class EntitySession {
  id: number;
  name: string;
  dba?: string;
  type?: EntityType;
  advisorName?: string;
  advisorEmail?: string;
  advisorPhone?: string;
  advisorCalendarLinkUrl?: string;
  advisorAvatar?: string;
  ein?: string;
  naics?: string;
  address?: Address;
  establishedDate?: Date;
  loans?: string[];

  constructor(data) {
    data = data || {};
    this.id = data.id || undefined;
    this.name = data.name || undefined;
    this.dba = data.dba || undefined;
    this.type = data.type || undefined;
    this.advisorName = data.advisorName || undefined;
    this.advisorEmail = data.advisorEmail || undefined;
    this.advisorPhone = data.advisorPhone || undefined;
    this.advisorCalendarLinkUrl = data.advisorCalendarLinkUrl || undefined;
    this.advisorAvatar = data.advisorAvatar || undefined;
    this.ein = data.ein || undefined;
    this.naics = data.naics || undefined;
    this.address = data.address || undefined;
    this.establishedDate = data.establishedDate || undefined;
    this.loans = data.loans || undefined;
  }
}
