import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { ApplicationRemote } from "../../infrastructure/application.remote";
import { ApplicationStateService } from "./application.state.service";
import { ApplicationData } from "../../application.model";
import { map, switchMap, take, tap } from "rxjs/operators";
import { ApplicationOfferBundleData, ApplicationQueryResult } from "common";
import { CustomerFacade } from "../../../customer/domain/+state/customer.facade";

@Injectable({
  providedIn: "root",
})
export class ApplicationFacade {
  constructor(
    private readonly stateService: ApplicationStateService,
    private readonly remote: ApplicationRemote,
    private readonly customerFacade: CustomerFacade
  ) {}

  getApplication$(id: number): Observable<ApplicationData> {
    if (this.stateService.isApplicationStateLoaded(id)) {
      return this.stateService.getApplication$(id);
    }

    return this.getRequest(id).pipe(
      tap((app: ApplicationData) => {
        this.stateService.setApplication(app);
      }),
      switchMap(() => this.stateService.getApplication$(id))
    );
  }

  private getRequest(id: number) {
    return this.remote.get(id);
  }

  getApplications$(entityId: number): Observable<ApplicationData[]> {
    return this.remote
      .queryByEntity(entityId)
      .pipe(map((res: ApplicationQueryResult) => res.values));
  }

  clearApplicationCache(): void {
    this.stateService.clearState();
  }

  getAgreementReadyApplications(): Observable<number> {
    return this.remote.getAgreementReadyApplications();
  }

  getAgreementReadyOfferBundle(applicationId: number): Observable<ApplicationOfferBundleData> {
    return this.remote.getAgreementReadyOfferBundle(applicationId);
  }

  getFirstAvailableForSign(id?: number): Observable<ApplicationData> {
    if (id) {
      return this.getRequest(id).pipe(take(1));
    }

    return this.customerFacade.getCurrentCustomer$().pipe(
      take(1),
      switchMap((customer) => {
        if (customer?.pendingApplications?.length > 0) {
          return this.getApplication$(customer.pendingApplications[0]);
        }
        return of(null);
      })
    );
  }
}
