import { Pipe, PipeTransform } from "@angular/core";
import { ValidationErrors } from "@angular/forms";
import { FormErrorsConditionOperator } from "../enums/form-errors-condition-operator.enum";

@Pipe({
  name: "hasControlError",
})
export class HasControlErrorPipe implements PipeTransform {
  transform(
    errors: ValidationErrors,
    errorCodes: string[] = [],
    conditionOperator = FormErrorsConditionOperator.Some
  ) {
    if (!this.hasAnyError(errors)) {
      return false;
    }

    switch (conditionOperator) {
      case FormErrorsConditionOperator.Some:
        return this.hasSomeError(errors, errorCodes);
      case FormErrorsConditionOperator.Every:
        return errorCodes.every((errorCode) => errors?.[errorCode]);
      case FormErrorsConditionOperator.Only:
        return (
          Object.keys(errors).length === 1 &&
          errorCodes.length === 1 &&
          errors?.[errorCodes[0]]
        );
      case FormErrorsConditionOperator.Exclude:
        return this.hasSomeErrorWithExcluded(errors, errorCodes);
      default:
        return false;
    }
  }

  private hasAnyError(errors: ValidationErrors) {
    return errors && Object.keys(errors).length > 0;
  }

  private hasSomeError(errors: ValidationErrors, errorCodes: string[] = []) {
    if (!this.hasAnyError(errors)) {
      return false;
    }

    return (
      errorCodes.length === 0 ||
      errorCodes.some((errorCode) => errors?.[errorCode])
    );
  }

  private hasSomeErrorWithExcluded(
    errors: ValidationErrors,
    errorCodes: string[]
  ) {
    const errorsCopy = { ...errors };
    errorCodes.forEach((code) => delete errorsCopy[code]);
    return this.hasSomeError(errorsCopy);
  }
}
