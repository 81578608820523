import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CustomerCheckoutState } from './customer-checkout.model';
import { AppSettings } from '../../../app.settings';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CustomerKbaConversation } from 'common';

@Injectable({
  providedIn: 'root'
})
export class CustomerCheckoutService {

  private readonly baseUrl = `${this.settings.customers.url}/api/checkout`;

  verifyAnswersError: any;

  constructor(private http: HttpClient,
    private settings: AppSettings,
    private dialog: MatDialog,
    private router: Router) { }

  answer(form: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/idology`, form);
  }

  getQuestions(): Observable<CustomerKbaConversation> {
    return this.http.get<CustomerKbaConversation>(`${this.baseUrl}/idology`);
  }

  getState(): Observable<CustomerCheckoutState> {
    return this.http.get<CustomerCheckoutState>(`${this.baseUrl}/state`);
  }

  acceptDisclosure(code: string, brokerId?: number): Observable<any> {
    let url = `${this.baseUrl}/disclosure/${code}`;
    if(!isNaN(brokerId))
      url += `/${brokerId}`;

    return this.http.put(url, { accepted : true});
  }
}
