import { untilDestroyed, UntilDestroy } from "@ngneat/until-destroy";
import { Component, OnInit } from "@angular/core";
import * as moment from "moment";
import { map } from "rxjs/operators";
import { forkJoin } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import * as _ from "lodash";
import {
  TimeHelper,
  StringHelper,
  ApplicationStatus,
  LoanStatus,
  EntityData,
  EntityRecentActivity,
  RenewalEligibilityDto,
  CompanyBranding,
  ApplicationType,
  ApplicationBaseData,
  LoanDocumentService,
  LoanFileTemplateData,
  EntityRecentDocuments,
  EntityDocumentsService,
  AvailableFundsExplanationDialogComponent,
  parseNumber,
  BrandingService,
  EntityHelper,
  NeedsAttentionModel,
  CompanyBrandingHelper,
  EntityExData,
} from "common";
import { CaseFinancingLoanData, LoanData, LoanDataSummary, LoanFileTemplate, ProductCode } from "../../loan/domain/models/loan.model";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { NotificationDialogComponent } from "../../shared/notification-dialog/notification-dialog.component";
import { MessageService } from "../../message/message.service";
import { ApplicationData } from "../../application/application.model";
import { CustomerData } from "../../customer/domain/models/customer.model";
import { FinancialInstitution } from "../../bank-account/bank-account.model";
import { ButtonAppearance, MessageData } from "../../message/message.model";
import { RenewalService } from "../../renewal/renewal.service";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { BankingService } from "../../bank-account/banking.service";
import { BankAccountService } from "../../bank-account/bank-account.service";
import { ApplicationFacade } from "../../application/domain/+state/application.facade";
import { EntityFacade } from "../../entity/domain/+state/entity.facade";
import { LoanFacade } from "../../loan/domain/+state/loan.facade";
import { NavigationService } from "../../core/navigation/navigation.service";

@UntilDestroy()
@Component({
  selector: "ifp-dashboard-content",
  templateUrl: "./dashboard-content.component.html",
  styleUrls: ["./dashboard-content.component.scss"],
})
export class DashboardContentComponent implements OnInit {
  private _filteredCFLoans: CaseFinancingLoanData[];
  private _filteredLoans: LoanData[];
  private _companyBranding: CompanyBranding;

  form: UntypedFormGroup;
  greeting: string;
  loans: LoanData[];
  cfLoans: CaseFinancingLoanData[];
  cfLoanTemplates: LoanFileTemplate[];
  customer: CustomerData;
  notificationDialogRef: MatDialogRef<NotificationDialogComponent>;
  messages: MessageData[];
  entity: EntityData;
  applications: ApplicationData[];
  cfApplications: ApplicationData[];
  nonCfApplications: ApplicationData[];
  cfApplicationsActionRequired: boolean;
  nonCfApplicationsActionRequired: boolean;
  productCodes = ProductCode;
  banks: FinancialInstitution[];
  financingActionRequired: boolean;
  caseFinancingActionRequired: boolean;
  noLinkedBanks: boolean;
  showCaseFinanceFilter: boolean = false;
  caseFinancingFiltered: boolean;
  showAccountsFilter: boolean = false;
  accountsFiltered: boolean;
  hasAvailableOffers: boolean;
  recentActivity: EntityRecentActivity[];
  recentDocuments: EntityRecentDocuments[];
  renewalEligibilityMessage: MessageData;
  needsAttentionModel: NeedsAttentionModel;

  constructor(
    private route: ActivatedRoute,
    private entityFacade: EntityFacade,
    private loanFacade: LoanFacade,
    private loanDocumentService: LoanDocumentService,
    private applicationFacade: ApplicationFacade,
    private messageService: MessageService,
    private brandingService: BrandingService,
    private dialog: MatDialog,
    private renewalService: RenewalService,
    private bankingService: BankingService,
    private bankAccountService: BankAccountService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private entityDocumentsService: EntityDocumentsService,
    private navigationService: NavigationService
  ) {
    this.form = this.formBuilder.group({
      search: "",
    });
  }

  ngOnInit() {
    this.initRouteData();
    this.initEntity();
    this.watchBankLinkingStatus();
    this.initCampaign();
  }

  private initRouteData() {
    this.route.data.pipe(untilDestroyed(this)).subscribe((data) => {
      this.customer = data.customer;
    });
  }

  private watchBankLinkingStatus() {
    this.bankingService.obsInstitutionLinkStatus
      .pipe(untilDestroyed(this))
      .subscribe(() => this.initBanks(this.entity.id));
  }

  private initEntity() {
    this.entityFacade
      .getEntity$(parseNumber(this.route.snapshot.queryParams.companyId))
      .pipe(untilDestroyed(this))
      .subscribe((entity: EntityData) => {
        this.entity = entity;
        this.getLoansData(entity.id);
        this.getApplications(entity.id);
        this.initBanks(entity.id);
        this.initRecentActivities(entity.id);
        this.initRecentDocuments(entity.id);
        this.initMessages(entity.id);
      });
  }

  private initRecentActivities(entityId: number) {
    this.entityFacade
      .getRecentActivities(entityId)
      .subscribe((res) => (this.recentActivity = res));
  }

  private initRecentDocuments(entityId: number) {
    this.entityDocumentsService
      .getRecentDocuments(entityId)
      .subscribe((documents) => (this.recentDocuments = documents));
  }

  private initCampaign() {
    const campaign = this.route.snapshot.queryParamMap.get("campaign");
    if (campaign) {
      sessionStorage.setItem("campaign", campaign);
      this.navigationService.removeQueryParamFromMaintenance("campaign");
    }
  }

  private initCfLoanTemplates(cfLoans: CaseFinancingLoanData[]) {
    const ids: number[] = cfLoans?.map((loan) => loan.id);
    const calls = ids?.map((loanId) => {
      return this.loanDocumentService
        .getLoanFileTemplates(loanId)
        .pipe(
          map((templates: LoanFileTemplateData[]) => ({ loanId, templates }))
        );
    });

    forkJoin(calls)
      .pipe(untilDestroyed(this))
      .subscribe((templates: LoanFileTemplate[]) => {
        this.cfLoanTemplates = templates;
      });
  }

  dataInit() {
    this.brandingService.getBranding().pipe(untilDestroyed(this)).subscribe((res) => {
      this._companyBranding = res;

      this.greeting = `${TimeHelper.getGreetingTime(moment())}, ${
        this.customer?.firstName
      }!`;
      this.financingActionRequired = _.some(
        this.activeLoans,
        (loan: LoanData) =>
          loan.status === LoanStatus.PendingRenewal ||
          loan.status === LoanStatus.Pending
      );
      this.caseFinancingActionRequired = _.some(
        this.activeCFLoans,
        (loan: LoanData) =>
          loan.status === LoanStatus.PendingRenewal ||
          loan.status === LoanStatus.Pending
      );
      this.cfApplicationsActionRequired = _.some(
        this.cfApplications,
        (app: ApplicationData) =>
          app.sfAppStatus === ApplicationStatus.ApplicationIncomplete ||
          app.sfAppStatus === ApplicationStatus.UnderwritingMissingInfo ||
          app.sfAppStatus === ApplicationStatus.UnderwritingCall
      );
      this.nonCfApplicationsActionRequired = _.some(
        this.nonCfApplications,
        (app: ApplicationData) =>
          app.sfAppStatus === ApplicationStatus.ApplicationIncomplete ||
          app.sfAppStatus === ApplicationStatus.UnderwritingMissingInfo ||
          app.sfAppStatus === ApplicationStatus.UnderwritingCall
      );
      this.hasAvailableOffers = _.some(
        this.applications,
        (app: ApplicationData) => app.sfAppStatus === ApplicationStatus.Approved
      );
      this.noLinkedBanks = !_.some(
        this.banks,
        (bank: FinancialInstitution) => bank.isLinked
      );
      this._filteredCFLoans = [
        ...this.cfLoans.filter(
          (l) =>
            l.status !== LoanStatus.Closed &&
            l.status !== LoanStatus.PendingClosure
        ),
      ].sort((l1, l2) => this.loanFacade.loanSorter(l1, l2, this.customer));

      this._filteredLoans = [
        ...this.loans.filter(
          (l) =>
            l.status !== LoanStatus.Closed &&
            l.status !== LoanStatus.PendingClosure
        ),
      ].sort((l1, l2) => this.loanFacade.loanSorter(l1, l2, this.customer));

      this.initNeedsAttentionModel(this.entity);

      this.pendingRenewalAlert();
    });
  }

  get activeLoans(): LoanData[] {
    return this._filteredLoans;
  }

  get activeCFLoans(): CaseFinancingLoanData[] {
    return this._filteredCFLoans;
  }

  get allActiveLoans(): LoanData[] {
    let loans = [];
    if (this.activeLoans?.length > 0) {
      loans = this.activeLoans;
    }

    if (this.activeCFLoans?.length > 0) {
      loans = [...this.activeLoans, ...this.activeCFLoans];
    }
    return loans;
  }

  get firstActiveCFLoan(): CaseFinancingLoanData {
    return this._filteredCFLoans.length > 0 ? this._filteredCFLoans[0] : null;
  }

  get closedLoans(): LoanData[] {
    return this.loans.filter(
      (l) =>
        l.status === LoanStatus.Closed || l.status === LoanStatus.PendingClosure
    );
  }

  private getLoansData(entityId: number) {
    this.loanFacade
      .getSummaryByEntity(entityId)
      .pipe(untilDestroyed(this))
      .subscribe((summary: LoanDataSummary) => {
        this.loans = summary.otherLoans;
        this.cfLoans = summary.caseFinancingLoans;
        this.dataInit();
        this.initCfLoanTemplates(this.cfLoans);
      });
  }

  private initBanks(entityId: number) {
    this.bankAccountService.getBanks(entityId).subscribe((res) => {
      this.banks = res;
      this.noLinkedBanks = !_.some(
        this.banks,
        (bank: FinancialInstitution) => bank.isLinked
      );
    });
  }

  private getApplications(entityId: number) {
    this.applicationFacade
      .getApplications$(entityId)
      .subscribe((applications: ApplicationData[]) => {
        this.initApplications(applications);
      });
  }

  private initMessages(entityId: number): void {
    const messages = [];
    this.messageService
      .query(entityId)
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        if (!data) {
          return;
        }
        const dismissed = this.messageService.getDismissedMessagesStorage();
        data.forEach((item) => {
          if (
            !_.includes(dismissed, item.id) &&
            item.subject !== "Refer Twitter"
          )
            messages.push(item);
        });

        messages.sort((x, y) => (y.importance ?? 0) - (x.importance ?? 0));
        this.messages = messages;
      });

    this.renewalService
      .getEligible()
      .pipe(untilDestroyed(this))
      .subscribe((eligibility: RenewalEligibilityDto[]) => {
        const pendingRenewal = eligibility?.find(
          (e) => !e.renewalApplicationId
        );
        if (pendingRenewal) {
          this.renewalEligibilityMessage = {
            icon: "../assets/ideafinancial-renewal.png",
            body: "Your loan is elegible for renewal",
            buttons: [
              {
                label: "START RENEWAL",
                link: "action:optIn",
                appearance: ButtonAppearance.emphasized,
              },
            ],
            loanId: pendingRenewal.loanId,
          };
        }
      });
  }

  private initNeedsAttentionModel(entity: EntityExData): void {
    const advisorPhone = CompanyBrandingHelper.getAdvisorPhone(this._companyBranding, entity?.advisorPhone);
 
    this.needsAttentionModel = {
      isApplicable: entity?.isDrawSuspended,
      messageInput: EntityHelper.getNeedsAttentionMessage({ ...entity, advisorPhone: advisorPhone})
    };
  }

  dismissMessage(message: MessageData) {
    this.messageService
      .update(this.customer.id, this.entity.id, message, true)
      .subscribe(() => this.initMessages(this.entity.id));
  }

  filterCFLoans(input: LoanData) {
    this.caseFinancingFiltered = true;
    this._filteredCFLoans = [
      ...(input != undefined
        ? this.cfLoans.filter((l) => l.id == input?.id)
        : this.cfLoans),
    ].sort((l1, l2) => this.loanFacade.loanSorter(l1, l2, this.customer));
  }

  closeFilterCFLoans() {
    this.filterCFLoans(undefined);
    this.showCaseFinanceFilter = false;
  }

  filterLoans(input: LoanData) {
    this.accountsFiltered = true;
    this._filteredLoans = [
      ...(input != undefined
        ? this.loans.filter((l) => l.id == input?.id)
        : this.loans.filter(
            (l) =>
              l.status !== LoanStatus.Closed &&
              l.status !== LoanStatus.PendingClosure
          )),
    ].sort((l1, l2) => this.loanFacade.loanSorter(l1, l2, this.customer));
  }

  closeFilterLoans() {
    this.filterLoans(undefined);
    this.showAccountsFilter = false;
  }

  pendingRenewalAlert() {
    if (this.notificationDialogRef) return;

    const pendingRenewals = this.loans.filter(
      (l) =>
        l.status === LoanStatus.PendingRenewal &&
        (!l.optInForRenewal || !l.renewalApplicationId)
    );
    if (!pendingRenewals.length) return;

    const advisorPhone = StringHelper.phoneFormat(
      CompanyBrandingHelper.getAdvisorPhone(this._companyBranding, pendingRenewals[0].advisorPhone)
    );

    let message: string;
    if (pendingRenewals.length === 1)
      // eslint-disable-next-line max-len
      message = `Your ${pendingRenewals[0].productName} with account number ${pendingRenewals[0].loanNumber} is pending renewal. Give us a call at ${advisorPhone} to start your renewal application.`;
    else if (pendingRenewals.length > 1) {
      let loansInfo = "";
      // eslint-disable-next-line max-len
      pendingRenewals.forEach(
        (p) =>
          (loansInfo =
            loansInfo === ""
              ? `${p.productName} with account number ${p.loanNumber}`
              : `${loansInfo} and ${p.productName} with account number ${p.loanNumber}`)
      );
      // eslint-disable-next-line max-len
      message = `Your ${loansInfo} are pending renewal. Give us a call at ${advisorPhone} to start your renewal application.`;
    }

    this.notificationDialogRef = this.dialog.open(NotificationDialogComponent, {
      disableClose: false,
      width: "500px",
    });
    this.notificationDialogRef.componentInstance.title = "Pending renewal";
    this.notificationDialogRef.componentInstance.message = message;
  }

  link() {
    this.router.navigate(["/bank-link-reminder"]);
  }

  fundAnotherCase() {
    this.router.navigate(["/new-case"]);
  }

  showAvailableFundsDialog(): void {
    AvailableFundsExplanationDialogComponent.show(
      this.dialog,
      "Your available funds is the lower amount of either a case's credit limit minus the outstanding balance of that case, or the Master Service Agreement limit minus the aggregate outstanding balances of all your cases.",
      this.firstActiveCFLoan?.loanInfo?.availableFunds,
      this.firstActiveCFLoan?.loanInfo?.msaCreditLimit
    );
  }

  private initApplications(applications: ApplicationBaseData[]) {
    this.applications = applications;
    this.cfApplications = applications?.filter(
      (a) => a.applicationType === ApplicationType.LCF
    );
    this.nonCfApplications = applications?.filter(
      (a) => a.applicationType !== ApplicationType.LCF
    );
  }
}
