import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { OAuthService } from '../oauth/oauth.service';
import { catchError, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RefreshTokenInterceptor implements HttpInterceptor {

  constructor(private oauthService: OAuthService,private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //RD: We need to skip execution if this reqest is for token endpoint which means that we want to get access token after login or we want to refresh existing expired token
    if (request.url.endsWith('/token') || !this.oauthService.accessToken)
      return next.handle(request);

    if (!this.oauthService.authenticated) {
      return this.oauthService.refreshAuthToken().pipe(switchMap((res) => {
          if (!res?.access_token) {
              this.oauthService.sessionEndLocal();
              sessionStorage.setItem('navigation.history', window.location.pathname);
              this.router.navigate(["sign-in"]);
          }

          this.oauthService.sessionBegin(
              sessionStorage.loginType,
              res?.access_token,
              res?.expires_in,
              res?.refresh_token,
              res?.refresh_token_expires_in,
              sessionStorage.clientId);

            return next.handle(request);
      }), catchError((err) => {
        sessionStorage.setItem('navigation.history', window.location.pathname);
        this.router.navigate(["sign-in"]);
        return EMPTY;
      }));
    } else {
        this.oauthService.restartIdleTimer();
        return next.handle(request);
    }
  }
}
