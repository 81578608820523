<mat-dialog-content class="mat-typography">
  <h3>Set filter</h3>

  <form [formGroup]="form">
    <div fxLayout="row wrap" fxLayoutAlign="start start" fxFlex="500px" fxFlex.xs="350px" fxLayoutGap="24px"  style="margin-right:-24px" fxLayout.xs="column"
      fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">

      <ifc-account-case-name-input [productCode]="data.query.productCode" [excludedProductCode]="data.query.excludedProductCode" fxFlex.xl="222px" [fc]="form.get('description')" class="mat-form-field"></ifc-account-case-name-input> 
      <ifc-account-case-number-input [productCode]="data.query.productCode" [excludedProductCode]="data.query.excludedProductCode" fxFlex.xl="222px"  *ngIf="data.productCode == productCodes.Law"  [fc]="form.get('externalId')" class="mat-form-field"></ifc-account-case-number-input> 
      <ifc-account-loan-number-input [productCode]="data.query.productCode" [excludedProductCode]="data.query.excludedProductCode" fxFlex.xl="222px"  [fc]="form.get('loanNumber')" class="mat-form-field"></ifc-account-loan-number-input> 

      <mat-form-field appearance="outline" fxFlex.xl="222px" class="mat-form-field">
        <mat-label>Status</mat-label>
        <mat-select formControlName="status" placeholder="All">
          <mat-option>All</mat-option>
          <mat-option *ngFor="let option of statusOptions" [value]="option.value">{{option.name | startCase}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <ifc-filter-param-date-range-input class="mat-form-field" fxFlex.xl="222px" [fc]="form.get('createdOn')" [label]="'Created on'" (rangeSelected)="createdDateSelected($event)">
      </ifc-filter-param-date-range-input>

      <ifc-datepicker *ngIf="form.get('createdOn').value === 'custom'" class="mat-form-field" fxFlex.xl="222px"  [fc]="form.get('createdOnFrom')" [label]="'Created on from'"></ifc-datepicker>
      <ifc-datepicker *ngIf="form.get('createdOn').value === 'custom'"class="mat-form-field"  fxFlex.xl="222px" [fc]="form.get('createdOnTo')" [label]="'Created on to'"></ifc-datepicker>

      <mat-form-field appearance="outline" fxFlex.xl="222px" class="mat-form-field">
        <mat-label>Favorite</mat-label>
        <mat-select formControlName="favorite" placeholder="All">
          <mat-option [value]="null">All</mat-option>
          <mat-option [value]="'true'">Yes</mat-option>
          <mat-option [value]="'false'">No</mat-option>
        </mat-select>
      </mat-form-field>      

      <mat-form-field appearance="outline" fxFlex.xl="222px" class="mat-form-field"  *ngIf="data.productCode != productCodes.Law" >
        <mat-label>Product type</mat-label>
        <mat-select formControlName="productCode" placeholder="All">
          <mat-option [value]="null">All</mat-option>
          <mat-option *ngFor="let option of productTypeOptions" [value]="option.value">{{option.name}}</mat-option>
        </mat-select>
      </mat-form-field>     

      
      <mat-form-field appearance="outline" fxFlex.xl="222px" class="mat-form-field" *ngIf="false" >
        <mat-label>Excluded product type</mat-label>
        <mat-select formControlName="excludedProductCode" placeholder="All">
          <mat-option [value]="null">All</mat-option>
          <mat-option *ngFor="let option of productTypeOptions" [value]="option.value">{{option.name}}</mat-option>
        </mat-select>
      </mat-form-field> 
    </div>

  </form>
</mat-dialog-content>

<div mat-dialog-actions class="flex row end-center">
  <button mat-button [mat-dialog-close]="">Cancel</button>
  <button mat-button [mat-dialog-close]="filters" cdkFocusInitial color="primary">Ok</button>
</div>
