  <div id="main" fxLayout="column" fxLayoutAlign="center center">

    <div fxLayout="column" fxLayoutAlign="center center">

      <div class="content" fxLayout="column" fxLayoutAlign="center center" class="header">
        <span class="title padding-bottom" ngClass.lt-md="title xs">
          Thank you for considering us as your business insurance provider. Unfortunately we are unable
          to provide you with a quote today.
        </span>
      </div>

    </div>

  </div>
