<ifc-offer-draw-slider [offerCalculator]="calculator"
                       [productCode]="'Law'">
</ifc-offer-draw-slider>

<ifc-offer-calculator-details-table [offerCalculator]="calculator"
                                    [showCommision]="!isRenewal"
                                    [showTerm]="false"
                                    (showDisclosures)="onShowDisclosures($event)">
</ifc-offer-calculator-details-table>

<ifc-offer-calculator-closing-requirements [checkoutRequirements]="calculator.applicationOfferBundle.checkoutRequirements"
                                           [isOfferBundleSelected]="calculator.applicationOfferBundle.status === offerBundleStatusEnum.Accepted"
                                           (documentsClick)="onDocumentsClick(calculator.application?.id, calculator.applicationOfferBundle?.id)">
</ifc-offer-calculator-closing-requirements>

<ifc-link-your-bank [bankLinkStatus]="calculator.application?.bankLinkStatus"
                    (linkBank)="onLinkBank(calculator.application?.entityId)">
</ifc-link-your-bank>

<ifc-offer-calculator-payment-schedule [offer]="calculator.applicationOffer"
                                       [sampleDraw]="calculator.sampleDraw">
</ifc-offer-calculator-payment-schedule>
