import { GlobalConfig, IndividualConfig } from "ngx-toastr";

export const getIndividualConfig = (config?: Partial<GlobalConfig>) => {
  return {
    ...defaultIndividualConfig,
    ...config,
  };
};

export const getGlobalConfig = (config?: Partial<GlobalConfig>) => {
  return {
    ...defaultGlobalConfig,
    ...config,
  };
};

const defaultIndividualConfig: Partial<IndividualConfig> = {
  timeOut: 5000,
};

const defaultGlobalConfig: Partial<GlobalConfig> = {
  positionClass: 'toast-top-right',
  preventDuplicates: true,
  includeTitleDuplicates: true,
  countDuplicates: true,
  resetTimeoutOnDuplicate: true,
  maxOpened: 1,
  closeButton: false,
  tapToDismiss: false,
  disableTimeOut: 'extendedTimeOut'
};
