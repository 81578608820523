import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { MatCheckbox } from "@angular/material/checkbox";
import { Subscription } from "rxjs";
import * as moment from "moment";
import {
  LoanStatus,
  MessageService,
  FeeType,
  PaymentFrequencyLabel,
  LinkingStatus,
  DocumentCategory,
  EnumHelper,
  ProductCode,
  AppPageService,
} from "common";
import { AcceptLoanAgreementResponse } from "../loc-agreement.data";
import { LocAgreementService } from "../loc-agreement.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AgreementDto, OfferInfo, OfferBundleInfo } from "../../agreement/agreement.data";
import { AccountTransferService } from "../../account/account-transfer/account-transfer.service";
import { RenewalService } from "../../renewal/renewal.service";
import { LoanFacade } from "../../loan/domain/+state/loan.facade";
import { CustomerFacade } from "../../customer/domain/+state/customer.facade";
import { CustomerCheckoutService } from "../../customer/customer-checkout/customer-checkout.service";
import { BankAccountService } from "../../bank-account/bank-account.service";
import { BankingService } from "../../bank-account/banking.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { LoanData } from "../../loan/domain/models/loan.model";
import { LoanFundedDialogComponent } from "../../loan/loan-funded-dialog/loan-funded-dialog.component";
import { FinancialInstitution } from "../../bank-account/bank-account.model";
import { CustomerData } from "../../customer/domain/models/customer.model";

@UntilDestroy()
@Component({
  selector: "ifp-loc-agreement-form",
  templateUrl: "./loc-agreement-form.component.html",
  styleUrls: ["./loc-agreement-form.component.scss"],
})
export class LocAgreementFormComponent implements OnInit {
  data: LocAgreementFormComponentData;
  productCodes = ProductCode;
  offerBundleId?: number;
  selectedOfferId?: number;
  templateId?: number;
  locOfferDetails = {
    creditLimit: null,
    repaymentTerm: null,
  };
  termLoanOfferDetails = {
    loanAmount: null,
    originationFeeAmount: null,
    disbursedAmount: null,
    factorRate: null,
    totalInterest: null,
    totalRepaymentAmount: null,
    paymentAmount: null,
    totalNumberOfPayments: null,
  };
  currentOffer: OfferInfo;
  agreementInfo: any;
  locAgreement: AgreementDto;
  activationMessage: string;
  dba: string;
  agreementHtml: SafeHtml;
  currentDisclosure: number = 0;
  bankIntegrationRequired: boolean;
  isDisbursementBankEligible: boolean = false;

  @ViewChild("agreementCheckbox")
  agreementCheckbox: MatCheckbox;

  @ViewChild("agreementLeft")
  agreementLeft: ElementRef;

  @ViewChild("agreementContent")
  agreementContent: ElementRef;

  @ViewChild("agreementActions")
  agreementActions: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private locAgreementService: LocAgreementService,
    private messageService: MessageService,
    private accountTransferService: AccountTransferService,
    private renewalService: RenewalService,
    private loanFacade: LoanFacade,
    private customerFacade: CustomerFacade,
    private sanitizer: DomSanitizer,
    private customerCheckoutService: CustomerCheckoutService,
    private bankAccountService: BankAccountService,
    private bankingService: BankingService,
    private snackBar: MatSnackBar,
    private appPageService: AppPageService
  ) {
    sessionStorage.setItem("accountSetupViewed", JSON.stringify(true));
    if ((window as any)?.flutter_inappwebview) {
      this.isInWebView = true;
    }
  }

  isInWebView: boolean = false;

  get FeeType() {
    return FeeType;
  }

  get hasDisclosures(): boolean {
    return (this.locAgreement?.disclosures?.length > 0);
  }

  get categoryName() {
    return EnumHelper.getNameFromValue(
      DocumentCategory,
      this.locAgreement?.category
    );
  }

  download() {
    this.locAgreementService.download(
      this.data.loan.id,
      this.offerBundleId,
      this.selectedOfferId
    );
  }

  reloadComponent() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = "reload";
    this.router.navigate([`account/${this.data.loan.id}/setup/sign-agreement`]);
  }

  ngOnInit() {
    this.route.data
      .pipe(untilDestroyed(this))
      .subscribe((data: LocAgreementFormComponentData) =>
        this.onDataInit(data)
      );

    this.locAgreementService
      .getAgreement(this.data.loan.id, this.offerBundleId, this.selectedOfferId)
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        this.templateId = data.templateId;
        this.locAgreement = data;
        this.agreementHtml = this.sanitizer.bypassSecurityTrustHtml(
          this.locAgreement?.html
        );
      });

    this.prepareAgreementData();

    this.bankIntegrationRequired = false;
    if (this.data && this.data.loan && this.data.loan.entityId) {
      this.bankAccountService
        .getIsBankLinkReminderRequired(this.data.loan.entityId)
        .subscribe(
          (isBankLinkReminderRequired) =>
            (this.bankIntegrationRequired = isBankLinkReminderRequired)
        );

      this.bankAccountService
        .getAccountsInfo(this.data.loan.entityId)
        .subscribe((accountInfo) => {
          this.isDisbursementBankEligible =
            accountInfo.isDisbursementBankEligible;

          this.checkDisbursementAccount();
        });
    }
  }

  private onDataInit(data: LocAgreementFormComponentData): void {
    this.data = data;

    this.offerBundleId =  this.data?.offerBundlesInfo?.offerBundleId;
    this.selectedOfferId = this.data?.offerBundlesInfo?.selectedOffer?.id;

    if (moment(data.loan.createdOn).isSame(moment().startOf("day"), "d")) {
      // eslint-disable-next-line max-len
      this.activationMessage =
        "You have two (2) days to activate your loan and draw funds. Funds requested after two days are subject to an additional underwriting review.";
    } else if (
      moment(data.loan.createdOn).isSame(
        moment().subtract(1, "days").startOf("day"),
        "d"
      )
    ) {
      // eslint-disable-next-line max-len
      this.activationMessage =
        "You have until today day to activate your loan and draw funds. Funds requested after one (1) day are subject to an additional underwriting review.";
    } else if (
      moment(data.loan.createdOn).isSameOrBefore(
        moment().subtract(2, "days").startOf("day"),
        "d"
      )
    ) {
      // eslint-disable-next-line max-len
      this.activationMessage =
        "It has been two (2) or more days after your loan was created. Any funds requested to activate your loan will be subject to an additional underwriting review.";
    }
  }

  private prepareAgreementData(): void {
    const pendingOffers =
      this.data.loan.status === LoanStatus.PendingRenewal &&
      this.data.offerBundlesInfo &&
      this.data.offerBundlesInfo.selectedOffer;

    this.agreementInfo = pendingOffers
      ? this.data.offerBundlesInfo.selectedOffer
      : this.data.loan;
    this.currentOffer = pendingOffers
      ? this.data.offerBundlesInfo.selectedOffer
      : this.data.loan.offer;

    if (this.agreementInfo.productCode === this.productCodes.Term)
      this.getTermLoanCalculations();
    else if (
      this.agreementInfo.productCode === this.productCodes.LOC ||
      this.agreementInfo.productCode === this.productCodes.Law
    )
      this.getLineOfCreditCalculations();
  }

  print() {
    const innerContents = document.getElementById(
      `account-setup-loc-agreement`
    ).innerHTML;
    const popupWinindow = window.open(
      "",
      "_blank",
      "width=600,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no"
    );
    popupWinindow.document.open();
    popupWinindow.document.write(
      `<html><head><link rel="stylesheet" type="text/css" href="style.css" /></head><body onload="window.print()">${innerContents}</html>`
    );
    popupWinindow.document.close();
  }

  get loanOfferPaymentFrequencyLabel() {
    return PaymentFrequencyLabel.get(this.currentOffer.paymentFrequency);
  }

  review() {
    if (!this.checkDisbursementAccount()) return;

    if (!this.hasDisclosures) {
      this.submit();
      return;
    }

    this.showNextDisclosure(0);
  }

  showNextDisclosure(index?: number) {
    if (!isNaN(index)) this.currentDisclosure = index;
    else
      this.currentDisclosure =
        this.currentDisclosure < this.locAgreement.disclosures.length - 1
          ? this.currentDisclosure + 1
          : 0;

    const disclosure = this.locAgreement.disclosures[this.currentDisclosure];
    const disclosureElement = document.getElementById(
      "account-setup-agreement-disclosure"
    );

    disclosureElement.style.display = "block";
    this.agreementContent.nativeElement.innerHTML = disclosure.text;

    if (this.currentDisclosure == this.locAgreement.disclosures.length - 1) {
      this.agreementLeft.nativeElement.style.display = "none";
      this.agreementActions.nativeElement.style.display = "block";
    } else {
      this.agreementLeft.nativeElement.style.display = "block";
      this.agreementActions.nativeElement.style.display = "none";
    }

    const scrollingElement = document.getElementById(disclosure.code);

    disclosureElement.style.top =
      scrollingElement.offsetTop - disclosureElement.offsetHeight - 16 + "px";
    disclosureElement.style.left = scrollingElement.offsetLeft + "px";
    scrollingElement.scrollIntoView({
      block: "center",
    });
  }

  hideDisclosure() {
    document.getElementById(
      "account-setup-agreement-disclosure"
    ).style.display = "none";
  }

  acceptDisclosure() {
    if (!this.checkDisbursementAccount()) return;

    const disclosure = this.locAgreement.disclosures[this.currentDisclosure];

    this.customerCheckoutService
      .acceptDisclosure(disclosure.code)
      .subscribe(() => {
        if (
          this.currentDisclosure >=
          this.locAgreement.disclosures.length - 1
        ) {
          this.hideDisclosure();
          this.submit();
        } else {
          this.agreementCheckbox.checked = false;
          this.showNextDisclosure();
        }
      });
  }

  private checkDisbursementAccount(): boolean {
    if (!this.isDisbursementBankEligible) {
      this.snackBar.open(
        "Your disbursement bank account is invalid. Please contact your business advisor.",
        "",
        { duration: 5000 }
      );
      return false;
    }
    return true;
  }

  submit() {
    if (this.submitSub) return;

    this.customerFacade.clearCustomerCache();

    this.submitSub = this.locAgreementService
      .submit(this.data.loan.id, {
        accept: true,
        templateId: this.templateId,
        offerBundleId: this.offerBundleId,
        selectedOfferId: this.selectedOfferId,
      })
      .subscribe(this.submitSuccess.bind(this), this.submitError.bind(this));
  }

  submitError(error: any) {
    this.submitSubClear();
    this.messageService.error(error);
  }

  submitSub: Subscription;

  submitSubClear() {
    if (this.submitSub) this.submitSub.unsubscribe();
    this.submitSub = null;
  }

  submitSuccess(response: AcceptLoanAgreementResponse) {
    this.submitSubClear();

    if (!response.isFinal) {
      this.reloadComponent();
      return;
    }

    if (this.offerBundleId) {
      this.renewalService
        .acceptOfferBundle(this.data.loan.id, this.offerBundleId)
        .subscribe();
    }

    if (response.loanId) {
      if(this.appPageService.backToIfPossible(true)) {
        return;
      }

      this.loanFacade
        .refreshLoan(response.loanId)
        .pipe(untilDestroyed(this))
        .subscribe((loan: LoanData) => {
          if (
            loan.productCode === ProductCode.Term &&
            loan.status === LoanStatus.Open
          ) {
            this.showTermLoanDraw(loan);
          } else if (loan.freezeForDays) {
            this.router.navigate(["dashboard"]);
          } else {
            this.router.navigate([
              `account/${response.loanId}/setup/make-a-draw`,
            ]);
          }
        });
    } else {
      this.messageService.info(
        `Please make sure you checked 'By clicking this checkbox you agree to the terms and conditions set forth in the agreement above.'`
      );
    }
  }

  private showTermLoanDraw(loan: LoanData): void {
    LoanFundedDialogComponent.show(
      this.dialog,
      loan,
      this.bankIntegrationRequired,
      this.termLoanOfferDetails.disbursedAmount
    ).subscribe(() => {
      if (this.bankIntegrationRequired) {
        this.navigateToBankLinking(loan);
      } else {
        this.router.navigate(["dashboard"]);
      }
    });
  }

  private navigateToBankLinking(loan: LoanData): void {
    const bankAccount = loan.disbursementBankAccountData;

    const instutution: FinancialInstitution = {
      institutionId: bankAccount.syncedBankAccount?.institutionId,
      institutionLoginId: bankAccount.syncedBankAccount?.institutionLoginId,
      institutionName:
        bankAccount.syncedBankAccount?.institutionName ?? bankAccount.bankName,
      routingNumber:
        bankAccount.syncedBankAccount?.routingNumber ??
        bankAccount.routingNumber,
      isLinked: bankAccount.linkingStatus == LinkingStatus.Linked,
    };
    this.bankingService.relink(
      this.data.loan.entityId,
      instutution,
      "dashboard"
    );
  }

  getLineOfCreditCalculations() {
    const currentOffer = { ...this.currentOffer } || {};
    this.locOfferDetails.creditLimit = currentOffer.amount;
    this.locOfferDetails.repaymentTerm = currentOffer.repaymentTerm;
  }

  getTermLoanCalculations() {
    const currentOffer = { ...this.currentOffer } || {};

    this.termLoanOfferDetails.loanAmount = currentOffer.amount;

    this.termLoanOfferDetails.originationFeeAmount =
      this.accountTransferService.calculateFeeAmount(
        currentOffer.amount,
        currentOffer.originationFee,
        currentOffer.originationFeeType
      );

    this.termLoanOfferDetails.disbursedAmount =
      this.accountTransferService.calculateDisbursedAmount(
        currentOffer.amount,
        this.termLoanOfferDetails.originationFeeAmount
      );

    this.termLoanOfferDetails.factorRate =
      currentOffer.interestRate / 100.0 + 1;

    this.termLoanOfferDetails.totalInterest =
      this.accountTransferService.calculateTotalInterest(
        currentOffer.amount,
        currentOffer.interestRate
      );

    this.termLoanOfferDetails.totalRepaymentAmount =
      currentOffer.amount + this.termLoanOfferDetails.totalInterest;

    this.termLoanOfferDetails.totalNumberOfPayments =
      this.accountTransferService.getTotalNumberOfPayments(
        currentOffer.paymentFrequency,
        currentOffer.repaymentTerm
      );

    this.termLoanOfferDetails.paymentAmount =
      this.accountTransferService.calculatePayments(
        currentOffer.amount,
        this.termLoanOfferDetails.totalInterest,
        currentOffer.paymentFrequency,
        currentOffer.repaymentTerm
      );
  }
}

export interface LocAgreementFormComponentData {
  customer: CustomerData;
  loan: LoanData;
  offerBundlesInfo: OfferBundleInfo;
}
