import { NgModule } from "@angular/core";
import { UiModule } from "./ui/ui.module";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { AuthModule } from "./auth/auth.module";
import { ApplicationModule } from "./application/application.module";
import { ConfigModule } from "./config/config.module";
import { ResourceModule } from "./resource/resource.module";
import { QueryModule } from "./query/query.module";
import { NgxModule } from "./ngx/ngx.module";
import { FilterModule } from "./filter/filter.module";
import { SpecialCharacterDirective } from "./special-characters/special-characters.directive";
import { NumberOnlyDirective } from "./directives/numbers-only.directive";
import { DocumentModule } from "./documents/document.module";
import { FeatureFlagModule } from "./feature-flag/feature-flag.module";
import { LoanModule } from "./loan/loan.module";
import { AccountModule } from "./account/account.module";
import { TransactionTypeNamePipe } from "./transaction/transaction-type-name.pipe";
import { PromotionTypeNamePipe } from "./promotion/promotion-type-name.pipe";
import { OfferBundleStatusLabelPipe } from "./offer/pipes/offer-bundle-status-label.pipe";
import { OfferStatusLabelPipe } from "./offer/pipes/offer-status-label.pipe";
import { ProductCodeLabelPipe } from "./product/product-code-label.pipe";
import { AddressPipe } from "./address/address.pipe";
import { RynoHttpClient } from "./http/ryno-http-client";
import { FlexLayoutModule } from "@angular/flex-layout";
import { RouterModule } from "@angular/router";
import { NgChartsModule } from "ng2-charts";
import { CaseModule } from "./case/case.module";
import { NeedsAttentionModelToMessagePipe } from "./needs-attention/needs-attention-model-product-code-to-message.pipe";
import { OfferTermLabelPipe } from "./offer/pipes/offer-term-label-pipe";

@NgModule({
  imports: [
    AuthModule,
    ApplicationModule,
    LoanModule,
    ConfigModule,
    DocumentModule,
    HttpClientModule,
    QueryModule,
    ResourceModule,
    UiModule,
    NgxModule,
    FilterModule,
    FeatureFlagModule,
    AccountModule,
    FlexLayoutModule,
    RouterModule,
    NgChartsModule,
    CaseModule,
  ],
  exports: [
    AuthModule,
    ConfigModule,
    DocumentModule,
    QueryModule,
    ResourceModule,
    UiModule,
    NgxModule,
    SpecialCharacterDirective,
    NumberOnlyDirective,
    FeatureFlagModule,
    AccountModule,
    TransactionTypeNamePipe,
    PromotionTypeNamePipe,
    NeedsAttentionModelToMessagePipe,
    OfferBundleStatusLabelPipe,
    OfferStatusLabelPipe,
    OfferTermLabelPipe,
    ProductCodeLabelPipe,
    AddressPipe,
    ApplicationModule,
    FlexLayoutModule,
    RouterModule,
    NgChartsModule,
    CaseModule,
  ],
  declarations: [
    SpecialCharacterDirective,
    NumberOnlyDirective,
    TransactionTypeNamePipe,
    PromotionTypeNamePipe,
    NeedsAttentionModelToMessagePipe,
    OfferBundleStatusLabelPipe,
    OfferStatusLabelPipe,
    OfferTermLabelPipe,
    ProductCodeLabelPipe,
    AddressPipe,
  ],
  providers: [
    {
      provide: HttpClient,
      useClass: RynoHttpClient,
    },
  ],
})
export class CommonModule {}
