import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AccountPayment } from './account-payment.data';
import { ResourceService } from 'common';
import { AppSettings } from '../../../app.settings';

@Injectable({
  providedIn: 'root'
})
export class AccountPaymentService {

  private _pageSize = 20;

  constructor(
    private http: HttpClient,
    private resourceService: ResourceService,
    private appsettings: AppSettings) { }

  serviceUrl = this.appsettings.loans.url;

  getPayments(loanId: number): Observable<AccountPayment[]> {
    return this.http.get<AccountPayment[]>(`${this.serviceUrl}/api/amortization/${loanId}`);
  }

  get pageSize(): number {
    return this._pageSize;
  }
  set pageSize(value: number) {
    this._pageSize = value;
  }

  downloadCsv(loanId: number) {
    this.resourceService.download(`${this.serviceUrl}/api/amortization/${loanId}/csv`, "upcoming-payments.csv");
  }
}

