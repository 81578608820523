import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { AppSettings } from "projects/portal/src/app.settings";
import { BrandingService, CompanyBranding } from "common";
import { MatMenuTrigger } from "@angular/material/menu";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { CustomerAccountType, CustomerData } from "../../customer/domain/models/customer.model";
import { CustomerFacade } from "../../customer/domain/+state/customer.facade";

@UntilDestroy()
@Component({
  selector: "ifp-user-menu",
  templateUrl: "./user-menu.component.html",
  styleUrls: ["./user-menu.component.scss"],
})
export class UserMenuComponent implements OnInit {
  @ViewChild(MatMenuTrigger) userMenu: MatMenuTrigger;

  isInWebView: boolean = false;
  allowPasswordUpdate: boolean = false;
  brandingData: CompanyBranding;

  constructor(
    private router: Router,
    private appSettings: AppSettings,
    private customerFasade: CustomerFacade,
    private brandingService: BrandingService
  ) {
    if ((window as any)?.flutter_inappwebview) {
      this.isInWebView = true;
    }
  }

  ngOnInit(): void {
    this.initBranding();
    this.customerFasade.getCurrentCustomer$().pipe(untilDestroyed(this)).subscribe(
      (data) => this.setAllowPasswordUpdate(data)
    );
  }

  private initBranding() {
    this.brandingService.getBranding$()
      .pipe(untilDestroyed(this))
      .subscribe((branding) => {
        this.brandingData = branding;
      });
  }

  logout(): void {
    this.router.navigate(["/sign-out"]);
  }

  changePassword(): void {
    if (this.isInWebView) {
      this.userMenu.closeMenu();
      (window as any).flutter_inappwebview.callHandler(
        "onChangePasswordSettingsClicked"
      );
    }
    else {
      window.location.href = this.brandingData?.loginUrl + this.appSettings.auth.changePassword;
    }
  }

  setBiometric(): void {
    if (this.isInWebView) {
      this.userMenu.closeMenu();
      (window as any).flutter_inappwebview.callHandler(
        "onBiometricsSettingsClicked"
      );
    }
  }

  private setAllowPasswordUpdate(data: CustomerData): void {
    this.allowPasswordUpdate = data.accountType === CustomerAccountType.Internal;
  }
}
