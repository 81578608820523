import { Component, Input, OnInit } from "@angular/core";
import { BrandingService, CompanyBranding } from "common";
import { PersonalCompanyFormType } from "../model/personal-company-form-type";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ActivatedRoute, Router } from "@angular/router";

@UntilDestroy()
@Component({
  selector: "ifp-contact-data-navigation",
  templateUrl: "./contact-data-navigation.component.html",
  styleUrls: ["./contact-data-navigation.component.scss"],
})
export class ContactDataNavigationComponent implements OnInit {
  @Input() currentFormType: PersonalCompanyFormType;
  companyBranding: CompanyBranding;
  showCompanyButton: boolean;
  get PersonalCompanyFormType() {
    return PersonalCompanyFormType;
  }

  constructor(
    private brandingService: BrandingService,
    private router: Router,
    private route : ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.parent.data.pipe(untilDestroyed(this)).subscribe((data) => {
      let entities = data?.entities;
      this.showCompanyButton = entities && entities.length > 0;
    });
    this.brandingService
      .getBranding$()
      .pipe(untilDestroyed(this))
      .subscribe((res: CompanyBranding) => {
        this.companyBranding = res;
      });
  }

  click(formType: PersonalCompanyFormType) {
    this.router.navigate([`contact-data/${formType}/change`]);
  }
}
