import { NgModule } from '@angular/core';
import { UserSignOutRoutingModule } from './user-sign-out-routing.module';
import { UserSignOutFormComponent } from './user-sign-out-form/user-sign-out-form.component';
import { CommonModule } from 'common';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    UserSignOutRoutingModule
  ],
  declarations: [
    UserSignOutFormComponent
  ],
})
export class UserSignOutModule { }
