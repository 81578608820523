export enum PromotionType {
  ReAmortization = "re-amortization",
  PayoffInterestReduction = "payoff-interest-reduction",
  DrawFeeReduction = "draw-fee-reduction",
  DrawFeeOverride = "draw-fee-override",
}

export const PromotionTypeLabel = new Map<string, string>([
  [PromotionType.ReAmortization, "Loan reamortization"],
  [PromotionType.PayoffInterestReduction, "Payoff interest rate discount"],
  [PromotionType.DrawFeeReduction, "Draw fee reduce"],
  [PromotionType.DrawFeeOverride, "Draw fee override"],
]);

export enum LinkAccountCondition {
  LinkedAccounts = "linked-accounts",
  LinkingAccounts = "linking-accounts",
}

export interface PromotionInfo
{
  promotionType: PromotionType;
  drawFeeReducement: number;
  interestRateReducement: number;
  promoCode: string;
}