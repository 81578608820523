<div id="application-widget">
  <div style="position: relative; z-index:999;" fxFlex="5" fxLayout="row" fxLayoutAlign="center center"
    class="mat-icon-area">
    <mat-icon class="mat-gray">assignment</mat-icon>
  </div>
  <mat-accordion *ngIf="application">
    <mat-expansion-panel #panel hideToggle class="mat-elevation-z0">
      <mat-expansion-panel-header #panelH (click)="panelH._toggle()" class='header'>
        <div fxLayout="row" fxLayoutAlign="space-between center" class="content">
          <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayout.lt-lg="column" fxLayoutGap.lt-lg="12px"
            fxLayoutAlign.lt-lg="center start" fxFlex="94" fxFlex.lt-lg="90" class="layout padding-left">
            <div fxLayout="column" fxLayoutAlign="center start" style="cursor: pointer;" fxFlex="55" (click)="details()">
              <div>
                <span *ngIf="application?.type === 'Renewal'" class="mat-subheading-2">{{loanRenewal?.productName}} {{maskedLoanRenewalNumber}}</span>
                <span *ngIf="application?.type !== 'Renewal'" class="mat-subheading-2">{{application?.applicationNumber}}</span>
              </div>
              <div style="display: grid;">
                <span *ngIf="application?.litigation" #caseName class="case-name" matTooltipClass="mat-tooltip" [matTooltipDisabled]="disableMatTooltip" [matTooltip]="application?.litigation?.caseName">{{application?.litigation?.caseName}}</span>
              </div>
              <div><span>{{application?.type}}</span></div>
              <span>Created on {{application?.createdOn | date: 'shortDate'}}</span>
            </div>
            <div fxFlex="45">
              <ifp-application-summary [application]="application">
              </ifp-application-summary>
              <button mat-button color="primary" (click)="calendarLink()"
                *ngIf="application.sfAppStatus === status.UnderwritingMissingInfo || application.sfAppStatus === status.UnderwritingCall || application.sfAppStatus === status.Dormant">
                <span>SCHEDULE A CALL</span>
              </button>
              <button mat-button color="primary" (click)="offer()"
                *ngIf="application.sfAppStatus === status.Approved && !panel.expanded">
                <span>SEE OFFERS</span>
              </button>
              <button mat-button color="primary" (click)="link()"
                *ngIf="application.sfAppStatus === status.ApplicationIncomplete && !isBankLinked && !panel.expanded">
                <span>LINK BANK</span>
              </button>
            </div>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="end center" fxFlex="8">
            <div fxFlexFill>
              <button mat-icon-button [matMenuTriggerFor]="actionsMenu" class="secondary-text more-actions-menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #actionsMenu="matMenu">
                <button mat-menu-item (click)="panelH._toggle()" *ngIf="!panel.expanded">
                  <mat-icon>expand_more</mat-icon> <span>Expand</span>
                </button>
                <button mat-menu-item (click)="panelH._toggle()" *ngIf="panel.expanded">
                  <mat-icon>expand_less</mat-icon> <span>Collapse</span>
                </button>
                <button mat-menu-item (click)="details()">
                  <mat-icon>description</mat-icon> <span>Details</span>
                </button>
                <button mat-menu-item (click)="link()" *ngIf="application.sfAppStatus === status.ApplicationIncomplete">
                  <mat-icon>link</mat-icon> <span>Link bank</span>
                </button>
                <button mat-menu-item (click)="calendarLink()"
                  *ngIf="application.sfAppStatus === status.UnderwritingMissingInfo || application.sfAppStatus === status.UnderwritingCall || application.sfAppStatus === status.Dormant">
                  <mat-icon>phone_in_talk</mat-icon> <span>Schedule a call</span>
                </button>
                <button mat-menu-item (click)="offer()" *ngIf="application.sfAppStatus === status.Approved">
                  <mat-icon>thumb_up</mat-icon> <span>See offers</span>
                </button>
                <button mat-menu-item matTooltip="Upload file" (click)="upload()">
                  <mat-icon>note_add</mat-icon> <span>Upload file</span>
                </button>
              </mat-menu>
            </div>
          </div>
        </div>
      </mat-expansion-panel-header>
      <ifp-application-status-stepper [application]="application"></ifp-application-status-stepper>
    </mat-expansion-panel>
  </mat-accordion>
</div>
