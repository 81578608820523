<div [ngClass.xl]="'form-field-lg'" [ngClass.lg]="'form-field-lg'" [ngClass.lt-lg]="'form-field-lg'"
  [ngClass.xs]="'form-field-xs'">

  <div *ngFor="let institution of bankLinkingData.financialInstitutions" (click)="relink(institution)"
    class="bank-account-container hover" fxLayout="row" fxLayoutAlign="space-between center">

    <div fxLayout="row" fxLayoutGap="12px">

      <div>
        <img *ngIf="logoExists(institution?.institutionId, institution?.institutionName)" matListAvatar
          [src]="getLogo(institution?.institutionId, institution?.institutionName)" alt="Bank logo">
        <div *ngIf="!logoExists(institution?.institutionId, institution?.institutionName)" matListAvatar
          class="avatar circle-wrap" fxLayoutAlign="center center">
          <mat-icon>account_balance</mat-icon>
        </div>
      </div>

      <div fxLayout="column" fxLayoutAlign="center start">
        <div>
          {{institution?.institutionName}}
        </div>
        <div *ngIf="bankLinkingData?.loan" class="secondary-text">
          For {{bankLinkingData?.loan?.productCode}} {{bankLinkingData?.loan?.loanNumber}}
        </div>
      </div>

    </div>

  </div>

</div>