import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { AppBarTitleService } from 'common';

@Component({
  selector: 'ifp-insurance-declined',
  templateUrl: './insurance-declined.component.html',
  styleUrls: ['./insurance-declined.component.scss']
})
export class InsuranceDeclinedComponent implements OnInit {

  constructor(private appBarTitleService: AppBarTitleService) { }

  ngOnInit() {
    this.appBarTitleService.title = 'Unable to provide a quote';
  }

}
