import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { DisplayAllOffersData } from "../../models/display-all-offers-data.model";
import { LinkBankData } from "../../models/link-bank-data.model";
import { OpenDocumentsData } from "../../models/open-documents-data.model";
import { ShowDisclosuresData } from "../../models/show-disclosures-data.model";
import { OfferActionIds } from "../../models/offer-action-ids.model";

@Injectable({
  providedIn: "root",
})
export class OfferActionsInternalService {
  private displayAllOffersSubject: Subject<DisplayAllOffersData>;
  displayAllOffers$: Observable<DisplayAllOffersData>;

  private showDisclosuresSubject: Subject<ShowDisclosuresData>;
  showDisclosures$: Observable<ShowDisclosuresData>;

  private openDocumentsDetailsSubject: Subject<OpenDocumentsData>;
  openDocumentsDetails$: Observable<OpenDocumentsData>;

  private linkBankSubject: Subject<LinkBankData>;
  linkBank$: Observable<LinkBankData>;

  private navigateToOffersSummarySubject: Subject<number>;
  navigateToOffersSummary$: Observable<number>;

  private navigateToOfferDetailsSubject: Subject<OfferActionIds>;
  navigateToOfferDetails$: Observable<OfferActionIds>;

  constructor() {
    this.displayAllOffersSubject = new Subject();
    this.displayAllOffers$ = this.displayAllOffersSubject.asObservable();

    this.showDisclosuresSubject = new Subject();
    this.showDisclosures$ = this.showDisclosuresSubject.asObservable();

    this.openDocumentsDetailsSubject = new Subject();
    this.openDocumentsDetails$ =
      this.openDocumentsDetailsSubject.asObservable();

    this.linkBankSubject = new Subject();
    this.linkBank$ = this.linkBankSubject.asObservable();

    this.navigateToOffersSummarySubject = new Subject();
    this.navigateToOffersSummary$ = this.navigateToOffersSummarySubject.asObservable();

    this.navigateToOfferDetailsSubject = new Subject();
    this.navigateToOfferDetails$ = this.navigateToOfferDetailsSubject.asObservable();
  }

  displayAllOffers(data: DisplayAllOffersData): void {
    this.displayAllOffersSubject.next(data);
  }

  showDisclosures(data: ShowDisclosuresData): void {
    this.showDisclosuresSubject.next(data);
  }

  linkBank(entityId: number): void {
    this.linkBankSubject.next({ entityId });
  }

  openDocumentsDetails(applicationId: number, offerBundleId: number): void {
    this.openDocumentsDetailsSubject.next({applicationId, offerBundleId});
  }

  navigateToOffersSummary(applicationId: number): void {
    this.navigateToOffersSummarySubject.next(applicationId);
  }

  navigateToOfferDetails(data: OfferActionIds): void {
    this.navigateToOfferDetailsSubject.next(data);
  }
}
