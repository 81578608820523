import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DocumentFilters, DocumentQueryParams, DocumentCategory, DocumentCategoryLabel, EnumHelper } from 'common';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'ifp-document-list-filter-dialog',
  templateUrl: './document-list-filter-dialog.component.html',
  styleUrls: ['./document-list-filter-dialog.component.scss']
})
export class DocumentListFilterDialogComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;

  form: UntypedFormGroup;
  filters: DocumentFilters;
  categoryOptions = EnumHelper.getMappedNamesAndValues(DocumentCategory, DocumentCategoryLabel);

  constructor(public dialogRef: MatDialogRef<DocumentListFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder
  ) {
    this._unsubscribeAll = new Subject();
    this.filters = new DocumentFilters(data.query);
    this.form = this.formBuilder.group({
      text: this.data.query.text || undefined,
      fromDate: new UntypedFormControl(this.data.query.fromDate ? new Date(this.data.query.fromDate) : undefined, { validators: null, updateOn: 'blur' }),
      toDate: new UntypedFormControl(this.data.query.toDate ? new Date(this.data.query.toDate) : undefined, { validators: null, updateOn: 'blur' }),
      category: this.data.query.category ? this.data.query.category : undefined
    });
  }

  static show(dialog: MatDialog, query: DocumentQueryParams, documents: any[]): Observable<any> {
    return dialog.open(DocumentListFilterDialogComponent, {
      data: { query, documents },
      closeOnNavigation: true,
    }).afterClosed();
  }

  ngOnInit(): void {
    this.form.valueChanges.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(data => {
        this.filters = new DocumentFilters(data);
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}
