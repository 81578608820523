
import { NeedsAttentionInput } from "../needs-attention/needs-attention.model";
import { EntityExData } from "./entity.model";

export class EntityHelper {
  public static getNeedsAttentionMessage(entity: EntityExData): NeedsAttentionInput {
    return {
      firstName: entity?.advisorFirstName,
      lastName: entity?.advisorLastName,
      email: entity?.advisorEmail,
      phone: entity?.advisorPhone,
      productName: ''
    };
  }
}