import { Component, Input, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { AppPageService, DocumentCategory, OAuthService, parseNumber } from "common";
import { AppSettings } from "projects/portal/src/app.settings";
import { CustomerData } from "../../customer/domain/models/customer.model";
import { NotificationDialogComponent } from "../../shared/notification-dialog/notification-dialog.component";
import { LitigationService } from "../litigation.service";
import { Uppy } from "@uppy/core";
import { DashboardOptions } from "@uppy/dashboard";
import {
  getUppyDashboardOptions,
  getUppyInstance,
  useUppyXHRUploadPlugin,
} from "./new-case-form-uppy-config";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { EntityFacade } from "../../entity/domain/+state/entity.facade";

@UntilDestroy()
@Component({
  selector: "ifp-new-case-form",
  templateUrl: "./new-case-form.component.html",
  styleUrls: ["./new-case-form.component.scss"],
})
export class NewCaseFormComponent implements OnInit {
  private customer: CustomerData;
  private entityId: number;
  private litigationId: number;
  private notificationDialogRef: MatDialogRef<NotificationDialogComponent>;

  @Input()
  form: UntypedFormGroup;

  valid: boolean = true;
  applicationId: number;

  uppy: Uppy;
  uppyDashboardOptions: DashboardOptions;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private authService: OAuthService,
    private dialog: MatDialog,
    private settings: AppSettings,
    private appPageService: AppPageService,
    private litigationService: LitigationService,
    private entityFacade: EntityFacade,
    private router: Router
  ) {
    this.form = this.formBuilder.group({
      caseName: new UntypedFormControl(undefined, {
        validators: [Validators.required, Validators.maxLength(80)],
      }),
      caseNumber: new UntypedFormControl(undefined, {
        validators: Validators.maxLength(255),
      }),
      requestedAmount: new UntypedFormControl(undefined, {
        validators: [Validators.required, Validators.min(1)],
      }),
    });
  }

  ngOnInit(): void {
    this.initEntity();
    this.route.data.pipe(untilDestroyed(this)).subscribe((res) => {
      this.customer = res.customer;
    });
    this.route.queryParams
      .pipe(untilDestroyed(this))
      .subscribe((params) => this.initParams(params));

    this.setUppyConfig();
  }

  private initEntity() {
    this.entityFacade
      .getEntity$(parseNumber(this.route.snapshot.queryParams.companyId))
      .pipe(untilDestroyed(this))
      .subscribe((entity) => {
        this.entityId = entity.id;
      });
  }

  private initParams(params: Params) {
    const caseName = params?.["caseName"];
    const caseNumber = params?.["caseNumber"];
    this.litigationId = params?.["litigationId"];

    const caseNameControl = this.form.get("caseName");
    const caseNumberControl = this.form.get("caseNumber");

    if (caseName) {
      caseNameControl.patchValue(caseName);
      caseNameControl.markAsTouched();
      if (caseNameControl.valid) {
        caseNameControl.disable();
      }
    }
    if (caseNumber) {
      caseNumberControl.patchValue(caseNumber);
      caseNumberControl.markAsTouched();
      if (caseNumberControl.valid) {
        caseNumberControl.disable();
      }
    }
  }

  private setUppyConfig(): void {
    this.uppy = getUppyInstance();
    this.uppyDashboardOptions = getUppyDashboardOptions();

    this.uppy.on("complete", (result) => {
      if (result.failed && result.failed.length > 0) {
        return;
      }

      this.notificationDialogRef = this.dialog.open(
        NotificationDialogComponent,
        {
          closeOnNavigation: true,
          width: "500px",
        }
      );
      this.notificationDialogRef.componentInstance.title =
        "Thanks for your submission";
      this.notificationDialogRef.componentInstance.message =
        "Your case financing application has been created. We will be in touch soon.";

      if (!this.appPageService.backToIfPossible(true))
        this.router.navigate(["dashboard"]);
    });
  }

  submit() {
    if (!this.applicationId) {
      this.litigationService
        .submit({
          LitigationId: this.litigationId,
          CaseName: this.form.controls.caseName.value,
          CaseNumber: this.form.controls.caseNumber.value,
          RequestedAmount: this.form.controls.requestedAmount.value,
          EntityId: this.entityId,
          CustomerIds: [this.customer?.id],
        })
        .subscribe((res) => {
          if (res) {
            this.applicationId = res?.id;
            this.form.disable();
            const endpoint = `${this.settings.applications.url}/api/application/${this.applicationId}/documents/${DocumentCategory.Application}`;
            useUppyXHRUploadPlugin(
              this.uppy,
              endpoint,
              this.authService.accessToken
            );
            this.upload();
          }
        });
    } else this.upload();
  }

  private upload() {
    this.uppy.getState()?.error ? this.uppy.retryAll() : this.uppy.upload();
  }

  cancel() {
    this.appPageService.back();
  }
}
