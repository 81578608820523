import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { ResourceService } from "common";
import { AppSettings } from "projects/portal/src/app.settings";
import { AcceptApplicationAgreementResponse } from "./application-agreement.data";
import { AcceptAgreementRequest, AgreementDto } from "../agreement/agreement.data";

@Injectable({
  providedIn: "root",
})
export class ApplicationAgreementService {
  private readonly baseUrl = `${this.settings.customers.url}/api/application-agreement`;

  constructor(
    private http: HttpClient,
    private resourceService: ResourceService,
    private settings: AppSettings,
  ) {}

  download(applicationId: number) {
    const url = `${this.baseUrl}/${applicationId}/pdf`;
    this.resourceService.download(url);
  }

  getAgreement(
    applicationId: number
  ): Observable<AgreementDto> {

    const url = `${this.baseUrl}/${applicationId}/html`;
    return this.http.get<AgreementDto>(url);
  }

  submit(applicationId: number, request: AcceptAgreementRequest): Observable<AcceptApplicationAgreementResponse> {
    const url = `${this.baseUrl}/${applicationId}`;
    return this.http.post<AcceptApplicationAgreementResponse>(url, request);
  }
}
