import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  AppDetailLayout2Component,
  AppPageComponent,
  AppBarTitleComponent,
  AppBarActionsComponent,
  AppBarComponent
} from 'common';
import { AccountTransactionTableCardComponent } from './account-transaction-table-card/account-transaction-table-card.component';
import { CustomerCurrentResolver } from '../../customer/infrastructure/customer.resolver';
import { AccountTransactionsResolver, TransactionResolver } from './account-transactions.resolver';
import { LoanWithRenewalsResolver, LoanFileTemplatesResolver, LoanResolver, LoansResolver } from '../../loan/infrastructure/loan.resolver';
import { AccountPaymentsResolver } from '../account-payment/account-payments.resolver';
import { SidenavContentComponent } from '../../shared/sidenav-content/sidenav-content.component';
import { TopToolbarComponent } from '../../shared/top-toolbar/top-toolbar.component';
import { AccountTransactionDetailComponent } from './account-transaction-detail/account-transaction-detail.component';
import { appPageComponentDataDetail } from '../../app.data';
import { NavbarComponent } from '../../shared/navbar/navbar.component';
import { BottomToolbarComponent } from '../../shared/bottom-toolbar/bottom-toolbar.component';
import { EntitiesResolver } from '../../entity/infrastructure/entity.resolver';
import { OAuthGuard } from '../../../guards/oauth.guard';
import { LoanPendingGuard } from '../../loan/infrastructure/loan.guard';

const appPageDataDetail = appPageComponentDataDetail();

const routes: Routes = [
  {
    path: 'account/:accountId/transactions',
    canActivate: [OAuthGuard],
    component: AppDetailLayout2Component,
    resolve: {
      customer: CustomerCurrentResolver,
      loan: LoanResolver,
      loanFileTemplates: LoanFileTemplatesResolver,
      loans: LoansResolver,
      accountPayment: AccountPaymentsResolver,
      entities: EntitiesResolver
    },
    children: [
      {
        path: '',
        outlet: 'sidenav-content',
        component: SidenavContentComponent
      },
      {
        path: '',
        component: TopToolbarComponent,
        outlet: 'app-top-toolbar'
      },
      {
        path: '',
        component: NavbarComponent,
        outlet: 'app-navbar'
      },
      {
        path: '',
        component: BottomToolbarComponent,
        outlet: 'app-bottom-toolbar'
      },
      {
        path: '',
        component: AccountTransactionTableCardComponent,
        resolve: {
          accountTransactionQueryResult: AccountTransactionsResolver
        },
        canActivate: [LoanPendingGuard],
        runGuardsAndResolvers: "paramsOrQueryParamsChange"
      }
    ]
  },
  {
    path: 'account/:accountId/transaction/:transactionId',
    canActivate: [OAuthGuard],
    component: AppPageComponent,
    data: appPageDataDetail,
    children: [
      {
        path: '',
        component: AppBarComponent,
        outlet: 'app-bar',
        children: [
          {
            path: '',
            outlet: 'title',
            component: AppBarTitleComponent,
          },
          {
            path: '',
            outlet: 'right',
            component: AppBarActionsComponent,
          }
        ]
      },
      {
        path: '',
        component: AccountTransactionDetailComponent,
        runGuardsAndResolvers: "paramsOrQueryParamsChange",
        resolve: {
          loan: LoanWithRenewalsResolver,
          transaction: TransactionResolver
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountTransactionRoutingModule { }
