import { MatDialog } from "@angular/material/dialog";
import { NeedsAttentionInput } from "./needs-attention.model";
import { DialogActionComponent } from "../ui/dialog/action/dialog-action.component";
import { ButtonColor } from "../ui/dialog/action/dialog-action-data.model";
import { ProductCodeLabel } from "../product/product.model";

export class NeedsAttentionHelper {
  public static openNeedsAttentionDialog(dialog: MatDialog, input: NeedsAttentionInput, productCode: string = null): void {
    const body = this.getNeedsAttentionMessageWithProductCode(input, productCode, true);

    DialogActionComponent.show(dialog, {
      title: 'Attention needed',
      body: body,
      buttons: [
        { label: "OK", buttonColor: ButtonColor.Primary, action: true },
      ],
    })
  }

  public static getNeedsAttentionMessageWithProductCode(input: NeedsAttentionInput, productCode: string = undefined, isHtml: boolean = false): string {
    if (!input) {
      return undefined;
    }
    return this.getNeedsAttentionMessage(
      {
        ...input,
        productName: productCode ? ProductCodeLabel.get(productCode) : input.productName
      },
      isHtml);
  }

  public static getNeedsAttentionMessage(input: NeedsAttentionInput, isHtml: boolean = false): string {
    var emailMsg = isHtml ? `<a href="mailto:${input?.email}">${input?.email}</a>` : input?.email;
    return `In order to access your ${input?.productName?.toLowerCase()}, please contact your Business Advisor ${input?.firstName} ${input?.lastName} at ${emailMsg} or ${input?.phone}`
  }
}