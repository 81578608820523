<ifc-app-loader></ifc-app-loader>

<router-outlet name="app-sidenav"></router-outlet>

<mat-sidenav-container>
  <mat-sidenav #sidenav [(opened)]="sidenavOpened" [mode]="'side'" class="mat-elevation-z1">
    <router-outlet name="sidenav-content"></router-outlet>
  </mat-sidenav>
  <mat-sidenav-content>
    <ng-container *ngTemplateOutlet="main"></ng-container>
  </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #main>
  <main>
    <header *ngIf="sidenavOpened" [class.mat-elevation-z3]="contentScrolled">
      <router-outlet name="app-top-toolbar"></router-outlet>
    </header>
    <header *ngIf="!sidenavOpened" [class.mat-elevation-z3]="contentScrolled">
      <router-outlet name="app-navbar"></router-outlet>
    </header>
    <div>
      <router-outlet></router-outlet>
    </div>
    <footer *ngIf="!sidenavOpened">
      <router-outlet name="app-bottom-toolbar"></router-outlet>
    </footer>
  </main>
</ng-template>
