import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
} from "@angular/common/http";
import { Observable, of } from "rxjs";
import { filter, tap } from "rxjs/operators";
import { CacheService } from "./cache.service";

@Injectable({
  providedIn: "root",
})
export class CacheInterceptor implements HttpInterceptor {
  constructor(private _cache: CacheService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.method === "GET") {
      const cachedResponse = this._cache.get(request);
      return cachedResponse
        ? of(cachedResponse)
        : next.handle(request).pipe(
            filter((event) => event instanceof HttpResponse),
            tap((response: HttpResponse<any>) => {
              this._cache.put(request, response);
            })
          );
    } else if (
      request.method === "POST" ||
      request.method === "PUT" ||
      request.method === "PATCH" ||
      request.method === "DELETE"
    ) {
      this._cache.invalidate(request);
    }
    return next.handle(request);
  }
}
