import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CompanyBranding } from '../../../branding/branding.model';
import { OAuthService } from '../../../auth/oauth/oauth.service';
import { BrandingService } from '../../../../core/branding/branding.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'ifc-app-logo',
  templateUrl: './app-logo.component.html',
  styleUrls: ['./app-logo.component.scss']
})
export class AppLogoComponent implements OnInit {
  @Input() logoClass: string;
  @Input() showDefaultLogo: boolean;

  logo: any;
  title: string;
  rootSiteUrl: string;

  constructor(
    private authService: OAuthService,
    private sanitizer: DomSanitizer,
    private brandingService: BrandingService) { }

  ngOnInit() {
    this.initData();
  }

  private initData() {
    this.brandingService.getBranding$()
      .pipe(untilDestroyed(this))
      .subscribe((branding: CompanyBranding) => {
        if (!this.showDefaultLogo) {
          this.logo = this.sanitizer.bypassSecurityTrustResourceUrl(branding.logo);
        }
        this.title = branding.logoTitle ?? 'Idea Financial';
        this.rootSiteUrl = this.authService.authenticated ? '/home' : branding.url;
      });
  }
}
