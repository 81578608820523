import { Injectable } from "@angular/core";
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpParams
} from "@angular/common/http";
import { Observable } from "rxjs";
import { ObjectHelper } from "../helpers/object.helper";

@Injectable({
    providedIn: 'root'
})
export class ConvertParamsToUrlInterceptor implements HttpInterceptor {
    constructor() { }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (!req.params)
            return next.handle(req);

        let params = [];
        req.params.keys().forEach(key => {
            const value = req.params.getAll(key);
            if (value !== null && value !== undefined && value.length > 0)
            {
                let valueFilled: string[] = [];
                value.forEach(k => { 
                    if(k !== null && k !== undefined && k !== "" && k !== "null" && k !== "undefined")
                        valueFilled.push(k);
                })
                if(valueFilled.length > 0)
                    params[key] = valueFilled;

            }
        });

        let paramString = ObjectHelper.getQueryParams(params);

        req = req.clone({
            url: req.url + (paramString != null && paramString != '' ? '?' + paramString : ''),
            params: new HttpParams()
        });

        return next.handle(req);
    }
}
