import { Component } from '@angular/core';

@Component({
  selector: 'ifc-app-card-one-column',
  templateUrl: './app-card-one-column.component.html',
  styleUrls: ['./app-card-one-column.component.scss']
})
export class AppCardOneColumnComponent {

  constructor() { }

}
