import { Component, OnInit } from '@angular/core';
import { CustomerCheckoutService } from '../customer-checkout.service';
import { Router } from '@angular/router';

@Component({
  selector: 'ifp-customer-kba-result',
  templateUrl: './customer-kba-result.component.html',
  styleUrls: ['./customer-kba-result.component.scss']
})
export class CustomerKbaResultComponent implements OnInit {

  constructor(
    private customerCheckoutService: CustomerCheckoutService,
    private router: Router) { }

  close() {
    this.closeEnabled = false;
    this.router.navigate(['sign-out']);
  }

  closeEnabled = true;

  ngOnInit() {
    if (this.customerCheckoutService.verifyAnswersError)
      this.message = this.customerCheckoutService.verifyAnswersError.error_description;
  }

  message = '';
}
