<form [formGroup]="form" ngClass.xs="form-field-sm" ngClass.gt-xs="form-field-md">

  <div formArrayName="emails" *ngFor="let item of form.get('emails')['controls']; let emailIndex = index;"
    fxLayoutAlign="start start">

    <div [formGroupName]="emailIndex">
      <mat-form-field appearance="outline" ngClass.xs="form-field-sm" ngClass.gt-xs="form-field-md">
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" type="email" (input)="emailChanged($event.target.value, emailIndex)">
      </mat-form-field>
    </div>
  </div>

  <div fxLayout="column" fxLayoutAlign="start end">
    <button mat-button color="primary" type="button" (click)="send()">REFER VIA EMAIL</button>
  </div>

</form>
