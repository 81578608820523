import { NgModule } from "@angular/core";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { FlexLayoutModule } from "@angular/flex-layout";
import {
  CommonModule,
  OAuthInterceptor,
  AppLoaderInterceptor,
  ConvertParamsToUrlInterceptor,
  CacheInterceptor,
  RefreshTokenInterceptor,
  OAuthService,
  ErrorsModule,
} from "common";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { DashboardModule } from "./dashboard/dashboard.module";
import { AccountModule } from "./account/account.module";
import { UserModule } from "./user/user.module";
import { CustomerModule } from "./customer/customer.module";
import { DocumentModule } from "./document/document.module";
import { AppBarContactComponent } from "./app-bar-contact/app-bar-contact.component";
import { AppBarTitleComponent } from "./app-bar-title/app-bar-title.component";
import { BankAccountModule } from "./bank-account/bank-account.module";
import { AboutPageModule } from "./about/about-page.module";
import { ApplicationModule } from "./application/application.module";
import { ReferralCodeModule } from "./referral-code/referral-code.module";
import { InsuranceQuoteModule } from "./insurance/insurance.module";
import { RenewalEligibleModule } from "./renewal-eligible/renewal-elegible.module";
import { CampaignInterceptor } from "../interceptors/campaign.interceptor";
import { EntityDocumentsModule } from "./entity-documents/entity-documents.module";
import { OfferModule } from "./offer/offer.module";
import { MobileModule } from "./mobile/mobile.module";
import { MfaModule } from "./mfa/mfa.module";
import { ContactDataModule } from "./contact-data/contact-data.module";
import { OidcService } from "./user/oidc/oidc.service";
import { CoreModule } from "./core/core.module";
import { LocAgreementModule } from "./loc-agreement/loc-agreement.module";
import { ApplicationAgreementModule } from "./application-agreement/application-agreement.module";
import { LitigationPayoffNotesDialogComponent } from './loan/litigation-payoff-notes-dialog/litigation-payoff-notes-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    AppBarContactComponent,
    AppBarTitleComponent,
    LitigationPayoffNotesDialogComponent
  ],
  imports: [
    AppRoutingModule,
    FlexLayoutModule,
    CommonModule,
    LocAgreementModule,
    ApplicationAgreementModule,
    DashboardModule,
    AccountModule,
    UserModule,
    CustomerModule,
    DocumentModule,
    BankAccountModule,
    AboutPageModule,
    ApplicationModule,
    ReferralCodeModule,
    InsuranceQuoteModule,
    RenewalEligibleModule,
    EntityDocumentsModule,
    OfferModule,
    MobileModule,
    CoreModule,
    MfaModule,
    ContactDataModule,
    ErrorsModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: OAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AppLoaderInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ConvertParamsToUrlInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: CampaignInterceptor, multi: true },
    //{ provide: HTTP_INTERCEPTORS, useClass: AvoidConcurrentHttpCallsInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    {
      provide: OAuthService,
      useClass: OidcService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
