import { Component } from '@angular/core';
import { BankingService } from '../banking.service';
@Component({
  selector: 'ifp-bank-account-oauth',
  templateUrl: './bank-account-oauth.component.html'
})
export class BankAccountOauthComponent {

  constructor(private bankingService: BankingService) {

    this.bankingService.detectOAuthRedirect();
  }
}
