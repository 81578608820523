import { Injectable } from "@angular/core";
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";
import { map, switchMap, take } from "rxjs/operators";
import { InsuranceType, QuoteData } from "./insurance.model";
import { InsuranceService } from "./insurance.service";
import { RouteHelper, parseNumber } from "common";
import { EntityFacade } from "../entity/domain/+state/entity.facade";

@Injectable({
  providedIn: "root",
})
export class QuoteResolver implements Resolve<QuoteData> {
  constructor(
    private insuranceService: InsuranceService,
    private entityFacade: EntityFacade
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): QuoteData | Observable<QuoteData> | Promise<QuoteData> {
    const companyId = parseNumber(RouteHelper.getQueryParamValueByName(route, "companyId"));
    return this.entityFacade.getEntity$(companyId).pipe(
      take(1),
      switchMap((entity) =>
        this.insuranceService
          .getQuote(entity.ein, InsuranceType["general-liability"])
          .pipe(map((res) => (res?.quotes?.length ? res.quotes[0] : null)))
      )
    );
  }
}
