import { Component } from "@angular/core";
import { ErrorImgType } from "../../enums/error-img-type.enum";

@Component({
  selector: "ifc-page-not-found",
  templateUrl: "./page-not-found.component.html",
})
export class PageNotFoundComponent {
  ErrorImgType = ErrorImgType;
}
