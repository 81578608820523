import { Component, Inject, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { MfaDataService } from "../../services/mfa-data.service";
import {
  UpdatePhoneNumberDialogData,
  UpdatePhoneNumberDialogType,
} from "../../const/models/mfa.model";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable } from "rxjs";
import { BrandingService } from "../../../../../../core/branding/branding.service";
import { CompanyBranding } from "../../../../../branding/branding.model";

@UntilDestroy()
@Component({
  selector: "ifc-update-phone-number-dialog",
  templateUrl: "./update-phone-number-dialog.component.html",
  styleUrls: ["./update-phone-number-dialog.component.scss"],
})
export class UpdatePhoneNumberDialogComponent implements OnInit {
  form: UntypedFormGroup;
  servicePhoneNumber: string;
  readonly DialogType = UpdatePhoneNumberDialogType;

  constructor(
    public dialogRef: MatDialogRef<UpdatePhoneNumberDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UpdatePhoneNumberDialogData,
    private formBuilder: UntypedFormBuilder,
    private mfaDataService: MfaDataService,
    private brandingService: BrandingService,
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.initServicePhoneNumber();
  }

  private initForm() {
    this.form = this.formBuilder.group({
      phoneNumber: [undefined, Validators.required],
    });
  }

  private initServicePhoneNumber() {
    this.brandingService.getBranding$()
      .pipe(untilDestroyed(this))
      .subscribe((branding: CompanyBranding) => {
        this.servicePhoneNumber = branding.phone;
      })
  }

  update() {
    if (this.form.invalid) {
      return;
    }

    this.mfaDataService
      .changePhoneToVerify(
        this.data.mfaToken,
        this.form.value.phoneNumber,
        this.data.config
      )
      .subscribe(() => {
        this.dialogRef.close(true);
      });
  }

  static show(
    dialog: MatDialog,
    data: UpdatePhoneNumberDialogData
  ): Observable<any> {
    return dialog
      .open(UpdatePhoneNumberDialogComponent, {
        width: "500px",
        closeOnNavigation: true,
        autoFocus: false,
        data,
      })
      .afterClosed();
  }
}
