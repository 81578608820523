import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CheckoutRequirementStatusLabelPipe } from "./pipes/checkout-requirement-status-label.pipe";
import { FeeTypeLabelPipe } from "./pipes/fee-type-label.pipe";
import { PaymentFrequencyLabelPipe } from "./pipes/payment-frequency-label.pipe";
import { FeePipe } from "./pipes/fee.pipe";

@NgModule({
  imports: [CommonModule],
  declarations: [
    CheckoutRequirementStatusLabelPipe,
    PaymentFrequencyLabelPipe,
    FeeTypeLabelPipe,
    FeePipe
  ],
  exports: [
    CheckoutRequirementStatusLabelPipe,
    PaymentFrequencyLabelPipe,
    FeeTypeLabelPipe,
    FeePipe
  ],
})
export class ApplicationModule {}
