import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CacheHeaders } from "common";
import { ApplicationData } from "projects/backoffice/src/app/application/application.model";
import { Observable } from "rxjs";
import { AppSettings } from "../../app.settings";
import { FundCaseCreateData } from "./litigation.model";

@Injectable({
    providedIn: 'root'
})
export class LitigationService
{
    constructor(
        private http: HttpClient,
        private settings: AppSettings
    ) {
    }

    submit(caseData: FundCaseCreateData) : Observable<ApplicationData>
    {
      const url = `${this.settings.applications.url}/api/litigations/new-case`;

      return this.http.put<ApplicationData>(url, caseData, {
        headers: {
          [CacheHeaders.Invalidate]: [
            `${this.settings.applications.url}/api/application`
          ]
        }
      });
    }
}
