import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { RenewalService } from "../../renewal/renewal.service";
import { ApplicationStage, RenewalEligibilityDto } from "common";
import { RenewalBaseGuard } from "./renewal-base.guard";


@Injectable({
    providedIn: "root"
})
export class RenewalEligibleGuard extends RenewalBaseGuard {

    constructor(router: Router, renewalService: RenewalService) {
        super(router, renewalService);
    }

    validate(eligibile: RenewalEligibilityDto): boolean {
        if (eligibile && (!eligibile.optedIn || !eligibile.renewalApplicationId)) {
            this.renewalService.renewalEligibilityChanged.next(eligibile);
            return true;
        }
        else {
            this.router.navigate(['home']);
            return false;
        }
    }
}
