import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { OfferBundleStatus } from "common";
import { OfferSelectButtonStatus } from "./offer-select-button-status.enum";
import { OffeSelectButtonFacade } from "./offer-select-button.facade";

@Component({
  selector: "ifp-offer-select",
  templateUrl: "./offer-select-button.component.html",
  styleUrls: ["./offer-select-button.component.scss"],
  providers: [OffeSelectButtonFacade],
})
export class OfferSelectButtonComponent implements OnChanges {
  @Input()
  applicationId: number;

  @Input()
  offerBundleId: number;

  @Input()
  isOfferSelectable: boolean;

  @Input()
  offerBundleStatus: OfferBundleStatus;

  @Output()
  offerSelect: EventEmitter<number> = new EventEmitter();

  actionStatus: OfferSelectButtonStatus;
  actionStatusEnum = OfferSelectButtonStatus;

  constructor(private facade: OffeSelectButtonFacade) {}

  ngOnChanges(): void {
    this.actionStatus = this.initActionStatus(this.offerBundleStatus);
  }

  private initActionStatus(offerBundleStatus: OfferBundleStatus) {
    if (offerBundleStatus === OfferBundleStatus.Accepted) {
      return OfferSelectButtonStatus.Selected;
    }
    return OfferSelectButtonStatus.Select;
  }

  onClickSelectOffer(): void {
    if(this.actionStatus != OfferSelectButtonStatus.Select) {
      return;
    }

    this.actionStatus = OfferSelectButtonStatus.Selecting;
    this.facade
      .selectOfferBundle(this.offerBundleId)
      .subscribe((result: boolean) => {
        if (result) {
          this.actionStatus = OfferSelectButtonStatus.Selected;
          this.offerSelect.emit(this.offerBundleId);
          return;
        }
        this.actionStatus = OfferSelectButtonStatus.Select;
      });
  }
}
