import { Inject, NgModule } from "@angular/core";
import { LayoutGapStyleBuilder } from "@angular/flex-layout";
import { CustomLayoutGapStyleBuilder } from "./flex-layout/custom-layout-gap-style-builder.service";
import { ToastrModule } from "ngx-toastr";
import { getGlobalConfig } from "./message/config/message.config";
import { MaskPipe, NgxMaskModule } from "ngx-mask";
import { CurrencyPipe, DOCUMENT, DatePipe, DecimalPipe, PercentPipe } from "@angular/common";
import { SplashScreenService } from "../lib/splash-screen/splash-screen.service";
import { AppPageService } from "../lib/ui/app/app-page/app-page.service";
import { sentryProviders } from "./static/sentry";
import { initAppVersion } from "./static/init";
import { CookieService } from 'ngx-cookie-service';
import { EnumValueLabelPipe } from "../lib/ui/pipes/enum-label.pipe";
import { OfferTermLabelPipe } from "../lib/offer/pipes/offer-term-label-pipe";

@NgModule({
  imports: [ToastrModule.forRoot(getGlobalConfig()), NgxMaskModule.forRoot()],
  providers: [
    ...sentryProviders,
    {
      provide: LayoutGapStyleBuilder,
      useClass: CustomLayoutGapStyleBuilder,
    },
    SplashScreenService,
    MaskPipe,
    DatePipe,
    CookieService,
    CurrencyPipe,
    PercentPipe,
    DecimalPipe,
    EnumValueLabelPipe,
    OfferTermLabelPipe
  ],
})
export class CoreModule {
  constructor(
    @Inject(DOCUMENT) document: Document,
    splashScreenService: SplashScreenService,
    appPageService: AppPageService
  ) {
    splashScreenService.init();
    appPageService.initUrlsHistory();
    initAppVersion(document);
  }
}
