import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AppPageComponent, AppBarComponent, AppFooterComponent } from "common";
import { UserSignOutFormComponent } from "./user-sign-out-form/user-sign-out-form.component";
import { AppBarContactComponent } from "../../app-bar-contact/app-bar-contact.component";
import { FlutterEventGuard } from "../guards/flutter-event.guard";

const routes: Routes = [
  {
    path: "sign-out",
    component: AppPageComponent,
    canActivate: [FlutterEventGuard],
    data: {
      appPage: {
        contentWithoutPadding: true,
        contentClass: "simple",
      },
      appBar: {
        logoSize: "logo-s",
        stickyDisabled: true,
      },
    },
    children: [
      {
        path: "",
        outlet: "app-bar",
        component: AppBarComponent,
        children: [
          {
            path: "",
            outlet: "right",
            component: AppBarContactComponent,
          },
        ],
      },
      {
        path: "",
        component: UserSignOutFormComponent,
      },
      {
        path: "",
        outlet: "app-footer",
        component: AppFooterComponent,
        data: {
          appFooter: {
            full: true,
          },
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UserSignOutRoutingModule {}
