import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import {
  EntityQueryParams,
  EntityQueryResult,
  EntityData,
  EntityExData,
  EntityOtherInfoUpdateDto,
  ConfigService,
  EntityRecentActivity,
} from "common";
import { AppSettings } from "projects/portal/src/app.settings";

@Injectable({
  providedIn: "root",
})
export class EntityRemote {
  private readonly baseUrl = `${this.settings.entities.url}/api/entity`;

  constructor(
    private http: HttpClient,
    private settings: AppSettings,
    private configService: ConfigService
  ) {}

  get(id: number): Observable<EntityExData> {
    const url = `${this.baseUrl}/${id}`;
    return this.http.get<EntityExData>(url, {
      headers: this.configService.environment.cache.shortLived,
    });
  }

  query(params: EntityQueryParams): Observable<EntityQueryResult> {
    return this.http.get<EntityQueryResult>(`${this.baseUrl}/query`, {
      params: <any>params
    });
  }

  updateOtherInfo(
    id: number,
    entity: EntityOtherInfoUpdateDto
  ): Observable<EntityExData> {
    return this.http.patch<EntityData>(
      `${this.baseUrl}/${id}/other-info`,
      entity
    );
  }

  changeAddress(data: any, id: number): Observable<any> {
    const url = `${this.baseUrl}/${id}/address`;
    return this.http.patch(url, data);
  }

  getRecentActivities(id: number): Observable<EntityRecentActivity[]> {
    return this.http.get<EntityRecentActivity[]>(
      `${this.baseUrl}/${id}/recent-activities`
    );
  }
}
