<article class="container">
  <header class="header">
    <img *ngIf="logoSrc"
         [src]="logoSrc"
         [title]="logoTitle">
    <div *ngIf="phoneNumber"
         class="contact">
      <span class="contact__questions">Questions? </span>
      Give us a call at
      <a href="tel:{{phoneNumber}}">{{ phoneNumber | phone }}</a>
    </div>
  </header>
  <section class="content">
    <img [src]="errorImgSrc">
    <div *ngIf="titleRef; else defaultTitle"
         class="content__title">
      <ng-container *ngTemplateOutlet="titleRef"></ng-container>
    </div>
    <div *ngIf="descriptionRef; else defaultDescription"
         class="content__text">
      <ng-container *ngTemplateOutlet="descriptionRef"></ng-container>
    </div>
    <ng-container [ngSwitch]="actionType">
      <ng-container *ngSwitchCase="ErrorActionType.Back">
        <ng-container *ngTemplateOutlet="action; context: {label: 'GO BACK'}"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="ErrorActionType.Dashboard">
        <ng-container *ngTemplateOutlet="action; context: {label: 'GO TO DASHBOARD'}"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="ErrorActionType.Homepage">
        <ng-container *ngTemplateOutlet="action; context: {label: 'GO TO HOMEPAGE'}"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="ErrorActionType.BackToLogin">
        <ng-container *ngTemplateOutlet="action; context: {label: 'BACK TO LOGIN'}"></ng-container>
      </ng-container>
    </ng-container>

    <ng-template #action
                 let-label="label">
      <button mat-flat-button
              class="content__action"
              color="primary"
              (click)="onClickAction(actionType)">
        {{ label }}
      </button>
    </ng-template>
  </section>
</article>

<ng-template #defaultTitle>
  <p class="content__title">Oops, something went wrong.</p>
</ng-template>

<ng-template #defaultDescription>
  <p class="content__text">Ensure that the information provided is correct and give it another try.
    <span *ngIf="phoneNumber">
      If the problem persists reach out to us at
      <a href="tel:{{phoneNumber}}">{{ phoneNumber | phone }}</a>
    </span>
  </p>
</ng-template>
