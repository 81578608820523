import { Injectable } from '@angular/core';
import { FeeType, PaymentFrequency, PaymentFrequencyLabel } from 'common';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccountTransferService {

  onAccountTransferChanged: BehaviorSubject<any>;

  constructor() {
    this.onAccountTransferChanged = new BehaviorSubject(null);
  }

  calculateFeeAmount(amount, fee, feeType) {
    if (feeType === FeeType.Percentage)
      return amount * fee / 100;
    else
      return fee;
  }

  calculateDisbursedAmount(amount, feeAmount): number {
    return (amount || 0) - (feeAmount || 0);
  }

  calculateTotalInterest(amount, rate) {
    return Math.round(amount * rate) / 100.0;
  }

  getTotalNumberOfPayments(paymentFrequency: PaymentFrequency, term: number): number {
    switch (paymentFrequency) {
      case PaymentFrequency.BiWeekly:
        return Math.round(term * 26 / 12);

      case PaymentFrequency.Monthly:
        return term;

      default:
        return Math.round(term * 52 / 12);
    }
  }

  calculatePayments(amount, totalInterest, paymentFrequency, term) {
    return (amount + totalInterest) / this.getTotalNumberOfPayments(paymentFrequency, term);
  }

  

  getLoanOfferPaymentFrequencyLabel(paymentFrequency: PaymentFrequency) {
    return PaymentFrequencyLabel.get(paymentFrequency);
  }

}
