import { Injectable } from "@angular/core";
import { LocalStorageCachingStorageService } from "common";
import { CustomerFacade } from "../customer/domain/+state/customer.facade";
import { ApplicationFacade } from "../application/domain/+state/application.facade";
import { EntityFacade } from "../entity/domain/+state/entity.facade";
import { LoanFacade } from "../loan/domain/+state/loan.facade";
import { tap } from "rxjs/operators";
import { OidcService } from "./oidc/oidc.service";
import { LiquidityEventFacade } from "../liquidity-event/domain/+state/liquidity-event.facade";

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(
    private oidcService: OidcService,
    private entityFacade: EntityFacade,
    private customerFacade: CustomerFacade,
    private cache: LocalStorageCachingStorageService,
    private applicationFacade: ApplicationFacade,
    private loanFacade: LoanFacade,
    private liquidityEventFacade: LiquidityEventFacade
  ) {}

  logout() {
    return this.oidcService.logout().pipe(tap(() => this.removeSession())).subscribe();
  }

  removeSession(): void {
    this.oidcService.sessionEndLocal();
    this.entityFacade.clearEntityCache();
    this.customerFacade.clearCustomerCache();
    this.cache.clear();
    this.applicationFacade.clearApplicationCache();
    this.loanFacade.clearLoanCache();
    this.liquidityEventFacade.clearLiquidityEventCache();
  }
}
