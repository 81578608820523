<ifc-app-scroll-container [layout2List]="true">

  <div class="message-box info" *ngIf="!data.accountTransactionQueryResult?.totalCount" fxLayout="row"
    fxLayoutGap="12px" fxLayoutAlign="start center">
    <mat-icon class="icon">info</mat-icon>
    <span>No data found</span>
  </div>

  <table mat-table matSort [dataSource]="data.accountTransactionQueryResult?.transactions"
    (matSortChange)="sortData($event)" *ngIf="data.accountTransactionQueryResult.totalCount">
    <ng-container matColumnDef="type-icon">
      <th class="table-header" mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let transaction" class="table-cell small"
        (click)="transactionDetailLink(transaction.id)">
        <div class="avatar medium mat-light-grey-bg" [innerHTML]="getAvatarIcon(transaction.type)"></div>
      </td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th class="table-header" mat-header-cell *matHeaderCellDef mat-sort-header="date"> Date </th>
      <td mat-cell *matCellDef="let transaction" class="table-cell" (click)="transactionDetailLink(transaction.id)">
        <span>{{transaction.date | date:"mediumDate"}}</span><br>
        <span class="secondary">{{transaction.date | date:"shortTime"}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th class="table-header" mat-header-cell *matHeaderCellDef mat-sort-header="displayAmount" class="right-align"
        arrowPosition="before" style="width: 100px"> Amount </th>
      <td mat-cell *matCellDef="let transaction" class="table-cell right-align"
        (click)="transactionDetailLink(transaction.id)">
        <span>{{transaction.displayAmount | currency:'$'}} </span><br>
        <span class="secondary">{{getTransactionPrimaryAmount(transaction) || 0 | currency:'$'}} /
          {{getTransactionSecondaryAmount(transaction) || 0 | currency:'$'}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th class="table-header" mat-header-cell *matHeaderCellDef> Status </th>
      <td mat-cell *matCellDef="let transaction" class="table-cell" (click)="transactionDetailLink(transaction.id)">
        <span *ngIf="transaction.status === TransactionStatus.Processed">Processed</span>
        <span *ngIf="transaction.status === TransactionStatus.Complete">Complete</span>
        <ifc-status-label [status]="Status" [value]="transaction.status"
          *ngIf="transaction.status !== TransactionStatus.Processed && transaction.status !== TransactionStatus.Complete && transaction.status !== undefined">
        </ifc-status-label>
      </td>
    </ng-container>

    <ng-container matColumnDef="initiator">
      <th class="table-header" mat-header-cell *matHeaderCellDef mat-sort-header="initiator"> Initiator </th>
      <td mat-cell *matCellDef="let transaction" class="table-cell" (click)="transactionDetailLink(transaction.id)">
        {{transaction.initiator}} </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th class="table-header" mat-header-cell *matHeaderCellDef mat-sort-header="type"> Description </th>
      <td mat-cell *matCellDef="let transaction" class="table-cell" (click)="transactionDetailLink(transaction.id)">
        {{transaction.type | transactionTypeName}} </td>
    </ng-container>

    <ng-container matColumnDef="notes">
      <th class="table-header" mat-header-cell *matHeaderCellDef mat-sort-header="notes"> Notes </th>
      <td mat-cell *matCellDef="let transaction" class="table-cell" [matTooltip]="transaction.customerNote?.text" (click)="transactionDetailLink(transaction.id)">
        {{(transaction.customerNote?.text?.length>60)? (transaction.customerNote?.text | slice:0:60)+'...':(transaction.customerNote?.text)}}</td>
        
    </ng-container>

    <ng-container matColumnDef="closingBalance">
      <th class="table-header" mat-header-cell *matHeaderCellDef mat-sort-header="closingBalance" class="right-align" arrowPosition="before" style="width: 100px"> Closing Balance </th>
      <td mat-cell *matCellDef="let transaction" class="table-cell right-align" (click)="transactionDetailLink(transaction.id)">
        <span>{{transaction.closingBalance | currency:'$'}} </span><br>
        <span class="secondary">{{(transaction.closingBalance - transaction.outstandingInterest) | currency:'$'}} /
          {{transaction.outstandingInterest | currency:'$'}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="totalPayback">
      <th class="table-header" mat-header-cell *matHeaderCellDef mat-sort-header="totalPayback" class="right-align" arrowPosition="before" style="width: 100px"> Totals </th>
      <td mat-cell *matCellDef="let transaction" class="table-cell right-align" (click)="transactionDetailLink(transaction.id)">
        <span>{{transaction.totalPayback | currency:'$'}} </span><br>
        <span class="secondary">{{transaction.totalPrincipal || 0 | currency:'$'}} / {{transaction.totalInterest || 0 | currency:'$'}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions" stickyEnd>
      <th class="table-header" mat-header-cell *matHeaderCellDef style="width: 40px;"></th>
      <td mat-cell *matCellDef="let transaction" class="table-cell" (click)="transactionCancel(transaction)"
        ngClass.gt-md="action-icon actions-menu" ngClass.lt-lg="actions-menu-xs">
        <div fxLayout="row" fxLayoutAlign="center start">
          <mat-icon matTooltip="Cancel transaction" *ngIf="transaction.cancellable">
            highlight_off
          </mat-icon>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="table-header"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row"></tr>

  </table>
</ifc-app-scroll-container>

<mat-paginator #paginator [hidePageSize]="hidePageSize" [pageSizeOptions]="[10, 20, 50, 100]" [pageSize]="pageSize"
  (page)="setPage($event)" [length]="data.accountTransactionQueryResult?.totalCount" showFirstLastButtons>
</mat-paginator>
