import { Component, Input, OnInit } from "@angular/core";
import { ShowDisclosuresData } from "../../../../models/show-disclosures-data.model";
import { OfferActionsInternalService } from "../../../../services/actions/offer-actions.internal.service";
import { OfferLawCalculator } from "../../../../services/offer-law-calculator";
import { OfferBundleStatus } from "../../../../models/offer.model";

@Component({
  selector: "ifc-offer-law-details-content",
  templateUrl: "./offer-law-details-content.component.html",
  styleUrls: ["./offer-law-details-content.component.scss"],
})
export class OfferLawDetailsContentComponent implements OnInit {
  @Input()
  calculator: OfferLawCalculator;

  isRenewal: boolean;
  offerBundleStatusEnum = OfferBundleStatus;

  constructor(private actionsService: OfferActionsInternalService) {}

  ngOnInit(): void {
    this.isRenewal = this.calculator.application.renewalForLoanUniqueId
      ? true
      : false;
  }

  onLinkBank(entityId: number): void {
    this.actionsService.linkBank(entityId);
  }

  onDocumentsClick(applicationId: number, offerBundleId: number): void {
    this.actionsService.openDocumentsDetails(applicationId, offerBundleId);
  }

  onShowDisclosures(data: ShowDisclosuresData) {
    this.actionsService.showDisclosures(data);
  }
}
