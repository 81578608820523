import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from "@angular/core";
import { ApplicationOfferBundleData } from "projects/common/src/lib/application/application.model";
import { OfferType } from "../../../../models/offer.model";
import { DisplayAllOffersData } from "../../../../models/display-all-offers-data.model";
import { OfferSummaryWidgetData } from "./models/offer-summary-widget-data.model";
import { OfferSummaryWidgetFacade } from "./offer-summary-widget.facade";

@Component({
  selector: "ifc-offer-summary-widget",
  templateUrl: "./offer-summary-widget.component.html",
  styleUrls: ["./offer-summary-widget.component.scss"],
  providers: [OfferSummaryWidgetFacade]
})
export class OfferSummaryWidgetComponent implements OnInit {
  @Input() applicationId: number;
  @Input() offerBundle: ApplicationOfferBundleData;

  @Output()
  displayAllOffers: EventEmitter<DisplayAllOffersData> = new EventEmitter();

  offerType = OfferType;
  data: OfferSummaryWidgetData;

  constructor(private facade: OfferSummaryWidgetFacade) {}

  ngOnInit(): void {
    this.data = this.facade.initData(this.offerBundle.offers);
  }

  onClickDisplayAllOffers(offerType: OfferType): void {
    const data: DisplayAllOffersData = {
      applicationId: this.applicationId,
      offerBundleId: this.offerBundle.id,
      offerType,
    };

    this.displayAllOffers.emit(data);
  }
}
