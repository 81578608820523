import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

// TODO workaround - will be reafctored here https://ideafinancial.atlassian.net/browse/RYNO-10672
@Pipe({ name: 'safePureHtml' })
export class SafePureHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }

  transform(content): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(content ?? '');
  }
}
