import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import {
  AppBarCloseAction,
  AppBarComponent,
  AppBarTitleComponent,
  AppPageComponent,
} from "common";
import { OAuthGuard } from "../../guards/oauth.guard";
import { EntityQueryResolver } from "../entity/infrastructure/entity.resolver";
import { EntityDocumentsListComponent } from "./components/entity-documents-list/entity-documents-list.component";
import { EntityDocumentsQueryResolver } from "./entity-documents.resolver";

const routes: Routes = [
  {
    path: "documents",
    component: AppPageComponent,
    canActivate: [OAuthGuard],
    data: {
      appPage: {
        contentClass: "simple",
        contentAlignment: "center-start",
        contentWithoutPadding: true,
      },
      appBar: {
        closeAction: AppBarCloseAction.HOME,
        logoHidden: true,
        stickyDisabled: true,
      },
    },
    children: [
      {
        path: "",
        outlet: "app-bar",
        component: AppBarComponent,
        children: [
          {
            path: "",
            outlet: "title",
            component: AppBarTitleComponent,
          },
        ],
      },
      {
        path: "",
        component: EntityDocumentsListComponent,
        runGuardsAndResolvers: "paramsOrQueryParamsChange",
        resolve: {
          entityDocumentsQueryResult: EntityDocumentsQueryResolver,
          entity: EntityQueryResolver
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EntityDocumentsRoutingModule {}
