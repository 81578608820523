import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { LoanData } from '../domain/models/loan.model';
import { LiquidityEvent } from 'common';
import { LitigationPayoffNotesDialogResult } from './litigation-payoff-notes-dialog.model';

@Component({
  selector: 'ifp-litigation-payoff-notes-dialog',
  templateUrl: './litigation-payoff-notes-dialog.component.html',
  styleUrls: ['./litigation-payoff-notes-dialog.component.scss']
})
export class LitigationPayoffNotesDialogComponent {

  constructor(public dialogRef: MatDialogRef<LitigationPayoffNotesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  public close() {
    this.dialogRef.close({
      loanId: this.data.loan.id,
      litigationId: this.data.litigation.litigationId,
      note: this.data.litigation.payoffNotes
    } as LitigationPayoffNotesDialogResult);
  }

  static show(dialog: MatDialog, loan: LoanData, litigation: LiquidityEvent): Observable<any> {
    return dialog.open(LitigationPayoffNotesDialogComponent, {
      width: '510px',
      data: { loan: loan, litigation: litigation },
      closeOnNavigation: true,
    }).afterClosed();
  }
}
