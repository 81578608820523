import { Component } from "@angular/core";
import { AppBarTitleService } from "common";
import { MessageService } from "../message/message.service";
import { MessageData, MessageSubjects } from "../message/message.model";
import { ReferralCodeBaseComponent } from "./referral-code-base.component";
import { CustomerFacade } from "../customer/domain/+state/customer.facade";
import { ActivatedRoute } from "@angular/router";
import { EntityFacade } from "../entity/domain/+state/entity.facade";

@Component({
  selector: "ifp-referral-code",
  templateUrl: "./referral-code.component.html",
  styleUrls: ["./referral-code.component.scss"],
})
export class ReferralCodeMesssageComponent extends ReferralCodeBaseComponent<MessageData> {
  referReminderId: number;

  constructor(
    appBarTitleService: AppBarTitleService,
    messageService: MessageService,
    customerFacade: CustomerFacade,
    entityFacade: EntityFacade,
    route: ActivatedRoute
  ) {
    super(appBarTitleService, messageService, customerFacade, entityFacade, route);
    super._searchCondition = { subject: MessageSubjects.EarnUpTo10000 };
  }
}
