import { Component, Input } from "@angular/core";
import { MatSliderChange } from "@angular/material/slider";
import { ProductCode } from "projects/common/src/lib/product/product.model";
import { OfferCalculator } from "../../../services/offer-calculator";

@Component({
  selector: "ifc-offer-draw-slider",
  templateUrl: "./offer-draw-slider.component.html",
  styleUrls: ["./offer-draw-slider.component.scss"],
})
export class OfferDrawSliderComponent {
  @Input() offerCalculator: OfferCalculator;
  @Input() productCode: ProductCode;

  get theme(): string {
    switch (this.productCode) {
      case ProductCode.LOC:
        return "blue";
      case ProductCode.Law:
        return "gray";
    }
  }

  onSampleDrawChanged(event: any): void {
    this.offerCalculator.sampleDraw = event.value;
  }

  onSampleDrawDisplay(value: number): string {
    return Math.round(value / 1000).toString() + "K";
  }

  onSampleDurationDisplay(value: number): string {
    return (value + 1).toString();
  }

  onSampleDurationChanged(event: MatSliderChange): void {
    this.offerCalculator.sampleDurationIndex = event.value;
  }

  get sampleDurationLength() {
    return this.offerCalculator.cards.length - 1;
  }
}
