import { Component, OnInit, HostBinding, ViewChild, OnDestroy, ElementRef, NgZone, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppPageService } from './app-page.service';
import { MatSidenav } from '@angular/material/sidenav';
import { AppBarExpandedTab } from '../app-bar-expanded-tabs/app-bar-expanded-tabs.component';

@Component({
  selector: 'ifc-app-page',
  templateUrl: './app-page.component.html',
  styleUrls: ['./app-page.component.scss']
})
export class AppPageComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];
  screenWidth: any;

  constructor(
    private route: ActivatedRoute,
    private service: AppPageService,
    private ngZone: NgZone) {
    this.getScreenSize();
  }

  ngOnInit() {
    this.subscriptions = [
      this.route.data.subscribe(this.dataSet.bind(this)),
      this.service.sidenavOpenRequest.subscribe(this.sidenavOpenRequest.bind(this)),
      this.service.itemSelectedSubject.subscribe(() => {
        if (this.mode === 'over') {
          this.sidenav.close();
          this.sidenav.closedStart.subscribe(() => {
            localStorage.setItem('AppPageService.sidenavOpened', "false");
          });
        }
      })
    ];
    this.service.onAppBarExpanded.next(true);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(_ => _.unsubscribe());
  }

  @HostBinding('class.app-bar-expandable')
  get appBarExpandable() {
    return this.data && this.data.appBar && this.data.appBar.expandable;
  }

  get mode() { return this.screenWidth > 600 ? 'side' : 'over'; }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenWidth = window.innerWidth;
  }

  @HostListener('window:scroll', ['$event'])
  onContentScrolled(event?) {
    this.ngZone.run(() => {
      this.contentScrolled = event?.currentTarget.scrollY > 0;
    });
  }

  contentScrolled = false;

  get appBarTabbed() {
    return this.data
      && this.data.appBarExpandedTabs
      && this.data.appBarExpandedTabs.tabs
      && this.data.appBarExpandedTabs.tabs.length;
  }

  @ViewChild('contentWrapper')
  contentWrapper: ElementRef;

  data: AppPageComponentData;

  dataSet(data: AppPageComponentData) {
    this.data = data;
    if (data.appPage && data.appPage.contentAlignment) {
      this.outletContainerClasses['center-center'] = false;
      this.outletContainerClasses[data.appPage.contentAlignment] = true;
    }
    if (data.appPage && data.appPage.contentWithoutPadding)
      this.outletContainerClasses.padding = false;
  }

  outletContainerClasses = {
    'outlet-container': true,
    'flex': true,
    'row': true,
    'center-center': true,
    'padding': false
  };

  private _sidenav: MatSidenav;

  get sidenav(): MatSidenav { return this._sidenav; }

  @ViewChild(MatSidenav)
  set sidenav(sidenav: MatSidenav) {
    this._sidenav = sidenav;
    if (sidenav) {
      this.subscriptions.push(
        sidenav.openedChange.subscribe(this.sidenavOpenedChangeHandler.bind(this))
      );
    }
  }

  get sidenavOpened() {
    return this.service.sidenavOpened;
  }

  private sidenavOpenedChangeHandler(opened: boolean) {
    this.service.sidenavOpened = opened;
  }

  private sidenavOpenRequest(open: boolean) {
    if (open)
      this.sidenav.open();
    else
      this.sidenav.close();
  }
}

export interface AppPageComponentData {
  appBar: {
    expandable?: boolean;
    fixed?: boolean;
    logoClass?: string;
    logoHidden?: boolean;
    showDefaultLogo?: boolean;
    stickyDisabled?: boolean;
    containerClass?: string;
  };
  appBarExpandedTabs?: { tabs: AppBarExpandedTab[] };
  appPage: AppPageOptions;
}

export interface AppPageOptions {
  contentAlignment?: string;
  contentClass?: string;
  contentWithoutPadding?: boolean;
  sidenavEnabled?: boolean;
  sidenavTitle: string;
  sidenavSubtitle: string;
  hideSidenavToggle?: boolean;
  sidenavHeaderSticky?: boolean;
}
