import { Component, Inject } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { Observable } from "rxjs";
import { LoanFileTemplateDialogData } from "./models/loan-file-template-dialog-data.model";

@Component({
  selector: "ifp-loan-file-template-dialog",
  templateUrl: "./loan-file-template-dialog.component.html",
  styleUrls: ["./loan-file-template-dialog.component.scss"],
})
export class LoanFileTemplateDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<LoanFileTemplateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  static show(dialog: MatDialog, data: LoanFileTemplateDialogData): Observable<any> {
    return dialog
      .open(LoanFileTemplateDialogComponent, {
        width: "510px",
        data,
        closeOnNavigation: true,
      })
      .afterClosed();
  }
}
