<div class="ifc-card-elevation" [class.mat-elevation-z4]="true" ngClass.gt-md="back-account-card-lg" [ngClass.md]="'card md'"
  [ngClass.xs]="'card xs'">
  <div class="card-header-gray" [class.mat-elevation-z4]="true" fxLayout="row"  fxLayoutAlign="space-between start">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
      <div class="avatar icon">
        {{type === BankAccountRole.Disbursement ? 'D' : 'P'}}
      </div>
      <div class="card-title" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="0px">
        <span class="mat-title">{{title}}</span>
      </div>
    </div>
  </div>
  <div class="card-body">
    <mat-list>
      <mat-list-item>
        <span class="item-title secondary-text" mat-line>Bank name</span>
        <span class="item-content">{{bank?.bankName}}</span>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item>
        <span class="item-title secondary-text" mat-line>Routing</span>
        <span class="item-content">{{bank?.routingNumber}}</span>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item>
        <span class="item-title secondary-text" mat-line>Account number</span>
        <span class="item-content">{{bank?.accountNumber}}</span>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item>
        <span class="item-title secondary-text" mat-line>Status</span>
        <span class="item-content">{{bank?.isVerified ? 'Verified' : 'Unverified'}}</span>
      </mat-list-item>
    </mat-list>
  </div>
</div>