import { UppyFile } from "@uppy/core";

export interface ProgressBarDialogComponentConfig {
  filesToUpload: UppyFile[];
  generateEndpoint?: (meta: any) => string;
}

export enum ProgressBarDialogComponentResultEnum {
  Completed = 'completed',
  Canceled = 'canceled'
}
