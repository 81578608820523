import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import {
  ConfigService,
  ApplicationBaseService,
  ApplicationQueryResult,
  ApplicationStage,
  ApplicationType,
  ApplicationOfferBundleData,
} from "common";
import { ApplicationData } from "../application.model";
import { CustomerFacade } from "../../customer/domain/+state/customer.facade";
import { switchMap } from "rxjs/operators";
import { CustomerData } from "../../customer/domain/models/customer.model";
import { AppSettings } from "projects/portal/src/app.settings";

@Injectable({
  providedIn: "root",
})
export class ApplicationRemote extends ApplicationBaseService<ApplicationData> {
  constructor(
    http: HttpClient,
    configService: ConfigService,
    private appSettings: AppSettings,
    private customerFacade: CustomerFacade
  ) {
    super(http, configService);
  }

  queryByEntity(entityId: number): Observable<ApplicationQueryResult> {
    return this.customerFacade
      .getCurrentCustomer$()
      .pipe(
        switchMap((currentCustomer: CustomerData) =>
          this.queryBase(currentCustomer.id, entityId)
        )
      );
  }

  private queryBase(customerId: number, entityId: number): Observable<ApplicationQueryResult> {
    const params = {
      customerId,
      activeLoan: false,
      entityId,
      exludedStagesIfNotUpdatedRecently: [
        ApplicationStage.NotInterested,
        ApplicationStage.Dormant,
        ApplicationStage.Expired,
        ApplicationStage.Declined,
      ],
      exludedStages: [
        ApplicationStage.Closed,
        ApplicationStage.PendingRenewal,
        ApplicationStage.Frozen,
        ApplicationStage.Open,
      ],
      exludedApplicationTypes: [ApplicationType.MSA],
    };
    return super.query(params);
  }


  getAgreementReadyApplications(
  ): Observable<number> {
    const url = `${this.appSettings.customers.url}/api/application-agreement/agreements-ready-to-sign`;
    return this.http.get<number>(url);
  }

  getAgreementReadyOfferBundle(applicationId: number
    ): Observable<ApplicationOfferBundleData> {
      const url = `${this.appSettings.applications.url}/api/offer-bundle/application/${applicationId}/agreement-ready`;
      return this.http.get<ApplicationOfferBundleData>(url);
    }
}
