import { NgModule } from "@angular/core";
import { PageNotFoundComponent } from "./components/page-not-found/page-not-found.component";
import { ErrorPageComponent } from "./components/error-page/error-page.component";
import { ErrorBasePageComponent } from "./components/error-base-page/error-base-page.component";
import { UiModule } from "../ui/ui.module";
import { ErrorsPagesRoutingModule } from "./errors-routing.module";
import { CustomErrorPageComponent } from "./components/custom-error-page/custom-error-page.component";

@NgModule({
  imports: [UiModule, ErrorsPagesRoutingModule],
  declarations: [
    PageNotFoundComponent,
    ErrorPageComponent,
    ErrorBasePageComponent,
    CustomErrorPageComponent,
  ],
})
export class ErrorsModule {}
