import { Component, OnInit } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import { MfaFacade } from "./mfa.facade";
import { MfaLoginService } from "./services/mfa-login.service";
import { MfaResetPasswordService } from "./services/mfa-reset-password.service";
import { MfaChangePhoneNumberService } from "./services/mfa-change-phone-number.service";

@UntilDestroy()
@Component({
  selector: "ifp-register-mfa",
  templateUrl: "./mfa.component.html",
  providers: [
    MfaFacade,
    MfaLoginService,
    MfaResetPasswordService,
    MfaChangePhoneNumberService,
  ],
})
export class MfaComponent implements OnInit {
  constructor(private facade: MfaFacade) {}

  ngOnInit(): void {
    this.facade.initActions();
  }
}
