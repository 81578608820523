import { OfferCalculator } from "./offer-calculator";

// TODO[offers] - will be refactored in RYNO-7973 (currently copied from common/application)
export class OfferLocCalculator extends OfferCalculator {
  protected getAnnualRate(): number {
    return this.monthlyRate * 12;
  }

  protected getMonthlyRate(): number {
    const simpleInterestRate =
      this.offerCalculatorService.getLineOfCreditTotalInterest(
        this.applicationOffer,
        this.amount,
        this.term
      ) / this.amount;
    return simpleInterestRate / this.term;
  }

  protected getTotalInterest(term: number): number {
    return this.offerCalculatorService.getLineOfCreditTotalInterest(
      this.applicationOffer,
      this.sampleDraw,
      term
    );
  }

  protected getTermPayment(): number {
    return this.offerCalculatorService.getLineOfCreditPayment(
      this.sampleDraw,
      this.rate / 100,
      this.numberOfPaymentsPerAnnum,
      this.totalNumberOfPayments
    );
  }

  protected initSampleDraw(): void {
    this._sampleDraw = Math.round((this.amount / 10000) * 6) * 1000;
  }

  protected getCommision(): number {
    return this.sampleDraw * this.brokerInitialDrawCommision;
  }
}
