import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'common';
import { CustomerData } from '../../../customer/domain/models/customer.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'ifp-setup-begin',
  templateUrl: './setup-begin.component.html',
  styleUrls: ['./setup-begin.component.scss']
})
export class SetupBeginComponent implements OnInit {


  data: SetupBeginComponentData;
  continueEnabled = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService
    ) {
    sessionStorage.setItem('accountSetupViewed', JSON.stringify(true));
    sessionStorage.setItem('applicationAgreementViewed', JSON.stringify(true));
  }

  ngOnInit() {
    this.route.data
      .pipe(untilDestroyed(this))
      .subscribe((it: SetupBeginComponentData) => this.data = it);
  }

  continue() {
    this.continueEnabled = false;
    this.router
      .navigate(['account', 'setup', 'verify-identity'], { replaceUrl: true })
      .catch(error => {
        this.continueEnabled = true;
        this.messageService.error(error);
      });
  }
}

export interface SetupBeginComponentData {
  customer: CustomerData;
}
