import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from "@angular/router";
import {
  ApplicationOfferBundleData,
  ApplicationOfferDisclosure,
  OfferBundleCheckoutRequirement,
  OfferBundleCheckoutRequirementService,
  RouteHelper,
  parseNumber,
} from "common";
import { OfferBundleService } from "projects/application/src/app/shared/offer-bundle.service";
import { Observable } from "rxjs";
import { OfferService } from "./offer.service";
@Injectable({
  providedIn: "root",
})
export class OfferBundleCheckoutRequirementsResolver
  implements Resolve<ApplicationOfferBundleData>
{
  constructor(private service: OfferBundleService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<ApplicationOfferBundleData> {
    const appId = parseNumber(RouteHelper.getParamValueByName(route, "appId"));
    const offerId = parseNumber(RouteHelper.getParamValueByName(route, "offerId"));
    return this.service.getOfferWithCheckoutRequirements(appId, offerId);
  }
}

@Injectable({
  providedIn: "root",
})
export class OfferBundleCheckoutRequirementsDocumentsResolver
  implements Resolve<OfferBundleCheckoutRequirement[]>
{
  constructor(private service: OfferBundleCheckoutRequirementService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<OfferBundleCheckoutRequirement[]> {
    const offerId = parseNumber(RouteHelper.getParamValueByName(route, "offerId"));
    return this.service.getList(offerId);
  }
}

@Injectable({
  providedIn: "root",
})
export class OfferDisclosureResolver
  implements Resolve<ApplicationOfferDisclosure[]>
{
  constructor(private offerService: OfferService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<ApplicationOfferDisclosure[]> {
    const appId = parseNumber(RouteHelper.getParamValueByName(route, "appId"));
    const offerId = parseNumber(RouteHelper.getParamValueByName(route, "offerId"));
    return this.offerService.getDisclosures(appId, offerId);
  }
}
