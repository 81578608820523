import { LoanFacade } from "./../../loan/domain/+state/loan.facade";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { switchMap, tap } from "rxjs/operators";
import { LoanData, LoanQueryResult } from "../../loan/domain/models/loan.model";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { EntityFacade } from "../../entity/domain/+state/entity.facade";
import { parseNumber } from "common";

@UntilDestroy()
@Component({
  selector: "ifp-loan-select",
  templateUrl: "./loan-select.component.html",
  styleUrls: ["./loan-select.component.scss"],
})
export class LoanSelectComponent implements OnInit {
  loans: LoanData[];
  currentLoan: LoanData;

  constructor(
    private entityFacade: EntityFacade,
    private loanFacade: LoanFacade,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.initLoans();
  }

  private initLoans() {
    this.route.params
      .pipe(
        untilDestroyed(this),
        switchMap((params) =>
          this.entityFacade.getEntity$(parseNumber(params.companyId)).pipe(
            switchMap((entity) =>
              this.loanFacade.getByEntity(entity.id).pipe(
                tap((results: LoanQueryResult) => {
                  const accountId = parseNumber(params.accountId);
                  this.loans = results?.values;
                  this.currentLoan = this.loans?.find(
                    (loan) => loan.id === accountId
                  );
                })
              )
            )
          )
        )
      )
      .subscribe();
  }

  onChangeLoan(loanId: number): void {
    const currentUrl = this.router.url;
    if (currentUrl?.includes("/documents")) {
      this.router.navigate([`/account/${loanId}/documents`]);
      return;
    }

    if (currentUrl?.includes("/bank-account")) {
      this.router.navigate([`/account/${loanId}/bank-account`]);
      return;
    }

    this.router.navigate([`/account/${loanId}/transactions`]);
  }
}
