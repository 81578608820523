import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FinancialInstitution} from './bank-account.model';
import { BankAccountsInfo } from 'common';
import { AppSettings } from '../../../src/app.settings';

@Injectable({
  providedIn: 'root'
})
export class BankAccountService {

  constructor(
    private http: HttpClient,
    private settings: AppSettings) {
  }

  getIsBankLinkReminderRequired(entityId: number): Observable<boolean> {
    return this.http.get<boolean>(`${this.settings.entities.url}/api/entities/${entityId}/is-bank-link-reminder-required`);
  }

  getBankAccountsForLinkReminder(entityId: number): Observable<FinancialInstitution[]> {
    return this.http.get<FinancialInstitution[]>(`${this.settings.entities.url}/api/entities/${entityId}/bank-accounts-for-link-reminder`);
  }


  getAccountsInfo(entityId: number): Observable<BankAccountsInfo> {
    return this.http.get<BankAccountsInfo>(`${this.settings.entities.url}/api/entities/${entityId}/accounts-info`);
  }


  getBanks(entityId: number): Observable<FinancialInstitution[]> {
    return this.http.get<FinancialInstitution[]>(`${this.settings.entities.url}/api/entities/${entityId}/banks`);
  }

  getBankLinkRequiredBanks(entityId: number): Observable<FinancialInstitution[]> {
    return this.http.get<FinancialInstitution[]>(`${this.settings.entities.url}/api/entities/${entityId}/banks?bankLinkRequired=true`);
  }


  getIsBankActionRequired(entityId: number): Observable<boolean> {
    return this.http.get<boolean>(`${this.settings.entities.url}/api/entities/${entityId}/is-bank-action-required`);
  }

  getBankActionRequiredBanks(entityId: number): Observable<FinancialInstitution[]> {
    return this.http.get<FinancialInstitution[]>(`${this.settings.entities.url}/api/entities/${entityId}/banks?bankActionRequired=true`);
  }
}
