<section>
  <p *ngIf="descriptionRef">
    <ng-container *ngTemplateOutlet="descriptionRef"></ng-container>
  </p>
  <div *ngIf="actionLabel">
    <button mat-flat-button
            class="action"
            color="primary"
            (click)="onClickAction()">{{actionLabel}}</button>
  </div>
</section>
