<mat-list>
  <mat-list-item *ngIf="loan?.status !== status.Open">
    <span class="item-title secondary-text" mat-line>Status</span>
    <ifp-account-status-icon-chip [status]="loan?.status" [accountStatusIconChipModel]="accountStatusIconChipModel"></ifp-account-status-icon-chip>
    <mat-divider [fxHide.xs]="hideXS" [fxHide.sm]="hideSM"></mat-divider>
  </mat-list-item>
  <mat-list-item [fxHide.xs]="hideXS" [fxHide.sm]="hideSM" *ngIf="!!liquidityEvent?.resolutionDate && loan?.status === status.Repayment">
    <span [ngClass]="{'item-index': liquidityEvent?.payoffNotes, 'item-title secondary-text': !liquidityEvent?.payoffNotes}" mat-line>Payoff notes</span>
    <span class="item-title secondary-text item-index" mat-line fxFlex="90">{{liquidityEvent?.payoffNotes}}</span>
    <span class="item-content" fxFlex="10" *ngIf="loan?.status !== status.Pending">
      <button mat-icon-button matTooltip="Edit payoff notes" (click)="editPayoffNote(liquidityEvent)">
        <mat-icon class="secondary-text">edit</mat-icon>
      </button>
    </span>
    <mat-divider [fxHide.xs]="hideXS" [fxHide.sm]="hideSM"></mat-divider>
  </mat-list-item>
  <mat-list-item [fxHide.xs]="hideXS" [fxHide.sm]="hideSM" *ngIf="loan?.productCode === product.Law">
    <span class="item-title secondary-text" mat-line>Case number</span>
    <span class="item-content">{{loan?.externalId}}</span>
    <mat-divider [fxHide.xs]="hideXS" [fxHide.sm]="hideSM"></mat-divider>
  </mat-list-item>
  <mat-list-item [fxHide.xs]="hideXS" [fxHide.sm]="hideSM" *ngIf="loan?.productCode === product.Law">
    <span class="item-title secondary-text" mat-line>Level policy number</span>
    <span class="item-content" title="{{loan?.policyNumber}}">{{loan?.policyNumber}}</span>
    <mat-divider [fxHide.xs]="hideXS" [fxHide.sm]="hideSM"></mat-divider>
  </mat-list-item>
  <mat-list-item [fxHide.xs]="hideXS" [fxHide.sm]="hideSM" *ngIf="loan?.productCode === product.Law">
    <span class="item-title secondary-text" mat-line>Level policy limit</span>
    <span class="item-content" title="{{loan?.policyLimit | currency:'$'}}">{{loan?.policyLimit | currency:'$'}}</span>
    <mat-divider [fxHide.xs]="hideXS" [fxHide.sm]="hideSM"></mat-divider>
  </mat-list-item>
  <mat-list-item [fxHide.xs]="hideXS" [fxHide.sm]="hideSM">
    <span class="item-title secondary-text" mat-line *ngIf="loan?.productCode === product.Law">Case loan balance</span>
    <span class="item-title secondary-text" mat-line *ngIf="loan?.productCode !== product.Law">Outstanding
      balance</span>
    <span class="item-content">{{loan?.loanInfo?.principalBalance | currency:'$'}}</span>
    <mat-divider [fxHide.xs]="hideXS" [fxHide.sm]="hideSM"></mat-divider>
  </mat-list-item>
  <mat-list-item [fxHide.xs]="hideXS" [fxHide.sm]="hideSM">
    <span class="item-title secondary-text" mat-line
      *ngIf="loan?.productCode === product.LOC || loan?.productCode === product.Law">Credit limit</span>
    <span class="item-title secondary-text" mat-line *ngIf="loan?.productCode === product.Term">Loan amount</span>
    <span class="item-content">{{loan?.loanInfo?.creditLimit | currency:'$'}}</span>
    <mat-divider [fxHide.xs]="hideXS" [fxHide.sm]="hideSM"></mat-divider>
  </mat-list-item>
  <mat-list-item [fxHide.xs]="hideXS" [fxHide.sm]="hideSM" *ngIf="loan?.productCode === product.Law">
    <span class="item-title secondary-text" mat-line>Interest</span>
    <span class="item-content">{{loan?.loanInfo?.totalInterestCharged | currency:'$'}}</span>
    <mat-divider [fxHide.xs]="hideXS" [fxHide.sm]="hideSM"></mat-divider>
  </mat-list-item>
  <mat-list-item [fxHide.xs]="hideXS" [fxHide.sm]="hideSM">
    <span class="item-title secondary-text" mat-line>Next payment</span>
    <span class="item-content">{{loan?.loanInfo?.upcomingPayment | currency:'$'}}</span>
    <mat-divider [fxHide.xs]="hideXS" [fxHide.sm]="hideSM"></mat-divider>
  </mat-list-item>
  <mat-list-item [fxHide.xs]="hideXS" [fxHide.sm]="hideSM">
    <span class="item-title secondary-text" mat-line>Payment due date</span>
    <span class="item-content" *ngIf="loan?.loanInfo?.upcomingPayment > 0">{{loan?.loanInfo?.paymentDueDate | date:
      'mediumDate'}}</span>
    <span class="item-content"
      *ngIf="!loan?.loanInfo?.upcomingPayment || loan?.loanInfo?.upcomingPayment <= 0">None</span>
    <mat-divider [fxHide.xs]="hideXS" [fxHide.sm]="hideSM"></mat-divider>
  </mat-list-item>
  <mat-list-item [fxHide.xs]="hideXS" [fxHide.sm]="hideSM" *ngIf="loan?.productCode === product.LOC">
    <span class="item-title secondary-text" mat-line>Daily interest rate</span>
    <span class="item-content">{{dailyInterestRate | percent:'1.4-4'}}</span>
    <mat-divider [fxHide.xs]="hideXS" [fxHide.sm]="hideSM"></mat-divider>
  </mat-list-item>
  <mat-list-item [fxHide.xs]="hideXS" [fxHide.sm]="hideSM" *ngIf="loan?.productCode === product.Term">
    <span class="item-title secondary-text" mat-line>Factor rate</span>
    <span class="item-content">{{factorRate | number:'1.4-4'}}</span>
    <mat-divider [fxHide.xs]="hideXS" [fxHide.sm]="hideSM"></mat-divider>
  </mat-list-item>
  <mat-list-item [fxHide.xs]="hideXS" [fxHide.sm]="hideSM" *ngIf="loan?.renewalDate">
    <span class="item-title secondary-text" mat-line>Renewal due date</span>
    <span class="item-content">{{loan?.renewalDate | date: 'mediumDate'}}</span>
    <mat-divider [fxHide.xs]="hideXS" [fxHide.sm]="hideSM"></mat-divider>
  </mat-list-item>
  <mat-list-item [fxHide.xs]="hideXS" [fxHide.sm]="hideSM"
    *ngIf="loan?.productCode !== product.Law || loan?.status === status.Repayment">
    <span class="item-title secondary-text" mat-line>Repayment term</span>
    <span class="item-content">{{loan?.offer?.repaymentTerm}} months</span>
    <mat-divider [fxHide.xs]="hideXS" [fxHide.sm]="hideSM"></mat-divider>
  </mat-list-item>
</mat-list>