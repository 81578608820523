import { ViewChild, AfterViewInit, Directive } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService, AppBarTitleService } from 'common';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive()
export abstract class ContactDataFormComponent {

  @ViewChild("placesRef", { static: true }) placesRef: GooglePlaceDirective;

  form: UntypedFormGroup;
  constructor(
    protected formBuilder: UntypedFormBuilder,
    protected route: ActivatedRoute,
    protected router: Router,
    protected messageService: MessageService,
    public snackBar: MatSnackBar,
    protected appBarTitleService: AppBarTitleService
  ) { }

  protected googleAutocompleteOptions = {
    types: [],
    componentRestrictions: { country: 'US' }
  };

  protected googleAutocompleteNameMapping = {
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    administrative_area_level_1: 'short_name',
    postal_code: 'short_name'
  };

  protected phoneValidator = Validators.compose([Validators.pattern(/^\(?(\d{3})\)?([ .-]?)(\d{3})\2(\d{4})$/)]);

  protected getAddressFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      line1: ['', Validators.compose([Validators.required,
      Validators.pattern(/^[a-zA-Z0-9]|[a-zA-Z0-9]\s$/), Validators.maxLength(255)])],
      line2: ['', Validators.compose([Validators.pattern(/^[a-zA-Z0-9]|[a-zA-Z0-9]\s$/), Validators.maxLength(255)])],
      city: ['', Validators.compose([Validators.required,
      Validators.pattern(/^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/), Validators.maxLength(40)])],
      state: [],
      zip: ['', Validators.compose([Validators.required, Validators.pattern(/^\d{9}|\d{5}(?:[-\s]\d{4})?$/)])]
    });
  }

  /* For Google Autocomplete widget, when user selects an address from the widget, we parse it out here and update our form */
  public handleAddressChange(address: Address) {
    const ac = address.address_components;
    const streetNumber = this.findAddressComponent(ac,
      "street_number", this.googleAutocompleteNameMapping.street_number);
    const route = this.findAddressComponent(ac, "route", this.googleAutocompleteNameMapping.route);
    const city = this.findAddressComponent(ac, "locality", this.googleAutocompleteNameMapping.locality);
    const state = this.findAddressComponent(ac, "administrative_area_level_1",
      this.googleAutocompleteNameMapping.administrative_area_level_1);
    const zip = this.findAddressComponent(ac, "postal_code", this.googleAutocompleteNameMapping.postal_code);
    const line1 = `${streetNumber} ${route}`;

    this.form.patchValue({
      address: {
        line1: line1,
        city: city,
        state: state,
        zip: zip
      }
    });
  }

  /* helper method for Google Maps API Autocomplete widget */
  private findAddressComponent(addressComponents: any[], searchFor: string, returnField: string): string {
    const c = addressComponents.find(f => f.types.find(t => {
      return t === searchFor;
    }));
    if (c == null)
      return "";
    if (returnField === "long_name" && c.long_name)
      return c.long_name;
    else if (returnField === "short_name" && c.short_name)
      return c.short_name;

    else
      return "";
  }

  submitSubClear = () => {
    this.submitSub.unsubscribe();
    this.submitSub = null;
  };

  submitSub: Subscription;

  submitError = response => {
    this.submitSubClear();
    this.messageService.error("Error occurred while trying to update the data.");
  };

  submitSuccess = () => {
    this.submitSubClear();
    let router = this.router;
    this.snackBar.open("Your data has been updated", "OK", { duration: 2000 })
      .afterDismissed().subscribe(() => { router.navigate(["dashboard"]); });
  };

  states: string[] = [
    'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA',
    'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD',
    'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
    'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC',
    'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'
  ];
}
