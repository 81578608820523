<mat-form-field appearance="outline">
  <mat-label>{{label}}</mat-label>
  <input type="text" matInput [formControl]="fc" [matAutocomplete]="autocomplete" placeholder="{{placeholder}}"
    [readonly]="readonly">
    <button type="button" *ngIf="fc.value && !readonly" mat-button matSuffix mat-icon-button aria-label="Clear"
      (click)="valueClear()">
      <mat-icon>close</mat-icon>
    </button>
    <mat-autocomplete #autocomplete="matAutocomplete" [displayWith]="autocompleteDisplayWith"
      (optionSelected)="autocompleteOptionSelected.emit($event.option.value)">
      <mat-option *ngFor="let option of optionsFiltered | async" [value]="option">
        <img *ngIf="showAvatar" height="30" width="30" matListAvatar src="../../../assets/profile.jpg">
        <span> {{optionLabel(option)}}</span>
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngFor="let error of errors">
      <ng-container *ngIf="fc?.hasError(error?.errorCode)">
        {{error?.message}}
      </ng-container>
    </mat-error>
</mat-form-field>
