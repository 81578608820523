import {
  Component,
  ContentChild,
  Input,
  OnChanges,
  TemplateRef,
} from "@angular/core";
import {
  ApplicationBaseData,
  ApplicationOfferBundleData,
} from "../../../application/application.model";
import { CompanyBranding } from "../../../branding/branding.model";
import { ProductCode } from "../../../product/product.model";
import { OfferCalculator } from "../../services/offer-calculator";
import { OfferDetailsFacade } from "./offer-details.facade";
import { OfferBundlesSummary } from "../../models/offer-bundles-summary.model";
import { OfferBundleStatus } from "../../models/offer.model";
import {
  OfferActionIds,
  OfferProductIds,
} from "../../models/offer-action-ids.model";

@Component({
  selector: "ifc-offer-details",
  templateUrl: "./offer-details.component.html",
  styleUrls: ["./offer-details.component.scss"],
  providers: [OfferDetailsFacade],
})
export class OfferDetailsComponent implements OnChanges {
  @Input()
  productCode: ProductCode;

  @Input()
  application: ApplicationBaseData;

  @Input()
  offerBundles: ApplicationOfferBundleData[];

  @Input()
  currentOfferBundle: ApplicationOfferBundleData;

  @Input()
  companyData: CompanyBranding;

  @Input()
  canSelectOffer: boolean;

  @ContentChild("selectOffer", { read: TemplateRef })
  selectOfferRef: TemplateRef<unknown>;

  calculator: OfferCalculator;
  offerBundlesSummary: OfferBundlesSummary;
  ProductCode = ProductCode;
  OfferBundleStatus = OfferBundleStatus;

  constructor(private facade: OfferDetailsFacade) {}

  ngOnChanges(): void {
    this.initData();
  }

  private initData() {
    this.offerBundlesSummary = this.facade.getOfferBundlesSummaryData(
      this.offerBundles,
      this.currentOfferBundle.id
    );
    this.calculator = this.facade.createOfferCalculator(
      this.productCode,
      this.application,
      this.currentOfferBundle
    );
  }

  onViewAllOffers(): void {
    this.facade.navigateToOffersSummary(this.application.id);
  }

  onChangeOffer(data: OfferProductIds): void {
    const actionData: OfferActionIds = {
      ...data,
      applicationId: this.application.id,
    };
    this.facade.navigateToOfferDetails(actionData);
  }
}
