import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DocumentQueryParams, DocumentQueryResult } from './document.model';
import { ConfigService } from '../config/config.service';
import { Observable } from 'rxjs';
import { ResourceService } from '../resource/resource.service';
import { DocumentBaseService } from './document-base.service';
import { SignedLoanDocumentFile } from './signed-loan-document-file.model';

@Injectable({
  providedIn: 'root'
})
export class ApplicationDocumentService extends DocumentBaseService {

  constructor(http: HttpClient, resourceService: ResourceService, configService: ConfigService) {
    super(http, resourceService, configService, `${configService.environment.applications.url}/api/application`)
  }

   override query(applicationId: number, params: DocumentQueryParams): Observable<DocumentQueryResult> {
    if (!params.sort)
      params.sort = '-createdon';
    return this.http.get<DocumentQueryResult>(`${this.baseUrl}/${applicationId}/documents`, {
      params: <any>params,
      headers: this.configService.environment.cache.mediumLived
    });
  }

  override queryByUniqueId(uniqueApplicationId: string, params: DocumentQueryParams): Observable<DocumentQueryResult> {
    if (!params.sort)
      params.sort = '-createdon';
    return this.http.post<DocumentQueryResult>(`${this.baseUrl}/sf/${uniqueApplicationId}/documents`, null, {
      params: <any>params,
      headers: this.configService.environment.cache.mediumLived
    });
  }

  override delete(fileId: number, applicationId: number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${applicationId}/documents/${fileId}`);
  }

  override download(fileId: number, applicationId: number) {
    this.resourceService.download(`${this.baseUrl}/${applicationId}/documents/${fileId}`);
  }

  getSignatures(fileId: number): Observable<SignedLoanDocumentFile> {
    return this.http.get<SignedLoanDocumentFile>(`${this.baseUrl}/${fileId}/signatures`);
  }
}
