import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from "@angular/router";
import { ApplicationData } from "./application.model";
import { Observable } from "rxjs";
import { RouteHelper, parseNumber } from "common";
import { ApplicationFacade } from "./domain/+state/application.facade";
import { EntityFacade } from "../entity/domain/+state/entity.facade";
import { switchMap, take } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ApplicationsResolver implements Resolve<ApplicationData[]> {
  constructor(
    private entityFacade: EntityFacade,
    private applicationFacade: ApplicationFacade
  ) {}

  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<ApplicationData[]> | ApplicationData[] {
    const companyId = parseNumber(RouteHelper.getQueryParamValueByName(route, "companyId"));
    return this.entityFacade.getEntity$(companyId).pipe(
      take(1),
      switchMap((entity) => this.applicationFacade.getApplications$(entity.id))
    );
  }
}

@Injectable({
  providedIn: "root",
})
export class ApplicationResolver implements Resolve<ApplicationData> {
  constructor(private facade: ApplicationFacade) {}

  resolve(route: ActivatedRouteSnapshot): Observable<ApplicationData> {
    const appId = RouteHelper.getParamValueByName(route, "appId");
    return this.facade.getApplication$(parseNumber(appId)).pipe(take(1));
  }
}
