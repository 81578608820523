import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BrandingService } from 'common';

@Injectable({
  providedIn: 'root'
})
export class RedirectDashboardGuard implements CanActivate {
  constructor(private brandingService: BrandingService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {

    return this.brandingService.getBranding()
      .pipe(
        map((brand) => {
          let isCorrectPortal = brand.portalUrl === window.location.origin;

          if (!isCorrectPortal) {
            window.location.href = `${brand.portalUrl}${state.url}`;
          }

          return isCorrectPortal;
        }
      )
    );
  }
}
