export class AppSettings {
    rootSite: {
        url: string;
    };
    google: {
        auth2: {
            client_id: string;
            cookiepolicy: string;
        };
    };
    if: {
        contact: {
            phone: string;
        };
    };
    banking: {
        url: string;
    };
    loans: {
        url: string;
    };
    transaction: {
        url: string;
    };
    applications: {
        url: string;
    };
    entities: {
        url: string;
    };
    leads: {
        url: string;
    };
    customers: {
        url: string;
    };
    oauth: {
        url: string;
    };
    doc: {
        url: string;
    };
    insurance: {
        url: string;
        phone: string;
    };
    insure: {
        url: string;
    };
    companies: {
        url: string;
    };
    messages: {
        url: string;
    };
    promotions: {
        url: string;
    };
    auth: {
        oidc: {
            redirectUrl: string;
            authority: string;
            clientId: string;
            clientSecret: string;
        }
        register: string;
        changePassword: string;
    }
}
