import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
  AccountHintData,
  AccountHintParams,
  AccountQueryParams,
  CaseResolutionDialogData,
  ConfigService,
  ResourceService,
} from "common";
import { AppSettings } from "projects/portal/src/app.settings";
import { LoanData, LoanQueryResult, LoanWithRenewalData } from "../domain/models/loan.model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { TransactionPreviewResponse } from "../../account/account-transfer/account-transfer.model";

@Injectable({
  providedIn: "root",
})
export class LoanRemote {
  constructor(
    private http: HttpClient,
    private settings: AppSettings,
    private resourceService: ResourceService,
    private configService: ConfigService
  ) {}

  get(id: number): Observable<LoanData> {
    const url = `${this.settings.loans.url}/api/account/get/${id}`;
    return this.http.get<LoanData>(url, {
      headers: this.configService.environment.cache.shortLived,
    });
  }

  getWithRenewal(id: number): Observable<LoanWithRenewalData> {
    return this.http.get<LoanWithRenewalData>(this.getWithRenewalUrl(id));
  }

  getWithRenewalUrl(id: number): string {
    return `${this.settings.loans.url}/api/account/get/${id}/with-renewal`;
  }

  query(params: any): Observable<LoanQueryResult> {
    const url = `${this.settings.loans.url}/api/account/list`;
    return this.http
      .get(url, { params: <any>params })
      .pipe(map<any, LoanQueryResult>((result) => result));
  }

  getAgreementReadyLoans(
    entityId: number,
    customerId: number
  ): Observable<LoanData> {
    const url = `${this.settings.loans.url}/api/account/${entityId}/${customerId}/agreement-ready-loans`;
    return this.http.get<LoanData>(url);
  }

  draw(id: number, amount: string, appliedPromoCode: string, note: string): Observable<any> {
    const url = `${this.settings.transaction.url}/api/transactions/customer-draw/${id}`;
    return this.http.post(url, {
      amount,
      promoCode: appliedPromoCode ? appliedPromoCode : null,
      note,
    });
  }

  pay(id: number, amount: string, appliedPromoCode: string): Observable<any> {
    const url = `${this.settings.transaction.url}/api/transactions/customer-pay/${id}`;
    return this.http.post(url, {
      amount,
      promoCode: appliedPromoCode ? appliedPromoCode : null,
    });
  }

  drawPreview(
    id: number,
    amount: number,
    appliedPromoCode: string
  ): Observable<TransactionPreviewResponse> {
    return this.http.get<TransactionPreviewResponse>(
      `${this.settings.transaction.url}/api/transactions/customer-draw/${id}`,
      {
        headers: { hideLoader: "true" },
        params: {
          amount: amount,
          promoCode: appliedPromoCode ? appliedPromoCode : null,
        },
      }
    );
  }

  payPreview(
    id: number,
    amount: number,
    appliedPromoCode: string
  ): Observable<TransactionPreviewResponse> {
    return this.http.get<TransactionPreviewResponse>(
      `${this.settings.transaction.url}/api/transactions/customer-pay/${id}`,
      {
        headers: { hideLoader: "true" },
        params: {
          amount: amount,
          promoCode: appliedPromoCode ? appliedPromoCode : null,
        },
      }
    );
  }

  signAgreementNeeded(loanId: number): Observable<boolean> {
    const url = `${this.settings.loans.url}/api/account/${loanId}/agreement-sign-needed`;
    return this.http.get<boolean>(url);
  }

  updateDescription(data: string, id: number): Observable<any> {
    return this.http.put(
      `${this.settings.loans.url}/api/account/${id}/description`,
      { description: data }
    );
  }
  
  download(params: AccountQueryParams) {
    this.resourceService.download(
      `${this.settings.loans.url}/api/account/csv`,
      "loans.csv",
      params
    );
  }

  updateFavorite(
    customerId: number,
    loanId: number,
    favorite: boolean
  ): Observable<any> {
    return this.http.put(
      `${this.settings.customers.url}/api/customer/${customerId}/loans/${loanId}/favorite`,
      { favorite: favorite }
    );
  }

  queryHints(query: AccountHintParams): Observable<AccountHintData[]> {
    return this.http.get<AccountHintData[]>(
      `${this.settings.loans.url}/api/account/hints`,
      { params: <any>query }
    );
  }

  getCasePaymentDueDetails(loanId: number): Observable<CaseResolutionDialogData> {
    return this.http.get<CaseResolutionDialogData>(
      `${this.settings.loans.url}/api/account/${loanId}/case-due`
    );
  }
}
