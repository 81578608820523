import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CompanyBranding } from '../../../branding/branding.model';
import { BrandingService } from '../../../../core/branding/branding.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'ifc-app-logo-icon',
  templateUrl: './app-logo-icon.component.html',
  styleUrls: ['./app-logo-icon.component.css']
})
export class AppLogoIconComponent implements OnInit {

  @Input() logoClass: string;

  logo: any;
  title: string

  constructor(
    private sanitizer: DomSanitizer,
    private brandingService: BrandingService
  ) { }

  ngOnInit() {
    this.initData();
  }

  private initData() {
    this.brandingService.getBranding$()
      .pipe(untilDestroyed(this))
      .subscribe((branding: CompanyBranding) => {
        if (branding?.mobileLogo) {
          this.logo = this.sanitizer.bypassSecurityTrustResourceUrl(branding.mobileLogo);
        }

        this.title = branding?.mobileLogoTitle ?? 'Idea Financial';
      });
  }
}
