import { NgModule } from '@angular/core';
import { CommonModule } from 'common';
import { AccountPaymentTableComponent } from './account-payment-table/account-payment-table.component';
import { AccountPaymentRoutingModule } from './account-payment-routing.module';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    AccountPaymentRoutingModule
  ],
  exports: [
    AccountPaymentTableComponent
  ],
  declarations: [ AccountPaymentTableComponent ]
})
export class AccountPaymentModule { }
