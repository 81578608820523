import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { LoanData, ProductCode } from "../../loan/domain/models/loan.model";
import { MatDialog } from "@angular/material/dialog";
import {
  BrandingService,
  CompanyBranding,
  CompanyBrandingHelper,
  LiquidityEvent,
  LoanHelper,
  LoanStatus,
  MessageService
} from "common";
import { LoanDescriptionDialogComponent } from "../../loan/loan-description-dialog/loan-description-dialog.component";
import { Subscription } from "rxjs/internal/Subscription";
import { LoanFacade } from "../../loan/domain/+state/loan.facade";
import { LitigationPayoffNotesDialogComponent } from "../../loan/litigation-payoff-notes-dialog/litigation-payoff-notes-dialog.component";
import { LitigationPayoffNotesDialogResult } from "../../loan/litigation-payoff-notes-dialog/litigation-payoff-notes-dialog.model";
import { LiquidityEventFacade } from "../../liquidity-event/domain/+state/liquidity-event.facade";
import { switchMap, tap } from "rxjs/operators";
import { AccountStatusIconChipModel } from "../account-status-icon-chip/account-status-icon-chip.model";

@Component({
  selector: "ifp-account-summary",
  templateUrl: "./account-summary.component.html",
  styleUrls: ["./account-summary.component.scss"],
})
export class AccountSummaryComponent implements OnInit, OnChanges {
  @Input() loan: LoanData;
  @Input() liquidityEvent: LiquidityEvent;
  @Input() hideXS: boolean;
  @Input() hideSM: boolean;

  product = ProductCode;
  status = LoanStatus;

  private saveSub: Subscription;
  private daysInCurrentYear: number;
  accountStatusIconChipModel: AccountStatusIconChipModel;
  brandingData: CompanyBranding;

  constructor(
    private loanFacade: LoanFacade,
    private liquidityEventFacade: LiquidityEventFacade,
    private dialog: MatDialog,
    private messageService: MessageService,
    private brandingService: BrandingService
  ) {
    const year = new Date().getFullYear();
    this.daysInCurrentYear =
      (year % 4 === 0 && year % 100 > 0) || year % 400 == 0 ? 366 : 365;
  }

  ngOnInit(): void {
    this.initBranding();
  }

  ngOnChanges(_: SimpleChanges): void {
    const advisorPhone = CompanyBrandingHelper.getAdvisorPhone(this.brandingData, this.loan?.advisorPhone);

    this.accountStatusIconChipModel = { 
      needsAttentionMessage:  LoanHelper.getNeedsAttentionMessage({...this.loan, advisorPhone: advisorPhone }),
      isLoanFullRepaymentDueDateSet: !!this.loan.fullRepaymentDueDate 
    };
  }

  editDescription() {
    LoanDescriptionDialogComponent.show(this.dialog, {
      ...this.loan,
    }).subscribe((result) => {
      if (result !== undefined) {
        this.saveSub = this.loanFacade
          .updateDescription(result, this.loan.id)
          .subscribe({
            next: this.saveSuccessHandler.bind(this),
            error: this.saveErrorHandler.bind(this),
          });
      }
    });
  }

  editPayoffNote(liquidityEvent: LiquidityEvent) {
    LitigationPayoffNotesDialogComponent.show(
      this.dialog, 
      {...this.loan},
      {...liquidityEvent}
    )
    .subscribe((dialogResult: LitigationPayoffNotesDialogResult) => {
      if (dialogResult !== undefined) {
        this.saveSub = this.loanFacade.updatePayoffNotes(dialogResult.note, dialogResult.litigationId, dialogResult.loanId)
          .pipe(
            switchMap(() => this.liquidityEventFacade.refreshLitigation(dialogResult.loanId))
          )
          .subscribe({
            next: this.saveSuccessHandler.bind(this),
            error: this.saveErrorHandler.bind(this),
          });
      }
    });
  }

  get dailyInterestRate(): number {
    return this.loan?.offer?.interestRate / this.daysInCurrentYear / 100;
  }

  get factorRate(): number {
    return this.loan?.offer?.interestRate / 100 + 1;
  }

  private saveSubClear() {
    if (this.saveSub) this.saveSub.unsubscribe();
    this.saveSub = null;
  }

  private saveErrorHandler(error: any) {
    this.saveSubClear();
    this.messageService.error(error);
  }

  private saveSuccessHandler() {
    this.saveSubClear();
  }
  
  private initBranding() {
    this.brandingService.getBranding().pipe(
      tap(brandingData => {
        this.brandingData = brandingData;
      }))
      .subscribe();
  }
}
