import { Component, OnInit } from "@angular/core";
import { MfaActionsInternalService } from "./services/mfa-actions.internal.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UpdatePhoneNumberDialogComponent } from "./components/update-phone-number-dialog/update-phone-number-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { MfaData, UpdatePhoneNumberDialogData, UpdatePhoneNumberDialogType } from "./const/models/mfa.model";
import { MfaDataService } from "./services/mfa-data.service";
import { MfaService } from "./services/mfa.service";
import { VerifyMfaCodeData } from "./const/models/verify-mfa-code-data.model";
import { AppBarCloseActionService } from "../../../ui/app/app-bar/app-bar-close-action.service";
import { CloseMfaData } from "./const/models/close-mfa-data.model";
import { MfaPhoneStatus } from "./const/enums/mfa-phone-status.enum";

@UntilDestroy()
@Component({
  selector: "ifc-mfa",
  templateUrl: "./mfa.component.html",
  styleUrls: ["./mfa.component.scss"],
})
export class MfaComponent implements OnInit {
  data: MfaData;
  readonly MfaPhoneStatus = MfaPhoneStatus;

  constructor(
    private mfaActions: MfaActionsInternalService,
    private dialog: MatDialog,
    private mfaDataService: MfaDataService,
    private appBarCloseActionService: AppBarCloseActionService,
    private mfaService: MfaService
  ) {}

  ngOnInit(): void {
    this.initAppBar();
    this.initMfaData();
  }

  onBackToLogin() {
    this.closeMfa();
  }

  onAddPhoneNumber() {
    this.openUpdatePhoneNumberDialog(UpdatePhoneNumberDialogType.Add);
  }

  onUpdatePhoneNumber() {
    this.openUpdatePhoneNumberDialog(UpdatePhoneNumberDialogType.Update);
  }

  onVerify(data: VerifyMfaCodeData) {
    this.mfaActions.verifyMfaCode(data);
  }

  onSendNewCode(mfaToken: string) {
    this.mfaDataService.sendNewCode(mfaToken).subscribe();
  }

  private initMfaData() {
    this.mfaService.mfaData$.pipe(untilDestroyed(this)).subscribe((data) => {
      if (data) {
        this.data = data;
      }
    });
  }

  private initAppBar() {
    this.appBarCloseActionService.closing$
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.closeMfa();
      });
  }

  private closeMfa() {
    const data: CloseMfaData = {
      contextType: this.data.contextType,
      errorRedirectUrl: this.data.errorRedirectUrl,
      contextData: this.data.contextData,
    };
    this.mfaActions.close(data);
  }

  private openUpdatePhoneNumberDialog(type: UpdatePhoneNumberDialogType) {
    const data: UpdatePhoneNumberDialogData = {
      mfaToken: this.data.mfaToken,
      config: this.data.config,
      type
    };
    UpdatePhoneNumberDialogComponent.show(this.dialog, data);
  }
}
