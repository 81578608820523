<mat-dialog-content>
  <header>
    <h2 class="heading">{{data.type === DialogType.Add ? 'Add' : 'Update'}} phone number</h2>
  </header>
  <section>
    <p>{{data.type === DialogType.Add ? 'Add' : 'Update'}} your phone number to receive the verification code. If you still don't received a code, reach out to us at
      <a href="tel:{{servicePhoneNumber}}">{{ servicePhoneNumber }}</a></p>
    <form [formGroup]="form">
      <mat-form-field appearance="outline"
                      class="phoneNumber">
        <mat-label>Enter phone number</mat-label>
        <input matInput
               formControlName="phoneNumber"
               type="text"
               placeholder="Enter phone number"
               mask="(000) 000-0000"
               inputmode="numeric" />
        <mat-error *ngIf="form.get('phoneNumber').errors | hasControlError:['required', 'mask']">
          Please enter a valid phone number
        </mat-error>
      </mat-form-field>
    </form>
  </section>
  <footer class="actions">
    <button mat-button
            [mat-dialog-close]="false">CANCEL</button>
    <button type="button"
            mat-button
            [disabled]="form.invalid"
            color="primary"
            (click)="update()">
          {{data.type === DialogType.Add ? 'ADD' : 'UPDATE'}}
          </button>
  </footer>
</mat-dialog-content>
