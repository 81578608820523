<h1 mat-dialog-title>{{data.loan?.loanNumber}}, Case {{data.loan?.externalId}} about</h1>
<mat-dialog-content>
  <p class="secondary-text sub-title">
    Tell us more about the case
  </p>
  <div class="content">
    <mat-form-field appearance="outline" fxFlexFill>
      <mat-label>Note</mat-label>
      <textarea matInput placeholder="Note" [(ngModel)]="data.litigation.payoffNotes" rows="4"></textarea>
    </mat-form-field>
  </div>
</mat-dialog-content>

<div mat-dialog-actions class="flex row end-center">
  <button mat-button [mat-dialog-close]="undefined">Cancel</button>
  <button mat-button (click)="close()" color="primary">Update</button>
</div>
