import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  AppPageComponent,
  AppBarComponent,
  AppBarCloseAction,
  AppBarActionsComponent,
  SyncGuardHelper
} from 'common';
import { AccountTransferDrawComponent } from '../account-transfer/account-transfer-draw/account-transfer-draw.component';
import { AccountSetupBeginComponent } from './account-setup-begin/account-setup-begin.component';
import { AccountTransferDrawResolver } from '../account-transfer/account-transfer-draw.resolver';
import { LoanResolver } from '../../loan/infrastructure/loan.resolver';
import { CustomerKbaFormComponent } from '../../customer/customer-checkout/customer-kba-form/customer-kba-form.component';
import { CustomerKbaResultComponent } from '../../customer/customer-checkout/customer-kba-result/customer-kba-result.component';
import { CustomerCurrentResolver } from '../../customer/infrastructure/customer.resolver';
import { AppBarTitleComponent } from '../../app-bar-title/app-bar-title.component';
import { CustomerKbaQuestionsResolver } from '../../customer/customer-checkout/customer-checkout.resolver';
import { LoanApplicationGuard, LoanOpenGuard } from '../../loan/infrastructure/loan.guard';
import { OfferBundlesInfoResolver } from '../../renewal/renewal.resolver';
import { OAuthGuard } from '../../../guards/oauth.guard';
import { AppBarContactComponent } from '../../app-bar-contact/app-bar-contact.component';
import { AgreementGuard } from '../../renewal-eligible/renewal-confirmation/sign-agreement-needed.guard';
import { SetupBeginComponent } from './setup-begin/setup-begin.component';
import { CustomerCheckoutGuard } from '../../agreement/customer-checkout.guard';
import { LocAgreementFormComponent } from '../../loc-agreement/loc-agreement-form/loc-agreement-form.component';

const routes: Routes = [
  {
    path: 'account/:accountId/setup',
    pathMatch: 'full',
    component: AppPageComponent,
    canActivate: [OAuthGuard],
    data: {
      appBar: {
        closeAction: AppBarCloseAction.HOME,
        expandable: false,
        logoHidden: true
      },
      appBarTitle: {
        content: 'Account setup'
      }
    },
    resolve: {
      customer: CustomerCurrentResolver,
      loan: LoanResolver
    },
    children: [
      {
        path: '',
        outlet: 'app-bar',
        component: AppBarComponent,
        children: [
          {
            path: '',
            outlet: 'title',
            component: AppBarTitleComponent,
          },
          {
            path: '',
            outlet: 'right',
            component: AppBarContactComponent
          }
        ]
      },
      {
        path: '',
        canActivate: [LoanApplicationGuard],
        component: AccountSetupBeginComponent
      }
    ]
  },
  {
    path: 'account/:accountId/setup',
    pathMatch: 'full',
    component: AppPageComponent,
    canActivate: [OAuthGuard],
    data: {
      appBar: {
        closeAction: AppBarCloseAction.HOME,
        expandable: false,
        logoHidden: true
      },
      appBarTitle: {
        content: 'Account setup'
      }
    },
    resolve: {
      customer: CustomerCurrentResolver,
      loan: LoanResolver
    },
    children: [
      {
        path: '',
        outlet: 'app-bar',
        component: AppBarComponent,
        children: [
          {
            path: '',
            outlet: 'title',
            component: AppBarTitleComponent,
          },
          {
            path: '',
            outlet: 'right',
            component: AppBarContactComponent
          }
        ]
      },
      {
        path: '',
        canActivate: [LoanApplicationGuard],
        component: AccountSetupBeginComponent
      }
    ]
  },
  {
    path: 'account/setup',
    pathMatch: 'full',
    component: AppPageComponent,
    canActivate: [OAuthGuard],
    data: {
      appBar: {
        closeAction: AppBarCloseAction.HOME,
        expandable: false,
        logoHidden: true
      },
      appBarTitle: {
        content: 'What happens next'
      }
    },
    resolve: {
      customer: CustomerCurrentResolver
    },
    children: [
      {
        path: '',
        outlet: 'app-bar',
        component: AppBarComponent,
        children: [
          {
            path: '',
            outlet: 'title',
            component: AppBarTitleComponent,
          },
          {
            path: '',
            outlet: 'right',
            component: AppBarContactComponent
          }
        ]
      },
      {
        path: '',
        canActivate: [LoanApplicationGuard],
        component: SetupBeginComponent
      }
    ]
  },
  {
    path: 'account/setup/verify-identity',
    component: AppPageComponent,
    canActivate: [OAuthGuard],
    data: {
      appBar: {
        closeAction: AppBarCloseAction.HOME,
        expandable: false,
        logoHidden: true,
      },
      appBarTitle: {
        content: 'Account setup'
      }
    },
    children: [
      {
        path: '',
        outlet: 'app-bar',
        component: AppBarComponent,
        children: [
          {
            path: '',
            outlet: 'title',
            component: AppBarTitleComponent
          },
          {
            path: '',
            outlet: 'right',
            component: AppBarContactComponent
          }
        ]
      },
      {
        path: '',
        component: CustomerKbaFormComponent,
        resolve: {
          customer: CustomerCurrentResolver,
          kbaConversation: CustomerKbaQuestionsResolver,
        }
      }
    ]
  },
  {
    path: 'account/setup/verify-identity-result',
    component: AppPageComponent,
    canActivate: [OAuthGuard],
    data: {
      appBar: {
        closeAction: AppBarCloseAction.HOME,
        expandable: false,
        logoHidden: true,
      },
      appBarTitle: {
        content: 'Account setup'
      }
    },
    children: [
      {
        path: '',
        outlet: 'app-bar',
        component: AppBarComponent,
        children: [
          {
            path: '',
            outlet: 'title',
            component: AppBarTitleComponent
          },
          {
            path: '',
            outlet: 'right',
            component: AppBarContactComponent
          }
        ]
      },
      {
        path: '',
        component: CustomerKbaResultComponent
      }
    ]
  },
  {
    path: 'account/:accountId/setup/sign-agreement',
    component: AppPageComponent,
    canActivate: [OAuthGuard],
    data: {
      appPage: {
        contentAlignment: 'stretch-stretch',
        contentWithoutPadding: true,
      },
      appBar: {
        closeAction: AppBarCloseAction.HOME,
        expandable: false,
        logoHidden: true,
      },
      appBarTitle: {
        content: 'Sign agreement'
      }
    },
    resolve: {
      customer: CustomerCurrentResolver,
      loan: LoanResolver,
      offerBundlesInfo: OfferBundlesInfoResolver
    },
    children: [
      {
        path: '',
        outlet: 'app-bar',
        component: AppBarComponent,
        children: [
          {
            path: '',
            outlet: 'title',
            component: AppBarTitleComponent
          },
          {
            path: '',
            outlet: 'right',
            component: AppBarContactComponent
          }
        ]
      },
      {
        path: '',
        canActivate: [SyncGuardHelper],
        data: {
          syncGuards: [
            CustomerCheckoutGuard,
            AgreementGuard,
          ]
        },
        component: LocAgreementFormComponent
      }
    ]
  },
  {
    path: 'account/:accountId/setup/make-a-draw',
    component: AppPageComponent,
    canActivate: [OAuthGuard],
    data: {
      appBar: {
        stickyDisabled: true,
        closeAction: AppBarCloseAction.HOME,
        expandable: false,
        logoHidden: true,
      },
      appBarActions: {
        menuMobile: true,
      },
      appPage: {
        contentClass: "simple"
      }
    },
    children: [
      {
        path: '',
        outlet: 'app-bar',
        component: AppBarComponent,
        children: [
          {
            path: '',
            outlet: 'title',
            component: AppBarTitleComponent,
          },
          {
            path: '',
            outlet: 'right',
            component: AppBarActionsComponent,
          }
        ]
      },
      {
        path: '',
        component: AccountTransferDrawComponent,
        canActivate: [LoanOpenGuard],
        data: { redirectedFromAgreement: true },
        resolve: {
          accountTransfer: AccountTransferDrawResolver,
          loan: LoanResolver
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountSetupRoutingModule { }
