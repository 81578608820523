import { Observable } from "rxjs";
import { LiquidityEventRemote } from "../../infrastructure/liquidity-event.remote";
import { LiquidityEventStateService } from "./liquidity-event.state.service";
import { switchMap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { LiquidityEvent } from "common";

@Injectable({
    providedIn: "root",
})
export class LiquidityEventFacade {
    constructor(
      private readonly stateService: LiquidityEventStateService,
      private readonly remote: LiquidityEventRemote,
    ) {}
  
    clearLiquidityEventCache(): void {
      this.stateService.clearState();
    }

    getLiquidityEventForLoan$(loanId: number): Observable<LiquidityEvent> {
      if (this.stateService.isLiquidityEventStateLoaded()) {
        return this.stateService.getLiquidityEvent$();
      }
  
      return this.updateLiquidityEvent(loanId);
    }
  
    private updateLiquidityEvent(loanId: number): Observable<LiquidityEvent> {
      this.stateService.setLoanId(loanId);
      return this.remote.getLiquidityEventForLoan(loanId).pipe(
        switchMap((liquidityEvent: LiquidityEvent) => {
          this.stateService.setLiquidityEvent(liquidityEvent);
          return this.stateService.getLiquidityEvent$();
        })
      );
    }
  
    refreshLitigation(loanId: number): Observable<LiquidityEvent> {
      return this.updateLiquidityEvent(loanId);
    }
}