import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { AppSettings } from "../../../app.settings";
import { catchError, tap } from "rxjs/operators";
import {
  InitMfaData,
  MessageService,
  MfaApiResponse,
  MfaConfig,
  MfaContextData,
  MfaContextType,
  MfaErrorCodeType,
  MfaErrorType,
  MfaService,
  OAuthService,
} from "common";
import { LoginType } from "./models/login-type.enum";
import { Router } from "@angular/router";
import {
  SingInErrorResponse,
  SingInExtras,
  SingInMfaRequest,
  SingInRequest,
  SingInResponse,
} from "./models/sign-in.models";

@Injectable({
  providedIn: "root",
})
export class UserSignInService {
  constructor(
    private http: HttpClient,
    private settings: AppSettings,
    private messageService: MessageService,
    private mfaService: MfaService,
    private oauthService: OAuthService,
    private router: Router
  ) {}

  private serviceUrl = this.settings.oauth.url;
  private googleClientId = this.settings.google.auth2.client_id;

  signInByPassword(
    username: string,
    password: string,
    rememberMe: boolean
  ): Observable<SingInResponse | SingInErrorResponse> {
    const request = {
      grant_type: "password",
      client_id: LoginType.Internal,
      username,
      password,
    };
    const extras: SingInExtras = {
      username,
      rememberMe,
      loginType: LoginType.Internal,
    };
    return this.signInBase(request, extras);
  }

  signInByMfa(
    request: SingInMfaRequest,
    extras: SingInExtras
  ): Observable<SingInResponse | SingInErrorResponse> {
    const requestBody = {
      grant_type: "mfa_otp",
      client_id: extras?.loginType,
      mfa_token: request.mfa_token,
      mfa_code: request.mfa_code,
      trust_device: request.trust_device,
    };
    return this.signInBase(requestBody, extras);
  }

  signInGoogle(token: any) {
    const request = {
      grant_type: "authorization_code",
      client_id: this.googleClientId,
      client_secret: LoginType.Google,
      code: token,
    };
    const extras: SingInExtras = {
      username: null,
      rememberMe: null,
      loginType: LoginType.Google,
    };
    return this.signInBase(request, extras);
  }

  confirmPhoneAndSignIn(
    request: SingInMfaRequest,
    extras: SingInExtras
  ): Observable<SingInResponse | SingInErrorResponse> {
    return this.confirmPhoneAndSignInApiRequest(request).pipe(
      tap((data: SingInResponse) => this.handleSignInSuccess(data, extras)),
      catchError((error) => this.handleSignInError(error, extras))
    );
  }

  private signInBase(
    request: SingInRequest,
    extras: SingInExtras
  ): Observable<SingInResponse | SingInErrorResponse> {
    return this.signInApiRequest(request).pipe(
      tap((data: SingInResponse) => this.handleSignInSuccess(data, extras)),
      catchError((error) => this.handleSignInError(error, extras))
    );
  }

  private signInApiRequest(request: SingInRequest): Observable<SingInResponse> {
    const body = this.transformRequest(request);
    return this.http.post<SingInResponse>(`${this.serviceUrl}/token`, body, {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      withCredentials: true,
    });
  }

  private transformRequest(obj) {
    const str = [];
    for (const p in obj)
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    return str.join("&");
  }

  private confirmPhoneAndSignInApiRequest(
    request: SingInMfaRequest
  ): Observable<SingInResponse> {
    return this.http.post<SingInResponse>(
      `${this.serviceUrl}/api/phone-number/confirm`,
      null,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-Ryno-Mfa-Token": request.mfa_token,
          "X-Ryno-Mfa-Code": request.mfa_code,
        },
      }
    );
  }

  private handleSignInSuccess(response: SingInResponse, extras: SingInExtras) {
    if (response?.access_token)
      this.oauthService.sessionBegin(
        extras.loginType,
        response.access_token,
        response.expires_in,
        response.refresh_token,
        response.refresh_token_expires_in
      );

    this.handleLocalLoginData(extras?.rememberMe, extras?.username);
    window.analytics.identify(extras?.username);
    const history = sessionStorage.getItem("navigation.history") ?? "home";
    this.router.navigate([history]);
  }

  private handleSignInError(
    response: any,
    extras: SingInExtras
  ): Observable<SingInErrorResponse> {
    if (response.status === 401) {
      this.messageService.error(
        "The email and password provided did not match our records. Double check this info and try again."
      );
      return of({ hasError: true });
    }

    if (response.status === 400 && response?.error?.error === "invalid_grant") {
      this.messageService.error(
        "The email and password provided did not match our records. Double check this info and try again."
      );
      return of({ hasError: true, errorCode: MfaErrorCodeType.InvalidMfaCode });
    }

    if (response.status === 400) {
      this.messageService.error(response);
      return of({ hasError: true });
    }

    if (response.status === 403 && this.hasMfaError(response?.error?.error)) {
      return this.handleMfaErrors(response?.error, extras);
    }

    this.messageService.error("Error occurred while trying to log in.");
    return of({ hasError: true });
  }

  private hasMfaError(errorType: any) {
    return Object.values(MfaErrorType).includes(errorType);
  }

  private handleMfaErrors(mfaResponse: MfaApiResponse, extras: SingInExtras) {
    switch (mfaResponse.error) {
      case MfaErrorType.MfaRequired:
        return this.handleMfaRequiredError(mfaResponse, extras);
      case MfaErrorType.MfaCodeError:
        return this.handleMfaCodeError(mfaResponse);
      default:
        console.error(`Mfa error: ${mfaResponse.error}`);
        return of({ hasError: true });
    }
  }

  private handleMfaRequiredError(
    mfaResponse: MfaApiResponse,
    extras: SingInExtras
  ) {
    const contextData: MfaContextData = {
      username: extras.username,
      rememberMe: extras.rememberMe,
      loginType: extras.loginType,
    };

    const config: MfaConfig = {
      canAddPhoneNumberIfMissing: true,
      canChangePhoneNumberIfUnverified: true,
      isTrustingDeviceAllowed: true,
    };

    const data: InitMfaData = {
      mfaContextType: MfaContextType.Login,
      mfaResponse,
      contextData,
      config,
    };

    this.mfaService.initMfa(data);
    return of({ hasError: true });
  }

  private handleMfaCodeError(mfaResponse: MfaApiResponse) {
    if (
      mfaResponse?.send_code_status?.error_result?.error ===
      MfaErrorCodeType.InvalidMfaCode
    ) {
      return of({ hasError: true, errorCode: MfaErrorCodeType.InvalidMfaCode });
    }
  }

  forgotPassword(email: any): Observable<any> {
    return this.http.post(`${this.serviceUrl}/api/password/forgot`, {
      email: email,
    });
  }

  private handleLocalLoginData(remember: boolean, email: string) {
    if (remember) {
      localStorage.setItem("loginData", JSON.stringify({ email }));
    } else {
      localStorage.removeItem("loginData");
    }
  }
}
