import { Injectable } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { MessageData } from "./message.model";
import { HttpClient } from "@angular/common/http";
import { AppSettings } from "../../app.settings";
import { DismissableBaseService } from "../dismissable-base/dismissable-base.service";
import { CacheHeaders, ConfigService } from "common";

@Injectable({
  providedIn: "root",
})
export class MessageService extends DismissableBaseService<MessageData> {
  constructor(
    private http: HttpClient,
    private settings: AppSettings,
    private configService: ConfigService
  ) {
    super();
  }

  getDismissedMessagesStorage() {
    return localStorage.getItem("dismissedMessages")
      ? JSON.parse(localStorage.getItem("dismissedMessages"))
      : [];
  }

  query(entityId: number): Observable<MessageData[]> {
    return this.http.get<MessageData[]>(
      `${this.settings.messages.url}/api/messages/entities/${entityId}`,
      {
        headers: this.configService.environment.cache.mediumLived,
      }
    );
  }

  update(
    customerId: number,
    entityId: number,
    message: MessageData,
    dismiss: boolean
  ): Observable<any> {
    if (message?.isEntityMessage) {
      return this.updateMessage(
        `${this.settings.messages.url}/api/messages/${message?.id}/entities/${entityId}`,
        dismiss,
        entityId
      );
    }

    return this.updateMessage(
      `${this.settings.messages.url}/api/messages/${message?.id}/customers/${customerId}`,
      dismiss,
      entityId
    );
  }

  private updateMessage(
    url: string,
    dismiss: boolean,
    entityId: number
  ): Observable<any> {
    return this.http.put<MessageData[]>(
      url,
      { dismiss: dismiss },
      {
        headers: {
          [CacheHeaders.Invalidate]: [
            `${this.settings.messages.url}/api/messages/entities/${entityId}`,
          ],
        },
      }
    );
  }

  sendReferEmail(customerId: number, emails: string[]): Observable<any> {
    return this.http.post<any>(
      `${this.settings.leads.url}/api/lead/${customerId}/refer-via-email`,
      { emails: emails }
    );
  }
}
