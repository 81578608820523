import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { AppSettings } from '../../../../app.settings';
import { AccountTransfer } from "../account-transfer.model";

@Component({
  selector: 'ifp-account-transfer-submitted-dialog',
  templateUrl: './account-transfer-submitted-dialog.component.html',
  styleUrls: ['./account-transfer-submitted-dialog.component.scss']
})
export class AccountTransferSubmittedDialogComponent {
  constructor(public dialogRef: MatDialogRef<AccountTransferSubmittedDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public settings: AppSettings,
  ) { }

  static show(
    dialog: MatDialog,
    transferInfo: AccountTransfer,
    title: string,
    action: string,
    bankIntegrationRequired?: boolean,
    closeButtonLabel?: string
  ): Observable<any> {
    return dialog.open(AccountTransferSubmittedDialogComponent, {
      width: '510px',
      data: {
        transferInfo: transferInfo,
        title: title,
        action: action,
        bankIntegrationRequired: bankIntegrationRequired,
        closeButtonLabel: closeButtonLabel
      },
      closeOnNavigation: true,
    }).afterClosed();
  }
}
