import { Component, OnInit } from '@angular/core';
import {
  bankLogoExists,
  FinancialInstitution,
  getBankLogo
} from '../bank-account.model';
import { EnumHelper, AppBarTitleService, CompanyBranding, BankAccountType, BrandingService } from 'common';
import { ActivatedRoute, Router } from '@angular/router';
import { AppSettings } from '../../../../src/app.settings';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'ifp-bank-action-required',
  templateUrl: './bank-action-required.component.html',
  styleUrls: ['./bank-action-required.component.scss']
})
export class BankActionRequiredComponent implements OnInit {
  customerMessages: string[] = [];
  contactPhone: string;
  contactEmail: string;
  data: BankActionRequiredComponentData;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appBarTitleService: AppBarTitleService,
    private settings: AppSettings,
    private brandingService: BrandingService
  ) {
    sessionStorage.setItem('bankActionRequiredViewed', JSON.stringify(true));
  }

  ngOnInit() {
    this.route.data.pipe(untilDestroyed(this)).subscribe(this.dataInit.bind(this));
    this.initBrandingInfo();
  }

  private initBrandingInfo(): void {
    this.brandingService.getBranding$()
      .pipe(untilDestroyed(this))
      .subscribe((branding: CompanyBranding) => {
        this.contactPhone = branding?.phone ?? this.settings.if.contact.phone;
        this.contactEmail = branding?.infoEmail ?? 'info@ideafinancial.com';
      });
  }

  private dataInit(data: BankActionRequiredComponentData) {
    this.data = data;
    this.appBarTitleService.title = 'Bank action required';

    if (data.financialInstitutions && data.financialInstitutions.some(_ => _.customerMessages))
      data.financialInstitutions.forEach(_ => {
        if (_.customerMessages.length > 0)
          _.customerMessages.forEach(msg => { this.customerMessages.push(msg) });
      });
  }

  logoExists(_institutionId?: string, institutionName?: string) {
    return bankLogoExists(institutionName);
  }

  getLogo(_institutionId: string, institutionName?: string) {
    return getBankLogo(institutionName);
  }

  getBankAccountTypeName(type: string) {
    if (!type) return;
    return EnumHelper.getNameFromValue(BankAccountType, type);
  }

  skip() {
    console.log('redirected from bank-account-link.component to ' + this.data.cancelUrl);
    this.router.navigate([this.data.cancelUrl]);
  }

  openBankWebsite(financialInstitution: FinancialInstitution) {
    if (financialInstitution.institutionUrl)
      window.open(financialInstitution.institutionUrl, "_blank");
  }
}

export interface BankActionRequiredComponentData {
  financialInstitutions?: FinancialInstitution[];
  cancelUrl?: string;
}
