  <div id="main" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="24px">
    <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="12px">
      <div class="content" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="24px" class="layout">
        <span ngClass.gt-sm="title" ngClass.lt-sm="title xs">
          Based on the info you provided, see your quote below.
        </span>

        <img src="../../assets/idea-hartford-logos-01.png" alt="Idea Hartford logos" height="50px">

        <span ngClass.gt-sm="title" ngClass.lt-sm="title xs">
          General Liability Insurance
        </span>
      </div>

      <div fxLayout="row" fxLayoutAlign="center start" fxLayout.lt-md="column" fxLayoutAlign.lt-md="center center"
        fxLayoutGap="32px" class="layout padding-sm">
        <img src="../../assets/store-coverage.svg" alt="Trees and store" height="202px">

        <div class="description" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayoutAlign.lt-md="center center">
          <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="24px">
            <div fxLayout="column" fxLayoutAlign="start start">
              <span class="monthlyAmount">{{monthlyAmount() | currency:'$':'symbol':'1.0-0'}}<span style="vertical-align: super; font-size: 32px;">{{(monthlyAmount() | number:'1.2-2').toString().substring((monthlyAmount() | number:'1.2-2').indexOf('.')+1)}}</span></span>
              <span class="item-title">Monthly premium / {{quote?.totalPremium | currency:'$'}} annually</span>
            </div>

            <div fxLayout="column" fxLayoutAlign="start start" >
              <span class="liabilityAmount">{{liabilityAmount() | currency:'$':'symbol':'1.0-0'}}<span style="vertical-align: super; font-size: 14px;">{{(liabilityAmount() | number:'1.2-2').toString().substring((liabilityAmount() | number:'1.2-2').indexOf('.')+1)}}</span></span>
              <span mat-line class="item-title">Liability amount (Each occurrence)</span>
            </div>
          </div>
          <span class="subtitle">Quoted premium is associated with a single location policy. Coverage is also available for multiple locations.</span>
        </div>
      </div>
    </div>
    <button mat-raised-button class="mat-green-bg" (click)="proceedWithQuote()">TELL ME MORE</button>
    <div fxLayout="row" fxLayoutAlign="start center" *ngIf="notificationData?.dismissable || notificationData?.dismissable === undefined" fxLayoutGap="12px">
      <mat-checkbox color="primary" (change)="dismiss($event)"></mat-checkbox>
      <span class="mat-caption">No thanks, not interested</span>
    </div>
  </div>
