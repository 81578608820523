import { Component, OnInit } from "@angular/core";
import { AppBarTitleService } from "common";
import { AppSettings } from "../../../../src/app.settings";

@Component({
  selector: "ifp-insurance-error",
  templateUrl: "./insurance-error.component.html",
  styleUrls: ["./insurance-error.component.scss"],
})
export class InsuranceErrorComponent implements OnInit {
  insuranceMessageSubject = "We need more information";
  contactPhone: string;

  constructor(
    private appBarTitleService: AppBarTitleService,
    private settings: AppSettings
  ) {
    this.contactPhone =
      this.settings.insurance.phone ?? this.settings.if.contact.phone;
  }

  ngOnInit() {
    this.appBarTitleService.title = this.insuranceMessageSubject;
  }
}
