import { Component, OnInit } from "@angular/core";
import { ErrorsService } from "../../errors.service";
import { ErrorData } from "../../models/errors.model";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ErrorDescriptionType } from "../../enums/error-description-type.enum";

@UntilDestroy()
@Component({
  selector: "ifc-custom-error-page",
  templateUrl: "./custom-error-page.component.html",
})
export class CustomErrorPageComponent implements OnInit {
  errorData: ErrorData;
  ErrorDescriptionType = ErrorDescriptionType;

  constructor(private errorsService: ErrorsService) {}

  ngOnInit(): void {
    this.errorsService.errorData$
      .pipe(untilDestroyed(this))
      .subscribe((data: ErrorData) => {
        this.errorData = data;
      });
  }
}
