import { ActivatedRouteSnapshot } from "@angular/router";

export class RouteHelper {
  static getParamValueByName = (
    route: ActivatedRouteSnapshot,
    paramName: string
  ): string => {
    if (route?.params?.[paramName]) {
      return route?.params?.[paramName];
    }
    return route?.pathFromRoot?.[route?.pathFromRoot?.length - 2]?.params?.[
      paramName
    ];
  };

  static getQueryParamValueByName = (
    route: ActivatedRouteSnapshot,
    paramName: string
  ): string => {
    if (route?.queryParams?.[paramName]) {
      return route?.queryParams?.[paramName];
    }
    return route?.pathFromRoot?.[route?.pathFromRoot?.length - 2]?.queryParams?.[
      paramName
    ];
  };
}
