import { DocumentQueryParams, DocumentQueryResult } from './document.model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ResourceService } from '../resource/resource.service';
import { ConfigService } from '../config/config.service';
import { SignedLoanDocumentFile } from './signed-loan-document-file.model';

export abstract class DocumentBaseService {

  protected _pageSize = 20;
  protected _queryParams: DocumentQueryParams = {};

  constructor(protected http: HttpClient, protected resourceService: ResourceService, protected configService: ConfigService, protected readonly baseUrl: string) {
  }

  get pageSize(): number {
    return this._pageSize;
  }
  set pageSize(value: number) {
    this._pageSize = value;
  }

  get queryParams(): DocumentQueryParams {
    return this._queryParams;
  }
  set queryParams(value: DocumentQueryParams) {
    this._queryParams = new DocumentQueryParams(value);
  }

  abstract query(parentRecordId: number, params: DocumentQueryParams): Observable<DocumentQueryResult>;

  abstract queryByUniqueId(uniqueId: string, params: DocumentQueryParams): Observable<DocumentQueryResult>;

  abstract delete(fileId: number, parentRecordId: number): Observable<void>;

  abstract download(fileId: number, parentRecordId: number);

  abstract getSignatures(fileId: number): Observable<SignedLoanDocumentFile>;
}
