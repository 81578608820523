import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { CustomerCheckoutState } from "./customer-checkout.model";
import { Observable } from "rxjs";
import { CustomerCheckoutService } from "./customer-checkout.service";
import { Injectable } from "@angular/core";
import { CustomerKbaConversation } from "common";

@Injectable({
    providedIn: 'root'
})
export class CustomerCheckoutStateResolver implements Resolve<CustomerCheckoutState> {

    constructor(private service: CustomerCheckoutService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
        : Observable<CustomerCheckoutState> | Promise<CustomerCheckoutState> | CustomerCheckoutState {
        return this.service.getState();
    }
}

@Injectable({
    providedIn: 'root'
})
export class CustomerKbaQuestionsResolver implements Resolve<CustomerKbaConversation> {

    constructor(private service: CustomerCheckoutService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
        : Observable<CustomerKbaConversation> | Promise<CustomerKbaConversation> | CustomerKbaConversation {
        return this.service.getQuestions();
    }
}
