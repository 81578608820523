<div fxLayout="column wrap" fxLayoutAlign="center center" class="wrapper">
  <form (ngSubmit)="submit()" [formGroup]="form" fxLayout="column" ngClass.xs="xs-padding-bar" fxLayoutGap.xs="0px">

    <ifp-company-select></ifp-company-select>

    <mat-form-field appearance="outline">
      <mat-label>Phone number</mat-label>
      <input matInput placeholder="Enter your phone number" autocomplete="tel" required formControlName="phone"
      customMask="(000) 000-0000">
      <mat-error>
        Incorrect phone number.
      </mat-error>
    </mat-form-field>

    <div formGroupName="address" fxLayout="column" fxLayoutAlign="start">
      <mat-form-field appearance="outline" fxFlex>
        <mat-label>Address line 1</mat-label>
        <input matInput placeholder="Enter your address line 1" autocomplete="address-line1" required
          formControlName="line1" ngx-google-places-autocomplete #placesRef="ngx-places"
          (onAddressChange)="handleAddressChange($event)"
          [options]="googleAutocompleteOptions">
        <mat-error>
          Incorrect Address.
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" fxFlex>
        <mat-label>Address line 2</mat-label>
        <input matInput placeholder="Enter your address line 2" autocomplete="address-line2" formControlName="line2">
      </mat-form-field>

      <div class="address">
        <mat-form-field appearance="outline" class="address__item">
          <mat-label>City</mat-label>
          <input matInput placeholder="Enter your city" autocomplete="off" required formControlName="city">
          <mat-error>
            Incorrect city.
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="address__item">
          <mat-select placeholder="State" formControlName="state">
            <mat-option *ngFor="let state of states" [value]="state">
              {{state}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="address__item">
          <mat-label>Zip</mat-label>
          <input matInput placeholder="Enter your zip" autocomplete="postal-code" required formControlName="zip">
          <mat-error>
            Incorrect zip code format.
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="end start">
      <button mat-button class="layout margin" routerLink="/home" type="button">Cancel</button>
      <button mat-raised-button color="primary" class="layout margin margin-right-0" type="submit"
        [disabled]="submitSub || !form.valid || !form.dirty">Save</button>
    </div>

  </form>
</div>
