import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from "@angular/router";
import { Observable, combineLatest, of } from "rxjs";
import { Injectable } from "@angular/core";
import { map, switchMap, take } from "rxjs/operators";
import { RouteHelper, parseNumber } from "common";
import { CustomerCheckoutService } from "../customer/customer-checkout/customer-checkout.service";
import { LoanFacade } from "../loan/domain/+state/loan.facade";
import { ApplicationFacade } from "../application/domain/+state/application.facade";
import { CustomerCheckoutState } from "../customer/customer-checkout/customer-checkout.model";



@Injectable({
  providedIn: "root",
})
export class CustomerCheckoutGuard implements CanActivate {
  constructor(
    private router: Router,
    private customerCheckoutService: CustomerCheckoutService,
    private loanFacade: LoanFacade,
    private applicationFacade: ApplicationFacade
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {

    const accountIdPath = parseNumber(RouteHelper.getParamValueByName(route, "accountId"));
    const applicationIdPath = parseNumber(RouteHelper.getParamValueByName(route, "appId"));

    if ((accountIdPath && JSON.parse(sessionStorage.getItem("accountSetupViewed"))) ||
      (applicationIdPath && JSON.parse(sessionStorage.getItem("applicationAgreementViewed")) ||
        !accountIdPath && !applicationIdPath && JSON.parse(sessionStorage.getItem("accountSetupViewed")))) {
      return true;
    }

    return this.customerCheckoutService
      .getState()
      .pipe(switchMap((kbaState) => this.validate(kbaState, accountIdPath, applicationIdPath)));
  }

  validate(
    kbaState: CustomerCheckoutState,
    accountIdPath: number,
    applicationIdPath: number
  ): Observable<boolean> {
    if (kbaState.verifyKba) {
      if (accountIdPath) {
        return this.loanFacade.getFirstAvailable(accountIdPath).pipe(
          map((loan) => {
            this.router.navigate([`account/${loan.id}/setup`]);
            return false;
          })
        );
      }
      else if (applicationIdPath) {
        return this.applicationFacade.getFirstAvailableForSign(applicationIdPath).pipe(map(application => {
          this.router.navigate([`account/setup`]);
          return false;
        }));
      }

      else {
        return combineLatest([
          this.applicationFacade.getFirstAvailableForSign(applicationIdPath),
          this.loanFacade.getFirstAvailable(accountIdPath)
        ])
          .pipe(
            take(1),
            map(([application, loan]) => {
              if (application) {
                this.router.navigate([`account/setup`]);
                return false;
              }
              else if (loan) {
                this.router.navigate([`account/${loan.id}/setup`]);
                return false;
              }

              else
                return true;
            }));
      }
    }
    if (kbaState.kbaError) {
      this.customerCheckoutService.verifyAnswersError = {
        error_description: kbaState.kbaError,
      };
      this.router.navigate(["account", "setup", "verify-identity-result"]);
      return of(false);
    }
    return of(true);
  }
}
