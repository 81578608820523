import { Component, Input, OnChanges } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { EntityRecentActivity, EntityRecentActivityEvent } from "common";

@Component({
  selector: "ifp-recent-activity-widget",
  templateUrl: "./recent-activity-widget.component.html",
  styleUrls: ["./recent-activity-widget.component.scss"],
})
export class RecentActivityWidgetComponent implements OnChanges {
  @Input() recentActivity: EntityRecentActivity[];

  dataSource = new MatTableDataSource<EntityRecentActivity>();
  displayedColumns: string[] = ["icon", "date", "loan", "description"];

  get EntityRecentActivityEvent() {
    return EntityRecentActivityEvent;
  }

  ngOnChanges(): void {
    this.dataSource = new MatTableDataSource(this.recentActivity);
  }

  hasIcon = (activity): boolean => {
    return !this.getInitial(activity);
  };

  getIcon = (activity: EntityRecentActivityEvent) => {
    return `../../../assets/recent-activity-icons/${activity}.svg`;
  };

  getInitial(activity) {
    switch (activity) {
      case EntityRecentActivityEvent.DrawFundsCommitted:
        return "D";
      case EntityRecentActivityEvent.AutomaticPaymentGenerated:
      case EntityRecentActivityEvent.PaymentCommitted:
        return "P";
      case EntityRecentActivityEvent.OriginationFeePosted:
        return "O";
      case EntityRecentActivityEvent.UCCFilingFeePosted:
        return "U";
      default:
        return null;
    }
  }
}
