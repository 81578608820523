import { OfferLocCalculator } from "./offer-loc-calculator";

export class OfferLawCalculator extends OfferLocCalculator {
  protected getAnnualRate(): number {
    return this.rate / 100;
  }

  protected getMonthlyRate(): number {
    return this.annualRate / 12;
  }
}
