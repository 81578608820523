import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class FlutterEventGuard implements CanActivate {
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (state?.url !== "/sign-out" && state?.url !== "/sign-in") {
      return true;
    }

    if ((window as any)?.flutter_inappwebview) {
      (window as any).flutter_inappwebview.callHandler("flutterLogoutEvent");
      return false;
    }

    return true;
  }
}
