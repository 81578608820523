import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ifc-app-card',
  templateUrl: './app-card.component.html',
  styleUrls: ['./app-card.component.scss']
})
export class AppCardComponent implements OnInit {

  constructor() { }

  @Input()
  align: string;

  classes: { [name: string]: boolean } = {
    'app-card': true,
    'flex': true,
    'row': true
  };


  @Input()
  asideOrderLtLg: number;

  @Input()
  asideOrderGtMd: number;

  @Input()
  asideHideXS: boolean;

  @Input()
  asideHideSM: boolean;

  @Input()
  fxLayoutDirection: string = 'column';

  ngOnInit() {
    if (this.align)
      this.classes[this.align] = true;
    else
      this.classes['fixed-width'] = true;

  }
}
