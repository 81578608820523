import { NgModule } from "@angular/core";
import { OfferCommonModule } from "../../common/offer-common.module";
import { OfferDisclosuresComponent } from "./offer-disclosures.component";

@NgModule({
  imports: [OfferCommonModule],
  declarations: [OfferDisclosuresComponent],
  exports: [OfferDisclosuresComponent],
})
export class OfferDisclosuresModule {}
