import { NgModule } from '@angular/core';
import { FiltersActiveListComponent } from './filters-active-list.component';
import { MaterialModule } from '../../material/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  imports: [
    MaterialModule,
    FlexLayoutModule
  ],
  exports: [FiltersActiveListComponent],
  declarations: [FiltersActiveListComponent]
})
export class FiltersActiveListModule { }
