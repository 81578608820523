import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { MatCheckbox } from "@angular/material/checkbox";
import { EMPTY, Subscription } from "rxjs";
import {
  MessageService,
  FeeType,
  PaymentFrequencyLabel,
  DocumentCategory,
  EnumHelper,
  ProductCode,
  AppPageService,
} from "common";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ApplicationAgreementService } from "../application-agreement.service";
import { AcceptApplicationAgreementResponse } from "../application-agreement.data";
import { AgreementDto, OfferBundleInfo, OfferInfo } from "../../agreement/agreement.data";
import { CustomerFacade } from "../../customer/domain/+state/customer.facade";
import { BankAccountService } from "../../bank-account/bank-account.service";
import { CustomerData } from "../../customer/domain/models/customer.model";
import { ApplicationData } from "../../application/application.model";
import { catchError } from "rxjs/operators";

@UntilDestroy()
@Component({
  selector: "ifp-application-agreement-form",
  templateUrl: "./application-agreement-form.component.html",
  styleUrls: ["./application-agreement-form.component.scss"],
})
export class ApplicationAgreementFormComponent implements OnInit {
  data: EntityAgreementFormComponentData;
  productCodes = ProductCode;
  templateId?: number;
  currentOffer: OfferInfo;
  agreementDto: AgreementDto;
  activationMessage: string;
  agreementHtml: SafeHtml;
  bankIntegrationRequired: boolean;
  isDisbursementBankEligible: boolean = false;

  @ViewChild("agreementCheckbox")
  agreementCheckbox: MatCheckbox;

  @ViewChild("agreementLeft")
  agreementLeft: ElementRef;

  @ViewChild("agreementContent")
  agreementContent: ElementRef;

  @ViewChild("agreementActions")
  agreementActions: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private entityAgreementService: ApplicationAgreementService,
    private messageService: MessageService,
    private customerFacade: CustomerFacade,
    private sanitizer: DomSanitizer,
    private bankAccountService: BankAccountService,
    private _snackBar: MatSnackBar,
    private appPageService: AppPageService
  ) {
    sessionStorage.setItem("applicationAgreementViewed", JSON.stringify(true));
    if ((window as any)?.flutter_inappwebview) {
      this.isInWebView = true;
    }
  }

  isInWebView: boolean = false;

  get FeeType() {
    return FeeType;
  }

  get hasDisclosures(): boolean {
    return (this.agreementDto?.disclosures?.length > 0);
  }

  get categoryName() {
    return EnumHelper.getNameFromValue(
      DocumentCategory,
      this.agreementDto?.category
    );
  }

  download() {
    this.entityAgreementService.download(
      this.data.application.id
    );
  }

  reloadComponent() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = "reload";
    this.router.navigate([`application/${this.data.application.id}/setup/sign-agreement`]);
  }

  ngOnInit() {
    this.route.data
      .pipe(untilDestroyed(this))
      .subscribe((data: EntityAgreementFormComponentData) =>
        this.onDataInit(data)
      );

    this.entityAgreementService
      .getAgreement(this.data.application.id)
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        this.templateId = data.templateId;
        this.agreementDto = data;
        this.agreementHtml = this.sanitizer.bypassSecurityTrustHtml(
          this.agreementDto?.html
        );
      });

    this.currentOffer = this.data.offerBundlesInfo?.selectedOffer;

    this.bankIntegrationRequired = false;
    if (this.data?.application?.entityId) {
      this.bankAccountService
        .getIsBankLinkReminderRequired(this.data.application.entityId)
        .subscribe(
          (isBankLinkReminderRequired) =>
            (this.bankIntegrationRequired = isBankLinkReminderRequired)
        );

      this.bankAccountService
        .getAccountsInfo(this.data.application.entityId)
        .subscribe((accountInfo) => {
          this.isDisbursementBankEligible =
            accountInfo.isDisbursementBankEligible;

          this.checkDisbursementAccount();
        });
    }
  }

  private onDataInit(data: EntityAgreementFormComponentData): void {
    this.data = data;
  }

  print() {
    const innerContents = document.getElementById(
      `account-setup-loc-agreement`
    ).innerHTML;
    const popupWinindow = window.open(
      "",
      "_blank",
      "width=600,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no"
    );
    popupWinindow.document.open();
    popupWinindow.document.write(
      `<html><head><link rel="stylesheet" type="text/css" href="style.css" /></head><body onload="window.print()">${innerContents}</html>`
    );
    popupWinindow.document.close();
  }

  get offerPaymentFrequencyLabel() {
    return PaymentFrequencyLabel.get(this.currentOffer.paymentFrequency);
  }

  review() {
    if (!this.checkDisbursementAccount()) return;

    this.submit();
  }

  hideDisclosure() {
    document.getElementById(
      "account-setup-agreement-disclosure"
    ).style.display = "none";
  }

  private checkDisbursementAccount(): boolean {
    if (!this.isDisbursementBankEligible) {
      this._snackBar.open(
        "Your disbursement bank account is invalid. Please contact your business advisor.",
        "",
        { duration: 5000 }
      );
      return false;
    }
    return true;
  }

  submit() {
    if (this.submitSub) return;

    this.customerFacade.clearCustomerCache();

    this.submitSub = this.entityAgreementService
      .submit(this.data.application.id, {
        accept: true,
        templateId: this.templateId
      })
      .pipe(catchError((error) => {
        return this.submitError(error);
      }))
      .subscribe(this.submitSuccess.bind(this));
  }

  submitError(error: any) {
    this.submitSubClear();
    this.messageService.error(error);
    return EMPTY;
  }

  submitSub: Subscription;

  submitSubClear() {
    if (this.submitSub) this.submitSub.unsubscribe();
    this.submitSub = null;
  }

  submitSuccess(response: AcceptApplicationAgreementResponse) {
    this.submitSubClear();

    if (!response.isFinal) {
      this.reloadComponent();
      return;
    }

    if (response.applicationId) {
      if(!this.appPageService.backToIfPossible(true))
        this.router.navigate(["dashboard"]);
    } else {
      this.messageService.info(
        `Please make sure you checked 'By clicking this checkbox you agree to the terms and conditions set forth in the agreement above.'`
      );
    }
  }
}

export interface EntityAgreementFormComponentData {
  customer: CustomerData;
  application: ApplicationData;
  offerBundlesInfo: OfferBundleInfo;
}
