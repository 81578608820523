<div id="app-toolbar" fxLayout="column" fxLayoutAlign="center center">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div *ngFor="let item of toolbarMenuItems">
      <a mat-button [routerLink]="[item.url]" routerLinkActive="active">
        <div fxLayout="column" fxLayoutAlign="center center">
          <mat-icon>{{item.icon}}</mat-icon>
        </div>
      </a>
    </div>
  </div>
</div>
