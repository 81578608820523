import { Component, Input } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { AccountBaseService } from '../account-base-service';
import { AutocompleteComponent } from '../../ui/autocomplete/autocomplete.component';
import { LoanStatus } from '../../loan/loan.model';
import { AccountHintData } from '../account.model';
import { ProductCode } from '../../product/product.model';

@Component({
  selector: 'ifc-account-loan-number-input',
  templateUrl: '../../ui/autocomplete/autocomplete.component.html',
  styleUrls: ['../../ui/autocomplete/autocomplete.component.css']
})
export class AccountLoanNumberInputComponent extends AutocompleteComponent {

  @Input()productCode: ProductCode;
  @Input()excludedProductCode: ProductCode;
  @Input()includedStatus: LoanStatus;
  @Input()excludedStatus: LoanStatus;
  @Input()excludeLoansWithNoOwnerAssigned: boolean;
  @Input()excludeLoansWithResolvedCase: boolean;


  @Input()
  label: string = 'Loan number';

  constructor(private accountService: AccountBaseService) {
    super();
  }

  optionFilter: (input: string | AccountHintData) => Observable<any[]> =
    input => input
      ? this.accountService.queryHints({
        loanNumber: !!input ? (typeof input !== 'string' ? input.loanNumber : input) : null,
        sortField: 'loanNumber',
        includedStatus: this.includedStatus,
        excludedStatus: this.excludedStatus,
        productCode: this.productCode,
        excludedProductCode: this.excludedProductCode,
        excludeLoansWithNoOwnerAssigned: this.excludeLoansWithNoOwnerAssigned,
        excludeLoansWithResolvedCase: this.excludeLoansWithResolvedCase
      })
      : EMPTY;

  optionLabelKey = 'loanNumber';
}
