<div class="checkout">
  <mat-icon class="mat-green" *ngIf="checkoutRequirement.status === checkoutRequirementStatusEnum.Approved; else emptyIcon">check_circle</mat-icon>
  <div class="detail">
    <p class="detail__title">{{checkoutRequirement.requiredDocument}}</p>
    <p class="detail__description">{{checkoutRequirement.notes}}</p>
    <div>
      <p class="detail__uploaded" *ngFor="let document of checkoutRequirement.documents">{{document.filename}}</p>
      <p class="detail__uploadable" *ngFor="let document of tempFiles">{{document.name}}</p>
    </div>
  </div>
  <button mat-button name="Upload" class="checkout__upload-btn" (click)="openUploadDialog()">Upload</button>
</div>
<ng-template #emptyIcon><div class="empty-icon"></div></ng-template>
