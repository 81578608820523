export interface CompanyBranding {
    id?: number;
    domains?: string;
    name?: string;
    dba?: string;
    phone?: string;
    infoEmail?: string;
    url?: string;
    logo?: string;
    mobileLogo?: string;
    mobileLogoTitle?: string;
    logoTitle?: string;
    trustpilotHtml?: string;
    facebookUrl?: string;
    instagramUrl?: string;
    twitterUrl?: string;
    linkedinUrl?: string;
    tosUrl?: string;
    privacyUrl?: string;
    portalUrl?: string;
    portalTopFooterText?: string;
    portalBottomFooterText?: string;
    noReplyEmail?: string;
    legalEmail?: string;
    financeEmail?: string;
    colorSchema?: string | CompanyColorSchemaEnum;
    loginUrl?: string;
    googleReviewUrl?: string;
    trustpilotReviewUrl?: string;
    configuration?: CompanyConfiguration;
}

export interface CompanyConfiguration {
    useIndividualAdvisorPhoneNumbers: boolean;
}

export enum CompanyColorSchemaEnum {
  IdeaFinancial = 'ideafinancial',
  LevelEsq = 'levelesq'
}
