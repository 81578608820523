import {
  ActivatedRoute,
  Params,
  QueryParamsHandling,
  Router,
  RoutesRecognized,
} from "@angular/router";
import { Injectable } from "@angular/core";
import {
  MaintainedQueryParam,
  NavigationConfig,
  RoutesTuple,
} from "./models/navigation.model";
import {
  getDefaultMaintainedQueryParam,
  getNavigationConfig,
} from "./data/navigation-data";
import { QueryParamsHandlingType } from "./models/query-params-handling-type.enum";
import { filter, map, pairwise } from "rxjs/operators";
import { Observable } from "rxjs";

@Injectable()
export class NavigationService {
  private maintainedQueryParams: MaintainedQueryParam[];

  constructor(private router: Router, private route: ActivatedRoute) {
    this.maintainedQueryParams = getDefaultMaintainedQueryParam;
  }

  navigate(path: string, navigationConfig?: NavigationConfig) {
    const config = getNavigationConfig(navigationConfig);
    if (config?.hardRefresh) {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = "reload";
    }

    switch (config.queryParamsHandlingType) {
      case QueryParamsHandlingType.Replace:
        return this.navigateBase(path, "", config.queryParams);

      case QueryParamsHandlingType.Preserve:
        return this.navigateBase(path, "preserve");

      case QueryParamsHandlingType.MergeAll:
        return this.navigateBase(path, "merge", config.queryParams);

      case QueryParamsHandlingType.MergeGiven:
        return this.navigateWithMergedGivenQueryParams(
          path,
          config.queryParams,
          config.queryParamKeysToMerge
        );
    }
  }

  private navigateBase(
    path: string,
    queryParamsHandling: QueryParamsHandling = "",
    queryParams?: Params
  ) {
    this.router.navigate([path], {
      queryParams,
      queryParamsHandling,
    });
  }

  private navigateWithMergedGivenQueryParams(
    path: string,
    queryParams: Params,
    queryParamKeysToMerge: string[]
  ) {
    if (!queryParamKeysToMerge) {
      return this.navigateBase(path);
    }
    const currentQueryParams = this.route.snapshot.queryParams;

    const newQueryParams = queryParamKeysToMerge.reduce((acc, key) => {
      if (!!queryParams && key in queryParams) {
        acc[key] = queryParams[key];
      } else if (!!currentQueryParams && key in currentQueryParams) {
        acc[key] = currentQueryParams[key];
      }

      return acc;
    }, {});

    return this.navigateBase(path, "", newQueryParams);
  }

  handleQueryParamDuringEachNavigation() {
    this.provideLastTwoRecognizedRoutes().subscribe((routes: RoutesTuple) => {
      const currentEvent: RoutesRecognized = routes.current;
      const previousQueryParams: Params =
        routes.previous.state.root.queryParams;
      const currentQueryParams: Params = currentEvent.state.root.queryParams;
      const targetPath: string = currentEvent.urlAfterRedirects.split("?")[0];

      const targetQueryParams = this.maintainedQueryParams.reduce(
        (acc, item) => {
          return ({
            ...acc,
            ...this.provideQueryParams(
              currentQueryParams,
              previousQueryParams,
              item.excludePaths,
              targetPath,
              item.name
            ),
          });
        },
        {}
      );

      if (Object.keys(targetQueryParams).length === 0) {
        return;
      }

      this.router.navigate([targetPath], { queryParams: targetQueryParams });
    });
  }

  private provideQueryParams(
    currentQueryParams: Params,
    previousQueryParams: Params,
    excludePaths: string[],
    targetPath: string,
    queryParamName: string
  ): Params {
    if (
      this.hasQueryParamWithName(currentQueryParams, queryParamName) ||
      !this.hasQueryParamWithName(previousQueryParams, queryParamName) ||
      this.hasExcludedPath(excludePaths, targetPath)
    ) {
      return undefined;
    }

    const result = {
      ...currentQueryParams,
      [queryParamName]: previousQueryParams[queryParamName],
    };

    return result;
  }

  public addQueryParamToMaintain(param: MaintainedQueryParam) {
    this.maintainedQueryParams.push(param);
  }

  public removeQueryParamFromMaintenance(paramName: string) {
    this.maintainedQueryParams = this.maintainedQueryParams?.filter(
      (item) => item.name !== paramName
    );
  }

  private provideLastTwoRecognizedRoutes(): Observable<RoutesTuple> {
    return this.router.events.pipe(
      filter((evt: any) => evt instanceof RoutesRecognized),
      pairwise(),
      map((events: RoutesRecognized[]) => {
        return {
          previous: events[0],
          current: events[1],
        };
      })
    );
  }

  private hasQueryParamWithName(params: Params, paramName: string) {
    return !!params && paramName in params;
  }

  private hasExcludedPath(excludePaths: string[], targetPath: string) {
    return excludePaths?.some((path) => targetPath.includes(path));
  }
}
