<ifc-app-scroll-container>

  <table mat-table
         aria-label="Recent documents table"
         [dataSource]="dataSource"
         *ngIf="dataSource.data?.length">
    <ng-container matColumnDef="icon">
      <th mat-header-cell
          *matHeaderCellDef></th>
      <td mat-cell
          *matCellDef="let row"
          class="table-cell table-cell--calendar">
        <mat-icon class="calendar_icon">today</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell
          *matHeaderCellDef> Date </th>
      <td mat-cell
          *matCellDef="let row"
          class="table-cell">
        {{row.date | date:"mediumDate"}}
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell
          *matHeaderCellDef> Name </th>
      <td mat-cell
          *matCellDef="let row"
          class="table-cell">
        {{row.name}}
      </td>
    </ng-container>

    <ng-container matColumnDef="category">
      <th mat-header-cell
          *matHeaderCellDef> Category </th>
      <td mat-cell
          *matCellDef="let row"
          class="table-cell">
        {{row.category | documentCategoryLabel}}
      </td>
    </ng-container>

    <ng-container matColumnDef="download" stickyEnd>
      <th mat-header-cell *matHeaderCellDef style="width: 40px"></th>
      <td mat-cell *matCellDef="let row" class="table-cell" ngClass.gt-md="action-icon actions-menu" ngClass.lt-lg="actions-menu-xs">
        <div fxLayout="row" fxLayoutAlign="center start">
          <button mat-icon-button (click)="onClickDownload(row.id)">
            <mat-icon>download</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: true"
        class="table-header"></tr>
    <tr mat-row
        *matRowDef="let row; columns: displayedColumns;"
        class="table-row"></tr>
  </table>

</ifc-app-scroll-container>
