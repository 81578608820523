import { MatSort } from '@angular/material/sort';

export class MatTableHelper {

    static getSort(sortQuery: string, sort: MatSort): string {
        const isAsc = sort.direction === 'asc';
        const isEmpty = sort.direction === '';
        sortQuery = isAsc ? '' : '-';
        sortQuery = !isEmpty ? sortQuery += sort.active : undefined;
        return sortQuery;
    }
}
