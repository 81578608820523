import { Component, OnInit } from "@angular/core";
import { AppBarTitleService, } from "common";
import { InsuranceService } from "../insurance.service";
import { ActivatedRoute, Router } from "@angular/router";
import { MessageService } from "../../message/message.service";
import { MessageData, MessageSubjects } from "../../message/message.model";
import { InsuranceQuoteBaseComponent } from "./insurance-quote-base.component";
import { CustomerFacade } from "../../customer/domain/+state/customer.facade";
import { EntityFacade } from "../../entity/domain/+state/entity.facade";

@Component({
  selector: "ifp-insurance-quote-message",
  templateUrl: "./insurance-quote.component.html",
  styleUrls: ["./insurance-quote.component.scss"],
})
export class InsuranceQuoteMessageComponent
  extends InsuranceQuoteBaseComponent<MessageData>
  implements OnInit
{
  constructor(
    appBarTitleService: AppBarTitleService,
    insuranceService: InsuranceService,
    entityFacade: EntityFacade,
    customerFacade: CustomerFacade,
    router: Router,
    messageService: MessageService,
    route: ActivatedRoute
  ) {
    super(
      appBarTitleService,
      insuranceService,
      entityFacade,
      customerFacade,
      router,
      messageService,
      route
    );
    super.searchCondition = {
      subject: MessageSubjects.GeneralLiabilityInsurance,
    };
  }
}
