import { Component, Input, Output, EventEmitter } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { DialogHtml, EntityData, OptInResponseDto } from "common";
import { MoreInfoDialogComponent } from "../../insurance/more-info-dialog/more-info-dialog.component";
import { MessageData, ButtonAppearance } from "../../message/message.model";
import { RenewalService } from "../../renewal/renewal.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "ifp-dashboard-offer-widget",
  templateUrl: "./dashboard-offer-widget.component.html",
  styleUrls: ["./dashboard-offer-widget.component.scss"],
})
export class DashboardOfferWidgetComponent {
  @Input() message: MessageData;
  @Input() entity: EntityData;
  @Output() dismiss = new EventEmitter<any>();

  buttonAppearance = ButtonAppearance;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private renewalService: RenewalService
  ) {}

  setDismiss() {
    this.dismiss.emit();
  }

  link(link: string): void {
    if (link.startsWith("/")) this.router.navigate([link]);
    else if (link.startsWith("action:")) {
      const methodName = link.split(":")[1];
      if (this[methodName]) this[methodName]();
    } else window.location.href = link;
  }

  getQuote() {
    const quoteUrl = "/insurance-quote";
    if (this.entity.establishedDate) {
      this.router.navigate([quoteUrl]);
      return;
    }

    const dialogRef = this.dialog.open(MoreInfoDialogComponent, {
      width: "500px",
      closeOnNavigation: true,
      data: { entityId: this.entity.id },
    });
    dialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        if (result) {
          this.router.navigate([quoteUrl]);
        }
      });
  }

  optIn() {
    this.renewalService
      .putOptIn(this.message?.loanId)
      .subscribe((data: OptInResponseDto) => {
        this.renewalService.optInChanged.next(data);
        this.router.navigate([`account/${data?.loanId}/renewal/documents`]);
      });
  }

  showExtra() {
    this.dialog.open(DialogHtml, {
      width: "600px",
      closeOnNavigation: true,
      data: { message: this.message.extra },
    });
  }
}
