import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import * as moment from "moment";
import {
  DocumentCategory,
  DocumentCategoryLabel,
  DocumentFilters,
  DocumentQueryParams,
  EnumHelper,
  FilterParameter,
  MatTableHelper,
} from "common";

@Injectable({
  providedIn: "root",
})
export class EntityDocumentsFilterService {
  constructor(private router: Router) {}

  filter(
    filters: DocumentFilters,
    paginator?: MatPaginator,
    sort?: MatSort
  ): void {
    const query = new DocumentQueryParams(filters);

    // Set default to first page
    query.skip = undefined;

    query.fromDate = filters.fromDate
      ? moment(filters.fromDate).format("L")
      : undefined;
    query.toDate = filters.toDate
      ? moment(filters.toDate).format("L")
      : undefined;

    if (paginator) {
      query.limit = paginator.pageSize;
      query.skip = paginator.pageIndex * paginator.pageSize;
    }

    if (sort && sort.active) {
      query.sort = MatTableHelper.getSort(query.sort, sort);
    }

    this.router.navigate(['/documents'], {
      replaceUrl: true,
      queryParams: query,
    });
  }

  addFilterParameters(query: DocumentQueryParams): FilterParameter[] {
    // Refresh filters chip list
    const filters: FilterParameter[] = [];

    if (query.text) {
      filters.push({
        id: "text",
        name: "Name",
        value: query.text.trim(),
      });
    }
    if (query.category) {
      filters.push({
        id: "category",
        name: "Category",
        value: EnumHelper.getMappedNameFromValue(
          DocumentCategory,
          DocumentCategoryLabel,
          query.category
        ),
      });
    }
    if (query.fromDate || query.toDate) {
      filters.push({
        id: "fromDate",
        name: "Date",
        value: `${
          query.fromDate || moment().subtract(1, "year").format("L")
        } - ${query.toDate}`,
      });
    }

    return filters;
  }
}
