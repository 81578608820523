import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CustomerLoanStatusLabel } from 'common';
import { AccountStatusIconChipModel } from './account-status-icon-chip.model';

@Component({
  selector: 'ifp-account-status-icon-chip',
  templateUrl: './account-status-icon-chip.component.html',
  styleUrls: ['./account-status-icon-chip.component.css']
})
export class AccountStatusIconChipComponent implements OnChanges {

  @Input()
  accountStatusIconChipModel: AccountStatusIconChipModel;

  @Input()
  status: number;

  statusLabels: any;

  constructor() {
    this.Init();
  }

  ngOnChanges(_: SimpleChanges): void {
    this.Init();
  }

  private Init() {

    this.statusLabels = {
      '0': { color: 'mat-grey-bg', icon: 'help'},
      '1': { color: 'mat-green-bg', icon: 'check_circle' },
      '2': { color: 'mat-red-bg', icon: 'error' },
      '3': { color: 'mat-orange-bg', icon: 'warning', tooltip: this.accountStatusIconChipModel?.needsAttentionMessage },
      '4': { color: 'mat-orange-bg', icon: 'check_circle' },
      '5': { color: 'mat-orange-bg', icon: 'check_circle' },
      '6': [
        { color: 'mat-orange-bg', icon: 'check_circle', label: 'Repayment', condition: (accountStatusIconChipModel: AccountStatusIconChipModel) => !accountStatusIconChipModel?.isLoanFullRepaymentDueDateSet },
        { color: 'mat-orange-bg', icon: 'payments', label: 'Payment Due', condition: (accountStatusIconChipModel: AccountStatusIconChipModel) => accountStatusIconChipModel?.isLoanFullRepaymentDueDateSet }
      ],
      '10': { color: 'mat-orange-bg', icon: 'update'},
      '11': { color: 'mat-red-bg', icon: 'error' },
      '20': { color: 'mat-purple-bg', icon: 'warning' },
      '21': { color: 'mat-orange-bg', icon: 'redo'},
      '30': { color: 'mat-orange-bg', icon: 'pause_circle_filled' },
      '40': { color: 'mat-orange-bg', icon: 'redo' },
      '42': { color: 'mat-orange-bg', icon: 'redo' },
      '50': { color: 'mat-orange-bg', icon: 'redo' },
      '51': { color: 'mat-red-bg', icon: 'error' }
    };

    Object.keys(this.statusLabels).forEach(status => {
      this.statusLabels[status].label = CustomerLoanStatusLabel.get(parseInt(status));
    });
  }
}
