import { Component, OnInit, Input } from "@angular/core";
import {
  bankLinkStatusLabels,
  FinancialInstitution,
  InstitutionIntegrationStatus,
} from "../../bank-account/bank-account.model";
import { BankingService } from "../../bank-account/banking.service";
import { EntityExData } from "common";

@Component({
  selector: "ifp-dashboard-bank-widget",
  templateUrl: "./bank-widget.component.html",
  styleUrls: ["./bank-widget.component.scss"],
})
export class DashboardBankWidgetComponent implements OnInit {
  @Input() bank: FinancialInstitution;
  @Input() entity: EntityExData;

  institutionIntegrationStatus: InstitutionIntegrationStatus;

  get bankLinkStatusLabels() {
    return bankLinkStatusLabels;
  }

  constructor(private _bankingService: BankingService) {}

  ngOnInit() {
    this.institutionIntegrationStatus = this.bank.isLinked
      ? InstitutionIntegrationStatus.Linked
      : InstitutionIntegrationStatus.Unlinked;
  }

  relink() {
    this._bankingService.relink(this.entity.id, this.bank);
  }
}
