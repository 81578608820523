import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import {
  AppBarActionsComponent,
  AppBarCloseAction,
  AppBarComponent,
  AppBarContactComponent,
  AppBarTitleComponent,
  AppFooterComponent,
  AppPageComponent,
  ProductCode,
} from "common";
import { ApplicationResolver } from "../application/application.resolver";
import { OfferSummaryComponent } from "./features/offer-summary/offer-summary.component";
import { OfferBundlesByAppResolver } from "./offer-bundle.resolver";
import { OfferBundleCheckoutRequirementsDocumentsResolver, OfferBundleCheckoutRequirementsResolver, OfferDisclosureResolver } from "./offer.resolver";
import { OfferDisclosuresComponent } from "./features/offer-disclosures/offer-disclosures.component";
import { OfferDetailsComponent } from "./features/offer-details/offer-details.component";
import { OfferDocumentsComponent } from "./features/offer-documents/offer-documents.component";
import { OAuthGuard } from "../../guards/oauth.guard";

const routes: Routes = [
  {
    path: "application/:appId/offers",
    canActivate: [OAuthGuard],
    component: AppPageComponent,
    data: {
      appPage: {
        contentClass: "simple",
        contentAlignment: "center-start",
        contentWithoutPadding: true,
      },
      appBar: {
        stickyDisabled: true,
        closeAction: AppBarCloseAction.HOME,
        logoHidden: true,
      },
      appBarTitle: {
        content: "Offers",
      },
    },
    children: [
      {
        path: "",
        outlet: "app-bar",
        component: AppBarComponent,
        children: [
          {
            path: "",
            outlet: "title",
            component: AppBarTitleComponent,
          },
          {
            path: "",
            outlet: "right",
            component: AppBarContactComponent,
          },
        ],
      },
      {
        path: "",
        outlet: "app-footer",
        component: AppFooterComponent,
        data: {
          appFooter: {
            full: true,
          },
        },
      },
      {
        path: "",
        redirectTo: "summary",
        pathMatch: "full",
      },
      {
        path: "summary",
        component: OfferSummaryComponent,
        resolve: {
          application: ApplicationResolver,
          offerBundles: OfferBundlesByAppResolver,
        },
      },
    ],
  },
  {
    path: "application/:appId/offer/:offerId",
    canActivate: [OAuthGuard],
    component: AppPageComponent,
    data: {
      leadFlowStepContainer: false,
      appBar: {
        expandable: false,
        logoHidden: true,
        stickyDisabled: true,
        contactInfoClass: "mt-auto",
        closeAction: AppBarCloseAction.MANUAL,
      },
      appPage: {
        contentClass: "simple",
        contentAlignment: "center-start",
      },
      appBarTitle: {
        content: "Offers details",
      },
    },
    children: [
      {
        path: "",
        outlet: "app-bar",
        component: AppBarComponent,
        children: [
          {
            path: "",
            outlet: "title",
            component: AppBarTitleComponent,
          },
          {
            path: "",
            outlet: "right",
            component: AppBarContactComponent,
          },
        ],
      },
      {
        path: "",
        outlet: "app-footer",
        component: AppFooterComponent,
        data: {
          appFooter: {
            full: true,
          },
        },
      },
      {
        path: "loc",
        component: OfferDetailsComponent,
        data: {
          productCode: ProductCode.LOC,
        },
      },
      {
        path: "term",
        component: OfferDetailsComponent,
        data: {
          productCode: ProductCode.Term,
        },
      },
      {
        path: "law",
        component: OfferDetailsComponent,
        data: {
          productCode: ProductCode.Law,
        },
      },
    ],
  },
  {
    path: 'application/:appId/offer/:offerId/checkout/documents',
    canActivate: [OAuthGuard],
    component: AppPageComponent,
    data: {
      appBar: {
          closeAction: AppBarCloseAction.BACK,
          expandable: false,
          logoHidden: true
      },
      appBarActions: {
          menuMobile: true
      },
      appPage: {
        contentClass: 'simple',
        contentAlignment: 'center-center',
        contentWithoutPadding: true
      }
    },
    children: [
      {
        path: '',
        component: AppBarComponent,
        outlet: 'app-bar',
        children: [
          {
            path: '',
            outlet: 'title',
            component: AppBarTitleComponent,
          },
          {
            path: '',
            outlet: 'right',
            component: AppBarActionsComponent,
          }
        ]
      },
      {
        path: '',
        component: OfferDocumentsComponent,
        resolve: {
          offerBundle: OfferBundleCheckoutRequirementsResolver,
          application: ApplicationResolver,
          checkoutRequirements: OfferBundleCheckoutRequirementsDocumentsResolver
        }
      }
    ]
  },
  {
    path: "application/:appId/offer/:offerId/disclosures",
    canActivate: [OAuthGuard],
    component: OfferDisclosuresComponent,
    resolve: {
      disclosures: OfferDisclosureResolver,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OfferRoutingModule {}
