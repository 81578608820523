import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppBarAction, AppBarActionsService, DateHelper, EnumHelper, FormHelper, ResolutionStatus } from 'common';
import { Observable } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'ifp-account-liquidity-event-add',
  templateUrl: './account-liquidity-event-add.component.html',
  styleUrls: ['./account-liquidity-event-add.component.scss']
})
export class AccountLiquidityEventAddComponent implements OnInit {

  resolutionStatusOptions = EnumHelper.getNamesAndValues(ResolutionStatus);
  liquidityForm: UntypedFormGroup;
  litigationId: number;
  minDate: Date;
  maxDate: Date;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private appBarActionsService: AppBarActionsService,
    private dialogRef: MatDialogRef<AccountLiquidityEventAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: number
    ) {

    this.litigationId = data;
    let today = new Date();
    today = DateHelper.setToStartOfDay(today);
    this.maxDate = today;

    const monthAgo = new Date();
    monthAgo.setDate(today.getDate() - 30);
    this.minDate = monthAgo;
    this.liquidityForm = this.formBuilder.group({
      resolutionStatus: [undefined, Validators.required],
      resolutionDate: [today, Validators.required],
      isConsented: [false],
      note: [undefined],
    });

    this.appBarActionsService.actions = [
      { id: 'cancel', label: 'Cancel', buttonType: 'button' },
      { id: 'ok', label: 'Ok', disabled: !this.liquidityForm.dirty, buttonType: 'submit', buttonAppearance: 'flat', buttonColor: 'primary' }
    ];
   }

  ngOnInit(): void {
    this.appBarActionsService.invoking.pipe(untilDestroyed(this))
    .subscribe(this.actionDispatch.bind(this))
  }

  isFormDataValid() {
    return this.liquidityForm.valid && this.liquidityForm.dirty;
  }

  confirm() {
    if (!this.isFormDataValid()) {
      FormHelper.showInvalidFormFields(this.liquidityForm);
      return;
    }

    this.dialogRef.close({
      resolutionStatus: this.liquidityForm.controls.resolutionStatus.value,
      resolutionDate: this.liquidityForm.controls.resolutionDate.value,
      note: this.liquidityForm.controls.note.value,
      isConsented: this.liquidityForm.controls.isConsented.value
    })
  }

  static show(dialog: MatDialog, litigationId: number): Observable<any> {
    return dialog
      .open(AccountLiquidityEventAddComponent, {
        data: litigationId,
        closeOnNavigation: true,
      })
      .afterClosed();
  }


  actionDispatch(action: AppBarAction) {
    const actionHandler: (action: AppBarAction) => void =
      this[action.id].bind(this);
    actionHandler(action);
  }
}
