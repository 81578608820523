import { Injectable } from '@angular/core';
import { QueryParams } from './query.data';


@Injectable({
  providedIn: 'root'
})
export class QueryParamsService {

  public init(params?: any): QueryParams {
    const result = Object.assign({}, params);

    if (!result.skip) {
      result.skip = 0;
    }

    if (!result.limit || result.limit <= 0) {
      result.limit = 20;
    }

    return result;
  }
}
