import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { gitInfo } from "../../../../git-info";
import { AppBarTitleService } from "../../../ui/app/app-bar-title/app-bar-title.service";
import { AboutData, VersionData } from "../../models/about.model";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
    selector: 'ifc-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
    public versions: VersionData[] = [];
    public currentVersion: VersionData = {
        version: null,
        date: null,
        name: null,
        notes: null
    };

    public git = gitInfo;

    constructor(
        private route: ActivatedRoute,
        private appBarTitleService: AppBarTitleService) {
    }

    dataSet(data: AboutComponentData) {
        this.appBarTitleService.title = 'About';

        for(var idx = 0; idx < data.about.versions.length; idx++) {
            let v = data.about.versions[idx];

            if(v.version === data.about.currentVersion)
                this.currentVersion = v;
            else
                this.versions.push(v);
        }
    }

    ngOnInit() {
        this.route.data
            .pipe(untilDestroyed(this))
            .subscribe(this.dataSet.bind(this));
    }
}

export interface AboutComponentData {
    about: AboutData;
}