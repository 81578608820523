import { Address, EntityData } from "common";
import { ReminderData } from "../reminder/reminder.model";
import { Contact } from "../shared/models/contact.model";

export interface QuoteRequestData {
    webLeadId?: number;
    entityId?: number;
    insuranceTypes: InsuranceType[];
    businessName: string;
    dba?: string;
    ein?: string;
    naics?: string;
    isSolePropOrNonProfit: boolean;
    annualSales?: number;
    establishedDate?: Date;
    address: Address;
    contact: Contact;
    numberOfOwners?: number;
}

export interface QuoteResponseData {
    quoteRequestId: number;
    quotes: QuoteData[];
}

export interface QuoteData {
    insuranceType: InsuranceType;
    providerId: number;
    providerName: string;
    result: QuoteResult;
    quoteDateUtc: Date;
    message: string;
    externalQuoteId: string;
    policyNumber: string;
    totalPremium: number;
    externalQuoteStatus: string;
    externalQuoteUrl: string;
    extra: any;
}

export enum InsuranceType {
    'none' = 0,
    'general-liability' = 1,
    'professional-liability' = 2,
    'workers-compensation' = 4,
    'commercial-property' = 8,
    'business-income' = 16,
    'business-owners-policy' = 32,
    'data-breach' = 64,
    'commercial-umbrella' = 128,
    'commercial-auto' = 256,
    'cyber' = 512,
    'litigation-cost-protection' = 1024
}

export enum QuoteResult {
    Unknown = 'unknown',
    Success = 'success',
    Error = 'error',
    Rejected = 'rejected',
    Intermediate = 'intermediate'
}

export interface CoverageData {
    liabilityAmount: number;
    medicalExpenses: number;
    damagesToPremises: number;
    generalAggregate: number;
    productOperations: number;
    personalInjury: number;
    liabilityDeductible: string;
}

export interface HartfordResponse {
    Coverage: HartfordCoverage[];
}

export interface HartfordCoverage {
    coverageCode?: string;
    description?: string;
    limit?: HartfordCoverageLimit[];
}

export interface HartfordCoverageLimit {
    limitAmount: number;
    limitCode?: string;
    description?: string;
}

export class InsuranceGuardResult {
    disableReminder: boolean;
    entity: EntityData;
    insurance: QuoteResponseData;
    reminders: ReminderData[];

    constructor(data?) {
        data = data || {};
        this.disableReminder = data.disableReminder || false;
        this.entity = data.entity || null;
        this.insurance = data.insurance || null;
        this.reminders = data.reminders || null;
    }
}
