<div class="term-card card-header-green"
     fxLayout="column"
     ngClass.lt-md="term-card-sm"
     ngClass.lt-sm="term-card-xs">
  <div class="term-card-header"
       fxLayout="row"
       fxLayoutAlign="start center"
       fxLayoutGap="16px">
    <div class="avatar mat-green-secondary">TL</div>
    <div class="mat-headline">Term Loan{{calculator.express ? ' *' : ''}}</div>
  </div>
  <div fxLayout="column"
       fxLayoutGap="16px"
       fxLayout.lt-md="column"
       fxLayoutAlign="center center">
    <ifc-bar-chart [data]="barChartData"
                   [options]="barChartOptions">
    </ifc-bar-chart>
    <span class="sub-title">Outstanding balance over time</span>
  </div>
  <div class="express-footer"
       *ngIf="calculator.express">
    * Express Checkout is not subject to additional credit underwriting.
  </div>
</div>

<mat-card class="sample-details"
          ngClass.lt-md="sample-details-sm"
          ngClass.lt-sm="sample-details-xs">
  <mat-card-content fxLayout="row"
                    fxLayoutAlign="space-between"
                    fxLayout.lt-md="column">
    <section fxFlex="48%">
      <span class="mat-title">Details</span>
      <mat-list>
        <mat-list-item>
          <span class="item-title secondary-text"
                mat-line>Loan amount</span>
          <span class="item-content">{{calculator.amount | currency:'USD':'symbol':'1.2-2'}}</span>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item>
          <span class="item-title secondary-text"
                mat-line>Term</span>
          <span class="item-content">{{calculator.term}} months</span>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item>
          <span class="item-title secondary-text"
                mat-line>Total payback</span>
          <span class="item-content">{{calculator.totalPayback | currency:'USD':'symbol':'1.2-2'}}</span>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item>
          <span class="item-title secondary-text"
                mat-line>Fee ({{calculator.drawFeeFormatted}})</span>
          <span class="item-content">{{calculator.drawFee | currency:'USD':'symbol':'1.2-2'}}</span>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item *ngIf="calculator.originationFeeAmount">
          <span class="item-title secondary-text"
                mat-line>Origination fee</span>
          <span class="item-content">{{calculator.originationFeeAmount | currency:'USD':'symbol':'1.2-2'}}</span>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item *ngIf="calculator.maintenanceFeeAmount">
          <span class="item-title secondary-text"
                mat-line>Maintenance fee</span>
          <span class="item-content">{{calculator.maintenanceFeeAmount | currency:'USD':'symbol':'1.2-2'}}</span>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item *ngIf="calculator.maintenanceFeeAmount">
          <span class="item-title secondary-text"
                mat-line>Maintenance fee frequency</span>
          <span class="item-content">{{calculator.maintenanceFeeFrequencyName}}</span>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item>
          <span class="item-title secondary-text"
                mat-line>Disbursed amount</span>
          <span class="item-content">{{calculator.disbursedAmount | currency:'USD':'symbol':'1.2-2'}}</span>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item>
          <span class="item-title secondary-text"
                mat-line>Factor rate</span>
          <span class="item-content">{{factorRate | number:'1.4-4'}}</span>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item>
          <span class="item-title secondary-text"
                mat-line>{{calculator.paymentFrequencyName}} payment</span>
          <span class="item-content">{{calculator.termPayment | currency:'USD':'symbol':'1.2-2'}}</span>
          <mat-divider *ngIf="!isRenewal"></mat-divider>
        </mat-list-item>
        <mat-list-item *ngIf="!isRenewal">
          <span class="item-title secondary-text"
                mat-line>Commissions ({{calculator.applicationOffer.maxUpsell / 100 | percent:'1.2-2'}})</span>
          <span class="item-content">{{calculator.commision | currency:'USD':'symbol':'1.2-2'}}</span>
        </mat-list-item>
      </mat-list>
    </section>
    <section fxFlex="48%"
             class="features">
      <span class="mat-title">Other features</span>
      <div class="content"
           fxLayout="column">
        <p>
          <span class="title">Disbursed amount:</span> The disbursed amount that we will transfer to your designated
          bank account will be
          reduced by any amount owed to Idea Financial from a prior loan or used to pay off an amount owed to a third
          party.
        </p>
        <p>
          <span class="title">Payoff discount:</span> Unpaid interest will be reduced by 50% should you pay off this
          loan in full before
          your
          last payment is due. The remaining unpaid interest is due upon full pay off.
        </p>
        <p>
          <span class="title">Renewals:</span> After you have paid your loan by 50% you may be eligible for renewal
          subject to a credit
          review.
        </p>
        <p *ngIf="calculator.hasDisclosures">
          <a (click)="showDisclosures(calculator.application.id, calculator.applicationOffer.id)"
             class="disclosure-link">Click here to read disclosures required by law.</a>
        </p>
      </div>
    </section>
  </mat-card-content>
</mat-card>

<ifc-offer-calculator-closing-requirements [checkoutRequirements]="calculator.applicationOfferBundle.checkoutRequirements"
                                           [isOfferBundleSelected]="calculator.applicationOfferBundle.status === offerBundleStatusEnum.Accepted"
                                           (documentsClick)="onDocumentsClick(calculator.application?.id, calculator.applicationOfferBundle?.id)">
</ifc-offer-calculator-closing-requirements>

<ifc-link-your-bank [bankLinkStatus]="calculator.application?.bankLinkStatus"
                    (linkBank)="onLinkBank(calculator.application?.entityId)">
</ifc-link-your-bank>

<ifc-offer-calculator-payment-schedule [offer]="calculator.applicationOffer"
                                       [sampleDraw]="calculator.sampleDraw">
</ifc-offer-calculator-payment-schedule>
