import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EMPTY, Observable } from "rxjs";
import {
  MfaApiResponse,
  MfaSendCodeApiData,
} from "../const/models/mfa-api.models";
import { ConfigService } from "../../../../config/config.service";
import { MessageService } from "projects/common/src/core/message/message.service";
import { MfaErrorType } from "../const/enums/mfa-error-type.enum";
import { MfaContextType } from "../const/enums/mfa-context-type.enum";
import { InitMfaData } from "../const/models/init-mfa-data.model";
import { MfaService } from "./mfa.service";
import { catchError, map, tap } from "rxjs/operators";
import { MfaErrorCodeType } from "../const/enums/mfa-error-code-type.enum";
import { MfaConfig } from "../const/models/mfa-config.model";
import { MfaPhoneStatus } from "../const/enums/mfa-phone-status.enum";

@Injectable()
export class MfaDataService {
  protected readonly mfaUrl: string;
  protected readonly oauthUrl: string;

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private messageService: MessageService,
    private mfaService: MfaService
  ) {
    this.mfaUrl = `${this.configService.environment.mfa.url}/api`;
    this.oauthUrl = `${this.configService.environment.oauth.url}/api`;
  }

  sendNewCode(mfaToken: string): Observable<MfaSendCodeApiData> {
    return this.http
      .post<MfaSendCodeApiData>(`${this.mfaUrl}/otp/send-code`, {
        mfaToken,
      })
      .pipe(
        tap((data) => this.mfaService.updateMfaCodeData(data)),
        catchError((error) => {
          return this.handleSendNewCodeError(error);
        })
      );
  }

  private handleSendNewCodeError(error: HttpErrorResponse) {
    if (error.status === 429) {
      this.mfaService.updateMfaCodeData(error.error);
    } else {
      this.mfaService.handleMfaError(error?.message);
    }
    return EMPTY;
  }

  changePhoneToVerify(mfaToken: string, phoneNumber: string, config: MfaConfig): Observable<void> {
    return this.http
      .post<MfaApiResponse>(`${this.oauthUrl}/phone-number/change-to-verify`, {
        phoneNumber,
        mfaToken,
      })
      .pipe(
        map((data: MfaApiResponse) =>
          this.handleChangePhoneToVerifySuccess(data, config)
        ),
        catchError((error) => this.handleChangePhoneToVerifyError(error))
      );
  }

  private handleChangePhoneToVerifySuccess(mfaResponse: MfaApiResponse, config: MfaConfig) {
    if (mfaResponse.error !== MfaErrorType.MfaRequired) {
      this.messageService.errorDefault(`Invalid mfa state`);
      return;
    }

    const data: InitMfaData = {
      mfaContextType: MfaContextType.Login,
      mfaResponse,
      config,
    };
    this.mfaService.initMfa(data);
  }

  private handleChangePhoneToVerifyError(response: any) {
    if (
      response.status === 403 &&
      response.error?.error === MfaErrorType.MfaCodeError
    ) {
      return this.handleMfaCodeError(response.error);
    }

    this.messageService.errorDefault(`Mfa error: ${response.error}`);
    return EMPTY;
  }

  private handleMfaCodeError(mfaResponse: MfaApiResponse) {
    if (
      mfaResponse?.send_code_status?.error_result?.error ===
      MfaErrorCodeType.CooldownNotReached
    ) {
      this.mfaService.warningSendingNewCode(
        mfaResponse.send_code_status.code_generation_interval
      );
    }
    return EMPTY;
  }
}
