import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService, ApplicationOfferBundleData, OfferBundleBaseDtoService, ProductCode } from 'common';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class OfferBundleService extends OfferBundleBaseDtoService<ApplicationOfferBundleData> {

    constructor(http: HttpClient, configService: ConfigService) {
        super(http, configService)
    }

    getByApplicationUUID(applicationUuid: string, offerBundleId: number): Observable<ApplicationOfferBundleData> {
        return this.http.get<ApplicationOfferBundleData>(`${this.baseUrl}/uuid/${applicationUuid}/${offerBundleId}`);
    }

    getOfferWithCheckoutRequirements(applicationId: number, offerBundleId: number): Observable<ApplicationOfferBundleData> {
      return this.http.get<ApplicationOfferBundleData>(`${this.baseUrl}/application/${applicationId}/${offerBundleId}`);
    }
}

export class OfferBundleData
{
    repayment: string;
    bundleId: number;
    productCode: ProductCode;
    amount: number;
    express: boolean;
}
