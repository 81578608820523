import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { UploadFileDialogComponent } from "../upload-file-dialog/upload-file-dialog.component";
import {
  CheckoutRequirementStatus,
  OfferBundleCheckoutRequirement,
} from "common";
import { UppyFileChangeTypeEnum } from "../upload-file-dialog/upload-file.model";
import { UppyFile } from "@uppy/core";

@Component({
  selector: "ifp-offer-checkout-requirement-item",
  templateUrl: "./offer-checkout-requirement-item.component.html",
  styleUrls: ["./offer-checkout-requirement-item.component.scss"],
})
export class OfferCheckoutRequirementItemComponent implements OnChanges {
  @Input() applicationId: number;
  @Input() checkoutRequirement: OfferBundleCheckoutRequirement;
  @Output() checkoutFilesChanged = new EventEmitter<UppyFile[]>();

  checkoutRequirementStatusEnum = CheckoutRequirementStatus;
  tempFiles: UppyFile[];

  constructor(private dialog: MatDialog) {
    this.tempFiles = [];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.checkoutRequirement) {
      this.tempFiles = [];
      this.checkoutFilesChanged.emit(this.tempFiles);
    }
  }

  openUploadDialog() {
    UploadFileDialogComponent.show(this.dialog, {
      files: this.tempFiles,
      checkoutRequirementId: this.checkoutRequirement.id,
      applicationId: this.applicationId,
      category: this.checkoutRequirement.category
    }).subscribe((result) => {
      if (
        result &&
        result.type &&
        result.type != UppyFileChangeTypeEnum.Canceled
      ) {
        this.tempFiles = result.files;
        this.checkoutFilesChanged.emit(this.tempFiles);
      }
    });
  }
}
