import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SecurityInfoDialogComponent } from '../../dialog/security-info-dialog/security-info-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CompanyBranding } from '../../../branding/branding.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BrandingService } from '../../../../core/branding/branding.service';

declare global {
  interface Window {
    Trustpilot: any;
  }
}

@UntilDestroy()
@Component({
  selector: 'ifc-app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.scss']
})
export class AppFooterComponent implements OnInit, AfterViewInit {

  @ViewChild('trustpilotWrapper') trustpilotWrapper: ElementRef;
  private trustpilotInitialized: boolean;

  data: any;
  currentYear: number;
  branding: CompanyBranding

  constructor(
    private router: ActivatedRoute,
    private dialog: MatDialog,
    private brandingService: BrandingService) { }

  ngOnInit() {
    this.router.data.pipe(untilDestroyed(this)).subscribe(data => this.data = data);
    this.currentYear = (new Date()).getFullYear();
    this.initBrandingInfo();
  }

  ngAfterViewInit(): void {
    this.initializeTrustpilot();
  }

  private initBrandingInfo(): void {
    this.brandingService.getBranding$()
      .pipe(untilDestroyed(this))
      .subscribe((branding: CompanyBranding) => {
        this.branding = branding;
        this.initializeTrustpilot();
      });
  }

  private initializeTrustpilot(): void {
    if (!this.trustpilotInitialized && this.trustpilotWrapper?.nativeElement && this.branding) {
      if (!this.branding.trustpilotHtml)
        this.trustpilotWrapper.nativeElement.display = 'none';
      else {
        this.trustpilotWrapper.nativeElement.innerHTML = this.branding?.trustpilotHtml;

        const trustbox = this.trustpilotWrapper.nativeElement.firstChild;
        window.Trustpilot.loadFromElement(trustbox);
      }

      this.trustpilotInitialized = true;
    }
  }

  openDialog() {
    SecurityInfoDialogComponent.show(this.dialog);
  }
}
