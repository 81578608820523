export enum SubtransactionType {
  // Draws
  DrawDisbursement                =   0,
  DrawFee                         =   1,

  // Payments
  PaymentPrincipal                =   2,
  PaymentInterest                 =   3,

  // Transfers
  InterestCharge                  =   4,
  PrincipalTransfer               =   8,
  InterestTransfer                =   9,
  FeeBalanceTransfer              =  10,
  TotalFeesTransfer               =  11,
  TotalPrincipalPaidTransfer      =  12,

  // Premiums and Payoffs
  LitigationCostProtectionPremium =  15,
  ThirdPartyPayoff                =  23,

  // Fees
  RenewalFeeCash                  =   5,
  RenewalFeeDeferred              =  25,
  WireFeeCash                     =  26,
  WireFeeDeferred                 =   6,
  LateFeeCash                     =   7,
  LateFeeDeferred                 =  27,
  OriginationFeeCash              =  33,
  OriginationFeeDeferred          =  13,
  MaintenanceFeeCash              =  14,
  MaintenanceFeeDeferred          =  34,
  UCCFilingFeeCash                =  16,
  UCCFilingFeeDeferred            =  36,
  StoppedPaymentFeeCash           =  17,
  StoppedPaymentFeeDeferred       =  37,
  NSFFeeCash                      =  18,
  NSFFeeDeferred                  =  38,
  ModificationFeeCash             =  19,
  ModificationFeeInstallments     =  20,
  ModificationFeeDeferred         =  21,
  StackingFeeCash                 =  22,
  StackingFeeDeferred             =  42,
  AdministrativeFeeCash           =  43,
  AdministrativeFeeDeferred       =  44,
  ServicingFeeCash                =  45,
  ServicingFeeDeferred            =  46,
}

export const SubtransactionTypeInternalLabel = new Map<number, string>([
  // Draws
  [SubtransactionType.DrawDisbursement, 'Disbursed amount'],
  [SubtransactionType.DrawFee, 'Draw fee'],

  // Payments
  [SubtransactionType.PaymentPrincipal, 'Principal payment'],
  [SubtransactionType.PaymentInterest, 'Interest payment'],

  // Premiums and Payoffs
  [SubtransactionType.LitigationCostProtectionPremium, 'Litigation cost protection premium'],
  [SubtransactionType.ThirdPartyPayoff, 'Third party payoff'],

  // Fees
  [SubtransactionType.RenewalFeeCash, 'Renewal fee (cash)'],
  [SubtransactionType.RenewalFeeDeferred, 'Renewal fee (deferred)'],
  [SubtransactionType.WireFeeCash, 'Wire fee (cash)'],
  [SubtransactionType.WireFeeDeferred, 'Wire fee (deferred)'],
  [SubtransactionType.LateFeeCash, 'Late fee (cash)'],
  [SubtransactionType.LateFeeDeferred, 'Late fee (deferred)'],
  [SubtransactionType.OriginationFeeCash, 'Origination fee (cash)'],
  [SubtransactionType.OriginationFeeDeferred, 'Origination fee (deferred)'],
  [SubtransactionType.MaintenanceFeeCash, 'Maintenance fee (cash)'],
  [SubtransactionType.MaintenanceFeeDeferred, 'Maintenance fee (deferred)'],
  [SubtransactionType.UCCFilingFeeCash, 'UCC filing fee (cash)'],
  [SubtransactionType.UCCFilingFeeDeferred, 'UCC filing fee (deferred)'],
  [SubtransactionType.StoppedPaymentFeeCash, 'Stopped payment fee (cash)'],
  [SubtransactionType.StoppedPaymentFeeDeferred, 'Stopped payment fee (deferred)'],
  [SubtransactionType.NSFFeeCash, 'NSF fee (cash)'],
  [SubtransactionType.NSFFeeDeferred, 'NSF fee (deferred)'],
  [SubtransactionType.ModificationFeeCash, 'Modification fee (cash)'],
  [SubtransactionType.ModificationFeeInstallments, 'Modification fee (installments)'],
  [SubtransactionType.ModificationFeeDeferred, 'Modification fee (deferred)'],
  [SubtransactionType.StackingFeeCash, 'Stacking fee (cash)'],
  [SubtransactionType.StackingFeeDeferred, 'Stacking fee (deferred)'],
  [SubtransactionType.AdministrativeFeeCash, 'Administrative fee (cash)'],
  [SubtransactionType.AdministrativeFeeDeferred, 'Administrative fee (deferred)'],
  [SubtransactionType.ServicingFeeCash, 'Servicing fee (cash)'],
  [SubtransactionType.ServicingFeeDeferred, 'Servicing fee (deferred)'],

  // Transfers
  [SubtransactionType.InterestCharge, 'Interest charge'],
  [SubtransactionType.PrincipalTransfer, 'Principal transfer'],
  [SubtransactionType.InterestTransfer, 'Interest transfer'],
  [SubtransactionType.FeeBalanceTransfer, 'Fee balance transfer'],
  [SubtransactionType.TotalFeesTransfer, 'Total fees transfer'],
  [SubtransactionType.TotalPrincipalPaidTransfer, 'Total principal paid transfer'],
]);

export const SubtransactionTypeConsumerLabel = new Map<number, string>([
  // Draws
  [SubtransactionType.DrawDisbursement, 'Disbursed amount'],
  [SubtransactionType.DrawFee, 'Draw fee'],

  // Payments
  [SubtransactionType.PaymentPrincipal, 'Principal payment'],
  [SubtransactionType.PaymentInterest, 'Interest payment'],

  // Premiums and Payoffs
  [SubtransactionType.LitigationCostProtectionPremium, 'Litigation cost protection premium'],
  [SubtransactionType.ThirdPartyPayoff, 'Third party payoff'],

  // Fees
  [SubtransactionType.RenewalFeeCash, 'Renewal fee'],
  [SubtransactionType.RenewalFeeDeferred, 'Renewal fee'],
  [SubtransactionType.WireFeeCash, 'Wire fee'],
  [SubtransactionType.WireFeeDeferred, 'Wire fee'],
  [SubtransactionType.LateFeeCash, 'Late fee'],
  [SubtransactionType.LateFeeDeferred, 'Late fee'],
  [SubtransactionType.OriginationFeeCash, 'Origination fee'],
  [SubtransactionType.OriginationFeeDeferred, 'Origination fee'],
  [SubtransactionType.MaintenanceFeeCash, 'Maintenance fee'],
  [SubtransactionType.MaintenanceFeeDeferred, 'Maintenance fee'],
  [SubtransactionType.UCCFilingFeeCash, 'UCC filing fee'],
  [SubtransactionType.UCCFilingFeeDeferred, 'UCC filing fee'],
  [SubtransactionType.StoppedPaymentFeeCash, 'Stopped payment fee'],
  [SubtransactionType.StoppedPaymentFeeDeferred, 'Stopped payment fee'],
  [SubtransactionType.NSFFeeCash, 'NSF fee'],
  [SubtransactionType.NSFFeeDeferred, 'NSF fee'],
  [SubtransactionType.ModificationFeeCash, 'Modification fee'],
  [SubtransactionType.ModificationFeeInstallments, 'Modification fee'],
  [SubtransactionType.ModificationFeeDeferred, 'Modification fee'],
  [SubtransactionType.StackingFeeCash, 'Stacking fee'],
  [SubtransactionType.StackingFeeDeferred, 'Stacking fee'],
  [SubtransactionType.AdministrativeFeeCash, 'Administrative fee'],
  [SubtransactionType.AdministrativeFeeDeferred, 'Administrative fee'],
  [SubtransactionType.ServicingFeeCash, 'Servicing fee'],
  [SubtransactionType.ServicingFeeDeferred, 'Servicing fee'],

  // Transfers
  [SubtransactionType.InterestCharge, 'Interest charge'],
  [SubtransactionType.PrincipalTransfer, 'Principal transfer'],
  [SubtransactionType.InterestTransfer, 'Interest transfer'],
  [SubtransactionType.FeeBalanceTransfer, 'Fee balance transfer'],
  [SubtransactionType.TotalFeesTransfer, 'Total fees transfer'],
  [SubtransactionType.TotalPrincipalPaidTransfer, 'Total principal paid transfer'],
]);

export class SubtransactionData {
  amount: number;
  type: SubtransactionType;
  display?: boolean;
}

export enum TransactionStatus {
  Pending = 0,
  Processed = 1,
  Rejected = 2,
  Cancelled = 3,
  Approved = 4,
  Verified = 5,
  Complete = 6,
  Declined = 7,
  Abandoned = 8,
  OnHold = 9
}

export const TransactionStatusLabel = new Map<TransactionStatus, string>([
  [TransactionStatus.Pending, "Pending"],
  [TransactionStatus.Processed, "Processed"],
  [TransactionStatus.Rejected, "Rejected"],
  [TransactionStatus.Cancelled, "Cancelled"],
  [TransactionStatus.Approved, "Approved"],
  [TransactionStatus.Verified, "Verified"],
  [TransactionStatus.Complete, "Complete"],
  [TransactionStatus.Declined, "Declined"],
  [TransactionStatus.Abandoned, "Abandoned"],
  [TransactionStatus.OnHold, "On Hold"],
]);

export enum TransactionType {
  Draw = 0,
  Fee = 1,
  Payment = 2,
  BalanceTransfer = 3,
  Premium = 4,
  Payoff = 5
}

export enum TransactionTypeString {
  Draw = "draw",
  Fee = "fee",
  Payment = "payment",
  BalanceTransfer = "balance-transfer",
  Premium = "premium",
  Payoff = 'payoff'
}

export const TransactionTypeStringLabel = new Map<string, string>([
  [TransactionTypeString.Draw, "Draw"],
  [TransactionTypeString.Fee, "Fee"],
  [TransactionTypeString.Payment, "Payment"],
  [TransactionTypeString.BalanceTransfer, "Balance transfer"],
  [TransactionTypeString.Premium, "Premium"],
  [TransactionTypeString.Payoff, "Payoff"],
]);

export const TransactionTypeLabel = new Map<number, string>([
  [TransactionType.Draw, "Draw"],
  [TransactionType.Fee, "Fee"],
  [TransactionType.Payment, "Payment"],
  [TransactionType.BalanceTransfer, "Balance transfer"],
  [TransactionType.Premium, "Premium"],
  [TransactionType.Payoff, "Payoff"],
]);


export enum TransactionTypeNames {
  Draw = 'draw',
  Fee = 'fee',
  Payment = 'payment',
  BalanceTransfer = 'balanceTransfer',
  Premium = 'premium',
  Payoff = 'payoff'
}
