<div class="layout padding-top"
     fxLayout="column"
     fxLayoutAlign="flex-start"
     class="item-requirements"
     ngClass.lt-md="item-requirements-sm"
     ngClass.xs="item-requirements-xs">
  <div class="header">
    <span class="mat-title">Closing requirements</span>
    <button *ngIf="isOfferBundleSelected && checkoutRequirements?.length > 0"
            mat-button
            class="header__action-button"
            (click)="onClickDocuments()">
      <mat-icon>publish</mat-icon>
      Documents
    </button>
  </div>
  <mat-list class="requirements-list">
    <mat-list-item class="requirement-item"
                   [ngClass]="{'mat-light-grey': requirement.status === checkoutRequirementStatus.Approved}"
                   *ngFor="let requirement of checkoutRequirements | filter:filterFunction">
      <mat-icon class="requirement-icon"
                matListItemIcon>{{requirement.status === checkoutRequirementStatus.Approved ? 'check_circle' : 'trip_origin'}}
      </mat-icon>
      <p>{{requirement.requiredDocument}}{{requirement.notes ? ': ' + requirement.notes : ''}}</p>
    </mat-list-item>
  </mat-list>
</div>
