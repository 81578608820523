import { NgModule } from "@angular/core";
import { MfaComponent } from "./features/mfa/mfa.component";
import { MfaCommonModule } from "./common/mfa-common.module";
import { UpdatePhoneNumberDialogComponent } from "./features/mfa/components/update-phone-number-dialog/update-phone-number-dialog.component";
import { MfaService } from "./features/mfa/services/mfa.service";
import { MfaGuard } from "./features/mfa/guards/mfa.guard";
import { MfaActionsInternalService } from "./features/mfa/services/mfa-actions.internal.service";
import { MfaActionsService } from "./features/mfa/services/mfa-actions.service";
import { MfaDataService } from "./features/mfa/services/mfa-data.service";
import { MfaContentComponent } from "./features/mfa/components/mfa-content/mfa-content.component";
import { MfaActionNeededComponent } from "./features/mfa/components/mfa-action-needed/mfa-action-needed.component";

@NgModule({
  imports: [MfaCommonModule],
  declarations: [
    MfaComponent,
    UpdatePhoneNumberDialogComponent,
    MfaContentComponent,
    MfaActionNeededComponent,
  ],
  exports: [MfaComponent],
  providers: [
    MfaService,
    MfaGuard,
    MfaActionsInternalService,
    MfaActionsService,
    MfaDataService,
  ],
})
export class MfaModule {}
