import { Component, OnInit } from "@angular/core";
import { DomHelper, parseNumber } from "common";
import { MessageService } from "../../message/message.service";
import { SocialMedia, SocialMediaShare } from "./social-media.model";
import { CustomerFacade } from "../../customer/domain/+state/customer.facade";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { EntityFacade } from "../../entity/domain/+state/entity.facade";
import { ActivatedRoute } from "@angular/router";
import { switchMap } from "rxjs/operators";

@UntilDestroy()
@Component({
  selector: "ifp-referral-code-social-media",
  templateUrl: "./referral-code-social-media.component.html",
  styleUrls: ["./referral-code-social-media.component.scss"],
})
export class ReferralCodeSocialMediaComponent implements OnInit {
  shareUrls: SocialMediaShare;
  referalLinkContent: string;
  referalLinkUrl: string;

  get type() {
    return SocialMedia;
  }

  constructor(
    private messageService: MessageService,
    private customerFacade: CustomerFacade,
    private entityFacade: EntityFacade,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.initShareUrls();
    this.initReferalLinkUrl();
  }

  private() {
    this.customerFacade
      .getCurrentCustomer$()
      .pipe(untilDestroyed(this))
      .subscribe((customer) => {
        if (customer) {
          this.referalLinkUrl = `https://www.ideafinancial.com/application?Referred_By_Code__c=${customer.referralCode}`;
        }
      });
  }

  private initShareUrls() {
    this.entityFacade
      .getEntity$(parseNumber(this.route.snapshot.queryParams.companyId))
      .pipe(
        untilDestroyed(this),
        switchMap((entity) => this.messageService.query(entity.id))
      )
      .subscribe((data) => {

        const twitterMessage = data.find((i) => i.subject === "Refer Twitter");
        this.shareUrls = {
          twitter: "http://www.twitter.com/share?url=",
          whatsapp: "https://web.whatsapp.com/send?l=en&text=",
          linkedin: "https://www.linkedin.com/shareArticle?mini=true&url=",
          facebook: "https://www.facebook.com/sharer/sharer.php?u=",
          email: "mailto:?",
        };
        this.referalLinkContent = twitterMessage
          ? `${twitterMessage.body}: `
          : "";
      });
  }

  private initReferalLinkUrl() {
    this.customerFacade
      .getCurrentCustomer$()
      .pipe(untilDestroyed(this))
      .subscribe((customer) => {
        if (customer) {
          this.referalLinkUrl = `https://www.ideafinancial.com/application?Referred_By_Code__c=${customer.referralCode}`;
        }
      });
  }

  openLink(type: SocialMedia) {
    switch (type) {
      case SocialMedia.twitter:
        // eslint-disable-next-line max-len
        window.open(
          `${this.shareUrls.twitter}${this.referalLinkContent}${this.referalLinkUrl}`,
          "_blank",
          "noopener"
        );
        break;

      case SocialMedia.linkedin:
        window.open(
          `${this.shareUrls.linkedin}${this.referalLinkUrl}`,
          "_blank",
          "noopener"
        );
        break;

      case SocialMedia.facebook:
        window.open(
          `${this.shareUrls.facebook}${this.referalLinkUrl}`,
          "_blank",
          "noopener"
        );
        return;

      case SocialMedia.whatsapp:
        // eslint-disable-next-line max-len
        window.open(
          `${this.shareUrls.whatsapp}${this.referalLinkContent}${this.referalLinkUrl}`,
          "_blank",
          "noopener"
        );
        break;

      case SocialMedia.email:
        // eslint-disable-next-line max-len
        window.location.href = `${this.shareUrls.email}subject=Fast and flexible funding for your business with my referral link&body=${this.referalLinkContent}${this.referalLinkUrl}`;
        break;
    }
  }

  copy() {
    DomHelper.copy(this.referalLinkUrl);
  }
}
