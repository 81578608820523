export function parseNumber(value: string | number | null | undefined): number {
  if (value === null || value === undefined || value === "") {
    return undefined;
  }

  if (Number.isInteger(value)) {
    return value as number;
  }

  const result = parseInt(<any>value);
  if (Number.isInteger(result)) {
    return result;
  }

  throw new Error(`parseNumber(): cannot convert "${value}" to "number"`);
}

export function parseNumberArray(values: Array<string | number>): number[] {
  if (!values) {
    return values as number[];
  }

  if (typeof values === "object" && <any>values instanceof Array) {
    return values.map((value) => parseNumber(value));
  }

  throw new Error(
    `parseNumberArray(): cannot convert "${values}" to "number[]"`
  );
}
