<mat-dialog-content class="mat-typography">
    <div fxLayout="column" fxLayoutGap="18px">

        <span class="mat-display-2">Payoff case?</span>
        <form [formGroup]="liquidityForm">
            <div fxLayout="column" class="app-card-content">
                <mat-form-field appearance="outline">
                    <mat-label>Payoff reason</mat-label>
                    <mat-select matInput
                        formControlName="resolutionStatus"
                        placeholder="Payoff reason"
                        >
                        <mat-option *ngFor="let option of resolutionStatusOptions" [value]="option.value">{{option.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <ifc-datepicker [fc]="liquidityForm.get('resolutionDate')" [label]="'Date of outcome'" [disabled]="false" [min]="minDate" [max]="maxDate"></ifc-datepicker>
                <mat-form-field appearance="outline">
                    <mat-label>Tell us more about the case</mat-label>
                    <textarea matInput type="text" formControlName="note" rows="4"></textarea>
                </mat-form-field>
                <mat-checkbox appearance="outline" color="primary" formControlName="isConsented">I authorize LevelEsq to use my experience as a testimonial.</mat-checkbox>
            </div>
        </form>
        <div mat-dialog-actions class="flex row end-center">
            <button mat-button [mat-dialog-close]="">Cancel</button>
            <button mat-button (click)="confirm()" [disabled]="!isFormDataValid()" cdkFocusInitial color="primary">Ok</button>
        </div>
    </div>
</mat-dialog-content>