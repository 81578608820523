<ifc-app-scroll-container class="ifc-app-scroll-container-center">

  <div id="main"
       fxLayout="column"
       fxLayoutAlign="center center">

    <div fxLayout="column"
         fxLayoutAlign="center center"
         [ngClass.xl]="'form-field-lg'"
         [ngClass.lg]="'form-field-lg'"
         [ngClass.lt-lg]="'form-field-lg'"
         [ngClass.xs]="'form-field-xs'">
      <ng-container *ngIf="companySchema === 'levelesq'; else ideafinancialIcon">
        <img src="../../assets/levelesq-bank-link-icon.png"
             alt="Link">
      </ng-container>
      <ng-template #ideafinancialIcon>
        <img src="../../assets/bank-link-icon.png"
             alt="Link">
      </ng-template>

      <div class="title">
        Link your bank.
      </div>

      <div class="secondary-text layout padding-top"
           *ngIf="data.financialInstitutions.length > 0">
        Link the bank account(s) below. This will ensure funding will be transferred to the correct account(s) we have on file.
      </div>

      <div class="secondary-text layout padding-top"
           *ngIf="data.financialInstitutions.length === 0">
        Linking allows us to protect and speed up the funding process.
      </div>

      <div class="secondary-text layout padding-top"
           *ngIf="data.financialInstitutions.length === 0">
        The more banks you link, the better and faster we can assess the health of your business.
      </div>

      <div class="secondary-text layout padding-top"
           *ngIf="data.financialInstitutions.length === 0">
        You'll need your bank login credentials which we can't see, store or access.
      </div>

      <div fxLayout="column"
           fxLayoutAlign="center center"
           class="layout padding-top">

        <div [ngClass.xl]="'form-field-lg'"
             [ngClass.lg]="'form-field-lg'"
             [ngClass.lt-lg]="'form-field-lg'"
             [ngClass.xs]="'form-field-xs'">

          <div *ngFor="let institution of data.financialInstitutions"
               (click)="relink(institution)"
               class="bank-account-container hover"
               fxLayout="row"
               fxLayoutAlign="space-between center">

            <div fxLayout="row"
                 fxLayoutGap="12px">

              <div>
                <div matListAvatar
                     class="avatar circle-wrap"
                     fxLayoutAlign="center center">
                  <mat-icon>account_balance</mat-icon>
                </div>
              </div>

              <div fxLayout="column"
                   fxLayoutAlign="center start">
                <div>
                  {{institution?.institutionName}}
                </div>
                <div class="secondary-text">
                  Account ending *{{institution?.accountNumber}}
                </div>
              </div>

            </div>

          </div>

        </div>
      </div>

      <div class="buttons-conatiner"
           fxLayout="row"
           fxLayoutAlign="end center">
        <button mat-button
                (click)="skip()"
                name="linkLater">Link later</button>
        <button mat-raised-button
                color="primary"
                *ngIf="data.financialInstitutions.length === 0"
                (click)="link()">Link Your Bank</button>
      </div>

    </div>

  </div>

</ifc-app-scroll-container>
