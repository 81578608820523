<div fxLayout="column"
     fxLayoutAlign="start center"
     *ngIf="bankLinkStatus === BankLinkStatus.Unlinked || !bankLinkStatus">
  <p class="text">For faster access to funds, securely link and verify your bank with <a href="https://plaid.com/">PLAID</a>.</p>
  <div>
    <button mat-button
            class="rounded-button link"
            (click)="onClickLinkBank()">
      <mat-icon>link</mat-icon>
      Link your bank
    </button>
  </div>
