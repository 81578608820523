
export interface BankAccount {
    id?: number;
    bankName?: string;
    routingNumber?: string;
    accountNumber?: string;
    accountType?: BankAccountType;
    status?: string;
    createdOn?: Date;
    createdBy?: any;
    updatedOn?: Date;
    updatedBy?: any;
    syncedBankAccount?: SyncedBankAccount;
    balance?: number;
    lastUpdatedDate?: Date;
    isVerified?: boolean;
    lastCheckDate?: Date;
    setAsUncheckedAfterDate?: Date;
    linkingStatus?: LinkingStatus;
    screenShareRequired?: boolean;
    isBasicDataEditable?: boolean;
    isChecked?: boolean;
    isVerifiable?: boolean;
    missingInformation?: string[];
    entityRole?: BankAccountRole;
}

export enum BankAccountRole {
    None = 0,
    Disbursement = 1,
    Payment = 2,
    PaymentAndDisbursement = 3
}

export interface SyncedBankAccount {
    id?: number;
    customerId?: number;
    accountNumber?: string;
    routingNumber?: string;
    name?: string;
    balance?: number;
    balanceDate?: Date;
    currency?: string;
    type?: string;

    ownerName?: string;
    ownerAddress?: string;

    createdDate?: Date;

    institutionId?: string;
    institutionName?: string;
    institutionLoginId?: string;

    aggregationSuccessDate?: Date;
    aggregationAttemptDate?: Date;

    integrationBroken?: boolean;

    status?: string;
    providerMsg?: string;
    providerName?: string;
}

export enum BankAccountType {
    'Unknown' = 'unknown',
    'Checking' = 'checking',
    'Savings' = 'savings',
    'Money Market' = 'money market',
    'Payroll' = 'payroll',
    'Operating' = 'operating',
    'Trust' = 'trust',
    'Tax' = 'tax',
    'Payables/Vendor' = 'payables/vendor',
    '2nd Location' = '2nd location',
    'Sweep' = 'sweep',
    'Escrow' = 'escrow'
}

export enum LinkingStatus {
    Unlinked = 0,
    Linked = 1,
    Broken = 2
}

export enum VerificationStatus {
    Unverified = 0,
    Verified = 1
}

export interface BankAccountsInfo
{
    isDisbursementBankEligible: boolean;
    isPaymentBankEligible: boolean;
}
