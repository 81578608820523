import { Injectable } from '@angular/core';
import { CacheTypes } from './cache-headers';
import { CachingStorage } from './cache.service';

@Injectable({
  providedIn: 'root'
})
export class InMemoryCachingStorageService implements CachingStorage {

  cache: { [key: string]: any } = {};

  get name() : string
  {
    return CacheTypes.InMemory;
  }

  get<T>(key: string): T {
    return this.cache[key];
  }
  put<T>(key: string, value: T): void {
    this.cache[key] = value;
  }
  delete(key: string): void {
    delete this.cache[key];
  }
  getKeys(): string[] {
    return Object.keys(this.cache);
  }
}
