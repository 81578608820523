import { Pipe, PipeTransform } from '@angular/core';
import { NeedsAttentionModel } from './needs-attention.model';
import { NeedsAttentionHelper } from './needs-attention.helper';

@Pipe({
    name: 'needsAttentionModelToMessage'
})

export class NeedsAttentionModelToMessagePipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    const needsAttentionModel = value as NeedsAttentionModel;
    const productCode = args[0] as string;
    return NeedsAttentionHelper.getNeedsAttentionMessageWithProductCode(needsAttentionModel?.messageInput, productCode);
  }
}