<mat-dialog-content class="mat-typography">
  <h3>Set filter</h3>
  <form [formGroup]="form">

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column"
      fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">
      <mat-form-field appearance="outline">
        <mat-label>Name</mat-label>
        <input matInput formControlName="text" type="text" placeholder="Name">
        <button type="button" *ngIf="form.get('text').value" mat-button matSuffix mat-icon-button aria-label="Clear"
          (click)="form.get('text').setValue('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Type</mat-label>
        <mat-select matInput formControlName="category" placeholder="Type">
          <mat-option>All</mat-option>
          <mat-option *ngFor="let option of categoryOptions" [value]="option.value">{{option.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap="24px"
      fxLayoutAlign.xs="start center">
      <ifc-datepicker [fc]="form.get('fromDate')" [label]="'Date from'"></ifc-datepicker>
      <ifc-datepicker [fc]="form.get('toDate')" [label]="'Date to'"></ifc-datepicker>
    </div>

  </form>
</mat-dialog-content>

<div mat-dialog-actions class="flex row end-center">
  <button mat-button [mat-dialog-close]="">Cancel</button>
  <button mat-button [mat-dialog-close]="filters" cdkFocusInitial color="primary">Ok</button>
</div>
