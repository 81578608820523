import { Component, ViewEncapsulation, HostBinding } from '@angular/core';

@Component({
  selector: 'ifc-ssl-logo',
  templateUrl: './ssl-logo.component.html',
  styleUrls: ['./ssl-logo.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SslLogoComponent {

  constructor() { }

  @HostBinding('class')
  get classes() { return 'ifc-ssl-logo-container'; }

}
