<div id="sidenav-application-status-stepper">
  <div>
    <mat-vertical-stepper #verticalStepper>
      <mat-step *ngFor="let step of steps" [completed]="false">
        <ng-template matStepLabel>{{step}}</ng-template>
        <div fxLayout fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
          <div class="error-icon" *ngIf="showError">
            <mat-icon>priority_high</mat-icon>
          </div>
          <span class="message">{{message}}</span>
        </div>
      </mat-step>
    </mat-vertical-stepper>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="center center" [ngClass]="{'layout padding-top': lastStep}">
    <div class="layout padding-vertical-sm padding-right-xs">
      <button mat-raised-button class="color-primary" *ngIf="showLinkBankButton" (click)="link()">
        <span>LINK BANK</span>
      </button>
    </div>
    <div class="layout padding-vertical-sm padding-right-xs">
      <button mat-raised-button class="color-primary" (click)="offer()"
        *ngIf="application?.sfAppStatus === status.Approved || application?.sfAppStatus === status.Accepted">
        <span>SEE OFFERS</span>
      </button>
    </div>
    <div class="layout padding-vertical-sm padding-right-xs">
      <button mat-raised-button class="color-primary"
        *ngIf="application?.sfAppStatus === status.UnderwritingCall || application?.sfAppStatus === status.UnderwritingMissingInfo"
        (click)="calendarLink()">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
          <mat-icon>phone_in_talk</mat-icon>
          <span>SCHEDULE A CALL</span>
        </div>
      </button>
    </div>
  </div>
</div>
