<div fxLayout="column" fxLayoutGap="24px" ngClass.xs="form-field-sm" ngClass.gt-xs="form-field-md">

  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="12px" (click)="$event.stopPropagation()">

    <div class="square mat-twitter-blue-bg" (click)="openLink(type.twitter)">
      <img src="../../../assets/twitter-18x18.png">
    </div>
    <div class="square mat-linkedin-blue-bg" (click)="openLink(type.linkedin)">
      <img src="../../../assets/linkedin-18x18.png">
    </div>
    <div class="square mat-facebook-blue-bg" (click)="openLink(type.facebook)">
      <img src="../../../assets/facebook-18x18.png">
    </div>
    <div class="square mat-whatsapp-green-bg" (click)="openLink(type.whatsapp)">
      <img src="../../../assets/whatsapp-18x18.png">
    </div>
    <div class="square mat-indigo-bg" (click)="openLink(type.email)">
      <img src="../../../assets/email-18x18.png">
    </div>
  </div>

  <div>
    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="4px">
      <span class="mat-caption secondary-text">Your unique link:</span>
      <span>{{referalLinkUrl}}</span>
    </div>

    <div fxLayout="column" fxLayoutAlign="start end">
      <button mat-button color="primary" type="button" (click)="copy()">Copy</button>
    </div>
  </div>

</div>
