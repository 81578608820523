import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export abstract class DismissableBaseService<T> {
  abstract query(entityId: number): Observable<T[]> ;
  abstract update(customerId: number, entityId: number, object: any, dismiss: boolean);
}
