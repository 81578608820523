import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { Injectable } from "@angular/core";
import { Observable, combineLatest } from "rxjs";
import { LoanData } from "../domain/models/loan.model";
import { map, take } from "rxjs/operators";
import { CustomerData } from "../../customer/domain/models/customer.model";
import { LoanStatus, RouteHelper, parseNumber } from "common";
import { CustomerFacade } from "../../customer/domain/+state/customer.facade";
import { LoanFacade } from "../domain/+state/loan.facade";

@Injectable({
  providedIn: "root",
})
export class LoanApplicationGuard implements CanActivate {
  constructor(private router: Router, private customerFacade: CustomerFacade) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.customerFacade
      .getCurrentCustomer$()
      .pipe(map((customer) => this.validate(customer)));
  }

  validate(customer: CustomerData): boolean {
    if (
      (customer.pendingLoans == null || customer.pendingLoans.length === 0) &&
      (customer.pendingApplications == null || customer.pendingApplications.length === 0) &&
      (customer.loans == null || customer.loans.length === 0)
    )
      this.router.navigate(["home"]);
    else return true;
  }
}

@Injectable({
  providedIn: "root",
})
export class LoanPendingGuard implements CanActivate {
  constructor(
    private router: Router,
    private loanFacade: LoanFacade,
    private customerFacade: CustomerFacade
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const accountId = parseNumber(RouteHelper.getParamValueByName(route, "accountId"));
    return combineLatest([
      this.customerFacade.getCurrentCustomer$(),
      this.loanFacade.getLoan$(accountId),
    ]).pipe(
      take(1),
      map(([customer, loan]) => this.validate(loan, customer))
    );
  }

  validate(loan: LoanData, customer: CustomerData): boolean {
    const isCurrentLoanPending =
      loan.status === LoanStatus.Pending &&
      customer &&
      customer.pendingLoans &&
      customer.pendingLoans.includes(loan?.id);
    if (isCurrentLoanPending) {
      this.router.navigate([`account/${loan.id}/setup/sign-agreement`]);
      return false;
    }
    return true;
  }
}

@Injectable({
  providedIn: "root",
})
export class LoanOpenGuard implements CanActivate {
  constructor(private router: Router, private loanFacade: LoanFacade) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const accountId = parseNumber(RouteHelper.getParamValueByName(route, "accountId"));
    return this.loanFacade.getLoan$(accountId).pipe(
      take(1),
      map((loan) => this.validate(loan))
    );
  }
  validate(loan: LoanData): boolean {
    if (loan.status === LoanStatus.Open.valueOf()) return true;
    this.router.navigate(["home"]);
    return false;
  }
}
