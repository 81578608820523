import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EnumHelper, FilterRange, LoanStatus, ProductCode, AccountFilters, AccountQueryParams } from 'common';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import moment from 'moment';

@Component({
  selector: 'ifp-account-list-filter-dialog',
  templateUrl: './account-list-filter-dialog.component.html',
  styleUrls: ['./account-list-filter-dialog.component.scss'],
})
export class AccountListFilterDialogComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;

  form: UntypedFormGroup;
  filters: AccountFilters;
  productCodes = ProductCode;
  statusOptions = EnumHelper.getNamesAndValues(LoanStatus);
  productTypeOptions = EnumHelper.getNamesAndValues(ProductCode);

  createdOnRange: FilterRange<moment.Moment> = {};

  constructor(public dialogRef: MatDialogRef<AccountListFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder
  ) {
    this._unsubscribeAll = new Subject();
    this.filters = new AccountFilters(data.query);
    this.form = this.formBuilder.group({
      description: this.data.query.description ? this.data.query.description : '',
      externalId: this.data.query.externalId ? this.data.query.externalId : '',
      loanNumber: this.data.query.loanNumber ? this.data.query.loanNumber : '',
      status: this.data.query.status && this.data.query.status >= 0 ? +this.data.query.status : undefined,
      createdOn: this.data.query.createdOn || '',
      createdOnFrom: new UntypedFormControl(this.data.query.createdOnFrom ? new Date(this.data.query.createdOnFrom) : undefined, { validators: null, updateOn: 'blur' }),
      createdOnTo: new UntypedFormControl(this.data.query.createdOnTo ? new Date(this.data.query.createdOnTo) : undefined, { validators: null, updateOn: 'blur' }),
      favorite: this.data.query.favorite,
      productCode: this.data.query.productCode,
      excludedProductCode: this.data.query.excludedProductCode
    });

  }

  static show(dialog: MatDialog,
              query: AccountQueryParams,
              productCode: ProductCode
              ): Observable<any> {
    return dialog.open(AccountListFilterDialogComponent, {
      data: { query, productCode },
      closeOnNavigation: true
    }).afterClosed();
  }

  ngOnInit(): void {
    this.createdOnRange.from = this.filters.createdOnFrom;
    this.createdOnRange.to = this.filters.createdOnTo;

    this.form.valueChanges.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(data => {
        this.filters = new AccountFilters(data);
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  createdDateSelected(dataRange: FilterRange<moment.Moment>) {
    this.form.patchValue({
      createdOnFrom: dataRange.from,
      createdOnTo: dataRange.to
    });
  }


}
