import { Injectable } from "@angular/core";
import {
  AcceptLoanAgreementRequest,
  AcceptLoanAgreementResponse,
} from "./loc-agreement.data";
import { Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { CacheHeaders, ResourceService } from "common";
import { switchMap, take } from "rxjs/operators";
import { AppSettings } from "projects/portal/src/app.settings";
import { LoanFacade } from "../loan/domain/+state/loan.facade";
import { AgreementDto } from "../agreement/agreement.data";

@Injectable({
  providedIn: "root",
})
export class LocAgreementService {
  private readonly baseUrl = `${this.settings.customers.url}/api/checkout`;

  constructor(
    private http: HttpClient,
    private resourceService: ResourceService,
    private settings: AppSettings,
    private loanFacade: LoanFacade
  ) {}

  download(loanId: number, offerBundleId: number, selectedOfferId: number) {
    let queryParams = new HttpParams();
    if(offerBundleId && selectedOfferId) {
      queryParams = queryParams
        .set("offerBundleId", offerBundleId)
        .set("selectedOfferId", selectedOfferId);
    }

    const url = `${this.baseUrl}/agreement/${loanId}/pdf?${queryParams}`;
    this.resourceService.download(url);
  }

  getAgreement(
    loanId: number,
    offerBundleId: number,
    selectedOfferId: number
  ): Observable<AgreementDto> {

    let queryParams = new HttpParams();
    if(offerBundleId && selectedOfferId) {
      queryParams = queryParams
        .set("offerBundleId", offerBundleId)
        .set("selectedOfferId", selectedOfferId);
    }

    const url = `${this.baseUrl}/agreement/${loanId}/html?${queryParams}`;
    return this.http.get<AgreementDto>(url);
  }

  submit(loanId: number, request: AcceptLoanAgreementRequest): Observable<AcceptLoanAgreementResponse> {
    return this.loanFacade.getLoan$(loanId).pipe(
      take(1),
      switchMap((loan) => {
        const url = `${this.baseUrl}/agreement/${loanId}`;
        return this.http.post<AcceptLoanAgreementResponse>(url, request, {
          headers: {
            [CacheHeaders.Invalidate]: [
              this.getAppUrlToInvalidate(loan.renewalApplicationId), // we need to explicitly invalidate renewal application here
              this.loanFacade.getWithRenewalUrl(loan.id), //also need to invalidate loan with renewal
            ],
          },
        });
      })
    );
  }

  private getAppUrlToInvalidate(id: number): string {
    return `${this.settings.applications.url}/api/application/${id}`;
  }
}
