import { NgModule } from "@angular/core";
import { CommonModule } from "common";
import { SharedModule } from "../shared/shared.module";
import { EntityDocumentsListComponent } from "./components/entity-documents-list/entity-documents-list.component";
import { EntityDocumentsRoutingModule } from "./entity-documents-routing.module";

@NgModule({
  imports: [SharedModule, CommonModule, EntityDocumentsRoutingModule],
  declarations: [EntityDocumentsListComponent],
})
export class EntityDocumentsModule {}
