import { Injectable } from "@angular/core";
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";
import { RouteHelper, parseNumber } from "common";
import { map } from "rxjs/operators";
import { ApplicationFacade } from "../application/domain/+state/application.facade";
import { OfferBundleInfo } from "../agreement/agreement.data";

@Injectable({
  providedIn: "root",
})
export class SignReadyOfferBundleInfoResolver implements Resolve<OfferBundleInfo> {
  constructor(
    private applicationFacade: ApplicationFacade
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): OfferBundleInfo | Observable<OfferBundleInfo> {
    const applicationId = parseNumber(RouteHelper.getParamValueByName(route, "appId"));
    return this.applicationFacade.getAgreementReadyOfferBundle(applicationId).pipe(map(res => {
      return {
      offerBundleId: res.id,
      selectedOffer: res.offers[0]
    }
    }));
  }
}
