import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { BankLinkingComponentData, FinancialInstitution, bankLogoExists, getBankLogo } from '../../bank-account/bank-account.model';
import { EnumHelper, BankAccountType } from 'common';
import { BankingService } from '../../bank-account/banking.service';

@Component({
  selector: 'ifp-bank-linking',
  templateUrl: './bank-linking.component.html',
  styleUrls: ['./bank-linking.component.scss']
})
export class BankLinkingComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;

  @Input() bankLinkingData: BankLinkingComponentData;

  constructor(
    private bankingService: BankingService) {
    sessionStorage.setItem('relinkAccountViewed', JSON.stringify(true));
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.bankingService.integrationReminded(this.bankLinkingData.entity.id);
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  logoExists(_institutionId?: string, institutionName?: string) {
    return bankLogoExists(institutionName);
  }

  getLogo(_institutionId: string, institutionName?: string) {
    return getBankLogo(institutionName);

  }

  relink(financialInstitution: FinancialInstitution) {
    this.bankingService.relink(this.bankLinkingData.entity.id, financialInstitution, 'dashboard');
  }

  getBankAccountTypeName(type: string) {
    if (!type) return;
    return EnumHelper.getNameFromValue(BankAccountType, type);
  }
}
