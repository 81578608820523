import {
  ElementRef, Component, OnInit, Input,
  OnChanges, SimpleChanges, Output, EventEmitter,
  OnDestroy, ViewChild, AfterViewInit
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDatepickerInput } from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';
import moment from 'moment';
import { fromEvent, Subscription } from 'rxjs';

@Component({
  selector: 'ifc-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss']
})
export class DatepickerComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  @ViewChild(MatInput, { static: true }) formFieldControl: MatInput;
  @ViewChild(MatDatepickerInput, { static: true }) datepickerInput: MatDatepickerInput<any>;
  @ViewChild('dateInput', { static: true }) dateInput: ElementRef;

  _min: Date;
  _max: Date;

  @Input()
  maskConfig = {
    mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
    showMask: false,
    guide: false,
    placeholderChar: '_'
  };

  @Input()
  fc: UntypedFormControl;

  @Input()
  label: string;

  @Input()
  disabled: boolean;

  @Input()
  min: Date = new Date(1700, 0);

  @Input()
  max: Date = new Date(2300, 0);

  @Input()
  clearDisabled: boolean;

  @Output()
  blured = new EventEmitter<any>();

  @Output()
  clearInput = new EventEmitter<any>();

  eventSubscription: Subscription;
  labelId: string;

  constructor() { }

  previousDate: Date;
  timeAdjusting = false;
  ngOnInit() {
    this.fc.valueChanges.subscribe(() => {

      if (!this.fc.value || this.timeAdjusting || !this.fc.valid)
        return;

      const _date = new Date(this.fc.value);
      if (!this.previousDate)
        this.previousDate = new Date(this.fc.value);
      else if (_date.getFullYear() !== this.previousDate.getFullYear()
        || _date.getMonth() !== this.previousDate.getMonth()
        || _date.getDate() !== this.previousDate.getDate()) {
        const date = new Date(
          _date.getFullYear(),
          _date.getMonth(),
          _date.getDate(),
          this.previousDate.getHours(),
          this.previousDate.getMinutes(),
          this.previousDate.getSeconds()
        );
        this.timeAdjusting = true;
        this.fc.setValue(date);
        this.previousDate = date;
        this.timeAdjusting = false;
      } else
        this.previousDate = _date;
    });

  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.disabled) {
      this.fc.disable();
    } else {
      this.fc.enable();
    }
    this._min = this.min;
    this._max = this.max;
    this.labelId = this.label?.toLowerCase()?.replace(/ /gi, '-');
  }

  ngAfterViewInit(): void {
    this.eventSubscription = fromEvent(this.dateInput.nativeElement, 'input').subscribe(_ => {
      this.datepickerInput._onInput(moment(new Date(this.dateInput.nativeElement.value)).format('L'));
    });
  }

  ngOnDestroy() {
    this.eventSubscription.unsubscribe();
  }

  onBlur() {
    if (this.fc.value == null)
      this.fc.setValue('');
    this.blured.emit();
  }

  valueClear() {
    this.fc.setValue(null);
    this.clearInput.emit('');
    this.blured.emit();
  }

  onClose() {
    this.blured.emit();
  }
}
