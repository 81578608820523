import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { OfferBundleService } from "../../../../offer-bundle.service";
import { OfferBundleStatus } from "common";

@Injectable()
export class OffeSelectButtonFacade {
  constructor(private offerBundleService: OfferBundleService) {}

  selectOfferBundle(offerBundleId: number): Observable<boolean> {
    return this
      .offerBundleService.acceptOfferBundle(offerBundleId)
      .pipe(map(ob => ob.status === OfferBundleStatus.Accepted));
  }
}
