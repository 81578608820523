import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { ApplicationOfferBundleData, RouteHelper, parseNumber } from "common";
import { Observable } from "rxjs";
import { OfferBundleService } from "./offer-bundle.service";

@Injectable({
  providedIn: "root",
})
export class OfferBundlesByAppResolver {
  constructor(private offerBundleService: OfferBundleService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<ApplicationOfferBundleData[]>
    | Promise<ApplicationOfferBundleData[]>
    | ApplicationOfferBundleData[] {
    const appId = parseNumber(RouteHelper.getParamValueByName(route, "appId"));
    return this.offerBundleService.getOfferBundlesByAppId(appId);
  }
}
