import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AppBarTitleService, AppBarTitleComponentData } from 'common';

@Component({
  selector: 'ifp-app-bar-title',
  templateUrl: './app-bar-title.component.html',
  styleUrls: ['./app-bar-title.component.scss']
})
export class AppBarTitleComponent implements OnInit, OnDestroy {

  private subs: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private service: AppBarTitleService) {
  }

  data: AppBarTitleComponentData;

  dataSet(data: AppBarTitleComponentData) {
    this.data = data;
    if (data.appBarTitle)
      this.title = data.appBarTitle.content;
  }

  ngOnInit() {
    this.subs = [
      this.route.data.subscribe(this.dataSet.bind(this)),
      this.service.titleChange.subscribe(it => this.title = it)
    ];
  }

  ngOnDestroy() {
    this.subs.forEach(it => it.unsubscribe());
  }

  title: string;

}
