import { Pipe, PipeTransform } from "@angular/core";

// TODO[mfa] to replace by ngx-mask - need to fix bug with hidden masks
@Pipe({
  name: "hiddenPhone",
})
export class HiddenPhonePipe implements PipeTransform {
  transform(phone: number | string) {
    if (!phone) {
      return null;
    }

    const clearPhoneNumber = phone?.toString()?.replace(/\D/g, "");
    if (!clearPhoneNumber || clearPhoneNumber.length === 0) {
      return null;
    }

    if (clearPhoneNumber.length < 4) {
      return clearPhoneNumber;
    }

    const lastNumbers = clearPhoneNumber.slice(-4);
    return `(***) ***-${lastNumbers}`;
  }
}
