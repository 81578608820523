import { CheckoutRequirementStatus, FeeType, PaymentFrequency } from "../../application/application.model";
import { DocumentCategory } from "../../documents/document.model";
import { ProductCode } from "../../product/product.model";

export enum OfferType {
    LOC,
    Term,
    Bundle,
    Law
}

export enum OfferBundleStatus {
    Pending = "pending",
    Accepted = "accepted",
    Rejected = "rejected",
    Expired = "expired"
}

export const OfferBundleStatusLabel = new Map<string, string>([
  [OfferBundleStatus.Pending, "Pending"],
  [OfferBundleStatus.Accepted, "Accepted"],
  [OfferBundleStatus.Rejected, "Rejected"],
  [OfferBundleStatus.Expired, "Expired"],
]);

export class Offer {
    id: number;
    type: OfferType;
    details: OfferDetail[];

    constructor(offer?) {
        offer = offer || {};
        this.id = offer.id || null;
        this.type = offer.type || null;
        this.details = offer.details || [];
    }
}

export class OfferDetail {
    offerId: number;
    productCode?: ProductCode;
    amount?: number;
    rate?: number;
    term?: number;
    noPaymentTerms?: number;
    interestOnlyPaymentTerms?: number;
    fee?: number;
    feeType?: FeeType;
    maintenanceFee?: number;
    maintenanceFeeType?: FeeType;
    maintenanceFeeFrequency?: PaymentFrequency;
    originationFee?: number;
    originationFeeType?: FeeType;
    paymentFrequency?: PaymentFrequency;
    advisorId?: number;

    constructor(detail?) {
        detail = detail || {};
        this.offerId = detail.offerId || '';
        this.productCode = detail.productCode || '';
        this.amount = detail.amount || 0;
        this.rate = detail.rate || 0;
        this.paymentFrequency = detail.paymentFrequency || '';
        this.advisorId = detail.advisorId || '';

        this.setTerms(detail);
        this.setFees(detail);
    }

    private setTerms(detail?): void {
        this.term = detail.term || null;
        this.noPaymentTerms = detail.noPaymentTerms || null;
        this.interestOnlyPaymentTerms = detail.interestOnlyPaymentTerms || null;
    }

    private setFees(detail?): void {
        this.fee = detail.fee || 0;
        this.feeType = detail.feeType || '';
        this.maintenanceFee = detail.maintenanceFee || 0;
        this.maintenanceFeeType = detail.maintenanceFeeType || '';
        this.maintenanceFeeFrequency = detail.maintenanceFeeFrequency || '';
        this.originationFee = detail.originationFee || 0;
        this.originationFeeType = detail.originationFeeType || '';
    }
}

export class Payment {
    label: string;
    date: Date;
    interestAmount: number;
    principalAmount: number;
    totalAmount: number;
    interestBalance: number;
    principalBalance: number;
    interestPaid?: number;
    totalDrawFees?: number;
    capitalRepayment?: number;
    totalPayback?: number;
}

export enum OfferStatus {
    Pending = 'pending',
    Accepted = 'accepted'
}

export enum CheckoutRequirementDocumentType {
  Document = "document",
  InternalRequirement = "internal-requirement",
  Text = "text",
}

export const OfferStatusLabel = new Map<string, string>([
  [OfferStatus.Pending, "Pending"],
  [OfferStatus.Accepted, "Accepted"],
]);

export interface OfferBundleCheckoutRequirement {
  id: number;
  name: string;
  requiredDocument: string;
  category: DocumentCategory;
  notes: string;
  status: CheckoutRequirementStatus;
  documents: SimpleDocumentFile[];
  requiredDocumentType: CheckoutRequirementDocumentType;
  isFileUploaded: boolean;
}

export interface OfferBundleCheckoutRequirementsSummary {
  offerBundleId: number;
  checkoutRequirements: OfferBundleCheckoutRequirement[];
}

export interface SimpleDocumentFile {
  id: number;
  filename: string;
  isTemporary: boolean;
}
