import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SecurityInfoDialogComponent } from '../dialog/security-info-dialog/security-info-dialog.component';
import { CompanyBranding, CompanyColorSchemaEnum } from '../../branding/branding.model';
import { BrandingService } from '../../../core/branding/branding.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'ifc-ssl-info',
  templateUrl: './ssl-info.component.html',
  styleUrls: ['./ssl-info.component.scss']
})
export class SslInfoComponent implements OnInit {
  @Input()
  isLearnMoreVisible: boolean = true;

  colorSchema: CompanyColorSchemaEnum;

  constructor(
    private readonly dialog: MatDialog,
    private readonly brandingService: BrandingService) { }

  ngOnInit(): void {
    this.initBranding();
  }

  private initBranding() {
    this.brandingService.getBranding$()
      .pipe(untilDestroyed(this))
      .subscribe((branding: CompanyBranding) => {
        this.colorSchema = branding.colorSchema as CompanyColorSchemaEnum;
      });
  }

  learnMore() {
    SecurityInfoDialogComponent.show(this.dialog);
  }
}
