import { Component, OnInit, OnDestroy } from '@angular/core';
import { AccountTransferComponentData } from '../account-transfer.model';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AccountTransferService } from '../account-transfer.service';
import { PromotionType, TransactionType } from 'common';

@Component({
  selector: 'ifp-account-transfer-details',
  templateUrl: './account-transfer-details.component.html',
  styleUrls: ['./account-transfer-details.component.scss']
})
export class AccountTransferDetailsComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;

  data: AccountTransferComponentData;

  get TransactionType() { return TransactionType; }

  get PromotionType() { return PromotionType; }

  constructor(private _accountTransferService: AccountTransferService) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this._accountTransferService.onAccountTransferChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(data =>
        this.data = data
      );
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
