import { OfferLocCalculator } from "./offer-loc-calculator";

// TODO[offers] - will be refactored in RYNO-7973 (currently copied from common/application)
export class OfferLawCalculator extends OfferLocCalculator {
  protected getAnnualRate(): number {
    return this.rate / 100;
  }

  protected getMonthlyRate(): number {
    return this.annualRate / 12;
  }
}
