<mat-card class="transaction-card">
  <div class="insurance-banner" *ngIf="isInsuranceApplicable" (click)="insurance()">
    <p>INSURE YOUR CASES | Level Insurance <span class="insurance-banner__highlighted">reimburses your case costs in the event of a loss at trial.</span> ADD A POLICY</p>
  </div>
  <mat-tab-group #tabGroup>
    <mat-tab label="RECENT">
      <ng-template matTabContent>
        <ifp-account-transaction-table #transactionTable></ifp-account-transaction-table>
      </ng-template>
    </mat-tab>
    <mat-tab label="REPAYMENT SCHEDULE" *ngIf="data?.loan?.status !== loanStatus.Closed">
      <ng-template matTabContent>
        <ifp-account-payment-table #paymentTable></ifp-account-payment-table>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
  <button mat-icon-button class="ifp-account-csv-download" [ngClass]="{ 'ifp-account-csv-download__lower': isInsuranceApplicable }" (click)="download()">
    <mat-icon aria-label="Download">file_download</mat-icon>
  </button>
</mat-card>
