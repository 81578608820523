<ifc-app-scroll-container class="ifc-app-scroll-container-center">
  <div class="container">
    <span class="container__title"> Required documents </span>
    <div class="summary">
      <div class="summary__header">
        <span>{{ data.offerBundle?.offers[0]?.productName }}</span>
      </div>
      <div class="summary__body">
        <div class="detail">
          <span class="detail__title">{{
            data.offerBundle?.offers[0]?.amount
              | currency : "USD" : "symbol" : "1.0-0"
          }}</span>
          <span class="detail__description">Credit limit</span>
        </div>
        <div class="detail">
          <span class="detail__title">{{ term }}</span>
          <span class="detail__description">Term</span>
        </div>
        <div class="detail">
          <span class="detail__title">{{ monthlyRate | percent : "1.2-2" }}</span>
          <span class="detail__description">Monthly rate</span>
        </div>
      </div>
    </div>

    <span class="info">
      Speed up the closing review by providing the required information.
    </span>

    <ifp-offer-checkout-requirement-list
      class="requirements-list"
      [applicationId]="data?.application?.id"
      [checkoutRequirements]="data?.checkoutRequirements"
      (filesToUploadChanged)="onFilesToUploadChanged($event)"
    ></ifp-offer-checkout-requirement-list>

    <div class="actions">
      <button mat-button type="button" (click)="save()" [disabled]="(isSomethingToSend$ | async) === false">Save</button>
      <button mat-raised-button type="submit" (click)="submit()" color="primary" [disabled]="(isSomethingToSend$ | async) === false">Submit</button>
    </div>
  </div>
</ifc-app-scroll-container>
