import { Component, OnInit, OnDestroy } from '@angular/core';
import { AccountTransferComponentData } from '../account-transfer.model';
import { AccountTransferService } from '../account-transfer.service';
import { BrandingService, CompanyBranding, FeeType, PromotionType } from 'common';
import { PromotionInfoData, PromotionService } from '../../../promotions/promotion.service';
import { CurrencyPipe, PercentPipe } from '@angular/common';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { promoCodeValidator } from './account-promo-code.validator';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'ifp-account-transfer-promo-code',
  templateUrl: './account-transfer-promo-code.component.html',
  styleUrls: ['./account-transfer-promo-code.component.scss']
})
export class AccountTransferPromoCodeComponent implements OnInit, OnDestroy {
  data: AccountTransferComponentData;
  form: UntypedFormGroup;
  brandingName: string;
  promoCodeInvalid = false;
  promotionInfo: PromotionInfoData;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private accountTransferService: AccountTransferService,
    private currencyPipe: CurrencyPipe,
    private percentPipe: PercentPipe,
    private promotionService: PromotionService,
    private brandingService: BrandingService
  ) {
    this.form = this.formBuilder.group({
      promoCode: [undefined, promoCodeValidator(this.promoCodeInvalid)]
    });
  }

  ngOnInit() {
    this.accountTransferService.onAccountTransferChanged
      .pipe(untilDestroyed(this))
      .subscribe(data =>
        this.data = data
      );

    this.initBrandingInfo();
  }

  applyPromoCode(): void {
    const promoCodeToApply = this.form.controls.promoCode.value;

    this.promotionService
      .getByPromoCode(promoCodeToApply, this.data?.loan?.id, this.data?.accountTransfer?.type)
      .pipe(untilDestroyed(this))
      .subscribe(promotionInfo => {
        if (promotionInfo) {
          this.promoCodeInvalid = false;
          this.promotionInfo = promotionInfo;
          this.form.controls.promoCode.setValidators(promoCodeValidator(this.promoCodeInvalid));
          this.form.controls.promoCode.updateValueAndValidity();
          this.form.controls.promoCode.setValue(undefined);
        }
        else {
          this.promoCodeInvalid = true;
          this.promotionInfo = null;
          this.form.controls.promoCode.setValidators(promoCodeValidator(this.promoCodeInvalid));
          this.form.controls.promoCode.updateValueAndValidity();
        }

        this.promotionService.onPromotionApplied.next(this.promotionInfo);
      })
  }

  removeAppliedProductCode() {
    this.promoCodeInvalid = false;
    this.promotionInfo = null;
    this.promotionService.onPromotionApplied.next(this.promotionInfo);
  }


  private initBrandingInfo(): void {
    this.brandingService.getBranding$()
      .pipe(untilDestroyed(this))
      .subscribe((branding: CompanyBranding) => {
        this.brandingName = branding?.dba ?? branding?.name ?? 'Idea Financial';
      })
  }

  get promotionInfoDetails(): string {
    if (!this.promotionInfo)
      return '';

    if (this.promotionInfo.promotionType === PromotionType.DrawFeeOverride && this.promotionInfo.reduceType === FeeType.Flat)
      return 'Draw fee amount will be overridden with ' + this.currencyPipe.transform(this.promotionInfo.reduceValue, 'USD', 'symbol', '1.2-2') + ' if it is lower than the original one';

    else if (this.promotionInfo.promotionType === PromotionType.DrawFeeOverride && this.promotionInfo.reduceType === FeeType.Percentage)
      return 'Draw fee amount will be overridden with ' + this.percentPipe.transform(this.promotionInfo.reduceValue / 100, '1.2-2') + ' if it is lower than the original one';

    if (this.promotionInfo.promotionType === PromotionType.DrawFeeReduction && this.promotionInfo.reduceType === FeeType.Flat)
      return 'Draw fee will be reduced by maximum ' + this.currencyPipe.transform(this.promotionInfo.reduceValue, 'USD', 'symbol', '1.2-2');

    else if (this.promotionInfo.promotionType === PromotionType.DrawFeeReduction && this.promotionInfo.reduceType === FeeType.Percentage)
      return 'Draw fee rate will be reduced by maximum ' + this.percentPipe.transform(this.promotionInfo.reduceValue / 100, '1.2-2');

    if (this.promotionInfo.promotionType === PromotionType.ReAmortization)
      return 'Loan will be re-amortized after partial pre-payment is made';

    if (this.promotionInfo.promotionType === PromotionType.PayoffInterestReduction && this.promotionInfo.reduceType === FeeType.Flat)
      return 'Payoff interest will be reduced by maximum ' + this.currencyPipe.transform(this.promotionInfo.reduceValue, 'USD', 'symbol', '1.2-2');

    else if (this.promotionInfo.promotionType === PromotionType.PayoffInterestReduction && this.promotionInfo.reduceType === FeeType.Percentage)
      return 'Payoff interest will be reduced by ' + this.percentPipe.transform(this.promotionInfo.reduceValue / 100, '1.2-2');
  }

  ngOnDestroy(): void {
    this.promotionService.onPromotionApplied.next(null);
  }
}
