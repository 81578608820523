import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReferralCodeRoutingModule } from './referral-code-routing.module';
import { SharedModule } from '../shared/shared.module';
import { ReferralCodeReminderComponent } from './referral-code-reminder.component';
import { ReferralCodeMesssageComponent } from './referral-code-message.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ReferralCodeRoutingModule
  ],
  declarations: [
    ReferralCodeReminderComponent,
    ReferralCodeMesssageComponent
  ]
})
export class ReferralCodeModule { }
