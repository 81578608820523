import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LiquidityEvent, ResolutionStatus } from "common";
import { Observable } from "rxjs";
import { AppSettings } from "../../../app.settings";

@Injectable({
    providedIn: 'root'
})
export class LiquidityEventRemote
{
    constructor(
        private http: HttpClient,
        private settings: AppSettings
    ) {
    }

    getLiquidityEventForLoan(loanId: number): Observable<LiquidityEvent> {
      return this.http.get<LiquidityEvent>(`${this.settings.loans.url}/api/account/${loanId}/liquidity-event`);
    }

    saveLiquidityEvent(litigationId: number, loanId: number, resolutionStatus: ResolutionStatus,
      resolutionDate: Date, note: string, notesConsent: boolean) : Observable<void> {
      
      return this.http.patch<any>(`${this.settings.applications.url}/api/litigations/${litigationId}/loan/${loanId}/customer-resolution`, 
      { 
        status: resolutionStatus,
        date: resolutionDate,
        notes: note,
        notesConsent: notesConsent
      });
    }

    updatePayoffNotes(data: string, litigationId: number, loanId: number): Observable<any> {
      return this.http.put(
        `${this.settings.applications.url}/api/litigations/${litigationId}/payoff-note`,
        { notes: data }
      );
    }
}
