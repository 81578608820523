<ifc-app-scroll-container [layout2]="layout2">
  <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="24px" fxFlexFill>
    <div class="header">
      <ifp-navbar *ngIf="!hideNavbar"></ifp-navbar>
    </div>
    <div class="content" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="24px">
      
      <div class="card-container">
        <mat-card [ngClass]="colorSchema === 'levelesq' ? 'mat-claret-bg' : 'mat-teal-bg'">
          <mat-card-content>
            <ng-container *ngIf="!isNPLLoan">
              <div *ngIf="currentLoan?.productCode === productCode.LOC || currentLoan?.productCode === productCode.Law" class="card-content">
                <ifp-sidenav-law-card-chart-options
                  *ngIf="currentLoan?.productCode === productCode.Law && currentLoanFileTemplates?.length > 0"
                  class="card-options"
                  [currentLoanFileTemplates]="currentLoanFileTemplates"
                  [currentLoan]="currentLoan">
                </ifp-sidenav-law-card-chart-options>
                <ifp-sidenav-loc-law-card-chart [loan]="currentLoan" [customer]="currentCustomer" [needsAttentionModel]="needsAttentionModel"
                  [kbaVerified]="kbaVerified" [hasAvailableRenewalToSign]="hasAvailableRenewalToSign"
                  [hasCurrentLoanPending]="hasCurrentLoanPending">
                </ifp-sidenav-loc-law-card-chart>
              </div>
              <div *ngIf="currentLoan?.productCode === productCode.Term">
                <ifp-sidenav-term-loan-card-chart [loan]="currentLoan" [customer]="currentCustomer"
                  [kbaVerified]="kbaVerified" [hasAvailableRenewalToSign]="hasAvailableRenewalToSign"
                  [hasCurrentLoanPending]="hasCurrentLoanPending"
                  [shouldDisplayLoanBarChart]="shouldDisplayLoanBarChart">
                </ifp-sidenav-term-loan-card-chart>
              </div>
              <div *ngIf="currentApplication">
                <ifp-sidenav-application-status-stepper [application]="currentApplication">
                </ifp-sidenav-application-status-stepper>
              </div>
            </ng-container>
            <ng-container *ngIf="isNPLLoan">
              <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="8px" class="layout padding-top">
                <div>
                  Your account is frozen. Contact our Legal department at {{legalEmail}}.
                </div>
                <a mat-raised-button class="color-primary" href="mailto:{{legalEmail}}">
                  <span>EMAIL US</span>
                </a>
              </div>
            </ng-container>
          </mat-card-content>
        </mat-card>

        <ifp-application-summary *ngIf="currentApplication" [application]="currentApplication">
        </ifp-application-summary>

        <ifp-account-summary *ngIf="currentLoan && !isNPLLoan" [loan]="currentLoan" [liquidityEvent]="currentLiquidityEvent"></ifp-account-summary>
      </div>

      <ifp-advisor-widget [value]="currentLoan || currentApplication"></ifp-advisor-widget>
    </div>
  </div>
</ifc-app-scroll-container>