import { switchMap, take } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import {
  DocumentQueryParams,
  EntityDocumentQueryResult,
  EntityDocumentsService,
  QueryParamsService,
  RouteHelper,
  parseNumber,
} from "common";
import { Observable } from "rxjs";
import { EntityFacade } from "../entity/domain/+state/entity.facade";

@Injectable({
  providedIn: "root",
})
export class EntityDocumentsQueryResolver {
  constructor(
    private entityDocumentsService: EntityDocumentsService,
    private entityFacade: EntityFacade,
    private queryParamsService: QueryParamsService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<EntityDocumentQueryResult>
    | Promise<EntityDocumentQueryResult>
    | EntityDocumentQueryResult {
    const queryParams = new DocumentQueryParams(
      this.queryParamsService.init(route.queryParams)
    );
    const companyId = parseNumber(RouteHelper.getQueryParamValueByName(route, "companyId"));
    return this.entityFacade.getEntity$(companyId).pipe(
      take(1),
      switchMap((entity) => {
        return this.entityDocumentsService.query(entity.id, queryParams);
      })
    );
  }
}
