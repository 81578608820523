<button mat-icon-button [matMenuTriggerFor]="userMenu">
  <mat-icon>more_vert</mat-icon>
</button>
<mat-menu #userMenu="matMenu">
  <button mat-menu-item (click)="onClickPayBalance()">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
      <span class="avatar medium action-icon">P</span>
      <span class="action-name">Pay balance</span>
    </div>
  </button>
  <ng-container *ngFor="let template of currentLoanFileTemplates">
    <button mat-menu-item (click)="onClickLoanTemplateOption(template)">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
        <mat-icon>request_quote</mat-icon> <span class="action-name">{{template.nameTemplate | sentenceCase}}</span>
      </div>
    </button>
  </ng-container>
</mat-menu>
