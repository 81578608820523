import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { map, switchMap, take } from "rxjs/operators";
import { ApplicationFacade } from "../application/domain/+state/application.facade";
import { CustomerFacade } from "../customer/domain/+state/customer.facade";


@Injectable({
  providedIn: "root",
})
export class ApplicationAgreementSignedGuard implements CanActivate {
  constructor(
    private applicationFacade: ApplicationFacade,
    private customerFacade: CustomerFacade,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    if (JSON.parse(sessionStorage.getItem("applicationAgreementViewed"))) {
      return true;
    }
    return this.customerFacade.getCurrentCustomer$().pipe(
      take(1),
      map((customer) => ({
        hasPendingApplications: customer?.pendingApplications?.length ? true : false,
      })),
      switchMap((data) =>
        this.applicationFacade.getAgreementReadyApplications()
      ),
      map((pendingApplications: any) => {
        if (pendingApplications?.length > 0) {
          this.router.navigate([
            `application/${pendingApplications[0]}/setup/sign-agreement`,
          ]);
          return false;
        }
        return true;
      })
    );
  }
}


