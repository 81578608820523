import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from 'common';
import { LocAgreementFormComponent } from './loc-agreement-form/loc-agreement-form.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { LoanFundedDialogComponent } from '../loan/loan-funded-dialog/loan-funded-dialog.component';

@NgModule({
    imports: [
        SharedModule,
        CommonModule,
        RouterModule
    ],
    declarations: [
        LocAgreementFormComponent,
        LoanFundedDialogComponent
    ],
    schemas: [NO_ERRORS_SCHEMA]
})
export class LocAgreementModule { }
