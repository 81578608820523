import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'ifc-avatar-icon',
  templateUrl: './avatar-icon.component.html',
  styleUrls: ['./avatar-icon.component.scss']
})
export class AvatarIconComponent implements OnChanges {

  @Input()
  data: string;

  image: any;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.image = this.data ? this.sanitizer.bypassSecurityTrustResourceUrl('data:image;base64,' + this.data) : '../assets/profile.jpg';
  }

}
