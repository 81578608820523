import { Component, OnInit, Input, OnChanges } from "@angular/core";
import { BrandingService, CompanyBranding, EntityExData, parseNumber, CompanyBrandingHelper } from "common";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ActivatedRoute } from "@angular/router";
import { EntityFacade } from "../../entity/domain/+state/entity.facade";

@UntilDestroy()
@Component({
  selector: "ifp-advisor-widget",
  templateUrl: "./advisor-widget.component.html",
  styleUrls: ["./advisor-widget.component.scss"],
})
export class AdvisorWidgetComponent implements OnInit, OnChanges {
  private currentEntity: EntityExData;
  data: any;
  advisorPhone: string;

  @Input()
  value: any;

  constructor(
    private entityFacade: EntityFacade,
    private brandingService: BrandingService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.brandingService
      .getBranding$()
      .pipe(untilDestroyed(this))
      .subscribe((branding: CompanyBranding) => {
        this.advisorPhone = CompanyBrandingHelper.getAdvisorPhone(branding, this.data?.advisorPhone);
      });

    this.entityFacade
      .getEntity$(parseNumber(this.route.snapshot.queryParams.companyId))
      .pipe(untilDestroyed(this))
      .subscribe((entity: EntityExData) => {
        if (entity) {
          this.currentEntity = entity;
          this.data = this.value ? this.value : this.currentEntity;
        }
      });
  }

  ngOnChanges(): void {
    this.data = this.value ? this.value : this.currentEntity;
  }

  mailLink(email: string) {
    return `mailto:${email}`;
  }

  calendarLink() {
    window.open(
      this.data?.advisorCalendarLinkUrl ?? "https://calendly.com/ideafinancial",
      "_blank"
    );
  }
}
