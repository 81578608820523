import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AppPageComponent, AppBarComponent } from "common";
import { InitMobileComponent } from "./init-mobile/init-mobile.component";

const routes: Routes = [
  {
    path: "mobile/init",
    component: AppPageComponent,
    data: {
      appBar: {
        stickyDisabled: true,
      },
    },
    children: [
      {
        path: "",
        outlet: "app-bar",
        component: AppBarComponent,
      },
      {
        path: "",
        component: InitMobileComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MobileRoutingModule {}
