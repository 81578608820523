<div class="ifc-card-elevation widget-content" [class.mat-elevation-z4]="true" [ngClass.gt-md]="'card lg'"
  [ngClass.md]="'card md'" [ngClass.xs]="'card xs'">

  <div class="card-header card-header-gray" [class.mat-elevation-z4]="true" [style.background]="color">

    <div fxLayout="column" fxLayoutGap="24px">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
        <img *ngIf="logoExists" matListAvatar [src]="logoSrc">
        <div *ngIf="!logoExists" matListAvatar class="avatar circle-wrap" fxLayoutAlign="center center">
          <mat-icon class="icon">account_balance</mat-icon>
        </div>
        <div class="card-title" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="0px">
          <span class="mat-title">{{institution.institutionName}}</span>
        </div>
      </div>
    </div>

  </div>

  <div class="card-body">
    <mat-list>
      <mat-list-item>
        <span class="item-title secondary-text" mat-line>Link status</span>
        <span class="item-content">
          <div [ngClass]="{'integration-status-chip' : !institution.isLinked}" (click)="relink()">
            <ifc-status-label [status]="bankLinkStatusLabels" [value]="institutionIntegrationStatus"></ifc-status-label>
          </div>
        </span>
      </mat-list-item>
      <mat-list-item [ngClass]="{'hide' : !institution.customerMessages}" *ngFor="let msg of institution.customerMessages" class="secondary-text">
        {{msg}}
      </mat-list-item>
    </mat-list>
  </div>

</div>