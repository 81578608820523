import { ApplicationRef, Component } from '@angular/core';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AppLoaderService } from './app-loader.service';

@Component({
  selector: 'ifc-app-loader',
  templateUrl: './app-loader.component.html',
  styleUrls: ['./app-loader.component.css']
})
export class AppLoaderComponent {
	private debug = false;

  constructor(public service: AppLoaderService, private appRef: ApplicationRef) {
    this.service.isLoading
      .pipe(
          debounceTime(300),
          distinctUntilChanged())
      .subscribe(it =>
      {
        this.visible = it;
        if (!this.visible) {
          this.appRef.tick();
        }
        if (this.debug) console.log(`AppLoader visiblility changed: ${this.visible}`);
      });
  }

  visible = false;
}
