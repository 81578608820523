import { NgModule } from "@angular/core";
import { ContactDataDetailsComponent } from "./contact-data-details/contact-data-details.component";
import { CompanyContactDataFormComponent } from "./company-contact-data-form/company-contact-data-form.component";
import { CustomerContactDataFormComponent } from "./customer-contact-data-form/customer-contact-data-form.component";
import { ContactDataNavigationComponent } from "./contact-data-navigation/contact-data-navigation.component";
import { SharedModule } from "../shared/shared.module";
import { CommonModule } from "common";
import { RouterModule } from "@angular/router";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { ContactDataRoutingModule } from "./contact-data-routing.module";
import { CompanySelectComponent } from "./company-contact-data-form/company-select/company-select.component";

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    RouterModule,
    GooglePlaceModule,
    ContactDataRoutingModule,
  ],
  declarations: [
    ContactDataDetailsComponent,
    CompanyContactDataFormComponent,
    CustomerContactDataFormComponent,
    ContactDataNavigationComponent,
    CompanySelectComponent
  ],
})
export class ContactDataModule {}
