<div class="container">
  <h4 class="heading">
    {{businessName}} has been approved for funding from <span [class]="companyCssClass">{{companyName}}</span>.
    <ng-container *ngIf="!canSelectOffer && !isAlreadySelected"><br></ng-container> See offer details below.
    <ng-container *ngIf="canSelectOffer || isAlreadySelected">
      <br>Once an offer is selected, see the Closing Requirements and provide the information requested.
      <span class="view-all-offer"
            (click)="onClickViewAllOffers()">To view all offers press here
      </span>.
    </ng-container>
    <ng-container *ngIf="!canSelectOffer && !isAlreadySelected"><br><br><mat-icon class="heading__icon">error</mat-icon>
      You cannot select another offer at this time. If you need to make changes, call: {{ advisorName }} at {{ advisorPhone }}.</ng-container>
  </h4>
</div>
