<h1 mat-dialog-title>Funding transfer started</h1>

<mat-dialog-content>
  <p class="secondary-text">
    <span>Your funding transfer in the amount of {{data.disbursedAmount | currency:'USD':'symbol':'1.2-2'}} has started.</span>
    <span *ngIf="data.bankIntegrationRequired"> Link your bank to speed up the funding process.</span>
    <span> Transfers take 1-2 business days to be processed.</span>
  </p>

  <div mat-dialog-actions class="flex row center-center margin-bottom-5">
    <button mat-raised-button [mat-dialog-close]="true" color="primary">{{data.bankIntegrationRequired ? 'Link Your Bank' : 'Ok'}}</button>
  </div>

  <div class="content">
    <mat-card class="mat-elevation-z0">
      <div fxLayout="row" fxLayoutAlign="start center" class="card-header">
        <span class="card-title">New account balances</span>
      </div>

      <mat-card-content>
        <mat-list>
          <mat-list-item>
            <span class="secondary-text" mat-line>Outstanding balance</span>
            <p class="item-content">{{data.loan?.loanInfo?.principalBalance | currency:'$'}}</p>
          </mat-list-item>

          <mat-divider class="white-border"></mat-divider>

          <mat-list-item>
            <span class="secondary-text" mat-line>Payment due</span>
            <p class="item-content">{{data.loan?.loanInfo?.paymentAmount | currency:'$'}}</p>
          </mat-list-item>

          <mat-divider class="white-border"></mat-divider>

          <mat-list-item>
            <span class="secondary-text" mat-line>Payment due date</span>
            <p class="item-content">{{data.loan?.loanInfo?.paymentDueDate | date: 'mediumDate'}}</p>
          </mat-list-item>

          <mat-divider class="white-border"></mat-divider>

          <mat-list-item>
            <span class="secondary-text" mat-line>Loan Amount</span>
            <p class="item-content">{{data.loan?.offer?.amount | currency:'$'}}</p>
          </mat-list-item>
        </mat-list>
      </mat-card-content>
    </mat-card>
  </div>
</mat-dialog-content>