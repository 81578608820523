import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OAuthModule } from './oauth/oauth.module';
import { SamlModule } from './saml/saml.module';

@NgModule({
  imports: [
    CommonModule,
    OAuthModule,
    SamlModule
  ],
  exports: [
    OAuthModule
  ],
  declarations: []
})
export class AuthModule { }
