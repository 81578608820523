<div id="main" fxLayout="column" fxLayoutAlign="center center">

  <div fxLayout="row" fxLayoutAlign="center center" fxLayout.lt-md="column" fxLayoutAlign.lt-md="center start"
    fxLayoutGap="0" class="layout padding-sm">

    <mat-card class="mat-elevation-z2 blur-card" ngClass.gt-sm="left-card" ngClass.lt-md="top-card">
      <mat-card-content class="liability layout padding">
        <div fxLayout="column" fxLayoutAlign="start start" class="layout padding-left-md" fxLayoutGap="16px">
          <span ngClass.gt-sm="display-3" ngClass.lt-md="display-4">Save $$$ on General Liability Insurance</span>
          <span class="subtitle">No hassle. One click.</span>
          <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="8px">
            <img src="../../assets/hartford-stars-01.svg" alt="4.8/5 stars" height="15px">
            <span class="rate">4.8/5 Star Claims Experience*</span>
            <a class="rate" href="https://www.thehartford.com/general-liability-insurance/reviews" target="_blagnk">See reviews</a>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="mat-elevation-z2" ngClass.gt-sm="right-card" ngClass.lt-md="bottom-card">
      <mat-card-content class="liability">
        <div fxLayout="column" fxLayoutAlign="start center" class="layout padding-vertical">
          <img src="../../assets/idea-hartford-logos-01.png" height="60px">
        </div>
        <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="8px"
          class="layout padding-horizontal padding-vertical-sm">
          <ul>
            <li><span>CLICK.</span> A custom quote, instantly </li>
            <li><span>COMPARE.</span> Trustworthy coverage at an affordable price</li>
            <li><span>SAVE.</span> Stop overpaying on premiums today</li>
          </ul>
        </div>
        <div fxLayout="row" fxLayoutAlign="center start" class="layout padding-top-md padding-bottom">
          <button mat-raised-button class="mat-green-bg" (click)="getQuote()">COMPARE AND SAVE</button>
        </div>
      </mat-card-content>
    </mat-card>

  </div>

  <div fxLayout="row" fxLayoutAlign="end center" ngClass.gt-sm="skip">
    <button mat-button routerLink="/dashboard">SKIP</button>
  </div>

</div>