import { Validators } from "@angular/forms";
import { CountdownConfig } from "ngx-countdown";
import { MfaConfig } from "../models/mfa-config.model";

export const getFormModel = {
  code: [undefined, Validators.required],
  trustDevice: [false],
};

export const getCountdownConfig = (leftTime: number): CountdownConfig => ({
  leftTime,
  format: "mm:ss",
  demand: true,
});

export const getMfaConfig = (config?: MfaConfig) => {
  return {
    ...defaultConfig,
    ...config,
  };
};

const defaultConfig: MfaConfig = {
  canAddPhoneNumberIfMissing: false,
  canChangePhoneNumberIfUnverified: false,
  isTrustingDeviceAllowed: false,
  isBackButtonVisible: false,
};
