import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, mapTo, switchMap } from "rxjs/operators";
import {
  ApplicationStage,
  EntityExData,
  EntityOtherInfoUpdateDto,
  EntityQueryResult,
  EntityRecentActivity,
  LoanStatus,
} from "common";
import { EntityRemote } from "../../infrastructure/entity.remote";
import { CustomerFacade } from "../../../customer/domain/+state/customer.facade";
import { EntityStateService } from "./entity.state.service";
import * as _ from "lodash";

@Injectable({
  providedIn: "root",
})
export class EntityFacade {
  allowActiveLoans: LoanStatus[] = [LoanStatus.Pending, LoanStatus.Open, LoanStatus.Frozen, LoanStatus.InProgressNoPayments,
    LoanStatus.InProgressInterestOnlyPayments, LoanStatus.InProgressInterestOnlyPayments,
    LoanStatus.Repayment, LoanStatus.PendingRenewal, LoanStatus.Delinquent,
    LoanStatus.Workout, LoanStatus.PaymentRelief];

  allowActiveApplications: ApplicationStage[] = [ApplicationStage.New, ApplicationStage.ApplicationCreated, ApplicationStage.ApplicationIncomplete,
                 ApplicationStage.MODALProcessing, ApplicationStage.MODALError, ApplicationStage.ConditionalOffer,
                 ApplicationStage.Underwriting, ApplicationStage.UnderwritingMissingInfo, ApplicationStage.UnderwritingReview,
                 ApplicationStage.Approved, ApplicationStage.Nurture, ApplicationStage.Accepted,
                 ApplicationStage.Closing, ApplicationStage.ClosingReview, ApplicationStage.UnderwritingCall,
                 ApplicationStage.PendingSignature, ApplicationStage.SalesReview, ApplicationStage.ConditionalApprovalNAICSCheck,
                 ApplicationStage.UnderwritingLitigation, ApplicationStage.PendingRenewal];
  constructor(
    private readonly stateService: EntityStateService,
    private readonly remote: EntityRemote,
    private readonly customerFacade: CustomerFacade
  ) {}

  getEntity$(id: number): Observable<EntityExData> {
    if (!id && this.stateService.isAnyEntityStateLoaded()) {
      return this.stateService.getEntity$();
    }
    if (!!id && this.stateService.isEntityStateLoaded(id)) {
      return this.stateService.getEntity$();
    }

    return this.refreshEntity$(id);
  }

  getEntities$(): Observable<EntityExData[]> {
    if (this.stateService.isEntitiesStateLoaded()) {
      return this.stateService.getEntities$();
    }

    return this.refreshEntities$();
  }

  private refreshEntities$(): Observable<EntityExData[]> {
    return this.customerFacade.getCurrentCustomer$().pipe(
      switchMap((customer) => this.remote.query({ customerId: customer.id })),
      switchMap((results: EntityQueryResult) => {
        this.stateService.setEntities(_.orderBy(results?.values, ['dba', 'name'], ['asc', 'asc']));
        return this.stateService.getEntities$();
      })
    );
  }

  private refreshEntity$(id: number): Observable<EntityExData> {
    if (!id) {
      return this.refreshFirstEntityWithActiveloansOrActiveApplications$();
    }

    return this.remote.get(id).pipe(
      catchError(() => of(null)),
      switchMap((entity: EntityExData) => {
        if (entity) {
          this.stateService.setEntity(entity);
          return this.stateService.getEntity$();
        }
        return this.refreshFirstEntityWithActiveloansOrActiveApplications$();
      })
    );
  }

  private refreshFirstEntityFromList$(): Observable<EntityExData> {
    return this.getEntities$().pipe(
      switchMap((entities: EntityExData[]) => {
        const firstEntity = entities?.[0];
        this.stateService.setEntity(firstEntity);
        return this.stateService.getEntity$();
      })
    );
  }

  private refreshFirstEntityWithActiveloansOrActiveApplications$(): Observable<EntityExData> {
    return this.getEntities$().pipe(
      switchMap((entities: EntityExData[])=> {
        let entity = entities?.find(e => e.loans.find(l=>this.allowActiveLoans.includes(l.status)));
        if(!entity)
          entity = entities?.find(e => e.applications.find(a => this.allowActiveApplications.includes(a.stage)));
        if(!entity)
          entity = entities?.[0];
        this.stateService.setEntity(entity);
        return this.stateService.getEntity$();
      })
    );
  }

  clearEntityCache(): void {
    return this.stateService.clearState();
  }

  updateOtherInfo(
    id: number,
    entity: EntityOtherInfoUpdateDto
  ): Observable<void> {
    return this.remote.updateOtherInfo(id, entity).pipe(
      switchMap(() => this.refreshEntity$(id)),
      mapTo(null)
    );
  }

  changeAddress(data: any, id: number): Observable<void> {
    return this.remote.changeAddress(data, id).pipe(
      switchMap(() => this.refreshEntity$(id)),
      mapTo(null)
    );
  }

  getRecentActivities(id: number): Observable<EntityRecentActivity[]> {
    return this.remote.getRecentActivities(id);
  }
}
