import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AppBarCloseActionInternalService {
  private closingSubject = new Subject<void>();
  closing$: Observable<void> = this.closingSubject.asObservable();

  close(): void {
    this.closingSubject.next();
  }
}
