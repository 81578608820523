import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { RenewalService } from "../../renewal/renewal.service";
import { ApplicationStage, RenewalEligibilityDto } from "common";
import { RenewalBaseGuard } from "./renewal-base.guard";

@Injectable({
    providedIn: "root"
})
export class RenewalEligibleReminderGuard extends RenewalBaseGuard {

    constructor(router: Router, renewalService: RenewalService) {
        super(router, renewalService);
    }

    protected validate(eligibile: RenewalEligibilityDto): boolean {
        const viewed = JSON.parse(sessionStorage.getItem('renewalEligibilityPageViewed'));
        if (!eligibile || viewed)
            return true;
        if (!eligibile.optedIn || !eligibile.renewalApplicationId) {
            this.renewalService.renewalEligibilityChanged.next(eligibile);
            this.router.navigate([`account/${eligibile?.loanId}/renewal/eligible`]);
            return false;
        } else if (eligibile.renewalApplicationStage === ApplicationStage.ApplicationIncomplete) {
            this.renewalService.renewalEligibilityChanged.next(eligibile);
            this.router.navigate([`account/${eligibile?.loanId}/renewal/documents`]);
            return false;
        } else
            return true;
    }
}
