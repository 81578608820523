import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest } from 'rxjs';
import { AppPageService } from '../app-page/app-page.service';
import { AppBarComponentData } from '../app-bar/app-bar.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BrandingService } from '../../../../core/branding/branding.service';
import { CompanyBranding } from '../../../branding/branding.model';

@UntilDestroy()
@Component({
  selector: 'ifc-app-bar-contact',
  templateUrl: './app-bar-contact.component.html',
  styleUrls: ['./app-bar-contact.component.scss']
})
export class AppBarContactComponent implements OnInit {
  advisorName: string;
  phoneNumber: string;
  ngClass: string;
  appBarComponentData: AppBarComponentData;

  constructor(
    private route: ActivatedRoute,
    private appPageService: AppPageService,
    private brandingService: BrandingService
  ) { }

  ngOnInit() {
    this.initData();
    this.appPageService.advisorNameChanged.pipe(untilDestroyed(this))
      .subscribe(it => this.advisorName = it);
    this.appPageService.phoneNumberChanged.pipe(untilDestroyed(this))
      .subscribe(it => this.phoneNumber = it);
  }

  private initData(): void {
    combineLatest([this.route.data, this.brandingService.getBranding$()])
      .pipe(untilDestroyed(this)).subscribe(([data, branding]) => {
        if (data && data.appBar) {
          this.appBarComponentData = data;
          this.advisorName = data.appBar.advisorName;
          this.ngClass = data.appBar.contactInfoClass;
        }

        if (!this.advisorName) {
          this.advisorName = 'us';
        }

        this.phoneNumber = this.getPhoneInfo(branding);
      })
  }

  private getPhoneInfo(branding: CompanyBranding): string {
    return branding?.phone ?? this.appBarComponentData?.appBar.phoneNumber ?? '1 (855) 900-7838';
  }
}
