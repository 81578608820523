import { Pipe, PipeTransform } from '@angular/core';
import { StringHelper } from '../../helpers/string.helper';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {
  transform(value: string) {
    return StringHelper.phoneFormat(value);
  }
}
