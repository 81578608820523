<nav ngClass.lt-sm="hidden">
  <button *ngFor="let offerBundle of offerBundles"
          (click)="click(offerBundle)"
          [class.active]="offerBundle.offerBundleId === currentOfferBundle.offerBundleId">
    {{ (offerBundle.express ? 'express ' : '') + offerBundle.repayment + (!offerBundle.express ? ' months' : '') }}
  </button>
</nav>
<mat-form-field appearance="outline"
                ngClass.gt-xs="hidden">
  <mat-label>Repayment term</mat-label>
  <mat-select matInput
              autocomplete="off"
              [(value)]="currentOfferBundle.offerBundleId">
    <mat-option [value]="offerBundle.offerBundleId"
                *ngFor="let offerBundle of offerBundles"
                (click)="click(offerBundle)">
      {{ (offerBundle.express ? 'express ' : '') + offerBundle.repayment + (!offerBundle.express ? ' months' : '') }}
    </mat-option>
  </mat-select>
</mat-form-field>
