import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DisplayAllOffersData } from "../../models/display-all-offers-data.model";
import { LinkBankData } from "../../models/link-bank-data.model";
import { OpenDocumentsData } from "../../models/open-documents-data.model";
import { ShowDisclosuresData } from "../../models/show-disclosures-data.model";
import { OfferActionsInternalService } from "./offer-actions.internal.service";
import { OfferActionIds } from "../../models/offer-action-ids.model";

@Injectable({
  providedIn: "root",
})
export class OfferActionsService {
  constructor(protected internalService: OfferActionsInternalService) {}

  get displayAllOffers$(): Observable<DisplayAllOffersData> {
    return this.internalService.displayAllOffers$;
  }

  get showDisclosures$(): Observable<ShowDisclosuresData> {
    return this.internalService.showDisclosures$;
  }

  get openDocumentsDetails$(): Observable<OpenDocumentsData> {
    return this.internalService.openDocumentsDetails$;
  }

  get linkBank$(): Observable<LinkBankData> {
    return this.internalService.linkBank$;
  }

  get navigateToOffersSummary$(): Observable<number> {
    return this.internalService.navigateToOffersSummary$;
  }

  get navigateToOfferDetails$(): Observable<OfferActionIds> {
    return this.internalService.navigateToOfferDetails$;
  }
}
