import { Injectable } from "@angular/core";
import {
  ApplicationBaseData,
  ApplicationOffer,
  ApplicationOfferBundleData,
} from "../../../application/application.model";
import { first } from "lodash";
import { ProductCode } from "../../../product/product.model";
import { CurrencyPipe, PercentPipe } from "@angular/common";
import { OfferCalculatorService } from "../../services/offer-calculator.service";
import { OfferLawCalculator } from "../../services/offer-law-calculator";
import { OfferLocCalculator } from "../../services/offer-loc-calculator";
import { OfferTermCalculator } from "../../services/offer-term-calculator";
import { OfferCalculator } from "../../services/offer-calculator";
import { OfferActionsInternalService } from "../../services/actions/offer-actions.internal.service";
import { OfferBundlesSummary } from "../../models/offer-bundles-summary.model";
import { OfferBundlePreview } from "../../models/offer-bundle-preview.model";
import { OfferActionIds } from "../../models/offer-action-ids.model";

@Injectable()
export class OfferDetailsFacade {
  constructor(
    private percentPipe: PercentPipe,
    private currencyPipe: CurrencyPipe,
    private offerCalculatorService: OfferCalculatorService,
    private actionsService: OfferActionsInternalService
  ) {}

  createOfferCalculator(
    productCode: ProductCode,
    application: ApplicationBaseData,
    offerBundle: ApplicationOfferBundleData
  ): OfferCalculator {
    const offer = first(offerBundle?.offers, { productCode });

    const offerCalculator = this.createOfferCalculatorBase(
      productCode,
      application,
      offerBundle,
      offer
    );

    offerCalculator.brokerInitialDrawCommision =
      application.brokerInitialDrawCommision / 100;

    return offerCalculator;
  }

  private createOfferCalculatorBase(
    productCode: ProductCode,
    application: ApplicationBaseData,
    offerBundle: ApplicationOfferBundleData,
    offer: ApplicationOffer
  ): OfferCalculator {
    switch (productCode) {
      case ProductCode.LOC: {
        return new OfferLocCalculator(
          application,
          offer,
          offerBundle,
          this.percentPipe,
          this.currencyPipe,
          this.offerCalculatorService
        );
      }

      case ProductCode.Term: {
        return new OfferTermCalculator(
          application,
          offer,
          offerBundle,
          this.percentPipe,
          this.currencyPipe,
          this.offerCalculatorService
        );
      }
      case ProductCode.Law: {
        return new OfferLawCalculator(
          application,
          offer,
          offerBundle,
          this.percentPipe,
          this.currencyPipe,
          this.offerCalculatorService
        );
      }
      default:
        return null;
    }
  }

  navigateToOffersSummary(applicationId: number): void {
    this.actionsService.navigateToOffersSummary(applicationId);
  }

  navigateToOfferDetails(data: OfferActionIds): void {
    this.actionsService.navigateToOfferDetails(data);
  }

  getOfferBundlesSummaryData(
    offerBundles: ApplicationOfferBundleData[],
    currentOfferBundleId: number
  ): OfferBundlesSummary {
    const offerBundlesSummary: OfferBundlePreview[] =
      this.mapOfferBundles(offerBundles);

    const locOfferBundles = this.getOfferBundlesByProductCode(
      offerBundlesSummary,
      ProductCode.LOC
    );
    const termOfferBundles = this.getOfferBundlesByProductCode(
      offerBundlesSummary,
      ProductCode.Term
    );
    const lawOfferBundles = this.getOfferBundlesByProductCode(
      offerBundlesSummary,
      ProductCode.Law
    );
    return {
      locOfferBundles,
      termOfferBundles,
      lawOfferBundles,
      bestLocOfferBundle: locOfferBundles?.[0],
      bestTermOfferBundle: termOfferBundles?.[0],
      bestLawOfferBundle: lawOfferBundles?.[0],
      currentOfferBundle: this.getCurrentOfferBundle(
        offerBundlesSummary,
        currentOfferBundleId
      ),
    };
  }

  private mapOfferBundles(
    offerBundles: ApplicationOfferBundleData[]
  ): OfferBundlePreview[] {
    return offerBundles
      .sort((a, b) => {
        if (a.expressCheckout && !b.expressCheckout) return -1;
        else if (b.expressCheckout && !a.expressCheckout) return 1;
        else return b.offers[0]?.repaymentTerm - a.offers[0]?.repaymentTerm;
      })
      .map((offerBundle) => {
        return {
          repayment: offerBundle.offers[0]?.repaymentTerm?.toString(),
          offerBundleId: offerBundle.id,
          productCode: offerBundle.offers[0]?.productCode,
          amount: offerBundle.offers[0]?.amount,
          express: offerBundle.expressCheckout,
        };
      });
  }

  private getOfferBundlesByProductCode(
    offerBundles: OfferBundlePreview[],
    productCode: ProductCode
  ): OfferBundlePreview[] {
    return offerBundles?.filter(
      (offerBundle) => offerBundle.productCode === productCode
    );
  }

  private getCurrentOfferBundle(
    offerBundles: OfferBundlePreview[],
    currentOfferBundleId: number
  ): OfferBundlePreview {
    return offerBundles.find(
      (item) => item.offerBundleId === currentOfferBundleId
    );
  }
}
