import { Component, Inject, OnInit } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { Uppy, UppyFile, UploadResult } from "@uppy/core";
import { AppSettings } from "projects/portal/src/app.settings";
import { getUppyInstance } from "projects/portal/src/app/document/document-upload/document-upload-uppy-config";
import { Observable } from "rxjs";
import { ProgressBarDialogService } from "./progress-bar-dialog.service";
import {
  ProgressBarDialogComponentConfig,
  ProgressBarDialogComponentResultEnum,
} from "./progress-bar.model";
import { BrandingService, CompanyBranding } from "common";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "ifp-progress-bar-dialog",
  templateUrl: "./progress-bar-dialog.component.html",
  styleUrls: ["./progress-bar-dialog.component.scss"],
  providers: [ProgressBarDialogService],
})
export class ProgressBarDialogComponent implements OnInit {
  progressValue: number;
  phoneNumber: string;
  uppy: Uppy;
  failureFiles: UppyFile[];
  recentTryFiles: UppyFile[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ProgressBarDialogComponentConfig,
    private dialog: MatDialogRef<ProgressBarDialogComponent>,
    private service: ProgressBarDialogService,
    private settings: AppSettings,
    private brandingService: BrandingService
  ) {
    this.failureFiles = [];
    this.progressValue = 0;
    this.service.setEndpointGenerator(this.data.generateEndpoint);
  }

  ngOnInit(): void {
    this.initPhoneNumber();
    this.uppy = getUppyInstance("", "");
    this.uppy.on("progress", (progress) => {
      this.progressValue = progress;
    });

    this.recentTryFiles = this.data.filesToUpload;
    this.handleUpload(
      this.service.prepairUppyAndStartUpload(this.uppy, this.recentTryFiles)
    );
  }

  public cancel() {
    this.dialog.close(ProgressBarDialogComponentResultEnum.Canceled);
  }

  public tryAgain() {
    this.recentTryFiles = this.failureFiles;
    this.handleUpload(
      this.service.prepairUppyAndStartUpload(this.uppy, this.recentTryFiles)
    );
  }

  static show(
    dialog: MatDialog,
    dialogConfig: ProgressBarDialogComponentConfig
  ): Observable<any> {
    return dialog
      .open(ProgressBarDialogComponent, {
        width: "650px",
        data: { ...dialogConfig },
        closeOnNavigation: true,
        disableClose: true,
      })
      .afterClosed();
  }

  private handleUpload(uploadPromise: Promise<UploadResult>) {
    uploadPromise
      ?.then(x => this.renderUploadResult(x))
      .catch(e => this.renderUploadResult(e));
  }

  private renderUploadResult(uploadResult: UploadResult) {
    if (!uploadResult) {
      return;
    }

    if (isThereAnyFailures()) {
      this.failureFiles = uploadResult.failed;
      this.progressValue = 0;
      return;
    }

    if (
      isThereAnySuccesses() &&
      uploadResult.successful.length !== this.recentTryFiles.length
    ) {
      this.failureFiles = this.recentTryFiles.filter(
        (rty) => !uploadResult.successful.map((s) => s.id).includes(rty.id)
      );
      this.progressValue = 0;
      return;
    }

    if (isThereAnySuccesses()) {
      this.dialog.close(ProgressBarDialogComponentResultEnum.Completed);
      return;
    }

    console.warn("Unsupported upload result");
    return;

    function isThereAnySuccesses() {
      return uploadResult.successful && uploadResult.successful.length;
    }

    function isThereAnyFailures() {
      return uploadResult.failed && uploadResult.failed.length;
    }
  }

  private initPhoneNumber(): void {
    this.brandingService.getBranding$()
      .pipe(untilDestroyed(this))
      .subscribe((branding: CompanyBranding) => {
        this.phoneNumber = branding?.phone ?? this.settings.if.contact.phone;
      });
  }
}
