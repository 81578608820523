import { Injectable } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Uppy } from "@uppy/core";
import {
  getUppyInstance
} from "projects/portal/src/app/document/document-upload/document-upload-uppy-config";
import { UploadFileDialogComponent } from "./upload-file-dialog.component";
import {
  UploadFileDialogConfig,
  UploadFileDialogResult,
  UppyFileChangeTypeEnum
} from "./upload-file.model";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class UploadFileDialogService {
  files: BehaviorSubject<any>;
  getMetaCallback: (data: UploadFileDialogConfig) => void;

  public getUppy(
    data: UploadFileDialogConfig,
    result: UploadFileDialogResult,
    dialog: MatDialogRef<UploadFileDialogComponent>
  ): Uppy {
    if (!result) {
      result = {} as UploadFileDialogResult;
    }
    this.files = new BehaviorSubject([]);
    const uppy = getUppyInstance(data.endpoint, data.accessToken);

    if (data.files) {
      uppy.addFiles(data.files);
    }

    const current = this.setUppyListeners(uppy, data, result, dialog);
    result.type = current.type;
    result.files = current.files;
    return uppy;
  }

  public setMetaCallback(callback: (data: UploadFileDialogConfig) => void) {
    this.getMetaCallback = callback;
  }

  private setUppyListeners(
    uppy: Uppy,
    data: UploadFileDialogConfig,
    result: UploadFileDialogResult,
    dialog: MatDialogRef<UploadFileDialogComponent, any>
  ): UploadFileDialogResult {
    if (!result) {
      result = {} as UploadFileDialogResult;
    }

    uppy.on("file-added", () => {
      if (data.checkoutRequirementId && data.applicationId) {
        uppy.getFiles().forEach((uppyFile) => {
          uppy.setFileMeta(uppyFile.id, this.getMeta(data));
        });
      }

      result.type = UppyFileChangeTypeEnum.Added;
      result.files = uppy.getFiles();
      this.files.next(result.files);
    });

    uppy.on("file-removed", () => {
      result.type = UppyFileChangeTypeEnum.Added;
      result.files = uppy.getFiles();
      this.files.next(result.files);
    });

    uppy.on("cancel-all", () => {
      result.type = UppyFileChangeTypeEnum.Canceled
      result.files = undefined;      
      dialog.close(result);
    });
    
    return result;
  }

  private getMeta(data: UploadFileDialogConfig): any {
    if (this.getMetaCallback) {
      return this.getMetaCallback(data);
    } else {
      return {
        checkoutRequirementId: data.checkoutRequirementId,
        applicationId: data.applicationId,
        category: data.category
      };
    }
  }
}
