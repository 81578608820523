import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { ConfigService } from "../../config/config.service";
import { AboutData } from "../models/about.model";

@Injectable({
    providedIn: 'root'
})
export class AboutService {

    constructor(private _http: HttpClient, private configService: ConfigService) { }

    customerNotes(): Observable<AboutData> {
        return this._http.get<AboutData>(`${this.configService.environment.doc.url}/api/about`);
    }

    partnerNotes(): Observable<AboutData> {
        return this._http.get<AboutData>(`${this.configService.environment.doc.url}/api/about/partner`);
    }

    internalNotes(): Observable<AboutData> {
        return this._http.get<AboutData>(`${this.configService.environment.doc.url}/api/about/internal`);
    }
}
