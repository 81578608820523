<mat-card class="mat-elevation-z0">
  <div>
    <mat-card-title>Hello {{data.customer.firstName}}!</mat-card-title>
  </div>

  <mat-card-content>
    <section class="content-title">
      You are just a few moments away from accessing your business financing.
      Once you get started, avoid closing your browser, hitting the refresh button, or leaving the screen before proceeding to the next step.
    </section>

    <mat-divider></mat-divider>

    <section class="content-subtitle">
        What happens next?
    </section>

    <section class="content-item">
      <div>
        <img src="../../../../assets/img/checkout/verify.png" alt="Verify identity" />
      </div>
      <div>
        <div>
          Verify your identity
        </div>
        <div>
          To make it easy and safe for you to verify your identity, we'll provide you with a few questions only {{data.customer.firstName}} {{data.customer.lastName}} will know the answers to.
        </div>
      </div>
    </section>

    <section class="content-item">
      <div>
        <img src="../../../../assets/img/checkout/agreement.png" alt="Review agreement" />
      </div>
      <div>
        <div>
          Review and accept the agreement
        </div>
        <div>
          We'll provide you with your terms and conditions.
        </div>
      </div>
    </section>

    <section class="content-item" *ngIf="data.loan?.productCode === productCodes.LOC || data.loan?.productCode === productCodes.Law">
      <div>
        <img src="../../../../assets/img/checkout/funds.png" alt="Draw funds" />
      </div>
      <div>
        <div>
          Draw funds
        </div>
        <div>
          Once you sign your agreement you'll be ready to draw funds from your line of credit.
        </div>
      </div>
    </section>

    <section class="content-item" *ngIf="data.loan?.productCode === productCodes.Term">
      <div>
        <img src="../../../../assets/img/checkout/funds.png" alt="Get funded" />
      </div>
      <div>
        <div>
          Get funded
        </div>
        <div>
          Once you sign your agreement we’ll disburse the funds into your bank account.
        </div>
      </div>
    </section>

    <section class="content-item">
      <div>
        <img src="../../../../assets/img/checkout/link.png" alt="Link bank" />
      </div>
      <div>
        <div>
          Link your bank
        </div>
        <div>
          After you submit your request, link your bank if you have not already done so.
          Linking your bank speeds up the funding process.
        </div>
      </div>
    </section>
  </mat-card-content>

  <mat-card-actions align="end">
    <button mat-raised-button (click)="continue()" color="primary" [disabled]="!continueEnabled">Continue</button>
  </mat-card-actions>
</mat-card>
