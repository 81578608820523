import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AppPageService } from "common";

@UntilDestroy()
@Component({
  selector: "ifp-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
  accountMode: boolean;

  constructor(private route: ActivatedRoute, private router: Router, private appPageService: AppPageService) {}

  ngOnInit() {
    this.route.data.pipe(untilDestroyed(this)).subscribe((data) => {
      this.accountMode = data.loans?.length > 0;
    });
  }

  back() {
    const result = this.appPageService.backToIfPossible(true);
    if (result) {
      return;
    }

    this.router.navigate(["/dashboard"]);
  }
}
