<nav>
  <button
      (click)="click(PersonalCompanyFormType.Personal)" class="{{companyBranding?.colorSchema}}-color"
      [class.levelesq-active]="currentFormType === PersonalCompanyFormType.Personal && companyBranding?.colorSchema === 'levelesq'"
      [class.ideafinancial-active]="currentFormType === PersonalCompanyFormType.Personal && companyBranding?.colorSchema === 'ideafinancial'"
      [class.levelesq-button]="companyBranding?.colorSchema === 'levelesq'"
      [class.ideafinancial-button]="companyBranding?.colorSchema === 'ideafinancial'">
    PERSONAL
  </button>

  <button *ngIf="showCompanyButton"
      (click)="click(PersonalCompanyFormType.Company)" class="{{companyBranding?.colorSchema}}-color"
      [class.levelesq-active]="currentFormType === PersonalCompanyFormType.Company && companyBranding?.colorSchema === 'levelesq'"
      [class.ideafinancial-active]="currentFormType === PersonalCompanyFormType.Company && companyBranding?.colorSchema === 'ideafinancial'"
      [class.levelesq-button]="companyBranding?.colorSchema === 'levelesq'"
      [class.ideafinancial-button]="companyBranding?.colorSchema === 'ideafinancial'"
  >
    COMPANY
  </button>
</nav>
