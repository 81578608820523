<h1 mat-dialog-title>{{data.loan?.loanNumber}}, Case {{data.loan?.externalId}} description</h1>
<mat-dialog-content>
  <p class="secondary-text sub-title">
    Add description.
  </p>
  <div class="content">
    <mat-form-field appearance="outline" fxFlexFill>
      <mat-label>Description</mat-label>
      <textarea matInput placeholder="Description" [(ngModel)]="data.loan.description" rows="4"></textarea>
    </mat-form-field>
  </div>
</mat-dialog-content>

<div mat-dialog-actions class="flex row end-center">
  <button mat-button [mat-dialog-close]="undefined">Cancel</button>
  <button mat-button [mat-dialog-close]="data.loan.description" color="primary">Update</button>
</div>
