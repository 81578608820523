import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import {
  DisplayAllOffersData,
  LinkBankData,
  OfferActionsService,
  OfferType,
} from "common";
import { BankingService } from "../../../bank-account/banking.service";

@UntilDestroy()
@Injectable()
export class OfferSummaryFacade {
  constructor(
    private router: Router,
    private bankingService: BankingService,
    private offerActionsService: OfferActionsService
  ) {}

  initActions(): void {
    this.offerActionsService.displayAllOffers$
      .pipe(untilDestroyed(this))
      .subscribe((data: DisplayAllOffersData) => {
        this.redirectToOfferDetails(data);
      });

    this.offerActionsService.linkBank$
      .pipe(untilDestroyed(this))
      .subscribe((data: LinkBankData) => {
        this.linkYourBank(data.entityId);
      });
  }

  watchLinkingBankSuccessfully() {
    return this.bankingService.obsSync;
  }

  private redirectToOfferDetails(data: DisplayAllOffersData): void {
    const offerType = this.convertOfferTypeToPath(data.offerType);
    const url = `/application/${data.applicationId}/offer/${data.offerBundleId}/${offerType}`;

    this.router.navigate([url], { replaceUrl: true });
  }

  private convertOfferTypeToPath(offerType: OfferType): string {
    switch (offerType) {
      case OfferType.LOC: {
        return "loc";
      }
      case OfferType.Term: {
        return "term";
      }
      case OfferType.Law: {
        return "law";
      }
    }
  }

  private linkYourBank(entiyId: number): void {
    this.bankingService.link(entiyId);
  }
}
