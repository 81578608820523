import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CallbackComponent } from "./callback/callback.component";
import { LoginDirective } from "./login/login.directive";
import { FlutterEventGuard } from "../guards/flutter-event.guard";
import { SignInGuard } from "../user-sign-in/sign-in.guard";
import { CallbackGuard } from "./callback/callback.guard";

const routes: Routes = [
  {
    path: "callback",
    canActivate: [CallbackGuard],
    component: CallbackComponent,
  },
  {
    path: "sign-in",
    canActivate: [SignInGuard, FlutterEventGuard],
    component: LoginDirective,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OidcRoutingModule {}
