import { SubtransactionData, TransactionStatus, TransactionType } from "../transaction/transaction.model";

export interface AccountTransaction {
    id?: number;
    accountId?: number;
    loanNumber?: string;
    loanProductName?: string;
    businessName?: string;
    cancellable: boolean;
    date?: Date;
    processDate?: Date;
    underwritingDate?: Date;
    financeDate?: Date;
    completeDate?: Date;
    cancellationDate?: Date;
    amount?: number;
    principalAmount?: number;
    interestAmount?: number;
    initiator?: string;
    modifier?: string;
    type?: TransactionType;
    status?: TransactionStatus;
    maintained?: string;
    disbursedAmount?: number;
    thirdPartyPayoffAmount?: number;
    deferredFeeAmount?: number;
    cashFeeAmount?: number;
    capitalAmount?: number;
    displayAmount?: number;
    closingBalance?: number;
    outstandingInterest?: number;
    totalDeferredFees?: number;
    totalCashFees?: number;
    totalFees?: number;
    totalInterest?: number;
    totalPayback?: number;
    totalPrincipal?: number;
    notes?: Note[];
    failureReasons?: string[];
    subtransactions?: SubtransactionData[];
    customerNote?: CustomerNoteData;
}

export interface CustomerNoteData {
    initiator: string;
    text: string;
    created: Date;
}

export interface Note {
    initiator: Initiator;
    date: Date;
    text: string;
}

export interface Initiator {
    FullName: string;
}

export class InitiatorData implements Initiator {
    constructor(public FullName: string) { }
}

export interface AccountTransactionQueryResult {
    loanId: number;
    totalCount: number;
    transactions: AccountTransaction[];
}

export const TransactionStatusChips = {
    '0': { color: 'mat-grey-bg', icon: 'help', label: 'Pending' },
    '2': { color: 'mat-red-bg', icon: 'block', label: 'Rejected' },
    '3': { color: 'mat-orange-bg', icon: 'highlight_off', label: 'Cancelled' },
    '4': { color: 'mat-green-bg', icon: 'check_circle', label: 'Approved' },
    '5': { color: 'mat-indigo-bg', icon: 'done_all', label: 'Verified' },
    '7': { color: 'mat-purple-bg', icon: 'warning', label: 'Declined' },
    '8': { color: 'mat-red-bg', icon: 'sentiment_very_dissatisfied', label: 'Abandoned' },
    '9': { color: 'mat-grey-bg', icon: 'help', label: 'Pending' }
};
