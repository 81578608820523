import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ApplicationOfferDisclosure } from "common";
import { Observable } from "rxjs";
import { AppSettings } from "../../app.settings";

@Injectable({
  providedIn: "root",
})
export class OfferService {
  private readonly baseUrl = `${this.settings.applications.url}/api/offer`;

  constructor(private http: HttpClient, private settings: AppSettings) {}

  getDisclosures(
    applicationId: number,
    offerId: number
  ): Observable<ApplicationOfferDisclosure[]> {
    return this.http.get<ApplicationOfferDisclosure[]>(
      `${this.baseUrl}/${offerId}/application/${applicationId}/disclosures`
    );
  }
}
