import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import { Router } from "@angular/router";
import { ApplicationStatus, EntityExData } from "common";
import { ApplicationData } from "../../application/application.model";

@Component({
  selector: "ifp-dashboard-application-widget",
  templateUrl: "./application-widget.component.html",
  styleUrls: ["./application-widget.component.scss"],
})
export class DashboardApplicationWidgetComponent implements AfterViewInit {
  disableMatTooltip: boolean = true;

  @ViewChild("caseName", { static: false }) caseName: ElementRef;

  @Input() application: ApplicationData;
  @Input() entity: EntityExData;
  @Input() isBankLinked: boolean;

  get status() {
    return ApplicationStatus;
  }

  get loanRenewal() {
    return this.application?.loanRenewals?.length
      ? this.application.loanRenewals[0]
      : null;
  }

  get maskedLoanRenewalNumber(): string {
    return this.loanRenewal?.loanNumber?.length > 4
      ? "*" +
          this.loanRenewal.loanNumber.substr(
            this.loanRenewal.loanNumber.length - 4
          )
      : null;
  }

  constructor(private router: Router) {}

  ngAfterViewInit(): void {
    this.disableMatTooltip =
      this.caseName?.nativeElement?.offsetWidth >=
      this.caseName?.nativeElement?.scrollWidth;
  }

  details() {
    this.router.navigate([`/application/${this.application?.id}/documents`]);
  }

  offer() {
    this.router.navigate(
      [`/application/${this.application.id}/offers/summary`],
      { replaceUrl: true }
    );
  }

  calendarLink() {
    window.open(
      this.entity?.advisorCalendarLinkUrl ??
        "https://calendly.com/ideafinancial",
      "_blank"
    );
  }

  link() {
    this.router.navigate(["/bank-link-reminder"]);
  }

  upload(): void {
    this.router.navigate([
      `application/${this.application?.id}/documents/upload`,
    ]);
  }
}
