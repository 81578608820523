import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppPageComponent, AppBarComponent, AppBarCloseAction, AppBarTitleComponent, AppBarActionsComponent } from 'common';
import { AccountTransferDrawComponent } from './account-transfer-draw/account-transfer-draw.component';
import { AccountTransferDrawResolver } from './account-transfer-draw.resolver';
import { AccountTransferPaymentResolver } from './account-transfer-payment.resolver';
import { AccountTransferPaymentComponent } from './account-transfer-payment/account-transfer-payment.component';
import { LoanResolver } from '../../loan/infrastructure/loan.resolver';
import { OAuthGuard } from '../../../guards/oauth.guard';

const appPage = {
  contentClass: 'simple',
  contentAlignment: 'center-center',
  contentWithoutPadding: true
}

const appBar = {
  closeAction: AppBarCloseAction.BACK,
  expandable: false,
  logoHidden: true,
};

const routes: Routes = [
  {
    path: 'account/:accountId/transfer/draw',
    component: AppPageComponent,
    canActivate: [OAuthGuard],
    data: {
      appBar: appBar,
      appBarActions: {
        menuMobile: true
      },
      appPage: appPage
    },
    children: [
      {
        path: '',
        outlet: 'app-bar',
        component: AppBarComponent,
        children: [
          {
            path: '',
            outlet: 'title',
            component: AppBarTitleComponent,
          },
          {
            path: '',
            outlet: 'right',
            component: AppBarActionsComponent,
          }
        ]
      },
      {
        path: '',
        component: AccountTransferDrawComponent,
        runGuardsAndResolvers: "paramsOrQueryParamsChange",
        resolve: {
          accountTransfer: AccountTransferDrawResolver,
          loan: LoanResolver
        }
      }
    ]
  },
  {
    path: 'account/:accountId/transfer/payment',
    component: AppPageComponent,
    canActivate: [OAuthGuard],
    data: {
      appBar: appBar,
      appBarActions: {
        menuMobile: true
      },
      appPage: appPage
    },
    children: [
      {
        path: '',
        outlet: 'app-bar',
        component: AppBarComponent,
        children: [
          {
            path: '',
            outlet: 'title',
            component: AppBarTitleComponent,
          },
          {
            path: '',
            outlet: 'right',
            component: AppBarActionsComponent,
          }
        ]
      },
      {
        path: '',
        component: AccountTransferPaymentComponent,
        runGuardsAndResolvers: "paramsOrQueryParamsChange",
        resolve: {
          accountTransfer: AccountTransferPaymentResolver,
          loan: LoanResolver
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountTransferRoutingModule { }
