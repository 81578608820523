import { NgModule } from '@angular/core';
import { CommonModule } from 'common';

import { AccountSetupRoutingModule } from './account-setup-routing.module';
import { AccountSetupBeginComponent } from './account-setup-begin/account-setup-begin.component';
import { SetupBeginComponent } from './setup-begin/setup-begin.component';

@NgModule({
  imports: [
    CommonModule,
    AccountSetupRoutingModule
  ],
  declarations: [
    AccountSetupBeginComponent, 
    SetupBeginComponent
  ]
})
export class AccountSetupModule { }
