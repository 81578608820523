<ifc-app-scroll-container class="ifc-app-scroll-container-center">
  <div id="main" fxLayout="column" fxLayoutAlign="center center">

    <div fxLayout.gt-md="row" fxLayoutAlign.gt-md="start start" fxLayoutGap.gt-md="48px" fxLayout="column"
      fxLayoutAlign="start center" fxFlexFill.lt-lg ngClass.lt-lg="fit-content">

      <form [formGroup]="form">

        <div fxLayout="column" fxLayoutAlign="start start" [ngClass.gt-xs]="'form-field-md'"
          [ngClass.xs]="'form-field-xs'">

          <mat-form-field appearance="outline">
            <mat-label>Loan Number</mat-label>
            <input matInput type="text" formControlName="loanNumber">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Legal entity name</mat-label>
            <input matInput type="text" formControlName="businessName">
          </mat-form-field>

          <mat-card [ngClass]="'card xs'" class="mat-elevation-z0 layout margin-bottom">
            <mat-card-content>
              <mat-list>
                <mat-list-item class="header-title">
                  <span mat-list-icon></span>
                  <span mat-line>Transaction type</span>
                  <span>Amount</span>
                </mat-list-item>
                <mat-divider></mat-divider>
                <mat-list-item *ngFor="let item of subtransactions; index as i">
                  <span class="mat-caption" mat-list-icon>{{i+1}}</span>
                  <span mat-line>{{getTypeName(item.type)}}</span>
                  <span class="item-content">{{item.amount | currency: '$'}}</span>
                  <mat-divider></mat-divider>
                </mat-list-item>
                <mat-list-item>
                  <span mat-list-icon></span>
                  <span mat-line>Total</span>
                  <span>{{transaction.displayAmount | currency: '$'}}</span>
                </mat-list-item>
              </mat-list>
            </mat-card-content>
          </mat-card>

          <div class="note" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="12px" *ngIf="transaction.customerNote">
            <span class="secondary">{{transaction.initiator}} wrote a note</span>
            <span >{{(transaction.customerNote.text)}}</span>
            <span class="secondary">{{transaction.date | date : 'medium'}}</span>
          </div>
          <br>
          <mat-form-field appearance="outline">
            <mat-label>Status</mat-label>
            <input matInput type="text" formControlName="status">
          </mat-form-field>

        </div>

      </form>

      <ifc-app-card-one-column>
        <mat-card class="mat-elevation-z0" ngClass.lt-lg="layout margin-bottom">
          <div fxLayout="row" fxLayoutAlign="start center" class="card-header">
            <span class="card-title">Transfer details</span>
          </div>
          <mat-card-content>
            <mat-list>
              <mat-list-item>
                <span class="item-title secondary-text" mat-line>Transfer from</span>
                <span class="item-content">
                  {{transferFrom}}</span>
              </mat-list-item>
              <mat-divider></mat-divider>
              <mat-list-item>
                <span class="item-title secondary-text" mat-line>Transfer to</span>
                <span class="item-content"> {{transferTo}}</span>
              </mat-list-item>
              <mat-divider></mat-divider>
              <mat-list-item>
                <span class="item-title secondary-text" mat-line>Initiated by</span>
                <span class="item-content">{{transaction.initiator}}</span>
              </mat-list-item>
              <mat-divider></mat-divider>
              <mat-list-item>
                <span class="item-title secondary-text" mat-line>Modified by</span>
                <span class="item-content">{{transaction.modifier}}</span>
              </mat-list-item>
              <mat-divider></mat-divider>
              <mat-list-item>
                <span class="item-title secondary-text" mat-line>Transaction date</span>
                <span class="item-content">{{(transaction.date | date: 'short')}}</span>
              </mat-list-item>
              <mat-divider></mat-divider>
              <mat-list-item>
                <span class="item-title secondary-text" mat-line>Processed date</span>
                <span class="item-content">{{(transaction.processDate | date: 'short')}}</span>
              </mat-list-item>
              <mat-divider></mat-divider>
              <mat-list-item>
                <span class="item-title secondary-text" mat-line>Complete date</span>
                <span class="item-content">{{(transaction.completeDate | date: 'short')}}</span>
              </mat-list-item>
              <mat-divider></mat-divider>
              <mat-list-item>
                <span class="item-title secondary-text" mat-line>ID</span>
                <span class="item-content">{{transaction.id}}</span>
              </mat-list-item>
            </mat-list>
          </mat-card-content>
        </mat-card>
      </ifc-app-card-one-column>

    </div>
  </div>
</ifc-app-scroll-container>
