import { Pipe, PipeTransform } from "@angular/core";
import { EnumHelper } from "../../helpers/enum.helper";
import { DocumentCategory, DocumentCategoryLabel } from "../document.model";

@Pipe({
  name: "documentCategoryLabel",
})
export class DocumentCategoryLabelPipe implements PipeTransform {
  transform(value: DocumentCategory): string {
    return EnumHelper.getMappedNameFromValue(
      DocumentCategory,
      DocumentCategoryLabel,
      value
    );
  }
}
