import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import {
  AppBarTitleService,
  ConfigService,
  DocumentCategory,
  DocumentCategoryLabel,
  EnumHelper,
  OAuthService,
  RenewalEligibilityDto,
} from "common";
import * as _ from "lodash";
import { combineLatest, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { BankLinkingComponentData } from "../../bank-account/bank-account.model";
import { RenewalService } from "../../renewal/renewal.service";
import { RenewalInfoDialogComponent } from "../renewal-info-dialog/renewal-info-dialog.component";
import { Uppy } from "@uppy/core";
import { DashboardOptions } from "@uppy/dashboard";
import {
  getUppyDashboardOptions,
  getUppyInstance,
  useUppyXHRUploadPlugin,
} from "./renewal-eligible-documents-uppy-config";

@Component({
  selector: "ifp-renewal-eligible-documents",
  templateUrl: "./renewal-eligible-documents.component.html",
  styleUrls: ["./renewal-eligible-documents.component.scss"],
})
export class RenewalEligibleDocumentsComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  private _unsubscribeAll: Subject<any>;

  valid: boolean = false;
  private applicationId: number;
  bankLinkingData: BankLinkingComponentData;
  selectedCategory: DocumentCategory;
  submittingValue: boolean = false;
  private allowedCategories: DocumentCategory[];
  private categories = EnumHelper.getMappedNamesAndValues(
    DocumentCategory,
    DocumentCategoryLabel
  );
  public applicationCategories: any;
  public elibility: RenewalEligibilityDto;

  uppy: Uppy;
  uppyDashboardOptions: DashboardOptions;

  constructor(
    private route: ActivatedRoute,
    private appBarTitleService: AppBarTitleService,
    private authService: OAuthService,
    private configService: ConfigService,
    private renewalService: RenewalService,
    private dialog: MatDialog,
    private router: Router
  ) {
    this._unsubscribeAll = new Subject();
    this.allowedCategories = [DocumentCategory.BankStatement];
    this.selectedCategory = DocumentCategory.BankStatement;
    sessionStorage.setItem(
      "renewalEligibilityPageViewed",
      JSON.stringify(true)
    );
  }

  set submitting(value: boolean) {
    if (this.submittingValue !== value) {
      this.submittingValue = value;
      this.appBarRefresh();
    }
  }

  ngOnInit() {
    this.appBarTitleService.title = "Renew your loan";

    this.route.data
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data) => (this.bankLinkingData = data));

    this.initUppy();

    combineLatest([
      this.renewalService.obsCurrentrenewalEligibility,
      this.renewalService.obsCurrentOptIn,
    ])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(([elibility, obsCurrentOptIn]) => {
        this.elibility = elibility;
        this.applicationId = obsCurrentOptIn?.renewalApplicationId;
        this.initUppyXhr();
      });

    this.appBarRefresh();
    this.applicationCategories = _.flatMap(
      this.categories.filter((i) => _.includes(this.allowedCategories, i.value))
    );
  }

  ngAfterViewInit(): void {
    this.uppy.on("file-added", () => {
      this.appBarRefresh();
    });
    this.uppy.on("file-removed", () => {
      this.appBarRefresh();
    });
    this.uppy.on("complete", (result) => {
      this.submitting = false;

      if (result.failed && result.failed.length > 0) {
        return;
      }

      this.router.navigate(["dashboard"]);
      RenewalInfoDialogComponent.show(this.dialog).subscribe();
    });
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  appBarRefresh(): void {
    const files = this.uppy?.getFiles();
    this.valid =
      files != null &&
      files.some((f) => !f.progress.uploadComplete) &&
      !this.submitting;
  }

  submit(): void {
    if (this.submitting) {
      return;
    }

    this.submitting = true;
    this.uppy.getState()?.error ? this.uppy.retryAll() : this.uppy.upload();
  }

  private initUppy(): void {
    this.uppy = getUppyInstance();
    this.uppyDashboardOptions = getUppyDashboardOptions();
  }

  private initUppyXhr() {
    const id = this.applicationId ?? this.elibility?.renewalApplicationId;
    const endpoint = `${this.configService.environment.applications.url}/api/application/${id}/documents/${this.selectedCategory}`;
    useUppyXHRUploadPlugin(this.uppy, endpoint, this.authService.accessToken);
  }
}
