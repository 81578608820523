<ifc-app-scroll-container>

    <table mat-table aria-label="Recent activities table" [dataSource]="dataSource" *ngIf="dataSource.data?.length">
        <ng-container matColumnDef="icon">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row" class="table-cell small">
                <img *ngIf="hasIcon(row.event)" [src]="getIcon(row.event)" alt="Activity icon" class="activity-icon">
                <div *ngIf="!hasIcon(row.event)" class="avatar medium mat-light-grey-bg">
                    {{getInitial(row.event)}}
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef> Date </th>
            <td mat-cell *matCellDef="let row" class="table-cell">
                {{row.date | date:"mediumDate"}}
            </td>
        </ng-container>

        <ng-container matColumnDef="loan">
            <th mat-header-cell *matHeaderCellDef> Loan </th>
            <td mat-cell *matCellDef="let row" class="table-cell">
                {{row.caseDescription ? row.caseDescription : row.loanNumber}}
            </td>
        </ng-container>

        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef> Description </th>
            <td mat-cell *matCellDef="let row" class="table-cell">
                {{row.description}}
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="table-header"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row"></tr>
    </table>

</ifc-app-scroll-container>