import { NgModule } from '@angular/core';
import { CommonModule } from 'common';
import { UserRoutingModule } from './user-routing.module';
import { UserSignOutModule } from './user-sign-out/user-sign-out.module';
import { UserPasswordChangeFormComponent } from './user-password-change-form/user-password-change-form.component';
import { RouterModule } from '@angular/router';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { SharedModule } from '../shared/shared.module';
import { OidcModule } from './oidc/oidc.module';
import { UserSignUpRoutingModule } from './user-sign-up/user-sign-up-routing.module';

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    RouterModule,
    UserSignOutModule,
    UserRoutingModule,
    UserSignUpRoutingModule,
    GooglePlaceModule,
    OidcModule
  ],
  exports: [
    UserSignOutModule,
    OidcModule
  ],
  declarations: [
    UserPasswordChangeFormComponent
  ]
})
export class UserModule { }

