<div fxLayout="row" fxLayoutAlign="space-between center" *ngIf="bank">
  <div style="position: relative; z-index:999;" fxLayout="column" fxLayoutAlign="center center" fxFlex="5">
    <mat-icon class="mat-gray">account_balance</mat-icon>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="94" fxFlex.lt-lg="90" class="layout padding-left">
    <div fxLayout="column" fxLayoutAlign="center start" fxFlex="50">
      <div><span class="mat-subheading-2">{{bank.institutionName}}</span></div>
    </div>
    <div fxLayout="column" fxLayoutAlign="center start" fxFlex="50">
      <div [ngClass]="{'integration-status-chip' : !bank?.isLinked}" (click)="relink()">
        <ifc-status-label [status]="bankLinkStatusLabels" [value]="institutionIntegrationStatus"></ifc-status-label>
      </div>
    </div>
  </div>
</div>