import { HttpRequest, HttpResponse } from '@angular/common/http';
import { NgZone, Injectable } from '@angular/core';
import { ConfigService } from '../config/config.service';
import { CacheHeaders, CacheTypes } from './cache-headers';
import { InMemoryCachingStorageService } from './in-memory-cache.service';
import { LocalStorageCachingStorageService } from './local-storage-cache.service';

export abstract class CachingStorage {
	abstract get<T>(key: string): T | null;
	abstract put<T>(key: string, value: T): void;
    abstract delete(key: string): void;
    abstract getKeys(): string[];
	abstract get name(): string;
}

export interface CachedItem {
	body: any;
	expireAt: Date;
	url: URL
  }

@Injectable({
  providedIn: 'root'
})
export class CacheService {
	private debug = false;

	cachingStorages: CachingStorage[] = [];
	defaultTTL: number;

	constructor(
		private inMemoryCachingStorage: InMemoryCachingStorageService,
		private localStorageCachingStorage: LocalStorageCachingStorageService,
		private zone: NgZone,
		configService: ConfigService) {

		if (this.debug) console.log('Cache created.');

		const ttl = parseInt(configService.environment.cache.shortLived[CacheHeaders.TTL]);
		this.defaultTTL = Number.isInteger(ttl) ? ttl : 0;

		this.cachingStorages.push(this.inMemoryCachingStorage);
		this.cachingStorages.push(this.localStorageCachingStorage);

		this.zone.runOutsideAngular(() => {
			setInterval(() => this.clearExpired(), /* every minute*/ 60 * 1000);
		});
	}

	private clearExpired()
	{
		for (const storage of this.cachingStorages)
		{
			const keys = storage.getKeys();
			if (keys.length > 0)
				if (this.debug) console.log(`Cache ${storage.name} size: ${ keys.length}`);

			for (const key of keys) {
				const cachedItem = storage.get<CachedItem>(key);
				const expireAt = new Date(cachedItem.expireAt);
				if (expireAt.getTime() < Date.now())
				{
					storage.delete(key);
					if (this.debug) console.log(`${new Date().toISOString()} Cache ${storage.name} exp: ${key}`);
				}
			}
		}
	}

	get(req: HttpRequest<any>): HttpResponse<any> {
		const key = req.urlWithParams;
		const storage = this.getStorage(req);
		const cachedItem = storage.get<CachedItem>(key);
		if (cachedItem)
		{
			const expireAt = new Date(cachedItem.expireAt);
			if (expireAt.getTime() < Date.now())
			{
				storage.delete(key);
				if (this.debug) console.log(`${new Date().toISOString()} Cache ${storage.name} exp: ${key}`);
				return null;
			}
			if (this.debug) console.log(`${new Date().toISOString()} Cache ${storage.name} hit: ${key}`);
			return new HttpResponse({ body: cachedItem.body });
		}

        return null;
	}

	put(req: HttpRequest<any>, res: HttpResponse<any>): void {
		const key = req.urlWithParams;
		const ttl = this.timeToLive(req);
        if (ttl > 0) {
			const expireAt = new Date(Date.now() + ttl * 1000)
            const cachedItem =
			{
				body: res.body,
				expireAt: expireAt,
				url: new URL(req.urlWithParams)
			};
			const storage = this.getStorage(req);
			if (this.debug) console.log(`${new Date().toISOString()} Cache ${storage.name} put: ${key}`);
			storage.put<CachedItem>(key, cachedItem);
		}
	}

	invalidate(req: HttpRequest<any>): void {
		this.invalidateUrl(req.urlWithParams);

		let urlsToInvalidate = req.headers.getAll(CacheHeaders.Invalidate);
		if (urlsToInvalidate)
		{
			urlsToInvalidate.forEach(url => {
				this.invalidateUrl(url);
			});

		}
	}

	invalidateUrl(urlWithParams: string): void {
		const isWildcard = urlWithParams.endsWith('*');
		if (isWildcard)
			urlWithParams = urlWithParams.replace('*','')

		const key = urlWithParams;
		const url = new URL(urlWithParams);

		for (const storage of this.cachingStorages)
		{
			const cachedItem = storage.get<CachedItem>(key);
			if (cachedItem) {
				storage.delete(key);
				if (this.debug) console.log(`${new Date().toISOString()} Cache ${storage.name} del: ${key}`);
			}

			//invalidate parent paths
			const keys = storage.getKeys();
			for (const k of keys) {
				const item = storage.get<CachedItem>(k);
				const itemUrl = new URL(item.url)
				if (itemUrl.origin == url.origin &&
					(isWildcard ? itemUrl.pathname.startsWith(url.pathname) : url.pathname.startsWith(itemUrl.pathname)))
				{
					storage.delete(k);
					if (this.debug) console.log(`${new Date().toISOString()} Cache ${storage.name} del: ${k}`);
				}
			}
		}
	}

	private timeToLive(req: HttpRequest<any>): number {
		const ttl = parseInt(req.headers.get(CacheHeaders.TTL));
		return Number.isInteger(ttl) ? ttl : this.defaultTTL;
	}

	private getStorage(req: HttpRequest<any>): CachingStorage {
		return req.headers.get(CacheHeaders.CacheType) === CacheTypes.LocalStorage ? this.localStorageCachingStorage : this.inMemoryCachingStorage;
	}
}
