import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoanStatus, MessageService } from 'common';
import { CustomerData } from '../../../customer/domain/models/customer.model';
import { ProductCode, LoanData } from '../../../loan/domain/models/loan.model';

@Component({
  selector: 'ifp-account-setup-begin',
  templateUrl: './account-setup-begin.component.html',
  styleUrls: ['./account-setup-begin.component.scss']
})
export class AccountSetupBeginComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;

  data: AccountSetupBeginComponentData;
  continueEnabled = true;
  productCodes = ProductCode;
  loanStatus = LoanStatus;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService
    ) {
    sessionStorage.setItem('accountSetupViewed', JSON.stringify(true));
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.route.data
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((it: AccountSetupBeginComponentData) => this.data = it);
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  continue() {
    this.continueEnabled = false;
    this.router
      .navigate(['account', 'setup', 'verify-identity'], { replaceUrl: true })
      .catch(error => {
        this.continueEnabled = true;
        this.messageService.error(error);
      });
  }
}

export interface AccountSetupBeginComponentData {
  customer: CustomerData;
  loan: LoanData;
}
