<ifc-app-scroll-container> 
    <div class="main" [ngClass.xl]="'form-field-lg'" [ngClass.gt-xs]="'form-field-md'" [ngClass.xs]="'main-xs'">
        <mat-accordion>
          <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header>
              <mat-panel-title class="secondary-text">
                ADD PROMO CODE
              </mat-panel-title>
            </mat-expansion-panel-header>
            <form [formGroup]="form">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <mat-form-field appearance="outline">
                        <mat-label>Promo code</mat-label>
                        <input matInput 
                                autocomplete="off" 
                                placeholder="Promo code" 
                                type="text" 
                                formControlName="promoCode" 
                                maxlength="16">
                        <mat-error>Promo could not be applied. Try again</mat-error>
                    </mat-form-field>
                    <div class="mat-form-field-wrapper">
                      <button [disabled]="promotionInfo" mat-button type="button" class="layout margin margin-right-0 " (click)="applyPromoCode()">APPLY</button>
                    </div>
                </div>
            </form>
                  
            <div fxLayout="column" fxLayoutGap="24px" style="margin-left: 24px">
              <div fxLayout="row" fxLayoutAlign="center center" fxLayoutAlign="space-between" *ngIf="promotionInfo && !promoCodeInvalid">
                <div fxLayout="column">
                  <span>{{promotionInfo?.promoCode}}</span>
                  <span class="secondary-text">{{promotionInfoDetails}}</span>
                </div>
    
                <button mat-button (click)="removeAppliedProductCode()">
                  <mat-icon>close</mat-icon>
                </button>
              </div>
            
              <div *ngIf="promotionInfo" fxLayout="column" fxFlex="nogrow" class="secondary-text">
                  <span>{{brandingName}} will not be able to honor this campaign if the Borrower has</span>
                  <br />
                  <span>(1) taken additional funding from a non-bank lender,</span>                
                  <span>(2) has negative activity in the bank account, or</span>              
                  <span>(3) the owner’s credit score has fallen below {{brandingName}}’s minimum threshold</span>
              </div>
            </div>
    
          </mat-expansion-panel>
        </mat-accordion>       
    </div>
</ifc-app-scroll-container>
