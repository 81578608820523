import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, merge } from 'rxjs';
import { FinancialInstitution, IntegrationEventData, IntegrationEventType } from './bank-account.model';
import { FeatureFlagService } from 'common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AppSettings } from '../../../src/app.settings';
import { PlaidService } from './plaid.service';
import { FinicityService } from './finicity.service';
import { BankAccountLinkFailedDialogComponent } from './bank-account-link-failed-dialog/bank-account-link-failed-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { BankAccountLinkSuccessDialogComponent } from './bank-account-link-success-dialog/bank-account-link-success-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class BankingService {

  onInstitutionLinkStatusChanged: ReplaySubject<boolean>;
  obsInstitutionLinkStatus: Observable<boolean>;

  onSyncStarted: ReplaySubject<any>;
  obsSync: Observable<any>;

  private debug = true;

  private readonly baseUrl = `${this.settings.banking.url}`;

  constructor(
    private dialog: MatDialog,
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private router: Router,
    private settings: AppSettings,
    private plaidService: PlaidService,
    private finicityService: FinicityService,
    private featureFlagService: FeatureFlagService) {
    this.onInstitutionLinkStatusChanged = new ReplaySubject<any>(1);
    this.obsInstitutionLinkStatus = this.onInstitutionLinkStatusChanged.asObservable();

    this.onSyncStarted = new ReplaySubject<any>(1);
    this.obsSync = this.onSyncStarted.asObservable();

    merge(this.plaidService.obsIntegrationEvent, this.finicityService.obsIntegrationEvent)
      .subscribe(
        e => {
          if (e.type == IntegrationEventType.Pending || e.type == IntegrationEventType.Success)
            this.success(e);
          else if (e.type == IntegrationEventType.Error || e.type == IntegrationEventType.Cancel)
            this.error(e);
        }
      );
  }

  integrationReminded(entityId: number): void {
    const url = `${this.baseUrl}/api/banking/${entityId}/integration-reminded`;
    this.http.put<any>(url, null, { headers: { hideLoader: "true" } })
      .subscribe(_res => { /* intentionally left blank, without subscribe PUT observable does not work*/ });
  }

  private integrationSuccessful(entityId: number, institutionName: string, institutionId: string): void {
    this.onSyncStarted.next();
    this.openInProgressSnackBar(institutionName);
    // eslint-disable-next-line max-len
    const url = `${this.baseUrl}/api/banking/${entityId}/integration-success`;
    this.http.put<boolean>(url, null,
      {
        params:
        {
          institutionName: institutionName ?? 'a bank',
          institutionId: institutionId ?? "0"
        },
        headers:
        {
          hideLoader: "true"
        }
      })
      .subscribe(
        res => {
          this.onInstitutionLinkStatusChanged.next(res);
          if (res)
            this.openSuccessSnackBar(institutionName);
          else this.openProblemSnackBar(institutionName);
        },
        _err => {
          this.openFailedSnackBar(institutionName);
        });
  }

  private integrationInterrupted(entityId: number, institutionName: string, institutionId: string, info: string): void {
    // eslint-disable-next-line max-len
    const url = `${this.baseUrl}/api/banking/${entityId}/integration-interrupted`;
    this.http.put(url, null,
      {
        params:
        {
          bankName: institutionName ?? 'a bank',
          institutionId: institutionId ?? "0",
          info: info ?? "no info provided"
        },
        headers:
        {
          hideLoader: "true"
        }
      })
      .subscribe(_res => { /* intentionally left blank, without subscribe PUT observable does not work*/ });
  }

  private integrationInprogress(entityId: number, institutionName: string, institutionId: string, info: string): void {

    // eslint-disable-next-line max-len
    const url = `${this.baseUrl}/api/banking/${entityId}/integration-inprogress`;
    this.http.put(url, null,
      {
        params:
        {
          bankName: institutionName ?? 'a bank',
          institutionId: institutionId ?? "0",
          bankLinkStatusInfo: info
        },
        headers:
        {
          hideLoader: "true"
        }
      })
      .subscribe(_res => { /* intentionally left blank, without subscribe PUT observable does not work*/ });
  }


  private openInProgressSnackBar(institutionName: string) {
    this.snackBar.open(`We are currently syncing ${institutionName ? institutionName : 'a bank'}.`, 'Dismiss',
      { duration: 5000, panelClass: ['mat-white-bg', 'main-text', 'snackbar'] });
  }

  private openSuccessSnackBar(institutionName: string) {
    this.snackBar.open(`${institutionName ? institutionName : 'Your bank'} was successfully synced.`, 'Dismiss',
      { duration: 5000, panelClass: ['mat-white-bg', 'main-text', 'snackbar'] });
  }

  private openProblemSnackBar(institutionName: string) {
    this.snackBar.open(`We are currently syncing ${institutionName ? institutionName : 'your bank'} and will reach out if necessary.`, 'Dismiss',
      { duration: 5000, panelClass: ['mat-white-bg', 'main-text', 'snackbar'] });
  }

  private openFailedSnackBar(institutionName: string) {
    this.snackBar.open(`We were unable to sync ${institutionName ? institutionName : 'your bank'}.
     We are looking into this and will reach out if necessary.`, 'Dismiss',
      { duration: 5000, panelClass: ['mat-orange-bg', 'snackbar'] });
  }

  relink(entityId: number, institution: FinancialInstitution, redirectPath?: string, allowLinkingAlreadyLinkedInstitution?: boolean) {
    if (!entityId)
      throw new Error('entityId argument is null');
    if (!institution)
      throw new Error('institution argument is null');

    if (!allowLinkingAlreadyLinkedInstitution && institution?.isLinked)
      return;

    const bankLinkData =
    {
      institution: institution,
      entityId: entityId,
      redirectPath: redirectPath
    }
    const json = JSON.stringify(bankLinkData);
    localStorage.setItem('bank_link_data', json);

    this.featureFlagService.activated('Plaid').subscribe(plaidIsActive => {
      if (plaidIsActive)
        this.plaidService.open(entityId, institution);
      else
      {
        this.featureFlagService.activated('Finicity').subscribe(finicityIsActive => {
          if (finicityIsActive)
            this.finicityService.open(entityId, institution);
          else
            alert('No active provider is defined.');
        });
      }
    });
  }

  link(entityId: number, redirectPath?: string) {
    if (!entityId)
      throw new Error('entityId argument is null');

    const bankLinkData =
    {
      entityId: entityId,
      redirectPath: redirectPath
    }
    const json = JSON.stringify(bankLinkData);
    localStorage.setItem('bank_link_data', json);

    this.featureFlagService.activated('Plaid').subscribe(plaidIsActive => {
      if (plaidIsActive)
        this.plaidService.open(entityId);
      else
      {
        this.featureFlagService.activated('Finicity').subscribe(finicityIsActive => {
          if (finicityIsActive)
            this.router.navigate(['/bank-account-linking-search-institution']);
          else
            alert('No active provider is defined.');
        });
      }
    });
  }

  private success(e: IntegrationEventData) {
    if (this.debug) console.debug(`${new Date().toISOString()} event:${e.type}`);

    if (e.type == IntegrationEventType.Pending)
      this.integrationInprogress(e.entityId, e.institutionName, e.institutionId, e.info);
    else if (e.type == IntegrationEventType.Success) {
      this.integrationSuccessful(e.entityId, e.institutionName, e.institutionId);

      BankAccountLinkSuccessDialogComponent.show(this.dialog, e.institutionName)
        .subscribe(res => {
          if (res) // Ok
          {
            const json = localStorage.getItem('bank_link_data');
            localStorage.removeItem('bank_link_data');
            const bankLinkData = JSON.parse(json);
            if (bankLinkData?.redirectPath)
              this.router.navigate([bankLinkData?.redirectPath]);
          }
          else // Link another bank
            this.link(e.entityId);
        });
    }
  }

  private error(err: IntegrationEventData) {
    if (this.debug) console.debug(`${new Date().toISOString()} event:${err.type}`);

    this.integrationInterrupted(err.entityId, err.institutionName, err.institutionId, err.info);

    BankAccountLinkFailedDialogComponent.show(this.dialog, err.institutionName)
      .subscribe(res => {
        const json = localStorage.getItem('bank_link_data');
        localStorage.removeItem('bank_link_data');
        const bankLinkData = JSON.parse(json);
        if (res) //Try again
        {
          if (bankLinkData?.entityId && bankLinkData?.institution)
            this.relink(bankLinkData.entityId, bankLinkData.institution, bankLinkData.redirectPath);
          else
            this.link(err.entityId);
        }
        else //Link later
        {
          if (bankLinkData?.redirectPath)
            this.router.navigate([bankLinkData?.redirectPath]);
        }
      });

  }

  detectOAuthRedirect() {
    this.plaidService.detectOAuthRedirect();
  }
}
