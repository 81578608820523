import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable, forkJoin, of } from "rxjs";
import { catchError, map, switchMap, take } from "rxjs/operators";
import { LoanHelper, MessageService, RouteHelper, parseNumber } from "common";
import { CustomerFacade } from "../../customer/domain/+state/customer.facade";
import { LoanFacade } from "../../loan/domain/+state/loan.facade";
import { LoanData } from "../../loan/domain/models/loan.model";

interface SignDetails {
  signAgreementNeeded: boolean;
  isPendingForSign: boolean;
  hasRenewalToSign: boolean;
  currentLoan: LoanData
}

abstract class SignAgreementNeededGuard {
  constructor(
    protected router: Router,
    private loanFacade: LoanFacade,
    private messageService: MessageService,
    private customerFacade: CustomerFacade
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const accountId = parseNumber(RouteHelper.getParamValueByName(route, "accountId"));
    return forkJoin({
      currentLoan: this.loanFacade.getLoan$(accountId).pipe(take(1)),
      currentCustomer: this.customerFacade.getCurrentCustomer$().pipe(take(1)),
    }).pipe(
      switchMap((res) => {
        return forkJoin({
          signAgreementNeeded: this.loanFacade.signAgreementNeeded(
            res.currentLoan?.id
          ),
          isPendingForSign: of(
            LoanHelper.isLoanPendingForSign(
              res.currentLoan,
              res.currentCustomer
            )
          ),
          hasRenewalToSign: this.loanFacade.hasLoanAvailableRenewalToSign(
            res.currentLoan,
            res.currentCustomer
          ),
          currentLoan: of(res.currentLoan)
        });
      }),
      catchError((error) => {
        this.messageService.error(error);
        sessionStorage.setItem("accountSetupViewed", JSON.stringify(true));
        this.router.navigate(["home"]);
        return of([]);
      }),
      map((details: SignDetails) => this.handleSignNeeded(details))
    );
  }

  abstract handleSignNeeded(details: SignDetails);
}

@Injectable({
  providedIn: "root",
})
export class AgreementGuard extends SignAgreementNeededGuard {
  constructor(
    router: Router,
    loanFacade: LoanFacade,
    messageService: MessageService,
    customerFacade: CustomerFacade
  ) {
    super(router, loanFacade, messageService, customerFacade);
  }

  handleSignNeeded(details: SignDetails) {
    if (
      details?.signAgreementNeeded &&
      (details?.isPendingForSign || details?.hasRenewalToSign)
    )
      return true;
    else if (
      !(details?.isPendingForSign || details?.hasRenewalToSign)
    ) {
      this.router.navigate(["dashboard"]);
      return false;
    } else {
      this.router.navigate([`account/${details.currentLoan.id}/renewal/confirmation`]);
      return false;
    }
  }
}

@Injectable({
  providedIn: "root",
})
export class RenewalConfirmationGuard extends SignAgreementNeededGuard {
  constructor(
    router: Router,
    loanFacade: LoanFacade,
    messageService: MessageService,
    customerFacade: CustomerFacade
  ) {
    super(router, loanFacade, messageService, customerFacade);
  }

  handleSignNeeded(details: SignDetails) {
    if (details?.signAgreementNeeded) {
      this.router.navigate([`account/${details.currentLoan.id}/setup/sign-agreement`]);
      return false;
    } else if (!details?.hasRenewalToSign) {
      this.router.navigate(["dashboard"]);
      return false;
    } else return true;
  }
}
