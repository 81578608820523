import { Observable, ReplaySubject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CoverageData, InsuranceType, QuoteData, QuoteRequestData, QuoteResponseData } from "./insurance.model";
import * as _ from 'lodash';
import { HartfordHelper } from "./hartford.helper";
import { AppSettings } from '../../../src/app.settings';

@Injectable(
    { providedIn: 'root' }
)
export class InsuranceService {

    onQuoteChanged: ReplaySubject<QuoteData>;
    obsQuoteChange: Observable<QuoteData>;

    constructor(private http: HttpClient, private settings: AppSettings) {
        this.onQuoteChanged = new ReplaySubject<QuoteData>(1);
        this.obsQuoteChange = this.onQuoteChanged.asObservable();
    }

    getQuote(ein: string, insuranceType: InsuranceType): Observable<QuoteResponseData> {
        const sanitizedEIN = ein.replace('-', '');
        const url = `${this.settings.insurance.url}/api/quote/${sanitizedEIN}/${insuranceType}`;
        return this.http.get<QuoteResponseData>(url);
    }

    requestQuote(source: string, request: QuoteRequestData): Observable<QuoteResponseData> {
        const url = `${this.settings.insurance.url}/api/quote/${source}`;
        return this.http.post<QuoteResponseData>(url, request);
    }

    coverageFactory(quote: QuoteData): CoverageData {
        if (!quote) return undefined;
        switch (quote.providerName) {
            // For now only Hartford
            default:
                return HartfordHelper.getHartfordCoverage(quote.extra);
        }
    }
}
