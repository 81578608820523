import { Pipe, PipeTransform } from "@angular/core";
import { EnumHelper, EnumValueType } from "../../helpers/enum.helper";

@Pipe({
  name: "enumValueLabel",
})
export class EnumValueLabelPipe implements PipeTransform {
  transform<T extends EnumValueType>(
    value: T,
    enumType: any,
    labelsMap: Map<T, string>
  ): string {
    if (!enumType || !labelsMap) {
      return value as any;
    }
    return EnumHelper.getMappedNameFromValue(enumType, labelsMap, value);
  }
}
