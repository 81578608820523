import { NgModule } from "@angular/core";
import { MobileRoutingModule } from "./mobile-routing.module";
import { SharedModule } from "../shared/shared.module";
import { InitMobileComponent } from "./init-mobile/init-mobile.component";

@NgModule({
  imports: [SharedModule, MobileRoutingModule],
  declarations: [InitMobileComponent],
})
export class MobileModule {}
