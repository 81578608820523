import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import { AppLoaderService } from './app-loader.service';

@Injectable({
  providedIn: 'root'
})
export class AppLoaderInterceptor implements HttpInterceptor {

  private totalRequests = 0;
	private debug = false;

  constructor(private service: AppLoaderService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const hideLoader = req.headers.get('hideLoader') == "true";
    if (hideLoader)
      return next.handle(req);

    this.totalRequests++;
    if (this.debug) console.log(`request added, total: ${this.totalRequests}, url: ${req.urlWithParams}`);
    this.service.setLoading(true);

    return next.handle(req).pipe(
      finalize(() => {
        this.totalRequests--;
        if (this.debug) console.log(`request removed, total: ${this.totalRequests}, url: ${req.urlWithParams}`);
        if (this.totalRequests === 0) {
          this.service.setLoading(false);
        }
      })
    );
  }
}
