import { NgModule } from "@angular/core";
import { AccountBaseService, CommonModule } from "common";
import { AccountBalanceModule } from "./account-balance/account-balance.module";
import { AccountTransactionModule } from "./account-transaction/account-transaction.module";
import { AccountTransferModule } from "./account-transfer/account-transfer.module";
import { AccountPaymentModule } from "./account-payment/account-payment.module";
import { AccountSetupModule } from "./account-setup/account-setup.module";
import { SharedModule } from "../shared/shared.module";
import { AccountRoutingModule } from "./account-routing.module";
import { AccountSearchInputComponent } from "./account-search-input/account-search-input.component";
import { AccountListComponent } from "./account-list/account-list.component";
import { AccountListFilterDialogComponent } from "./account-list/account-list-filter-dialog/account-list-filter-dialog.component";
import { NewCaseFormComponent } from "../case-financing/new-case/new-case-form.component";
import { UppyAngularDashboardModule } from "@uppy/angular";
import { LoanFacade } from "../loan/domain/+state/loan.facade";
import { AccountLiquidityEventAddComponent } from "./account-liquidity-event-add/account-liquidity-event-add.component";

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    AccountBalanceModule,
    AccountTransactionModule,
    AccountTransferModule,
    AccountPaymentModule,
    AccountSetupModule,
    AccountRoutingModule,
    UppyAngularDashboardModule,
  ],
  exports: [
    AccountBalanceModule,
    AccountTransactionModule,
    AccountTransferModule,
    AccountPaymentModule,
    AccountSetupModule,
    AccountSearchInputComponent,
  ],
  declarations: [
    AccountSearchInputComponent,
    AccountListComponent,
    AccountListFilterDialogComponent,
    NewCaseFormComponent,
    AccountLiquidityEventAddComponent
  ],
  providers: [{ provide: AccountBaseService, useClass: LoanFacade }],
})
export class AccountModule {}
