<h1 mat-dialog-title>{{data?.loanTemplate?.nameTemplate | sentenceCase}}</h1>
<mat-dialog-content>
  <p class="text">
    A {{data?.loanTemplate?.nameTemplate | lowercase}} for {{(data?.loanDescription | lowercase) ?? data?.loanNumber}} has been created.
    View the {{data?.loanTemplate?.nameTemplate | lowercase}} by pressing on the file below.
  </p>
  <p class="file">
    <a [href]="data?.fileData?.url"
       [download]="data?.fileData?.filename">{{data?.fileData?.filename}}</a>
  </p>
</mat-dialog-content>

<div mat-dialog-actions
     class="flex row end-center">
  <button mat-button
          [mat-dialog-close]="undefined"
          color="primary">OK</button>
</div>
