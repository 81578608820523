export const getAccountBottomToolbarMenuItems = (accountId: number) => [
  {
    label: "TRANSACTIONS",
    url: `/account/${accountId}/transactions`,
    param: "loan.id",
    icon: "format_list_bulleted",
  },
  {
    label: "DOCUMENTS",
    url: `/account/${accountId}/documents`,
    icon: "description",
  },
  {
    label: "BANK ACCOUNTS",
    url: `/account/${accountId}/bank-account`,
    icon: "account_balance",
  },
];

export const getApplicationTopToolbarMenuItems = (appId: number) => [
  {
    label: "DOCUMENTS",
    url: `/application/${appId}/documents`,
    icon: "description",
  },
  {
    label: "BANK ACCOUNTS",
    url: `/application/${appId}/bank-account`,
    icon: "account_balance",
  },
];
