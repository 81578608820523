<nav class="productNav"
     ngClass.lt-sm="productNav-sm">
  <button *ngIf="offerBundlesSummary?.bestLocOfferBundle"
          (click)="onClickProduct(offerBundlesSummary.bestLocOfferBundle)"
          [ngClass]="{active: currentProductCode === ProductCode.LOC}">
    <mat-icon>data_saver_off</mat-icon>
    <div>
      LINE OF CREDIT
    </div>
  </button>
  <button *ngIf="offerBundlesSummary?.bestLawOfferBundle"
          (click)="onClickProduct(offerBundlesSummary.bestLawOfferBundle)"
          [ngClass]="{active: currentProductCode === ProductCode.Law}">
    <mat-icon>assessment</mat-icon>
    <div>
      CASE FINANCING
    </div>
  </button>
  <button *ngIf="offerBundlesSummary?.bestTermOfferBundle"
          (click)="onClickProduct(offerBundlesSummary.bestTermOfferBundle)"
          [ngClass]="{active: currentProductCode === ProductCode.Term}">
    <mat-icon>assessment</mat-icon>
    <div>
      TERM LOAN
    </div>
  </button>
</nav>
