import { NgModule } from '@angular/core';
import { CommonModule } from 'common';
import { AccountTransferRoutingModule } from './account-transfer-routing.module';
import { AccountTransferDrawComponent } from './account-transfer-draw/account-transfer-draw.component';
import { AccountTransferDetailsComponent } from './account-transfer-details/account-transfer-details.component';
import { SharedModule } from '../../shared/shared.module';
import { AccountTransferPaymentComponent } from './account-transfer-payment/account-transfer-payment.component';
import { CurrencyPipe, PercentPipe } from '@angular/common';
import { AccountTransferSubmittedDialogComponent } from './account-transfer-submitted-dialog/account-transfer-submitted-dialog.component';
import { AccountTransferPromoCodeComponent } from './account-transfer-promo-code/account-transfer-promo-code.component';

@NgModule({
    imports: [
        SharedModule,
        CommonModule,
        AccountTransferRoutingModule
    ],
    declarations: [
        AccountTransferDrawComponent,
        AccountTransferDetailsComponent,
        AccountTransferPaymentComponent,
        AccountTransferSubmittedDialogComponent,
        AccountTransferPromoCodeComponent
    ],
    providers: [
        CurrencyPipe,
        PercentPipe
    ]
})
export class AccountTransferModule { }
