import { NgModule } from '@angular/core';
import { CommonModule } from 'common';
import { AccountTransactionTableComponent } from './account-transaction-table/account-transaction-table.component';
import { AccountTransactionTableCardComponent } from './account-transaction-table-card/account-transaction-table-card.component';
import { AccountTransactionRoutingModule } from './account-transaction-routing.module';
import { AccountPaymentModule } from '../account-payment/account-payment.module';
import { SharedModule } from '../../shared/shared.module';
import { AccountTransactionDetailComponent } from './account-transaction-detail/account-transaction-detail.component';
import { AccountTransactionCancelDialogComponent } from './account-transaction-cancel-dialog/account-transaction-cancel-dialog.component';

@NgModule({
    imports: [
        SharedModule,
        CommonModule,
        AccountTransactionRoutingModule,
        AccountPaymentModule
    ],
    exports: [
        AccountTransactionTableCardComponent,
        AccountTransactionTableComponent
    ],
    declarations: [
        AccountTransactionTableComponent,
        AccountTransactionTableCardComponent,
        AccountTransactionDetailComponent,
        AccountTransactionCancelDialogComponent
    ]
})
export class AccountTransactionModule { }
