import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import _ from "lodash";
import {
  MfaCodeData,
  MfaData,
  MfaSessionValidityData,
} from "../const/models/mfa.model";
import { getMfaConfig } from "../const/data/mfa-data";
import { MfaContextType } from "../const/enums/mfa-context-type.enum";
import { MfaSendCodeApiData } from "../const/models/mfa-api.models";
import { MfaDataStatus } from "../const/enums/mfa-data-status.enum";
import { AppPageService } from "projects/common/src/lib/ui/app/app-page/app-page.service";
import { InitMfaData } from "../const/models/init-mfa-data.model";
import { MessageService } from "projects/common/src/core/message/message.service";
import { MaskPipe } from "ngx-mask";
import { DatePipe } from "@angular/common";
import { BrandingService } from "../../../../../core/branding/branding.service";
import { CompanyBranding } from "../../../../branding/branding.model";

@Injectable()
export class MfaService {
  private mfaDataSubject: BehaviorSubject<MfaData>;
  mfaData$: Observable<MfaData>;

  constructor(
    private readonly router: Router,
    private readonly appPageService: AppPageService,
    private readonly messageService: MessageService,
    private readonly maskPipe: MaskPipe,
    private readonly datePipe: DatePipe,
    private readonly brandingService: BrandingService,
  ) {
    this.mfaDataSubject = new BehaviorSubject(this.getMfaSessionData());
    this.mfaData$ = this.mfaDataSubject.asObservable();
  }

  initMfa(data: InitMfaData) {
    const mfaData = this.prepareMfaData(data);
    this.saveMfaData(mfaData);
    this.router.navigate(["mfa"], { replaceUrl: true });
  }

  getMfaSessionData(): MfaData {
    return this.checkMfaSessionDataValidity().mfaData;
  }

  getMfaValidityData(): MfaSessionValidityData {
    return this.checkMfaSessionDataValidity();
  }

  // TODO[mfa] add adapter to data service
  updateMfaCodeData(mfaCodeApiData: MfaSendCodeApiData) {
    this.mfaDataSubject.next({
      ...this.mfaDataSubject.value,
      mfaCodeData: this.adaptMfaCodeData(mfaCodeApiData),
    });
  }

  handleDefaultCloseAction() {
    const redirectUrl = this.mfaDataSubject?.value?.errorRedirectUrl;
    if (redirectUrl) {
      this.router.navigate([redirectUrl]);
    } else {
      this.appPageService.back();
    }
    this.clearMfaData();
  }

  handleMfaError(logMessage?: string): void {
    this.brandingService.getBranding().subscribe((branding: CompanyBranding) => {
      this.handleDefaultCloseAction();

      if (logMessage) {
        console.error(logMessage);
      }

      this.messageService.error(
        `Something went wrong. Please contact our support team at
        ${this.maskPipe.transform(branding.phone, "(000) 000-0000")}`
      );
    })
  }

  handleMfaSuccess(): void {
    this.clearMfaData();
  }

  clearMfaData(): void {
    sessionStorage.removeItem("mfa");
    this.mfaDataSubject.next(null);
  }

  warningSendingNewCode(timeleft: number) {
    const leftTimeText = this.datePipe.transform(timeleft * 1000, "mm:ss");
    this.messageService.warning(
      `Too many requests for a new code were made.
      Wait ${leftTimeText} before sending another code request.`,
      "Just a moment"
    );
  }

  private prepareMfaData(data: InitMfaData): MfaData {
    if (!data?.mfaResponse) {
      return null;
    }

    const { mfaResponse } = data;

    return {
      contextType: data.mfaContextType,
      errorRedirectUrl: this.prepareErrorRedirectUrl(data.mfaContextType),
      phoneNumber: mfaResponse.phone_number,
      phoneStatus: mfaResponse.phone_status,
      mfaToken: mfaResponse.mfa_Token,
      config: getMfaConfig(data.config),
      mfaCodeData: this.adaptMfaCodeData(mfaResponse.send_code_status),
      contextData: data.contextData,
    };
  }

  private prepareErrorRedirectUrl(contextType: MfaContextType) {
    switch (contextType) {
      case MfaContextType.Login:
        return "/sign-in";
      case MfaContextType.ChangePhoneNumber:
      case MfaContextType.ResetPassword:
      default:
        return "";
    }
  }

  private adaptMfaCodeData(apiData: MfaSendCodeApiData): MfaCodeData {
    return {
      codeGenerationIntervalSeconds: apiData?.code_generation_interval,
      codeLength: apiData?.code_length,
      codeSent: apiData?.code_sent,
      error: apiData?.error_result?.error,
    };
  }

  private saveMfaData(data: MfaData) {
    const sessionData = this.adaptToMfaSessionData(data);
    sessionStorage.setItem("mfa", JSON.stringify(sessionData));
    this.mfaDataSubject.next(data);
  }

  private adaptToMfaSessionData(summary: MfaData): MfaData {
    const copy: MfaData = _.cloneDeep(summary);
    copy.mfaCodeData = {
      codeLength: summary.mfaCodeData?.codeLength,
      codeGenerationIntervalSeconds:
        summary.mfaCodeData?.codeGenerationIntervalSeconds,
    };
    return copy;
  }

  private checkMfaSessionDataValidity(): MfaSessionValidityData {
    const mfaRawData = sessionStorage.getItem("mfa");
    if (!mfaRawData) {
      return { status: MfaDataStatus.Empty };
    }
    try {
      const mfaData = JSON.parse(mfaRawData);
      if (!mfaData) {
        return { status: MfaDataStatus.Empty };
      }
      return { status: MfaDataStatus.Valid, mfaData };
    } catch (e) {
      console.error(`Invalid mfa data in session. ${e}. ${mfaRawData}`);
      return { status: MfaDataStatus.Invalid };
    }
  }
}
