<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 74 21.65" class="ifc-ssl-logo">
	<defs>
		<style>
			.cls-1{font-size:14.06px;fill:#231f20;font-family:Roboto-Bold, Roboto;font-weight:700;}
			.cls-2{font-size:6.36px;font-family:Roboto-Medium, Roboto;}
			.cls-2,.cls-5{fill:#bcbabf;}
			.cls-3{letter-spacing:-0.02em;}
			.cls-4{fill:none;}
			.cls-6{fill:#80af41;}
			.cls-7{fill:#8dbe3f;}
			.cls-8{fill:#fff;}
		</style>
	</defs>
	<title>ssl_secure</title>
	<text class="cls-1" transform="translate(21.58 12.67)">SECURE</text>
	<text class="cls-2" transform="translate(21.69 19.76)">SSL ENC
		<tspan class="cls-3" x="24.96" y="0">R</tspan>
		<tspan x="28.78" y="0">YPTION</tspan>
	</text>
	<path class="cls-4" d="M12.57,2.15H6a.53.53,0,0,0-.52.52V7.45h7.66V2.67A.53.53,0,0,0,12.57,2.15Z"/>
	<path class="cls-5" d="M5.43,7.45V2.67A.53.53,0,0,1,6,2.15h6.62a.53.53,0,0,1,.52.52V7.45h1.28a3.2,3.2,0,0,1,.63.07V2.67A2.43,2.43,0,0,0,12.57.24H6A2.43,2.43,0,0,0,3.52,2.67V7.51a2.6,2.6,0,0,1,.56-.06Z"/>
	<path class="cls-6" d="M15,7.52a3.2,3.2,0,0,0-.63-.07H9.19v6.71L12,11.4l1.16,1.15L9.19,16.47v5h5.18a3,3,0,0,0,3-3v-8A3,3,0,0,0,15,7.52Z"/>
	<path class="cls-7" d="M8.17,17.5,5.4,14.74l1.16-1.16,1.61,1.6,1-1V7.45H4.08a2.6,2.6,0,0,0-.56.06,3,3,0,0,0-2.41,2.91v8a3,3,0,0,0,3,3H9.19v-5Z"/>
	<polygon class="cls-8" points="8.16 15.18 6.56 13.58 5.41 14.74 8.16 17.5 9.19 16.47 13.11 12.55 11.95 11.4 9.19 14.16 8.16 15.18"/>
	<rect class="cls-8" x="18.02" y="0.06" width="8.33" height="2.3"/>
</svg>