import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {  ProductCode } from '../domain/models/loan.model';
import { MatTableHelper, EnumHelper, LoanStatus, FilterParameter, AccountFilters, AccountQueryParams } from 'common';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class LoanFilterService {

  filter(filters: AccountFilters, paginator?: MatPaginator, sort?: MatSort) {
    const query = new AccountQueryParams(filters);
    query.loanNumber = typeof query.loanNumber === 'object' ? filters.loanNumber.loanNumber : query.loanNumber;
    query.description = typeof query.description === 'object' ? filters.description?.description : query.description;
    query.favorite = typeof query.favorite === 'object' ? filters.favorite : query.favorite;
    query.externalId = typeof query.externalId === 'object' ? filters.externalId.externalId : query.externalId;
    query.productCode = typeof query.productCode === 'object' ? filters.productCode.productCode : query.productCode;
    query.excludedProductCode = typeof query.excludedProductCode === 'object' ? filters.excludedProductCode.excludedProductCode : query.excludedProductCode;
    // Set default to first page
    query.skip = undefined;

    query.createdOnFrom = filters.createdOnFrom ? moment(filters.createdOnFrom).format('L') : undefined;
    query.createdOnTo = filters.createdOnTo ? moment(filters.createdOnTo).format('L') : undefined;

    if (paginator) {
      query.limit = paginator.pageSize;
      query.skip = paginator.pageIndex * paginator.pageSize;
    }

    if (sort?.active) {
      query.sort = MatTableHelper.getSort(query.sort, sort);
    }


    return query;
  }

  addFilterParameters(query: AccountQueryParams, constProductCode: ProductCode, constExcludedProductCode: ProductCode): FilterParameter[] {
    // Refresh filters chip list
    const filters: FilterParameter[] = [];

    if (query.loanNumber) {
      filters.push({
        id: 'loanNumber', name: 'Loan number', value: query.loanNumber.trim()
      });
    }
    if (query.favorite) {
      filters.push({
        id: 'favorite', name: 'Favorite', value: query.favorite
      });
    }
    if (query.externalId) {
      filters.push({
        id: 'externalId', name: 'Case number', value: query.externalId.trim()
      });
    }
    if (query.description) {
      filters.push({
        id: 'description', name: 'Case name', value: query.description.trim()
      });
    }
    if (query.createdOnFrom) {
      filters.push({
        id: 'createdOn', name: 'Date created on',
        value: `${moment(query.createdOnFrom).format('L')} - ${moment(query.createdOnTo).format('L')}`
      });
    }
    if (query.productCode && !constProductCode) {
      filters.push({
        id: 'productCode', name: 'Product type', value: EnumHelper.getNameFromValue(ProductCode, query.productCode)
      });
    }
    if (query.excludedProductCode && !constExcludedProductCode) {
      filters.push({
        id: 'excludedProductCode', name: 'Excluded product type', value: EnumHelper.getNameFromValue(ProductCode, query.excludedProductCode)
      });
    }
    if (query.status && query.status >= 0) {
      filters.push({
        id: 'status', name: 'Status', value: _.startCase(EnumHelper.getNameFromValue(LoanStatus, +query.status))
      });
    }
    return filters;
  }
}
