import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { LoanRemote } from "../../infrastructure/loan.remote";
import { LoanStateService } from "./loan.state.service";
import { map, switchMap, take, tap } from "rxjs/operators";
import { AccountHintData, AccountHintParams, AccountQueryParams, CaseResolutionDialogData, DateHelper, LiquidityEvent, ResolutionStatus } from "common";
import {
  LoanData,
  LoanDataSummary,
  LoanQueryResult,
  LoanWithRenewalData,
} from "../models/loan.model";
import { LoanActionsFacade } from "./loan-actions.facade";
import { TransactionPreviewResponse } from "../../../account/account-transfer/account-transfer.model";
import { CustomerData } from "../../../customer/domain/models/customer.model";
import { CustomerFacade } from "../../../customer/domain/+state/customer.facade";
import { LiquidityEventRemote } from "../../../liquidity-event/infrastructure/liquidity-event.remote";

@Injectable({
  providedIn: "root",
})
export class LoanFacade {
  
  constructor(
    private readonly stateService: LoanStateService,
    private readonly remote: LoanRemote,
    private readonly remoteLitigation: LiquidityEventRemote,
    private readonly loanActionsFacade: LoanActionsFacade,
    private readonly customerFacade: CustomerFacade
  ) {}

  clearLoanCache(): void {
    this.stateService.clearState();
  }

  getLoan$(id: number): Observable<LoanData> {
    if (this.stateService.isLoanStateLoaded(id)) {
      return this.stateService.getLoan$();
    }

    return this.updateLoan(id).pipe(
      switchMap(() => this.stateService.getLoan$())
    );
  }

  private updateLoan(id: number): Observable<LoanData> {
    return this.getRequest(id).pipe(
      map((loan: LoanData) => {
        this.stateService.setLoan(loan);
        return loan;
      })
    );
  }

  refreshLoan(id: number): Observable<LoanData> {
    return this.updateLoan(id);
  }

  private getRequest(id: number) {
    return this.remote.get(id);
  }

  getFirstAvailable(id?: number): Observable<LoanData> {
    if (id) {
      return this.getLoan$(id).pipe(take(1));
    }

    return this.customerFacade.getCurrentCustomer$().pipe(
      take(1),
      switchMap((customer) => {
        if (customer?.pendingLoans?.length > 0) {
          return this.getRequest(customer.pendingLoans[0]);
        }
        return this.loanActionsFacade.getAllLoans().pipe(map((results) => results?.values?.[0]));
      })
    );
  }

  getWithRenewal(loanId: number): Observable<LoanWithRenewalData> {
    return this.remote.getWithRenewal(loanId);
  }

  getWithRenewalUrl(id: number): string {
    return this.remote.getWithRenewalUrl(id);
  }

  getByEntity(
    entityId: number,
    params?: AccountQueryParams
  ): Observable<LoanQueryResult> {
    return this.loanActionsFacade.getByEntity(entityId, params);
  }

  getSummaryByEntity(
    entityId: number,
    params?: AccountQueryParams
  ): Observable<LoanDataSummary> {
    return this.loanActionsFacade.getSummaryByEntity(entityId, params);
  }

  query(params: AccountQueryParams): Observable<LoanQueryResult> {
    return this.remote.query(params);
  }

  querySummary(params: AccountQueryParams): Observable<LoanDataSummary> {
    return this.loanActionsFacade.querySummary(params);
  }

  draw(id: number, amount: string, appliedPromoCode: string, note: string): Observable<void> {
    return this.remote
      .draw(id, amount, appliedPromoCode, note)
      .pipe(tap(() => {
        this.updateLoan(id);
      }));
  }

  pay(id: number, amount: string, appliedPromoCode: string): Observable<void> {
    return this.remote
      .pay(id, amount, appliedPromoCode)
      .pipe(tap(() => {
        this.updateLoan(id); 
      }));
  }

  drawPreview(
    id: number,
    amount: number,
    appliedPromoCode: string
  ): Observable<TransactionPreviewResponse> {
    return this.remote.drawPreview(id, amount, appliedPromoCode);
  }

  payPreview(
    id: number,
    amount: number,
    appliedPromoCode: string
  ): Observable<TransactionPreviewResponse> {
    return this.remote.payPreview(id, amount, appliedPromoCode);
  }

  queryHints(query: AccountHintParams): Observable<AccountHintData[]> {
    return this.remote.queryHints(query);
  }

  loanSorter(l1: LoanData, l2: LoanData, customer: CustomerData): number {
    return this.loanActionsFacade.loanSorter(l1, l2, customer);
  }

  hasLoanAvailableRenewalToSign(
    loan: LoanData,
    customer: CustomerData
  ): Observable<boolean> {
    return this.loanActionsFacade.hasLoanAvailableRenewalToSign(loan, customer);
  }

  download(params: AccountQueryParams) {
    return this.remote.download(params);
  }

  updateFavorite(
    customerId: number,
    loanId: number,
    favorite: boolean
  ): Observable<any> {
    return this.remote.updateFavorite(customerId, loanId, favorite);
  }

  signAgreementNeeded(loanId: number): Observable<boolean> {
    return this.remote.signAgreementNeeded(loanId);
  }

  getAgreementReadyLoans(
    entityId: number,
    customerId: number
  ): Observable<LoanData> {
    return this.remote.getAgreementReadyLoans(entityId, customerId);
  }

  updateDescription(data: string, id: number): Observable<any> {
    return this.remote
      .updateDescription(data, id)
      .pipe(switchMap(() => this.updateLoan(id)));
  }

  updatePayoffNotes(data: string, litigationId: number, loanId: number): Observable<any> {
    return this.remoteLitigation
      .updatePayoffNotes(data, litigationId, loanId)
      .pipe(tap(() => this.updateLiquidityEvent(loanId).subscribe()));
  }
  
  getCasePaymentDueDetails(loanId: number): Observable<CaseResolutionDialogData> {
    return this.remote
      .getCasePaymentDueDetails(loanId);
  }

  getLiquidityEventForLoan(loanId: number): Observable<LiquidityEvent> {
    if (this.stateService.isLiquidityEventLoaded(loanId)) {
      return this.stateService.getLiquidityEvent$();
    }

    return this.updateLiquidityEvent(loanId);
  }

  private updateLiquidityEvent(loanId: number): Observable<LiquidityEvent> {
    return this.remoteLitigation.getLiquidityEventForLoan(loanId).pipe(
      switchMap((liquidityEvent: LiquidityEvent) => {
        this.stateService.setLiquidityEvent(loanId, liquidityEvent);
        return this.stateService.getLiquidityEvent$();
      })
    );
  }

  saveLiquidityEvent(litigationId: number, loanId: number, resolutionStatus: ResolutionStatus,
    resolutionDate: Date, note: string, notesConsent: boolean) : Observable<LiquidityEvent> {
    
    return this.remoteLitigation.saveLiquidityEvent(litigationId, loanId,
      resolutionStatus, DateHelper.getLocalDateAsUTC(resolutionDate), note, notesConsent).pipe(
        switchMap(_ => this.updateLiquidityEvent(loanId))
      );
  }
}
