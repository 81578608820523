import { Injectable } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import {
  CloseMfaData,
  MfaActionsService,
  MfaContextType,
  MfaService,
  VerifyMfaCodeData,
} from "common";
import { MfaLoginService } from "./services/mfa-login.service";
import { MfaResetPasswordService } from "./services/mfa-reset-password.service";
import { MfaChangePhoneNumberService } from "./services/mfa-change-phone-number.service";

@UntilDestroy()
@Injectable()
export class MfaFacade {
  constructor(
    private mfaActions: MfaActionsService,
    private mfaService: MfaService,
    private mfaLoginService: MfaLoginService,
    private mfaResetPasswordService: MfaResetPasswordService,
    private mfaChangePhoneNumberService: MfaChangePhoneNumberService
  ) {}

  initActions(): void {
    this.mfaActions.verifyMfaCode$
      .pipe(untilDestroyed(this))
      .subscribe((data: VerifyMfaCodeData) => {
        this.verifyMfaCode(data);
      });

    this.mfaActions.close$
      .pipe(untilDestroyed(this))
      .subscribe((data: CloseMfaData) => {
        this.close(data);
      });
  }

  private verifyMfaCode(data: VerifyMfaCodeData) {
    switch (data.mfaData.contextType) {
      case MfaContextType.Login:
        return this.mfaLoginService.verify(data);
      case MfaContextType.ChangePhoneNumber:
        return this.mfaChangePhoneNumberService.verify(data);
      case MfaContextType.ResetPassword:
        return this.mfaResetPasswordService.verify(data);
      default:
        this.mfaService.handleMfaError("Invalid mfaContextType to verify");
        break;
    }
  }

  private close(data: CloseMfaData) {
    switch (data.contextType) {
      case MfaContextType.ChangePhoneNumber:
        return this.mfaChangePhoneNumberService.close(data);
      case MfaContextType.Login:
      case MfaContextType.ResetPassword:
      default:
        this.mfaService.handleDefaultCloseAction();
        break;
    }
  }
}
