import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { CloseMfaData } from "../const/models/close-mfa-data.model";
import { VerifyMfaCodeData } from "../const/models/verify-mfa-code-data.model";

@Injectable()
export class MfaActionsInternalService {
  private invalidCodeSubject: Subject<void>;
  invalidCode$: Observable<void>;

  private closeSubject: Subject<CloseMfaData>;
  close$: Observable<CloseMfaData>;

  private verifyMfaCodeSubject: Subject<VerifyMfaCodeData>;
  verifyMfaCode$: Observable<VerifyMfaCodeData>;

  constructor() {
    this.invalidCodeSubject = new Subject();
    this.invalidCode$ = this.invalidCodeSubject.asObservable();

    this.closeSubject = new Subject();
    this.close$ = this.closeSubject.asObservable();

    this.verifyMfaCodeSubject = new Subject();
    this.verifyMfaCode$ = this.verifyMfaCodeSubject.asObservable();
  }

  invalidCode(): void {
    this.invalidCodeSubject.next();
  }

  close(data: CloseMfaData): void {
    this.closeSubject.next(data);
  }

  verifyMfaCode(data: VerifyMfaCodeData): void {
    this.verifyMfaCodeSubject.next(data);
  }
}
