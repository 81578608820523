<div class="container flex row stretch-center end-end-xs end-end-m"
     *ngIf="waitingForConfirmation">
  <p class="text">
    <mat-icon class="text__icon">error</mat-icon>
    <span class="app-bar-reminder">Your new number has not been verified. We will continue to use your old number {{oldPhoneNumber | hiddenPhone}} to verify
      your account.</span>
  </p>

  <div>
    <button mat-button
            (click)="confirmPhoneNumber()">VERIFY NEW NUMBER</button>
    <button mat-button
            (click)="discardPhoneNumber()">DISCARD CHANGES</button>
  </div>
</div>
