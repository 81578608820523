import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { LiquidityEvent, StateService } from "common";
import { LoanState } from "../models/loan-state.model";
import { LoanData } from "../models/loan.model";

const initialState: LoanState = null;

@Injectable({
  providedIn: "root",
})
export class LoanStateService extends StateService<LoanState> {
  constructor() {
    super(initialState);
  }

  getLoan$(): Observable<LoanData> {
    return this.select((state: LoanState) => state?.loan);
  }

  setLoan(loan: LoanData): void {
    const newState = {
      ...this.state,
      loan,
    };
    this.setState(newState);
  }

  isLoanStateLoaded(id: number): boolean {
    return this.state?.loan?.id === id;
  }

  getLiquidityEvent$(): Observable<LiquidityEvent> {
    return this.select((state: LoanState) => state?.liquidityEvent[state?.loan?.id]);
  }

  setLiquidityEvent(loanId: number, liquidityEvent: LiquidityEvent): void {
    const newState = {
      ...this.state,
      liquidityEvent: { [loanId]: liquidityEvent },
    };
    this.setState(newState);
  }

  isLiquidityEventLoaded(loanId: number): boolean {
    return this.state?.liquidityEvent ? !!this.state?.liquidityEvent[loanId] : false;
  }

  clearState(): void {
    this.initState(initialState);
  }
}
