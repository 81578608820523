
export type EnumValueType = string | number;

export class EnumHelper {

    static getNamesAndValues<T extends EnumValueType>(e: any): EnumValue[] {
        return this.getNames(e).map(_name => ({ name: _name, value: e[_name] as T }));
    }

    static getNamesAndStringValues(e: any): { name: string, value: string }[] {
        return this.getNamesAndValues(e).map(option => ({ name: option.name, value: option.value.toString() }));
    }

    static getMappedNamesAndValues<T extends EnumValueType>(e: any, map: Map<T, string>): EnumValue[] {
        return this.getValues(e).map(v => ({ name: map.get(v as T), value: v as T }));
    }

    static getMappedNamesAndValuesFromValues<T extends EnumValueType>(e: any, map: Map<T, string>, values: T[]): EnumValue[] {
      return this.getValues(e).reduce((acc, enumValue) => {
        if (values?.some(value => value === enumValue)) {
          return [...acc, { name: map.get(enumValue as T), value: enumValue as T }];
        }
        return acc;
      }, []);
    }

    static getMappedNamesFromValues<T extends EnumValueType>(e: any, map: Map<T, string>, values: T[]): string[] {
      return this.getValues(e).reduce((acc, enumValue) => {
        if (values?.some(value => value === enumValue)) {
          return [...acc, map.get(enumValue as T)];
        }
        return acc;
      }, []);
    }

    static getNames(e: any): string[] {
        return Object.keys(e).filter(key => isNaN(+key));
    }

    static getValues<T extends EnumValueType>(e: any): T[] {
        const values = Object.values(e);
        if (values.some(value => typeof value === "number")) {
          return values.filter(value => typeof value === "number") as T[];
        }
        return values as T[];
    }

    static getNameFromValue<T extends EnumValueType>(e: any, value: T): string | null {
        const all = this.getNamesAndValues(e).filter(pair => pair.value == value);
        return all.length === 1 ? all[0].name : null;
    }

    static getMappedNameFromValue<T extends EnumValueType>(e: any, map: Map<T, string>, value: T): string | null {
        const all = this.getNamesAndValues(e).filter(pair => pair.value === value);
        return all.length === 1 ? map.get(all[0].value as T) : null;
    }

    static getValueFromName<T extends EnumValueType>(e: any, name: any): any | null {
        const all = this.getNamesAndValues(e).filter(pair => pair.name === name);
        return all.length === 1 ? all[0].value : null;
    }
}

export interface EnumValue
{
   name: string, 
   value: EnumValueType
}