export interface MessageData {
    id?: number;
    date?: Date;
    subject?: string;
    body?: string;
    extra?: string;
    disclaimer?: string;
    importance?: number;
    icon?: string;
    buttons?: MessageButton[];
    dismissable?: boolean;
    loanId?: number;
    isEntityMessage?: boolean;
}

export interface MessageButton {
    label: string;
    link: string;
    appearance: ButtonAppearance;
}

export enum ButtonAppearance {
    normal = 'normal',
    emphasized = 'emphasized'
}


export enum MessageSubjects {
    ReferTwitter = 'Refer Twitter',
    UpdatedTermsAndConditions = 'Updated terms & conditions',
    EarnUpTo10000 = 'Earn up to $10,000',
    GeneralLiabilityInsurance = 'General Liability Insurance'
}
