<div *ngIf="!data || !data.appFooter || !data.appFooter.topHidden" fxLayout="row" fxLayoutAlign="start center" fxLayout.lt-md="column" fxLayoutAlign.lt-md="center center">
  <div *ngIf="!data.appFooter.hideText" class="flex layout padding footer-text">
    <span [innerHTML]="branding?.portalTopFooterText"></span>
  </div>
  <div *ngIf="data.appFooter.showEncryptionInfo" class="layout padding security-box">
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="12px">
      <div class="security-icon-wrapper" fxLayoutAlign="center center">
        <mat-icon>lock</mat-icon>
      </div>
      <p class="footer-text"><span class="secondary-text">We use advanced encryption to keep your information safe. Learn more </span><a class="pointer" (click)="openDialog()">here</a>.</p>
    </div>
  </div>
  <div class="flex fill"></div>
  <div #trustpilotWrapper class="layout margin margin-left-0 margin-right-0"></div>
  <div class="layout margin flex row">
    <ifc-ssl-logo></ifc-ssl-logo>
  </div>
</div>
<div *ngIf="data && data.appFooter && data.appFooter.full" class="container flex row column-m start-center">
  <div class="copyrights">
    <span [innerHTML]="branding?.portalBottomFooterText"></span>
  </div>
  <div class="space flex fill"></div>
  <div class="links flex row center-center links">
    <a [href]="branding?.tosUrl" target="_blank" class="layout margin-right white-space-nowrap">Terms
      of service</a>
    <a [href]="branding?.privacyUrl" target="_blank" class="layout white-space-nowrap">Privacy
      policy</a>
  </div>
</div>
