import { UntypedFormGroup, UntypedFormControl } from "@angular/forms";

export class FormHelper {

    static showInvalidFormFields(formGroup: UntypedFormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof UntypedFormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof UntypedFormGroup) {
                this.showInvalidFormFields(control);
            }
        });
    }
}
