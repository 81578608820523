<div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="8px">
  <div *ngIf="loan?.status === loanStatus.Open">
    <span class="title">${{loan?.loanInfo?.availableFunds | shortNumber}}</span>
    <span class="mat-title secondary-text"> Available</span>
  </div>
  <mat-progress-bar *ngIf="loan?.status === loanStatus.Open" mode="determinate" value="{{utilization}}">
  </mat-progress-bar>
  <ifp-account-status-icon-chip
    *ngIf="loan?.status !== loanStatus.Open && !hasAvailableRenewalToSign && (drawEnabled || payEnabled || loan?.status === loanStatus.PendingRenewal)"
    [status]="loan?.status"
    [accountStatusIconChipModel]="accountStatusIconChipModel">
  </ifp-account-status-icon-chip>
  <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="8px">
    <button mat-button color="primary" (click)="onClickDraw()" [disabled]="!drawEnabled"
      *ngIf="!hasAvailableRenewalToSign && drawEnabled" [fxHide.lt-sm]="showAvailableFunds"
      [fxHide.lt-md]="widgetLayout" name="draw">
      <span>DRAW</span>
    </button>
    <button mat-button color="primary" (click)="onClickPayment()" *ngIf="payEnabled"
      [fxHide.lt-sm]="showAvailableFunds" [fxHide.lt-md]="widgetLayout">
      <span>PAY</span>
    </button>
  </div>
</div>
