import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InsuranceQuoteRoutingModule } from './insurance-routing.module';
import { SharedModule } from '../shared/shared.module';
import { InsuranceReminderComponent } from './reminder/insurance-reminder.component';
import { InsuranceErrorComponent } from './error/insurance-error.component';
import { InsuranceDeclinedComponent } from './declined/insurance-declined.component';
import { MoreInfoDialogComponent } from './more-info-dialog/more-info-dialog.component';
import { InsuranceQuoteMessageComponent } from './quote/insurance-quote-message.component';
import { InsuranceQuoteReminderComponent } from './quote/insurance-quote-reminder.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        InsuranceQuoteRoutingModule
    ],
    declarations: [
        InsuranceQuoteMessageComponent,
        InsuranceQuoteReminderComponent,
        InsuranceReminderComponent,
        InsuranceErrorComponent,
        InsuranceDeclinedComponent,
        MoreInfoDialogComponent
    ]
})
export class InsuranceQuoteModule { }
