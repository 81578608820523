import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

import { AppSettings } from "../../app.settings";
import { ApplicationOfferBundleData, ConfigService, OfferBundleStatus } from "common";

@Injectable({
  providedIn: "root",
})
export class OfferBundleService {
  private readonly baseUrl = `${this.settings.applications.url}/api/offer-bundle`;

  constructor(
    private http: HttpClient,
    private settings: AppSettings,
    private configService: ConfigService
  ) {}

  getOfferBundlesByAppId(
    applicationId: number
  ): Observable<ApplicationOfferBundleData[]> {
    return this.http.get<ApplicationOfferBundleData[]>(
      `${this.baseUrl}/application/${applicationId}`
    );
  }

  getOfferBundle(
    applicationId: number,
    offerBundleId: number,
    useCache: boolean = true
  ): Observable<ApplicationOfferBundleData> {
    return this.http.get<ApplicationOfferBundleData>(
      `${this.baseUrl}/application/${applicationId}/${offerBundleId}`,
      {
        headers: this.configService.environment.cache.noCache,
      }
    );
  }

  acceptOfferBundle(offerBundleId: number): Observable<ApplicationOfferBundleData> {
    return this.http.patch<ApplicationOfferBundleData>(
      `${this.baseUrl}/${offerBundleId}/status`,
      {
        status: OfferBundleStatus.Accepted
      }
    );
  }
}
