import { Injectable } from "@angular/core";
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";
import { AccountTransfer } from "./account-transfer.model";
import { EnumHelper, RouteHelper, TransactionType, parseNumber } from "common";
import { ProductName } from "../../loan/domain/models/loan.model";
import { LoanFacade } from "../../loan/domain/+state/loan.facade";

@Injectable({
  providedIn: "root",
})
export class AccountTransferPaymentResolver
  implements Resolve<AccountTransfer>
{
  constructor(private loanFacade: LoanFacade) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<AccountTransfer> {
    const accountId = parseNumber(RouteHelper.getParamValueByName(route, "accountId"));
    return this.loanFacade.getLoan$(accountId).pipe(
      take(1),
      map((loan) => {
        const transfer: AccountTransfer = {
          type: TransactionType.Payment,
          amountInput: "",
          amountMaximum: loan.loanInfo?.currentPayoffAmount,
          amountMinimum: loan.loanInfo?.minPaymentAmount,
          weeklyPaymentAmount: loan.loanInfo?.paymentAmount,
          from:
            loan.loanInfo?.paymentBankName && loan.loanInfo?.paymentBankAccount
              ? `${loan.loanInfo?.paymentBankName} ${loan.loanInfo?.paymentBankAccount}`
              : "Your bank account on file",
          to: `${EnumHelper.getNameFromValue(ProductName, loan.productCode)}: ${
            loan.loanNumber
          }`,
          balanceAfter: {
            availableBalance: loan.loanInfo?.availableFunds,
            outstandingBalance: loan.loanInfo?.principalBalance,
            upcomingPaymentAmount: loan.loanInfo?.upcomingPayment,
            upcomingPaymentDate: loan.loanInfo?.paymentDueDate
              ? new Date(loan.loanInfo?.paymentDueDate)
              : null,
          },
          loanInfo: loan.loanInfo,
          loanOffer: loan.offer,
          prepaymentHidden: loan.productCode === "Term",
          drawHidden: loan.productCode === "Term",
        };
        transfer.submit = () => {
          return this.loanFacade.pay(
            loan.id,
            transfer.amountInput,
            transfer.promotionInfo?.promoCode
          );
        };
        return transfer;
      })
    );
  }
}
