import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ErrorPageComponent } from "./components/error-page/error-page.component";
import { PageNotFoundComponent } from "./components/page-not-found/page-not-found.component";
import { CustomErrorPageComponent } from "./components/custom-error-page/custom-error-page.component";

const routes: Routes = [
  {
    path: "error",
    children: [
      { path: "", component: ErrorPageComponent },
      { path: "404", component: PageNotFoundComponent },
      { path: "custom", component: CustomErrorPageComponent },
    ],
  },
  { path: "**", component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ErrorsPagesRoutingModule {}
