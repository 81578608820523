import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
  InitMfaData,
  MessageService,
  MfaApiResponse,
  MfaContextData,
  MfaContextType,
  MfaErrorCodeType,
  MfaErrorType,
  MfaService,
} from "common";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AppSettings } from "../../../app.settings";
import {
  MfaTokenPayload,
  PasswordResetRequest,
  PasswordResetResponse,
} from "./user-password-reset-form/user-password-reset-form.model";

@Injectable({
  providedIn: "root",
})
export class UserPasswordResetService {
  constructor(
    private http: HttpClient,
    private settings: AppSettings,
    private mfaService: MfaService,
    private messageService: MessageService,
    private router: Router
  ) {}

  private serviceUrl = this.settings.oauth.url;

  submit(
    request: PasswordResetRequest,
    tokenPayload: MfaTokenPayload = undefined
  ): Observable<PasswordResetResponse> {
    return this.submitBase(request, tokenPayload).pipe(
      map(() => {
        this.handleSuccess();
        return { hasError: false };
      }),
      catchError((error) => this.handleError(error, request))
    );
  }

  handleSuccess() {
    this.router.navigate(["account/reset-done"]);
  }

  handleError(
    response: any,
    request: PasswordResetRequest
  ): Observable<PasswordResetResponse> {
    if (response.status === 403 && this.hasMfaError(response?.error?.error)) {
      return of(this.handleMfaErrors(response?.error, request));
    }
    if (response?.error) {
      this.messageService.error(response?.error);
    }
    return of({
      hasError: true,
      message: response?.error?.error,
    });
  }

  private hasMfaError(errorType: any) {
    return Object.values(MfaErrorType).includes(errorType);
  }

  private handleMfaErrors(
    mfaResponse: MfaApiResponse,
    request: PasswordResetRequest
  ): PasswordResetResponse {
    switch (mfaResponse.error) {
      case MfaErrorType.MfaRequired:
        return this.handleMfaRequiredError(mfaResponse, request);
      case MfaErrorType.MfaCodeError:
        return this.handleMfaCodeError(mfaResponse);
      default:
        console.error(`Mfa error: ${mfaResponse.error}`);
        return {
          hasError: true,
          message: "Error occurred while trying to set password.",
        };
    }
  }

  private handleMfaRequiredError(
    mfaResponse: MfaApiResponse,
    request: PasswordResetRequest
  ): PasswordResetResponse {
    const contextData: MfaContextData = {
      password: request.password,
      confirmPassword: request.confirmPassword,
      token: request.token,
    };

    const data: InitMfaData = {
      mfaContextType: MfaContextType.ResetPassword,
      mfaResponse,
      contextData,
    };

    this.mfaService.initMfa(data);
    return { hasError: true };
  }

  private handleMfaCodeError(
    mfaResponse: MfaApiResponse
  ): PasswordResetResponse {
    if (
      mfaResponse?.send_code_status?.error_result?.error ===
      MfaErrorCodeType.InvalidMfaCode
    ) {
      return {
        hasError: true,
        message: mfaResponse?.send_code_status?.error_result?.error,
      };
    }
  }

  private submitBase(
    request: PasswordResetRequest,
    token: MfaTokenPayload
  ): Observable<string> {
    let headers = {};
    if (token?.mfaToken) {
      headers = { ...headers, "X-Ryno-Mfa-Token": token?.mfaToken };
    }
    if (token?.mfaCode) {
      headers = { ...headers, "X-Ryno-Mfa-Code": token.mfaCode };
    }
    return this.http.post<string>(
      `${this.serviceUrl}/api/password/reset`,
      request,
      { headers }
    );
  }

  checkToken(token: string): any {
    return this.http.post(`${this.serviceUrl}/api/password/check/${token}`, {});
  }
}
