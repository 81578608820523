import { Injectable } from '@angular/core';
import { ConfigService } from '../../config/config.service';
import { timer, Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import jwtDecode, { JwtPayload } from "jwt-decode";
import { delay } from 'rxjs/operators';

declare const gapi: any;

@Injectable({
  providedIn: 'root'
})
export class OAuthService {

  constructor(
    protected configService: ConfigService,
    protected router: Router,
    protected http: HttpClient) {
  }

  static oauthSessionTimeout: any;

  get accessToken(): string {
    return sessionStorage.token;
  }

  transformRequest(obj) {
    const str = [];
    for (const p in obj)
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    return str.join("&");
  }

  refreshAuthToken(): Observable<any> {
    if (!sessionStorage.refreshToken) {
      this.sessionEndLocal();
      return of(null);
    }
    const body = this.transformRequest({
      grant_type: "refresh_token",
      client_id: sessionStorage.loginType,
      refresh_token: sessionStorage.refreshToken
    });
    return this.http.post(`${this.configService.environment.oauth.url}/token`, body, {
      headers: { "Content-Type": "application/x-www-form-urlencoded" }
    });
  }

  get authenticated(): boolean {
    const tokenExpiresAt = sessionStorage.tokenExpiresAt
      ? new Date(sessionStorage.tokenExpiresAt)
      : undefined;
    const tokenExpired = tokenExpiresAt
      && tokenExpiresAt.getTime() < new Date().getTime();

    return this.accessToken && tokenExpiresAt && !tokenExpired;
  }

  sessionBegin(loginType: string, accessToken: string, expiresIn: number, refreshToken: string, refresh_token_expires_in?: number, clientId?: string) {
    const now = new Date();

    sessionStorage.loginType = loginType;
    sessionStorage.token = accessToken;
    sessionStorage.tokenSetAt = now;
    sessionStorage.tokenExpiresAt = new Date(now.getTime() + expiresIn * 1000);
    sessionStorage.refreshToken = refreshToken;

    if(clientId) {
      sessionStorage.clientId = clientId;
    }

    if(refresh_token_expires_in) {
      sessionStorage.refreshTokenExpiresAt = new Date(now.getTime() + refresh_token_expires_in * 1000);
    }

    this.restartIdleTimer();
  }

  sessionEndLocal(): void {
    this.sessionTimeoutClear();
    sessionStorage.removeItem('loginType');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('tokenSetAt');
    sessionStorage.removeItem('tokenExpiresAt');
    sessionStorage.removeItem('refreshToken');
    sessionStorage.removeItem('refreshTokenExpiresAt');
    sessionStorage.removeItem('accountSetupViewed');
    sessionStorage.removeItem('relinkAccountViewed');
    sessionStorage.removeItem('referralPageViewed');
    sessionStorage.removeItem('insurancePageViewed');
    sessionStorage.removeItem('bankActionRequiredViewed');
    sessionStorage.removeItem('renewalEligibilityPageViewed');
    sessionStorage.removeItem('campaign');
    sessionStorage.removeItem('applicationAgreementViewed');
    sessionStorage.removeItem('navigationHistory')
  }

  protected sessionTimeoutClear() {
    if (OAuthService.oauthSessionTimeout && OAuthService.oauthSessionTimeout.unsubscribe) {
      OAuthService.oauthSessionTimeout.unsubscribe();
    }

    OAuthService.oauthSessionTimeout = null;
  }
  public restartIdleTimer(): void {
    this.sessionTimeoutClear();

    if (!sessionStorage.tokenExpiresAt || !sessionStorage.refreshToken) return;

    const refreshToken = jwtDecode<JwtPayload>(sessionStorage.refreshToken);

    // Expiration Date - Not Before ( we have no Issued At claim, but this is fine as well )
    const signOutDueTimeInMs = refreshToken.exp * 1000 - new Date(sessionStorage.tokenExpiresAt).getTime();
    // console.log("Restarting the idle timer, will log out in: " + signOutDueTimeInMs + " ms.");

    // RD: It but looks like timer does not support huge numbers, so it emits immediately. See https://github.com/ReactiveX/rxjs/issues/3015
    OAuthService.oauthSessionTimeout = timer().pipe(delay(signOutDueTimeInMs)).subscribe(() => {
      this.sessionEndLocal();
      this.router.navigate(["sign-out"], { replaceUrl: true });
    });
  }

  public saveNavigationHistory() {
    if (window.location.pathname !== "/mfa") {
      let params = new URLSearchParams(window.location.search);
      params.delete("sessionId");
      sessionStorage.setItem("navigation.history", window.location.pathname);
      sessionStorage.setItem(
        "navigation.historyQuery",
        `${window.location.pathname}?${params.toString()}`
      );
    }
  }
}
