import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { BrandingService, CompanyBranding } from 'common';
import { Observable } from 'rxjs';
import { AppSettings } from '../../../../src/app.settings';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'ifp-bank-account-link-failed-dialog',
  templateUrl: './bank-account-link-failed-dialog.component.html',
  styleUrls: ['./bank-account-link-failed-dialog.component.css']
})
export class BankAccountLinkFailedDialogComponent implements OnInit {
  contactPhone: string;
  contactEmail: string;

  constructor(
    public dialogRef: MatDialogRef<BankAccountLinkFailedDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public settings: AppSettings,
    private brandingService: BrandingService
  ) { }

  ngOnInit(): void {
    this.initBrandingInfo();
  }

  private initBrandingInfo(): void {
    this.brandingService.getBranding$()
      .pipe(untilDestroyed(this))
      .subscribe((branding: CompanyBranding) => {
        this.contactPhone = branding?.phone ?? this.settings.if.contact.phone;
        this.contactEmail = branding?.infoEmail ?? 'info@ideafinancial.com';
      });
  }

  static show(dialog: MatDialog, institutionName: string): Observable<any> {
    return dialog.open(BankAccountLinkFailedDialogComponent, {
      width: '500px',
      data: {
        institutionName: institutionName
      },
      closeOnNavigation: true,
    }).afterClosed();
  }
}
