import { Observable} from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppSettings } from "projects/portal/src/app.settings";
import { ConfirmNewPhoneNumberRequest, CustomerContactData, CustomerContactDataDetails, CustomerData } from "../domain/models/customer.model";

@Injectable({ providedIn: "root" })
export class CustomerRemote {
  constructor(
    private http: HttpClient,
    private settings: AppSettings,
  ) {}

  getCurrent(): Observable<CustomerData> {
    const url = `${this.settings.oauth.url}/api/accounts/current`;
    return this.http.get<CustomerData>(url);
  }

  getPersonalInformation(
    customerId: number
  ): Observable<CustomerContactDataDetails> {
    const url = `${this.settings.customers.url}/api/customer/${customerId}/personal-information`;
    return this.http.get<CustomerContactDataDetails>(url);
  }

  updatePersonalInformation(
    customerId: number,
    contactData: CustomerContactData
  ): Observable<any> {
    const url = `${this.settings.customers.url}/api/customer/${customerId}/personal-information`;
    return this.http.patch(url, contactData);
  }

  confirmMobilePhoneNumberChange(
    request: ConfirmNewPhoneNumberRequest
  ): Observable<any> {
    let headers = {};
    if (request?.mfaToken) {
      headers = { ...headers, "X-Ryno-Mfa-Token": request?.mfaToken };
    }
    if (request?.mfaCode) {
      headers = { ...headers, "X-Ryno-Mfa-Code": request.mfaCode };
    }
    const url = `${this.settings.customers.url}/api/customer/${request.customerId}/confirm-new-phone-number`;
    return this.http.patch(url, null, { headers });
  }

  discardMobilePhoneNumberChange(customerId: number): Observable<any> {
    const url = `${this.settings.customers.url}/api/customer/${customerId}/discard-new-phone-number`;
    return this.http.patch(url, {});
  }
}
