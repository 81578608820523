import { NgModule } from "@angular/core";
import { UiModule } from "../ui/ui.module";
import { AccountApplicationNumberInputComponent } from "./account-application-number-input/account-application-number-input.component";
import { AccountBusinessNameInputComponent } from "./account-business-name-input/account-business-name-input.component";
import { AccountCaseNameInputComponent } from "./account-case-name-input/account-case-name-input.component";
import { AccountCaseNumberInputComponent } from "./account-case-number-input/account-case-number-input.component";
import { AccountLoanNumberInputComponent } from "./account-loan-number-input/account-loan-number-input.component";

@NgModule({
    imports: [
        UiModule
    ],
    exports: [
        AccountApplicationNumberInputComponent,
        AccountBusinessNameInputComponent,
        AccountCaseNameInputComponent,
        AccountLoanNumberInputComponent,
        AccountCaseNumberInputComponent
    ],
    declarations: [
        AccountApplicationNumberInputComponent,
        AccountBusinessNameInputComponent,
        AccountCaseNameInputComponent,
        AccountLoanNumberInputComponent,
        AccountCaseNumberInputComponent
    ]
  })
  export class AccountModule { }