import { NgModule } from '@angular/core';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardContentComponent } from './dashboard-content/dashboard-content.component';
import { AccountTransactionModule } from '../account/account-transaction/account-transaction.module';
import { AccountModule } from '../account/account.module';
import { AccountBalanceModule } from '../account/account-balance/account-balance.module';
import { UserModule } from '../user/user.module';
import { DashboardAccountWidgetComponent } from './account-widget/account-widget.component';
import { SharedModule } from '../shared/shared.module';
import { DashboardOfferWidgetComponent } from './offer-widget/dashboard-offer-widget.component';
import { BankAccountModule } from '../bank-account/bank-account.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { DashboardApplicationWidgetComponent } from './application-widget/application-widget.component';
import { DashboardAccountActionButtonsComponent } from './account-action-buttons/account-action-buttons.component';
import { DashboardBankWidgetComponent } from './bank-widget/bank-widget.component';
import { RecentActivityWidgetComponent } from './recent-activity-widget/recent-activity-widget.component';
import { RecentDocumentsWidgetComponent } from './recent-documents-widget/recent-documents-widget.component';
import { PhoneNumberConfirmationReminderComponent } from './phone-number-confirmation-reminder/phone-number-confirmation-reminder.component';
import { MfaModule } from 'common';

@NgModule({
  imports: [
    MatExpansionModule,
    SharedModule,
    AccountModule,
    AccountBalanceModule,
    AccountTransactionModule,
    UserModule,
    DashboardRoutingModule,
    BankAccountModule,
    MfaModule
  ],
  declarations: [
    DashboardContentComponent,
    DashboardAccountWidgetComponent,
    DashboardOfferWidgetComponent,
    DashboardApplicationWidgetComponent,
    DashboardAccountActionButtonsComponent,
    DashboardBankWidgetComponent,
    RecentActivityWidgetComponent,
    RecentDocumentsWidgetComponent,
    PhoneNumberConfirmationReminderComponent
  ]
})
export class DashboardModule { }
