import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppBarCloseActionInternalService } from "./app-bar-close-action.internal.service";

@Injectable({
  providedIn: "root",
})
export class AppBarCloseActionService {
  constructor(private internalService: AppBarCloseActionInternalService) {}

  get closing$(): Observable<void> {
    return this.internalService.closing$;
  }
}
