  <div id="main" fxLayout="column" fxLayoutAlign="center center">

    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="32px">

      <div class="header" fxLayout="column" fxLayoutAlign="center center">
        <img src="../../assets/dollar-rhino-background.png">
        <span ngClass.gt-sm="title" ngClass.lt-sm="title xs">
          Awesome <span class="color-primary">business owners</span> are a rare breed.
        </span>
        <span ngClass.gt-sm="title" ngClass.lt-sm="title xs">
          Refer businesses and get up to $10,000!
        </span>
      </div>

      <div class="content" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center start" fxLayoutGap="32px"
        fxLayoutGap.lt-sm="8px">

        <div fxLayout.lt-sm="row" fxLayoutAlign.lt-sm="center center" fxLayoutGap.lt-sm="12px">
          <span fxHide.gt-xs class="avatar small bg-primary">1</span>
          <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap.gt-sm="12px" ngClass.gt-sm="widget"
            fxLayoutAlign.lt-sm="start start">
            <span fxHide.lt-sm class="badge left bg-primary">1</span>
            <img fxHide.lt-sm src="../../assets/customer-stories-01.png" width="60px">
            <span class="mat-body-2">Refer</span>
            <span class="description">Share your unique link with friends</span>
          </div>
        </div>

        <div fxLayout.lt-sm="row" fxLayoutAlign.lt-sm="center center" fxLayoutGap.lt-sm="12px">
          <span fxHide.gt-xs class="avatar small bg-primary">2</span>
          <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap.gt-sm="12px" ngClass.gt-sm="widget"
            fxLayoutAlign.lt-sm="start start">
            <span fxHide.lt-sm class="badge left bg-primary">2</span>
            <img fxHide.lt-sm src="../../assets/get-funded-01.png" width="60px">
            <span class="mat-body-2">Get paid</span>
            <span class="description">{{referSubject}}</span>
          </div>
        </div>

        <div fxLayout.lt-sm="row" fxLayoutAlign.lt-sm="center center" fxLayoutGap.lt-sm="12px">
          <span fxHide.gt-xs class="avatar small bg-primary">3</span>
          <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap.gt-sm="12px" ngClass.gt-sm="widget"
            fxLayoutAlign.lt-sm="start start">
            <span fxHide.lt-sm class="badge left bg-primary">3</span>
            <img fxHide.lt-sm src="../../assets/line-of-credit-01.png" width="60px">
            <span class="mat-body-2">Repeat</span>
            <span class="description">You earn $1,000 per funded referral</span>
          </div>
        </div>

      </div>

      <div ngClass.gt-sm="layout margin-top" fxLayout="row" fxLayoutAlign="stretch" fxLayout.lt-md="column"
        fxLayoutAlign.lt-md="center center" fxLayoutGap="12px">
        <div class="card layout padding" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="8px">
          <span class="mat-subheading-1 secondary-text">Type your friend's emails</span>
          <ifp-referral-code-email [customerId]="customerId"></ifp-referral-code-email>
        </div>
        <div class="card layout padding" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="8px">
          <span class="mat-subheading-1 secondary-text">Share your unique link with everyone</span>
          <ifp-referral-code-social-media></ifp-referral-code-social-media>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayout.lt-sm="column"
        fxLayoutAlign.lt-sm="center center" fxLayoutGap.lt-sm="12px" fxLayoutGap="0px"
        class="full-width layout padding-right padding-left">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px" *ngIf="notificationData?.dismissable || notificationData?.dismissable === undefined">
          <mat-checkbox color="primary" (change)="dismiss($event)"></mat-checkbox>
          <span class="mat-caption">Don't remind me again</span>
        </div>
        <button mat-button routerLink="/dashboard">REFER LATER</button>
      </div>

      <div>
        <a class="terms pointer" href="https://www.ideafinancial.com/referral-terms-and-conditions" target="_blank">
          See full terms and conditions</a>
      </div>

    </div>

  </div>
