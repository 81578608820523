import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Component({
  selector: 'ifp-account-transaction-cancel-dialog',
  templateUrl: './account-transaction-cancel-dialog.component.html',
  styleUrls: ['./account-transaction-cancel-dialog.component.scss']
})
export class AccountTransactionCancelDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<AccountTransactionCancelDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  static show(dialog: MatDialog): Observable<any> {
    return dialog
      .open(AccountTransactionCancelDialogComponent, {
        closeOnNavigation: true,
      })
      .afterClosed();
  }
}
