import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SslLogoComponent } from '../svgs/ssl-logo/ssl-logo.component';
import { GoogleLogoComponent } from './google-logo/google-logo.component';

@NgModule({
    imports: [
      CommonModule,
      RouterModule
    ],
    declarations: [
      SslLogoComponent,
      GoogleLogoComponent
    ],
    exports: [
      SslLogoComponent,
      GoogleLogoComponent
    ]
  })
  export class SvgsModule { }