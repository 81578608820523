export enum ResolutionStatus {
    'Settlement' = 'settlement',
    'Trial win' = 'trial-win',
    'Trial loss' = 'trial-loss',
    'Summary judgement' = 'summary-judgement',
    'Motion to dismiss' = 'motion-to-dismiss',
    'Lawyer-client relationship terminated' = 'lawyer-client-relationship-terminated'
  }

export interface LiquidityEvent {
    litigationId: number;
    resolutionDate?: Date;
    resolutionStatus?: ResolutionStatus;
    payoffNotes: string;
    isPayoffNotesConsent: boolean;  
}