export class DomHelper {

    static copy(value: string) {
        const element = document.createElement('textarea');
        element.style.position = 'fixed';
        element.style.left = '0';
        element.style.top = '0';
        element.style.opacity = '0';
        element.value = value;
        document.body.appendChild(element);
        element.focus();
        element.select();
        document.execCommand('copy');
        document.body.removeChild(element);
    }

}
