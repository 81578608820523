import { Injectable } from '@angular/core';
import { CacheTypes } from './cache-headers';
import { CachingStorage } from './cache.service';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageCachingStorageService implements CachingStorage {

  prefix: string = 'E29CD455-5E53-4A0C-957D-DCFB97EB6C93_';

  get name() : string
  {
    return CacheTypes.LocalStorage;
  }

  get<T>(key: string): T {
    const json = localStorage.getItem(`${this.prefix}${key}`);
    return json ? JSON.parse(json) : null;
  }
  
  put<T>(key: string, value: T): void {
    localStorage.setItem(`${this.prefix}${key}`, JSON.stringify(value));
  }

  delete(key: string): void {
    localStorage.removeItem(`${this.prefix}${key}`);
  }

  getKeys(): string[] {
    const keys = Object.keys(localStorage)
      .filter(k => k.startsWith(this.prefix))
      .map(k => k.replace(this.prefix, ''));
    return keys;
  }

  clear(): void {
    const keys = this.getKeys();
    for (const key of keys) {
      this.delete(key);
    }
  }
}
