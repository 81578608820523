import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { UserPasswordChangeService } from './user-password-change.service';
import { AppBarTitleService } from 'common';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription, Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomerData } from '../../customer/domain/models/customer.model';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ifp-user-password-change-form',
  templateUrl: './user-password-change-form.component.html',
  styleUrls: ['./user-password-change-form.component.scss']
})
export class UserPasswordChangeFormComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;

  data: UserPasswordChangeFormComponentData;
  submitSub: Subscription;

  constructor(
    private route: ActivatedRoute,
    private userPasswordChangeService: UserPasswordChangeService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    public snackBar: MatSnackBar,
    private appBarTitleService: AppBarTitleService) {
    this._unsubscribeAll = new Subject();
  }

  form = this.formBuilder.group({
    currentPassword: ['', Validators.compose([Validators.required])],
    newPassword: ['', Validators.compose([Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/)])],
    confirmPassword: ['', Validators.required]
  }, { validator: formPasswordConfirmationValidator('newPassword', 'confirmPassword') });

  ngOnInit() {
    this.route.data.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.dataInit.bind(this));

    this.appBarTitleService.title = 'Update your password';
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  dataInit(data: UserPasswordChangeFormComponentData) {
    this.data = data;
  }

  submit() {
    if (!this.form.valid) return;
    this.submitSub = this.userPasswordChangeService
      .submit(this.form.value, this.data.customer.id)
      .subscribe(this.submitSuccess, this.submitError);
  }

  submitSubClear() {
    this.submitSub.unsubscribe();
    this.submitSub = null;
  }

  submitError = response => {
    if (response.status === 401)
      this.snackBar.open('The password provided was incorrect. Double check this info and try again.', 'Error',
        { duration: 2000, panelClass: ['mat-red-bg'] });
    else {
      const message = response.error ? response.error.error_description : 'Error ocurred while trying to change password';
      this.snackBar.open(message, 'Error', { duration: 2000, panelClass: ['mat-red-bg'] });
    }
    this.submitSubClear();
  }

  submitSuccess = () => {
    this.snackBar.open("Your password has been changed.", "OK", { duration: 2000, panelClass: ['mat-green-bg'] });
    this.router.navigate(["dashboard"]);
  }
}

function formPasswordConfirmationValidator(controlName: string, matchingControlName: string) {
  return (formGroup: UntypedFormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors) {
      return;
    }

    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}

export interface UserPasswordChangeFormComponentData {
  customer: CustomerData;
}
