import { parseNumber } from "../helpers/parse.helper";
import { QueryParams, QueryResult } from "../query/query.data";

export interface DocumentBase {
  id: number;
  uploadDate: Date;
  filename: string;
  length: number;
  customerId: number;
  category: DocumentCategory;
  mimeType: string;
  name: string;
  description: string;
  uploadedBy: string;
  canDelete: boolean;
}

export interface Document extends DocumentBase {
  loanId: number;
}

export enum DocumentCategory {
  Other = "other",
  Contract = "contract",
  Application = "application",
  ApplicationSupporting = "application-supporting",
  CustomAgreement = "custom-agreement",
  InsurancePolicy = "insurance-policy",
  BankStatement = "bank-statement",
  TaxReturn = "tax-return",
  Disclosure = "disclosure",
  SettlementStatement = "settlement-statement",
  Statement = "statement"
}

export const DocumentCategoryLabel = new Map<DocumentCategory, string>([
  [DocumentCategory.Other, "Other"],
  [DocumentCategory.Contract, "Contract"],
  [DocumentCategory.Application, "Application"],
  [DocumentCategory.ApplicationSupporting, "Application supporting"],
  [DocumentCategory.CustomAgreement, "Custom agreement"],
  [DocumentCategory.InsurancePolicy, "Insurance policy"],
  [DocumentCategory.BankStatement, "Bank Statement"],
  [DocumentCategory.TaxReturn, "Tax return"],
  [DocumentCategory.Disclosure, "Disclosure"],
  [DocumentCategory.SettlementStatement, "Settlement statement"],
  [DocumentCategory.Statement, "Statement"],
]);

export class DocumentQueryParams extends QueryParams {
  text?: string;
  fromDate?: any;
  toDate?: any;
  category?: any;

  constructor(params?: any) {
    super(params);
    params = params || {};

    this.text = params.text || undefined;
    this.fromDate = params.fromDate || undefined;
    this.toDate = params.toDate || undefined;
    this.category = params.category || undefined;
  }
}

export class LoanDocumentQueryParams extends DocumentQueryParams {
  loanId?: number;
  loanUniqueId?: string;

  constructor(params?: any) {
    super(params);
    params = params || {};

    this.loanId = parseNumber(params.loanId) || undefined;
    this.loanUniqueId = params.loanUniqueId || undefined;
  }
}

export interface DocumentQueryResult extends QueryResult<Document> {}

export class DocumentFilters {
  text: string;
  fromDate: Date;
  toDate: Date;
  category: DocumentCategory;

  constructor(filters?) {
    filters = filters || {};
    this.text = filters.text || undefined;
    this.fromDate = filters.fromDate || undefined;
    this.toDate = filters.toDate || undefined;
    this.category = filters.category !== null ? filters.category : undefined;
  }

  remove(item: string) {
    this[item] = undefined;
    if (item === "fromDate") {
      this.fromDate = undefined;
      this.toDate = undefined;
    }
  }
}
