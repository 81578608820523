import { Component, Input, OnInit } from "@angular/core";
import { PersonalCompanyFormType } from "../model/personal-company-form-type";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ActivatedRoute } from "@angular/router";

@UntilDestroy()
@Component({
  selector: "ifp-contact-data-details",
  templateUrl: "./contact-data-details.component.html",
  styleUrls: ["./contact-data-details.component.scss"],
})
export class ContactDataDetailsComponent implements OnInit {
  @Input()
  personalCompanyFormType: PersonalCompanyFormType;
  get PersonalCompanyFormType() {
    return PersonalCompanyFormType;
  }

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.initData();
  }

  private initData(): void {
    this.route.data.pipe(untilDestroyed(this)).subscribe((res) => {
      this.personalCompanyFormType = res.personalCompanyFormType;
    });
  }
}
