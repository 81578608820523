import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatDialog } from "@angular/material/dialog";
import { AppBarTitleService, DocumentFilters, DocumentQueryParams, EntityDocumentsService, FilterParameter } from "common";
import { QueryParamsService } from "projects/common/src/lib/query/query-params.service";
import { EntityDocumentsListData } from "./models/entity-documents-list-data.model";
import { EntityDocumentsFilterService } from "../../entity-documents-filter.service";
import { DocumentListFilterDialogComponent } from "../../../document/document-list-filter-dialog/document-list-filter-dialog.component";

@UntilDestroy()
@Component({
  selector: "ifp-entity-documents-list",
  templateUrl: "./entity-documents-list.component.html",
  styleUrls: ["./entity-documents-list.component.scss"],
})
export class EntityDocumentsListComponent implements OnInit {
  tableColumns: string[] = ["createdOn", "name", "category", "download"];

  filters: DocumentFilters;
  activeFilters: FilterParameter[] = [];

  data: EntityDocumentsListData;
  queryParams: DocumentQueryParams;

  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;

  @ViewChild(MatSort)
  sort: MatSort;

  constructor(
    private appBarTitleService: AppBarTitleService,
    private route: ActivatedRoute,
    private queryParamsService: QueryParamsService,
    private entityDocumentsFilterService: EntityDocumentsFilterService,
    private entityDocumentsService: EntityDocumentsService,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.route.data
      .pipe(untilDestroyed(this))
      .subscribe((data: EntityDocumentsListData) => {
        this.data = data;
        this.appBarTitleService.title = `Documents - ${data?.entity?.name}`;
      });
    this.route.queryParams
      .pipe(untilDestroyed(this))
      .subscribe((params) => {
        this.queryParams = new DocumentQueryParams(this.queryParamsService.init(params));
        this.activeFilters = this.entityDocumentsFilterService.addFilterParameters(
          this.queryParams
        );
      });

    this.filters = new DocumentFilters(this.queryParams);
    this.paginator.pageSize = this.queryParams.limit;
    this.paginator.pageIndex = this.queryParams.skip / this.queryParams.limit;
  }

  activeFilterRemoved(activefilters: FilterParameter[]): void {
    if (!activefilters.length) {
      return;
    }

    activefilters.forEach((item) => {
      this.filters.remove(item.id);
      this.entityDocumentsFilterService.filter(this.filters);
    });
  }

  filter(): void {
    this.entityDocumentsFilterService.filter(
      this.filters,
      this.paginator,
      this.sort
    );
  }

  openFilterDialog(): void {
    DocumentListFilterDialogComponent.show(this.dialog, new DocumentFilters(this.queryParams), this.data.entityDocumentsQueryResult.values)
    .subscribe(result => {
      if (result) {
        this.filters = result;
        this.paginator.firstPage();
        this.filter();
      }
    });
  }

  onClickDownload(fileId: number) {
    this.entityDocumentsService.download(fileId);
  }
}
