import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "../shared/shared.module";
import { RenewalEligibleReminderComponent } from "./reminder/renewal-eligible-reminder.component";
import { RenewalEligibleRoutingModule } from "./renewal-eligible-routing.module";
import { RenewalEligibleDocumentsComponent } from "./documents/renewal-eligible-documents.component";
import { RenewalInfoDialogComponent } from "./renewal-info-dialog/renewal-info-dialog.component";
import { UppyAngularDashboardModule } from "@uppy/angular";
import { RenewalConfirmationComponent } from "./renewal-confirmation/renewal-confirmation.component";

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RenewalEligibleRoutingModule,
    UppyAngularDashboardModule,
  ],
  declarations: [
    RenewalEligibleReminderComponent,
    RenewalEligibleDocumentsComponent,
    RenewalInfoDialogComponent,
    RenewalConfirmationComponent
  ],
})
export class RenewalEligibleModule {}
