import { Injectable } from "@angular/core";
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import {
  QueryResult,
  Document,
  LoanDocumentService,
  RouteHelper,
  parseNumber,
  LoanDocumentQueryParams,
} from "common";
import { Observable } from "rxjs";
import { switchMap, take } from "rxjs/operators";
import { QueryParamsService } from "projects/common/src/lib/query/query-params.service";
import { LoanFacade } from "../loan/domain/+state/loan.facade";

@Injectable({
  providedIn: "root",
})
export class LoanDocumentQueryResolver
  implements Resolve<QueryResult<Document>>
{
  constructor(
    private documentService: LoanDocumentService,
    private loanFacade: LoanFacade,
    private queryParamsService: QueryParamsService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<QueryResult<Document>>
    | Promise<QueryResult<Document>>
    | QueryResult<Document> {
    const accountId = parseNumber(RouteHelper.getParamValueByName(route, "accountId"));
    return this.loanFacade.getLoan$(accountId).pipe(
      take(1),
      switchMap((loan) => {
        return this.documentService.query(
          loan.id,
          new LoanDocumentQueryParams(this.queryParamsService.init(route.queryParams))
        );
      })
    );
  }
}
