import { Component, EventEmitter, Input, Output } from "@angular/core";
import {
  ApplicationCheckoutRequirement,
  CheckoutRequirementStatus,
} from "projects/common/src/lib/application/application.model";

@Component({
  selector: "ifc-offer-calculator-closing-requirements",
  templateUrl: "./offer-calculator-closing-requirements.component.html",
  styleUrls: ["./offer-calculator-closing-requirements.component.scss"],
})
export class OfferCalculatorClosingRequirementsComponent {
  @Input()
  checkoutRequirements: ApplicationCheckoutRequirement[];

  @Input()
  isOfferBundleSelected: boolean;

  @Output()
  documentsClick: EventEmitter<void> = new EventEmitter();

  get checkoutRequirementStatus() {
    return CheckoutRequirementStatus;
  }

  filterFunction(req: ApplicationCheckoutRequirement) {
    return (
      req.status === CheckoutRequirementStatus.Required ||
      req.status === CheckoutRequirementStatus.Approved
    );
  }

  onClickDocuments() {
    this.documentsClick.emit();
  }
}
