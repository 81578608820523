<mat-form-field appearance="outline">
  <mat-label>{{label}}</mat-label>
  <input [id]="labelId + '-form-field'"
         [formControl]="fc"
         matInput
         [matDatepicker]="picker"
         [min]="_min"
         [max]="_max"
         (blur)="onBlur()"
         [dateMask]="maskConfig"
         #dateInput>
  <button type="button"
          *ngIf="fc.value && !disabled && !clearDisabled"
          mat-button
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="valueClear()">
    <mat-icon>close</mat-icon>
  </button>
  <mat-datepicker-toggle matSuffix
                         [for]="picker"
                         (closed)="onClose()"></mat-datepicker-toggle>
  <mat-datepicker touchUi
                  #picker
                  (closed)="onClose()"></mat-datepicker>
  <mat-error [id]="labelId + '-required-error-msg'"
             *ngIf="fc?.errors | hasControlError:['required']">
    {{label}} is required.
  </mat-error>
  <mat-error [id]="labelId + '-min-length-error-msg'"
             *ngIf="fc?.errors | hasControlError:['matDatepickerMin']">
    {{label}} needs to be later or equal {{fc?.errors?.matDatepickerMin?.min | date:'M/d/yyyy'}}
  </mat-error>
  <mat-error [id]="labelId + '-max-length-error-msg'"
             *ngIf="fc?.errors | hasControlError:['matDatepickerMax']">
    {{label}} needs to be earlier or equal {{fc?.errors?.matDatepickerMax?.max | date:'M/d/yyyy'}}
  </mat-error>
</mat-form-field>
