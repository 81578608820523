import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AccountTransaction, AccountTransactionQueryResult, ConfigService, ResourceService } from 'common';
import { AppSettings } from '../../../app.settings';

@Injectable({
  providedIn: 'root'
})
export class AccountTransactionService {

  private _pageSize = 20;
  private serviceUrl = this.appsettings.transaction.url;

  constructor(
    private http: HttpClient,
    private resourceService: ResourceService,
    private appsettings: AppSettings,
    private configService: ConfigService
    ) { }

  get(id: number): Observable<AccountTransaction> {
    return this.http.get<AccountTransaction>(`${this.serviceUrl}/api/transactions/${id}`);
  }

  getUnprocessedByLoan(loanId: number): Observable<AccountTransaction[]> {
    return this.http.get<AccountTransaction[]>(`${this.serviceUrl}/api/transactions/loan/${loanId}/unprocessed-transactions`, {
      headers: this.configService.environment.cache.shortLived
    });
  }

  getTransactions(loanId: number, pageNumber: any, pageLimit: any, sortBy: any): Observable<AccountTransactionQueryResult> {
    const skip = pageNumber * pageLimit;
    const data = {
      skip: skip,
      limit: pageLimit,
      sort: sortBy
    };
    return this.http.post<AccountTransactionQueryResult>(`${this.serviceUrl}/api/transactions/loan/${loanId}`, data);
  }

  get pageSize(): number {
    return this._pageSize;
  }

  set pageSize(value: number) {
    this._pageSize = value;
  }

  cancelTransaction(transactionId: number) {
    return this.http.post(`${this.serviceUrl}/api/transactions/${transactionId}/cancel`, null);
  }

  downloadCsv(loanId: number) {
    this.resourceService.download(`${this.serviceUrl}/api/transactions/csv/portal?loanId=${loanId}`, "transactions.csv");
  }
}
