<ng-container [ngSwitch]="actionStatus">

  <ng-container *ngSwitchCase="actionStatusEnum.Select">
    <button mat-button
            *ngIf="this.isOfferSelectable"
            class="select-button"
            (click)="onClickSelectOffer()">
      Select this offer
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="actionStatusEnum.Selecting">
    <button mat-button
            disabled
            class="select-button">
      <span class="loader-1"></span>
      Please wait...
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="actionStatusEnum.Selected">
    <button mat-button
            class="select-button select-button--selected">
      <mat-icon class="select-button__icon">check_circle</mat-icon>
      Selected
    </button>
  </ng-container>
</ng-container>
