import { Component, Input } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { AccountBaseService } from '../account-base-service';
import { AutocompleteComponent } from '../../ui/autocomplete/autocomplete.component';
import { AccountHintData } from '../account.model';
import { ProductCode } from '../../product/product.model';

@Component({
  selector: 'ifc-account-case-name-input',
  templateUrl: '../../ui/autocomplete/autocomplete.component.html',
  styleUrls: ['../../ui/autocomplete/autocomplete.component.css']
})
export class AccountCaseNameInputComponent extends AutocompleteComponent {

  @Input()
  productCode: ProductCode;

  @Input()
  excludedProductCode: ProductCode;

  constructor(private accountService: AccountBaseService) {
    super();
  }

  label = 'Case name';

  optionFilter: (input: string | AccountHintData) => Observable<any[]> =
    input => input
      ? this.accountService.queryHints({
        description: !!input ? (typeof input !== 'string' ? input.description : input) : null,
        productCode: this.productCode,
        excludedProductCode: this.excludedProductCode,
        sortField: 'description'
      })
      : EMPTY;

  optionLabelKey = 'description';
}
