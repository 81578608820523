import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { FinancialInstitution, IntegrationEventData, IntegrationEventType } from './bank-account.model';
import { ObjectHelper, QueryParams } from 'common';
import { AppSettings } from '../../../src/app.settings';

declare global {
  interface Window {
    finicityConnect: any;
  }
}

@Injectable({
  providedIn: 'root'
})
export class FinicityService {

  private readonly baseUrl = `${this.settings.banking.url}`;

  onIntegrationEvent: ReplaySubject<IntegrationEventData>;
  obsIntegrationEvent: Observable<IntegrationEventData>;

  constructor(
    private http: HttpClient,
    private settings: AppSettings) {

    this.onIntegrationEvent = new ReplaySubject<IntegrationEventData>(1);
    this.obsIntegrationEvent = this.onIntegrationEvent.asObservable();

    this.obsIntegrationEvent.subscribe(e => {
      if (window.finicityConnect && e.type == IntegrationEventType.Cancel || e.type == IntegrationEventType.Error || e.type == IntegrationEventType.Success)
        window.finicityConnect.destroy();
    });
  }

  getFinicityInstitutionList(params: FinicityInstitutionQueryParams): Observable<any> {
    ObjectHelper.removeEmptyProperties(params);
    const queryParams = ObjectHelper.getQueryParams(params);
    return this.http.get(`${this.baseUrl}/api/finicity/institution/search?${queryParams}`);
  }

  open(entityId: number, institution: FinancialInstitution) {
    if (!institution.institutionId && !institution.institutionLoginId && !institution.institutionName)
      throw new Error('institution is empty');

    const req = {
      entityId: entityId,
      finicityInstitutionId: institution.institutionId,
      finicityInstitutionLoginId: institution.institutionLoginId,
      finicityInstitutionName: institution.institutionName
    };
    this.http.post<GetConnectUrlResponse>(`${this.baseUrl}/api/finicity/connect-url`, req)
      .subscribe(
        res => {
          this.openIFrame(res.link, entityId, res.institution?.name, res.institution?.id);
        },
        err => {
          let error = 'Error while getting finicity connect url.'
          if (err.status === 404)
            error = 'Institution not found.'
          else
            error = err?.error?.error_description ?? err.message ?? error;

          const event: IntegrationEventData =
          {
            entityId: entityId,
            institutionId: institution.institutionId,
            institutionName: institution.institutionName,
            type: IntegrationEventType.Error,
            info: error
          };
          this.onIntegrationEvent.next(event);
        }
      );
  }

  private openIFrame(link: string, entityId: number, institutionName: string, institutionId: string) {
    let event: IntegrationEventData =
    {
      entityId: entityId,
      institutionId: institutionId,
      institutionName: institutionName,
      type: IntegrationEventType.Pending,
      info: null
    };

    const that = this;

    window.finicityConnect.destroy();

    window.finicityConnect.launch(link, {
      success: function (result) {
        console.debug('Finicity success called');
        console.debug(result);
        event.type = IntegrationEventType.Success;
        event.info = 'Great success!';
        that.onIntegrationEvent.next(event);
      },
      cancel: function () {
        console.debug('Finicity cancel called');
        event.type = IntegrationEventType.Cancel;
        event.info = 'User cancelled Finicity login form';
        that.onIntegrationEvent.next(event);
      },
      error: function (err) {
        console.error('Finicity error called');
        console.error(err);
        var info = 'Unknown unexpected error';
        if (err && err.code && err.reason)
          info = "code: " + err.code + "; reason: " + err.reason;

        event.type = IntegrationEventType.Error;
        event.info = info;
        that.onIntegrationEvent.next(event);
      },
      loaded: function () {
        console.debug('This gets called only once after the iframe has finished loading');
      },
      user: (userEvent) => {
        console.debug('Finicity user called');
        console.debug(userEvent);
        const info = `${userEvent?.data?.action} ${userEvent.data.title}: ${userEvent.data.message}`;

        if (userEvent?.data?.action == "DisplayAlert") {
          event.type = IntegrationEventType.Pending;
          event.info = info;
          that.onIntegrationEvent.next(event);
        }
      }
    });
  }
}

interface FinicityInstitutionQueryParams extends QueryParams {
  search?: string;
}

interface GetConnectUrlResponse {
  link?: string;
  institution?: FinicityInstitution;
}

interface FinicityInstitution {
  id?: string;
  name?: string;
}
