import { Component, Inject } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { Observable } from "rxjs";
import { DialogActionData } from "../action/dialog-action-data.model";
import {
  getDialogActionButtons,
  getDialogActionConfig,
} from "./dialog-action-data";

@Component({
  selector: "ifc-dialog-action",
  templateUrl: "./dialog-action.component.html",
  styleUrls: ["./dialog-action.component.scss"],
})
export class DialogActionComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogActionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogActionData
  ) { }

  static show(dialog: MatDialog, data: DialogActionData): Observable<boolean> {
    const buttons = getDialogActionButtons(data?.buttons);
    const config = getDialogActionConfig(data?.config);
    return dialog
      .open(DialogActionComponent, {
        data: { ...data, buttons, config },
        closeOnNavigation: config.closeOnNavigation,
        width: config.width,
        autoFocus: config.autoFocus,
      })
      .afterClosed();
  }
}
