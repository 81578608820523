<mat-dialog-content>
  <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="20px" fxFlex="100">
    <span class="mat-title">Thanks for your interest in renewing</span>
    <span>Your renewal application has been created. Your business advisor will reach out shortly.</span>
  </div>
</mat-dialog-content>

<div mat-dialog-actions class="flex row end-center">
  <button mat-raised-button [mat-dialog-close]="true" color="primary">OK</button>
</div>
