import { Component, OnInit } from '@angular/core';
import { BrandingService, CompanyBranding } from 'common';
import { AppSettings } from '../../app.settings';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'ifp-app-bar-contact',
  templateUrl: './app-bar-contact.component.html',
  styleUrls: ['./app-bar-contact.component.scss']
})
export class AppBarContactComponent implements OnInit {
  contactPhone: string;

  constructor(
    private brandingService: BrandingService,
    private settings: AppSettings
  ) { }

  ngOnInit(): void {
    this.initBrandingInfo();
  }

  private initBrandingInfo(): void {
    this.brandingService.getBranding$()
      .pipe(untilDestroyed(this))
      .subscribe((branding: CompanyBranding) => {
        this.contactPhone = branding?.phone ?? this.settings.if.contact.phone;
      });
  }
}
