import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { AppPageService } from '../app-page/app-page.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'ifc-app-scroll-container',
  templateUrl: './app-scroll-container.component.html',
  styleUrls: ['./app-scroll-container.component.scss']
})
export class AppScrollContainerComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;
  appBarExpanded: boolean;
  @Input() widgetsWithTopHeader: boolean;
  @Input() default: boolean;
  @Input() tabbed: boolean;
  @Input() innerTabbedHidden: boolean;
  @Input() innerTabbedVisible: boolean;
  @Input() filtered: boolean;
  @Input() detailTabbed: boolean;
  @Input() layout2: boolean;
  @Input() layout2List: boolean;
  @Input() layout2ListFiltered: boolean;
  @Input() innerSidenav: boolean;
  @Input() innerSidenavAndList: boolean;
  @Input() innerSidenavAndTabbed: boolean;

  constructor( private appPageService: AppPageService) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.appPageService.obsAppBarExpanded
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(value => {
        this.appBarExpanded = value;
      });
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}

