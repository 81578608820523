import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ActivatedRoute } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { OfferSummaryData } from "./models/offer-summary-data.model";
import { OfferSummaryFacade } from "./offer-summary.facade";
import { LinkingStatus } from "common";

@UntilDestroy()
@Component({
  selector: "ifp-offer-summary",
  templateUrl: "./offer-summary.component.html",
  styleUrls: ["./offer-summary.component.scss"],
  providers: [OfferSummaryFacade],
})
export class OfferSummaryComponent implements OnInit {
  data: OfferSummaryData;

  constructor(
    private route: ActivatedRoute,
    private facade: OfferSummaryFacade
  ) {}

  ngOnInit(): void {
    this.initData();
    this.facade.initActions();
    this.watchLinkingBankSuccessfully();
  }

  private initData(): void {
    this.route.data
      .pipe(untilDestroyed(this))
      .subscribe((data: OfferSummaryData) => {
        this.data = data;
      });
  }

  private watchLinkingBankSuccessfully() {
    this.facade
      .watchLinkingBankSuccessfully()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (this.data?.application) {
          this.data.application.bankLinkStatus = LinkingStatus.Linked;
        }
      });
  }
}
