import { Address } from "../address/address.model";

export class StringHelper {
  static phoneFormat(phone: string) {
    if (!phone)
      return null;

    phone = phone.replace(/\D/g, '');

    if (phone.startsWith('1'))
      phone = phone.substring(1);

    return '(' + phone.substring(0, 3) + ') ' + phone.substring(3, 6) + '-' + phone.substring(6);
  }

  static addressFormat(address: Address): string {
    if (!address) {
      return '';
    }

    let result = `${address.line1 ? address.line1 + ' ' : ''}`;
    if (address.line2) {
      result += `${address?.line2} `;
    }
    result += `${address.city ? address.city + ',' : ''} ${address.state ?? ''} ${address.zip ?? ''}`;

    return result;
  }
}
