<div id="main-content" fxLayout="column" fxLayoutAlign="center center" [ngClass]="classes">
  <div fxLayout.gt-sm="row" fxLayoutAlign.gt-md="start start" [fxLayout]="fxLayoutDirection" fxLayoutAlign="start center" class="layout margin app-card-frame" ngClass.xs="layout margin-sm app-card-frame"  >
    <div class="app-card-content">
      <ng-content></ng-content>
    </div>
    <div [fxHide.xs]="asideHideXS" [fxHide.sm]="asideHideSM" class="app-card-aside" [fxFlexOrder.gt-md]="asideOrderGtMd" [fxFlexOrder.lt-lg]="asideOrderLtLg">
      <ng-content select="aside"></ng-content>
    </div>
  </div>
</div>
