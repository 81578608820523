/* eslint-disable @angular-eslint/no-inputs-metadata-property */
import { Component } from "@angular/core";
import { ReleaseNoteData } from "../../models/about.model";

@Component({
    selector: 'ifc-release-note',
    templateUrl: './release-note.component.html',
    styleUrls: [ './release-note.component.scss' ],
    inputs: ["notes"]
})
export class ReleaseNoteComponent {
    public notes: ReleaseNoteData[] = [];
}