import { Injectable } from "@angular/core";
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from "@angular/router";
import { AccountTransfer } from "./account-transfer.model";
import { Observable } from "rxjs";
import { EnumHelper, RouteHelper, TransactionType, parseNumber } from "common";
import { map, take } from "rxjs/operators";
import { ProductName } from "../../loan/domain/models/loan.model";
import { LoanFacade } from "./../../loan/domain/+state/loan.facade";

@Injectable({
  providedIn: "root",
})
export class AccountTransferDrawResolver implements Resolve<AccountTransfer> {
  constructor(private loanFacade: LoanFacade) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<AccountTransfer> {
    const accountId = parseNumber(RouteHelper.getParamValueByName(route, "accountId"));
    return this.loanFacade.getLoan$(accountId).pipe(
      take(1),
      map(loan => {
          const transfer: AccountTransfer = {
              type: TransactionType.Draw,
              amountInput: '',
              amountMaximum: loan.loanInfo?.availableFunds,
              amountMinimum: loan.loanInfo?.minDrawAmount,
              suggestedDrawAmount: loan.loanInfo?.suggestedDrawAmount,
              fee: 0,
              from: `${EnumHelper.getNameFromValue(ProductName, loan.productCode)}: ${loan.loanNumber}`,
              to: loan.loanInfo?.disbursementBankName && loan.loanInfo?.disbursementBankAccount
                  ? `${loan.loanInfo?.disbursementBankName} ${loan.loanInfo?.disbursementBankAccount}`
                  : 'Your bank account on file',
              balanceAfter: {
                  availableBalance: loan.loanInfo?.availableFunds,
                  outstandingBalance: loan.loanInfo?.principalBalance,
                  upcomingPaymentAmount: loan.loanInfo?.upcomingPayment,
                  upcomingPaymentDate: loan.loanInfo?.paymentDueDate ? new Date(loan.loanInfo?.paymentDueDate) : null
              },
              loanInfo: loan.loanInfo,
              loanOffer: loan.offer,
              prepaymentHidden: loan.productCode === "Term",
              drawHidden: loan.productCode === "Term",
              drawDownFee: loan.drawDownFee,
              drawDownFeeType: loan.drawDownFeeType
          };
          transfer.submit = () => {
              return this.loanFacade.draw(loan.id, transfer.amountInput, transfer.promotionInfo?.promoCode, transfer.note);
          };
          return transfer;
      })
    );
  }
}
