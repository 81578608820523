import { Component, OnInit } from "@angular/core";
import { AppBarTitleService } from "common";
import { InsuranceService } from "../insurance.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ReminderData, Reminders } from "../../reminder/reminder.model";
import { InsuranceQuoteBaseComponent } from "./insurance-quote-base.component";
import { ReminderService } from "../../reminder/reminder.service";
import { CustomerFacade } from "../../customer/domain/+state/customer.facade";
import { EntityFacade } from "../../entity/domain/+state/entity.facade";

@Component({
  selector: "ifp-insurance-quote-reminder",
  templateUrl: "./insurance-quote.component.html",
  styleUrls: ["./insurance-quote.component.scss"],
})
export class InsuranceQuoteReminderComponent
  extends InsuranceQuoteBaseComponent<ReminderData>
  implements OnInit
{
  constructor(
    appBarTitleService: AppBarTitleService,
    insuranceService: InsuranceService,
    entityFacade: EntityFacade,
    customerFacade: CustomerFacade,
    router: Router,
    reminderService: ReminderService,
    route: ActivatedRoute
  ) {
    super(
      appBarTitleService,
      insuranceService,
      entityFacade,
      customerFacade,
      router,
      reminderService,
      route
    );
    super.searchCondition = { name: Reminders.Insurance };
    sessionStorage.setItem("insurancePageViewed", JSON.stringify(true));
  }
}
