import { Component, OnInit } from "@angular/core";
import * as _ from "lodash";
import { AppBarTitleService, EntityData, parseNumber } from "common";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { MoreInfoDialogComponent } from "../more-info-dialog/more-info-dialog.component";
import { ReminderService } from "../../reminder/reminder.service";
import { ReminderData } from "../../reminder/reminder.model";
import { MessageSubjects } from "../../message/message.model";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { EntityFacade } from "../../entity/domain/+state/entity.facade";

@UntilDestroy()
@Component({
  selector: "ifp-insurance-reminder",
  templateUrl: "./insurance-reminder.component.html",
  styleUrls: ["./insurance-reminder.component.scss"],
})
export class InsuranceReminderComponent implements OnInit {
  insuranceReminder: ReminderData;
  insuranceReminderName = MessageSubjects.GeneralLiabilityInsurance;
  private entity: EntityData;

  constructor(
    private appBarTitleService: AppBarTitleService,
    private reminderService: ReminderService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private entityFacade: EntityFacade,
    private router: Router
  ) {
    sessionStorage.setItem("insurancePageViewed", JSON.stringify(true));
  }

  ngOnInit() {
    this.appBarTitleService.title = MessageSubjects.GeneralLiabilityInsurance;

    this.entityFacade
      .getEntity$(parseNumber(this.route.snapshot.queryParams.companyId))
      .pipe(untilDestroyed(this))
      .subscribe((entity) => {
        this.entity = entity;
        this.initInsuranceReminder(entity.id);
      });
  }

  private initInsuranceReminder(entityId: number) {
    this.reminderService.query(entityId).subscribe((data) => {
      const reminder = _.find(data, { name: this.insuranceReminderName });
      if (reminder) {
        this.insuranceReminder = reminder;
      }
    });
  }

  getQuote() {
    const quoteUrl = "/insurance-quote/reminder";
    if (this.entity.establishedDate) {
      this.router.navigate([quoteUrl]);
      return;
    }

    const dialogRef = this.dialog.open(MoreInfoDialogComponent, {
      width: "500px",
      closeOnNavigation: true,
      data: { entityId: this.entity.id },
    });
    dialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        if (result) {
          this.router.navigate([quoteUrl]);
        }
      });
  }
}
