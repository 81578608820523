<ifc-app-loader></ifc-app-loader>

<router-outlet name="app-sidenav"></router-outlet>

<mat-sidenav-container *ngIf="(data && data.appPage && data.appPage.sidenavEnabled) else main">
  <mat-sidenav
    #sidenav
    [mode]="mode"
    [opened]="sidenavOpened"
    [ngClass]="{'sticky': data.appPage.sidenavHeaderSticky, 'mat-elevation-z8': true}">
    <div class="app-sidenav-header layout position-relative">
      <div class="app-sidenav-header-background">
      </div>
      <div class="app-sidenav-header-background-wrapper">
      </div>
      <div class="app-logo-container flex row space-between-center">
        <ifc-app-logo *ngIf="!data.appBar.logoHidden" class="layout logo height" logoClass="white" [showDefaultLogo]="data.appBar?.showDefaultLogo"></ifc-app-logo>
        <button *ngIf="!data.appPage.hideSidenavToggle" mat-icon-button (click)="sidenav.toggle()">
          <mat-icon class="app-sidenav-toggle-icon">keyboard_arrow_left</mat-icon>
        </button>
      </div>
      <div class="app-sidenav-heading">
        <div class="app-sidenav-title">{{data.appPage.sidenavTitle}}</div>
        <div class="app-sidenav-subtitle">{{data.appPage.sidenavSubtitle}}</div>
      </div>
    </div>
    <div *ngIf="data.appPage.sidenavHeaderSticky else baseSidenavContent" class="app-sidenav-content-wrapper">
      <router-outlet name="sidenav-content"></router-outlet>
    </div>
    <ng-template #baseSidenavContent>
      <router-outlet name="sidenav-content"></router-outlet>
    </ng-template>
  </mat-sidenav>

  <mat-sidenav-content>
    <ng-container *ngTemplateOutlet="main"></ng-container>
  </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #main>
  <main class="main" [class.main--app-bar-fixed]="data.appBar && data.appBar.fixed"
    [ngClass]="{'app-bar-expanded': appBarExpandable}">

    <header [ngClass]="{'app-bar': !data.appBar.stickyDisabled, 'app-bar--tabbed': appBarTabbed }" [class.mat-elevation-z3]="contentScrolled">
      <router-outlet name="app-bar"></router-outlet>
    </header>

    <div [ngClass]="'content ' + (data.appPage && data.appPage.contentClass ? data.appPage.contentClass : '') + ' flex'">
      <div #contentWrapper class="layout flex column fill">
        <div [ngClass]="outletContainerClasses" class="layout fill">
          <router-outlet></router-outlet>
        </div>
        <aside>
          <router-outlet name="content-footer"></router-outlet>
        </aside>
      </div>
    </div>

    <footer>
      <router-outlet name="app-footer"></router-outlet>
    </footer>

  </main>
</ng-template>
