import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EntityState } from "../models/entity-state.model";
import { EntityExData, StateService } from "common";

const initialState: EntityState = null;

@Injectable({
  providedIn: "root",
})
export class EntityStateService extends StateService<EntityState> {
  constructor() {
    super(initialState);
  }

  getEntity$(): Observable<EntityExData> {
    return this.select((state: EntityState) => state?.entity);
  }

  getEntities$(): Observable<EntityExData[]> {
    return this.select((state: EntityState) => state?.entities);
  }

  setEntity(entity: EntityExData): void {
    const newState = {
      ...this.state,
      entity,
    };
    this.setState(newState);
  }

  setEntities(entities: EntityExData[]): void {
    const newState = {
      ...this.state,
      entities,
    };
    this.setState(newState);
  }

  isEntityStateLoaded(id: number): boolean {
    return !!this.state?.entity && this.state.entity.id === id;
  }

  isAnyEntityStateLoaded(): boolean {
    return !!this.state?.entity;
  }

  isEntitiesStateLoaded(): boolean {
    return this.state?.entities?.length > 0;
  }

  clearState(): void {
    this.initState(initialState);
  }
}
