import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppPageComponent, AppBarComponent, AppBarTitleComponent, AppFooterComponent, AppBarContactComponent, AppBarCloseAction, SyncGuardHelper } from 'common';
import { OAuthGuard } from '../../guards/oauth.guard';
import { BankLinkRequiredFinancialInstitutionsResolver } from '../bank-account/bank-account.resolver';
import { EntityQueryResolver } from '../entity/infrastructure/entity.resolver';
import { RenewalEligibleDocumentsComponent } from './documents/renewal-eligible-documents.component';
import { RenewalEligibleReminderComponent } from './reminder/renewal-eligible-reminder.component';
import { RenewalEligibleGuard } from "./reminder/renewal-eligible.guard";
import { RenewalDocumentsUploadGuard } from "./reminder/renewal-documents-upload.guard";
import { RenewalConfirmationComponent } from './renewal-confirmation/renewal-confirmation.component';
import { LoanResolver } from '../loan/infrastructure/loan.resolver';
import { RenewalConfirmationGuard } from '../renewal-eligible/renewal-confirmation/sign-agreement-needed.guard';

const routes: Routes = [
  {
    path: 'account/:accountId/renewal',
    canActivate: [OAuthGuard],
    component: AppPageComponent,
    data: {
      appPage: {
        contentClass: 'simple',
        contentAlignment: 'center-start',
        contentWithoutPadding: true
      },
      appBar: {
        closeAction: AppBarCloseAction.HOME,
        logoHidden: true,
        stickyDisabled: true
      }
    },
    children: [
      {
        path: '',
        outlet: 'app-bar',
        component: AppBarComponent,
        children: [
          {
            path: '',
            outlet: 'title',
            component: AppBarTitleComponent,
          },
          {
            path: '',
            outlet: 'right',
            component: AppBarContactComponent
          }
        ]
      },
      {
        path: '',
        outlet: 'app-footer',
        component: AppFooterComponent,
        data: {
          appFooter: {
            full: false,
            hideText: true,
            showEncryptionInfo: true
          }
        }
      },
      {
        path: 'eligible',
        component: RenewalEligibleReminderComponent,
        canActivate: [RenewalEligibleGuard]
      },
      {
        path: 'documents',
        component: RenewalEligibleDocumentsComponent,
        canActivate: [RenewalDocumentsUploadGuard],
        resolve: {
          entity: EntityQueryResolver,
          financialInstitutions: BankLinkRequiredFinancialInstitutionsResolver
        }
      },
      {
        path: 'confirmation',
        component: RenewalConfirmationComponent,
        canActivate: [SyncGuardHelper],
        data: {
          syncGuards: [
            RenewalConfirmationGuard,
          ]
        },
        resolve: {
          loan: LoanResolver
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class RenewalEligibleRoutingModule { }
