import { NgModule } from "@angular/core";
import { UiModule } from "../ui/ui.module";
import { AvailableFundsExplanationDialogComponent } from "./available-funds-explanation-dialog/available-funds-explanation-dialog.component";
import { CaseResolutionDialogComponent } from "./case-resolution-dialog/case-resolution-dialog.component";

@NgModule({
  imports: [UiModule],
  declarations: [
    AvailableFundsExplanationDialogComponent,
    CaseResolutionDialogComponent
  ],
  exports: [
    AvailableFundsExplanationDialogComponent,
    CaseResolutionDialogComponent
  ],
})
export class CaseModule {}
