<div id="main" fxLayout="column" fxLayoutAlign="center center">

  <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="32px" ngClass.lt-sm="layout padding-horizontal">

    <div class="header" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="12px">
      <img [src]="renewalIconSrc" alt="Renewal">
      <span ngClass.gt-sm="title" ngClass.lt-sm="title xs">
        Your loan is eligible for renewal!
      </span>
      <span ngClass.lt-sm="title xs">
        It's quick and painless to keep your {{currentLoan?.productName}} {{currentLoan?.description ?? currentLoan?.loanNumber}} loan open. Continue below to confirm your loan renewal.
      </span>
    </div>

    <div class="content" fxLayout="column" fxLayoutAlign.lt-sm="center start" fxLayoutGap="12px"
      fxLayoutGap.lt-sm="8px">
      <div fxLayout="row" fxFlexAlign="space-between center" class="layout padding-top">
        <div fxLayoutAlign="end" fxLayoutGap="8px" fxFlex="100">
          <button mat-button color="mat-gray" type="button" [routerLink]="['/dashboard']">
            DECIDE LATER
          </button>
          <button mat-raised-button class="{{companyBranding?.colorSchema}}-color" type="button" (click)="confirmRenewal()">
           CONTINUE
          </button>
        </div>
      </div>

    </div>

  </div>

</div>
