<div class="container">
  <div class="icon-container"
       [class]="'icon-container--'+ colorSchema">
    <mat-icon class="icon-container__icon">lock</mat-icon>
  </div>
  <p>We use advanced encryption to keep your information safe.
    <ng-container *ngIf="isLearnMoreVisible">
      Learn more <a (click)="learnMore()">here</a>.
    </ng-container>
  </p>
</div>
