<mat-form-field class="loan-selector">
  <mat-select [ngModel]="currentLoan?.id"
              name="Loan"
              (selectionChange)="onChangeLoan($event.value)">
    <mat-option *ngFor="let loan of loans"
                [value]="loan?.id">
      {{ loan.description || loan?.loanNumber }}
    </mat-option>
  </mat-select>
  <mat-hint> {{ currentLoan?.entityDBA || currentLoan?.entityName}}</mat-hint>
</mat-form-field>
