import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { BankingService } from "../../../bank-account/banking.service";
import { ApplicationStatusStepperComponent } from "../application-status-stepper.component";

@Component({
  selector: "ifp-sidenav-application-status-stepper",
  templateUrl: "./sidenav-application-status-stepper.component.html",
  styleUrls: ["./sidenav-application-status-stepper.component.scss"],
})
export class SideNavApplicationStatusStepperComponent extends ApplicationStatusStepperComponent {
  constructor(router: Router, bankingService: BankingService) {
    super(router, bankingService);
  }
}
