import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { AppPageService } from "../app-page/app-page.service";
import { AppBarCloseAction, AppBarComponentData } from "./app-bar.model";
import { AppBarCloseActionInternalService } from "./app-bar-close-action.internal.service";

@Component({
  selector: "ifc-app-bar",
  templateUrl: "./app-bar.component.html",
  styleUrls: ["./app-bar.component.scss"],
})
export class AppBarComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  appPageSidenavOpened: boolean;
  data: AppBarComponentData;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private appBarCloseActionService: AppBarCloseActionInternalService,
    private appPageService: AppPageService
  ) {
    this.appPageSidenavOpened = this.appPageService.sidenavOpened;
  }

  ngOnInit() {
    this.appPageSidenavOpened = this.appPageService.sidenavOpened;
    this.subscriptions = [
      this.route.data.subscribe((data: AppBarComponentData) => {
        if (!data) data = {};
        if (!data.appBar) data.appBar = {};
        if (!data.appBar.logoClass) data.appBar.logoClass = "colored";
        this.data = data;
      }),
      this.appPageService.sidenavOpenedChange.subscribe((it) => {
        this.appPageSidenavOpened = it;
      }),
    ];
  }

  close() {
    switch (this.data.appBar.closeAction) {
      case AppBarCloseAction.HOME: {
        const result = this.appPageService.backToIfPossible(true);
        if (result) {
          return;
        }

        this.router.navigate(["home"]);
        break;
      }
      case AppBarCloseAction.SIGN_OUT: {
        const result = this.appPageService.backToIfPossible(true);
        if (result) {
          return;
        }

        this.router.navigate(["sign-out"]);
        break;
      }
      case AppBarCloseAction.CALCULATOR_SUMMARY: {
        const result = this.appPageService.backToIfPossible(true);
        if (result) {
          return;
        }
        
        this.route.queryParams.subscribe((params) => {
          this.route.parent.url.subscribe((url) => {
            this.router.navigate([url[0].path + "/summary"], {
              queryParams: { uuid: params.uuid },
            });
          });
        });
        break;
      }
      case AppBarCloseAction.BACK: {
        this.appPageService.back();
        break;
      }
      case AppBarCloseAction.MANUAL: {
        this.appBarCloseActionService.close();
        break;
      }
      case AppBarCloseAction.SIDENAV: {
        this.appPageService.sidenavClose();
        break;
      }
      default: {
        const result = this.appPageService.backToIfPossible(true);
        if (result) {
          return;
        }

        this.router.navigate([this.data.appBar.closeAction]);
        break;
      }
    }
  }

  appPageSidenavOpen() {
    this.appPageService.sidenavOpen();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((it) => it.unsubscribe());
  }
}
