import { Component, OnInit } from "@angular/core";
import { ErrorData } from "../../models/errors.model";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { ErrorsService } from "../../errors.service";
import { ErrorActionType } from "../../enums/error-action-type.enum";

@Component({
  selector: "ifc-error-page",
  templateUrl: "./error-page.component.html",
})
export class ErrorPageComponent implements OnInit {
  constructor (private errorService: ErrorsService) {}

  actionType$: Observable<ErrorActionType>;
  
  ngOnInit(): void {
    this.actionType$ = this.errorService.errorData$
      .pipe(
        map((errorData: ErrorData) => {
          if (errorData && errorData.actionType) {
            return errorData.actionType;
          } else {
            return undefined;
          }
        })
      );
  }
}
