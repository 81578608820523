import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { of } from "rxjs";
import { mergeMap, tap } from "rxjs/operators";
import { OidcService } from "../app/user/oidc/oidc.service";

@Injectable({
    providedIn: 'root',
})
export class OAuthGuard implements CanActivate {

    constructor(private oauthService: OidcService, private router: Router) {}

    async canActivate(_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Promise<UrlTree | boolean> {
        const sessionId = _next.queryParams.sessionId;

        if(sessionId && this.oauthService.getSessionId() != sessionId) {
            this.sessionInvalidateAndRenew();
        }
        else if(sessionId) {
            return this.sessionValidReroute();
        }

        if (this.oauthService.authenticated) {
            return this.getIsAuthenticated();
        } else {
            return this.refreshSession();
        }
    }

    private refreshSession(): boolean | UrlTree | PromiseLike<boolean | UrlTree> {
        return this.oauthService.refreshAuthToken().toPromise().then(res => {
            if (!res?.access_token) {
                this.oauthService.saveNavigationHistory();
                this.oauthService.authorize();
                return false;
            }

            this.oauthService.sessionBegin(
                sessionStorage.loginType,
                res?.access_token,
                res?.expires_in,
                res?.refresh_token,
                res?.refresh_token_expires_in
            );

            return true;
        }).catch(_ => {
            this.oauthService.saveNavigationHistory();
            this.oauthService.authorize();
            return false;
        });
    }

    private sessionInvalidateAndRenew() {
        this.oauthService.saveNavigationHistory();
        this.oauthService.sessionEndLocal();
        this.oauthService.authorize();
    }

    private sessionValidReroute() {
        let params = new URLSearchParams(window.location.search);
        params.delete('sessionId');
        return this.router.parseUrl(`${window.location.pathname}?${params.toString()}`);
    }

    private getIsAuthenticated(): Promise<boolean> {
        this.oauthService.restartIdleTimer();
        const isAuthObs = of(this.oauthService.authenticated);
        const isParentSsoSessionValid = this.oauthService.isSsoSessionValid()
            .pipe(
                tap(isSsoValid => {
                    if (!isSsoValid) {
                        this.oauthService.sessionEndLocal();
                        this.oauthService.authorize();
                    }
                })
            );

        return isAuthObs.pipe(
            mergeMap(isAuth => isAuth ? isParentSsoSessionValid : of(isAuth))
        ).toPromise();
    }
}
