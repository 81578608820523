import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { Meta } from "@angular/platform-browser";

@Injectable({
    providedIn: 'root'
})
export class MetaService {

    constructor(@Inject(DOCUMENT) private dom, private meta: Meta) { }

    createCanonicalURL(url?: string) {
        const canURL = url == undefined ? this.dom.URL : url;
        const link: HTMLLinkElement = this.dom.createElement('link');
        link.setAttribute('rel', 'canonical');
        link.setAttribute('href', canURL);
        this.dom.head.appendChild(link);
    }

    createMetaTag(name: string, content: string) {
        this.meta.addTag({ name: name, content: content });
    }

    createAlternateURL(url?: string) {
        const canURL = url == undefined ? this.dom.URL : url;
        const link: HTMLLinkElement = this.dom.createElement('link');
        link.setAttribute('rel', 'alternate');
        link.setAttribute('hreflang', 'en-us');
        link.setAttribute('href', canURL);
        this.dom.head.appendChild(link);
    }
}
