import { Component, OnInit, Input } from "@angular/core";
import {
  BankBrandingByName,
  bankLinkStatusLabels,
  bankLogoExists,
  FinancialInstitution,
  getBankLogo,
  InstitutionIntegrationStatus,
} from "../bank-account.model";
import { BankingService } from "../banking.service";
import { EntityFacade } from "../../entity/domain/+state/entity.facade";
import { ActivatedRoute } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { parseNumber } from "common";

@UntilDestroy()
@Component({
  selector: "ifp-bank-widget",
  templateUrl: "./bank-widget.component.html",
  styleUrls: ["./bank-widget.component.scss"],
})
export class BankWidgetComponent implements OnInit {
  institutionIntegrationStatus: number;
  brandingData = null;
  logoExists = false;
  logoSrc = null;
  color = null;
  private entityId: number;

  constructor(
    private bankingService: BankingService,
    private entityFacade: EntityFacade,
    private route: ActivatedRoute
  ) {}

  get bankLinkStatusLabels() {
    return bankLinkStatusLabels;
  }

  get InstitutionIntegrationStatus() {
    return InstitutionIntegrationStatus;
  }

  @Input()
  institution: FinancialInstitution;

  ngOnInit() {
    this.initEntity();
    this.institutionIntegrationStatus = this.institution.isLinked
      ? InstitutionIntegrationStatus.Linked
      : InstitutionIntegrationStatus.Unlinked;

    this.brandingData = BankBrandingByName[this.institution.institutionName];
    this.logoExists = bankLogoExists(this.institution.institutionName);

    if (this.logoExists) {
      this.logoSrc = getBankLogo(this.institution.institutionName);
      this.color = this.brandingData.color;
    }
  }

  private initEntity() {
    this.entityFacade
      .getEntity$(parseNumber(this.route.snapshot.queryParams.companyId))
      .pipe(untilDestroyed(this))
      .subscribe((entity) => {
        this.entityId = entity.id;
      });
  }

  relink() {
    this.bankingService.relink(this.entityId, this.institution);
  }
}
