import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppBarCloseAction, AppBarComponent, AppBarContactComponent, AppBarTitleComponent, AppDetailLayout2Component, AppPageComponent, SyncGuardHelper } from 'common';
import { OAuthGuard } from '../../guards/oauth.guard';
import { CustomerCurrentResolver } from '../customer/infrastructure/customer.resolver';
import { BottomToolbarComponent } from '../shared/bottom-toolbar/bottom-toolbar.component';
import { NavbarComponent } from '../shared/navbar/navbar.component';
import { SidenavContentComponent } from '../shared/sidenav-content/sidenav-content.component';
import { TopToolbarComponent } from '../shared/top-toolbar/top-toolbar.component';
import { ApplicationResolver, ApplicationsResolver } from './application.resolver';
import { SignReadyOfferBundleInfoResolver } from '../application-agreement/sign-ready-offer-bundle-info.resolver';
import { ApplicationAgreementFormComponent } from '../application-agreement/application-agreement-form/application-agreement-form.component';
import { CustomerCheckoutGuard } from '../agreement/customer-checkout.guard';

const routes: Routes = [
  {
    path: 'application/:appId/dashboard',
    canActivate: [OAuthGuard],
    component: AppDetailLayout2Component,
    resolve: {
      customer: CustomerCurrentResolver,
      applications: ApplicationsResolver,
      application: ApplicationResolver
    },
    data: { sidenavDisabled: true },
    children: [
      {
        path: '',
        component: TopToolbarComponent,
        outlet: 'app-top-toolbar'
      },
      {
        path: '',
        component: NavbarComponent,
        outlet: 'app-navbar'
      },
      {
        path: '',
        component: BottomToolbarComponent,
        outlet: 'app-bottom-toolbar'
      },
      {
        path: '',
        component: SidenavContentComponent
      }
    ]
  },
  {
    path: 'application/:appId/setup/sign-agreement',
    component: AppPageComponent,
    canActivate: [OAuthGuard],
    data: {
      appPage: {
        contentAlignment: 'stretch-stretch',
        contentWithoutPadding: true,
      },
      appBar: {
        closeAction: AppBarCloseAction.HOME,
        expandable: false,
        logoHidden: true,
      },
      appBarTitle: {
        content: 'Sign agreement'
      }
    },
    resolve: {
      customer: CustomerCurrentResolver,
      application: ApplicationResolver,
      offerBundlesInfo: SignReadyOfferBundleInfoResolver
    },
    children: [
      {
        path: '',
        outlet: 'app-bar',
        component: AppBarComponent,
        children: [
          {
            path: '',
            outlet: 'title',
            component: AppBarTitleComponent
          },
          {
            path: '',
            outlet: 'right',
            component: AppBarContactComponent
          }
        ]
      },
      {
        path: '',
        canActivate: [SyncGuardHelper],
        data: {
          syncGuards: [
            CustomerCheckoutGuard
          ]
        },
        component: ApplicationAgreementFormComponent
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class ApplicationRoutingModule { }
