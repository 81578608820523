import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { UntypedFormGroup, UntypedFormBuilder } from "@angular/forms";
import {
  AppBarTitleService,
  BrandingService,
  CompanyBranding,
  EntityData,
} from "common";
import {
  FinancialInstitution,
  bankLogoExists,
  getBankLogo,
  PopularInstistitution,
} from "../bank-account.model";
import { AppSettings } from "../../../../src/app.settings";
import { BankingService } from "../banking.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "ifp-bank-account-search",
  templateUrl: "./bank-account-search.component.html",
  styleUrls: ["./bank-account-search.component.scss"],
})
export class BankAccountSearchComponent implements OnInit {
  form: UntypedFormGroup;
  filters: FinicityInstitutionFilters;

  data: BankAccountSearchComponentData;
  contactPhone: string;
  contactEmail: string;
  title: string;

  constructor(
    private route: ActivatedRoute,
    private bankingService: BankingService,
    private appBarTitleService: AppBarTitleService,
    private formBuilder: UntypedFormBuilder,
    private settings: AppSettings,
    private brandingService: BrandingService,
  ) {
    this.filters = new FinicityInstitutionFilters();
    this.form = this.formBuilder.group({
      search: "",
    });
  }

  get PopularInstistitution() {
    return PopularInstistitution;
  }

  ngOnInit() {
    this.route.data
      .pipe(untilDestroyed(this))
      .subscribe((data) => (this.data = data));

    this.appBarTitleService.title = "Link your bank";
    this.title = "Link your bank";

    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((data) => {
      this.filters = new FinicityInstitutionFilters(data);
    });

    this.contactPhone = this.settings.if.contact.phone;
    this.initBrandingInfo();
  }

  private initBrandingInfo(): void {
    this.brandingService.getBranding$()
      .pipe(untilDestroyed(this))
      .subscribe((branding: CompanyBranding) => {
        this.contactPhone = branding?.phone ?? this.settings.if.contact.phone;
        this.contactEmail = branding?.infoEmail ?? "info@ideafinancial.com";
      });
  }

  chooseBank(institutionName: any, fromMap?: boolean) {
    const instutution: FinancialInstitution = {
      institutionId: null,
      institutionLoginId: null,
      institutionName: institutionName,
      routingNumber: null,
      isLinked: false,
    };

    this.bankingService.relink(this.data.entity.id, instutution, "dashboard");
  }

  logoExists(institutionName?: string) {
    return bankLogoExists(institutionName);
  }

  getLogo(institutionName?: string) {
    return getBankLogo(institutionName);
  }
}

export interface BankAccountSearchComponentData {
  entity?: EntityData;
}

class FinicityInstitutionFilters {
  search: any;

  constructor(filters?) {
    filters = filters || {};
    this.search = filters.search || undefined;
  }
}
