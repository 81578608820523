<h1 mat-dialog-title>Available funds</h1>

<mat-dialog-content>
  <p>
    {{data?.message}}
  </p>
  <p>
    <span class="subtitle-number color-primary">{{data?.availableFunds | shortNumber:'0'}}</span>
    <span class="subtitle secondary-text"> available of </span>
    <span class="subtitle-number color-primary">{{data?.msaCreditLimit | shortNumber:'0'}}</span>
    <span class="subtitle secondary-text"> line limit</span>
  </p>
</mat-dialog-content>

<div mat-dialog-actions class="flex row end-center">
  <button mat-button mat-button color="primary" [mat-dialog-close]="true">OK</button>
</div>
