<div id="main" fxLayout="column" fxLayoutAlign="start center">

  <div fxLayout="column" fxLayoutAlign="start center">

    <div class="content" fxLayout="column" fxLayoutAlign="center center" class="header">
      <span ngClass.gt-sm="title" ngClass.lt-sm="title xs">
        We need more information to give you an accurate quote.
      </span>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" fxLayout.lt-md="column" fxLayoutAlign.lt-md="center start"
      fxLayoutGap="32px" class="layout padding-sm">

      <mat-card class="mat-elevation-z4">
        <mat-card-content class="liability layout padding">
          <div fxLayout="column" fxLayoutAlign="center center">
            <span class="mat-headline">General Liability</span>
            <span class="mat-headline layout padding-vertical call-text">
              Call <a href="tel:{{contactPhone}}">{{contactPhone}}</a> and someone will take care of you.
            </span>
            <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" class="layout padding-bottom">
              <img src="../../assets/idea-hartford-logos-01.png" height="60px">
            </div>
          </div>
        </mat-card-content>
      </mat-card>

      <div class="description" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="8px">
        <span>
          General liability insurance helps cover costs from claims of personal injury, property damage, reputational
          harm and advertising injury. This may include:
        </span>
        <ul>
          <li>A customer or client’s medical expenses if they slip and fall in your business</li>
          <li>Legal costs to defend your business in a lawsuit or costs from judgements and
            settlements</li>
          <li>Repair costs to fix damaged property on the job and more</li>
        </ul>
      </div>

    </div>

  </div>

</div>