import { Component, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { CompanyBranding } from "../../branding/branding.model";
import { BrandingService } from "../../../core/branding/branding.service";

@UntilDestroy()
@Component({
  selector: "ifc-finance-contact-widget",
  templateUrl: "./finance-contact-widget.component.html",
  styleUrls: ["./finance-contact-widget.component.scss"],
})
export class FinanceContactWidgetComponent implements OnInit {
  companyBranding: CompanyBranding;

  constructor(
    private _brandingService: BrandingService
  ) {}

  ngOnInit() {
    this._brandingService
      .getBranding$()
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.companyBranding = res;
      });
  }
}
