import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { SidenavContentComponent } from "../shared/sidenav-content/sidenav-content.component";
import { OAuthGuard } from "../../guards/oauth.guard";
import {
  AppBarCloseAction,
  AppBarComponent,
  AppBarContactComponent,
  AppBarTitleComponent,
  AppDetailLayout2Component,
  AppFooterComponent,
  AppPageComponent,
  ProductCode,
} from "common";
import { CustomerCurrentResolver } from "../customer/infrastructure/customer.resolver";
import { TopToolbarComponent } from "../shared/top-toolbar/top-toolbar.component";
import { NavbarComponent } from "../shared/navbar/navbar.component";
import { BottomToolbarComponent } from "../shared/bottom-toolbar/bottom-toolbar.component";
import {
  LoanAllByProductCodeResolver,
  LoanResolver,
  LoansResolver,
} from "../loan/infrastructure/loan.resolver";
import { AccountPaymentsResolver } from "./account-payment/account-payments.resolver";
import { UserMenuComponent } from "../shared/user-menu/user-menu.component";
import { AccountListComponent } from "./account-list/account-list.component";
import { NewCaseFormComponent } from "../case-financing/new-case/new-case-form.component";
import { EntityQueryResolver } from "../entity/infrastructure/entity.resolver";

const routes: Routes = [
  {
    path: "account/:accountId/dashboard",
    canActivate: [OAuthGuard],
    component: AppDetailLayout2Component,
    resolve: {
      customer: CustomerCurrentResolver,
      loans: LoansResolver,
      loan: LoanResolver,
      accountPayment: AccountPaymentsResolver,
    },
    data: { sidenavDisabled: true },
    children: [
      {
        path: "",
        component: TopToolbarComponent,
        outlet: "app-top-toolbar",
      },
      {
        path: "",
        component: NavbarComponent,
        outlet: "app-navbar",
      },
      {
        path: "",
        component: BottomToolbarComponent,
        outlet: "app-bottom-toolbar",
      },
      {
        path: "",
        component: SidenavContentComponent,
      },
    ],
  },
  {
    path: "case-financing",
    canActivate: [OAuthGuard],
    component: AppPageComponent,
    data: {
      appBar: {
        closeAction: AppBarCloseAction.BACK,
        expandable: false,
        logoHidden: true,
        closeIcon: "chevron_left",
      },
      appBarActions: {
        menuMobile: true,
      },
      appPage: {
        contentClass: "simple",
        contentAlignment: "center-center",
        contentWithoutPadding: true,
      },
      productCode: ProductCode.Law,
    },
    children: [
      {
        path: "",
        component: AppBarComponent,
        outlet: "app-bar",
        children: [
          {
            path: "",
            outlet: "title",
            component: AppBarTitleComponent,
            data: {
              appBarTitle: {
                content: "Case financing",
              },
            },
          },
          {
            path: "",
            outlet: "right",
            component: UserMenuComponent,
          },
        ],
      },
      {
        path: "",
        component: AccountListComponent,
        runGuardsAndResolvers: "paramsOrQueryParamsChange",
        resolve: {
          loanSummaryData: LoanAllByProductCodeResolver,
          customer: CustomerCurrentResolver,
          entity: EntityQueryResolver,
        },
      },
    ],
  },
  {
    path: "accounts",
    canActivate: [OAuthGuard],
    component: AppPageComponent,
    data: {
      appBar: {
        closeAction: AppBarCloseAction.BACK,
        expandable: false,
        logoHidden: true,
        closeIcon: "chevron_left",
      },
      appBarActions: {
        menuMobile: true,
      },
      appPage: {
        contentClass: "simple",
        contentAlignment: "center-center",
        contentWithoutPadding: true,
      },
      excludedProductCode: ProductCode.Law,
    },
    children: [
      {
        path: "",
        component: AppBarComponent,
        outlet: "app-bar",
        children: [
          {
            path: "",
            outlet: "title",
            component: AppBarTitleComponent,
            data: {
              appBarTitle: {
                content: "Financing",
              },
            },
          },
          {
            path: "",
            outlet: "right",
            component: UserMenuComponent,
          },
        ],
      },
      {
        path: "",
        component: AccountListComponent,
        runGuardsAndResolvers: "paramsOrQueryParamsChange",
        resolve: {
          loanSummaryData: LoanAllByProductCodeResolver,
          customer: CustomerCurrentResolver,
          entity: EntityQueryResolver,
        },
      },
    ],
  },
  {
    path: "new-case",
    canActivate: [OAuthGuard],
    component: AppPageComponent,
    data: {
      appBar: {
        closeAction: AppBarCloseAction.BACK,
        expandable: false,
        logoHidden: true,
      },
      appBarActions: {
        menuMobile: true,
      },
      appPage: {
        contentClass: "simple",
        contentAlignment: "center-center",
        contentWithoutPadding: true,
      },
    },
    children: [
      {
        path: "",
        component: AppBarComponent,
        outlet: "app-bar",
        children: [
          {
            path: "",
            outlet: "title",
            component: AppBarTitleComponent,
            data: {
              appBarTitle: {
                content: "Fund another case",
              },
            },
          },
          {
            path: "",
            outlet: "right",
            component: AppBarContactComponent,
          },
        ],
      },
      {
        path: "",
        outlet: "app-footer",
        component: AppFooterComponent,
        data: {
          appFooter: {
            full: false,
            hideText: true,
            showEncryptionInfo: true,
          },
        },
      },
      {
        path: "",
        component: NewCaseFormComponent,
        runGuardsAndResolvers: "paramsOrQueryParamsChange",
        resolve: {
          customer: CustomerCurrentResolver,
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AccountRoutingModule {}
