import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Uppy } from "@uppy/core";
import { DashboardOptions } from "@uppy/dashboard";
import {
  getUppyDashboardOptions
} from "projects/portal/src/app/document/document-upload/document-upload-uppy-config";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UploadFileDialogService } from './upload-file-dialog.service';
import { UploadFileDialogConfig, UploadFileDialogResult, UppyFileChangeTypeEnum } from './upload-file.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'ifp-upload-file-dialog',
  templateUrl: './upload-file-dialog.component.html',
  styleUrls: ['./upload-file-dialog.component.scss'],
  providers: [UploadFileDialogService]
})
export class UploadFileDialogComponent implements OnInit {
  hasFiles: boolean = false;
  uppy: Uppy;
  uppyDashboardOptions: DashboardOptions;
  result: UploadFileDialogResult;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: UploadFileDialogConfig,
    private dialog: MatDialogRef<UploadFileDialogComponent>,
    private service: UploadFileDialogService) {
      this.result = {} as UploadFileDialogResult;
      this.service.setMetaCallback(this.data.getMetaCallback);
    }

  ngOnInit(): void {
    this.uppy = this.service.getUppy(this.data, this.result, this.dialog);    
    this.service.files.pipe(untilDestroyed(this)).subscribe(files=>{
      const inputFiles = this.dialog.componentInstance.data.files?.length > 0; 
      const outputFiles = files?.length > 0;
      this.hasFiles = inputFiles || outputFiles;
    });
    this.uppyDashboardOptions = getUppyDashboardOptions();
    this.dialog.addPanelClass('upload-panel');
  }

  public cancel() {
    this.result = { type: UppyFileChangeTypeEnum.Canceled, files: null };
    this.dialog.close();
  }

  public Ok() {
    this.dialog.close();
  }

  static show(dialog: MatDialog, uploadConfig: UploadFileDialogConfig): Observable<UploadFileDialogResult> {
    const dialogRef = dialog.open(UploadFileDialogComponent, {
      width: '480px',
      data: { ...uploadConfig },
      closeOnNavigation: true,
      disableClose: true
    });

    return dialogRef.afterClosed().pipe(
      map(result => {
        if (result) {
          return result;
        }
        return dialogRef.componentInstance.result;
      })
    );
  }
}
