import {
  ApplicationStage,
  FeeType,
  PaymentFrequency,
} from "../application/application.model";
import { BankAccount } from "../bank/bank.model";
import { LoanFileTemplateData } from "../documents/loan-document.model";
import { OfferStatus } from "../offer/models/offer.model";
import { QueryResult } from "../query/query.data";

export interface LoanRefData {
  id?: number;
  status?: LoanStatus;
}

export enum LoanStatus {
  Pending = 0,
  Open = 1,
  Closed = 2,
  Frozen = 3,
  InProgressNoPayments = 4,
  InProgressInterestOnlyPayments = 5,
  Repayment = 6,
  PendingRenewal = 10,
  PendingClosure = 11,
  Declined = 20,
  RenewalDeclined = 21,
  Delinquent = 30,
  Workout = 40,
  PaymentRelief = 42,
  WriteOff = 50,
  PaidOff = 51,
}

export const LoanStatusUniqueLabel = new Map<number, string>([
  [LoanStatus.Pending, "Pending"],
  [LoanStatus.Open, "Open"],
  [LoanStatus.Closed, "Closed"],
  [LoanStatus.Frozen, "Frozen"],
  [LoanStatus.InProgressNoPayments, "In progress no payments"],
  [
    LoanStatus.InProgressInterestOnlyPayments,
    "In progress interest only payments",
  ],
  [LoanStatus.Repayment, "Repayment"],
  [LoanStatus.PendingRenewal, "Pending renewal"],
  [LoanStatus.PendingClosure, "Pending closure"],
  [LoanStatus.Declined, "Declined"],
  [LoanStatus.RenewalDeclined, "Renewal declined"],
  [LoanStatus.Delinquent, "Delinquent"],
  [LoanStatus.Workout, "Workout"],
  [LoanStatus.PaymentRelief, "Payment relief"],
  [LoanStatus.WriteOff, "Write off"],
  [LoanStatus.PaidOff, "Paid off"],
]);

export const LoanStatusLabel = new Map<number, string>([
  [LoanStatus.Pending, "Pending"],
  [LoanStatus.Open, "Open"],
  [LoanStatus.Closed, "Closed"],
  [LoanStatus.Frozen, "Frozen"],
  [LoanStatus.InProgressNoPayments, "Case in Progress"],
  [LoanStatus.InProgressInterestOnlyPayments, "Case in Progress"],
  [LoanStatus.Repayment, "Repayment"],
  [LoanStatus.PendingRenewal, "Pending renewal"],
  [LoanStatus.PendingClosure, "Pending Closure"],
  [LoanStatus.Declined, "Declined"],
  [LoanStatus.RenewalDeclined, "Repayment"],
  [LoanStatus.Delinquent, "Frozen - Missed Payment"],
  [LoanStatus.Workout, "Repayment"],
  [LoanStatus.PaymentRelief, "Repayment"],
  [LoanStatus.WriteOff, "Repayment"],
  [LoanStatus.PaidOff, "Paid off"],
]);

export const CustomerLoanStatusLabel = new Map<number, string>([
  ...LoanStatusLabel,
  [LoanStatus.Frozen, "Needs attention"]
]);

export interface LoanInfoDto {
  id?: number;
  creditLimit?: number;
  msaCreditLimit?: number;
  interestRate?: number;
  principalBalance?: number;
  entityPrincipalBalance?: number;
  adjustedPrincipalBalance: number;
  accruedInterest?: number;
  adjustedAccruedInterest?: number;
  currentPayoffAmount?: number;
  nextDayPayoffAmount?: number;
  nextDayPayoffAt?: any;
  availableFunds?: number;
  loanAvailableFunds?: number;
  masAvailableFunds?: number;
  paymentAmount?: number;
  upcomingPayment?: number;
  paymentDueDate?: any;
  termInterestRate?: number;
  totalNumberOfPayments?: number;
  numberOfPaymentsLeft?: number;
  termMaintenanceFee?: number;
  disbursementBankName?: string;
  disbursementBankAccount?: string;
  paymentBankName?: string;
  paymentBankAccount?: string;
  minDrawAmount?: number;
  suggestedDrawAmount?: number;
  minPaymentAmount?: number;
  noAutoPayments?: boolean;
  noInterestAccrual?: boolean;
  amortizedBalance?: number;
  firstDrawId?: number;
  totalInterestCharged?: number;
  totalInterestPaid?: number;
}

export interface RenewalEligibilityDto {
  entityId?: number;
  entityNumber?: string;
  entityName?: string;
  entityDba?: string;
  loanId?: number;
  loanNumber?: string;
  productCode?: string;
  renewalDate?: Date;
  optedIn?: boolean;
  creditLimit?: number;
  renewalApplicationId?: number;
  renewalApplicationUniqueId?: string;
  renewalApplicationStage?: ApplicationStage;
}

export interface OptInResponseDto {
  loanId?: number;
  optedIn?: boolean;
  renewalApplicationId?: number;
  renewalApplicationUniqueId?: number;
  renewalApplicationStage?: ApplicationStage;
}

export interface LoanData {
  active?: boolean;
  advisorId?: number;
  advisorFirstName?: string;
  advisorLastName?: string;
  advisorName?: string;
  advisorEmail?: string;
  advisorPhone?: string;
  advisorAvatar?: string;
  advisorCalendarLinkUrl?: string;
  applicationNumber?: string;
  entityAddress?: {
    city?: string;
    line1?: string;
    line2?: string;
    state?: string;
    zip?: string;
  };
  entityId?: number;
  entityDBA?: string;
  entityEIN?: string;
  entityName?: string;
  entityPhone?: string;
  id?: number;
  loanNumber?: string;
  offer?: LoanOffer;
  owners?: CustomerLoan[];
  status?: number;
  frozenUntil?: Date;
  freezeForDays?: number;
  uniqueId?: string;
  productCode?: string;
  productName?: string;
  renewalDate?: Date;
  renewedBy?: string;
  renewalFor?: string;
  optInForRenewal?: boolean;
  renewalApplicationId?: number;
  renewalOffer?: LoanOffer;
  renewalOfferAvailableToSign?: boolean;
  offerBundles?: OfferbundleStatus[];
  paymentBankAccountData?: BankAccount;
  disbursementBankAccountData?: BankAccount;
  externalId?: string;
  description?: string;
  policyNumber?: string;
  policyLimit?: number;
  allowDraw?: boolean;
  allowPayment?: boolean;
  noAutoPayments: boolean;
  drawDownFee?: number;
  drawDownFeeType?: number;
  repaymentTerm?: number;
  interestRate?: number;
  maintenanceFee?: number;
  maintenanceFeeType?: number;
  createdOn?: Date;
  loanInfo?: LoanInfoDto;
  favorite?: boolean;
}

export interface LoanQueryResult extends QueryResult<LoanData> { }

export interface LoanWithRenewalData extends LoanData {
  renewalForLoanNumber?: string;
  renewalForProductName?: string;
  renewedByLoanNumber?: string;
  renewedByProductName?: string;
}

export interface LoanOffer {
  id?: number;
  amount?: number;
  drawDownFee?: number;
  drawDownFeeType?: FeeType;
  interestRate?: number;
  maintenanceFee?: number;
  maintenanceFeeType?: FeeType;
  paymentFrequency?: PaymentFrequency;
  term?: number;
  renewalFee?: number;
  renewalFeeType?: FeeType;
  renewalFor?: string;
  productId?: number;
  productCode?: string;
  productName?: string;
  status?: OfferStatus;
  repaymentTerm?: number;
  originationFee?: number;
  originationFeeType?: FeeType;
}

export interface OfferBundle {
  applicationUniqueId?: string;
  applicationNumber?: string;
  offers?: LoanOffer[];
  status?: OfferbundleStatus;
  createdOn?: Date;
  statusUpdatedOn?: Date;
}

export interface OfferbundlesInfo {
  offerBundleId: number;
  selectedOffer: LoanOffer;
}

export enum OfferbundleStatus {
  Pending = "pending",
  Accepted = "accepted",
  Rejected = "rejected",
  Expired = "expired",
}

export interface LoanBankInfo {
  name?: string;
  routingNumber?: string;
  accountNumber?: string;
  types?: BankInfoType[];
}

export enum BankInfoType {
  Unknown = 0,
  Disbursement = 1,
  Payment = 2,
}

export enum ProductName {
  "Term Loan" = "Term",
  "Line of Credit" = "LOC",
  "Case Financing" = "Law",
}

export enum AccountOfferPaymentFrequencies {
  "Weekly" = 0,
  "Bi weekly" = 1,
  "Monthly" = 2,
}

export interface CustomerLoan {
  customerId: number;
  favorite: boolean;
}

export interface LoanFileTemplate {
  loanId: number;
  templates: LoanFileTemplateData[];
}