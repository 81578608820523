<mat-dialog-content>
  <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="20px" fxFlex="100">
    <span class="mat-title">Oops we need more info</span>
    <span>Provide us with the info below and we'll get you a quote</span>
    <ifc-datepicker [fc]="establishedDate" [label]="'Established date'" [max]="today" [disabled]="false"></ifc-datepicker>
  </div>
</mat-dialog-content>
<div mat-dialog-actions class="flex row end-center">
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button type="button" mat-button color="primary" (click)="submit()">SUBMIT</button>
</div>
