import { ViewChild, OnDestroy, Directive, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Subject, Subscription } from 'rxjs';
import { DocumentCategory, DocumentCategoryLabel, DocumentFilters, DocumentQueryResult, MessageService, FilterParameter } from 'common';
import { DocumentFilterService } from '../document-filter.service';
import { DocumentListFilterDialogComponent } from '../document-list-filter-dialog/document-list-filter-dialog.component';
import { DocumentBaseService } from 'common/lib/documents/document-base.service';

@Directive()
export abstract class DocumentListBaseComponent<T extends DocumentBaseService> implements OnInit, OnDestroy {

  _unsubscribeAll: Subject<any>;
  data: DocumentListComponentData;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = [
    'createdOn',
    'filename',
    'category',
    'actions'
  ];

  filters: DocumentFilters;
  activeFilters: FilterParameter[] = [];
  hidePageSize: boolean;
  protected deleteSub: Subscription;

  constructor(
    protected route: ActivatedRoute,
    protected _documentService: T,
    protected _documentFilterService: DocumentFilterService<T>,
    protected dialog: MatDialog,
    breakpointObserver: BreakpointObserver,
    private messageService: MessageService
  ) {
    this._unsubscribeAll = new Subject();

    breakpointObserver.observe([
      Breakpoints.XSmall
    ]).subscribe(result => {
      if (result.matches)
        this.hidePageSize = true;
      else
        this.hidePageSize = false;
    });
  }

  ngOnInit(): void{
    this.filters = new DocumentFilters(this.queryParams);
    this.activeFilters = this._documentFilterService.addFilterParameters(this.queryParams);
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  activeFilterRemoved(activefilters: FilterParameter[]): void {
    if (!activefilters.length) return;
    activefilters.forEach(item => {
      this.filters.remove(item.id);
      this.filter();
    });
  }

  sortData(sort: Sort) {
    this.filter();
  }

  getCategory(category: DocumentCategory) {
    return DocumentCategoryLabel.get(category);
  }

  filterDialog() {
    DocumentListFilterDialogComponent.show(this.dialog, new DocumentFilters(this.queryParams), this.data.documentQueryResult.values)
      .subscribe(result => {
        if (result) {
          this.filters = result;
          this.paginator?.firstPage();
          this.filter();
        }
      });
  }

  deleteSubClear() {
    if(this.deleteSub)
      this.deleteSub.unsubscribe();
    this.deleteSub = null;
  }

  deleteErrorHandler(error: any) {
    this.deleteSubClear();
    this.messageService.error(error);
  }

  protected abstract deleteSuccessHandler();

  protected abstract get pageSize();

  protected abstract get queryParams();

  protected abstract setPage(event: PageEvent): void;

  protected abstract filter(): void;

  protected abstract get canUpload(): boolean;

  protected abstract upload(): void;

  protected abstract download(fileId: number, id: number): void;

  protected abstract delete(fileId: number, filename: string) : void;

}

export interface DocumentListComponentData {
  documentQueryResult: DocumentQueryResult;
}
