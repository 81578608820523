import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { LoanData } from "../domain/models/loan.model";
import { AppSettings } from '../../../../src/app.settings';

@Component({
  selector: 'ifp-loan-funded-dialog',
  templateUrl: './loan-funded-dialog.component.html',
  styleUrls: ['./loan-funded-dialog.component.scss']
})
export class LoanFundedDialogComponent {

  contactPhone: string;

  constructor(public dialogRef: MatDialogRef<LoanFundedDialogComponent>,
    public settings: AppSettings,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.contactPhone = this.settings.if.contact.phone;
  }

  static show(dialog: MatDialog, loan: LoanData, bankIntegrationRequired: boolean, disbursedAmount: number): Observable<any> {
    return dialog.open(LoanFundedDialogComponent, {
      width: '510px',
      data: {
        loan: loan,
        bankIntegrationRequired: bankIntegrationRequired,
        disbursedAmount: disbursedAmount
      },
      closeOnNavigation: true,
    }).afterClosed();
  }
}
