import { AppBarCloseAction } from "common";


export function appPageComponentDataDetail() {
    return {
        appBar: {
            closeAction: AppBarCloseAction.BACK,
            expandable: false,
            logoHidden: true,
        },
        appBarActions: {
            menuMobile: true
        },
        appPage: {
            contentClass: 'simple',
            contentAlignment: 'center-center',
            contentWithoutPadding: true
        }
    };
}