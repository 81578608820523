<div id="ifc-co-branding-logo">
</div>

<a [title]="title" [attr.href]="rootSiteUrl">
  <svg *ngIf="!logo" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 340 103.1" style="enable-background:new 0 0 340 103.1;" xml:space="preserve">
    <g [ngClass]="['svg-logo-text', (logoClass || '')]">
      <g>
        <rect x="185.4" y="89.3" class="logo0" width="3" height="13.5"></rect>
        <polygon class="logo0" points="212,102.7 212,89.3 209,89.3 209,93.8 209.1,98 202.4,89.3 199.8,89.3 199.8,102.7 202.8,102.7     202.8,98.5 202.7,94.1 209.4,102.7   "></polygon>
        <path class="logo0" d="M182.8,32.2c0-4.2-3.2-6.5-6.7-6.5c-3.3,0-6.5,2.2-6.5,6.3c0,4,3.2,6.3,6.7,6.4    C179.6,38.4,182.8,36.1,182.8,32.2z"></path>
        <path class="logo0" d="M210.1,41.7c-11.2,0-20.1,6.8-20.1,20.8c0,13.4,8,20.5,19.3,20.5c5.9,0,10-2,13.3-5.9l1.6,5.2h14.8v-10h-5.4    V26.2h-16.8v9.6h5.5v10.8C219.4,42.9,214.6,41.7,210.1,41.7z M211.3,72.6c-5.6,0-10-3.6-10-10.2c0-7.5,5-10.6,10.4-10.6    c3.9,0,10.2,1.7,10.2,10.5C221.9,69.7,216.4,72.6,211.3,72.6z"></path>
        <path class="logo0" d="M221.8,102.7h3.2l1-2.3h6.9l1,2.3h3.2l-6-13.5h-3.3L221.8,102.7z M231.8,97.8h-4.6l2.3-5.5L231.8,97.8z"></path>
        <polygon class="logo0" points="165,102.7 168,102.7 168,97.9 174.4,97.9 174.4,95.2 168,95.2 168,92 174.8,92 174.8,89.3 165,89.3       "></polygon>
        <polygon class="logo0" points="165.3,72.6 165.3,82.3 186.2,82.3 186.2,72.6 181.6,72.6 181.6,42.5 165.3,42.5 165.3,52.1     170.3,52.1 170.3,72.6   "></polygon>
        <polygon class="logo0" points="259.1,102.7 259.1,89.3 256.1,89.3 256.1,93.8 256.3,98 249.5,89.3 247,89.3 247,102.7 250,102.7     250,98.5 249.9,94.1 256.6,102.7   "></polygon>
        <path class="logo0" d="M335.6,52.5v-10h-14.6l-0.5,4.1c-3.7-4.4-8.3-5.1-13.7-5.1c-11.6,0.6-18.7,10.7-18.7,21.4    c0,13,7.4,20.4,18.3,20.4c4.9,0,9.7-1,14.1-5.5l0.7,4.5h14.7v-9.7h-5.2V52.5H335.6z M309.3,73.6c-6.4,0-10.4-4.5-10.4-10.6    c0-6,4.4-10.7,10.9-10.7c7,0,10.6,4.3,10.5,10.6C320.3,68.9,316.1,73.6,309.3,73.6z"></path>
        <path class="logo0" d="M305.1,102.7h3.2l1-2.3h6.9l1,2.3h3.2l-6-13.5h-3.3L305.1,102.7z M315.1,97.8h-4.6l2.3-5.5L315.1,97.8z"></path>
        <path class="logo0" d="M277,91.8c1.1,0,2.2,0.3,3.1,1.1l1.9-1.9c-1.4-1.4-3.1-2.1-4.9-2.1c-4.9,0-7.2,3.7-7.2,7.1c0,3.7,2.1,7,7.2,7    c2,0,3.8-0.6,5.2-2l-1.9-2.1c-0.8,0.8-2.2,1.3-3.3,1.3c-3.2,0-4.2-2.2-4.2-4.2C272.7,94.1,273.9,91.8,277,91.8z"></path>
        <rect x="292.3" y="89.3" class="logo0" width="3" height="13.5"></rect>
        <polygon class="logo0" points="333.3,89.3 330.3,89.3 330.3,102.7 340,102.7 340,100 333.3,100   "></polygon>
        <path class="logo0" d="M263.5,41.4c-13.1,0-21.4,8.3-21.4,20.7c0,11.9,6.5,21.3,22,21.3c5.9,0,13-2,17.3-6.3l-6.6-7.3    c-2.7,2.2-7.3,3.5-10.5,3.5c-6.7,0-10.3-3-10.8-7h29.3C285.1,48.4,276.7,41.4,263.5,41.4z M253.4,57.9c1-4.7,5.3-6.9,10.2-6.9    c5,0,9.1,2,8.9,6.9H253.4z"></path>
      </g>
    </g>
    <g [ngClass]="['svg-logo-icon', logoClass]">
      <g>
        <path class="logo1" d="M131.9,49.9c-0.5-1.7-1.4-3.5-1.2-5.6c6.5,4.2,13,6.5,21.6,4.1c-1.5-0.7-1.9-1-2.3-1.1    c-10.3-1.1-17.2-7.2-21.9-15.8c-1.9-3.4-3.6-5.2-7.7-5.7c-8.9-1.2-24.3-5.9-32.2-10c-8.7-4.6-17-10.3-26.3-13.7    C51.8-1.6,42.1-0.2,33,5c-1.9,1.1-4.4,2.5-3.7,5c0.7,2.4,3.5,1.7,5.5,1.7c5.7,0,11.4,0.1,16.7,2.5c1.9,0.9,2.6,0.5,3.8-1    c3.9-4.6,8.8-5.9,13.5-3.9c2.1,0.9,3,1.7,0.5,3.3c-3.6,2.4-7.1,5-10.6,7.5c-0.9,0.6-1.9,1.1-2,2.4C56.1,34.7,66.7,44,75.9,42.2    c5.5-1.1,10.2-3.6,14.3-7.3c1.3,1.9,1.1,3.9,1.4,5.9c2.7,19-7.7,36-25.2,40.9c-2.2,0.6-4.6,1-6.9,1.3c-2.3,0.3-3.7,1.1-4,2.7    c0,0,0,0,0,0l0,0.1c-0.1,0.7-0.1,1.4,0.1,2.4c0.2,1,0.4,2,0.6,3l1.5,11.1l5.2-0.1c0.2,0,0.4,0,0.7,0c8.5,0.1,17.1,0.1,25.6,0    c0.1,0,0.2,0,0.3,0c1.4-0.1,5.9-0.1,5.9-0.1l-3.1-14.4c18.9-1,21.5,9.1,25.5,13.4l0.4,1.1l4.4,0.1c0.6,0.1,1.2,0.1,1.8,0.1    c0.7,0,1.5,0,2.2,0l1.3,0l0,0c3.8,0,7.6,0,11.4,0l0,0h9.1L136.8,66C135.1,60.6,133.5,55.2,131.9,49.9z"></path>
        <path class="logo1" d="M36.8,80.9C23.1,74.5,17,62.7,19,47.7c0.8-6.1,1.9-12.2,1.6-18.4c-0.5-13-8.9-18.5-20.6-12.9    c4.4,3.3,8.4,6.4,12.5,9.3c1.6,1.1,2,2.2,1.7,4.3C12.1,45.3,9.8,60.7,8,76.2C7.3,82,6.6,87.9,6.1,93.8c0,0.3,0,0.5,0,0.8l-0.7,7.1    l6.6,0.2c0.2,0,0.4,0.1,0.6,0.1c0.5,0,0.9,0,1.4,0l1.6,0.1l0,0c5.5,0.1,10.9,0.1,16.4,0l0,0.1l7.6-0.4l1.1-10.4l-0.1,0.1    c0.1-1,0.2-2,0.5-3C41.7,84.6,40.3,82.6,36.8,80.9z"></path>
        <path class="logo1" d="M76.1,68.7c0.5,0.6,1,1.2,1.8,0.8c0.6-0.3,0.5-1,0.5-1.9c-0.3-1.5-1.2-2.9-2.9-3.6c-1.2-0.5-2.7-0.7-3.3,0.4    c-1,1.6,1.1,1.3,1.7,2C74.6,67.1,75.4,67.8,76.1,68.7z"></path>
        <path class="logo1" d="M40.3,49.3c-1.9-0.1-3.6,0.4-4.3,1.6c-3.1,5.1,2,12.3,8.6,12.7c2.8,0.1,4.8-0.6,4.6-3.4    C49.2,56.1,46.3,49.7,40.3,49.3z"></path>
      </g>
    </g>
  </svg>
  <img *ngIf="logo" [src]="logo">
</a>