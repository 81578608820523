import { CurrencyPipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import {
  AppBarAction,
  AppBarActionsService,
  AppBarTitleService,
  AppPageService,
  MessageService,
} from "common";
import { Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { BankAccountService } from "../../../bank-account/bank-account.service";
import { BankingService } from "../../../bank-account/banking.service";
import { LoanFacade } from "../../../loan/domain/+state/loan.facade";
import { ProductCode } from "../../../loan/domain/models/loan.model";
import {
  PromotionInfoData,
  PromotionService,
} from "../../../promotions/promotion.service";
import { ConfirmDialogComponent } from "../../../shared/confirm-dialog/confirm-dialog.component";
import { AccountTransferSubmittedDialogComponent } from "../account-transfer-submitted-dialog/account-transfer-submitted-dialog.component";
import { AccountTransferComponentData } from "../account-transfer.model";
import { AccountTransferService } from "../account-transfer.service";

@UntilDestroy()
@Component({
  selector: "ifp-account-transfer-draw",
  templateUrl: "./account-transfer-draw.component.html",
  styleUrls: ["./account-transfer-draw.component.scss"],
})
export class AccountTransferDrawComponent implements OnInit {
  form: UntypedFormGroup;
  data: AccountTransferComponentData;
  loanId: number;
  confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  bankIntegrationRequired: boolean;
  promotionInfo: PromotionInfoData;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private dialog: MatDialog,
    private appBarTitleService: AppBarTitleService,
    private bankingService: BankingService,
    private bankAccountService: BankAccountService,
    private formBuilder: UntypedFormBuilder,
    private loanFacade: LoanFacade,
    private _accountTransferService: AccountTransferService,
    private _appBarActionsService: AppBarActionsService,
    private _currencyPipe: CurrencyPipe,
    private _promotionService: PromotionService,
    private appPageService: AppPageService
  ) {}

  get isLcf(): boolean {
    return this.data?.loan?.productCode == ProductCode.Law;
  }

  get continueDisabled(): boolean {
    return (
      this.form.invalid ||
      (this.isLcf && this.form.controls.lcfAcknowledgement.value !== true)
    );
  }

  ngOnInit() {
    this.route.data
      .pipe(untilDestroyed(this))
      .subscribe((data: AccountTransferComponentData) => {
        this.data = data;
        this.forminit();
        this._accountTransferService.onAccountTransferChanged.next(this.data);
      });

    this.appBarTitleService.title = "Make a draw";

    this.form.controls.drawAmount.valueChanges
      .pipe(untilDestroyed(this), debounceTime(500), distinctUntilChanged())
      .subscribe((value) => {
        this.data.accountTransfer.amountInput = value;
        this.accountNewBalancesInit();
      });

    this.form.controls.note.valueChanges
      .pipe(untilDestroyed(this), distinctUntilChanged())
      .subscribe((value) => {
        this.data.accountTransfer.note = value;
      });

    this.form.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => this.enableSaveAction());

    this._appBarActionsService.invoking
      .pipe(untilDestroyed(this))
      .subscribe(this.actionDispatch.bind(this));

    this.bankAccountService
      .getIsBankLinkReminderRequired(this.data.loan.entityId)
      .subscribe(
        (isBankLinkReminderRequired) =>
          (this.bankIntegrationRequired = isBankLinkReminderRequired && this.data.loan?.productCode !== ProductCode.Law)
      );

    this._promotionService.onPromotionApplied
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.promotionInfo = res;
        if (this.data.accountTransfer.amountInput)
          this.accountNewBalancesInit();
      });
  }

  forminit(): void {
    this.form = this.formBuilder.group({
      drawAmount: [
        undefined,
        [
          Validators.required,
          Validators.min(this.data.accountTransfer.amountMinimum),
          Validators.max(this.data.accountTransfer.amountMaximum),
        ],
      ],
      note: undefined,
      lcfAcknowledgement: [""],
    });

    this.loanId = this.data.loan.id;
    this.setAppBarActions();
  }

  actionDispatch(action: AppBarAction) {
    const actionHandler: (action: AppBarAction) => void =
      this[action.id].bind(this);
    actionHandler(action);
  }

  private setAppBarActions() {
    this._appBarActionsService.actions = [];
    this._appBarActionsService.actions.push({
      id: "cancel",
      label: "Cancel",
      buttonType: "button",
    });
    this._appBarActionsService.actions.push({
      id: "save",
      label: "Continue",
      disabled: !this.form.dirty,
      buttonType: "submit",
      buttonAppearance: "flat",
      buttonColor: "primary",
    });
  }

  enableSaveAction(): void {
    if (!this.continueDisabled) this._appBarActionsService.enable("save", true);
    else this._appBarActionsService.enable("save", false);
  }

  showConfirmationDialog(): void {
    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "510px",
      disableClose: true,
    });
    this.confirmDialogRef.componentInstance.title = "Confirm draw?";
    this.confirmDialogRef.componentInstance.message = `You are making a draw in the amount of ${this._currencyPipe.transform(
      this.data.accountTransfer.amountInput
    )}.`;
  }

  save() {
    this.showConfirmationDialog();
    this.confirmDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.submitSub = this.data.accountTransfer
          .submit()
          .subscribe(
            this.submitSuccess.bind(this),
            this.submitError.bind(this)
          );
      }
    });
  }

  submitError(error) {
    this.messageService.error(error);
    this.submitSubClear();
  }

  submitSub: Subscription;

  submitSubClear() {
    if (this.submitSub) this.submitSub.unsubscribe();
    this.submitSub = null;
  }

  submitSuccess(result) {
    const title = "Draw request submitted";
    let closeButtonLabel;
    if (this.bankIntegrationRequired) {
      closeButtonLabel = "Link your bank";
      this.bankingService.integrationReminded(this.data.loan.entityId);
    }

    this.loanFacade.refreshLoan(this.loanId).subscribe();

    AccountTransferSubmittedDialogComponent.show(
      this.dialog,
      this.data.accountTransfer,
      title,
      "draw",
      this.bankIntegrationRequired,
      closeButtonLabel
    )
    .subscribe(() => {
      if (this.bankIntegrationRequired && !this.appPageService.isBackAvailable()) 
        this.router.navigate(["/bank-link-reminder"]);
      else 
        this.cancel();
    });
  }

  setAmount(value: number): void {
    this.form.controls.drawAmount.setValue(value);
  }

  accountNewBalancesInit(): void {
    const value = this.data.accountTransfer.amountInput
      ? parseFloat(this.data.accountTransfer.amountInput)
      : 0;

    this.loanFacade
      .drawPreview(this.data.loan.id, value, this.promotionInfo?.promoCode)
      .subscribe((res) => {
        this.data.accountTransfer.balanceAfter.outstandingBalance =
          res.newOutstandingBalance;
        this.data.accountTransfer.balanceAfter.availableBalance =
          res.newAvailableFunds;
        this.data.accountTransfer.balanceAfter.upcomingPaymentAmount =
          res.newUpcomingPaymentAmount;
        this.data.accountTransfer.balanceAfter.upcomingPaymentDate =
          res.newUpcomingPaymentDate;
        this.data.accountTransfer.fee = res.fee;
        this.data.accountTransfer.thirdPartyPayoffAmount =
          res.thirdPartyPayoffAmount;
        this.data.accountTransfer.disbursedAmount = res.disbursedAmount;
        this.data.accountTransfer.promotionInfo = res.promotionInfo;
        this._accountTransferService.onAccountTransferChanged.next(this.data);
      });
  }

  cancel(): void {
    const result = this.appPageService.backToIfPossible();
    if (result) {
      return;
    }

    if (this.data.redirectedFromAgreement) {
      this.router.navigate(["dashboard"]);
    }

    this.appPageService.back();
  }
}
