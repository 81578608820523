import { Component, Input, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { EntityDocumentsService, EntityRecentDocuments } from "common";

@Component({
  selector: "ifp-recent-documents-widget",
  templateUrl: "./recent-documents-widget.component.html",
  styleUrls: ["./recent-documents-widget.component.scss"],
})
export class RecentDocumentsWidgetComponent implements OnInit {
  @Input() recentDocuments: EntityRecentDocuments[];

  dataSource = new MatTableDataSource<EntityRecentDocuments>();
  displayedColumns: string[] = ["icon", "date", "name", "category", "download"];

  constructor(private entityDocumentsService: EntityDocumentsService) {}

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.recentDocuments);
  }

  onClickDownload(fileId: number) {
    this.entityDocumentsService.download(fileId);
  }
}
