<div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="8px">
  <button mat-raised-button color="primary" *ngIf="drawEnabled" [disabled]="!drawEnabled"
    [fxHide.lt-sm]="showAvailableFunds" (click)="draw()">
    <span>DRAW FUNDS</span>
  </button>
  <button mat-raised-button class="color-primary" *ngIf="payEnabled" [disabled]="!payEnabled"
    [fxHide.lt-sm]="showAvailableFunds" (click)="pay()">
    <span>PAY BALANCE</span>
  </button>
</div>
