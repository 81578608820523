import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { OidcService } from "../oidc/oidc.service";

@Injectable({
  providedIn: "root",
})
export class SignInGuard implements CanActivate {
  constructor(private oauthService: OidcService, private router: Router) {}

  async canActivate(
    _next: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Promise<boolean> {
    if (this.oauthService.authenticated) {
      this.router.navigate(["dashboard"], { queryParams: _next.queryParams });
      return false;
    }

    this.oauthService.saveNavigationHistory();
    this.oauthService.authorize();
    return false;
  }
}
