import { NgModule } from "@angular/core";
import { OfferCommonModule } from "../../common/offer-common.module";
import { OfferLawDetailsContentComponent } from "./components/offer-law-details-content/offer-law-details-content.component";
import { OfferLocDetailsContentComponent } from "./components/offer-loc-details-content/offer-loc-details-content.component";
import { OfferTermDetailsContentComponent } from "./components/offer-term-details-content/offer-term-details-content.component";
import { OfferDetailsComponent } from "./offer-details.component";

@NgModule({
  imports: [OfferCommonModule],
  declarations: [
    OfferDetailsComponent,
    OfferLawDetailsContentComponent,
    OfferLocDetailsContentComponent,
    OfferTermDetailsContentComponent,
  ],
  exports: [OfferDetailsComponent],
})
export class OfferDetailsModule {}
