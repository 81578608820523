import { Component, Input, Output, EventEmitter } from '@angular/core';
import moment from 'moment';
import { UntypedFormControl } from '@angular/forms';
import { FilterRange } from '../../../filter/filter.data';

@Component({
  selector: 'ifc-filter-param-date-range-input',
  templateUrl: './filter-param-date-range-input.component.html',
  styleUrls: ['./filter-param-date-range-input.component.css']
})
export class FilterParamDateRangeInputComponent {

  @Input()
  label: string;

  @Input()
  fc: UntypedFormControl;

  @Output()
  rangeSelected = new EventEmitter<any>();

  dateRange: FilterRange<moment.Moment> = {};

  constructor() { }

  setDateRange(value: string) {
    switch (value) {
      case 'today':
        this.dateRange.from = moment();
        this.dateRange.to = moment();
        break;

      case 'week':
        this.dateRange.from = moment().subtract(7, 'days');
        this.dateRange.to = moment();
        break;

      case 'month':
        this.dateRange.from = moment().subtract(1, 'months');
        this.dateRange.to = moment();
        break;

      default:
        this.dateRange.from = undefined;
        this.dateRange.to = undefined;
        break;
    }

    this.rangeSelected.emit(this.dateRange);
  }

}
