import { Component, OnInit, Input } from "@angular/core";
import {
  CaseFinancingLoanData,
  LoanData,
  ProductCode,
} from "../../loan/domain/models/loan.model";
import { Router } from "@angular/router";
import {
  CustomerData,
  KBAVerificationStatus,
} from "../../customer/domain/models/customer.model";
import * as _ from "lodash";
import { ApplicationData } from "../../application/application.model";
import { LoansSelectDialogComponent } from "../../shared/loans-select-dialog/loans-select-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { LinkingStatus, LoanHelper, LoanStatus, NeedsAttentionHelper, NeedsAttentionModel } from "common";
import { BankingService } from "../../bank-account/banking.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "ifp-dashboard-account-action-buttons",
  templateUrl: "./account-action-buttons.component.html",
  styleUrls: ["./account-action-buttons.component.scss"],
})
export class DashboardAccountActionButtonsComponent implements OnInit {
  @Input() caseFinancingLoans: CaseFinancingLoanData[];
  @Input() otherLoans: LoanData[];
  @Input() applications: ApplicationData[];
  @Input() currentCustomer: CustomerData;
  @Input() isBankVerified: boolean;
  @Input() needsAttentionModel: NeedsAttentionModel;

  drawEnabled: boolean;
  payEnabled: boolean;
  productCodes = ProductCode;
  pendingCustomer: boolean;
  kbaVerified: boolean;
  hasCurrentLoanPending: boolean;
  hasAvailableRenewalToSign: boolean;
  showAvailableFunds: boolean;
  showLinkBankButton: boolean;
  private allLoans: LoanData[];

  constructor(
    private router: Router,
    private bankingService: BankingService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.initData();

    this.bankingService.obsSync.pipe(untilDestroyed(this)).subscribe(() => {
      this.showLinkBankButton = false;
    });

    this.bankingService.obsInstitutionLinkStatus
      .pipe(untilDestroyed(this))
      .subscribe((res) => (this.showLinkBankButton = !res));
  }

  initData() {
    this.allLoans = [...this.otherLoans, ...this.caseFinancingLoans];
    this.drawEnabled = this.isDrawEnabled();
    this.payEnabled = _.some(this.allLoans, (loan) =>
      LoanHelper.hasPayAccess(loan)
    );
    this.showLinkBankButton = _.some(
      this.applications,
      (app) => app.bankLinkStatus !== LinkingStatus.Linked
    );
    this.kbaVerified =
      this.currentCustomer &&
      this.currentCustomer.kbaStatus === KBAVerificationStatus.Success;

    if (this.kbaVerified) {
      this.pendingCustomer =
        this.currentCustomer?.pendingLoans &&
        _.some(this.allLoans, (loan) =>
          this.currentCustomer.pendingLoans.includes(loan)
        );
      this.hasCurrentLoanPending = _.some(
        this.allLoans,
        (loan) => loan.status === LoanStatus.Pending
      );
    }
  }

  private isDrawEnabled() {
    const lcfResult = _.some(
      this.caseFinancingLoans,
      (loan: CaseFinancingLoanData) =>
        LoanHelper.hasDrawAccess(loan) &&
        !loan.liquidityEventData?.resolutionStatus
    );

    const otherLoansResult = _.some(this.otherLoans, (loan) =>
      LoanHelper.hasDrawAccess(loan)
    );

    return lcfResult || otherLoansResult;
  }

  draw() {
    const drawAllowedLcf = _.filter(
      this.caseFinancingLoans,
      (loan: CaseFinancingLoanData) =>
        LoanHelper.hasDrawAccess(loan) &&
        !loan.liquidityEventData?.resolutionStatus
    );

    const drawAllowedOtherLoans = _.filter(this.otherLoans, (loan) =>
      LoanHelper.hasDrawAccess(loan)
    );

    const drawAllowedLoans = [...drawAllowedOtherLoans, ...drawAllowedLcf];
    LoansSelectDialogComponent.show(
      this.dialog,
      "Draw funds",
      drawAllowedLoans,
      this.needsAttentionModel
    ).subscribe((loan) => {
      if (loan) {
        if(this.needsAttentionModel?.isApplicable) {
          NeedsAttentionHelper.openNeedsAttentionDialog(this.dialog, this.needsAttentionModel.messageInput, loan.productCode);
          return;
        }
        this.router.navigate([`/account/${loan.id}/transfer/draw`]);
      }
    });
  }

  pay() {
    const payAllowed = _.filter(this.allLoans, (loan) =>
      LoanHelper.hasPayAccess(loan)
    );
    LoansSelectDialogComponent.show(
      this.dialog,
      "Pay balance",
      payAllowed
    ).subscribe((loan) => {
      if (loan) {
        this.router.navigate([`/account/${loan.id}/transfer/payment`]);
      }
    });
  }
}
