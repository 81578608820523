import { Component, Input } from "@angular/core";
import { ChartConfiguration } from "chart.js";

@Component({
  selector: "ifc-bar-chart",
  templateUrl: "./bar-chart.component.html",
})
export class BarChartComponent {
  @Input()
  data: ChartConfiguration<"bar">["data"] = null;

  @Input()
  options: ChartConfiguration<"bar">["options"] = null;
}
