import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from "@angular/core";

@Component({
  selector: "ifc-mfa-action-needed",
  templateUrl: "./mfa-action-needed.component.html",
  styleUrls: ["./mfa-action-needed.component.scss"],
})
export class MfaActionNeededComponent {
  @ContentChild("description", { static: true, read: TemplateRef })
  descriptionRef: TemplateRef<unknown>;

  @Input()
  actionLabel: string;

  @Output()
  action = new EventEmitter<void>();

  onClickAction() {
    this.action.emit();
  }
}
