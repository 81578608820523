import { Injectable } from "@angular/core";
import { OfferBundleCheckoutRequirement } from "common";
import { UppyFile } from "@uppy/core";
import { CheckoutRequirementFileUploadModel } from "../../model/offer-documents.model";

@Injectable()
export class OfferCheckoutRequirementListService {
  public onCheckoutRequirementChanged(
    checkoutRequirement: OfferBundleCheckoutRequirement,
    filesToUpload: UppyFile[],
    checkoutRequirementsFiles: CheckoutRequirementFileUploadModel[]
  ) {
    this.updateCheckoutRequirementsFiles(
      checkoutRequirement,
      filesToUpload,
      checkoutRequirementsFiles
    );
    this.clearOutEmptyCheckoutRequirementsFiles(checkoutRequirementsFiles);
  }

  private updateCheckoutRequirementsFiles(
    checkoutRequirement: OfferBundleCheckoutRequirement,
    filesToUpload: UppyFile[],
    checkoutRequirementsFiles: CheckoutRequirementFileUploadModel[]
  ): void {
    if (!checkoutRequirement) {
      return;
    }

    const found = checkoutRequirementsFiles.find(
      (c) => c.checkoutRequirement.id === checkoutRequirement.id
    );
    if (found) {
      found.filesToUpload = filesToUpload;
    } else {
      checkoutRequirementsFiles.push({
        checkoutRequirement,
        filesToUpload,
      } as CheckoutRequirementFileUploadModel);
    }
  }

  private clearOutEmptyCheckoutRequirementsFiles(
    checkoutRequirementsFiles: CheckoutRequirementFileUploadModel[]
  ) {
    const noFileCheckouts = checkoutRequirementsFiles.filter(
      (c) => !c.filesToUpload || c.filesToUpload.length === 0
    );
    noFileCheckouts.forEach((noFileCheckout) => {
      checkoutRequirementsFiles.splice(
        checkoutRequirementsFiles.indexOf(noFileCheckout),
        1
      );
    });
  }
}
