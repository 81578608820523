import { NgModule } from '@angular/core';
import { CommonModule } from 'common';
import { AccountBalanceSummaryComponent } from './account-balance-summary/account-balance-summary.component';

@NgModule({
  imports: [
    CommonModule
  ],
  exports: [
    AccountBalanceSummaryComponent
  ],
  declarations: [AccountBalanceSummaryComponent]
})
export class AccountBalanceModule { }
