import { AfterViewInit, Directive, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import {
  AppBarTitleService,
  AppBarActionsService,
  AppBarAction,
  DocumentCategory,
  EnumHelper,
  DocumentCategoryLabel,
} from "common";
import { takeUntil } from "rxjs/operators";
import { Uppy } from "@uppy/core";
import { DashboardOptions } from "@uppy/dashboard";
import { updateUppyEndpoint } from "./document-upload-uppy-config";

@Directive()
export abstract class DocumentUploadBaseComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  _unsubscribeAll: Subject<any> = new Subject();

  valid: boolean = false;
  categories = EnumHelper.getMappedNamesAndValues(
    DocumentCategory,
    DocumentCategoryLabel
  );
  selectedCategory: DocumentCategory;
  submittingValue: boolean = false;

  uppy: Uppy;
  uppyDashboardOptions: DashboardOptions;

  constructor(
    private appBarTitleService: AppBarTitleService,
    protected appBarActionsService: AppBarActionsService
  ) {
    this.appBarActionsService.actions = [
      { id: "cancel", label: "Cancel", buttonType: "button" },
      {
        id: "submit",
        label: "Submit",
        disabled: this.valid,
        buttonType: "submit",
        buttonAppearance: "flat",
        buttonColor: "primary",
      },
    ];
    this.appBarTitleService.title = "Upload files";
  }

  set submitting(value: boolean) {
    if (this.submittingValue !== value) {
      this.submittingValue = value;
      this.appBarRefresh();
    }
  }

  get submitting(): boolean {
    return this.submittingValue;
  }

  ngOnInit() {
    this.appBarActionsService.invoking
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.actionDispatch.bind(this));
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  ngAfterViewInit(): void {
    this.uppy.on("file-added", () => {
      this.appBarRefresh();
    });
    this.uppy.on("file-removed", () => {
      this.appBarRefresh();
    });
    this.uppy.on("complete", (result) => {
      this.onUploadResult(result);
    });
  }

  onCategoryChange(): void {
    this.updateUppyEndpoint();
    this.appBarRefresh();
  }

  submit(): void {
    if (this.submitting) {
      return;
    }

    this.submitting = true;
    this.uppy.getState()?.error ? this.uppy.retryAll() : this.uppy.upload();
  }

  onUploadResult(result: any) {
    this.submitting = false;
  }

  protected abstract cancel(): void;

  protected abstract setupUppy(): void;
  protected abstract updateUppyEndpoint();

  protected updateUppyEndpointBase(endpoint: string) {
    updateUppyEndpoint(this.uppy, endpoint);
  }

  protected actionDispatch(action: AppBarAction) {
    const actionHandler: (action: AppBarAction) => void =
      this[action.id].bind(this);
    actionHandler(action);
  }

  protected appBarRefresh(): void {
    const files = this.uppy?.getFiles();
    this.valid =
      this.selectedCategory &&
      files != null &&
      files.some((f) => !f.progress.uploadComplete) &&
      !this.submitting;
    this.appBarActionsService.enable("submit", this.valid);
  }
}
