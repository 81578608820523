<div [ngClass]="'layout fill position-relative ' + (data.appPage && data.appBar?.closeAction ? 'app-bar-closable' : '')">
  <div [ngClass]="'app-bar-background ' + (data.appBar?.backgroundClass || '')">
  </div>

  <div class="app-bar-background-wrapper">
  </div>

  <div [ngClass]="'flex column layout fill ' + (data.appBar?.containerWrapperClass ?? '')">
    <div class="app-bar-container flex row start-center" [ngClass.xs]="(data.appBar && data.appBar.containerClass ? data.appBar.containerClass : '') + ' padding-xs'" [ngClass.gt-xs]="(data.appBar && data.appBar.containerClass ? data.appBar.containerClass : '') + ' padding'">
      <button *ngIf="data.appPage && data.appPage.sidenavEnabled && !appPageSidenavOpened" mat-icon-button type="button" ngClass.gt-md="app-page-sidenav-open-button" (click)="appPageSidenavOpen()">
        <mat-icon class="app-page-sidenav-open-icon">{{data.appBar?.sidenavOpenIcon ?? 'menu'}}</mat-icon>
      </button>

      <button *ngIf="(data?.appBar?.closeAction && !data?.appBar?.isSidenavAppBar) || (data?.appBar?.isSidenavAppBar && appPageSidenavOpened)" mat-icon-button type="button" (click)="close()" ngClass.gt-md="app-bar-close-button">
        <mat-icon class="secondary-text">{{data.appBar?.closeIcon ?? 'close'}}</mat-icon>
      </button>

      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap.lt-sm="12px" fxLayoutGap.gt-xs="24px">
        <ifc-app-logo [fxHide.lt-sm]="data.appBar?.logoIcon" *ngIf="!data.appBar?.logoHidden && (!data.appPage || !data.appPage.sidenavEnabled)" class="layout logo height" [logoClass]="data.appBar?.logoClass" [showDefaultLogo]="data.appBar?.showDefaultLogo" [ngClass]="data.appBar?.logoSize">
        </ifc-app-logo>
        <ifc-app-logo-icon fxHide.gt-xs *ngIf="!data.appBar?.logoHidden && (!data.appPage || !data.appPage.sidenavEnabled) && data.appBar?.logoIcon" class="layout logo height" [logoClass]="'colored'" class="logo-xs">
        </ifc-app-logo-icon>
        <router-outlet name="selector"></router-outlet>
      </div>

      <div [ngClass]="{'app-bar-title-container': true, 'app-bar-title-container--hidden-collapsed': data.appBar?.titleHiddenCollapsed}">
        <router-outlet name="title"></router-outlet>
      </div>

      <div class="flex fill"></div>

      <router-outlet name="right"></router-outlet>
    </div>

    <div class="app-bar-expanded-container flex fill position-relative" *ngIf="data.appBar?.expandable">
      <router-outlet name="expanded"></router-outlet>
    </div>

    <div class="layout fill">
      <router-outlet name="reminder"></router-outlet>
    </div>
  </div>
</div>
