import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApplicationOfferBundleData } from "../../../application/application.model";
import { ConfigService } from "../../../config/config.service";

@Injectable({
    providedIn: 'root'
})
export class OfferBundleBaseDtoService<TData extends ApplicationOfferBundleData> {
    protected readonly baseUrl: string;

    constructor(protected http: HttpClient, protected configService: ConfigService) {
        this.baseUrl = `${this.configService.environment.applications.url}/api/offer-bundle`;
    }

    get(offerBundleId: number): Observable<TData> {
        return this.http.get<TData>(`${this.baseUrl}/${offerBundleId}`);
    }

}
