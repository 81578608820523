import { ElementRef, Input, Directive, forwardRef, Renderer2, HostListener } from "@angular/core";
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from "@angular/forms";

export const MASK_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MaskDirective),
    multi: true
};

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[customMask]',
    providers: [MASK_VALUE_ACCESSOR]
})
export class MaskDirective implements ControlValueAccessor {
    private input: HTMLInputElement;
    private onChange: (string) => void;
    private isBackspace: boolean = false;

    constructor(private renderer: Renderer2, element: ElementRef) {
        this.input = element.nativeElement;
    }

    @Input("customMask")
    mask: string;

    registerOnChange(fn: (string) => void) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) { }

    writeValue(value: string): void {
        if (value !== undefined && value !== null && value.toString)
            value = value.toString();
        const formattedValue = this.format(value, 0, false).value;
        this.renderer.setProperty(this.input, 'value', formattedValue);
    }

    @HostListener('input', ['$event.target.value'])
    onInput(newValue: string) {
        const cursorIndex = this.input.selectionEnd;
        const formatResult = this.format(newValue, cursorIndex, this.isBackspace);
        this.isBackspace = false;
        this.input.value = formatResult.value;
        this.input.selectionEnd = formatResult.index;
        this.onChange(this.raw(formatResult.value));
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        let targetIndex = this.input.selectionEnd;
        while (targetIndex < this.input.value.length && this.separator(this.input.value[targetIndex]))
            targetIndex++;

        const maskChar = this.mask[targetIndex];
        const valueChar = event.key;
        this.isBackspace = valueChar == 'Backspace';
        if (targetIndex >= this.mask.length && !this.isBackspace)
            return;
        const targetChar = this.input.value[targetIndex];
        const targetCharValid = targetChar && maskChar
            && (
                (this.letter(maskChar) && this.letter(valueChar))
                || (this.number(maskChar) && this.number(valueChar))
                || (this.any(maskChar) && this.raw(valueChar))
            );
        const targetCharValidApply = () => {
            event.preventDefault();
            const newValue =
                this.input.value.substring(0, targetIndex)
                + valueChar
                + this.input.value.substring(Math.min(targetIndex + 1, this.input.value.length));
            targetIndex++;
            while (targetIndex < this.input.value.length && this.separator(newValue[targetIndex]))
                targetIndex++;
            this.input.value = newValue;
            this.input.selectionStart = this.input.selectionEnd = targetIndex;
            this.onChange(this.raw(newValue));
        };

        if (targetCharValid) targetCharValidApply();
    }

    private format(value: string, cursorIndex: number, isBackspace: boolean): { value: string, index: number } {
        if (!this.mask || !value) return { value: '', index: 0 };

        const rawValue = this.raw(value);
        let formattedValue = '';
        let maskIndex = 0;
        let valueIndex = 0;

        while (maskIndex < this.mask.length && valueIndex < rawValue.length) {
            const maskChar = this.mask[maskIndex];
            const maskCharSeparator = this.separator(maskChar);
            const valueChar = rawValue[valueIndex];

            if (!maskCharSeparator) {
                if ((this.letter(maskChar) && this.letter(valueChar))
                    || this.number(maskChar) && this.number(valueChar)
                    || this.any(maskChar) && this.raw(valueChar)) {
                    formattedValue += valueChar;
                    valueIndex++;
                    maskIndex++;
                } else {
                    valueIndex++;
                    continue;
                }
            }

            while (maskIndex < this.mask.length && this.separator(this.mask[maskIndex])) {
                formattedValue += this.mask[maskIndex];

                if(isBackspace) {
                    if (maskIndex === cursorIndex - 1 || maskIndex === cursorIndex - 2) {
                        if (this.separator(this.mask[maskIndex++]))
                            cursorIndex = cursorIndex + 2;
                        else
                            cursorIndex++;
                    }
                }
                else {
                    if (maskIndex === cursorIndex - 1)
                        cursorIndex++;
                }
                maskIndex++;
            }
        }

        return { value: formattedValue, index: cursorIndex };
    }

    letter(char: string) {
        return !!char.match(/^[a-zA-Z]$/);
    }
    number(char: string) {
        return !!char.match(/^[0-9]$/);
    }
    any(value: string) {
        return value.replace(/[^/?]/ig, '');
    }
    raw(value: string) {
        return value.replace(/[^a-z0-9]/ig, '');
    }
    separator(char: string) {
        return !!char.match(/^[() -]$/);
    }
}
