import { MfaContextType } from "../enums/mfa-context-type.enum";
import { MfaDataStatus } from "../enums/mfa-data-status.enum";
import { MfaPhoneStatus } from "../enums/mfa-phone-status.enum";
import { MfaConfig } from "./mfa-config.model";

export interface MfaData {
  contextType: MfaContextType;
  errorRedirectUrl: string;
  phoneNumber: string;
  phoneStatus: MfaPhoneStatus;
  mfaToken: string;
  config: MfaConfig;
  mfaCodeData: MfaCodeData;
  contextData?: MfaContextData;
}

// TODO[mfa] refactor to generic models?
export interface MfaContextData {
  // TODO[mfa] part for login flow
  username?: string;
  rememberMe?: boolean;
  loginType?: string;

  // TODO[mfa] part for reset password
  password?: string;
  confirmPassword?: string;
  token?: string;

  // TODO[mfa] part for change phone number
  customerId?: number;
  oldPhoneNumber?: number;
}

export interface MfaCodeData {
  codeLength: number;
  codeGenerationIntervalSeconds: number;
  codeSent?: boolean;
  error?: string; // TODO[mfa] object
}

export interface MfaSessionValidityData {
  status: MfaDataStatus;
  mfaData?: MfaData;
}

export interface MfaActionNeededData {
  label: MfaDataStatus;
  mfaData?: MfaData;
}

export enum UpdatePhoneNumberDialogType {
  Add = "add",
  Update = "update",
}

export interface UpdatePhoneNumberDialogData {
  mfaToken: string;
  config: MfaConfig;
  type: UpdatePhoneNumberDialogType;
}
