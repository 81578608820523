<ifc-app-scroll-container>

    <div id="main" fxLayout="column" fxLayoutAlign="center center">

        <div fxLayout="column" fxLayoutAlign="start" [ngClass.xl]="'form-field-lg'" [ngClass.lg]="'form-field-lg'"
            [ngClass.lt-lg]="'form-field-lg'" [ngClass.xs]="'form-field-xs'">

            <div class="title" fxLayoutAlign="center center">
                Bank action required
            </div>

            <div class="secondary-text layout padding-top">
                The following banks are no longer linked to your account.
            </div>

            <div *ngIf="customerMessages.length > 0" class="layout padding-top">
                <div *ngFor="let message of customerMessages" class="secondary-text">
                    {{message}} <br>
                </div>
            </div>

            <div *ngIf="!(customerMessages.length > 0)"
                class="secondary-text layout padding-top">
                Action is required. Login directly to the banks and check for actions that need to be taken.
            </div>

            <div class="secondary-text layout padding-top">
                Once the {{(data.financialInstitutions.length) > 1 ? 'actions are complete' : 'action is complete'}},
                log back into our portal and the bank link should be fixed. If you continue to have issues,
                reach out to us at <a href="mailto:{{contactEmail}}">{{contactEmail}}</a> or
                <a href="tel:{{contactPhone}}">{{contactPhone}}</a>.
            </div>

            <div fxLayout="column" fxLayoutAlign="center center">

                <div [ngClass.xl]="'form-field-lg'" [ngClass.lg]="'form-field-lg'" [ngClass.lt-lg]="'form-field-lg'"
                    [ngClass.xs]="'form-field-xs'">

                    <div *ngFor="let institution of data.financialInstitutions" [class.hover]="!!institution.institutionUrl" class="bank-account-container" (click)="openBankWebsite(institution)"
                        fxLayout="row" fxLayoutAlign="space-between center">

                        <div fxLayout="row" fxLayoutGap="12px">

                            <div>
                                <img *ngIf="logoExists(institution?.institutionId, institution?.institutionName)"
                                    matListAvatar
                                    [src]="getLogo(institution?.institutionId, institution?.institutionName)">
                                <div *ngIf="!logoExists(institution?.institutionId, institution?.institutionName)"
                                    matListAvatar class="avatar circle-wrap" fxLayoutAlign="center center">
                                    <mat-icon>account_balance</mat-icon>
                                </div>
                            </div>

                            <div fxLayout="column" fxLayoutAlign="center start">
                                <div>
                                    {{institution?.institutionName}}
                                </div>
                                <div *ngIf="data?.loan" class="secondary-text">
                                    For {{data?.loan?.productCode}} {{data?.loan?.loanNumber}}
                                </div>
                            </div>

                        </div>

                    </div>

                </div>

            </div>

            <div class="buttons-conatiner" fxLayout="row" fxLayoutAlign="end center">
                <button mat-button (click)="skip()">Ok</button>
            </div>

        </div>

    </div>

</ifc-app-scroll-container>