<ng-container *ngIf="data">
  <ifc-offer-details [productCode]="data.productCode"
                     [application]="data.application"
                     [offerBundles]="data.offerBundles"
                     [currentOfferBundle]="data.currentOfferBundle"
                     [companyData]="data.companyData"
                     [canSelectOffer]="data.currentOfferBundle.isOfferSelectable">
    <ng-template #selectOffer>
      <ifp-offer-select [applicationId]="data.application.id"
                        [offerBundleId]="data.currentOfferBundle.id"
                        [offerBundleStatus]="data.currentOfferBundle.status"
                        [isOfferSelectable]="data.currentOfferBundle.isOfferSelectable"
                        (offerSelect)="onSelectOffer($event)">
      </ifp-offer-select>
    </ng-template>
  </ifc-offer-details>
</ng-container>
