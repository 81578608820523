import { Component, Inject } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { Observable } from "rxjs";

@Component({
  selector: "ifc-available-funds-explanation-dialog",
  templateUrl: "./available-funds-explanation-dialog.component.html",
  styleUrls: ["./available-funds-explanation-dialog.component.scss"],
})
export class AvailableFundsExplanationDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<AvailableFundsExplanationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  static show(
    dialog: MatDialog,
    message?: string,
    availableFunds?: number,
    msaCreditLimit?: number
  ): Observable<any> {
    return dialog
      .open(AvailableFundsExplanationDialogComponent, {
        width: "620px",
        data: {
          message,
          availableFunds,
          msaCreditLimit,
        },
        closeOnNavigation: true,
      })
      .afterClosed();
  }
}
