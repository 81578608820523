/* eslint-disable @angular-eslint/component-class-suffix */
/* eslint-disable @angular-eslint/component-selector */
import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";

@Component({
    selector: 'dialog-confirm',
    templateUrl: './dialog-confirm.component.html'
})
export class DialogConfirm {
    constructor(public dialogRef: MatDialogRef<DialogConfirm>,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    static show(dialog: MatDialog, message: string): Observable<boolean> {
        return dialog.open(DialogConfirm, {
            data: { message },
            closeOnNavigation: true,
            width: "600px",
            autoFocus: false
        }).afterClosed();
    }
}