import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { CompanyBranding } from '../../../branding/branding.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BrandingService } from '../../../../core/branding/branding.service';

@UntilDestroy()
@Component({
  selector: 'ifc-security-info-dialog',
  templateUrl: './security-info-dialog.component.html',
  styleUrls: ['./security-info-dialog.component.scss']
})
export class SecurityInfoDialogComponent implements OnInit {
  privacyUrl: string;

  constructor(
    public dialogRef: MatDialogRef<SecurityInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private brandingService: BrandingService,
  ) { }

  ngOnInit(): void {
    this.initBrandingInfo();
  }

  private initBrandingInfo(): void {
    this.brandingService.getBranding$()
      .pipe(untilDestroyed(this))
      .subscribe((branding: CompanyBranding) => {
        this.privacyUrl = branding?.privacyUrl ?? 'https://www.ideafinancial.com/privacy-policy';
      })
  }

  static show(dialog: MatDialog): Observable<any> {
    return dialog.open(SecurityInfoDialogComponent, {
      width: '510px',
      closeOnNavigation: true,
    }).afterClosed();
  }
}
