import { NgModule } from "@angular/core";
import { OfferCommonModule } from "../../common/offer-common.module";
import { OfferSummaryWidgetComponent } from "./components/offer-summary-widget/offer-summary-widget.component";
import { OfferSummaryComponent } from "./offer-summary.component";

@NgModule({
  imports: [OfferCommonModule],
  declarations: [OfferSummaryComponent, OfferSummaryWidgetComponent],
  exports: [OfferSummaryComponent],
})
export class OfferSummaryModule {}
