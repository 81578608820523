import { CurrencyPipe, PercentPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { ApplicationBaseData, ApplicationOffer, ApplicationOfferBundleData, ProductCode } from 'common';
import { OfferDocumentsComponentData } from '../../offer.model';
import { OfferCalculator } from './offer-calculator';
import { OfferCalculatorService } from './offer-calculator.service';
import { OfferLawCalculator } from './offer-law-calculator';
import { OfferLocCalculator } from './offer-loc-calculator';
import { OfferTermCalculator } from './offer-term-calculator';

// TODO[offers] - will be refactored in RYNO-7973 (currently copied from common/application)
@Injectable({providedIn: 'root'})
export class OfferDocumentsService {

  constructor(
    private percentPipe: PercentPipe,
    private currencyPipe: CurrencyPipe,
    private offerCalculatorService: OfferCalculatorService
  ) {}

  getMonthlyRate(data: OfferDocumentsComponentData): number {
    const calculator = this.getCalculator(data);

    return calculator.monthlyRate;
  }

  private getCalculator(data: OfferDocumentsComponentData) {
    return this.createOfferCalculatorBase(
      data.offerBundle.offers[0].productCode,
      data.application,
      data.offerBundle,
      data.offerBundle.offers[0]
    )
  }

  private createOfferCalculatorBase(
    productCode: ProductCode,
    application: ApplicationBaseData,
    offerBundle: ApplicationOfferBundleData,
    offer: ApplicationOffer
  ): OfferCalculator {
    switch (productCode) {
      case ProductCode.LOC: {
        return new OfferLocCalculator(
          application,
          offer,
          offerBundle,
          this.percentPipe,
          this.currencyPipe,
          this.offerCalculatorService
        );
      }

      case ProductCode.Term: {
        return new OfferTermCalculator(
          application,
          offer,
          offerBundle,
          this.percentPipe,
          this.currencyPipe,
          this.offerCalculatorService
        );
      }
      case ProductCode.Law: {
        return new OfferLawCalculator(
          application,
          offer,
          offerBundle,
          this.percentPipe,
          this.currencyPipe,
          this.offerCalculatorService
        );
      }
      default:
        return null;
    }
  }

  getTerm(data: OfferDocumentsComponentData): string {
    return `${data.offerBundle?.expressCheckout ? 'express ' : ''}${data.offerBundle?.offers[0]?.repaymentTerm} ${!data.offerBundle?.expressCheckout ? ' months' : ''}`;
  }
}
