import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppBarTitleService } from './app-bar-title.service';
import { delay } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'ifc-app-bar-title',
  templateUrl: './app-bar-title.component.html',
  styleUrls: ['./app-bar-title.component.scss']
})
export class AppBarTitleComponent implements OnInit, OnDestroy {
  title = '';

  constructor(
    private route: ActivatedRoute,
    private service: AppBarTitleService) {
  }

  data: AppBarTitleComponentData;

  dataSet(data: AppBarTitleComponentData) {
    this.data = data;
    if (data.appBarTitle)
      this.title = data.appBarTitle.content;
  }

  ngOnInit() {
    this.route.data.pipe(untilDestroyed(this)).subscribe(this.dataSet.bind(this)),
      this.service.titleChange.pipe(delay(100), untilDestroyed(this)).subscribe(it => this.title = it)
  }

  ngOnDestroy() {
    this.service.clear();
  }
}

export interface AppBarTitleComponentData {
  appBarTitle?: {
    content?: string;
  };
}
