import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { ChartConfiguration } from "chart.js";
import {
  getBarChartData,
  getBarChartOptions,
} from "./offer-term-loan-bar-chart-data";
import { OfferCalculatorService } from "../../../../services/offer-calculator.service";
import { OfferTermCalculator } from "../../../../services/offer-term-calculator";
import { OfferActionsInternalService } from "../../../../services/actions/offer-actions.internal.service";
import { OfferBundleStatus } from "../../../../models/offer.model";

@Component({
  selector: "ifc-offer-term-details-content",
  templateUrl: "./offer-term-details-content.component.html",
  styleUrls: ["./offer-term-details-content.component.scss"],
})
export class OfferTermDetailsContentComponent implements OnInit, OnChanges {
  @Input()
  calculator: OfferTermCalculator;

  isRenewal: boolean;
  offerBundleStatusEnum = OfferBundleStatus;

  private _chartData: ChartData = null;

  private _barChartData: ChartConfiguration<"bar">["data"] = null;
  private _barChartOptions: ChartConfiguration<"bar">["options"] = null;

  constructor(
    private actionsService: OfferActionsInternalService,
    private offerCalculatorService: OfferCalculatorService
  ) {}

  ngOnInit(): void {
    this.isRenewal = this.calculator.application.renewalForLoanUniqueId
      ? true
      : false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this._barChartData = null;
    this._barChartOptions = null;
  }

  get factorRate(): number {
    return this.calculator.rate / 100 + 1;
  }

  get barChartData(): any {
    if (!this._barChartData) {
      this._barChartData = this.prepareBarChartData();
    }

    return this._barChartData;
  }

  get barChartOptions(): any {
    if (!this._barChartOptions) {
      this._barChartOptions = this.prepareBarChartOptions();
    }

    return this._barChartOptions;
  }

  private get chartData(): ChartData {
    this._chartData = {
      labels: [],
      interest: [],
      principal: [],
      total: [],
    };

    const payments = this.offerCalculatorService.getTermLoanPayments(
      this.calculator.applicationOffer
    );

    for (let idx = 0; idx < payments.length; idx++) {
      this._chartData.labels.push(payments[idx].label);
      this._chartData.interest.push(payments[idx]?.interestBalance);
      this._chartData.principal.push(payments[idx]?.principalBalance);
      this._chartData.total.push(
        payments[idx]?.interestBalance + payments[idx]?.principalBalance
      );
    }

    return this._chartData;
  }

  private prepareBarChartData(): ChartConfiguration<"bar">["data"] {
    return getBarChartData(this.chartData.total, this.chartData.labels);
  }

  private prepareBarChartOptions(): ChartConfiguration<"bar">["options"] {
    return getBarChartOptions;
  }

  onLinkBank(entityId: number): void {
    this.actionsService.linkBank(entityId);
  }

  onDocumentsClick(applicationId: number, offerBundleId: number): void {
    this.actionsService.openDocumentsDetails(applicationId, offerBundleId);
  }

  showDisclosures(applicationId: number, offerId: number): void {
    this.actionsService.showDisclosures({ applicationId, offerId });
  }
}

class ChartData {
  labels: string[];
  interest: number[];
  principal: number[];
  total: number[];
}
