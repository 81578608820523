import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs/internal/Observable';
import * as _ from 'lodash';
import { LoanData, ProductCode } from '../../loan/domain/models/loan.model';
import { LoanDataOptionItem } from './loans-select-dialog.model';
import { LoanStatus, NeedsAttentionHelper, NeedsAttentionModel } from 'common';

@Component({
  selector: 'ifp-loans-select-dialog',
  templateUrl: './loans-select-dialog.component.html',
  styleUrls: ['./loans-select-dialog.component.scss']
})
export class LoansSelectDialogComponent {

  public selected: LoanDataOptionItem;
  public loanOptions: LoanDataOptionItem[];

  constructor(public dialogRef: MatDialogRef<LoansSelectDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
      this.loanOptions = this.getLoanOptions(this.data?.loans);
  }
  
  getLoanOptions(loans: LoanData[]): LoanDataOptionItem[] {
    if (!loans) {
      return [];
    }

    return loans.map(loan => {
      let result = {
        label: this.getLoanLabel(loan),
        value: loan,
        tooltip: this.getLoanLabel(loan),
      } as LoanDataOptionItem;

      if (loan.status === LoanStatus.Frozen) {
        result = { ...result,
          iconName: 'warning',
          iconClass: 'mat-orange',
          iconTooltip: NeedsAttentionHelper.getNeedsAttentionMessageWithProductCode(this.data.needsAttentionModel.messageInput, loan.productCode)
        };
      }

      return result;
    });
  }

  form: UntypedFormGroup = this.formBuilder.group({
    loanNumber: ''
  });

  save() {
    this.dialogRef.close(this.selected.value);
  }

  getLoanLabel(loan: LoanData): string {
    return loan?.productCode !== ProductCode.Law ? loan?.loanNumber : loan?.description;
  }

  shouldShowTooltip(label: any): boolean {
    return !(label?.offsetWidth > label?.offsetParent?.offsetWidth);
  }

  static show(dialog: MatDialog, title: string, loans: LoanData[], needsAttentionModel?: NeedsAttentionModel): Observable<any> {
    return dialog.open(LoansSelectDialogComponent, {
      width: '510px',
      data: {
        title: title,
        loans: loans,
        needsAttentionModel: needsAttentionModel
      },
      closeOnNavigation: true
    }).afterClosed();
  }
}
