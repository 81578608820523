<div id="main" fxLayout="column" fxLayoutAlign="center center">

  <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="32px" ngClass.lt-sm="layout padding-horizontal">

    <div class="header" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="12px">
      <img src="../../assets/ideafinancial-renewal.png" alt="Renewal">
      <span ngClass.gt-sm="title" ngClass.lt-sm="title xs">
        Your loan is eligible for renewal!
      </span>
      <span ngClass.lt-sm="title xs">
        It's quick and painless to get started. Continue below to keep your loan open and your business growing.
      </span>
    </div>

    <div class="content" fxLayout="column" fxLayoutAlign.lt-sm="center start" fxLayoutGap="12px"
      fxLayoutGap.lt-sm="8px">

      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="24px">
        <img src="../../assets/keep-access.png" alt="Keep access">
        <div fxLayout="column">
          <span class="mat-green">Keep your loan open and available</span>
          <div>
            <span *ngIf="elibility.productCode != ProductCode.Term">Continue using your revolving line of </span>
            <span *ngIf="elibility.productCode == ProductCode.Term">Continue using your </span>
            <span class="mat-green"> {{elibility?.creditLimit | currency}}</span></div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="24px">
        <img src="../../assets/balance-reset.png" alt="Balance reset">
        <div fxLayout="column">
          <span class="mat-green">Reset your repayment period</span>
          <div><span>Every draw you make restarts your repayment period</span></div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="24px">
        <img src="../../assets/no-monthly-fees.png" alt="No monthly fees">
        <div fxLayout="column">
          <span class="mat-green">No pre-payment penalties</span>
          <div><span>Replenish available credit after every payment</span></div>
        </div>
      </div>

      <div fxLayout="row" fxFlexAlign="space-between center" class="layout padding-top">
        <div fxLayoutAlign="end" fxLayoutGap="8px" fxFlex="100">
          <button mat-button color="mat-gray" type="button" [routerLink]="['/dashboard']">
            Go to Dashboard
          </button>
          <button mat-raised-button color="primary" type="button" (click)="optIn()">
            Start Renewal
          </button>
        </div>
      </div>

    </div>

  </div>

</div>