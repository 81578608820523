import { NgModule } from "@angular/core";
import { UiModule } from "../../ui/ui.module";
import { LinkYourBankComponent } from "./components/link-your-bank/link-your-bank.component";
import { OfferDetailsHeadingComponent } from "./components/offer-details-heading/offer-details-heading.component";
import { OfferProductNavigationComponent } from "./components/offer-product-navigation/offer-product-navigation.component";
import { OfferRepaymentNavigationComponent } from "./components/offer-repayment-navigation/offer-repayment-navigation.component";
import { OfferDrawSliderComponent } from "./components/offer-draw-slider/offer-draw-slider.component";
import { NgxModule } from "../../ngx/ngx.module";
import { OfferCalculatorDetailsTableComponent } from "./components/offer-calculator-details-table/offer-calculator-details-table.component";
import { OfferCalculatorClosingRequirementsComponent } from "./components/offer-calculator-closing-requirements/offer-calculator-closing-requirements.component";
import { OfferCalculatorPaymentScheduleComponent } from "./components/offer-calculator-payment-schedule/offer-calculator-payment-schedule.component";
import { OfferPaymentScheduleTableComponent } from "./components/offer-payment-schedule-table/offer-payment-schedule-table.component";

@NgModule({
  imports: [UiModule, NgxModule],
  declarations: [
    OfferDetailsHeadingComponent,
    OfferProductNavigationComponent,
    OfferRepaymentNavigationComponent,
    LinkYourBankComponent,
    OfferDrawSliderComponent,
    OfferCalculatorDetailsTableComponent,
    OfferCalculatorClosingRequirementsComponent,
    OfferCalculatorPaymentScheduleComponent,
    OfferPaymentScheduleTableComponent,
  ],
  exports: [
    UiModule,
    OfferDetailsHeadingComponent,
    OfferProductNavigationComponent,
    OfferRepaymentNavigationComponent,
    LinkYourBankComponent,
    OfferDrawSliderComponent,
    OfferCalculatorDetailsTableComponent,
    OfferCalculatorClosingRequirementsComponent,
    OfferCalculatorPaymentScheduleComponent,
  ],
})
export class OfferCommonModule {}
