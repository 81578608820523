export enum ProductCode {
    LOC = 'LOC',
    Term = 'Term',
    Law = 'Law'
}

export const ProductCodeLabel = new Map<string, string>([
    [ProductCode.LOC, 'Line of Credit'],
    [ProductCode.Term, 'Term Loan'],
    [ProductCode.Law, 'Case Financing']
]);

export const ProductCodeShort = new Map<string, string>([
    [ProductCode.LOC, 'LC'],
    [ProductCode.Term, 'TL'],
    [ProductCode.Law, 'CF']
]);