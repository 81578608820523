<div fxLayout="column">
  <div fxLayout="column"
       fxLayoutAlign="start start"
       class="formRow"
       fxLayoutGap="8px"
       style="padding-bottom:8px;">
    <div class="title">Case details</div>
    <div class="secondary-text medium">Have a case in mind? Fill out the info below to speed up your Lawsuit Cost Financing application.</div>
  </div>
  <form [formGroup]="form">
    <div fxLayout="column"
         fxLayoutAlign="start">

      <div class="formRow"
           fxLayout="row"
           fxLayout.xs="column"
           fxLayoutGap="24px"
           fxLayoutGap.xs="0px">
        <mat-form-field appearance="outline"
                        fxFlex>
          <input id="case-name-form-field"
                 matInput
                 type="text"
                 formControlName="caseName">
          <mat-label>Case name</mat-label>
          <mat-error id="case-name-required-error-msg"
                     *ngIf="form.get('caseName').errors | hasControlError:['required']">
            Case name is required.
          </mat-error>
          <mat-error id="case-name-max-length-error-msg"
                     *ngIf="form.get('caseName').errors | hasControlError:['maxlength']">
            Case name is too long.
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline"
                        fxFlex>
          <input id="case-number-form-field"
                 matInput
                 type="text"
                 formControlName="caseNumber">
          <mat-label>Case number (if available)</mat-label>
          <mat-error id="case-number-max-length-error-msg"
                     *ngIf="form.get('caseNumber').errors | hasControlError:['maxlength']">
            Case number is too long.
          </mat-error>
        </mat-form-field>
      </div>

      <div class="formRow"
           fxLayout="row"
           fxLayout.xs="column"
           fxLayoutGap="24px"
           fxLayoutGap.xs="0px">
        <mat-form-field appearance="outline"
                        fxFlex="48">
          <input id="requested-amount-form-field"
                 matInput
                 type="text"
                 currencyMask
                 formControlName="requestedAmount"
                 maxlength="11">
          <mat-label>Requested financing amount</mat-label>
          <mat-error id="requested-amount-required-error-msg"
                     *ngIf="form.get('requestedAmount').errors | hasControlError:['required']">
            Requested financing amount is required.
          </mat-error>
          <mat-error id="requested-amount-min-error-msg"
                     *ngIf="form.get('requestedAmount').errors | hasControlError:['min']">
            Requested financing amount is too small.
          </mat-error>
        </mat-form-field>
      </div>

      <div class="formRow"
           fxLayout="column"
           fxLayoutGap="24px">
        <div id="title"
             class="upload-title secondary-text">
          Copy of the complaint filed in PDF format (if available)
        </div>
        <uppy-dashboard [uppy]="uppy"
                        [props]="uppyDashboardOptions"></uppy-dashboard>
        <div fxLayout="row"
             fxLayoutAlign="end center">
          <button mat-button
                  (click)="cancel()">CANCEL</button>
          <button mat-flat-button
                  color="primary"
                  type="submit"
                  [disabled]="!form.valid && !applicationId"
                  (click)="submit()">SUBMIT</button>
        </div>
      </div>
    </div>
  </form>

</div>
