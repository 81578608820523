import { Pipe, PipeTransform } from "@angular/core";
import { DateHelper } from "../../helpers/date.helper";

@Pipe({
  name: "daysBetween",
})
export class DaysBetweenPipe implements PipeTransform {
    transform(from: Date, to: Date): number {
        let localFrom, localTo;
        if (!from) {
            localFrom = new Date();
        }

        localFrom = DateHelper.setToStartOfDay(new Date(localFrom));
        localTo = DateHelper.setToStartOfDay(new Date(to));

        const oneDayInTicks = 1000 * 60 * 60 * 24;
        const createUtcDateOnly = (date: Date) => Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
        
        return Math.floor((createUtcDateOnly(localTo) - createUtcDateOnly(localFrom))/ oneDayInTicks);
    }
}
