<div class="canvas-background">
  <div class="canvas"
       #confettiCanvas></div>
</div>

<div class="wrapper"
     fxLayout="column">

  <div fxLayout="column"
       fxLayoutAlign="start center">

    <h2>
      Congratulations!
    </h2>

    <h4 class="layout padding-left padding-right">
      {{application?.businessName}} have offers for financing up to<span *ngIf="offerBundles?.length"
            class="highlighted"> {{maxOfferAmount | currency:'$'}}</span>
      and terms up to<span *ngIf="offerBundles?.length"
            class="highlighted"> {{maxOfferRepayment}} months</span>. See your approved product(s) below.
    </h4>

    <ng-container *ngIf="shareOfferRef">
      <ng-container *ngTemplateOutlet="shareOfferRef"></ng-container>
    </ng-container>

    <div fxLayout="row wrap"
         fxLayoutAlign="center start"
         fxLayoutGap="24px"
         fxLayout.lt-lg="column"
         fxLayoutAlign.lt-lg="center center"
         class="layout padding-top">
      <ifc-offer-summary-widget *ngFor="let offerBundle of bestOfferBundles"
                                [applicationId]="application.id"
                                [offerBundle]="offerBundle"
                                (displayAllOffers)="onDisplayAllOffers($event)">
      </ifc-offer-summary-widget>
    </div>

    <ifc-link-your-bank [bankLinkStatus]="application?.bankLinkStatus"
                        (linkBank)="onLinkBank(application?.entityId)">
    </ifc-link-your-bank>

    <ng-container *ngIf="contactFooterRef">
      <ng-container *ngTemplateOutlet="contactFooterRef"></ng-container>
    </ng-container>

  </div>

</div>
