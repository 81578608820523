import { BankAccount, LiquidityEvent, LoanInfoDto, QueryResult } from "common";
import { LoanFileTemplateData } from "projects/common/src/lib/documents/loan-document.model";
import { OfferInfo } from "../../../agreement/agreement.data";

export interface LoanData {
    fullAmortizationStartDateUtc: any;
    active?: boolean;
    advisorId?: number;
    advisorFirstName?: string;
    advisorLastName?: string;
    advisorName?: string;
    advisorEmail?: string;
    advisorPhone?: string;
    advisorAvatar?: string;
    advisorCalendarLinkUrl?: string;
    applicationNumber?: string;
    entityAddress?: {
        city?: string;
        line1?: string;
        line2?: string;
        state?: string;
        zip?: string;
    };
    entityId?: number;
    entityDBA?: string;
    entityEIN?: string;
    entityName?: string;
    entityPhone?: string;
    id?: number;
    loanNumber?: string;
    offer?: OfferInfo;
    owners?: CustomerLoan[];
    status?: number;
    frozenUntil?: Date;
    freezeForDays?: number;
    uniqueId?: string;
    productCode?: string;
    productName?: string;
    renewalDate?: Date;
    renewedBy?: string;
    renewalFor?: string;
    optInForRenewal?: boolean;
    renewalApplicationId?: number;
    renewalOffer?: OfferInfo,
    renewalOfferAvailableToSign?: boolean;
    offerBundles?: OfferbundleStatus[];
    paymentBankAccountData?: BankAccount;
    disbursementBankAccountData?: BankAccount;
    externalId?: string;
    description?: string;
    policyNumber?: string;
    policyLimit?: number;
    allowDraw?: boolean;
    allowPayment?: boolean;
    noAutoPayments: boolean;
    drawDownFee?: number;
    drawDownFeeType?: number;
    repaymentTerm?: number;
    interestRate?: number;
    maintenanceFee?: number;
    maintenanceFeeType?: number;
    createdOn?: Date;
    loanInfo?: LoanInfoDto;
    favorite?: boolean;
    fullRepaymentDueDate?: Date;
}

export interface CaseFinancingLoanData extends LoanData {
  liquidityEventData: LiquidityEvent;
}

export interface LoanDataSummary {
  caseFinancingLoans: CaseFinancingLoanData[];
  otherLoans: LoanData[];
  totalCount: number;
}

export interface LoanQueryResult extends QueryResult<LoanData> { }

export interface LoanWithRenewalData extends LoanData {
    renewalForLoanNumber?: string;
    renewalForProductName?: string;
    renewedByLoanNumber?: string;
    renewedByProductName?: string;
}

export interface OfferBundle {
    applicationUniqueId?: string;
    applicationNumber?: string;
    offers?: OfferInfo[];
    status?: OfferbundleStatus;
    createdOn?: Date;
    statusUpdatedOn?: Date;
}


export enum OfferbundleStatus {
    Pending = 'pending',
    Accepted = 'accepted',
    Rejected = 'rejected',
    Expired = 'expired'
}

export interface LoanBankInfo {
    name?: string;
    routingNumber?: string;
    accountNumber?: string;
    types?: BankInfoType[];
}

export enum BankInfoType {
    Unknown = 0,
    Disbursement = 1,
    Payment = 2
}

export enum ProductName {
    'Term Loan' = 'Term',
    'Line of Credit' = 'LOC',
    'Case Financing' = 'Law'
}

export enum ProductCode {
    Term = 'Term',
    LOC = 'LOC',
    Law = 'Law'
}

export enum AccountOfferPaymentFrequencies {
    'Weekly' = 0,
    'Bi weekly' = 1,
    'Monthly' = 2
}


export interface CustomerLoan
{
    customerId: number,
    favorite: boolean
}

export interface LoanFileTemplate {
  loanId: number;
  templates: LoanFileTemplateData[];
}
