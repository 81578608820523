import { Component, Input } from "@angular/core";
import { ApplicationOffer } from "projects/common/src/lib/application/application.model";

@Component({
  selector: "ifc-offer-calculator-payment-schedule",
  templateUrl: "./offer-calculator-payment-schedule.component.html",
  styleUrls: ["./offer-calculator-payment-schedule.component.scss"],
})
export class OfferCalculatorPaymentScheduleComponent {
  @Input() offer: ApplicationOffer;
  @Input() sampleDraw: number;
}
