<ifc-app-scroll-container [layout2]="true">
  <ifc-finance-contact-widget></ifc-finance-contact-widget>
  <div class="content" fxLayout="column" fxLayoutAlign="start center">
    <div class="card-container">
      <div fxLayout="column" fxLayoutGap="40px" ngClass.gt-md="layout margin" ngClass.lt-lg="layout margin-sm">

        <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutAlign="center start" fxLayoutAlign.xs="center center"
          fxLayoutGap="24px" fxLayoutGap.lt-lg="40px" *ngIf="data?.loan">

          <ifp-transfer-bank-account-widget [bank]="disbursementBankAccount" [type]="BankAccountRole.Disbursement">
          </ifp-transfer-bank-account-widget>

          <ifp-transfer-bank-account-widget [bank]="paymentBankAccount" [type]="BankAccountRole.Payment">
          </ifp-transfer-bank-account-widget>

        </div>

        <div fxLayout="column" fxLayoutAlign="center center">
          <button mat-stroked-button class="add-account-btn" (click)="link()">
            <mat-icon>link</mat-icon> <span class="btn-label">LINK BANK</span>
          </button>
        </div>

        <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutAlign="center st art" fxLayoutAlign.xs="center center"
          fxLayoutGap="24px" fxLayoutGap.lt-lg="40px">
          <div class="odds-column" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="24px">
            <div *ngFor="let institution of data?.financialInstitutions; let even = even; let odd = odd">
              <ifp-bank-widget *ngIf="odd" [institution]="institution"></ifp-bank-widget>
            </div>
          </div>
          <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="24px">
            <div *ngFor="let institution of data?.financialInstitutions; let even = even; let odd = odd">
              <ifp-bank-widget *ngIf="even" [institution]="institution"></ifp-bank-widget>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

</ifc-app-scroll-container>
