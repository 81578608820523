<ifc-app-card-one-column> 
  <mat-card class="mat-elevation-z0" ngClass.lt-lg="layout margin">
    <div fxLayout="row" fxLayoutAlign="start center" class="card-header">
      <span class="card-title">Transfer details</span>
    </div>
    <mat-card-content>
      <mat-list>
        <mat-list-item *ngIf="!!data.loan?.description" class="fit-content">
          <span class="secondary-text" mat-line>Case name</span>
          <span class="item-content">{{data.loan?.description}}</span>
        </mat-list-item>
        <mat-divider *ngIf="!!data.loan?.description" class="white-border"></mat-divider>
        <mat-list-item>
          <span class="secondary-text" mat-line>Transfer from</span>
          <span class="item-content">{{data.accountTransfer.from}}</span>
        </mat-list-item>
        <mat-divider class="white-border"></mat-divider>
        <mat-list-item>
          <span class="secondary-text" mat-line>Transfer to</span>
          <span class="item-content">{{data.accountTransfer.to}}</span>
        </mat-list-item>
        <mat-divider class="white-border"></mat-divider>
        <mat-list-item *ngIf="data.accountTransfer.type === TransactionType.Payment && data.accountTransfer.promotionInfo?.promotionType === PromotionType.PayoffInterestReduction">
          <span class="secondary-text" mat-line>Payoff interest reduction</span>
          <span class="item-content">{{data.accountTransfer.promotionInfo.interestRateReducement | currency:'$'}}</span>
        </mat-list-item>
        <mat-divider class="white-border"></mat-divider>
        <mat-list-item *ngIf="data.accountTransfer.type === TransactionType.Payment && data.accountTransfer.promotionInfo?.promotionType === PromotionType.ReAmortization">
          <span class="secondary-text" mat-line>Loan will be re-amortized after payment is made</span>
        </mat-list-item>
        <mat-divider class="white-border"></mat-divider>
        <mat-list-item class="higher-item">
          <span class="secondary-text">Transfers to and from your bank account are done through the Automated Clearing
            House (ACH) Network. Transfers
            take 1-2 business days to be processed.</span>
        </mat-list-item>
        <mat-list-item *ngIf="data.accountTransfer.type === TransactionType.Draw">
          <span class="secondary-text" mat-line>Fee amount</span>
          <span class="item-content">{{data.accountTransfer.fee | currency:'$'}}</span>
        </mat-list-item>
        <mat-divider class="white-border" *ngIf="data.accountTransfer.type === TransactionType.Draw && data.accountTransfer.thirdPartyPayoffAmount"></mat-divider>
        <mat-list-item *ngIf="data.accountTransfer.type === TransactionType.Draw && data.accountTransfer.thirdPartyPayoffAmount">
          <span class="secondary-text" mat-line>Third party payoff amount</span>
          <span class="item-content">{{data.accountTransfer.thirdPartyPayoffAmount | currency:'$'}}</span>
        </mat-list-item>
        <mat-divider class="white-border" *ngIf="data.accountTransfer.type === TransactionType.Draw && data.accountTransfer.promotionInfo"></mat-divider>
        <mat-list-item *ngIf="data.accountTransfer.type === TransactionType.Draw && data.accountTransfer.promotionInfo">
          <span class="secondary-text" mat-line>Promotional fee reduction</span>
          <span class="item-content">{{data.accountTransfer.promotionInfo.drawFeeReducement | currency:'$'}}</span>
        </mat-list-item>
        <mat-divider class="white-border" *ngIf="data.accountTransfer.type === TransactionType.Draw"></mat-divider>
        <mat-list-item *ngIf="data.accountTransfer.type === TransactionType.Draw">
          <span class="secondary-text" mat-line>Disbursed amount</span>
          <span class="item-content">{{(data.accountTransfer.disbursedAmount || 0) | currency:'$'}}</span>
        </mat-list-item>
        <mat-list-item>
          <span class="secondary-text" mat-line>New outstanding balance</span>
          <span class="item-content">{{data.accountTransfer.balanceAfter.outstandingBalance | currency:'$'}}</span>
        </mat-list-item>
        <mat-divider class="white-border"></mat-divider>
        <mat-list-item>
          <span class="secondary-text" mat-line>New available funds</span>
          <span class="item-content">{{data.accountTransfer.balanceAfter.availableBalance | currency:'$'}}</span>
        </mat-list-item>
        <mat-divider class="white-border"></mat-divider>
        <mat-list-item>
          <span class="secondary-text" mat-line>Old payment due</span>
          <span class="item-content">{{data.accountTransfer.loanInfo.paymentAmount | currency:'$'}}</span>
        </mat-list-item>
        <mat-divider class="white-border"></mat-divider>
        <mat-list-item>
          <span class="secondary-text" mat-line>New payment due</span>
          <span class="item-content">{{data.accountTransfer.balanceAfter.upcomingPaymentAmount | currency:'$'}}</span>
        </mat-list-item>
        <mat-divider class="white-border"></mat-divider>
        <mat-list-item>
          <span class="secondary-text" mat-line>Payment due date</span>
          <span class="item-content">{{data.accountTransfer.balanceAfter.upcomingPaymentDate | date}}</span>
        </mat-list-item>
     
      </mat-list> 
    </mat-card-content>
  </mat-card>
</ifc-app-card-one-column>