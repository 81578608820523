import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AccountBaseService, AccountHintData, AutocompleteComponent, ProductCode } from 'common';
import { EMPTY, Observable } from 'rxjs';
import { AccountSearchInputService } from './account-search-input.service';

@Component({
  selector: 'ifp-account-search-input',
  templateUrl: './account-search-input.component.html',
  styleUrls: ['./account-search-input.component.scss'],
})
export class AccountSearchInputComponent extends AutocompleteComponent {

  @Input()
  productCode: ProductCode;

  @Input()
  excludedProductCode: ProductCode;

  @Input()
  entityId: number;

  @Output() enter = new EventEmitter<boolean>();

  @Output() backAction = new EventEmitter<string>();
    constructor(
      private accountService: AccountBaseService,
      private accountSearchInputService: AccountSearchInputService
      ) {
    super();
    accountSearchInputService.clearSearch.subscribe(() => this.fc.setValue(''));
  }

  label = 'Search';
  leftIcon = 'search';

  override ngOnInit(): void {
    super.ngOnInit();
    this.optionLabelKey = this.productCode == ProductCode.Law ? 'description' : 'loanNumber'
    this.optionLabelKey2 = this.productCode == ProductCode.Law ? 'loanNumber' : 'description'
  }

  optionFilter: (input: string | AccountHintData) => Observable<any[]> =
    input =>
    {
      let description = null;
      let loanNumber = null;
      let inputValue = null;
      if(!!input)
      {
        inputValue = typeof input !== 'string' ? null : input;
        description = typeof input !== 'string' ? input.description : null;
        loanNumber = typeof input !== 'string' ? input.loanNumber : null;
      }

      var sortField = this.productCode == ProductCode.Law ? 'description' : 'loanNumber';

      return input
      ? this.accountService.queryHints({
        description: description,
        loanNumber: loanNumber,
        sortField: sortField,
        productCode: this.productCode,
        excludedProductCode: this.excludedProductCode,
        loanNumberOrDescription: inputValue,
        entityId: this.entityId
      })
      : EMPTY;
    }

  back()
  {
    this.valueClear();
    this.backAction.emit()
  }

  onFocus()
  {
    this.leftIcon = 'west'
    this.enter.emit(true);
  }

  onBlur()
  {
    this.leftIcon = 'search'
    this.enter.emit(false);
  }
}
