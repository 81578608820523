import { NgModule } from "@angular/core";
import { TruncatePipe } from "./text-truncate.pipe";
import { FileSizePipe } from "./file-size.pipe";
import { StartCasePipe } from "./start-case.pipe";
import { SafeHtmlPipe } from "./safe-html.pipe";
import { PhonePipe } from "./phone.pipe";
import { SortByPipe } from "./sort-by-pipe";
import { ShortNumberPipe } from "./short-number.pipe";
import { SentenceCasePipe } from "./sentence-case.pipe";
import { FilterPipe } from "./filter.pipe";
import { SafeResourceUrlPipe } from "./safe-resource-url.pipe";
import { HasControlErrorPipe } from "./has-control-error.pipe";
import { HiddenPhonePipe } from "./hidden-phone.pipe";
import { EnumValueLabelPipe } from "./enum-label.pipe";
import { DaysBetweenPipe } from "./date-diff.pipe";
import { HiddenPipe } from "./hidden.pipe";
import { BooleanValueLabelPipe } from './boolean-label.pipe';
import { SafePureHtmlPipe } from "./safe-pure-html.pipe";

@NgModule({
  declarations: [
    TruncatePipe,
    FileSizePipe,
    StartCasePipe,
    SafeHtmlPipe,
    SafePureHtmlPipe,
    SafeResourceUrlPipe,
    PhonePipe,
    SortByPipe,
    ShortNumberPipe,
    SentenceCasePipe,
    FilterPipe,
    HasControlErrorPipe,
    HiddenPhonePipe,
    EnumValueLabelPipe,
    HiddenPipe,
    DaysBetweenPipe,
    BooleanValueLabelPipe
  ],
  exports: [
    TruncatePipe,
    FileSizePipe,
    StartCasePipe,
    SafeHtmlPipe,
    SafePureHtmlPipe,
    SafeResourceUrlPipe,
    PhonePipe,
    SortByPipe,
    ShortNumberPipe,
    SentenceCasePipe,
    FilterPipe,
    HasControlErrorPipe,
    HiddenPhonePipe,
    EnumValueLabelPipe,
    HiddenPipe,
    DaysBetweenPipe,
    BooleanValueLabelPipe
  ],
  providers: [HiddenPhonePipe],
})
export class PipesModule {}
