import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class AppBarTitleService {
    get title(): string {
        return this.title;
    }
    set title(value: string) {
        this.titleChange.next(value);
    }

    private titleSubject = new Subject<string>();
    get titleChange() {
        return this.titleSubject;
    }

    clear() {
      this.title = '';
    }
}
