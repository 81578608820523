import { Component, Input } from '@angular/core';
import { ApplicationStatus } from 'common';
import { ApplicationData } from '../../application/application.model';

@Component({
  selector: 'ifp-application-summary',
  templateUrl: './application-summary.component.html',
  styleUrls: ['./application-summary.component.scss']
})
export class ApplicationSummaryComponent {

  @Input() application: ApplicationData;

  get status() { return ApplicationStatus; }

  constructor() { }

}
