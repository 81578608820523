import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder, UntypedFormGroup } from "@angular/forms";
import {
  CountdownComponent,
  CountdownConfig,
  CountdownEvent,
  CountdownStatus,
} from "ngx-countdown";
import { MfaData } from "../../const/models/mfa.model";
import { getCountdownConfig, getFormModel } from "../../const/data/mfa-data";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { VerifyMfaCodeData } from "../../const/models/verify-mfa-code-data.model";
import { FormErrorsConditionOperator } from "projects/common/src/lib/ui/enums/form-errors-condition-operator.enum";
import { MfaActionsInternalService } from "../../services/mfa-actions.internal.service";
import { MfaService } from "../../services/mfa.service";
import { MfaErrorCodeType } from "../../const/enums/mfa-error-code-type.enum";
import { MessageService } from "projects/common/src/core/message/message.service";
import { MfaPhoneStatus } from "../../const/enums/mfa-phone-status.enum";

@UntilDestroy()
@Component({
  selector: "ifc-mfa-content",
  templateUrl: "./mfa-content.component.html",
  styleUrls: ["./mfa-content.component.scss"],
})
export class MfaContentComponent implements OnInit {
  @ViewChild("countdown", { static: false })
  private countdown: CountdownComponent;

  data: MfaData;
  @Input() set mfaData(data: MfaData) {
    this.data = data;
    this.initMfaCodeVerificationFlow(data);
  }

  @Output()
  updatePhoneNumber = new EventEmitter<void>();

  @Output()
  verify = new EventEmitter<VerifyMfaCodeData>();

  @Output()
  sendNewCode = new EventEmitter<string>();

  form: UntypedFormGroup;
  sendingNewCodeBlocked: boolean = false;
  countdownConfig: CountdownConfig;
  codeMask: string;

  readonly ConditionOperator = FormErrorsConditionOperator;
  readonly MfaPhoneStatus = MfaPhoneStatus;

  constructor(
    private formBuilder: FormBuilder,
    private mfaActions: MfaActionsInternalService,
    private mfaService: MfaService,
    private messageService: MessageService
  ) {
    this.initForm();
  }

  ngOnInit(): void {
    this.initActions();
  }

  private initForm() {
    this.form = this.formBuilder.group(getFormModel);
  }

  private initActions(): void {
    this.form.controls["code"].valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => this.clearInvalidCodeError());

    this.mfaActions.invalidCode$.pipe(untilDestroyed(this)).subscribe(() => {
      this.setInvalidCodeError();
    });
  }

  private initMfaCodeVerificationFlow(data: MfaData): void {
    if (
      !data ||
      !data.mfaCodeData?.codeGenerationIntervalSeconds ||
      !data.mfaCodeData?.codeLength
    ) {
      console.error(
        `Invalid 'mfaCodeData' state, current state: ${data?.mfaCodeData}`
      );
      return;
    }

    this.countdownConfig = getCountdownConfig(
      this.data.mfaCodeData.codeGenerationIntervalSeconds
    );
    this.codeMask = this.getCodeMask(this.data.mfaCodeData.codeLength);

    if (data.mfaCodeData?.error === MfaErrorCodeType.CooldownNotReached) {
      this.warningSendingNewCode();
      this.beginCountdown();
    } else if (data.mfaCodeData.codeSent) {
      this.beginCountdown();
      this.messageService.info("New code has been sent");
    }
  }

  private clearInvalidCodeError() {
    if (this.form.controls["code"].hasError(MfaErrorCodeType.InvalidMfaCode)) {
      delete this.form.controls["code"].errors?.[
        MfaErrorCodeType.InvalidMfaCode
      ];
    }
  }

  private setInvalidCodeError() {
    this.form.controls["code"].setErrors({
      ...this.form.controls["code"].errors,
      [MfaErrorCodeType.InvalidMfaCode]: true,
    });
  }

  private getCodeMask(codeLength: number) {
    return "0".repeat(codeLength);
  }

  onCountdownEvent(event: CountdownEvent) {
    if (event.action === "start") {
      this.sendingNewCodeBlocked = true;
    }
    if (event.action === "done" && event.status === CountdownStatus.done) {
      this.countdown.restart();
      this.sendingNewCodeBlocked = false;
    }
  }

  onClickUpdatePhoneNumber() {
    this.updatePhoneNumber.emit();
  }

  onClickSendNewCode(mfaToken: string) {
    if (this.sendingNewCodeBlocked) {
      this.warningSendingNewCode();
      return;
    }

    this.sendingNewCodeBlocked = true;
    this.sendNewCode.emit(mfaToken);
  }

  private warningSendingNewCode() {
    this.mfaService.warningSendingNewCode(
      this.data.mfaCodeData.codeGenerationIntervalSeconds
    );
  }

  private beginCountdown() {
    setTimeout(() => this.countdown.begin());
  }

  onClickVerify() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const formValues = this.form.value;
    const data: VerifyMfaCodeData = {
      mfaData: this.data,
      mfaCode: formValues["code"],
      trustDevice: formValues["trustDevice"],
    };

    this.verify.emit(data);
  }
}
