import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { BrandingService } from "common";
import { TermLoanChartContentComponent } from "../term-loan-card-chart.component";

@Component({
  selector: "ifp-sidenav-term-loan-card-chart",
  templateUrl: "./sidenav-term-loan-card-chart.component.html",
  styleUrls: ["./sidenav-term-loan-card-chart.component.scss"],
})
export class SidenavTermLoanChartContentComponent extends TermLoanChartContentComponent {
  constructor(router: Router, brandingService: BrandingService) {
    super(router, brandingService);
    this.displayYAxes = true;
  }
}
