import { map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { ConfigService } from "../config/config.service";
import { ResourceService } from "../resource/resource.service";
import { DocumentQueryParams } from "./document.model";
import {
  EntityDocumentQueryResult,
  EntityRecentDocuments,
} from "./entity-documents.model";

@Injectable({
  providedIn: "root",
})
export class EntityDocumentsService {
  private readonly baseUrl = `${this.configService.environment.entities.url}/api/files`;

  constructor(
    private http: HttpClient,
    private resourceService: ResourceService,
    private configService: ConfigService
  ) {}

  query(
    entityId: number,
    params: DocumentQueryParams
  ): Observable<EntityDocumentQueryResult> {
    return this.http.get<EntityDocumentQueryResult>(`${this.baseUrl}`, {
      params: <any>{ ...params, entityId },
    });
  }

  getRecentDocuments(entityId: number): Observable<EntityRecentDocuments[]> {
    const query: DocumentQueryParams = {
      limit: 5,
      sort: "-createdOn",
    };

    return this.query(entityId, query).pipe(
      map((query) => {
        return query.values?.map((data) => {
          return {
            entityId: data?.entityId,
            id: data?.id,
            date: data?.uploadDate,
            name: data?.name,
            category: data?.category,
          };
        });
      })
    );
  }

  download(fileId: number) {
    this.resourceService.download(`${this.baseUrl}/${fileId}`);
  }
}
