import { Component, OnInit, Input, Output, EventEmitter, ViewChild, SimpleChanges, OnChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, startWith } from 'rxjs/operators';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatErrorData } from './models/mat-error-data.model';

@Component({
  selector: 'ifc-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.css']
})
export class AutocompleteComponent implements OnInit, OnChanges {

  @ViewChild(MatAutocompleteTrigger, { static: true }) trigger;

  constructor() { }

  @Input()
  autocompleteDisplayWith = (option: any) =>
    option ? this.optionLabel(option) : undefined

  @Output()
  autocompleteOptionSelected = new EventEmitter<any>();

  @Output()
  clearInput = new EventEmitter<any>();

  @Input()
  fc: UntypedFormControl;

  @Input()
  label: string;

  @Input()
  errors: MatErrorData[];

  ngOnInit() {
    this.optionsFiltered = this.fc.valueChanges.pipe(
      startWith(this.fc.value),
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(this.optionFilter.bind(this))
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.disabled) {
      this.fc.disable();
    } else {
      this.fc.enable();
    }
  }


  optionLabel = (option: any) => {
    let optionLabel;
    if(!this.optionLabelKey2 || !option[this.optionLabelKey2])
      optionLabel = option[this.optionLabelKey] ? option[this.optionLabelKey] : option;

    else if(option[this.optionLabelKey])
      optionLabel = `${option[this.optionLabelKey]} - ${option[this.optionLabelKey2]}`;

    else
      optionLabel = option[this.optionLabelKey2] ? option[this.optionLabelKey2] : option;

    return optionLabel;
  }

  @Input()
  optionLabelKey: string;

  @Input()
  optionLabelKey2: string;

  @Input()
  options: any[];

  optionsFiltered: Observable<string[]>;

  @Input()
  optionFilter: (input: string) => Observable<any[]> =
    input => of(this.options.filter(it => it.label.toLowerCase().includes(input)))

  @Input()
  placeholder: string;

  @Input()
  readonly: boolean;

  @Input()
  showAvatar: boolean;

  @Input()
  disabled: boolean;

  @Input()
  valueClear = () => {
    this.fc.setValue('');
    this.clearInput.emit('');
  }

  valueEmpty = true;

}
