<article class="container">
  <header>
    <div class="icon">
      <mat-icon class="icon__content">lock</mat-icon>
    </div>

    <h1 class="heading">
      2-Factor Verification
    </h1>
  </header>

  <ng-container *ngIf="data.phoneStatus === MfaPhoneStatus.Unverified || data.phoneStatus === MfaPhoneStatus.Verified; else missingPhoneNumber">
    <ifc-mfa-content [mfaData]="data"
                     (updatePhoneNumber)="onUpdatePhoneNumber()"
                     (verify)="onVerify($event)"
                     (sendNewCode)="onSendNewCode($event)">
    </ifc-mfa-content>
  </ng-container>
</article>

<ng-template #missingPhoneNumber>
  <ng-container *ngIf="data.config.canAddPhoneNumberIfMissing; else mfaNotPossible">
    <ifc-mfa-action-needed actionLabel="ADD PHONE NUMBER"
                           (action)="onAddPhoneNumber()">
      <ng-template #description>
        To keep your account safe, we want to make sure it's really you. Provide phone number to receive a verification code.
      </ng-template>
    </ifc-mfa-action-needed>
  </ng-container>
</ng-template>

<ng-template #mfaNotPossible>
  <ifc-mfa-action-needed actionLabel="BACK TO LOGIN"
                         (action)="onBackToLogin()">
    <ng-template #description>
      To keep your account safe, we want to make sure it's really you. Provide phone number to your Business Advisor to receive a verification code.
    </ng-template>
  </ifc-mfa-action-needed>
</ng-template>
