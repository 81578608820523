import { CoverageData, HartfordCoverage, HartfordCoverageLimit, HartfordResponse } from "./insurance.model";
import * as _ from 'lodash';

export class HartfordHelper {

    static getHartfordCoverage(data: HartfordResponse): CoverageData {
        if (!data || !data.Coverage) return undefined;
        const result: CoverageData = {
            damagesToPremises: this.getHartfordLimitAmount(data.Coverage, 'FIRDM'),
            liabilityAmount: this.getHartfordLimitAmount(data.Coverage, 'EAOCC'),
            medicalExpenses: this.getHartfordLimitAmount(data.Coverage, 'MEDEX'),
            generalAggregate: this.getHartfordLimitAmount(data.Coverage, 'GENAG'),
            productOperations: this.getHartfordLimitAmount(data.Coverage, 'PRDCO'),
            personalInjury: this.getHartfordLimitAmount(data.Coverage, 'PIADV'),
            liabilityDeductible: this.getHartfordLimitCode(data.Coverage, 'EAOCC'),
        };
        return result;
    }

    static getHartfordLimitAmount(coverage: HartfordCoverage[], code: string): number {
        const data: HartfordCoverage = _.find(coverage, (i: HartfordCoverage) => i.coverageCode && i.coverageCode.toUpperCase() === code);
        const limit: HartfordCoverageLimit = data && data.limit ? _.first(data.limit) : undefined;
        return limit ? limit.limitAmount : 0;
    }

    static getHartfordLimitCode(coverage: HartfordCoverage[], code: string): string {
        const data: HartfordCoverage = _.find(coverage, (i: HartfordCoverage) => i.coverageCode && i.coverageCode.toUpperCase() === code);
        const limit: HartfordCoverageLimit = data && data.limit
            ? _.first(_.filter(data.limit, (i: HartfordCoverageLimit) => i.limitCode))
            : undefined;
        return limit ? limit.limitCode : undefined;
    }

}
