import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApplicationtState } from "../models/application-state.model";
import { StateService } from "common";
import { ApplicationData } from "../../application.model";

const initialState: ApplicationtState = null;

@Injectable({
  providedIn: "root",
})
export class ApplicationStateService extends StateService<ApplicationtState> {
  constructor() {
    super(initialState);
  }

  getApplication$(id: number): Observable<ApplicationData> {
    return this.select((state: ApplicationtState) =>
      state?.application?.id === id ? state?.application : null
    );
  }

  setApplication(application: ApplicationData): void {
    const newState = {
      ...this.state,
      application,
    };
    this.setState(newState);
  }

  isApplicationStateLoaded(id: number): boolean {
    return this.state?.application?.id === id;
  }

  clearState(): void {
    this.initState(initialState);
  }
}
