import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  AppPageComponent,
  AppBarComponent,
  AppBarTitleComponent,
  AppFooterComponent,
  AppBarContactComponent,
  AppBarCloseAction
} from 'common';
import { OAuthGuard } from '../../guards/oauth.guard';
import { ReferralCodeMesssageComponent } from './referral-code-message.component';
import { ReferralCodeReminderComponent } from './referral-code-reminder.component';


const routes: Routes = [
  {
    path: 'referral-code',
    canActivate: [OAuthGuard],
    component: AppPageComponent,
    data: {
      appPage: {
        contentClass: 'simple',
        contentAlignment: 'center-start',
        contentWithoutPadding: true
      },
      appBar: {
        closeAction: AppBarCloseAction.HOME,
        logoHidden: true,
        stickyDisabled: true
      }
    },
    children: [
      {
        path: '',
        outlet: 'app-bar',
        component: AppBarComponent,
        children: [
          {
            path: '',
            outlet: 'title',
            component: AppBarTitleComponent,
          },
          {
            path: '',
            outlet: 'right',
            component: AppBarContactComponent
          }
        ]
      },
      {
        path: '',
        outlet: 'app-footer',
        component: AppFooterComponent,
        data: {
          appFooter: {
            full: false,
            hideText: true,
            showEncryptionInfo: true
          }
        }
      },
      {
        path: '',
        component: ReferralCodeMesssageComponent
      },
      {
        path: 'reminder',
        component: ReferralCodeReminderComponent
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class ReferralCodeRoutingModule { }
