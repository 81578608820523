import { NgModule, inject } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppPageComponent, AppBarComponent, AppBarCloseAction, AppBarTitleComponent, AboutService, AboutComponent } from 'common';

import { OAuthGuard } from '../../guards/oauth.guard';

const routes: Routes = [
  {
    path: 'about',
    component: AppPageComponent,
    data: {
      appBar: {
        closeAction: AppBarCloseAction.HOME,
        logoHidden: true,
        stickyDisabled: true
      }
    },
    canActivate: [OAuthGuard],
    children: [
      {
        path: '',
        outlet: 'app-bar',
        component: AppBarComponent,
        children: [
          {
            path: '',
            outlet: 'title',
            component: AppBarTitleComponent,
          }
        ]
      },
      {
        path: '',
        component: AboutComponent,
        resolve: {
            about: () => inject(AboutService).customerNotes()
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AboutPageRoutingModule { }
