import { Component } from '@angular/core';
import { AppBarTitleService } from 'common';
import { ReferralCodeBaseComponent } from './referral-code-base.component';
import { ReminderService } from '../reminder/reminder.service';
import { ReminderData, Reminders } from '../reminder/reminder.model';
import { CustomerFacade } from '../customer/domain/+state/customer.facade';
import { EntityFacade } from '../entity/domain/+state/entity.facade';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ifp-referral-code',
  templateUrl: './referral-code.component.html',
  styleUrls: ['./referral-code.component.scss']
})
export class ReferralCodeReminderComponent extends  ReferralCodeBaseComponent<ReminderData> {

  constructor(
    appBarTitleService: AppBarTitleService,
    reminderService: ReminderService,
    customerFacade: CustomerFacade,
    entityFacade: EntityFacade,
    route: ActivatedRoute
  ) {
    super(appBarTitleService, reminderService, customerFacade, entityFacade, route);
    super._searchCondition = { name: Reminders.Referral }
    sessionStorage.setItem('referralPageViewed', JSON.stringify(true));
  }
}
