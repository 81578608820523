import { Injectable } from "@angular/core";
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { map, switchMap, take } from "rxjs/operators";
import { Observable } from "rxjs";
import * as _ from "lodash";
import { ReminderService } from "../reminder/reminder.service";
import { ReminderData, Reminders } from "../reminder/reminder.model";
import { EntityFacade } from "../entity/domain/+state/entity.facade";
import { RouteHelper, parseNumber } from "common";

@Injectable({
  providedIn: "root",
})
export class ReferralCodeGuard implements CanActivate {
  constructor(
    private router: Router,
    private reminderService: ReminderService,
    private entityFacade: EntityFacade
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const companyId = parseNumber(RouteHelper.getQueryParamValueByName(route, "companyId"));
    return this.entityFacade.getEntity$(companyId).pipe(
      take(1),
      switchMap((entity) => this.reminderService.query(entity.id)),
      map((reminders) => this.validate(reminders))
    );
  }

  validate(reminders): boolean {
    if (
      reminders &&
      _.some(reminders, (item: ReminderData) => {
        return item.name === Reminders.Referral;
      }) &&
      !JSON.parse(sessionStorage.getItem("referralPageViewed"))
    ) {
      this.router.navigate(["referral-code/reminder"], { replaceUrl: true });
      return false;
    } else return true;
  }
}
