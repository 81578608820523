import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import {
  AppBarCloseActionService,
  ApplicationOfferBundleData,
  BrandingService,
  LinkBankData,
  OfferActionIds,
  OfferActionsService,
  ShowDisclosuresData,
  parseNumber,
} from "common";
import { OpenDocumentsData } from "projects/common/src/lib/offer/models/open-documents-data.model";
import { combineLatest, Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { BankingService } from "../../../bank-account/banking.service";
import { OfferBundleService } from "../../offer-bundle.service";
import { OfferDetailsData } from "./models/offer-details-data.model";
import { ApplicationFacade } from "../../../application/domain/+state/application.facade";

@UntilDestroy()
@Injectable()
export class OfferDetailsFacade {
  constructor(
    private offerBundleService: OfferBundleService,
    private brandingService: BrandingService,
    private appBarCloseActionService: AppBarCloseActionService,
    private router: Router,
    private route: ActivatedRoute,
    private bankingService: BankingService,
    private offerActionsService: OfferActionsService,
    private applicationFacade: ApplicationFacade
  ) {}

  initAppBar(applicationId: number) {
    this.appBarCloseActionService.closing$
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.navigateToOffersSummary(applicationId);
      });
  }

  initData$(): Observable<OfferDetailsData> {
    return combineLatest([
      this.route.data,
      this.route.pathFromRoot[this.route.pathFromRoot.length - 2].params,
    ]).pipe(
      untilDestroyed(this),
      switchMap(([data, params]) =>
        combineLatest([
          this.applicationFacade.getApplication$(parseNumber(params.appId)),
          this.offerBundleService.getOfferBundlesByAppId(parseNumber(params.appId)),
          this.brandingService.getBranding$(),
        ]).pipe(
          map(([application, offerBundles, companyData]) => {
            this.initAppBar(parseNumber(params.appId));
            const offerId = parseNumber(params.offerId);
            const currentOfferBundle = offerBundles.find(
              (o) => o.id === offerId
            );

            return {
              productCode: data.productCode,
              application,
              offerBundles,
              currentOfferBundle,
              companyData,
            };
          })
        )
      )
    );
  }

  refreshCurrentOfferBundle(
    applicationId: number,
    currentOfferBundleId: number
  ): Observable<ApplicationOfferBundleData> {
    return this.offerBundleService.getOfferBundle(
      applicationId,
      currentOfferBundleId,
      false
    );
  }

  initActions(): void {
    this.offerActionsService.openDocumentsDetails$
      .pipe(untilDestroyed(this))
      .subscribe((data: OpenDocumentsData) => {
        this.openDocuments(data.applicationId, data.offerBundleId);
      });

    this.offerActionsService.linkBank$
      .pipe(untilDestroyed(this))
      .subscribe((data: LinkBankData) => {
        this.linkYourBank(data.entityId);
      });

    this.offerActionsService.showDisclosures$
      .pipe(untilDestroyed(this))
      .subscribe((data: ShowDisclosuresData) => {
        this.showDisclosures(data);
      });

    this.offerActionsService.navigateToOffersSummary$
      .pipe(untilDestroyed(this))
      .subscribe((applicationId: number) => {
        this.navigateToOffersSummary(applicationId);
      });

    this.offerActionsService.navigateToOfferDetails$
      .pipe(untilDestroyed(this))
      .subscribe((data: OfferActionIds) => {
        this.navigateToOfferDetails(data);
      });
  }

  watchLinkingBankSuccessfully() {
    return this.bankingService.obsSync;
  }

  private linkYourBank(entiyId: number): void {
    this.bankingService.link(entiyId);
  }

  private openDocuments(applicationId: number, offerBundleId: number): void {
    this.router.navigate([
      `/application/${applicationId}/offer/${offerBundleId}/checkout/documents`,
    ]);
  }

  private showDisclosures(data: ShowDisclosuresData): void {
    const path = `application/${data.applicationId}/offer/${data.offerId}/disclosures`;
    const url = this.router.serializeUrl(this.router.createUrlTree([path]));
    window.open(url, "_blank");
  }

  private navigateToOffersSummary(applicationId: number): void {
    this.router.navigate([`/application/${applicationId}/offers/summary`]);
  }

  private navigateToOfferDetails(data: OfferActionIds): void {
    const productCode = data.productCode.toLowerCase();
    this.router.navigate([
      `/application/${data.applicationId}/offer/${data.offerId}/${productCode}`,
    ]);
  }
}
