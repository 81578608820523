import { NgModule } from '@angular/core';
import { CommonModule } from 'common';
import { SharedModule } from '../shared/shared.module';
import { ApplicationRoutingModule } from './application-routing.module';

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    ApplicationRoutingModule
  ]
})
export class ApplicationModule { }
