import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  ViewChild,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { ApplicationOffer } from "projects/common/src/lib/application/application.model";
import { ProductCode } from "projects/common/src/lib/product/product.model";
import { Payment } from "../../../models/offer.model";
import { OfferCalculatorService } from "../../../services/offer-calculator.service";

@Component({
  selector: "ifc-offer-payment-schedule-table",
  templateUrl: "./offer-payment-schedule-table.component.html",
  styleUrls: ["./offer-payment-schedule-table.component.scss"],
})
export class OfferPaymentScheduleTableComponent
  implements OnChanges, AfterViewInit
{
  @Input() offerDetail: ApplicationOffer;
  @Input() sampleDraw: number;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource = new MatTableDataSource<Payment>();
  displayedColumns: string[] = [
    "date",
    "amount",
    "principalAmount",
    "interestAmount",
    "balance",
    "totalInterestCost",
    "capitalRepayment",
    "totalPayback",
  ];

  constructor(private offerCalculatorService: OfferCalculatorService) {}

  ngOnChanges(): void {
    this.calculatePayments();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  calculatePayments() {
    let payments: Payment[];
    if (this.offerDetail.productCode == ProductCode.Term)
      payments = this.offerCalculatorService.getTermLoanPayments(this.offerDetail);
    else if (
      this.offerDetail.productCode == ProductCode.LOC ||
      this.offerDetail.productCode == ProductCode.Law
    )
      payments = this.offerCalculatorService.getLineOfCreditLoanPayments(
        this.offerDetail,
        this.sampleDraw
      );

    this.dataSource = new MatTableDataSource(payments);

    if (this.paginator) {
      this.paginator.firstPage();
      this.paginator.pageSize = 10;
      this.dataSource.paginator = this.paginator;
    }
  }
}
