import { NgModule } from '@angular/core';
import { CustomerKbaFormComponent } from './customer-kba-form/customer-kba-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from 'common';
import { CustomerKbaResultComponent } from './customer-kba-result/customer-kba-result.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
    imports: [
        SharedModule,
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [CustomerKbaFormComponent, CustomerKbaResultComponent]
})
export class CustomerCheckoutModule { }
