<mat-divider></mat-divider>
<div class="filter-toolbar">
  <div fxLayout="row" fxLayoutAlign="space-between center" class="table-top">
    <ifc-filters-active-list class="layout margin-sm" [filters]="activeFilters"
      (filterRemoved)="activeFilterRemoved($event, false)"></ifc-filters-active-list>
    <div class="layout padding-right-md" fxLayout="row" >

      <form [formGroup]="form" class="secondary-text" style="height: 24px !important;" [ngClass]="{
        'search-collapsed': showAccountsFilter === false,
        'search-expanded': showAccountsFilter === true}">
        <ifp-account-search-input [productCode]="productCode" (enter)="showAccountsFilter=$event" [entityId]="entity?.id" [excludedProductCode]="excludedProductCode" [fc]="form.get('search')" fxFlexFill (backAction)="closeSearchLoans()" (clearInput)="search(null)" (autocompleteOptionSelected)="search($event)">
        </ifp-account-search-input>
      </form>
      <button mat-icon-button (click)="filterDialog()" class="secondary-text" matTooltip="Filter">
        <mat-icon>filter_list</mat-icon>
      </button>
      <button mat-icon-button class="secondary-text" (click)="download(filters)">
        <mat-icon aria-label="Download">file_download</mat-icon>
      </button>
    </div>
  </div>
</div>

<ifc-app-scroll-container [layout2List]="true">
    <div class="message-box info" *ngIf="!loans?.length" fxLayout="row"
      fxLayoutGap="12px" fxLayoutAlign="start center">
      <mat-icon class="icon">info</mat-icon>
      <span>No data found</span>
    </div>

    <table mat-table matSort [dataSource]="loans"
      (matSortChange)="sortData()" *ngIf="loans?.length > 0" aria-label="Accounts list">
      <ng-container matColumnDef="favorite">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let loan" (mouseover)="setHoverLoan(loan.Id)" class="table-cell small"
          (click)="updateFavorite(loan.id, !isLoanFavorite(loan))">
            <mat-icon *ngIf="!isLoanFavorite(loan) && hoverLoanId !== loan.id">star_border</mat-icon>
            <mat-icon *ngIf="isLoanFavorite(loan) || hoverLoanId === loan.id" class="mat-green">star</mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="createdOn">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="createdOn"> Created on </th>
        <td mat-cell *matCellDef="let loan" class="table-cell" (click)="details(loan)">
          <span>{{loan?.createdOn | date:"mediumDate"}}</span><br>
        </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="description"> Case name </th>
        <td mat-cell *matCellDef="let loan" class="table-cell"
          (click)="details(loan)">
          <span>{{loan?.description}} </span><br>
        </td>
      </ng-container>

      <ng-container matColumnDef="externalId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="externalId"> Case number </th>
        <td mat-cell *matCellDef="let loan" class="table-cell"
          (click)="details(loan)">
          <span>{{loan?.externalId}} </span><br>
        </td>
      </ng-container>

      <ng-container matColumnDef="loanNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="loanNumber"> Loan number </th>
        <td mat-cell *matCellDef="let loan" class="table-cell"
          (click)="details(loan)">
          <span>{{loan?.loanNumber}} </span><br>
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="status"> Status </th>
        <td mat-cell *matCellDef="let row" class="table-cell" (click)="details(loan)" >
          <span *ngIf="row.status === 1"> Open </span>
          <ifp-account-status-icon-chip *ngIf="row.status !== 1 && row.status !== undefined"
          [status]="row.status"
          [accountStatusIconChipModel]="{ isLoanFullRepaymentDueDateSet: !!row.loanFullRepaymentDueDate, needsAttentionMessage: needsAttentionModel | needsAttentionModelToMessage : row.productCode}">
          </ifp-account-status-icon-chip>
        </td>
      </ng-container>

      <ng-container matColumnDef="creditLimit">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="creditLimit" class="right-align" arrowPosition="before" > Credit limit </th>
        <td mat-cell *matCellDef="let loan" class="table-cell right-align" (click)="details(loan)">
          <span>{{loan?.loanInfo?.creditLimit | currency:'$'}} </span><br>
        </td>
      </ng-container>

      <ng-container matColumnDef="availableFunds">
        <th mat-header-cell *matHeaderCellDef class="right-align" arrowPosition="before"> Available funds </th>
        <td mat-cell *matCellDef="let loan" class="table-cell right-align" (click)="details(loan)">
          {{loan?.loanInfo?.availableFunds | currency:'$'}} </td>
      </ng-container>

      <ng-container matColumnDef="outstandingBalance">
        <th mat-header-cell *matHeaderCellDef arrowPosition="before" class="right-align"> Outstanding balance </th>
        <td mat-cell *matCellDef="let loan" class="table-cell right-align" (click)="details(loan)">
          <span>{{loan?.loanInfo?.principalBalance | currency:'$'}}</span><br>
        </td>
      </ng-container>

      <ng-container matColumnDef="policyNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="policyNumber"> Level policy number </th>
        <td mat-cell *matCellDef="let loan" class="table-cell" (click)="details(loan)">
          <span>{{loan?.policyNumber}} </span><br>
        </td>
      </ng-container>

      <ng-container matColumnDef="policyLimit">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="policyLimit" arrowPosition="before" class="right-align"> Level policy limit </th>
        <td mat-cell *matCellDef="let loan" class="table-cell right-align" (click)="details(loan)">
          {{loan?.policyLimit | currency:'$'}} </td>
      </ng-container>

      <ng-container matColumnDef="upcomingPayment">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="upcomingPayment" arrowPosition="before" class="right-align"> Upcoming payment </th>
        <td mat-cell *matCellDef="let loan" class="table-cell right-align" (click)="details(loan)">
          {{loan?.loanInfo?.upcomingPayment | currency:'$'}} </td>
      </ng-container>

      <ng-container matColumnDef="upcomingPaymentDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="upcomingPaymentDate"> Upcoming payment date </th>
        <td mat-cell *matCellDef="let loan" class="table-cell" (click)="details(loan)">
          <span>{{loan?.loanInfo?.paymentDueDate | date:"mediumDate"}}</span><br>
        </td>
      </ng-container>

      <ng-container matColumnDef="more" stickyEnd>
        <th mat-header-cell *matHeaderCellDef></th>
        <td (click)="$event.stopPropagation()" mat-cell *matCellDef="let loan" class="action-icon"  ngClass.gt-md="action-icon actions-menu" ngClass.lt-lg="actions-menu-xs">
          <button *ngIf="customer && customer.kbaStatus === kbaVerificationStatus.Success && loan?.status !== loanStatus.Pending" mat-icon-button
            [matMenuTriggerFor]="actionsMenu" [matMenuTriggerData]="{loan: loan}" class="secondary-text more-actions-menu">
              <mat-icon>more_vert</mat-icon>
          </button>
        </td>
        <mat-menu #actionsMenu="matMenu">
          <ng-template matMenuContent let-loan="loan">
          <button mat-menu-item (click)="details(loan)">
            <mat-icon>description</mat-icon> <span>Details</span>
          </button>
          <button mat-menu-item (click)="draw(loan)" *ngIf="hasDrawAccess(loan)">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
              <div class="avatar medium letter mat-light-grey-bg">D</div> <span>Draw funds</span>
            </div>
          </button>
          <button mat-menu-item (click)="pay(loan)" *ngIf="hasPayAccess(loan)">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
              <div class="avatar medium letter mat-light-grey-bg">P</div> <span>Pay balance</span>
            </div>
          </button>
        </ng-template>
        </mat-menu>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="getDisplayedColumns(); sticky: true" class="table-header"></tr>
      <tr mat-row *matRowDef="let row; columns: getDisplayedColumns();" class="table-row"></tr>

    </table>
  </ifc-app-scroll-container>

  <mat-paginator #paginator [hidePageSize]="hidePageSize" [pageSizeOptions]="[10, 20, 50, 100]"
    (page)="setPage()" [length]="totalResultCount" showFirstLastButtons>
  </mat-paginator>
