import {
  MaintainedQueryParam,
  NavigationConfig,
} from "../models/navigation.model";
import { QueryParamsHandlingType } from "../models/query-params-handling-type.enum";

export const getNavigationConfig = (config?: NavigationConfig) => {
  return {
    ...defaultConfig,
    ...config,
  };
};

const defaultConfig: NavigationConfig = {
  queryParamsHandlingType: QueryParamsHandlingType.Replace,
  hardRefresh: false,
  queryParams: null,
  queryParamKeysToMerge: null,
};

export const getDefaultMaintainedQueryParam: MaintainedQueryParam[] = [
  { name: "companyId", excludePaths: ["sign-in", "sign-out"] },
  { name: "backTo" },
  { name: "campaign" },
];
