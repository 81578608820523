import { NgModule } from '@angular/core';
import { CommonModule } from 'common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';

import { BankAccountListComponent } from './bank-account-list/bank-account-list.component';
import { BankAccountRoutingModule } from './bank-account-routing.module';
import { TransferBankAccountWidgetComponent } from './transfer-bank-account-widget/transfer-bank-account-widget.component';
import { BankWidgetComponent } from './bank-widget/bank-widget.component';
import { BankAccountSearchComponent } from './bank-account-search/bank-account-search.component';
// eslint-disable-next-line max-len
import { BankAccountLinkFailedDialogComponent } from './bank-account-link-failed-dialog/bank-account-link-failed-dialog.component';
import { BankAccountLinkComponent } from './bank-account-link/bank-account-link.component';
import { BankAccountSearchInputComponent } from './bank-account-search-input/bank-account-search-input.component';
import { BankActionRequiredComponent } from './bank-action-required/bank-action-required.component';
import { BankAccountLinkSuccessDialogComponent } from './bank-account-link-success-dialog/bank-account-link-success-dialog.component';
import { BankAccountOauthComponent } from './bank-account-oauth/bank-account-oauth.component';

@NgModule({
    imports: [
        SharedModule,
        CommonModule,
        RouterModule,
        BankAccountRoutingModule
    ],
    declarations: [
        BankAccountListComponent,
        TransferBankAccountWidgetComponent,
        BankWidgetComponent,
        BankAccountSearchComponent,
        BankAccountLinkFailedDialogComponent,
        BankAccountLinkComponent,
        BankAccountSearchInputComponent,
        BankActionRequiredComponent,
        BankAccountLinkSuccessDialogComponent,
        BankAccountOauthComponent
    ]
})

export class BankAccountModule { }
