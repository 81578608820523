import { NativeDateAdapter } from '@angular/material/core';
import * as _moment from 'moment';
import {default as _rollupMoment} from 'moment';
import { Injectable } from "@angular/core";
const moment = _rollupMoment || _moment;

@Injectable()
export class FedDateAdapter extends NativeDateAdapter {
  format(date: Date): string {
    return moment(date).format('MM/DD/YYYY');
  }

  parse(value: any): Date | null {
    if (!moment(value, 'MM/DD/YYYY', true).isValid()) {
      return this.invalid();
    }
    return moment(value, 'MM/DD/YYYY', true).toDate();
  }
}
