<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
    <div class="aside" [ngClass.xs]="'xs'">
        <div>Current version</div>
        <div *ngIf="currentVersion.name">aka {{currentVersion.name}}</div>
        <div>r{{currentVersion.version}} ({{currentVersion.date | date: 'mediumDate'}})</div>
        <div>commit: {{ git.commit }}</div>
    </div>
    <div class="aside" [ngClass.xs]="'xs'">
        <div>Release notes</div>
        <ifc-release-note [notes]="currentVersion.notes"></ifc-release-note>
    </div>
</div>
<div fxLayout="column" [ngClass.xs]="'xs'">
    <h3>Previous updates</h3>
    <div *ngFor="let version of versions">
        <div>Version r{{version.version}}{{version.name ? ' aka ' + version.name : ''}} ({{version.date | date: 'mediumDate'}})</div>
        <ifc-release-note [notes]="version.notes"></ifc-release-note>
    </div>
</div>