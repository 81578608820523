import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AppBarActionsService, AppBarAction } from "./app-bar-actions.service";
import { debounceTime } from "rxjs/operators";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "ifc-app-bar-actions",
  templateUrl: "./app-bar-actions.component.html",
  styleUrls: ["./app-bar-actions.component.css"],
})
export class AppBarActionsComponent implements OnInit, OnDestroy {
  @Input() menuMobileAllowed = true;

  data: AppBarActionsComponentData;
  actions: AppBarAction[];

  constructor(
    private route: ActivatedRoute,
    private service: AppBarActionsService
  ) {
    this.actions = service.actions;
  }

  click(action: AppBarAction) {
    this.service.invoke(action.id);
  }

  ngOnInit() {
    this.route.data
      .pipe(untilDestroyed(this))
      .subscribe((it: AppBarActionsComponentData) => {
        this.data = it;
      });

    this.service.changed
      .pipe(debounceTime(500), untilDestroyed(this))
      .subscribe((it) => {
        this.actions = it;
      });
  }

  ngOnDestroy() {
    this.service.clearAll();
  }

  fixDisappearIOSBug() {
    const styleNode = document.createElement("style");
    styleNode.type = "text/css";
    styleNode.id = "panel-fix";
    styleNode.appendChild(
      document.createTextNode(".mat-menu-panel{overflow: initial !important;}")
    );
    document.getElementsByTagName("head")[0].appendChild(styleNode);
    setTimeout(() => {
      styleNode.remove();
    }, 500);
  }
}

export interface AppBarActionsComponentData {
  appBarActions: {
    menuMobile: boolean;
  };
}
