import { DomSanitizer } from '@angular/platform-browser';
import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { MessageService } from "../../core/message/message.service";
import { HttpClient } from "@angular/common/http";
import { EMPTY, Observable } from "rxjs";
import { GetFileData } from "./resource.model";

declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean;
  }
}

@Injectable({
  providedIn: "root",
})
export class ResourceService {
  constructor(
    private messageService: MessageService,
    private http: HttpClient,
    private domSanitizer: DomSanitizer
  ) {}


  getFileData(url: string, trustUrl: boolean = false, name?: string, params?: any): Observable<GetFileData> {
    return this.http
      .get(url, { responseType: "blob", observe: "response", params: params })
      .pipe(
        map((response) => {
          // Get the filename from the content-disposition filename header or default to "download.bin"
          let filename = name ? name : "download.bin";
          try {
            const disposition = response.headers.get("content-disposition");
            if (disposition && disposition.indexOf("attachment") !== -1) {
              const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              const matches = filenameRegex.exec(disposition);
              if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, "");
              }
            }
            let objectUrl: any = window.URL.createObjectURL(response.body);
            if(trustUrl){
              objectUrl = this.domSanitizer.bypassSecurityTrustUrl(objectUrl);
            }
            return { responseBody: response.body, url: objectUrl, filename };
          } catch (e) {}
        }),
        catchError((error) => {
          this.messageService.error(error);
          return EMPTY;
        })
      );
  }

  download(url: string, name?: string, params?: any) {
    this.getFileData(url, false, name, params).subscribe(
      (data: GetFileData) => {
        if (navigator.msSaveBlob)
          // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
          window.navigator.msSaveBlob(data.responseBody, data.filename);
        else {
          var linkElement = document.createElement("a");
          try {
            linkElement.setAttribute("href", data.url);
            linkElement.setAttribute("download", data.filename);

            var clickEvent = new MouseEvent("click", {
              view: window,
              bubbles: true,
              cancelable: false,
            });

            linkElement.dispatchEvent(clickEvent);
          } catch (ex) {
            console.log(ex);
          }
        }
      },
      (error) => {
        this.messageService.error(error);
      }
    );
  }
}

