import { NgModule } from "@angular/core";
import { UiModule } from "../ui/ui.module";
import { OfferSummaryModule } from "./features/offer-summary/offer-summary.module";
import { OfferDisclosuresModule } from "./features/offer-disclosures/offer-disclosures.module";
import { OfferDetailsModule } from "./features/offer-details/offer-details.module";

@NgModule({
  imports: [
    UiModule,
    OfferSummaryModule,
    OfferDisclosuresModule,
    OfferDetailsModule,
  ],
  exports: [OfferSummaryModule, OfferDisclosuresModule, OfferDetailsModule],
})
export class OfferModule {}
