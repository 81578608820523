import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ifp-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.scss']
})
export class NotificationDialogComponent {

  public title: string;
  public message: string;

  constructor(public dialogRef: MatDialogRef<NotificationDialogComponent>) { }
}
