import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppBarTitleService, ApplicationStage, OptInResponseDto, RenewalEligibilityDto } from 'common';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProductCode } from '../../loan/domain/models/loan.model';
import { RenewalService } from '../../renewal/renewal.service';

@Component({
  selector: 'ifp-renewal-eligible-reminder',
  templateUrl: './renewal-eligible-reminder.component.html',
  styleUrls: ['./renewal-eligible-reminder.component.scss']
})
export class RenewalEligibleReminderComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;

  public elibility: RenewalEligibilityDto;

  constructor(
    private router: Router,
    private appBarTitleService: AppBarTitleService,
    private renewalService: RenewalService
  ) {
    this._unsubscribeAll = new Subject();
    sessionStorage.setItem('renewalEligibilityPageViewed', JSON.stringify(true));
  }

  get ProductCode() { return ProductCode; }

  ngOnInit() {
    this.appBarTitleService.title = "Renew your loan";
    this.renewalService.obsCurrentrenewalEligibility
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(data => this.elibility = data);
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  optIn() {
    this.renewalService.putOptIn(this.elibility?.loanId)
      .subscribe((data: OptInResponseDto) => {
        if (data?.renewalApplicationStage === ApplicationStage.ApplicationIncomplete) {
          this.renewalService.optInChanged.next(data);
          this.router.navigate([`account/${data?.loanId}/renewal/documents`]);
        } else {
          this.router.navigate(['dashboard']);
        }

      });
  }

}
