import { Component, Input, EventEmitter, Output } from '@angular/core';
import { FilterParameter } from '../../../filter/filter-parameter.model';

@Component({
  selector: 'ifc-filters-active-list',
  templateUrl: './filters-active-list.component.html',
  styleUrls: ['./filters-active-list.component.css']
})
export class FiltersActiveListComponent  {

  private _filters: FilterParameter[];

  @Input()
  set filters(filters: FilterParameter[]) {
    if (!filters) return;

    this._filters = filters;
  }

  get filters() {
    return this._filters;
  }

  @Output()
  filterRemoved = new EventEmitter<FilterParameter[]>();

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;

  constructor() {

  }

  remove(filter: FilterParameter): void {
    const removed: FilterParameter[] = [];
    removed.push(filter);
    this.filterRemoved.emit(removed);
  }

  clear(): void {
    this.filterRemoved.emit(this.filters);
  }

}
