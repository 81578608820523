import { Component, OnInit, Input } from '@angular/core';
import { BankAccountRole } from '../bank-account.model';
import { BankAccount, EnumHelper } from 'common';

@Component({
  selector: 'ifp-transfer-bank-account-widget',
  templateUrl: './transfer-bank-account-widget.component.html',
  styleUrls: ['./transfer-bank-account-widget.component.css']
})
export class TransferBankAccountWidgetComponent implements OnInit {

  constructor() { }

  get BankAccountRole() { return BankAccountRole; }

  @Input()
  bank: BankAccount;

  @Input()
  type: BankAccountRole;

  title: string;

  ngOnInit() {
    this.title = EnumHelper.getNameFromValue(BankAccountRole, this.type) + ' bank';
  }
}
