import { Component, EventEmitter, Input, Output } from "@angular/core";
import { OfferBundlePreview } from "../../../models/offer-bundle-preview.model";
import { OfferProductIds } from "../../../models/offer-action-ids.model";

@Component({
  selector: "ifc-offer-repayment-navigation",
  templateUrl: "./offer-repayment-navigation.component.html",
  styleUrls: ["./offer-repayment-navigation.component.scss"],
})
export class OfferRepaymentNavigationComponent {
  @Input() offerBundles: OfferBundlePreview[];
  @Input() currentOfferBundle: OfferBundlePreview;

  @Output()
  changeOffer = new EventEmitter<OfferProductIds>();

  click(offerBundle: OfferBundlePreview) {
    const data: OfferProductIds = {
      offerId: offerBundle.offerBundleId,
      productCode: offerBundle.productCode,
    };
    this.changeOffer.emit(data);
  }
}
