import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CampaignInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let targetRequest = req;
    const campaign = sessionStorage.getItem('campaign');
    if (campaign) {
      targetRequest = req.clone({
        headers: req.headers.append('X-Idea-Campaign-Id', campaign)
      });
    }

    return next.handle(targetRequest);
  }
}
