import { NgModule } from "@angular/core";
import { CommonModule, OfferModule as OfferCommonModule } from "common";
import { SharedModule } from "../shared/shared.module";
import { OfferSummaryComponent } from "./features/offer-summary/offer-summary.component";
import { OfferRoutingModule } from "./offer-routing.module";
import { OfferDisclosuresComponent } from "./features/offer-disclosures/offer-disclosures.component";
import { OfferDetailsComponent } from "./features/offer-details/offer-details.component";
import { UppyAngularDashboardModule } from "@uppy/angular";
import { OfferCheckoutRequirementItemComponent } from "./features/offer-documents/components/offer-checkout-requirement-item/offer-checkout-requirement-item.component";
import { OfferCheckoutRequirementListComponent } from "./features/offer-documents/components/offer-checkout-requirement-list/offer-checkout-requirement-list.component";
import { ProgressBarDialogComponent } from "./features/offer-documents/components/progress-bar-dialog/progress-bar-dialog.component";
import { OfferDocumentsComponent } from "./features/offer-documents/offer-documents.component";
import { UploadFileDialogComponent } from "./features/offer-documents/components/upload-file-dialog/upload-file-dialog.component";
import { OfferSelectButtonComponent } from "./features/offer-details/components/offer-select-button/offer-select-button.component";

@NgModule({
  imports: [
    OfferCommonModule,
    CommonModule,
    SharedModule,
    OfferRoutingModule,
    UppyAngularDashboardModule,
  ],
  declarations: [
    OfferSummaryComponent,
    OfferDisclosuresComponent,
    OfferDetailsComponent,
    OfferDocumentsComponent,
    OfferCheckoutRequirementItemComponent,
    OfferCheckoutRequirementListComponent,
    UploadFileDialogComponent,
    ProgressBarDialogComponent,
    OfferSelectButtonComponent,
  ],
})
export class OfferModule {}
