import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { ProductCode } from "projects/common/src/lib/product/product.model";
import { OfferBundlePreview } from "../../../models/offer-bundle-preview.model";
import { OfferBundlesSummary } from "../../../models/offer-bundles-summary.model";
import { OfferProductIds } from "../../../models/offer-action-ids.model";

@Component({
  selector: "ifc-offer-product-navigation",
  templateUrl: "./offer-product-navigation.component.html",
  styleUrls: ["./offer-product-navigation.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class OfferProductNavigationComponent implements OnChanges {
  @Input() offerBundlesSummary: OfferBundlesSummary;

  currentProductCode: ProductCode;
  ProductCode = ProductCode;

  @Output()
  changeOffer = new EventEmitter<OfferProductIds>();

  ngOnChanges() {
    this.currentProductCode =
      this.offerBundlesSummary.currentOfferBundle.productCode;
  }

  onClickProduct(offerBundle: OfferBundlePreview) {
    const data: OfferProductIds = {
      offerId: offerBundle.offerBundleId,
      productCode: offerBundle.productCode,
    };
    this.changeOffer.emit(data);
  }
}
