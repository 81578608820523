import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, from, of } from 'rxjs';
import { concatMap, first } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SyncGuardHelper implements CanActivate {
    // TODO [RYNO-8500] refactor due to second injector inside our app
    // singleton with multiple instances
    // multiple observables
    public constructor(public injector: Injector) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return from(route.data.syncGuards).pipe(concatMap((value: any) => {
            const guard = this.injector.get<any>(value);
            const result = guard.canActivate(route, state);
            if (result instanceof Observable) {
                return result;
            } else if (result instanceof Promise) {
                return from(result);
            } else return of(result);
        }), first((x) => x === false || x instanceof UrlTree, true));
    }
}
