import { CacheHeaders, CacheTypes } from "common";

export const cacheSettings = {
  noCache: {
    [CacheHeaders.TTL]: "0",
    [CacheHeaders.CacheType]: CacheTypes.InMemory,
  },
  shortLived: {
    [CacheHeaders.TTL]: "3", // 3 seconds - average page load duration
    [CacheHeaders.CacheType]: CacheTypes.InMemory,
  },
  mediumLived: {
    [CacheHeaders.TTL]: "120", // 2 minutes
    [CacheHeaders.CacheType]: CacheTypes.InMemory,
  },
  longLived: {
    [CacheHeaders.TTL]: "1800", // half-hour
    [CacheHeaders.CacheType]: CacheTypes.LocalStorage,
  },
};
