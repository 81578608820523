import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { switchMap, tap } from "rxjs/operators";
import { ApplicationData } from "../../application/application.model";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ApplicationFacade } from "../../application/domain/+state/application.facade";
import { EntityFacade } from "../../entity/domain/+state/entity.facade";
import { parseNumber } from "common";

@UntilDestroy()
@Component({
  selector: "ifp-application-select",
  templateUrl: "./application-select.component.html",
  styleUrls: ["./application-select.component.scss"],
})
export class ApplicationSelectComponent implements OnInit {
  applications: ApplicationData[];
  currentApplication: ApplicationData;

  constructor(
    private applicationFacade: ApplicationFacade,
    private entityFacade: EntityFacade,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.initApplications();
  }

  private initApplications() {
    this.route.params
      .pipe(
        untilDestroyed(this),
        switchMap((params) =>
          this.entityFacade.getEntity$(parseNumber(params.companyId)).pipe(
            switchMap((entity) =>
              this.applicationFacade.getApplications$(entity.id).pipe(
                tap((applications: ApplicationData[]) => {
                  const appId = parseNumber(params.appId);
                  this.applications = applications;
                  this.currentApplication = this.applications?.find(
                    (app) => app.id === appId
                  );
                })
              )
            )
          )
        )
      )
      .subscribe();
  }

  onChangeApplication(applicationId: number): void {
    const currentUrl = this.router.url;
    if (currentUrl?.includes("/bank-account")) {
      this.router.navigate([`/application/${applicationId}/bank-account`]);
      return;
    }
    this.router.navigate([`/application/${applicationId}/documents`]);
  }
}
