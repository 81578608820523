import { Component, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoanData } from '../domain/models/loan.model';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'ifp-loan-description-dialog',
  templateUrl: './loan-description-dialog.component.html',
  styleUrls: ['./loan-description-dialog.component.scss']
})
export class LoanDescriptionDialogComponent {

  description: string;

  constructor(public dialogRef: MatDialogRef<LoanDescriptionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  static show(dialog: MatDialog, loan: LoanData): Observable<any> {
    return dialog.open(LoanDescriptionDialogComponent, {
      width: '510px',
      data: { loan: loan },
      closeOnNavigation: true,
    }).afterClosed();
  }
}
