import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { MfaService } from "../services/mfa.service";
import { MfaDataStatus } from "../const/enums/mfa-data-status.enum";

@Injectable()
export class MfaGuard implements CanActivate {
  constructor(private mfaService: MfaService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const validityData = this.mfaService.getMfaValidityData();

    if (validityData.status !== MfaDataStatus.Valid) {
      this.mfaService.handleMfaError(
        "[Guard]: Invalid mfaData to handle mfa flow"
      );
      return false;
    }
    return true;
  }
}
