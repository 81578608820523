import { Injectable } from "@angular/core";
import { Uppy, UppyFile, UploadResult } from "@uppy/core";
import { ConfigService, OAuthService } from "common";

@Injectable()
export class ProgressBarDialogService {
  
  generateEndpointCallback: (meta: any) => string;

  constructor(
    private configService: ConfigService,
    private authService: OAuthService
    ) {}
    
  setEndpointGenerator(generateEndpoint: (meta: any) => string) {
    this.generateEndpointCallback = generateEndpoint;
  }
  
  public prepairUppyAndStartUpload(
    uppy: Uppy,
    files: UppyFile[]
  ): Promise<UploadResult> {
    this.clearAllFiles(uppy);
    this.setFilesToUppy(uppy, files);
    this.setUppyFilesEndpoints(uppy);

    return uppy.upload();
  }

  private clearAllFiles(uppy: Uppy) {
    const files = uppy.getFiles();
    files.forEach((file) => {
      uppy.removeFile(file.id, "cancel-all");
    });
  }

  private setFilesToUppy(uppy: Uppy, filesToUpload: UppyFile[]) {
    filesToUpload.forEach((checkoutFileToUpload) => {
      checkoutFileToUpload.name = this.getFileNameWithCopiesCount(
        uppy,
        checkoutFileToUpload.name
      );
      try {
        uppy.addFile(checkoutFileToUpload);
      }
      catch {
        console.info('Upload files - adding trouble');
      }
    });
  }

  private setUppyFilesEndpoints(uppy: Uppy) {
    const files = uppy.getFiles() as any[];

    files.forEach((uppyFile) => {
      uppy.setFileState(uppyFile.id, {
        xhrUpload: {
          endpoint: this.generateEndpoint(uppyFile.meta),
          headers: {
            Authorization: `Bearer ${this.authService.accessToken}`,
          },
        },
      });
    });
  }

  private getFileNameWithCopiesCount(uppy: Uppy, filename: string): string {
    const filesWithSameName = uppy
      .getFiles()
      .filter((f) => f.name.startsWith(this.getFilename(filename)));
    if (filesWithSameName && filesWithSameName.length > 0) {
      const dotIndex = filename.lastIndexOf(".");
      if (dotIndex === -1) {
        filename = `${filename}_${filesWithSameName.length}`;
      } else {
        filename = `${filename.substring(0, dotIndex)}_${
          filesWithSameName.length
        }${filename.substring(dotIndex)}`;
      }
    }
    return filename;
  }

  private getFilename(filename: string): string {
    const dotIndex = filename.lastIndexOf(".");
    if (dotIndex === -1) {
      return filename;
    } else {
      return `${filename.substring(0, dotIndex)}`;
    }
  }

  private generateEndpoint(meta: any): string {
    if (this.generateEndpointCallback) {
      return this.generateEndpointCallback(meta);
    }
    else {
      return `${this.configService.environment.applications.url}/api/checkout-requirements/${meta.checkoutRequirementId}/files/${meta.category}`;
    }
  }
}
