import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import {
  AppBarCloseAction,
  AppBarComponent,
  AppBarContactComponent,
  AppBarTitleComponent,
  AppFooterComponent,
  AppPageComponent,
  MfaGuard,
} from "common";
import { MfaComponent } from "./mfa.component";

const routes: Routes = [
  {
    path: "mfa",
    canActivate: [MfaGuard],
    component: AppPageComponent,
    data: {
      appPage: {
        contentClass: "simple",
        contentAlignment: "center-center",
        contentWithoutPadding: true,
      },
      appBar: {
        stickyDisabled: true,
        closeAction: AppBarCloseAction.MANUAL,
        logoHidden: true,
      },
      appBarTitle: {
        content: "2-Factor Verification",
      },
    },
    children: [
      {
        path: "",
        outlet: "app-bar",
        component: AppBarComponent,
        children: [
          {
            path: "",
            outlet: "title",
            component: AppBarTitleComponent,
          },
          {
            path: "",
            outlet: "right",
            component: AppBarContactComponent,
          },
        ],
      },
      {
        path: "",
        outlet: "app-footer",
        component: AppFooterComponent,
        data: {
          appFooter: {
            full: true,
          },
        },
      },
      {
        path: "",
        component: MfaComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MfaRoutingModule {}
