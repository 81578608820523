import { Directive, OnInit } from "@angular/core";
import { AppBarTitleService, parseNumber } from "common";
import * as _ from "lodash";
import { MessageSubjects } from "../message/message.model";
import { DismissableBaseService } from "../dismissable-base/dismissable-base.service";
import { CustomerFacade } from "../customer/domain/+state/customer.facade";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { EntityFacade } from "../entity/domain/+state/entity.facade";
import { ActivatedRoute } from "@angular/router";

@UntilDestroy()
@Directive()
export abstract class ReferralCodeBaseComponent<T> implements OnInit {
  referSubject = MessageSubjects.EarnUpTo10000;
  customerId: number;
  entityId: number;
  notificationData: any;
  protected _searchCondition: any;

  constructor(
    private appBarTitleService: AppBarTitleService,
    protected notificationDismissableService: DismissableBaseService<T>,
    private customerFacade: CustomerFacade,
    private entityFacade: EntityFacade,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.appBarTitleService.title = MessageSubjects.EarnUpTo10000;
    this.initCustomer();
    this.initEntity();
  }

  private initCustomer() {
    this.customerFacade
      .getCurrentCustomer$()
      .pipe(untilDestroyed(this))
      .subscribe((customer) => {
        this.customerId = customer.id;
      });
  }

  private initEntity() {
    this.entityFacade
      .getEntity$(parseNumber(this.route.snapshot.queryParams.companyId))
      .pipe(untilDestroyed(this))
      .subscribe((entity) => {
        this.entityId = entity.id;
        this.initNotificationData(entity.id);
      });
  }

  private initNotificationData(entityId: number) {
    this.notificationDismissableService
      .query(entityId)
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        const object = _.find(data, this._searchCondition);
        if (object) {
          this.notificationData = object;
        }
      });
  }

  dismiss(event: any) {
    if (this.notificationData) {
      this.notificationDismissableService
        .update(
          this.customerId,
          this.entityId,
          this.notificationData,
          event.checked
        )
        .pipe(untilDestroyed(this))
        .subscribe();
    }
  }
}
