import { UppyFile } from "@uppy/core";

export enum UppyFileChangeTypeEnum {
  Added = 'added',
  Removed = 'removed',
  Canceled = "canceled",
  Closed = "closed"
}

export interface UploadFileDialogResult {
  type: UppyFileChangeTypeEnum;
  files: UppyFile[];
}

export interface UploadFileDialogConfig {
  checkoutRequirementId: number;
  applicationId: number;
  accessToken?: string;
  endpoint?: string;
  category?: string;
  files?: any[];
  getMetaCallback?: (data: UploadFileDialogConfig) => any;
}
