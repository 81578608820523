export interface AppBarComponentData {
  appBar?: AppBarOptions;
  appPage?: {
    sidenavEnabled?: boolean;
  };
}

export interface AppBarOptions {
  backgroundClass?: string;
  closeAction?: AppBarCloseAction;
  closeIcon?: string;
  expandable?: boolean;
  logoClass?: string;
  logoHidden?: boolean;
  logoSize?: string;
  logoIcon?: boolean;
  showDefaultLogo?: boolean;
  titleHiddenCollapsed?: boolean;
  advisorName?: string;
  phoneNumber?: string;
  containerClass?: string;
  contactInfoClass?: string;
  sidenavOpenIcon?: string;
  isSidenavAppBar?: boolean;
  containerWrapperClass?: string;
}

export enum AppBarCloseAction {
  BACK = "back",
  MANUAL = "manual",
  HOME = "home",
  SIGN_OUT = "sign-out",
  CALCULATOR_SUMMARY = "calculator/summary",
  SIDENAV = "sidenav",
}
