import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppBarTitleService, AppPageService, BrandingService, CompanyBranding } from 'common';
import { LoanData } from '../../loan/domain/models/loan.model';
import { RenewalService } from '../../renewal/renewal.service';

@UntilDestroy()
@Component({
  selector: 'ifp-renewal-confirmation',
  templateUrl: './renewal-confirmation.component.html',
  styleUrls: ['./renewal-confirmation.component.scss']
})
export class RenewalConfirmationComponent implements OnInit {
  companyBranding: CompanyBranding;
  renewalIconSrc: string;
  currentLoan: LoanData;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appBarTitleService: AppBarTitleService,
    private appPageService: AppPageService,
    private renewalService: RenewalService,
    private brandingService: BrandingService
  ) {
    sessionStorage.setItem('accountSetupViewed', JSON.stringify(true));
  }

  ngOnInit() {
    this.appBarTitleService.title = "Renew your loan";
    this.route.data.pipe(untilDestroyed(this)).subscribe(data => {
      this.currentLoan = data.loan;
    })

    this.brandingService.getBranding$()
      .pipe(untilDestroyed(this))
      .subscribe((data: CompanyBranding) => {
        this.companyBranding = data;
        this.renewalIconSrc = `../../assets/${this.companyBranding?.colorSchema}-renewal.png`;
      })
  }

  confirmRenewal() {
    this.renewalService.confirmRenewal(this.currentLoan?.id)
      .subscribe(_ => {
        if (!this.appPageService.backToIfPossible(true)) {
          this.router.navigate(['dashboard']);
        }
      });
  }
}
