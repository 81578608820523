import { LoanStatus } from "../loan/loan.model";
import { QueryParams } from "../query/query.data";
import * as _ from 'lodash';
import { ProductCode } from "../product/product.model";
import { parseNumber } from "../helpers/parse.helper";

export interface AccountHintData {
    businessName: string;
    id: number;
    loanNumber: string;
    applicationNumber: string;
    description: string;
    externalId: string;
  }

  export interface AccountHintParams {
    id?: number;
    businessName?: string;
    description?: string;
    loanNumber?: string;
    applicationNumber?: string;
    sortField?: string;
    excludedOwnerId?: number;
    excludedStatus?: LoanStatus;
    includedStatus?: LoanStatus;
    excludeLoansWithNoOwnerAssigned?: boolean;
    excludeLoansWithResolvedCase?: boolean;
    externalId?: string;
    productCode?: ProductCode;
    excludedProductCode?: ProductCode;
    usePropertiesWithOr?: string[];
    loanNumberOrDescription?: string;
    entityId?: number;
  }

  export enum AccountActivationStage {
    'None' = 0,
    'Owners registered' = 4096,
    'Identity verified' = 8192,
    'Agreement signed' = 12288,
    'Activated' = 2147483647,
  }


  export class AccountFilters {
    loanNumber: any;
    applicationNumber: any;
    businessName: any;
    description: any;
    createdOn: any;
    createdOnFrom: any;
    createdOnTo: any;
    renewalOn: any;
    renewalOnFrom: any;
    renewalOnTo: any;
    status: LoanStatus;
    activationStage: AccountActivationStage;
    active: any;
    productCode: any;
    excludedProductCode: any;
    companyId: any;
    modificationFlags: AccountModificationFlag[];
    minBalance: any;
    maxBalance: any;
    minPaymentAmount: any;
    maxPaymentAmount: any;
    paymentFrequency: any;
    upcomingPaymentDateOn: any;
    minUpcomingPaymentDate: any;
    maxUpcomingPaymentDate: any;
    favorite: any;
    externalId: any;

    constructor(filters?) {
      filters = filters || {};
      this.loanNumber = filters.loanNumber || undefined;
      this.applicationNumber = filters.applicationNumber || undefined;
      this.businessName = filters.businessName || undefined;
      this.description = filters.description || undefined;
      this.createdOn = filters.createdOn || undefined;
      this.createdOnFrom = filters.createdOnFrom || undefined;
      this.createdOnTo = filters.createdOnTo || undefined;
      this.renewalOn = filters.renewalOn || undefined;
      this.renewalOnFrom = filters.renewalOnFrom || undefined;
      this.renewalOnTo = filters.renewalOnTo || undefined;
      this.status = filters.status;
      this.activationStage = filters.activationStage;
      this.active = filters.active || undefined;
      this.productCode = filters.productCode || undefined;
      this.excludedProductCode = filters.excludedProductCode || undefined;
      this.companyId = filters.companyId || undefined;
      this.modificationFlags = filters.modificationFlags || undefined;
      this.minBalance = filters.minBalance;
      this.maxBalance = filters.maxBalance;
      this.minPaymentAmount = filters.minPaymentAmount;
      this.maxPaymentAmount = filters.maxPaymentAmount;
      this.paymentFrequency = filters.paymentFrequency;
      this.upcomingPaymentDateOn = filters.upcomingPaymentDateOn || undefined;
      this.minUpcomingPaymentDate = filters.minUpcomingPaymentDate || undefined;
      this.maxUpcomingPaymentDate = filters.maxUpcomingPaymentDate || undefined;
      this.favorite = filters.favorite || undefined;
      this.externalId = filters.externalId || undefined;
    }

    remove(item: string) {
      this[item] = undefined;
      if (item === 'createdOn' || item === 'createdOnFrom') {
        this.createdOn = undefined;
        this.createdOnFrom = undefined;
        this.createdOnTo = undefined;
      } else if (item === 'renewalOn' || item === 'renewalOnFrom') {
        this.renewalOn = undefined;
        this.renewalOnFrom = undefined;
        this.renewalOnTo = undefined;
      } else if (item === 'upcomingPaymentDateOn' || item === 'minUpcomingPaymentDate') {
        this.upcomingPaymentDateOn = undefined;
        this.minUpcomingPaymentDate = undefined;
        this.maxUpcomingPaymentDate = undefined;
      }
    }
  }

  export class AccountQueryParams extends QueryParams {
    loanNumber?: string;
    applicationNumber?: string;
    businessName?: string;
    description?: string;
    createdOnFrom?: any;
    createdOnTo?: any;
    renewalOnFrom?: any;
    renewalOnTo?: any;
    status?: LoanStatus;
    activationStage?: AccountActivationStage;
    productCode?: any;
    excludedProductCode?: any;
    companyId?: number;
    entityId?: number;
    brokerId?: number;
    modificationFlags?: AccountModificationFlag[];
    minBalance?: any;
    maxBalance?: any;
    minPaymentAmount?: any;
    maxPaymentAmount?: any;
    paymentFrequency?: any;
    minUpcomingPaymentDate?: any;
    maxUpcomingPaymentDate?: any;
    favorite?: boolean;
    externalId?: string;

    constructor(params?: any) {
      super(params);
      params = params || {};
      this.loanNumber = params.loanNumber || undefined;
      this.applicationNumber = params.applicationNumber || undefined;
      this.description = params.description || undefined;
      this.businessName = params.businessName || undefined;
      this.createdOnFrom = params.createdOnFrom || undefined;
      this.createdOnTo = params.createdOnTo || undefined;
      this.renewalOnFrom = params.renewalOnFrom || undefined;
      this.renewalOnTo = params.renewalOnTo || undefined;
      this.status = parseNumber(params.status);
      this.activationStage = parseNumber(params.activationStage);
      this.productCode = params.productCode || undefined;
      this.excludedProductCode = params.excludedProductCode || undefined;
      this.companyId = parseNumber(params.companyId);
      this.entityId = parseNumber(params.entityId);
      this.brokerId = parseNumber(params.brokerId);
      this.modificationFlags = params.modificationFlags ? _.values(params.modificationFlags) : undefined;
      this.minBalance = params.minBalance || undefined;
      this.maxBalance = params.maxBalance || undefined;
      this.minPaymentAmount = params.minPaymentAmount || undefined;
      this.maxPaymentAmount = params.maxPaymentAmount || undefined;
      this.paymentFrequency = parseNumber(params.paymentFrequency);
      this.minUpcomingPaymentDate = params.minUpcomingPaymentDate || undefined;
      this.maxUpcomingPaymentDate = params.maxUpcomingPaymentDate || undefined;
      this.favorite = params.favorite || undefined;
      this.externalId = params.externalId || undefined;
    }
  }

  export enum AccountModificationFlag {
    None = 0,
    CurrentModification = 1,
    PastModification = 2,
    FutureModification = 3
  }

  export const AccountModificationFlagMap = new Map<number, string>([
    [AccountModificationFlag.None, 'No modification'],
    [AccountModificationFlag.CurrentModification, 'Current modification'],
    [AccountModificationFlag.PastModification, 'Past modification'],
    [AccountModificationFlag.FutureModification, 'Future modification']
  ]);

  export enum TimeInBusinessPeriod {
    UpToYear = 0,
    From1To2Years = 1,
    Over2Years = 2,
  }

  export const TimeInBusinessPeriodLabel = new Map<number, string>([
    [TimeInBusinessPeriod.UpToYear, '0-1 Years'],
    [TimeInBusinessPeriod.From1To2Years, '1-2 Years'],
    [TimeInBusinessPeriod.Over2Years, '2+ Years']
  ]);
