import * as _ from "lodash";
import { CustomerData } from "../customer/customer.model";
import { LoanData, LoanStatus } from "./loan.model";
import { ProductCode, ProductCodeLabel } from "../product/product.model";
import { NeedsAttentionInput } from "../needs-attention/needs-attention.model";
import { NeedsAttentionHelper } from "../needs-attention/needs-attention.helper";

export class LoanHelper {
  static isNPLLoan(status: LoanStatus): boolean {
    return _.includes(
      [
        LoanStatus.Delinquent,
        LoanStatus.Workout,
        LoanStatus.PaymentRelief,
        LoanStatus.WriteOff,
      ],
      status
    );
  }

  static hasDrawAccess(loan: LoanData): boolean {
    return (
      loan.loanInfo &&
      loan.loanInfo?.availableFunds >= loan.loanInfo?.minDrawAmount &&
      loan.loanInfo?.availableFunds > 0 &&
      loan.productCode !== ProductCode.Term &&
      loan.allowDraw
    ) ||
      loan?.status === LoanStatus.Frozen;
  }

  static hasPayAccess(loan: LoanData): boolean {
    return (
      loan.loanInfo &&
      loan.loanInfo?.currentPayoffAmount > 0 &&
      loan.loanInfo?.principalBalance > 0 &&
      loan.allowPayment
    );
  }

  static isLoanPendingForSign(loan: LoanData, customer: CustomerData) {
    return (
      loan?.status === LoanStatus.Pending &&
      LoanHelper.isLoanPendingForCustomer(loan, customer)
    );
  }

  static isLoanPendingForSignByOthers(loan: LoanData, customer: CustomerData) {
    return (
      !this.isLoanPendingForSign(loan, customer) &&
      loan?.status === LoanStatus.Pending
    );
  }

  static isLoanPendingForCustomer(
    loan: LoanData,
    customer: CustomerData
  ): boolean {
    return customer?.pendingLoans?.includes(loan?.id);
  }

  static getOutstandingBalance(loan: LoanData): number {
    if (loan.productCode === ProductCode.Term)
      return loan.loanInfo?.principalBalance + loan.loanInfo?.accruedInterest;
    else return loan.loanInfo?.principalBalance;
  }

  public static getNeedsAttentionMessage(loan: LoanData): string {
    return NeedsAttentionHelper.getNeedsAttentionMessage(this.getNeedsAttentionMessageInput(loan));
  }

  public static getNeedsAttentionMessageInput(loan: LoanData): NeedsAttentionInput {
    return {
      firstName: loan?.advisorFirstName,
      lastName: loan?.advisorLastName,
      email: loan?.advisorEmail,
      phone: loan?.advisorPhone,
      productName: ProductCodeLabel.get(loan?.productCode)
    };
  }
}
