import { Component, NgZone, OnInit, OnDestroy, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppDetailLayout2Data } from './app-detal-layout-2.model';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'ifc-app-detail-layout-2',
    templateUrl: './app-detail-layout-2.component.html',
    styleUrls: ['./app-detail-layout-2.component.scss']
})
export class AppDetailLayout2Component implements OnInit, OnDestroy {

    private _unsubscribeAll: Subject<any>;

    contentScrolled = false;
    sidenavOpened = true;
    data: AppDetailLayout2Data = new AppDetailLayout2Data();

    constructor(
        private ngZone: NgZone,
        private route: ActivatedRoute,
        breakpointObserver: BreakpointObserver
    ) {
        this._unsubscribeAll = new Subject();
        breakpointObserver.observe([
            Breakpoints.XSmall,
            Breakpoints.Small,
        ]).subscribe(result => {
            if (result.matches)
                this.sidenavOpened = false;
            else
                this.sidenavOpened = true;
        });
    }

    ngOnInit(): void {
        this.route.data.pipe(takeUntil(this._unsubscribeAll))
            .subscribe(this.dataSet.bind(this));
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    dataSet(data: AppDetailLayout2Data) {
        this.data = new AppDetailLayout2Data(data);
    }

    @HostListener('window:scroll', ['$event'])
    onContentScrolled(event?) {
      this.ngZone.run(() => {
        this.contentScrolled = event?.currentTarget.scrollY > 0;
      });
    }
}
