export const getAccountBottomToolbarMenuItems = (accountId: number) => [
  {
      id: 'DASHBOARD',
      url: `/account/${accountId}/dashboard`,
      icon: 'pie_chart'
  },
  {
      id: 'TRANSACTIONS',
      url: `/account/${accountId}/transactions`,
      icon: 'format_list_bulleted'
  },
  {
      id: 'DOCUMENTS',
      url: `/account/${accountId}/documents`,
      icon: 'description'
  },
  {
      id: 'BANKS',
      url: `/account/${accountId}/bank-account`,
      icon: 'account_balance'
  }
];

export const getApplicationBottomToolbarMenuItems = (appId: number) => [
    {
        id: 'DASHBOARD',
        url: `/application/${appId}/dashboard`,
        icon: 'pie_chart'
    },
    {
        id: 'DOCUMENTS',
        url: `/application/${appId}/documents`,
        icon: 'description'
    },
    {
        id: 'BANKS',
        url: `/application/${appId}/bank-account`,
        icon: 'account_balance'
    }
];
