<mat-dialog-content>
  <p>
    Your privacy and security is important. The data you provide to us is used to find the best financing solution for
    your business and it is stored according to our <a [href]="privacyUrl" target="_blank">Privacy
      Policy</a>. We use advanced encryption to ensure your
    information is secured.
  </p>
  <div fxLayout="row" fxLayout.lt-sm="column" class="flex center-center" fxLayoutGap="12px">
    <div fxFlexAlign="center">
        <img src="/assets/img/soc/soc.png" width="45px" height="45px">
    </div>
    <div class="ssl-logo-wrapper">
      <ifc-ssl-logo></ifc-ssl-logo>
    </div>
  </div>
</mat-dialog-content>
<div mat-dialog-actions class="flex row end-center">
  <button type="button" mat-button [mat-dialog-close]="true">OK</button>
</div>
