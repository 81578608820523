import { EntityData } from "common";
import { LoanData } from "../loan/domain/models/loan.model";

export interface FinancialInstitution {
    institutionName?: string;
    institutionId?: string;
    institutionLoginId?: string;
    institutionUrl?: string;
    routingNumber?: string;
    accountNumber?: string;
    isLinked: boolean;
    customerMessages?: string[];
}

export class IntegrationEventData {
    entityId: number;
    institutionId: string;
    institutionName: string;
    type: IntegrationEventType;
    info: string = null;
}

export enum IntegrationEventType
{
    Pending,
    Success,
    Error,
    Cancel
}

export enum BankAccountRole {
    Unknown = 0,
    Disbursement = 1,
    Payment = 2
}

export function bankLogoExists(institutionName?: string) {
    const brandingData = BankBranding.find(branding => branding.name.toLowerCase() === institutionName.toLowerCase());
    return !!brandingData;
}


export function getBankLogo(institutionName?: string) {
    if (institutionName) {
      const brandingData = BankBranding.find(branding => branding.name.toLowerCase() === institutionName.toLowerCase());
      if (!brandingData)
        return null;

        institutionName = institutionName.replace(/\s/g, "").toLowerCase();
      return `../../../assets/img/banks/${institutionName}.png`;
    }
  }

  export enum PopularInstistitution {
    WellsFargo = "Wells Fargo",
    ChaseBank = "Chase",
    BankOfAmerica = "Bank of America",
    PncBank = "PNC Bank",
    TdBank = "TD Bank",
    UsBank = "US Bank"
}

export const BankBranding = [
    { name: "Wells Fargo", color: "#CD1409" },
    { name: "Chase", color: "#117ACA" },
    { name: "Bank of America", color: "#0065b3" },
    { name: "PNC Bank", color: "#FF5400" },
    { name: "TD Bank", color: "#35B234" },
    { name: "US Bank", color: "#d62a43" }
];

export const BankBrandingByName = BankBranding.reduce(function (map, obj) {
    map[obj.name] = obj;
    return map;
}, {});

export const bankLinkStatusLabels = {
    '0': { color: 'mat-red-bg', icon: 'link_off', label: 'Unlinked' },
    '1': { color: 'mat-green-bg', icon: 'link', label: 'Linked' }
};

export enum InstitutionIntegrationStatus {
    Unlinked = 0,
    Linked = 1
}

export interface BankLinkingComponentData {
    loan?: LoanData;
    entity?: EntityData;
    financialInstitutions?: FinancialInstitution[];
  }
