import { ChartConfiguration } from "chart.js";

export const getBarChartData = (
  data: number[],
  labels: string[],
  color: string
): ChartConfiguration<"bar">["data"] => {
  return {
    datasets: [
      {
        label: "Balance",
        borderColor: "transparent",
        hoverBackgroundColor: "rgb(255, 99, 132)",
        backgroundColor: color || "white",
        categoryPercentage: 0.75,
        barPercentage: 1.0,
        data,
      },
    ],
    labels,
  };
};

export const getBarChartOptions = (displayYAxes: boolean): ChartConfiguration<"bar">["options"] => {
  return {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const yLabel = tooltipItem.parsed.y;
            const formatter = new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2,
            });

            return formatter.format(yLabel);
          },
          labelColor: function (context) {
            return {
              borderColor: "rgb(255, 99, 132)",
              backgroundColor: "rgb(255, 99, 132)",
            };
          },
        },
      },
    },
    responsive: false,
    maintainAspectRatio: false,
    scales: {
      x: {
        display: false,
        stacked: false,
        grid: {
          display: false,
          borderColor: "white",
        },
        ticks: {
          display: false,
        },
      },
      y: {
        display: displayYAxes,
        ticks: {
          font: {
            size: 12,
          },
          callback: function (value, index, values) {
            return "$" + <number>value / 1000 + "k";
          },
          color: "white",
          maxTicksLimit: 5,
        },
        min: 0,
        grid: {
          display: false,
          lineWidth: 0,
          color: "rgba(255,255,255,0)",
          borderColor: "transparent",
        },
        title: {
          display: false,
          color: "#FFFFFF",
          font: {
            size: 10,
          },
        },
        stacked: false,
      },
    },
  };
};
