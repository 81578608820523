import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Component({
  selector: 'ifp-renewal-info-dialog',
  templateUrl: './renewal-info-dialog.component.html',
  styleUrls: ['./renewal-info-dialog.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class RenewalInfoDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<RenewalInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  static show(dialog: MatDialog): Observable<boolean> {
    return dialog.open(RenewalInfoDialogComponent, {
        closeOnNavigation: true,
        width: "510px"
    }).afterClosed();
}

}
