import { Injectable } from '@angular/core';
import { zip, of } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';
import { ApplicationRemote } from '../../../application/infrastructure/application.remote';
import { CustomerFacade } from '../../../customer/domain/+state/customer.facade';
import { LoanStateService } from '../../../loan/domain/+state/loan.state.service';
import { LoanData } from '../../../loan/domain/models/loan.model';
import { PrefillForInsurance } from './account-transaction-table-card.model';
import { Buffer } from 'buffer'; 
import { ApplicationBaseData } from 'common';
import { CustomerData, CustomerContactDataDetails } from '../../../customer/domain/models/customer.model';
import { AppSettings } from 'projects/portal/src/app.settings';

@Injectable({
  providedIn: 'root'
})
export class AccountTransactionTableCardFacade {

  constructor(private customerFacade: CustomerFacade, private loanStateService: LoanStateService, private applicationRemote: ApplicationRemote, private settings: AppSettings) { }

  public gotoInsurance() {
    this.loanStateService.getLoan$()
    .pipe(
      mergeMap((loan: LoanData) => {
        if(loan.entityId) {
          return zip(this.customerFacade.getCurrentCustomer$(), this.customerFacade.getCustomerContactDetails$(), of(loan), this.applicationRemote.queryByEntity(loan.entityId));
        } else {
          throw new Error('No entity id');
        }
      }),
      map(([customer, details, loan, application]) => {
        const data = this.mapToInsurePrefill(customer, loan, details, application?.values.find(a => a.loanId === loan.id));
        this.validateInsurePrefill(data);
        return data;
      })
    )
    .subscribe((data) => {
      window.open(`${this.settings.insure.url}?data=${Buffer.from(JSON.stringify(data)).toString('base64')}`, '_blank');
    });
  }

  private validateInsurePrefill(data: PrefillForInsurance) {
    if (data.state.length < 2) {
      throw new Error('State is required');
    }
  }

  private mapToInsurePrefill(customer: CustomerData, loan: LoanData, details: CustomerContactDataDetails, currentApplication: ApplicationBaseData): PrefillForInsurance {
    const data = {
      ...this.extractPrefillFromCustomer(customer),
      ...this.extractPrefillFromDetails(details),
      ...this.extractPrefillFromApplication(currentApplication),
      ...this.extractPrefillFromLoan(loan)
    } as PrefillForInsurance;
    
    Object.keys(data).forEach((key) => {
    if (data[key]?.length > 256) {
        data[key] = data[key].substring(0, 255);
      }
    });

    if (data.dateComplaintServed.length < 10) {
      delete data.dateComplaintServed;
    } else {
      data.dateComplaintServed = new Date(data.dateComplaintServed).toISOString().split('T')[0];
    }

    return data;
  }

  private extractPrefillFromLoan(loan: LoanData): Partial<PrefillForInsurance>{
    return {
      companyName: loan?.entityName ?? '',
      policyLimit: loan?.loanInfo?.creditLimit?.toString() ?? ''
    };
  }

  private extractPrefillFromApplication(currentApplication: ApplicationBaseData): Partial<PrefillForInsurance> {
    return {
      caseName: (currentApplication as any)?.litigation?.caseName ?? '',
      caseJurisdiction: (currentApplication as any)?.litigation?.stateFiled ?? '',
      caseTypes: (currentApplication as any)?.litigation?.caseType ?? '',
      dateComplaintServed: (currentApplication as any)?.dateServed ?? ''
    };
  }

  private extractPrefillFromDetails(details: CustomerContactDataDetails): Partial<PrefillForInsurance> {
    return {
      mailingAddress2: details?.address?.line2 ?? '',
      mailingAddress1: details?.address?.line1 ?? '',
      city: details?.address?.city ?? '',
      state: details?.address?.state ?? '',
      zipCode: details?.address?.zip ?? '',
      phoneNumber: details?.mobilePhone?.toString() ?? '',
      phoneExtension: details?.homePhone?.toString() ?? ''
    };
  }
  
  private extractPrefillFromCustomer(customer: CustomerData): Partial<PrefillForInsurance> {
    return {
      applicantFirstName: customer?.firstName ?? '',
      applicantLastName: customer?.lastName ?? '',
      emailAddress: customer?.email ?? ''
    };
  }
}
