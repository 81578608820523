import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { RenewalService } from "../../renewal/renewal.service";
import { ApplicationStage, RenewalEligibilityDto } from "common";


export abstract class RenewalBaseGuard implements CanActivate {

    constructor(protected router: Router, protected renewalService: RenewalService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.renewalService.getEligible().pipe(
            map(eligibility => this.validate(this.findProperEligibleForRenewal(eligibility)))
        );
    }

    protected findProperEligibleForRenewal(eligibility: RenewalEligibilityDto[]) : RenewalEligibilityDto {
        return eligibility?.find(e => !e.optedIn
            || !e.renewalApplicationId
            || e.renewalApplicationStage === ApplicationStage.ApplicationIncomplete);
    }

    protected abstract validate(eligibile: RenewalEligibilityDto): boolean;
}
