<ng-container *ngIf="offerCalculator">
  <div [class]="'loc-card card-header-' + theme"
       fxLayout="column"
       ngClass.lt-md="loc-card-sm"
       ngClass.lt-sm="loc-card-xs">
    <div class="loc-card-header"
         fxLayout="row"
         fxLayoutAlign="start center"
         fxLayoutGap="16px">
      <div [class]="'avatar mat-' + theme">{{offerCalculator.productShortName}}</div>
      <div class="mat-headline">{{offerCalculator.productName}}{{offerCalculator.express ? ' *' : ''}}</div>
    </div>
    <div fxLayout="row"
         fxLayoutGap="16px"
         fxLayout.lt-md="column"
         fxLayoutAlign="center center">
      <section>
        <fieldset>
          <legend>
            Draw amount
          </legend>
          <input [class]="'draw-amount card-header-' + theme"
                 currencyMask
                 type="text"
                 [(ngModel)]="offerCalculator.sampleDraw" />
        </fieldset>
        <mat-slider min="10000"
                    [max]="offerCalculator.amount"
                    step="1000"
                    [(ngModel)]="offerCalculator.sampleDraw"
                    thumbLabel="true"
                    [displayWith]="onSampleDrawDisplay"
                    (input)="onSampleDrawChanged($event)"></mat-slider>
      </section>
      <section>
        <fieldset>
          <legend>
            Payoff period
          </legend>
          <input [class]="'draw-amount card-header-' + theme"
                 currencyMask
                 type="text"
                 [value]="offerCalculator.sampleDurationDisplay"
                 disabled />
        </fieldset>
        <mat-slider min="0"
                    [max]="sampleDurationLength"
                    step="1"
                    [(ngModel)]="offerCalculator.sampleDurationIndex"
                    thumbLabel="true"
                    [displayWith]="onSampleDurationDisplay"
                    (input)="onSampleDurationChanged($event)"></mat-slider>
      </section>
    </div>
    <div class="express-footer"
         *ngIf="offerCalculator.express">
      * Express Checkout is not subject to additional credit underwriting.
    </div>
  </div>
</ng-container>
