import {
  Component,
  Input,
  SimpleChanges,
  OnChanges,
  OnInit,
} from "@angular/core";
import { Router } from "@angular/router";
import { formatCurrency } from "@angular/common"
import {
  DateHelper,
  LiquidityEvent,
  LoanStatus,
  LoanHelper,
  NeedsAttentionHelper,
  NeedsAttentionModel
} from "common";
import { LoanData, ProductCode } from "../../loan/domain/models/loan.model";
import { CustomerData } from "../../customer/domain/models/customer.model";
import { MatDialog } from "@angular/material/dialog";
import { AccountStatusIconChipModel } from "../account-status-icon-chip/account-status-icon-chip.model";

@Component({
  selector: "ifp-loc-law-card-chart",
  templateUrl: "./loc-law-card-chart.component.html",
  styleUrls: ["./loc-law-card-chart.component.scss"],
})
export class LocLawChartContentComponent implements OnInit, OnChanges {
  @Input() loan: LoanData;
  @Input() liquidityEventData?: LiquidityEvent;
  @Input() customer: CustomerData;
  @Input() showAvailableFunds: boolean;
  @Input() widgetLayout: boolean;
  @Input() kbaVerified: boolean;
  @Input() hasAvailableRenewalToSign: boolean;
  @Input() hasCurrentLoanPending: boolean;
  @Input() needsAttentionModel: NeedsAttentionModel;

  fundsAvailableFaktor: number;
  fundsAvailable: string;
  drawEnabled: boolean;
  payEnabled: boolean;
  loanStatus = LoanStatus;
  pendingCustomer: boolean;
  utilization: number;
  productCodes = ProductCode;
  resolveCaseEnabled: boolean;
  casePaymentRequired: boolean;
  casePaymentWithinDays: number;
  accountStatusIconChipModel: AccountStatusIconChipModel;

  get isLocLoan() {
    return this.loan?.productCode === this.productCodes.LOC;
  }

  constructor(protected router: Router, protected dialog: MatDialog) { }

  ngOnInit() {
    this.bindData();
  }

  ngOnChanges(_: SimpleChanges): void {
    this.bindData();
  }

  bindData() {
    if (this.loan && this.loan.loanInfo) {
      this.fundsAvailableFaktor =
        this.loan.loanInfo.availableFunds / this.loan.loanInfo.creditLimit;
      this.fundsAvailable = formatCurrency(
        this.loan.loanInfo.availableFunds,
        "en_US",
        "$",
        undefined,
        "1.0-0"
      );
      this.drawEnabled = this.isDrawEnabled();
      this.payEnabled = LoanHelper.hasPayAccess(this.loan);
    }

    if(this.loan && this.loan.productCode === ProductCode.Law && this.loan.fullRepaymentDueDate) {
      this.casePaymentRequired = true;
      const fullRepaymentDueDate = DateHelper.setToStartOfDay(new Date(this.loan.fullRepaymentDueDate));
      const today = DateHelper.setToStartOfDay(new Date());
      this.casePaymentWithinDays = Math.floor((fullRepaymentDueDate.getTime() - today.getTime()) / (1000 * 3600 * 24));
    }

    this.utilization = this.getUtilization();

    this.accountStatusIconChipModel = { 
      needsAttentionMessage: NeedsAttentionHelper.getNeedsAttentionMessageWithProductCode(this.needsAttentionModel.messageInput, this.loan.productCode),
      isLoanFullRepaymentDueDateSet: !!this.loan.fullRepaymentDueDate 
    };
  }

  onClickDraw() {
    if(this.needsAttentionModel?.isApplicable) {
      NeedsAttentionHelper.openNeedsAttentionDialog(this.dialog, this.needsAttentionModel.messageInput, this.loan.productCode);
      return;
    }

    this.router.navigate([`account/${this.loan.id}/transfer/draw`]);
  }

  onClickPayment() {
    this.router.navigate([`account/${this.loan.id}/transfer/payment`]);
  }

  getUtilization(): number {
    return Math.round(
      (this.loan.loanInfo.availableFunds / this.loan.loanInfo.creditLimit) * 100
    );
  }
  
  private isDrawEnabled(): boolean {
    const drawEnabled = LoanHelper.hasDrawAccess(this.loan);

    if (this.loan?.productCode === ProductCode.Law) {
      return drawEnabled && !this.liquidityEventData?.resolutionStatus
    }
    return drawEnabled;
  }
}