import { Directive, OnInit } from '@angular/core';
import { OidcService } from '../oidc.service';

@Directive({
  selector: '[ifpLogin]'
})
export class LoginDirective implements OnInit {

  constructor(
    private oidcService: OidcService,
    ) { }
   
  ngOnInit(): void {
    this.oidcService.authorize();
  }
}