import { Injectable } from "@angular/core";
import {
  MfaActionsService,
  MfaErrorCodeType,
  MfaPhoneStatus,
  MfaService,
  VerifyMfaCodeData,
} from "common";
import {
  SingInErrorResponse,
  SingInExtras,
  SingInMfaRequest,
  SingInResponse,
} from "../../user/user-sign-in/models/sign-in.models";
import { UserSignInService } from "../../user/user-sign-in/user-sign-in.service";

@Injectable()
export class MfaLoginService {
  constructor(
    private mfaActions: MfaActionsService,
    private userSignInService: UserSignInService,
    private mfaService: MfaService
  ) {}

  verify(data: VerifyMfaCodeData) {
    const request: SingInMfaRequest = {
      mfa_token: data.mfaData.mfaToken,
      mfa_code: data.mfaCode,
      trust_device: data.trustDevice,
    };
    const extras: SingInExtras = {
      username: data.mfaData.contextData?.username,
      rememberMe: data.mfaData.contextData?.rememberMe,
      loginType: data.mfaData.contextData?.loginType,
    };

    switch (data.mfaData.phoneStatus) {
      case MfaPhoneStatus.Verified:
        return this.verifyMfaLoginVerifiedPhone(request, extras);
      case MfaPhoneStatus.Unverified:
        return this.verifyMfaLoginUnverifiedPhone(request, extras);
      default:
        this.mfaService.handleMfaError("Invalid phoneStatus to verify");
        break;
    }
  }

  private verifyMfaLoginVerifiedPhone(
    request: SingInMfaRequest,
    extras: SingInExtras
  ) {
    this.userSignInService
      .signInByMfa(request, extras)
      .subscribe((data: SingInResponse & SingInErrorResponse) => {
        this.handleMfaLoginResponse(data);
      });
  }

  private verifyMfaLoginUnverifiedPhone(
    request: SingInMfaRequest,
    extras: SingInExtras
  ) {
    this.userSignInService
      .confirmPhoneAndSignIn(request, extras)
      .subscribe((data: SingInResponse & SingInErrorResponse) => {
        this.handleMfaLoginResponse(data);
      });
  }

  private handleMfaLoginResponse(data: SingInResponse & SingInErrorResponse) {
    if (!data?.hasError) {
      this.mfaService.handleMfaSuccess();
      return;
    }

    if (data?.errorCode === MfaErrorCodeType.InvalidMfaCode) {
      this.mfaActions.invalidCode();
      return;
    }

    this.mfaService.handleMfaError();
  }
}
