import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { filter, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SplashScreenService {
  splashScreenEl: Element;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router
  ) { }

  public init(): void {
    this.splashScreenEl = this.document.body.querySelector('#ifc-splash-screen');

    if (this.splashScreenEl) {
      this.router.events
        .pipe(
          filter((event => event instanceof NavigationEnd)),
          filter((event => event["url"] !== "/mobile/init")),
          take(1)
        )
        .subscribe(() => {
          this.splashScreenEl.classList.add("fade-out");
          setTimeout(() => {
            this.splashScreenEl.remove();
          }, 400);
        });
    }
  }
}
