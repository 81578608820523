import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { AutocompleteComponent } from 'common';
import { FinicityService } from '../finicity.service';

@Component({
  selector: 'ifp-bank-account-search-input',
  templateUrl: '../../../../../common/src/lib/ui/autocomplete/autocomplete.component.html',
  styleUrls: ['../../../../../common/src/lib/ui/autocomplete/autocomplete.component.css']
})
export class BankAccountSearchInputComponent extends AutocompleteComponent {

  constructor(private finicityService: FinicityService) {
    super();
  }

  label = 'Institution name';

  multipleLines = true;

  secondLineText: (input: any) => string = input => input.urlHomeApp;

  notFoundText: (input: string) => string = input => `No results matching "${input}". Please give us a call.`;

  optionFilter: (input: string | FinicityInstitutionHintData) => Observable<any[]> =
    input => this.finicityService.getFinicityInstitutionList({
      search: !!input ? (typeof input !== 'string' ? input.search : input) : null
    })

  optionLabelKey = 'name';
}

interface FinicityInstitutionHintData {
    search: string;
}
