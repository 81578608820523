export class DateHelper {
    static setToStartOfDay(date: Date) : Date {
        date.setHours(0, 0, 0, 0);
        return date;
    }

    static getLocalDateAsUTC(date: Date) : Date {
        if (!date)
            return date;

        let dateTemp;
        if (date instanceof Date) {
            dateTemp = date;
        }
        else {
            dateTemp = new Date(date);
        }
        return new Date(Date.UTC(
            dateTemp.getUTCFullYear(),
            dateTemp.getUTCMonth(),
            dateTemp.getUTCDate(),
            dateTemp.getUTCHours(),
            dateTemp.getUTCMinutes(),
            0,
            0));
    }

    static getUTCDateAsDateLocal(date: Date) : Date {
        if (!date)
            return date;

        let dateTemp;
        if (date instanceof Date) {
            dateTemp = date;
        }
        else {
            dateTemp = new Date(date);
        }
        return new Date(
            dateTemp.getUTCFullYear(),
            dateTemp.getUTCMonth(),
            dateTemp.getUTCDate(),
            0,
            0,
            0,
            0);
    }
}