import { combineLatest } from "rxjs";
import { Directive, OnInit } from "@angular/core";
import {
  AppBarTitleService,
  EntityData,
  EntityType,
  parseNumber,
} from "common";
import { InsuranceService } from "../insurance.service";
import {
  CoverageData,
  InsuranceType,
  QuoteData,
  QuoteResponseData,
  QuoteResult,
} from "../insurance.model";
import { CustomerData } from "../../customer/domain/models/customer.model";
import { ActivatedRoute, Router } from "@angular/router";
import * as _ from "lodash";
import { DismissableBaseService } from "../../dismissable-base/dismissable-base.service";
import { MessageSubjects } from "../../message/message.model";
import { CustomerFacade } from "../../customer/domain/+state/customer.facade";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { EntityFacade } from "../../entity/domain/+state/entity.facade";

@UntilDestroy()
@Directive()
export abstract class InsuranceQuoteBaseComponent<T> implements OnInit {
  insuranceSubject = MessageSubjects.GeneralLiabilityInsurance;
  quote: QuoteData;
  coverage: CoverageData;
  customerId: number;
  entityId: number;
  loading = false;
  dba: string;
  notificationData: any;
  protected searchCondition: any;

  constructor(
    private appBarTitleService: AppBarTitleService,
    private insuranceService: InsuranceService,
    private entityFacade: EntityFacade,
    private customerFacade: CustomerFacade,
    private router: Router,
    private notificationDismissableService: DismissableBaseService<T>,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.appBarTitleService.title = this.insuranceSubject;
    this.initData();
  }

  private initData() {
    combineLatest([
      this.customerFacade.getCurrentCustomer$(),
      this.entityFacade.getEntity$(parseNumber(this.route.snapshot.queryParams.companyId)),
      this.route.data,
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([customer, entity, data]) => {
        this.customerId = customer.id;
        this.entityId = entity.id;
        if (data?.quote) {
          this.dataInit(data.quote);
        } else {
          this.requestQuote(entity, customer);
        }
        this.getNotificationData(entity.id);
      });
  }

  private getNotificationData(entityId: number) {
    this.notificationDismissableService.query(entityId).subscribe((data) => {
      const object = _.find(data, this.searchCondition);
      if (object) {
        this.notificationData = object;
      }
    });
  }

  monthlyAmount() {
    return this.quote ? this.quote.totalPremium / 12 : 0;
  }

  liabilityAmount() {
    return this.coverage ? this.coverage.liabilityAmount : 0;
  }

  dataInit(data: QuoteData) {
    switch (data?.result) {
      case QuoteResult.Rejected:
        this.router.navigate(["/insurance-declined"]);
        break;
      case QuoteResult.Error:
      case QuoteResult.Intermediate:
        this.router.navigate(["/insurance-error"]);
        break;
      default:
        this.quote = data;
        this.coverage = this.insuranceService.coverageFactory(this.quote);
        break;
    }
  }

  private requestQuote(entity: EntityData, customer: CustomerData) {
    this.loading = true;
    this.insuranceService
      .requestQuote("customer-portal", {
        entityId: entity.id,
        ein: entity.ein.replace("-", ""),
        dba: entity.dba,
        naics: entity.naics,
        businessName: entity.name,
        isSolePropOrNonProfit:
          entity.type === EntityType.SoleProprietorship || false,
        insuranceTypes: [InsuranceType["general-liability"]],
        contact: {
          email: customer.email,
          firstName: customer.firstName,
          lastName: customer.lastName,
          primaryPhone: customer.preferredPhone?.toString(),
          mobilePhone: customer.mobilePhone?.toString(),
        },
        address: {
          line1: entity.address?.line1,
          line2: entity.address?.line2,
          city: entity.address?.city,
          state: entity.address?.state,
          zip: entity.address?.zip,
        },
        establishedDate: entity.establishedDate,
      })
      .subscribe({
        next: (response: QuoteResponseData) => {
          this.loading = false;
          if (!response || !response.quotes?.length) {
            this.router.navigate(["/insurance-error"]);
            return;
          }
          this.dataInit(response.quotes[0]);
        },
        error: () => {
          this.router.navigate(["/insurance-error"]);
        },
      });
  }

  proceedWithQuote() {
    if (this.quote?.externalQuoteUrl) window.open(this.quote.externalQuoteUrl);
  }

  dismiss(event: any) {
    if (this.notificationData) {
      this.notificationDismissableService
        .update(this.customerId, this.entityId, this.notificationData, event.checked)
        .subscribe();
    }
  }
}
