import { Component, Inject, OnInit, Renderer2 } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { catchError, filter } from "rxjs/operators";
import { BrandingService, CompanyBranding, CompanyColorSchemaEnum, MetaService } from "common";
import { Title } from "@angular/platform-browser";
import { DOCUMENT } from "@angular/common";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { EMPTY } from "rxjs";

declare global {
  interface Window {
    analytics: any;
  }
}

@UntilDestroy()
@Component({
  selector: "ifp-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private router: Router,
    private titleService: Title,
    private metaService: MetaService,
    private brandingService: BrandingService
  ) { }
  ngOnInit(): void {
    this.router.events
      .pipe(untilDestroyed(this), filter((event) => event instanceof NavigationEnd))
      .subscribe((val) => {
        window.analytics.page();
      });

    this.brandingService.getBranding$()
    .pipe(
      untilDestroyed(this),
      catchError((error) => {
        console.error(error);
        return EMPTY;
      })
    ).subscribe(data => {
      this.initBrandingTheme(data);
      this.setDOMElements(data);
    });
  }

  private initBrandingTheme(brandingData: CompanyBranding) {
    if (brandingData?.colorSchema === CompanyColorSchemaEnum.LevelEsq) {
      this.renderer.setAttribute(
        this.document.body,
        "class",
        "theme-level-esq"
      );
    }
  }

  private setDOMElements(brandingData: CompanyBranding): void {
    if (this.router.url === "/sign-in")
      this.titleService.setTitle(
        `Business Financing | ${
          brandingData?.dba ?? "Idea Financial"
        } Sign In`
      );
    else
      this.titleService.setTitle(
        `Business Financing | ${brandingData?.dba ?? "Idea Financial"}`
      );

    this.metaService.createMetaTag(
      "description",
      `Welcome to your ${brandingData?.dba} account. You can access information about your business with us here.`
    );
    this.metaService.createAlternateURL(
      brandingData?.domains?.length
        ? brandingData?.domains[2]
        : "https://portal.ideafinancial.com"
    );
  }
}
