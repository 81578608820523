import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  getAccountBottomToolbarMenuItems,
  getApplicationBottomToolbarMenuItems,
} from "./bottom-toolbar.model";
import { LoanData } from "../../loan/domain/models/loan.model";
import * as _ from "lodash";
import { ApplicationData } from "../../application/application.model";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { LoanHelper } from "common";

@UntilDestroy()
@Component({
  selector: "ifp-bottom-toolbar",
  templateUrl: "./bottom-toolbar.component.html",
  styleUrls: ["./bottom-toolbar.component.scss"],
})
export class BottomToolbarComponent implements OnInit {
  toolbarMenuItems: any;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.data
      .pipe(untilDestroyed(this))
      .subscribe(this.dataInit.bind(this));
  }

  dataInit(data) {
    const appData: ApplicationData = data.application;
    if (appData) {
      this.toolbarMenuItems = getApplicationBottomToolbarMenuItems(appData.id);
      return;
    }

    this.toolbarMenuItems = this.getAccountBottomToolbarMenuItems(data.loan);
  }

  private getAccountBottomToolbarMenuItems(loan: LoanData) {
    if (LoanHelper.isNPLLoan(loan?.status))
      return _.filter(
        getAccountBottomToolbarMenuItems(loan.id),
        (item) => item.id != "TRANSACTIONS"
      );

    return getAccountBottomToolbarMenuItems(loan.id);
  }
}
