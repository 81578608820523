import { Injectable } from "@angular/core";
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";
import { CustomerContactData, CustomerData } from "../domain/models/customer.model";
import { CustomerFacade } from "../domain/+state/customer.facade";
import { take } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class CustomerCurrentResolver implements Resolve<CustomerData> {
  constructor(private facade: CustomerFacade) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<CustomerData> {
    return this.facade.getCurrentCustomer$().pipe(take(1));
  }
}

@Injectable({ providedIn: "root" })
export class CustomerContactDetailsResolver
  implements Resolve<CustomerContactData>
{
  constructor(private facade: CustomerFacade) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<CustomerContactData> {
    return this.facade.getCustomerContactDetails$().pipe(take(1));
  }
}
