import { Injectable } from "@angular/core";
import { Observable, forkJoin, from, of } from "rxjs";
import { LoanRemote } from "../../infrastructure/loan.remote";
import { AccountQueryParams, LoanHelper, LoanStatus } from "common";
import {
  CaseFinancingLoanData,
  LoanData,
  LoanDataSummary,
  LoanQueryResult,
  ProductCode,
} from "../models/loan.model";
import { CustomerData } from "../../../customer/domain/models/customer.model";
import { AccountTransactionService } from "../../../account/account-transaction/account-transaction.service";
import { concatMap, map, mergeMap, switchMap, toArray } from "rxjs/operators";
import { LiquidityEventRemote } from "../../../liquidity-event/infrastructure/liquidity-event.remote";

@Injectable({
  providedIn: "root",
})
export class LoanActionsFacade {
  constructor(
    private readonly remote: LoanRemote,
    private readonly accountTransactionService: AccountTransactionService,
    private readonly litigationService: LiquidityEventRemote
  ) {}

  getByEntity(
    entityId: number,
    params?: AccountQueryParams
  ): Observable<LoanQueryResult> {
    const targetParams = this.prepareDefaultAccountParams(entityId, params);
    return this.remote.query(targetParams);
  }

  getSummaryByEntity(
    entityId: number,
    params?: AccountQueryParams
  ): Observable<LoanDataSummary> {
    return this.getByEntity(entityId, params).pipe(
      mergeMap((results: LoanQueryResult) =>
        this.getSummaryFromResults(results)
      )
    );
  }

  querySummary(params: AccountQueryParams): Observable<LoanDataSummary> {
    return this.remote.query(params).pipe(
      mergeMap((results: LoanQueryResult) =>
        this.getSummaryFromResults(results)
      )
    );
  }

  private getSummaryFromResults(
    results: LoanQueryResult
  ): Observable<LoanDataSummary> {
    const loans = results?.values?.filter(
      (loan) => loan.productCode !== ProductCode.Law
    );
    const cfLoans = results?.values?.filter(
      (loan) => loan.productCode === ProductCode.Law
    );

    if (cfLoans?.length > 0) {
      return from(cfLoans).pipe(
        concatMap((cfLoan: LoanData) => this.getDataPerCfLoan(cfLoan)),
        toArray(),
        map((cfLoans: CaseFinancingLoanData[]) => ({
          caseFinancingLoans: cfLoans,
          otherLoans: loans,
          totalCount: results?.totalCount,
        }))
      );
    }

    return of({
      caseFinancingLoans: cfLoans as CaseFinancingLoanData[],
      otherLoans: loans,
      totalCount: results?.totalCount,
    });
  }

  private getDataPerCfLoan(
    cfLoan: LoanData
  ): Observable<CaseFinancingLoanData> {
    return forkJoin([
      this.litigationService.getLiquidityEventForLoan(cfLoan.id),
    ]).pipe(
      map(([liquidityEventData]) => ({
        ...cfLoan,
        liquidityEventData,
      }))
    );
  }

  private prepareDefaultAccountParams(
    entityId: number,
    params: AccountQueryParams
  ) {
    return new AccountQueryParams({
      ...params,
      skip: params?.skip ?? 0,
      limit: params?.limit ?? 1000,
      active: typeof params?.active === "boolean" ? params?.active : true,
      sort: params?.sort ?? "loanNumber",
      entityId,
    });
  }

  getAllLoans(): Observable<LoanQueryResult> {
    return this.remote.query({
      skip: 0,
      limit: 1000,
      sort: "loanNumber",
      active: true,
    });
  }

  loanSorter(l1: LoanData, l2: LoanData, customer: CustomerData): number {
    const signSortResult = this.loanSorterByPendingAgreementSign(
      l1,
      l2,
      customer
    );
    if (signSortResult == 0) {
      const favoriteResult = this.loanSorterByFavorite(l1, l2, customer?.id);
      if (favoriteResult == 0) {
        const statusResult = this.loanSorterByStatus(l1, l2);
        if (statusResult == 0) return this.loanSorterByAvailableAmount(l1, l2);
        else return statusResult;
      } else return favoriteResult;
    } else return signSortResult;
  }

  private loanSorterByFavorite(
    l1: LoanData,
    l2: LoanData,
    customerId: number
  ): number {
    const favorite1 = l1.owners?.find(
      (x) => x.customerId == customerId
    )?.favorite;
    const favorite2 = l2.owners?.find(
      (x) => x.customerId == customerId
    )?.favorite;

    if (favorite1 === favorite2) return 0;
    else if (favorite1) return -1;
    else if (favorite2) return 1;
  }

  private loanSorterByPendingAgreementSign(
    l1: LoanData,
    l2: LoanData,
    customer: CustomerData
  ): number {
    let isL1PendingForSignByCustomer = LoanHelper.isLoanPendingForSign(
      l1,
      customer
    );
    if (!isL1PendingForSignByCustomer)
      this.hasLoanAvailableRenewalToSign(l1, customer).subscribe(
        (res) => (isL1PendingForSignByCustomer = res)
      );

    let isL2PendingForSignByCustomer = LoanHelper.isLoanPendingForSign(
      l2,
      customer
    );
    if (!isL2PendingForSignByCustomer)
      this.hasLoanAvailableRenewalToSign(l2, customer).subscribe(
        (res) => (isL2PendingForSignByCustomer = res)
      );

    let isL1PendingForSignByOthers = LoanHelper.isLoanPendingForSignByOthers(
      l1,
      customer
    );
    let isL2PendingForSignByOthers = LoanHelper.isLoanPendingForSignByOthers(
      l2,
      customer
    );

    if (isL1PendingForSignByCustomer && isL2PendingForSignByCustomer) return 0;
    else if (isL1PendingForSignByCustomer && !isL2PendingForSignByCustomer)
      return -1;
    else if (!isL1PendingForSignByCustomer && isL2PendingForSignByCustomer)
      return 1;
    else if (isL1PendingForSignByOthers && isL2PendingForSignByOthers) return 0;
    else if (isL1PendingForSignByOthers && !isL2PendingForSignByOthers)
      return -1;
    else if (!isL1PendingForSignByOthers && isL2PendingForSignByOthers)
      return 1;

    return 0;
  }

  hasLoanAvailableRenewalToSign(
    loan: LoanData,
    customer: CustomerData
  ): Observable<boolean> {
    const renewalReady = loan?.status === LoanStatus.PendingRenewal &&
        LoanHelper.isLoanPendingForCustomer(loan, customer) &&
        loan?.renewalOfferAvailableToSign &&
        loan?.renewalOffer !== null;

    if(!renewalReady) {
      return of(false);
    }

    return this.accountTransactionService.getUnprocessedByLoan(loan?.id).pipe(
      switchMap((res) => {
        res = res || [];
        return of(!res.length);
      })
    );
  }

  private loanSorterByStatus(l1: LoanData, l2: LoanData): number {
    if (l1.status == LoanStatus.Open && l2.status != LoanStatus.Open) return -1;
    else if (l2.status == LoanStatus.Open && l2.status != LoanStatus.Open)
      return 1;
    else if (
      (l1.status == LoanStatus.Open && l1.status == l2.status) ||
      (l1.status != LoanStatus.Open && l2.status != LoanStatus.Open)
    )
      return 0;
  }

  private loanSorterByAvailableAmount(l1: LoanData, l2: LoanData): number {
    const l1Balance = l1?.loanInfo?.availableFunds;
    const l2Balance = l2?.loanInfo?.availableFunds;

    if (l1Balance == l2Balance) return 0;
    else if (l1Balance > l2Balance) return -1;
    else return 1;
  }
}
