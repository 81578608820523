import { ActivatedRoute } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { ApplicationOfferDisclosure } from "common";

@Component({
  selector: "ifp-offer-disclosures",
  templateUrl: "./offer-disclosures.component.html",
})
export class OfferDisclosuresComponent implements OnInit {
  disclosures: ApplicationOfferDisclosure[];

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.data.subscribe((data) => {
      this.disclosures = data.disclosures;
    });
  }
}
