import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DocumentQueryResult, LoanDocumentQueryParams } from './document.model';
import { ConfigService } from '../config/config.service';
import { Observable } from 'rxjs';
import { ResourceService } from '../resource/resource.service';
import { DocumentBaseService } from './document-base.service';
import { LoanFileTemplateData } from './loan-document.model';
import { GetFileData } from '../resource/resource.model';
import { SignedLoanDocumentFile } from './signed-loan-document-file.model';

@Injectable({
  providedIn: 'root'
})
export class LoanDocumentService extends DocumentBaseService {

  protected override _queryParams: LoanDocumentQueryParams = {};

  constructor(http: HttpClient, resourceService: ResourceService, configService: ConfigService) {
    super(http, resourceService, configService, `${configService.environment.loans.url}/api/files`)
  }

  override query(loanId: number, params: LoanDocumentQueryParams): Observable<DocumentQueryResult> {
    params.loanId = loanId;
    if (!params.sort)
      params.sort = '-createdon';
    return this.http.post<DocumentQueryResult>(this.baseUrl, params);
  }

  override queryByUniqueId(loanUniqueId: string, params: LoanDocumentQueryParams): Observable<DocumentQueryResult> {
    params.loanUniqueId = loanUniqueId;
    if (!params.sort)
      params.sort = '-createdon';
    return this.http.post<DocumentQueryResult>(`${this.baseUrl}/uniqueId`, params);
  }

  override delete(fileId: number, _: number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${fileId}`);
  }

  override download(fileId: number, _: number) {
    this.resourceService.download(`${this.baseUrl}/${fileId}`);
  }

  getFileData(fileId: number, trustUrl: boolean = false): Observable<GetFileData> {
    return this.resourceService.getFileData(`${this.baseUrl}/${fileId}`, trustUrl);
  }

  generateFileFromTemplate(loanId: number, templateId: number, trustUrl: boolean = false): Observable<GetFileData> {
    return this.resourceService.getFileData(`${this.baseUrl}/${loanId}/generate/${templateId}`, trustUrl);
  }

  clearCache(loanId: number): Observable<number> {
    return this.http.delete<number>(`${this.baseUrl}/${loanId}/cache`);
  }

  getLoanFileTemplates(loanId: number): Observable<LoanFileTemplateData[]> {
    return this.http.get<LoanFileTemplateData[]>(`${this.baseUrl}/${loanId}/templates`);
  }

  getSignatures(fileId: number): Observable<SignedLoanDocumentFile> {
    return this.http.get<SignedLoanDocumentFile>(`${this.baseUrl}/${fileId}/signatures`);
  }
}
