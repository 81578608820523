import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "offerTermLabel",
})
export class OfferTermLabelPipe implements PipeTransform {
  transform(term: number): string {
    return `${term} ${term > 1 ? "months" : "month"}`;
  }
}
