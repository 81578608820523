import { Component, Input, ViewEncapsulation } from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MessageService } from "../../message/message.service";
import * as _ from "lodash";
import { MatDialog } from "@angular/material/dialog";
import { ReferralCodeEmailDialogComponent } from "./referral-code-email-dialog/referral-code-email-dialog.component";
import { Router } from "@angular/router";

@Component({
  selector: "ifp-referral-code-email",
  templateUrl: "./referral-code-email.component.html",
  styleUrls: ["./referral-code-email.component.scss"],
  encapsulation: ViewEncapsulation.Emulated,
})
export class ReferralCodeEmailComponent {
  @Input()
  customerId: number;

  form: UntypedFormGroup;

  constructor(
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private messageSerice: MessageService,
    private router: Router
  ) {
    this.form = this.formBuilder.group({
      emails: this.formBuilder.array([]),
    });
    this.addOwner();
  }

  emailChanged(value, index): void {
    setTimeout(() => {
      if (!value) return;
      const emails = this.form.get("emails") as UntypedFormArray;
      if (index === emails.length - 1 && emails.length < 100) this.addOwner();
    }, 3000);
  }

  addOwner() {
    const emails = this.form.get("emails") as UntypedFormArray;
    emails.push(this.createDefaultForm());
  }

  createDefaultForm(): UntypedFormGroup {
    return this.formBuilder.group({
      email: [
        undefined,
        Validators.compose([Validators.email, Validators.required]),
      ],
    });
  }

  send() {
    const data = this.form.get("emails") as UntypedFormArray;
    const emails: string[] = _.filter(_.map(data.value, "email"), null);
    if (emails.length)
      this.messageSerice
        .sendReferEmail(this.customerId, emails)
        .subscribe(() => {
          ReferralCodeEmailDialogComponent.show(this.dialog).subscribe(
            (value) => {
              if (value) {
                this.router.navigate(["dashboard"]);
                return;
              }

              this.form.controls.emails.reset();
              while (data.length !== 1) {
                data.removeAt(0);
              }
            }
          );
        });
  }
}
