import { Injectable } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AccountTransactionService } from './account-transaction.service';
import { MatTableHelper } from 'common';

@Injectable({
    providedIn: 'root'
})
export class AccountTransactionFilterService {

    constructor(private _accountTransactionService: AccountTransactionService) { }

    filter(loanId: number, paginator?: MatPaginator, sort?: MatSort) {
        const sortBy = sort && sort.active ? MatTableHelper.getSort(sort.active, sort) : '-date';
        return this._accountTransactionService
            .getTransactions(loanId, paginator.pageIndex, paginator.pageSize, sortBy);
    }

}
