import { NgModule } from "@angular/core";
import { CommonModule, MfaModule as MfaCommonModule } from "common";
import { SharedModule } from "../shared/shared.module";
import { MfaRoutingModule } from "./mfa-routing.module";
import { MfaComponent } from "./mfa.component";

@NgModule({
  imports: [MfaCommonModule, CommonModule, SharedModule, MfaRoutingModule],
  declarations: [MfaComponent],
})
export class MfaModule {}
