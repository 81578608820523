import { Component, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { OfferDetailsData } from "./models/offer-details-data.model";
import { OfferDetailsFacade } from "./offer-details.facade";
import { ApplicationOfferBundleData, LinkingStatus } from "common";

@UntilDestroy()
@Component({
  selector: "ifp-offer-details",
  templateUrl: "./offer-details.component.html",
  styleUrls: ["./offer-details.component.scss"],
  providers: [OfferDetailsFacade],
})
export class OfferDetailsComponent implements OnInit {
  data: OfferDetailsData;

  constructor(private facade: OfferDetailsFacade) {}

  ngOnInit(): void {
    this.initData();
    this.facade.initActions();
    this.watchLinkingBankSuccessfully();
  }

  private initData(): void {
    this.facade
      .initData$()
      .pipe(untilDestroyed(this))
      .subscribe((data: OfferDetailsData) => {
        this.data = data;
      });
  }

  private watchLinkingBankSuccessfully() {
    this.facade
      .watchLinkingBankSuccessfully()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (this.data?.application) {
          this.data.application.bankLinkStatus = LinkingStatus.Linked;
        }
      });
  }

  onSelectOffer(currentOfferBundleId: number) {
    this.facade
      .refreshCurrentOfferBundle(this.data.application.id, currentOfferBundleId)
      .subscribe((currentOfferBundle: ApplicationOfferBundleData) => {
        this.data.currentOfferBundle = currentOfferBundle;
      });
  }
}
