export enum SocialMedia {
    twitter,
    linkedin,
    facebook,
    whatsapp,
    email
}

export interface SocialMediaShare {
    twitter?: string;
    linkedin?: string;
    facebook?: string;
    whatsapp?: string;
    email?: string;
}
