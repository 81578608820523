import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Data, Router } from "@angular/router";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import {
  BrandingService,
  CompanyBranding,
  CompanyBrandingHelper,
  LiquidityEvent,
  LoanFileTemplateData,
  LoanHelper,
  LoanStatus,
  NeedsAttentionModel,
  parseNumber
} from "common";
import { LoanData, ProductCode } from "../../loan/domain/models/loan.model";
import {
  CustomerData,
  KBAVerificationStatus,
} from "../../customer/domain/models/customer.model";
import { ApplicationData } from "../../application/application.model";
import { AccountTransactionService } from "../../account/account-transaction/account-transaction.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { LoanFacade } from "../../loan/domain/+state/loan.facade";
import { LiquidityEventFacade } from "../../liquidity-event/domain/+state/liquidity-event.facade";
import { combineLatest } from "rxjs";

@UntilDestroy()
@Component({
  selector: "ifp-sidenav-content",
  templateUrl: "./sidenav-content.component.html",
  styleUrls: ["./sidenav-content.component.scss"],
})
export class SidenavContentComponent implements OnInit {
  currentLoan: LoanData;
  currentLiquidityEvent: LiquidityEvent;
  currentLoanFileTemplates: LoanFileTemplateData[];
  currentCustomer: CustomerData;
  currentApplication: ApplicationData;
  productCode = ProductCode;
  hideNavbar: boolean;
  kbaVerified: boolean;
  hasCurrentLoanPending: boolean;
  hasAvailableRenewalToSign: boolean;
  shouldDisplayLoanBarChart: boolean;
  loanStatus = LoanStatus;
  legalEmail: string;
  layout2: boolean;
  isNPLLoan: boolean;
  accountMode: boolean;
  colorSchema: string;
  needsAttentionModel: NeedsAttentionModel;
  branding: CompanyBranding;

  constructor(
    private route: ActivatedRoute,
    private loanFacade: LoanFacade,
    private liquidityEventFacade: LiquidityEventFacade,
    private transactionService: AccountTransactionService,
    private brandingService: BrandingService,
    private router: Router,
    breakpointObserver: BreakpointObserver
  ) {
    breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small])
      .subscribe((result) => (this.layout2 = result.matches));
  }

  ngOnInit() {
    combineLatest([this.route.data, this.brandingService.getBranding$()]).pipe(untilDestroyed(this)).subscribe((combinedResult: Array<Data | CompanyBranding>) => {
      const data = combinedResult[0] as Data;
      const branding = combinedResult[1] as CompanyBranding;

      this.currentLoanFileTemplates = data.loanFileTemplates;
      this.currentApplication = data.application;
      this.currentCustomer = data.customer;
      this.hideNavbar = data.sidenavDisabled;
      this.kbaVerified =
        this.currentCustomer?.kbaStatus === KBAVerificationStatus.Success;
      this.hasCurrentLoanPending =
        this.currentLoan?.status === LoanStatus.Pending;
      this.accountMode = data.loans?.length > 0;
      this.initBranding(branding);

      if(data.loan){
        this.initNeedsAttentionModel(data);
      }
    });

    if (this.accountMode) {
      this.initLoan();
    }
    this.shouldDisplayLoanBarChart = this.currentLoan?.loanInfo?.principalBalance &&
      this.currentLoan?.loanInfo?.paymentAmount &&
      !this.currentLoan?.noAutoPayments;
  }

  private initNeedsAttentionModel(data: Data): void {
    const entityId = data.loan.entityId;
    const entity = data?.entities?.find(entity => entity.id === entityId);

    const advisorPhone = CompanyBrandingHelper.getAdvisorPhone(this.branding, data.loan?.advisorPhone);
    
    this.needsAttentionModel = {
      isApplicable: entity?.isDrawSuspended,
      messageInput: LoanHelper.getNeedsAttentionMessageInput({...data.loan, advisorPhone: advisorPhone })
    };
  }

  private initLoan() {
    this.loanFacade
      .getLoan$(parseNumber(this.route.snapshot.params.accountId))
      .pipe(untilDestroyed(this))
      .subscribe((loan) => {
        this.currentLoan = loan;
        this.getUnprocessedByLoan();
        this.isNPLLoan = LoanHelper.isNPLLoan(this.currentLoan?.status);
      });

    this.liquidityEventFacade
      .getLiquidityEventForLoan$(parseNumber(this.route.snapshot.params.accountId))
      .pipe(untilDestroyed(this))
      .subscribe((liquidityEvent) => {
        this.currentLiquidityEvent = liquidityEvent;
      })
  }

  private getUnprocessedByLoan() {
    if (!this.currentLoan?.id) {
      return;
    }

    const pendingCustomer = this.currentCustomer?.pendingLoans?.includes(
      this.currentLoan.id
    );

    if (pendingCustomer && this.currentLoan.status === LoanStatus.Pending) {
      this.router.navigate([
        `account/${this.currentLoan.id}/setup/sign-agreement`,
      ]);
    } else {
      this.transactionService
        .getUnprocessedByLoan(this.currentLoan.id)
        .pipe(untilDestroyed(this))
        .subscribe((res) => {
          res = res || [];
          this.hasAvailableRenewalToSign =
            this.currentLoan.status === LoanStatus.PendingRenewal &&
            pendingCustomer &&
            this.currentLoan.renewalOfferAvailableToSign &&
            this.currentLoan.renewalOffer !== null &&
            !res.length;
        });
    }
  }

  private initBranding(branding: CompanyBranding): void {
    this.colorSchema = branding?.colorSchema;
    this.legalEmail = branding?.legalEmail ?? "legal@ideafinancial.com";
    this.branding = branding;
  }
}
