<mat-divider></mat-divider>
<div class="wrapper">
  <div class="filter-toolbar">
    <div fxLayout="row"
         fxLayoutAlign="space-between center"
         class="table-top">
      <ifc-filters-active-list class="layout margin-sm"
                               [filters]="activeFilters"
                               (filterRemoved)="activeFilterRemoved($event)">
      </ifc-filters-active-list>
      <div fxHide.xs
           class="layout padding-right-md">
        <button mat-icon-button
                (click)="openFilterDialog()"
                class="secondary-text"
                matTooltip="Filter">
          <mat-icon>filter_list</mat-icon>
        </button>
      </div>
      <div fxHide
           fxShow.xs>
        <button mat-icon-button
                [matMenuTriggerFor]="actionsMenu"
                class="secondary-text more-actions-menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #actionsMenu="matMenu">
          <button mat-menu-item
                  (click)="openFilterDialog()">
            <mat-icon>filter_list</mat-icon>
            <span>Filter</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </div>

  <ifc-app-scroll-container [tabbed]="true">
    <div class="table-container">
      <div class="message-box info"
           *ngIf="!data.entityDocumentsQueryResult?.totalCount"
           fxLayout="row"
           fxLayoutGap="12px"
           fxLayoutAlign="start center">
        <mat-icon class="icon">info</mat-icon>
        <span>No data found</span>
      </div>
      <table class="table"
             *ngIf="data.entityDocumentsQueryResult?.totalCount"
             mat-table
             matSort
             aria-describedby="entity documents list"
             [dataSource]="data.entityDocumentsQueryResult.values"
             (matSortChange)="filter()">

        <ng-container matColumnDef="createdOn">
          <th mat-header-cell
              *matHeaderCellDef
              mat-sort-header="createdOn"> Date </th>
          <td mat-cell
              *matCellDef="let row"
              class="table-cell">
            {{row.uploadDate | date:'mediumDate'}} </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell
              *matHeaderCellDef
              mat-sort-header="name"> Name </th>
          <td mat-cell
              *matCellDef="let row"
              class="table-cell"> {{row.name}}
          </td>
        </ng-container>

        <ng-container matColumnDef="category">
          <th mat-header-cell
              *matHeaderCellDef
              mat-sort-header="category"> Category </th>
          <td mat-cell
              *matCellDef="let row"
              class="table-cell">
            {{row.category | documentCategoryLabel}} </td>
        </ng-container>

        <ng-container matColumnDef="download" stickyEnd>
          <th mat-header-cell *matHeaderCellDef style="width: 40px"></th>
          <td mat-cell *matCellDef="let row" class="table-cell" ngClass.gt-md="action-icon actions-menu" ngClass.lt-lg="actions-menu-xs">
            <div fxLayout="row" fxLayoutAlign="center start">
              <button mat-icon-button (click)="onClickDownload(row.id)">
                <mat-icon>download</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>


        <tr mat-header-row
            *matHeaderRowDef="tableColumns"
            class="table-header"></tr>
        <tr mat-row
            *matRowDef="let row; columns: tableColumns;"
            class="table-row"></tr>
      </table>
    </div>
  </ifc-app-scroll-container>

  <div [hidden]="data.entityDocumentsQueryResult?.totalCount <= 10">
    <mat-paginator class="paginator"
                   #paginator
                   showFirstLastButtons
                   [length]="data.entityDocumentsQueryResult.totalCount"
                   [pageSizeOptions]="[10, 20, 50]"
                   (page)="filter()">
    </mat-paginator>
  </div>

</div>
