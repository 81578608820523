import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppSettings } from "../../app.settings";
import { DismissableBaseService } from "../dismissable-base/dismissable-base.service";
import { ReminderData } from "./reminder.model";
import { CacheHeaders, ConfigService } from "common";

@Injectable({
  providedIn: "root",
})
export class ReminderService extends DismissableBaseService<ReminderData> {
  constructor(
    private http: HttpClient,
    private settings: AppSettings,
    private configService: ConfigService
  ) {
    super();
  }

  query(entityId: number): Observable<ReminderData[]> {
    return this.http.get<ReminderData[]>(
      `${this.settings.messages.url}/api/reminders/entities/${entityId}`,
      {
        headers: this.configService.environment.cache.mediumLived,
      }
    );
  }

  update(
    customerId: number,
    entityId: number,
    reminder: ReminderData,
    dismiss: boolean
  ): Observable<any> {
    return this.http.put(
      `${this.settings.messages.url}/api/reminders/${reminder?.id}/customers/${customerId}`,
      dismiss,
      {
        headers: {
          [CacheHeaders.Invalidate]: [
            `${this.settings.messages.url}/api/reminders/${entityId}`,
          ],
        },
      }
    );
  }
}
