import { DashboardOptions } from "@uppy/dashboard";
import { Uppy } from "@uppy/core";
import XHRUpload from "@uppy/xhr-upload";

export const getUppyInstance = (
  endpoint: string,
  accessToken: string
): Uppy => {
  const uppy = new Uppy({
    debug: false,
    autoProceed: false,
    restrictions: {
      maxFileSize: 20971520, // 20MB
      maxNumberOfFiles: 10,
      allowedFileTypes: ["application/pdf"],
    },
  });

  uppy.use(XHRUpload, {
    endpoint,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return uppy;
};

export const updateUppyEndpoint = (uppy: Uppy, endpoint: string): void => {
  if (!!uppy) {
    uppy.getPlugin("XHRUpload").setOptions({ endpoint });
  }
};

export const getUppyDashboardOptions = (): DashboardOptions => {
  return {
    id: "Dashboard",
    height: 312,
    width: 480,
    plugins: [],
    hideUploadButton: true,
    hideRetryButton: true,
    hidePauseResumeButton: true,
    proudlyDisplayPoweredByUppy: false,
  };
};
