import { Pipe, PipeTransform } from "@angular/core";
import { TransactionType, TransactionTypeString } from "./transaction.model";

@Pipe({
  name: "transactionTypeName",
})
export class TransactionTypeNamePipe implements PipeTransform {
  transform(value: TransactionTypeString | TransactionType): string {
    switch (value) {
      case TransactionTypeString.Draw:
      case TransactionType.Draw:
        return "Draw";
      case TransactionTypeString.Fee:
      case TransactionType.Fee:
            return "Fee";
      case TransactionTypeString.Payment:
      case TransactionType.Payment:
        return "Payment";
      case TransactionTypeString.BalanceTransfer:
      case TransactionType.BalanceTransfer:
        return "Balance Transfer";
      case TransactionTypeString.Premium:
      case TransactionType.Premium:
        return "Premium";
      case TransactionTypeString.Payoff:
      case TransactionType.Payoff:
            return "Payoff";
      default:
        return "Unknown";
    }
  }
}
