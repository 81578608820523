<mat-form-field class="application-selector">
  <mat-select [ngModel]="currentApplication?.id"
              name="Loan"
              (selectionChange)="onChangeApplication($event.value)">
    <mat-option *ngFor="let application of applications"
                [value]="application?.id">
      {{ application?.applicationNumber }}
    </mat-option>
  </mat-select>
  <mat-hint> {{ currentApplication?.entityDBA || currentApplication?.entityName}}</mat-hint>
</mat-form-field>
