import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ShowDisclosuresData } from "../../../models/show-disclosures-data.model";
import { OfferCalculator } from "../../../services/offer-calculator";

@Component({
  selector: "ifc-offer-calculator-details-table",
  templateUrl: "./offer-calculator-details-table.component.html",
  styleUrls: ["./offer-calculator-details-table.component.scss"],
})
export class OfferCalculatorDetailsTableComponent {
  @Input() offerCalculator: OfferCalculator;
  @Input() showCommision: boolean;
  @Input() showTerm: boolean;

  @Output() showDisclosures: EventEmitter<ShowDisclosuresData> =
    new EventEmitter();

  onClickDisclosures(): void {
    const data: ShowDisclosuresData = {
      applicationId: this.offerCalculator.application.id,
      offerId: this.offerCalculator.applicationOffer.id,
    };
    this.showDisclosures.emit(data);
  }
}
