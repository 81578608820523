import { Injectable } from "@angular/core";
import { ApplicationOfferBundleData } from "../../../application/application.model";
import { ProductCode } from "../../../product/product.model";
import { DisplayAllOffersData } from "../../models/display-all-offers-data.model";
import { OfferType } from "../../models/offer.model";
import { OfferActionsInternalService } from "../../services/actions/offer-actions.internal.service";

@Injectable()
export class OfferSummaryFacade {
  constructor(private actionsService: OfferActionsInternalService) {}

  getBestOfferBundles(
    offerBundles: ApplicationOfferBundleData[]
  ): ApplicationOfferBundleData[] {
    let bestLOC: ApplicationOfferBundleData;
    let bestTerm: ApplicationOfferBundleData;
    let bestLaw: ApplicationOfferBundleData;

    offerBundles?.forEach((bundle) => {
      switch (this.getProductType(bundle)) {
        case OfferType.LOC:
          bestLOC = this.getBetterOffer(bestLOC, bundle);
          break;
        case OfferType.Law:
          bestLaw = this.getBetterOffer(bestLaw, bundle);
          break;
        case OfferType.Term:
          bestTerm = this.getBetterOffer(bestTerm, bundle);
          break;
      }
    });

    let bundles = [];
    if (bestLOC) bundles.push(bestLOC);
    if (bestLaw) bundles.push(bestLaw);
    if (bestTerm) bundles.push(bestTerm);
    return bundles;
  }

  private getBetterOffer(
    offer1: ApplicationOfferBundleData,
    offer2: ApplicationOfferBundleData
  ): ApplicationOfferBundleData {
    if (!offer2) return offer1;
    if (!offer1) return offer2;

    return offer1.offers[0].amount > offer2.offers[0].amount ? offer1 : offer2;
  }

  private getProductType(offerBundle: ApplicationOfferBundleData): OfferType {
    if (offerBundle.offers[0]?.productCode === ProductCode.LOC) {
      return OfferType.LOC;
    } else if (offerBundle.offers[0]?.productCode === ProductCode.Term) {
      return OfferType.Term;
    } else if (offerBundle.offers[0]?.productCode === ProductCode.Law) {
      return OfferType.Law;
    }
  }

  getMaxOfferAmount(offerBundles: ApplicationOfferBundleData[]): number {
    if (offerBundles) {
      return Math.max(...offerBundles.map((x) => x.offers[0].amount));
    }
  }

  getMaxRepayment(offerBundles: ApplicationOfferBundleData[]): number {
    if (offerBundles) {
      return Math.max(...offerBundles.map((x) => x.offers[0].repaymentTerm));
    }
  }

  linkBank(entityId: number): void {
    this.actionsService.linkBank(entityId);
  }

  displayAllOffers(data: DisplayAllOffersData): void {
    this.actionsService.displayAllOffers(data);
  }
}
