import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from 'common';
import { RouterModule } from '@angular/router';
import { ApplicationAgreementFormComponent } from './application-agreement-form/application-agreement-form.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    imports: [
        SharedModule,
        CommonModule,
        RouterModule
    ],
    declarations: [
        ApplicationAgreementFormComponent
    ],
    schemas: [NO_ERRORS_SCHEMA]
})
export class ApplicationAgreementModule { }
