import { Component, OnInit, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BreakpointObserver } from "@angular/cdk/layout";
import { PageEvent } from "@angular/material/paginator";
import {
  ApplicationDocumentService,
  DialogConfirm,
  MessageService,
  FilterParameter,
} from "common";
import { ApplicationDocumentFilterService } from "../document-filter.service";
import { DocumentListBaseComponent } from "./document-list-base.component";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "ifp-application-document-list",
  templateUrl: "./document-list.component.html",
  styleUrls: ["./document-list.component.scss"],
})
export class ApplicationDocumentListComponent
  extends DocumentListBaseComponent<ApplicationDocumentService>
  implements OnInit, OnDestroy
{
  private applicationId: number;

  constructor(
    private router: Router,
    route: ActivatedRoute,
    _documentService: ApplicationDocumentService,
    _documentFilterService: ApplicationDocumentFilterService,
    dialog: MatDialog,
    breakpointObserver: BreakpointObserver,
    messageService: MessageService
  ) {
    super(
      route,
      _documentService,
      _documentFilterService,
      dialog,
      breakpointObserver,
      messageService
    );
  }

  get pageSize() {
    return this._documentService.pageSize;
  }
  get queryParams() {
    return this._documentService.queryParams;
  }

  ngOnInit() {
    super.ngOnInit();

    this.route.params.pipe(untilDestroyed(this)).subscribe((params) => {
      this.applicationId = params?.appId;
      this.getDocumentList(this.applicationId);
    });
  }

  private getDocumentList(appId: number) {
    this._documentService
      .query(appId, this.queryParams)
      .subscribe((documentQueryResult) => {
        this.data = { ...this.data, documentQueryResult };
      });
  }

  activeFilterRemoved(activefilters: FilterParameter[]): void {
    if (!activefilters.length) return;
    activefilters.forEach((item) => {
      this.filters.remove(item.id);
      this.filter();
    });
  }

  setPage(event: PageEvent) {
    this._documentService.pageSize = event.pageSize;
    this.filter();
  }

  filter(): void {
    const query = this._documentFilterService.filter(
      this.filters,
      this.paginator,
      this.sort
    );
    this._documentService
      .query(this.applicationId, query)
      .subscribe((result) => {
        this.data.documentQueryResult = result;
        this.activeFilters = this._documentFilterService.addFilterParameters(
          this.queryParams
        );
      });
  }

  download(id: number): void {
    this._documentService.download(id, this.applicationId);
  }

  get canUpload(): boolean {
    return true;
  }

  upload(): void {
    this.router.navigate([
      `application/${this.applicationId}/documents/upload`,
    ]);
  }

  delete(id: number, filename: string) {
    DialogConfirm.show(this.dialog, `Delete file ${filename}?`).subscribe(
      (result) => {
        if (result) {
          this.deleteSub = this._documentService
            .delete(id, this.applicationId)
            .subscribe({
              next: this.deleteSuccessHandler.bind(this),
              error: this.deleteErrorHandler.bind(this),
            });
        }
      }
    );
  }

  deleteSuccessHandler() {
    this.deleteSubClear();
    this.getDocumentList(this.applicationId);
  }

  ngOnDestroy() {
    this._documentService.queryParams = {};
  }
}
