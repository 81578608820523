import { CurrencyPipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import {
  AppBarAction,
  AppBarActionsService,
  AppBarTitleService,
  AppPageService,
  MessageService
} from "common";
import { Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { LoanData, ProductCode } from "../../../loan/domain/models/loan.model";
import {
  PromotionInfoData,
  PromotionService,
} from "../../../promotions/promotion.service";
import { ConfirmDialogComponent } from "../../../shared/confirm-dialog/confirm-dialog.component";
import { NotificationDialogComponent } from "../../../shared/notification-dialog/notification-dialog.component";
import { AccountTransferSubmittedDialogComponent } from "../account-transfer-submitted-dialog/account-transfer-submitted-dialog.component";
import { AccountTransferComponentData } from "../account-transfer.model";
import { AccountTransferService } from "../account-transfer.service";
import { LoanFacade } from './../../../loan/domain/+state/loan.facade';

@UntilDestroy()
@Component({
  selector: "ifp-account-transfer-payment",
  templateUrl: "./account-transfer-payment.component.html",
  styleUrls: ["./account-transfer-payment.component.scss"],
})
export class AccountTransferPaymentComponent implements OnInit {
  form: UntypedFormGroup;
  data: AccountTransferComponentData;
  submittedDialogRef: MatDialogRef<NotificationDialogComponent>;
  loan: LoanData;
  product = ProductCode;
  confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  paymentFrequency: string;
  promotionInfo: PromotionInfoData;

  constructor(
    private route: ActivatedRoute,
    private messageService: MessageService,
    private dialog: MatDialog,
    private appBarTitleService: AppBarTitleService,
    private formBuilder: UntypedFormBuilder,
    private _accountTransferService: AccountTransferService,
    private _appBarActionsService: AppBarActionsService,
    private _currencyPipe: CurrencyPipe,
    private _promotionService: PromotionService,
    private loanFacade: LoanFacade,
    private appPageService: AppPageService
  ) {
  }

  get maximumAmount(): number {
    return (
      this.promotionInfo?.payoffAmount ??
      this.data.accountTransfer.amountMaximum
    );
  }

  get minimumAmount(): number {
    return Math.min(this.data.accountTransfer.amountMinimum, this.promotionInfo?.payoffAmount ?? this.data.accountTransfer.amountMinimum);
  }

  ngOnInit() {
    this.route.data
      .pipe(untilDestroyed(this))
      .subscribe((data: AccountTransferComponentData) => {
        this.data = data;
        this.loan = data.loan;
        this.paymentFrequency =
          this._accountTransferService.getLoanOfferPaymentFrequencyLabel(
            this.loan.offer.paymentFrequency
          );
        this.formInit();
        this._accountTransferService.onAccountTransferChanged.next(this.data);
      });
    this.appBarTitleService.title = "Make a payment";

    this.form.controls.paymentAmount.valueChanges
      .pipe(untilDestroyed(this), debounceTime(500), distinctUntilChanged())
      .subscribe((value) => {
        this.data.accountTransfer.amountInput = value;
        this.accountNewBalancesInit();
      });

    this.form.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => this.enableSaveAction());

    this._appBarActionsService.invoking
      .pipe(untilDestroyed(this))
      .subscribe(this.actionDispatch.bind(this));

    this._promotionService.onPromotionApplied
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.applyPromotionChanged(res);
      });
  }

  formInit(): void {
    this.form = this.formBuilder.group({
      paymentAmount: [undefined, this.getPaymentAmountValidators()],
    });
    this.setAppBarActions();
  }

  actionDispatch(action: AppBarAction) {
    const actionHandler: (action: AppBarAction) => void =
      this[action.id].bind(this);
    actionHandler(action);
  }

  private setAppBarActions() {
    this._appBarActionsService.actions = [];
    this._appBarActionsService.actions.push({
      id: "cancel",
      label: "Cancel",
      buttonType: "button",
    });
    this._appBarActionsService.actions.push({
      id: "save",
      label: "Continue",
      disabled: !this.form.dirty,
      buttonType: "submit",
      buttonAppearance: "flat",
      buttonColor: "primary",
    });
  }

  enableSaveAction(): void {
    if (this.form.valid) this._appBarActionsService.enable("save", true);
    else this._appBarActionsService.enable("save", false);
  }

  showConfirmationDialog(): void {
    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "510px",
      disableClose: true,
    });
    this.confirmDialogRef.componentInstance.title = "Confirm payment?";
    this.confirmDialogRef.componentInstance.message = `You are making a payment in the amount of ${this._currencyPipe.transform(
      this.data.accountTransfer.amountInput
    )}.`;
  }

  save() {
    this.showConfirmationDialog();
    this.confirmDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.submitSub = this.data.accountTransfer
          .submit()
          .subscribe(
            this.submitSuccess.bind(this),
            this.submitError.bind(this)
          );
      }
    });
  }

  submitError(error) {
    this.messageService.error(error);
    this.submitSubClear();
  }

  submitSub: Subscription;

  submitSubClear() {
    if (this.submitSub) this.submitSub.unsubscribe();
    this.submitSub = null;
  }

  submitSuccess(result) {
    this.loanFacade.refreshLoan(this.data.loan.id).subscribe();

    AccountTransferSubmittedDialogComponent.show(
      this.dialog,
      this.data.accountTransfer,
      "Payment submitted",
      "payment")
      .subscribe(() => {
        this.cancel();
    });
  }

  setAmount(value: number): void {
    this.form.controls.paymentAmount.setValue(value);
  }

  accountNewBalancesInit(): void {
    const value = this.data.accountTransfer.amountInput
      ? parseFloat(this.data.accountTransfer.amountInput)
      : 0;

    this.loanFacade
      .payPreview(this.data.loan.id, value, this.promotionInfo?.promoCode)
      .subscribe((res) => {
        this.data.accountTransfer.balanceAfter.outstandingBalance =
          res.newOutstandingBalance;
        this.data.accountTransfer.balanceAfter.availableBalance =
          res.newAvailableFunds;
        this.data.accountTransfer.balanceAfter.upcomingPaymentAmount =
          res.newUpcomingPaymentAmount;
        this.data.accountTransfer.balanceAfter.upcomingPaymentDate =
          res.newUpcomingPaymentDate;
        this.data.accountTransfer.promotionInfo = res.promotionInfo;

        this._accountTransferService.onAccountTransferChanged.next(this.data);
      });
  }

  cancel(): void {
    this.appPageService.back();
  }

  private getPaymentAmountValidators(): ValidatorFn[] {
    return [
      Validators.required,
      Validators.min(this.minimumAmount),
      Validators.max(this.maximumAmount),
    ];
  }

  private applyPromotionChanged(res: any) {
    this.promotionInfo = res;
    this.form.controls.paymentAmount.setValidators(
      this.getPaymentAmountValidators()
    );
    this.form.controls.paymentAmount.updateValueAndValidity();
    if (this.promotionInfo?.payoffAmount)
      this.form.controls.paymentAmount.markAsTouched();
    this.accountNewBalancesInit();
  }
}
