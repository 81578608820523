import {
  Component,
  ViewChild,
  ElementRef,
  OnInit,
  AfterViewInit,
  Input,
  ContentChild,
  TemplateRef,
} from "@angular/core";
import { confetti, ConfettiConfig } from "dom-confetti";
import {
  ApplicationBaseData,
  ApplicationOfferBundleData,
} from "../../../application/application.model";
import { DisplayAllOffersData } from "../../models/display-all-offers-data.model";
import { OfferSummaryFacade } from "./offer-summary.facade";

@Component({
  selector: "ifc-offer-summary",
  templateUrl: "./offer-summary.component.html",
  styleUrls: ["./offer-summary.component.scss"],
  providers: [OfferSummaryFacade],
})
export class OfferSummaryComponent implements OnInit, AfterViewInit {
  @ViewChild("confettiCanvas", { static: true }) confettiCanvas: ElementRef;

  @Input()
  application: ApplicationBaseData;

  @Input()
  offerBundles: ApplicationOfferBundleData[];

  @ContentChild("shareOffer", { read: TemplateRef })
  shareOfferRef: TemplateRef<unknown>;

  @ContentChild("contactFooter", { read: TemplateRef })
  contactFooterRef: TemplateRef<unknown>;

  bestOfferBundles: ApplicationOfferBundleData[];
  maxOfferAmount: number;
  maxOfferRepayment: number;

  constructor(private facade: OfferSummaryFacade) {}

  ngOnInit() {
    this.bestOfferBundles = this.facade.getBestOfferBundles(this.offerBundles);
    this.maxOfferAmount = this.facade.getMaxOfferAmount(this.bestOfferBundles);
    this.maxOfferRepayment = this.facade.getMaxRepayment(this.bestOfferBundles);
  }

  ngAfterViewInit(): void {
    this.showConfetti();
  }

  private showConfetti() {
    window.setTimeout(() => {
      confetti(this.confettiCanvas.nativeElement, <ConfettiConfig>{
        angle: 90,
        spread: 360,
        startVelocity: 50,
        elementCount: 100,
      });
    }, 1000);
  }

  onDisplayAllOffers(data: DisplayAllOffersData): void {
    this.facade.displayAllOffers(data);
  }

  onLinkBank(entityId: number): void {
    this.facade.linkBank(entityId);
  }
}
