import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CompanyBranding } from "projects/common/src/lib/branding/branding.model";

@Component({
  selector: "ifc-offer-details-heading",
  templateUrl: "./offer-details-heading.component.html",
  styleUrls: ["./offer-details-heading.component.scss"],
})
export class OfferDetailsHeadingComponent implements OnInit {
  @Input() businessName: string;
  @Input() companyData: CompanyBranding;
  @Input() canSelectOffer: boolean;
  @Input() isAlreadySelected: boolean;
  @Input() advisorName: string;
  @Input() advisorPhone: string;

  @Output() viewAllOffers: EventEmitter<void> = new EventEmitter();

  companyName: string;
  companyCssClass: string;

  ngOnInit(): void {
    this.companyName = this.companyData?.dba;
    this.companyCssClass = `companyName_${this.companyData?.colorSchema}`;
  }

  onClickViewAllOffers() {
    this.viewAllOffers.emit();
  }
}
