<div class="ifp-contact-wrapper"
     fxLayout="column">

     <ng-container [ngSwitch]="personalCompanyFormType">
      <ng-container *ngSwitchCase="PersonalCompanyFormType.Personal">
        <ng-container *ngTemplateOutlet="personal"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="PersonalCompanyFormType.Company">
        <ng-container *ngTemplateOutlet="company"></ng-container>
      </ng-container>
    </ng-container>
</div>

<ng-template #personal>
  <ifp-contact-data-navigation class="margin-bottom" [currentFormType]="PersonalCompanyFormType.Personal">
  </ifp-contact-data-navigation>

  <ifp-customer-contact-data-form [currentCustomerData]="currentCustomer">
  </ifp-customer-contact-data-form>
</ng-template>

<ng-template #company>
  <ifp-contact-data-navigation class="margin-bottom" [currentFormType]="PersonalCompanyFormType.Company">
  </ifp-contact-data-navigation>

  <ifp-company-contact-data-form [currentCompanyData]="currentCompany">
  </ifp-company-contact-data-form>
</ng-template>

