import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { EntityExData } from "common";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "ifp-company-select",
  templateUrl: "./company-select.component.html",
})
export class CompanySelectComponent implements OnInit {
  entities: EntityExData[];
  currentEntityId: string;

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    this.route.parent.data.pipe(untilDestroyed(this)).subscribe((data) => {
      this.entities = data?.entities;
      this.currentEntityId = data?.entity?.id;
    });
  }

  changeEntity(entityId: number): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { companyId: entityId },
      queryParamsHandling: "merge",
    });
  }
}
