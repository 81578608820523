import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  AppPageComponent
} from 'common';
import { UserSignUpGuard } from './user-sign-up.guard';

const routes: Routes = [
  {
    path: 'registerotp',
    component: AppPageComponent,
    canActivate: [UserSignUpGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UserSignUpRoutingModule { }
