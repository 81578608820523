import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { StateService } from "../state/state.service";
import { CompanyBranding } from "../../lib/branding/branding.model";

@Injectable({ providedIn: "root" })
export class BrandingStateService extends StateService<BrandingState> {
  constructor() {
    super(initialState);
  }

  getBranding$(): Observable<CompanyBranding> {
    return this.select((state: BrandingState) => state?.branding);
  }

  setBranding(branding: CompanyBranding): void {
    const newState = {
      ...this.state,
      branding,
    };
    setBrandingStorage(branding);
    this.setState(newState);
  }

  isBrandingStateLoaded(): boolean {
    return !!this.state?.branding;
  }

  clearState(): void {
    this.initState(initialState);
  }
}

export interface BrandingState {
  branding: CompanyBranding;
}

const initialState: BrandingState = getInitialBrandingState();

function getInitialBrandingState(): BrandingState {
  return { branding: sessionStorage.branding ? JSON.parse(sessionStorage.branding) : null }
}

function setBrandingStorage(branding: CompanyBranding): void {
  sessionStorage.branding = JSON.stringify(branding);
}

