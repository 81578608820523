<mat-dialog-content>
  <div>
    <span class="mat-title">Sent!</span>
  </div>
  <div class="content" class="layout padding-top-md">
    <span class="text secondary-text">
      The application to get great funding is on its way to your friends.
    </span>
  </div>
</mat-dialog-content>
<div mat-dialog-actions class="flex row end-center">
  <button mat-button [mat-dialog-close]="false">Refer more friends</button>
  <button type="button" mat-button color="primary" [mat-dialog-close]="true">OK</button>
</div>