import { Injectable } from "@angular/core";
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import { EntityData, RouteHelper, parseNumber } from "common";
import { EntityFacade } from "../domain/+state/entity.facade";

@Injectable({
  providedIn: "root",
})
export class EntitiesResolver implements Resolve<EntityData[]> {
  constructor(private entityFacade: EntityFacade) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): EntityData[] | Observable<EntityData[]> | Promise<EntityData[]> {
    return this.entityFacade.getEntities$().pipe(take(1));
  }
}

@Injectable({
  providedIn: "root",
})
export class EntityQueryResolver implements Resolve<EntityData> {
  constructor(private entityFacade: EntityFacade) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): EntityData | Observable<EntityData> | Promise<EntityData> {
    const companyId = parseNumber(RouteHelper.getQueryParamValueByName(route, "companyId"));
    return this.entityFacade.getEntity$(companyId).pipe(take(1));
  }
}
