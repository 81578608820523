import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DocumentCategoryLabelPipe } from "./pipes/document-category-label.pipe";

@NgModule({
  imports: [CommonModule],
  declarations: [DocumentCategoryLabelPipe],
  exports: [DocumentCategoryLabelPipe],
})
export class DocumentModule {}
