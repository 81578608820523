import { NgModule } from "@angular/core";
import { UiModule } from "../ui/ui.module";
import { AboutComponent } from "./components/about/about.component";
import { ReleaseNoteComponent } from "./components/release-note/release-note.component";

@NgModule({
  imports: [UiModule],
  declarations: [
    AboutComponent,
    ReleaseNoteComponent
  ],
  exports: [AboutComponent]
})
export class AboutModule {}
