import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Component({
  selector: 'ifp-bank-account-link-success-dialog',
  templateUrl: './bank-account-link-success-dialog.component.html'
})
export class BankAccountLinkSuccessDialogComponent  {  

  constructor(
    public dialogRef: MatDialogRef<BankAccountLinkSuccessDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {
  }

  static show(dialog: MatDialog, institutionName: string): Observable<any> {
    return dialog.open(BankAccountLinkSuccessDialogComponent, {
      width: '500px',
      maxHeight: '300px',
      data: {
        institutionName: institutionName
      },
      closeOnNavigation: true,
    }).afterClosed();
  }

}
