import {
  Component,
  ContentChild,
  Input,
  OnInit,
  TemplateRef,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { ErrorImgType } from "../../enums/error-img-type.enum";
import { CompanyBranding } from "projects/common/src/lib/branding/branding.model";
import { AppPageService } from "../../../ui/app/app-page/app-page.service";
import { ErrorActionType } from "../../enums/error-action-type.enum";
import { ErrorDescriptionType } from "../../enums/error-description-type.enum";
import { BrandingService } from "../../../../core/branding/branding.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "ifc-error-base-page",
  templateUrl: "./error-base-page.component.html",
  styleUrls: ["./error-base-page.component.scss"],
})
export class ErrorBasePageComponent implements OnInit {
  @Input()
  errorImgType?: ErrorImgType = ErrorImgType.WarningSign;

  @Input()
  actionType: ErrorActionType;

  @ContentChild("title", { static: true, read: TemplateRef })
  titleRef: TemplateRef<unknown>;

  @ContentChild("description", { static: true, read: TemplateRef })
  descriptionRef: TemplateRef<unknown>;

  logoSrc: any;
  logoTitle: string;
  errorImgSrc: string;
  hasAnyUrlHistory: boolean;
  phoneNumber: string;
  ErrorActionType = ErrorActionType;
  ErrorDescriptionType = ErrorDescriptionType;

  constructor(
    private readonly sanitizer: DomSanitizer,
    private readonly appPageService: AppPageService,
    private readonly router: Router,
    private readonly brandingService: BrandingService,
  ) {}

  ngOnInit() {
    this.initBranding();

    this.errorImgSrc = this.getErrorImgSrc(this.errorImgType);
    this.actionType = this.initActionType();
  }

  private initBranding() {
    this.brandingService.getBranding$()
    .pipe(untilDestroyed(this))
    .subscribe((branding: CompanyBranding) => {
      this.phoneNumber = branding?.phone;
      this.sanitize(branding);
    });
  }

  private sanitize(branding: CompanyBranding) {
    if (branding?.logo) {
      this.logoSrc = this.sanitizer.bypassSecurityTrustResourceUrl(
        branding.logo
      );
      this.logoTitle = branding?.logoTitle;
    }
  }

  private getErrorImgSrc(errorImgType: ErrorImgType) {
    if (errorImgType === ErrorImgType.PageNotFound) {
      return "../../../../../assets/errors/404.png";
    }
    return "../../../../../assets/errors/warning-sign.png";
  }

  private initActionType() {
    if (Object.values(ErrorActionType).includes(this.actionType)) {
      return this.actionType;
    }

    return this.appPageService.hasAnyHistory()
      ? ErrorActionType.Back
      : ErrorActionType.Dashboard;
  }

  onClickAction(actionType: ErrorActionType) {
    switch(actionType) {
      case ErrorActionType.Back: {
        this.appPageService.back();
        break;
      }
      case ErrorActionType.Dashboard:
      case ErrorActionType.Homepage: {
        this.router.navigate(["/"]);
        break;
      }
      case ErrorActionType.BackToLogin: {
        this.router.navigate(["sign-in"]);
        break;
      }
      default: {
        console.warn('Unsuported error handling action.');
        break;
      }
    }
  }
}
