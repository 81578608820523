import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ErrorData } from "./models/errors.model";
import { Router } from "@angular/router";
import { ErrorActionType } from "./enums/error-action-type.enum";
import { ErrorDescriptionType } from "./enums/error-description-type.enum";

@Injectable({ providedIn: "root" })
export class ErrorsService {
  private errorDataSubject: BehaviorSubject<ErrorData>;
  public errorData$: Observable<ErrorData>;

  constructor(private router: Router) {
    this.errorDataSubject = new BehaviorSubject<ErrorData>(null);
    this.errorData$ = this.errorDataSubject.asObservable();
  }

  showGeneralErrorPage(actionType: ErrorActionType = undefined) {
    if (actionType) {
      this.setGeneralErrorPageBase({ description: undefined, title: undefined, actionType })
    } else {
      this.setGeneralErrorPageBase(undefined);
    }
  }

  showNotFoundErrorPage() {
    this.router.navigate(["error/404"]);
  }

  showCustomErrorPage(
    description: string,
    title: string = "Error",
    actionType?: ErrorActionType,
    descriptionType: ErrorDescriptionType = ErrorDescriptionType.Text
  ) {
    this.setCustomErrorPageBase({ description, title, actionType, descriptionType});
  }

  showForbiddenErrorPage(description?: string) {
    this.setCustomErrorPageBase({
      description:
        description ?? "You don't have access to view this resource.",
      title: "Access Denied",
      actionType: ErrorActionType.Dashboard,
    });
  }

  private setCustomErrorPageBase(data: ErrorData) {
    this.setErrorPageBase(data, 'error/custom')
  }

  private setGeneralErrorPageBase(data: ErrorData) {
    this.setErrorPageBase(data, 'error')
  }

  private setErrorPageBase(data: ErrorData, path: string) {
    this.errorDataSubject.next(data);
    this.router.navigate([path]);
  }
}
