import { Component, OnInit } from "@angular/core";
import { FinancialInstitution } from "../bank-account.model";
import {
  AppBarCloseAction,
  AppBarTitleService,
  BrandingService,
  CompanyBranding,
  CompanyColorSchemaEnum,
  EntityData,
} from "common";
import { ActivatedRoute, Router } from "@angular/router";
import { AppSettings } from "../../../../src/app.settings";
import { BankingService } from "../banking.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "ifp-bank-account-link",
  templateUrl: "./bank-account-link.component.html",
  styleUrls: ["./bank-account-link.component.scss"],
})
export class BankAccountLinkComponent implements OnInit {
  data: BankAccountLinkComponentData;
  companySchema: CompanyColorSchemaEnum;

  get contactPhone() {
    return this.settings.if.contact.phone;
  }

  constructor(
    private bankingService: BankingService,
    private route: ActivatedRoute,
    private appBarTitleService: AppBarTitleService,
    private settings: AppSettings,
    private router: Router,
    private brandingService: BrandingService,
  ) {
    sessionStorage.setItem("relinkAccountViewed", JSON.stringify(true));
  }

  ngOnInit() {
    this.route.data.pipe(untilDestroyed(this)).subscribe((data) => {
      this.data = data;
    });

    this.initBrandingInfo();
    this.appBarTitleService.title = "Link your bank";
  }

  private initBrandingInfo(): void {
    this.brandingService.getBranding$()
      .pipe(untilDestroyed(this))
      .subscribe((branding: CompanyBranding) => {
        this.companySchema = <CompanyColorSchemaEnum>branding?.colorSchema;
      });
  }

  relink(financialInstitution: FinancialInstitution) {
    this.bankingService.relink(
      this.data.entity.id,
      financialInstitution,
      "dashboard",
      true
    );
  }

  link() {
    this.bankingService.link(this.data.entity.id, "dashboard");
  }

  skip() {
    this.router.navigate([AppBarCloseAction.HOME]);
  }
}

export interface BankAccountLinkComponentData {
  entity?: EntityData;
  financialInstitutions?: FinancialInstitution[];
}
