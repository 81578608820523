import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UppyFile } from "@uppy/core";
import { CheckoutRequirementStatus, OfferBundleCheckoutRequirement } from 'common';
import { CheckoutRequirementFileUploadModel } from '../../model/offer-documents.model';
import { OfferCheckoutRequirementListService } from './offer-checkout-requirement-list.service';
@Component({
  selector: 'ifp-offer-checkout-requirement-list',
  templateUrl: './offer-checkout-requirement-list.component.html',
  styleUrls: ['./offer-checkout-requirement-list.component.scss'],
  providers: [OfferCheckoutRequirementListService]
})
export class OfferCheckoutRequirementListComponent implements OnChanges {
  @Input() applicationId: number;
  @Input() checkoutRequirements: OfferBundleCheckoutRequirement[];
  @Output() filesToUploadChanged = new EventEmitter<CheckoutRequirementFileUploadModel[]>();

  checkoutRequirementsFiles: CheckoutRequirementFileUploadModel[] = [];

  constructor(private service: OfferCheckoutRequirementListService) {}


  public onCheckoutRequirementChanged(checkoutRequirement: OfferBundleCheckoutRequirement, filesToUpload: UppyFile[]) {
    this.service.onCheckoutRequirementChanged(checkoutRequirement, filesToUpload, this.checkoutRequirementsFiles);
    this.filesToUploadChanged.emit(this.checkoutRequirementsFiles);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.checkoutRequirements && changes.checkoutRequirements.currentValue) {
      this.checkoutRequirements = changes.checkoutRequirements.currentValue
        .filter(checkoutRequirement =>
          checkoutRequirement.status === CheckoutRequirementStatus.Required ||
          checkoutRequirement.status === CheckoutRequirementStatus.Approved);
    }
  }
}
