import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Component({
  selector: 'ifp-referral-code-email-dialog',
  templateUrl: './referral-code-email-dialog.component.html',
  styleUrls: ['./referral-code-email-dialog.component.scss']
})
export class ReferralCodeEmailDialogComponent {

  constructor(public dialogRef: MatDialogRef<ReferralCodeEmailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  static show(dialog: MatDialog): Observable<any> {
    return dialog.open(ReferralCodeEmailDialogComponent, {
      width: '450px',
      closeOnNavigation: true,
    }).afterClosed();
  }
}
