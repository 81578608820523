import { Component, OnInit, Input, ViewChild, OnChanges } from "@angular/core";
import { ApplicationStatus, LinkingStatus } from "common";
import { ApplicationData } from "../../application/application.model";
import { BankingService } from "../../bank-account/banking.service";
import { Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "ifp-application-status-stepper",
  templateUrl: "./application-status-stepper.component.html",
  styleUrls: ["./application-status-stepper.component.scss"],
})
export class ApplicationStatusStepperComponent implements OnInit, OnChanges {
  @ViewChild("verticalStepper", { static: true }) verticalStepper;

  @Input()
  application: ApplicationData;

  get status() {
    return ApplicationStatus;
  }

  message = "";
  showError: boolean;
  unlinkedBank: boolean;
  lastStep: boolean;
  showLinkBankButton: boolean;

  steps = ["Application created", "Underwriting", "Decision"];

  constructor(private router: Router, private bankingService: BankingService) {}

  ngOnInit() {
    this.bankingService.obsSync.pipe(untilDestroyed(this)).subscribe(() => {
      this.showLinkBankButton = false;
    });

    this.bankingService.obsInstitutionLinkStatus
      .pipe(untilDestroyed(this))
      .subscribe((res) => (this.showLinkBankButton = !res));
  }

  ngOnChanges(): void {
    this.initData();
  }

  private initData() {
    this.showError = false;
    this.unlinkedBank =
      this.application?.bankLinkStatus !== LinkingStatus.Linked;
    this.showLinkBankButton = this.unlinkedBank;
    switch (this.application?.sfAppStatus) {
      case ApplicationStatus.ApplicationCreated:
      case ApplicationStatus.ApplicationIncomplete:
      case ApplicationStatus.Processing:
      case ApplicationStatus.Other:
        this.verticalStepper.selectedIndex = 0;
        this.message = this.unlinkedBank
          ? "Link your bank to continue with the application process. Schedule a call if you have any questions."
          : "Your application is up for review. Come back later to check on the status.";
        this.showError = this.unlinkedBank;
        break;
      case ApplicationStatus.Underwriting:
      case ApplicationStatus.UnderwritingReview:
      case ApplicationStatus.ConditionalOffer:
        this.verticalStepper.selectedIndex = 1;
        this.message = "Your application is currently under review.";
        break;
      case ApplicationStatus.UnderwritingMissingInfo:
        this.verticalStepper.selectedIndex = 1;
        // eslint-disable-next-line max-len
        this.message =
          "We are missing some information. A business advisor will reach out to you shortly. Schedule a call if you have any questions.";
        this.showError = true;
        break;
      case ApplicationStatus.Approved:
      case ApplicationStatus.Accepted:
        this.verticalStepper.selectedIndex = 2;
        this.lastStep = true;
        this.message =
          "Congratulations! Your application has been approved. See your offer now.";
        break;
      case ApplicationStatus.Closing:
      case ApplicationStatus.SalesReview:
      case ApplicationStatus.ClosingReview:
      case ApplicationStatus.ClosingIncomplete:
      case ApplicationStatus.PendingSignature:
        this.verticalStepper.selectedIndex = 2;
        this.lastStep = true;
        this.message =
          "Congratulations! Your application is now closing. A business advisor will reach out shortly.";
        break;
      case ApplicationStatus.UnderwritingCall:
        this.verticalStepper.selectedIndex = 2;
        this.showError = true;
        this.lastStep = true;
        this.message =
          "We have a couple more questions regarding your application. Schedule a call to close your application.";
        break;
      case ApplicationStatus.Declined:
        // eslint-disable-next-line max-len
        this.message =
          "Unfortunately we are unable to accept your application at this moment. Please try again at a later time. Schedule a call if you have any questions.";
        break;
      case ApplicationStatus.Dormant:
        this.verticalStepper.selectedIndex = 2;
        this.message =
          "Your application is now dormant. Schedule a call if you have any questions.";
        break;
    }
  }

  link() {
    this.router.navigate(["/bank-link-reminder"]);
  }

  offer() {
    this.router.navigate(
      [`/application/${this.application.id}/offers/summary`],
      {
        replaceUrl: true,
      }
    );
  }

  calendarLink() {
    window.open(
      this.application?.advisorCalendarLinkUrl ??
        "https://calendly.com/ideafinancial",
      "_blank"
    );
  }
}
