<ifc-error-base-page *ngIf="errorData; else generalError"
                     [actionType]="errorData.actionType">
  <ng-template #title>
    {{ errorData.title }}
  </ng-template>

  <ng-template #description>
    <ng-container [ngSwitch]="errorData.descriptionType">
      <ng-container *ngSwitchCase="ErrorDescriptionType.Text">
        <ng-container *ngTemplateOutlet="textDescription"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="ErrorDescriptionType.HTML">
        <ng-container *ngTemplateOutlet="htmlDescription"></ng-container>
      </ng-container>
    </ng-container>
  </ng-template>
  
</ifc-error-base-page>

<ng-template #textDescription> 
  {{ errorData.description }}
</ng-template>

<ng-template #htmlDescription> 
  <div [innerHTML]="errorData.description | safeHtml"></div>
</ng-template>

<ng-template #generalError>
  <ifc-error-base-page></ifc-error-base-page>
</ng-template>
