<ifc-app-scroll-container class="ifc-app-scroll-container-center">

  <div id="main" fxLayout="column" fxLayoutAlign="center center">

    <div fxLayout="column" fxLayoutAlign="start center" fxFlexFill.lt-lg ngClass.lt-lg="fit-content"
      [ngClass.xl]="'form-field-lg'" [ngClass.lg]="'form-field-lg'" [ngClass.lt-lg]="'form-field-lg'"
      [ngClass.xs]="'form-field-xs'">

      <div class="title">
        {{title}}
      </div>

      <div class="secondary-text align-left">
        <p>Search for or select one of the options below. You will need your bank login credentials.</p>
        <p>If your bank isn't showing up, reach out to us at <a href="mailto:{{contactEmail}}">{{contactEmail}}</a>
        or <a href="tel:{{contactPhone}}">{{contactPhone}}</a>.</p>
      </div>

      <div fxLayout="column" fxLayoutAlign="center center">

        <div [ngClass.xl]="'form-field-lg'" [ngClass.lg]="'form-field-lg'" [ngClass.lt-lg]="'form-field-lg'"
          [ngClass.xs]="'form-field-xs'">

          <form [formGroup]="form">
            <ifp-bank-account-search-input [fc]="form.get('search')" (autocompleteOptionSelected)="chooseBank($event)"></ifp-bank-account-search-input>
          </form>

          <div class="secondary-text align-center margin bottom">
            Or select one of the banks below
          </div>

          <div fxLayout.gt-xs="row" fxLayout.xs="column" fxLayoutGap="26px">

            <div fxLayout.gt-xs="column" fxLayout.xs="row" fxLayoutAlign.xs="space-between center" fxLayoutGap="32px"
              fxLayoutGap.xs="5px">

              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="6px" class="bank-container"
                (click)="chooseBank(PopularInstistitution.WellsFargo, true)">
                <img *ngIf="logoExists(PopularInstistitution.WellsFargo)"
                  [src]="getLogo(PopularInstistitution.WellsFargo)" alt="Wells Fargo logo" class="bank-logo">
                <div *ngIf="!logoExists(PopularInstistitution.WellsFargo)" class="avatar circle-wrap"
                  fxLayoutAlign="center center">
                  <mat-icon>account_balance</mat-icon>
                </div>
                <div><b class="secondary-text">WELLS FARGO</b></div>
              </div>

              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="6px" class="bank-container"
                (click)="chooseBank(PopularInstistitution.ChaseBank, true)">
                <img *ngIf="logoExists(PopularInstistitution.ChaseBank)"
                  [src]="getLogo(PopularInstistitution.ChaseBank)" alt="Chase Bank logo" class="bank-logo">
                <div *ngIf="!logoExists(PopularInstistitution.ChaseBank)" class="avatar circle-wrap"
                  fxLayoutAlign="center center">
                  <mat-icon>account_balance</mat-icon>
                </div>
                <div><b class="secondary-text">CHASE BANK</b></div>
              </div>

            </div>

            <div fxLayout.gt-xs="column" fxLayout.xs="row" fxLayoutAlign.xs="space-between center" fxLayoutGap="32px"
              fxLayoutGap.xs="5px">
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="6px" class="bank-container"
                (click)="chooseBank(PopularInstistitution.BankOfAmerica, true)">
                <img *ngIf="logoExists(PopularInstistitution.BankOfAmerica)"
                  [src]="getLogo(PopularInstistitution.BankOfAmerica)" alt="Bank of America logo" class="bank-logo">
                <div *ngIf="!logoExists(PopularInstistitution.BankOfAmerica)" class="avatar circle-wrap"
                  fxLayoutAlign="center center">
                  <mat-icon>account_balance</mat-icon>
                </div>
                <div><b class="secondary-text">BANK OF AMERICA</b></div>
              </div>

              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="6px" class="bank-container"
                (click)="chooseBank(PopularInstistitution.PncBank, true)">
                <img *ngIf="logoExists(PopularInstistitution.PncBank)" [src]="getLogo(PopularInstistitution.PncBank)"
                  alt="PNC Bank logo" class="bank-logo">
                <div *ngIf="!logoExists(PopularInstistitution.PncBank)" class="avatar circle-wrap"
                  fxLayoutAlign="center center">
                  <mat-icon>account_balance</mat-icon>
                </div>
                <div><b class="secondary-text">PNC BANK</b></div>
              </div>
            </div>

            <div fxLayout.gt-xs="column" fxLayout.xs="row" fxLayoutAlign.xs="space-between center" fxLayoutGap="32px"
              fxLayoutGap.xs="5px">
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="6px" class="bank-container"
                (click)="chooseBank(PopularInstistitution.TdBank, true)">
                <img *ngIf="logoExists(PopularInstistitution.TdBank)" [src]="getLogo(PopularInstistitution.TdBank)"
                  alt="TD Bank logo" class="bank-logo">
                <div *ngIf="!logoExists(PopularInstistitution.TdBank)" class="avatar circle-wrap"
                  fxLayoutAlign="center center">
                  <mat-icon>account_balance</mat-icon>
                </div>
                <div><b class="secondary-text">TD BANK</b></div>
              </div>

              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="6px" class="bank-container"
                (click)="chooseBank(PopularInstistitution.UsBank, true)">
                <img *ngIf="logoExists(PopularInstistitution.UsBank)" [src]="getLogo(PopularInstistitution.UsBank)"
                  alt="US Bank logo" class="bank-logo">
                <div *ngIf="!logoExists(PopularInstistitution.UsBank)" class="avatar circle-wrap"
                  fxLayoutAlign="center center">
                  <mat-icon>account_balance</mat-icon>
                </div>
                <div><b class="secondary-text">US BANK</b></div>
              </div>
            </div>

          </div>

          <div class="secondary-text margin top">
            By linking your bank, you agree to our <a href="https://www.ideafinancial.com/terms-of-service/" target="_blank">Terms of Service</a>.
          </div>

        </div>

      </div>

    </div>

  </div>

</ifc-app-scroll-container>