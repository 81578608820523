import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerCheckoutService } from '../customer-checkout.service';
import { AppPageService, CustomerKbaConversation, CustomerKbaStatus, MessageService } from 'common';
import { CustomerData } from '../../domain/models/customer.model';
import { CustomerFacade } from '../../domain/+state/customer.facade';
import { map, mergeMap, take } from 'rxjs/operators';

@Component({
  selector: 'ifp-customer-kba-form',
  templateUrl: './customer-kba-form.component.html',
  styleUrls: ['./customer-kba-form.component.scss']
})
export class CustomerKbaFormComponent implements OnInit, OnDestroy {

  private subs: Subscription[] = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private customerCheckoutService: CustomerCheckoutService,
    private messageService: MessageService,
    private customerFacade: CustomerFacade,
    private appPageService: AppPageService
  ) {
    sessionStorage.setItem('accountSetupViewed', JSON.stringify(true));
  }

  data: KbaFormComponentData;

  dataSet(data: KbaFormComponentData) {
    this.data = data;
    this.formInit();
  }

  form: UntypedFormGroup;

  formInit() {
    this.form = this.formBuilder.group({
      conversationId: [this.data.kbaConversation.conversationId, Validators.required],
      answers: this.formBuilder.group({
        QuestionSetId: [this.data.kbaConversation.questionSet.QuestionSetId, Validators.required],
        Questions: this.formBuilder.array(this.data.kbaConversation.questionSet.Questions.map(question => this.formBuilder.group({
          QuestionId: question.QuestionId,
          Choices: this.formBuilder.array([
            this.formBuilder.group({ Choice: ['', Validators.required] })
          ])
        })))
      })
    });
  }

  ngOnInit() {
    this.subs = [
      this.route.data.subscribe(this.dataSet.bind(this))
    ];
  }

  ngOnDestroy() {
    this.subs.forEach(_ => _.unsubscribe());
  }

  submit() {
    if (!this.form.valid)
      return;

    this.customerFacade.clearCustomerCache();



    this.submitSub = this.customerCheckoutService
      .answer(this.form.value)
      .pipe(mergeMap(results => {
        return this.customerFacade.getCurrentCustomer$().pipe(take(1), map(customer => {
          return { results, customer };
        }))
      }))
      .subscribe({
        next: this.submitSuccess.bind(this),
        error: this.submitError.bind(this)
      });
  }

  submitEnabled = true;

  private submitError(response: any) {
    this.submitSubClear();
    if (response.results.error.error === 'InvalidOperationException') {
      this.customerCheckoutService.verifyAnswersError = response.results.error;
      this.submitEnabled = false;
      this.router.navigate(['account', 'setup', 'verify-identity-result'], { replaceUrl: true });
    } else {
      this.messageService.error(response.results);
    }
  }

  private submitSuccess(result: any) {
    this.submitSubClear();
    if (result.results.status === CustomerKbaStatus.Success) {
      this.submitEnabled = false;
      if(!this.appPageService.backToIfPossible(true))
      {
        if(result.customer?.pendingApplications?.length > 0) {
          this.router.navigate([`application/${result.customer?.pendingApplications[0]}/setup/sign-agreement`]);
        }
        else if(result.customer?.pendingLoans?.length > 0)
          this.router.navigate([`account/${result.customer?.pendingLoans[0]}/setup/sign-agreement`]);
        else
          this.router.navigate(['home']);
      }
    } else if (result.results.status === CustomerKbaStatus.None) {
      this.data.kbaConversation = result.results.questions;
      this.formInit();
    }
  }

  submitSub: Subscription;

  private submitSubClear() {
    this.submitSub.unsubscribe();
    this.submitSub = null;
  }
}

export interface KbaFormComponentData {
  customer: CustomerData;
  kbaConversation: CustomerKbaConversation;
}
