import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { catchError, map, switchMap, take } from "rxjs/operators";
import { Observable, of } from "rxjs";
import * as _ from 'lodash';
import { InsuranceService } from "./insurance.service";
import { EntityData } from "../../../../common/src/lib/entity/entity.model";
import { InsuranceGuardResult, InsuranceType, QuoteResponseData, QuoteResult } from "./insurance.model";
import { FeatureFlagService, RouteHelper, parseNumber } from "common";
import { ReminderService } from "../reminder/reminder.service";
import { ReminderData, Reminders } from "../reminder/reminder.model";
import { EntityFacade } from "../entity/domain/+state/entity.facade";

@Injectable({
    providedIn: "root"
})
export class InsuranceReminderQuoteGuard implements CanActivate {
    private readonly insuranceReminderName = Reminders.Insurance;

    constructor(private router: Router,
        private entityFacade: EntityFacade,
        private _insuranceService: InsuranceService,
        private _featureFlagService: FeatureFlagService,
        private reminderService: ReminderService
    ) {
    }

    canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {
        return this._featureFlagService.activated('GetInsuranceQuote').pipe(
            map((res: boolean) => res),
            switchMap(res => res ?
                this.entityFacade.getEntity$(parseNumber(RouteHelper.getQueryParamValueByName(_route, "companyId"))).pipe(
                    take(1),
                    map((entity: EntityData) => new InsuranceGuardResult({ entity: entity })),
                    switchMap(entityResponse => entityResponse.entity?.ein
                        ? this._insuranceService.getQuote(entityResponse.entity.ein, InsuranceType["general-liability"])
                            .pipe(
                                map((quoteResponse: QuoteResponseData) => new InsuranceGuardResult({
                                    insurance: quoteResponse,
                                    entity: entityResponse.entity,
                                    disableReminder: false
                                })),
                                catchError((e: any) => of(new InsuranceGuardResult({ disableReminder: true }))))
                        : of(new InsuranceGuardResult({ disableReminder: true }))
                    ),
                    switchMap((quoteResult) => quoteResult.entity ?
                        this.reminderService.query(quoteResult.entity.id).pipe(
                            map(reminders => {
                                quoteResult.reminders = reminders;
                                return quoteResult;
                            })
                        )
                        : of(new InsuranceGuardResult({ disableReminder: true }))
                    ),
                    map((result: InsuranceGuardResult) => {
                        return this.validate(new InsuranceGuardResult({
                            insurance: result?.insurance,
                            entity: result?.entity,
                            disableReminder: result?.disableReminder,
                            reminders: result?.reminders
                        }));
                    })
                )
                : of(true)
            )
        );
    }

    validate(result: InsuranceGuardResult): boolean {
        if (result.disableReminder)
            return true;
        const dismissedReminder = !(result.reminders &&
            _.some(result.reminders, (item: ReminderData) => {
                return item.name === this.insuranceReminderName;
            }));
        const viewed = JSON.parse(sessionStorage.getItem('insurancePageViewed'));

        if(!dismissedReminder)
        {
            if(result.insurance?.quotes?.length)
            {
                const successQuote = _.find(result.insurance.quotes, { result: QuoteResult.Success });
                if (!successQuote)
                    return true;
                else if (!viewed) {
                    this._insuranceService.onQuoteChanged.next(successQuote);
                    this.router.navigate(['insurance-quote/reminder'], { replaceUrl: true });
                    return false;
                }
            }
            else if (!viewed)
                this.router.navigate(['insurance-reminder'], { replaceUrl: true });
        }

        return true;
    }

}
