<div id="main" fxLayout="column" fxLayoutAlign="center center">

  <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="12px" ngClass.lt-sm="layout padding-horizontal">

    <div class="header" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="12px">
      <img src="../../assets/ideafinancial-renewal.png" alt="Renewal">
      <span ngClass.gt-sm="title" ngClass.lt-sm="title xs">
        Get started on your renewal
      </span>
      <span ngClass.lt-sm="title xs">
        Select the bank below to link. You'll need your bank login credentials wich we can't see, store or access.
      </span>
    </div>

    <div class="content" fxLayout="column" fxLayoutAlign.lt-sm="center center" fxLayoutGap="12px"
      fxLayoutGap.lt-sm="8px">

      <div fxLayout="column" fxLayoutAlign="center center">
        <ifp-bank-linking [bankLinkingData]="bankLinkingData"></ifp-bank-linking>
      </div>

      <div fxFlexAlign="center">
        <span ngClass.lt-sm="title xs">or</span>
      </div>

      <span ngClass.lt-sm="title xs">
        Upload the last 3 months of your business bank statements in PDF format below.
      </span>

      <uppy-dashboard [uppy]="uppy" [props]="uppyDashboardOptions"></uppy-dashboard>

      <mat-form-field appearance="outline">
        <mat-label>Category</mat-label>
        <mat-select [(ngModel)]="selectedCategory" disabled="true">
          <mat-option *ngFor="let category of applicationCategories" [value]="category.value">{{category.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div fxLayout="row" fxFlexAlign="space-between center" class="layout padding-top">
        <div fxLayoutAlign="end" fxLayoutGap="8px" fxFlex="100">
          <button mat-button color="mat-gray" type="button" [routerLink]="['/dashboard']">Go to Dashboard</button>
          <button mat-flat-button color="primary" [disabled]="!valid" (click)="submit()">SUBMIT</button>
        </div>
      </div>

    </div>

  </div>

</div>
