<ifc-app-scroll-container class="ifc-app-scroll-container-center">
  <div id="main" fxLayout="column" fxLayoutAlign="center center">

    <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="48px" fxFlexFill.lt-lg ngClass.lt-lg="fit-content">

      <div class="layout padding-horizontal align-center">
        <ng-container *ngIf="!data.loan.description">
          <span class="title">How much would you like to pay today?</span>
        </ng-container>
        <ng-container *ngIf="!!data.loan.description">
          <div class="title">{{data.loan.description}}</div>
          <div class="secondary-text align-center" >Select or enter an amount to pay below.</div>
        </ng-container>
      </div>

      <div fxLayout.gt-md="row" fxLayoutAlign.gt-md="start start" fxLayoutGap.gt-md="48px" fxLayout="column"
        fxLayoutAlign="start center">

        <form [formGroup]="form">

          <div fxLayout="column" fxLayoutAlign="start start" fxLayoutAlign.lt-md="start center" fxLayoutGap="24px">
            <div [ngClass.xl]="'form-field-lg'" [ngClass.gt-xs]="'form-field-md'" [ngClass.xs]="'form-field-xs'">
              <button mat-stroked-button disabled class="disabled">
                <span class="color-primary">{{data.accountTransfer?.weeklyPaymentAmount | currency:'$'}}</span>
              </button>
              <div class="mat-caption sub-title">{{paymentFrequency}} amount (debited from your bank account {{paymentFrequency | lowercase}})</div>
            </div>
            <div [ngClass.xl]="'form-field-lg'" [ngClass.gt-xs]="'form-field-md'" [ngClass.xs]="'form-field-xs'"
              *ngIf="loan?.productCode === product.LOC || loan?.productCode === product.Law">
              <mat-form-field appearance="outline" class="padding-bottom-0">
                <mat-label>Other amount</mat-label>
                <input matInput autocomplete="off" placeholder="Other amount" currencyMask
                  formControlName="paymentAmount">
              </mat-form-field>
              <div class="mat-caption sub-title">
                Enter an amount between {{minimumAmount | currency:'$'}} and
                {{maximumAmount | currency:'$'}}
              </div>
            </div>
            <div [ngClass.xl]="'form-field-lg'" [ngClass.gt-xs]="'form-field-md'" [ngClass.xs]="'form-field-xs'">
              <button mat-stroked-button type="button"
                [ngClass]="{'selected': form.controls.paymentAmount.value === maximumAmount}"
                (click)="setAmount(maximumAmount)">
                <span class="color-primary">{{maximumAmount | currency:'$'}}</span>
              </button>
              <div class="mat-caption sub-title">Payoff amount</div>
            </div>
          </div>

          <ifp-account-transfer-promo-code></ifp-account-transfer-promo-code>

          <footer class="flex row end-center">
            <button mat-button type="button" class="layout margin margin-right-0" (click)="cancel()">Cancel</button>
            <button mat-raised-button type="button" color="primary" class="layout margin margin-right-0"
              (click)="save()" [disabled]="form.invalid">Continue</button>
          </footer>

        </form>

        <ifp-account-transfer-details></ifp-account-transfer-details>

      </div>
    </div>
  </div>
</ifc-app-scroll-container>
