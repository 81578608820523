<mat-dialog-content>
  <header>
    <h2 class="heading">{{data.title}}</h2>
  </header>
  <section>
    <div class="body" [innerHTML]="data.body | safePureHtml"></div>
  </section>
  <footer class="actions">
    <button *ngFor="let button of data.buttons"
            mat-button
            [mat-dialog-close]="button.action"
            [color]="button.buttonColor">{{button.label}}</button>
  </footer>
</mat-dialog-content>
