<div id="agreement-wrapper">
  <div>
    <div>
      Review {{agreementDto?.showHeader ? 'and accept ' : ''}} your {{agreementDto?.name}}
    </div>

    <div class="title-buttons">
      <button *ngIf="!isInWebView"
              mat-icon-button
              (click)="print()">
        <mat-icon>print</mat-icon>
      </button>
      <button mat-icon-button
              (click)="download()">
        <mat-icon>file_download</mat-icon>
      </button>
    </div>
  </div>

  <section>
    <section class="content-terms"
             *ngIf="agreementDto?.showHeader">
      <div>{{data.application.entityName}}</div>
      <div fxLayout.xs="column"
           fxLayoutGap.xs="12px">
        <div>
          <div>{{currentOffer.amount | currency}}</div>
          <div>{{currentOffer?.productCode === productCodes.Term ? 'Loan amount' : 'Credit limit'}}</div>
        </div>
        <div>
          <div>{{currentOffer.repaymentTerm}} months</div>
          <div>Term</div>
        </div>
        <div>
          <div>{{offerPaymentFrequencyLabel}}</div>
          <div>Payment frequency</div>
        </div>
      </div>
    </section>

    <section class="flex column center-center layout margin-top">
      <button mat-raised-button
              color="primary"
              (click)="review()"
              class="layout margin-bottom"
              *ngIf="hasDisclosures">Review {{categoryName}}</button>
    </section>

    <section *ngIf="agreementDto?.showHeader && currentOffer?.productCode === productCodes.Term"
             class="content-term-loan-addon">
      <div>
        <span *ngIf="currentOffer.originationFeeType === FeeType.Percentage">Origination fee ({{currentOffer.originationFee / 100 | percent:'1.2-2'}})</span>
        <span *ngIf="currentOffer.originationFeeType !== FeeType.Percentage">Origination fee
          ({{currentOffer.originationFee | currency:'USD':'symbol':'1.2-2'}})</span>
        <span>{{termLoanOfferDetails.originationFeeAmount | currency:'USD':'symbol':'1.2-2'}}</span>
      </div>
      <div>
        <span>Factor rate</span>
        <span>{{termLoanOfferDetails.factorRate | number:'1.4-4'}}</span>
      </div>
      <div>
        <span>Total repayment amount</span>
        <span>{{termLoanOfferDetails.totalRepaymentAmount | currency:'USD':'symbol':'1.2-2'}}</span>
      </div>
      <div>
        <span>{{loanOfferPaymentFrequencyLabel}} payment</span>
        <span>{{termLoanOfferDetails.paymentAmount | currency:'USD':'symbol':'1.2-2'}}</span>
      </div>
    </section>

    <div id="account-setup-loc-agreement"
         class="layout"
         [innerHtml]="agreementHtml"></div>

    <section class="flex column center-center layout margin-top">
      <button mat-raised-button
              color="primary"
              (click)="review()"
              class="layout margin-bottom"
              *ngIf="!hasDisclosures">{{agreementDto?.showHeader ? 'Accept ' + categoryName : 'I Confirm'}}</button>
    </section>

  </section>
</div>
