import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  AppPageComponent,
  AppBarComponent,
  AppBarTitleComponent,
  AppFooterComponent,
  AppBarContactComponent,
  AppBarCloseAction
} from 'common';
import { OAuthGuard } from '../../guards/oauth.guard';
import { InsuranceDeclinedComponent } from './declined/insurance-declined.component';
import { InsuranceErrorComponent } from './error/insurance-error.component';
import { QuoteResolver } from './insurance.resolver';
import { InsuranceQuoteMessageComponent } from './quote/insurance-quote-message.component';
import { InsuranceQuoteReminderComponent } from './quote/insurance-quote-reminder.component';
import { InsuranceReminderComponent } from './reminder/insurance-reminder.component';

export const insurancePhone: string = '(855) 847-2414';

const routes: Routes = [
  {
    path: 'insurance-reminder',
    canActivate: [OAuthGuard],
    component: AppPageComponent,
    data: {
      appPage: {
        contentClass: 'simple',
        contentAlignment: 'center-start',
        contentWithoutPadding: true
      },
      appBar: {
        closeAction: AppBarCloseAction.HOME,
        logoHidden: true,
        stickyDisabled: true,
        phoneNumber: insurancePhone
      }
    },
    children: [
      {
        path: '',
        outlet: 'app-bar',
        component: AppBarComponent,
        children: [
          {
            path: '',
            outlet: 'title',
            component: AppBarTitleComponent,
          },
          {
            path: '',
            outlet: 'right',
            component: AppBarContactComponent
          }
        ]
      },
      {
        path: '',
        outlet: 'app-footer',
        component: AppFooterComponent,
        data: {
          appFooter: {
            full: false,
            hideText: true,
            showEncryptionInfo: true
          }
        }
      },
      {
        path: '',
        component: InsuranceReminderComponent
      }
    ]
  },
  {
    path: 'insurance-quote',
    canActivate: [OAuthGuard],
    component: AppPageComponent,
    data: {
      appPage: {
        contentClass: 'simple',
        contentAlignment: 'center-start',
        contentWithoutPadding: true
      },
      appBar: {
        closeAction: AppBarCloseAction.HOME,
        logoHidden: true,
        stickyDisabled: true,
        phoneNumber: insurancePhone
      }
    },
    children: [
      {
        path: '',
        outlet: 'app-bar',
        component: AppBarComponent,
        children: [
          {
            path: '',
            outlet: 'title',
            component: AppBarTitleComponent,
          },
          {
            path: '',
            outlet: 'right',
            component: AppBarContactComponent
          }
        ]
      },
      {
        path: '',
        outlet: 'app-footer',
        component: AppFooterComponent,
        data: {
          appFooter: {
            full: false,
            hideText: true,
            showEncryptionInfo: true
          }
        }
      },
      {
        path: '',
        component: InsuranceQuoteMessageComponent,
        resolve: {
          quote: QuoteResolver
        }
      },
      {
        path: 'reminder',
        component: InsuranceQuoteReminderComponent,
        resolve: {
          quote: QuoteResolver
        }
      }
    ]
  },
  {
    path: 'insurance-error',
    canActivate: [OAuthGuard],
    component: AppPageComponent,
    data: {
      appPage: {
        contentClass: 'simple',
        contentAlignment: 'center-start',
        contentWithoutPadding: true
      },
      appBar: {
        closeAction: AppBarCloseAction.HOME,
        logoHidden: true,
        stickyDisabled: true,
        phoneNumber: insurancePhone
      }
    },
    children: [
      {
        path: '',
        outlet: 'app-bar',
        component: AppBarComponent,
        children: [
          {
            path: '',
            outlet: 'title',
            component: AppBarTitleComponent,
          },
          {
            path: '',
            outlet: 'right',
            component: AppBarContactComponent
          }
        ]
      },
      {
        path: '',
        outlet: 'app-footer',
        component: AppFooterComponent,
        data: {
          appFooter: {
            full: false,
            hideText: true,
            showEncryptionInfo: true
          }
        }
      },
      {
        path: '',
        component: InsuranceErrorComponent
      }
    ]
  },
  {
    path: 'insurance-declined',
    canActivate: [OAuthGuard],
    component: AppPageComponent,
    data: {
      appPage: {
        contentClass: 'simple',
        contentAlignment: 'center-start',
        contentWithoutPadding: true
      },
      appBar: {
        closeAction: AppBarCloseAction.HOME,
        logoHidden: true,
        stickyDisabled: true,
        phoneNumber: insurancePhone
      }
    },
    children: [
      {
        path: '',
        outlet: 'app-bar',
        component: AppBarComponent,
        children: [
          {
            path: '',
            outlet: 'title',
            component: AppBarTitleComponent,
          },
          {
            path: '',
            outlet: 'right',
            component: AppBarContactComponent
          }
        ]
      },
      {
        path: '',
        outlet: 'app-footer',
        component: AppFooterComponent,
        data: {
          appFooter: {
            full: false,
            hideText: true,
            showEncryptionInfo: true
          }
        }
      },
      {
        path: '',
        component: InsuranceDeclinedComponent
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class InsuranceQuoteRoutingModule { }
