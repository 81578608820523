import { Injectable } from "@angular/core";
import { AccountTransactionService } from "./account-transaction.service";
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";
import { AccountTransaction, AccountTransactionQueryResult, RouteHelper, parseNumber } from "common";

@Injectable({
  providedIn: "root",
})
export class AccountTransactionsResolver
  implements Resolve<AccountTransactionQueryResult>
{
  constructor(private transactionService: AccountTransactionService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<AccountTransactionQueryResult> {
    const pageSize = this.transactionService.pageSize;
    const accountId = parseNumber(RouteHelper.getParamValueByName(route, "accountId"));
    return this.transactionService.getTransactions(
      accountId,
      0,
      pageSize,
      "-date"
    );
  }
}

@Injectable({
  providedIn: "root",
})
export class TransactionResolver implements Resolve<AccountTransaction> {
  constructor(private service: AccountTransactionService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<AccountTransaction>
    | Promise<AccountTransaction>
    | AccountTransaction {
    const transactionId = parseNumber(RouteHelper.getParamValueByName(
      route,
      "transactionId"
    ));
    return this.service.get(transactionId);
  }
}
