import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { Observable, combineLatest } from "rxjs";
import { Injectable } from "@angular/core";
import { map, switchMap, take } from "rxjs/operators";
import { RouteHelper, parseNumber } from "common";
import { LoanFacade } from "../loan/domain/+state/loan.facade";
import { CustomerFacade } from "../customer/domain/+state/customer.facade";
import { EntityFacade } from "../entity/domain/+state/entity.facade";

@Injectable({
  providedIn: "root",
})
export class LocAgreementSignedGuard implements CanActivate {
  constructor(
    private loanFacade: LoanFacade,
    private customerFacade: CustomerFacade,
    private entityFacade: EntityFacade,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    if (JSON.parse(sessionStorage.getItem("accountSetupViewed"))) {
      return true;
    }
    const companyId = parseNumber(RouteHelper.getQueryParamValueByName(route, "companyId"));
    return combineLatest([
      this.customerFacade.getCurrentCustomer$(),
      this.entityFacade.getEntity$(companyId),
    ]).pipe(
      take(1),
      map(([customer, entity]) => ({
        entityId: entity.id,
        customerId: customer.id,
        hasPendingLoans: customer?.pendingLoans?.length ? true : false,
      })),
      switchMap((data) =>
        this.loanFacade.getAgreementReadyLoans(data.entityId, data.customerId)
      ),
      map((pendingLoans: any) => {
        if (pendingLoans?.length > 0) {
          this.router.navigate([
            `account/${pendingLoans[0].id}/setup/sign-agreement`,
          ]);
          return false;
        }
        return true;
      })
    );
  }
}
