import { NgModule } from '@angular/core';
import { AboutModule, CommonModule } from 'common';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../shared/shared.module';
import { AboutPageRoutingModule } from './about-page-routing.module';

@NgModule({
    imports: [
        SharedModule,
        CommonModule,
        RouterModule,
        AboutModule,
        AboutPageRoutingModule
    ]
})
export class AboutPageModule { }