import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { FeeType, PromotionType, TransactionType } from "common";
import { AppSettings } from "../../app.settings";
@Injectable({
  providedIn: "root",
})
export class PromotionService {
  private readonly baseUrl = `${this.settings.promotions.url}/api/promotions`;

  onPromotionApplied: BehaviorSubject<any>;

  constructor(
    private http: HttpClient,
    private settings: AppSettings,
  ) {
    this.onPromotionApplied = new BehaviorSubject(null);
  }

  getByPromoCode(promoCode: string, loanId: number, transactionType: TransactionType): Observable<PromotionInfoData> {
    return this.http.get<PromotionInfoData>(`${this.baseUrl}/get-by-promo-code`, { params: { promoCode: promoCode, loanId: loanId, transactionType: transactionType } });
  }
}

export interface PromotionInfoData {
    promotionType: PromotionType;
    reduceValue?: number;
    reduceType?: FeeType;
    promoCode?: string;
    payoffAmount?: number;
}
