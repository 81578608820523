import { Component, OnInit, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BreakpointObserver } from "@angular/cdk/layout";
import { PageEvent } from "@angular/material/paginator";
import { takeUntil } from "rxjs/operators";
import {
  DialogConfirm,
  LoanDocumentService,
  MessageService,
  FilterParameter,
} from "common";
import { LoanDocumentFilterService } from "../document-filter.service";
import {
  DocumentListBaseComponent,
  DocumentListComponentData,
} from "./document-list-base.component";
import { LoanData } from "../../loan/domain/models/loan.model";
import { LoanFacade } from "../../loan/domain/+state/loan.facade";

@Component({
  selector: "ifp-account-document-list",
  templateUrl: "./document-list.component.html",
  styleUrls: ["./document-list.component.scss"],
})
export class AccountDocumentListComponent
  extends DocumentListBaseComponent<LoanDocumentService>
  implements OnInit, OnDestroy
{
  private loanId: number;

  constructor(
    private router: Router,
    private loanFacade: LoanFacade,
    route: ActivatedRoute,
    _documentService: LoanDocumentService,
    _documentFilterService: LoanDocumentFilterService,
    dialog: MatDialog,
    breakpointObserver: BreakpointObserver,
    messageService: MessageService
  ) {
    super(
      route,
      _documentService,
      _documentFilterService,
      dialog,
      breakpointObserver,
      messageService
    );
  }

  get pageSize() {
    return this._documentService.pageSize;
  }
  get queryParams() {
    return this._documentService.queryParams;
  }

  ngOnInit() {
    super.ngOnInit();
    this.route.data
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data: AccountDocumentListComponentData) => {
        this.data = data;
        this.loanId = data?.loan?.id;
      });
  }

  ngOnDestroy() {
    this._documentService.queryParams = {};
  }

  activeFilterRemoved(activefilters: FilterParameter[]): void {
    if (!activefilters.length) return;
    activefilters.forEach((item) => {
      this.filters.remove(item.id);
      this.filter();
    });
  }

  setPage(event: PageEvent) {
    this._documentService.pageSize = event.pageSize;
    this.filter();
  }

  filter(): void {
    const query = this._documentFilterService.filter(
      this.filters,
      this.paginator,
      this.sort
    );
    this._documentService.query(this.loanId, query).subscribe((result) => {
      this.data.documentQueryResult = result;
      this.activeFilters = this._documentFilterService.addFilterParameters(
        this.queryParams
      );
    });
  }

  download(id: number, loanId: number): void {
    this._documentService.download(id, loanId);
  }

  get canUpload(): boolean {
    return false;
  }

  upload(): void {
    this.router.navigate([`account/${this.loanId}/documents/upload`]);
  }

  delete(id: number, filename: string) {
    DialogConfirm.show(this.dialog, `Delete file ${filename}?`).subscribe(
      (result) => {
        if (result) {
          this.deleteSub = this._documentService
            .delete(id, this.loanId)
            .subscribe({
              next: this.deleteSuccessHandler.bind(this),
              error: this.deleteErrorHandler.bind(this),
            });
        }
      }
    );
  }

  deleteSuccessHandler() {
    this.deleteSubClear();
    this.loanFacade.refreshLoan(this.loanId).subscribe();
  }
}

export interface AccountDocumentListComponentData extends DocumentListComponentData {
  loan: LoanData;
}
