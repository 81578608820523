export class ObjectHelper {

    static isEmpty(value: any, testWhiteSpace: boolean): boolean {
        if (value === undefined || value === null)
            return true;
        if (value.length === 0)
            return true;
        if (testWhiteSpace && typeof value === "string" && value.trim().length === 0)
            return true;

        return false;
    }

    static removeEmptyProperties(target: any) {
        Object.keys(target).forEach(key => {
            if (key in target && target[key] == null || target[key] === undefined)
                delete target[key];
        });
    }

    static getQueryParams(target: object): string {
        return ObjectHelper.to_qs(target);
    }

    private static format = (k,v) => v !== null ? (v instanceof Date ? `${k}=${v.toISOString()}` : `${k}=${encodeURIComponent(v)}`) : ''

    private static to_qs = (obj) => {
        return [].concat(...Object.entries(obj)
                       .map(([k,v]) => Array.isArray(v)
                          ? v.map(arr => ObjectHelper.to_qs({[k]:arr}))
                          : ObjectHelper.format(k,v)))
           .filter(x => !!x)
           .join('&');
    }

    static getListFromArray(arr: any[], field: string, separator: string): string {
        let response = '';
        let i = 0;
        arr.forEach(item => {
            response = i > 0 ? `${response}${separator} ${item[field]}` : item[field];
            i++;
        });
        return response;
    }

    static makeArray(value: any): any[] {
        return value === null || typeof value === 'undefined' || Array.isArray(value) ? value : [value];
    }
}
