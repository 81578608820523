import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'shortNumber'
})
export class ShortNumberPipe implements PipeTransform {

    transform(number: number, args?: any): any {
        const nullResult = args === '0' ? 0 : null;
        if (isNaN(number)) return nullResult;
        if (number === null) return nullResult;
        if (number === 0) return nullResult;
        let abs = Math.abs(number);
        const isNegative = number < 0;
        let key = '';

        const powers = [
            { key: 'B', value: Math.pow(10, 9) },
            { key: 'M', value: Math.pow(10, 6) },
            { key: 'K', value: 1000 }
        ];

        for (let i = 0; i < powers.length; i++) {
            let reduced = abs / powers[i].value;
            reduced = Math.trunc(reduced);
            if (reduced >= 1) {
                abs = reduced;
                key = powers[i].key;
                break;
            }
        }
        return (isNegative ? '-' : '') + abs + key;
    }
}
