import { Injectable } from "@angular/core";
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable, of } from "rxjs";
import { BankAccountService } from "./bank-account.service";
import { FinancialInstitution } from "./bank-account.model";
import { switchMap, take } from "rxjs/operators";
import { EntityFacade } from "../entity/domain/+state/entity.facade";
import { RouteHelper, parseNumber } from "common";

@Injectable({
  providedIn: "root",
})
export class FinancialInstitutionsListResolver implements Resolve<any> {
  constructor(
    private bankAccountService: BankAccountService,
    private entityFacade: EntityFacade
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    const companyId = parseNumber(RouteHelper.getQueryParamValueByName(route, "companyId"));
    return this.entityFacade.getEntity$(companyId).pipe(
      take(1),
      switchMap((entity) => {
        return entity ? this.bankAccountService.getBanks(entity.id) : of(null);
      })
    );
  }
}

@Injectable({
  providedIn: "root",
})
export class BankLinkRequiredFinancialInstitutionsResolver
  implements Resolve<FinancialInstitution[]>
{
  constructor(
    private bankAccountService: BankAccountService,
    private entityFacade: EntityFacade
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<FinancialInstitution[]> {
    const companyId = parseNumber(RouteHelper.getQueryParamValueByName(route, "companyId"));
    return this.entityFacade.getEntity$(companyId).pipe(
      take(1),
      switchMap((entity) => {
        return this.bankAccountService.getBankLinkRequiredBanks(entity.id);
      })
    );
  }
}

@Injectable({
  providedIn: "root",
})
export class BankActionRequiredFinancialInstitutionsResolver
  implements Resolve<FinancialInstitution[]>
{
  constructor(
    private bankAccountService: BankAccountService,
    private entityFacade: EntityFacade
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<FinancialInstitution[]>
    | Promise<FinancialInstitution[]>
    | FinancialInstitution[] {
    const companyId = parseNumber(RouteHelper.getQueryParamValueByName(route, "companyId"));
    return this.entityFacade.getEntity$(companyId).pipe(
      take(1),
      switchMap((entity) => {
        return this.bankAccountService.getBankActionRequiredBanks(entity.id);
      })
    );
  }
}

@Injectable({
  providedIn: "root",
})
export class BankAccountsForLinkReminderResolver
  implements Resolve<FinancialInstitution[]>
{
  constructor(
    private bankAccountService: BankAccountService,
    private entityFacade: EntityFacade
  ) {}

  // eslint-disable-next-line max-len
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<FinancialInstitution[]>
    | Promise<FinancialInstitution[]>
    | FinancialInstitution[] {
    const companyId = parseNumber(RouteHelper.getQueryParamValueByName(route, "companyId"));
    return this.entityFacade.getEntity$(companyId).pipe(
      take(1),
      switchMap((entity) => {
        return this.bankAccountService.getBankAccountsForLinkReminder(
          entity.id
        );
      })
    );
  }
}
