import { NgModule } from "@angular/core";
import { CoreModule as CoreCommonModule } from "common";
import { NavigationService } from "./navigation/navigation.service";

@NgModule({
  imports: [CoreCommonModule],
  providers: [NavigationService],
})
export class CoreModule {
  constructor(navigationService: NavigationService) {
    navigationService.handleQueryParamDuringEachNavigation();
  }
}
