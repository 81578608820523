<div class="ifa-calc-wrapper"
     fxLayout="column">

  <ng-container [ngSwitch]="productCode">
    <ng-container *ngSwitchCase="ProductCode.LOC">
      <ng-container *ngTemplateOutlet="loc"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="ProductCode.Term">
      <ng-container *ngTemplateOutlet="term"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="ProductCode.Law">
      <ng-container *ngTemplateOutlet="law"></ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #loc>
  <ifc-offer-product-navigation *ngIf="offerBundlesSummary?.bestTermOfferBundle || offerBundlesSummary?.bestLawOfferBundle"
                                [offerBundlesSummary]="offerBundlesSummary"
                                (changeOffer)="onChangeOffer($event)">
  </ifc-offer-product-navigation>

  <ifc-offer-repayment-navigation *ngIf="offerBundlesSummary?.locOfferBundles?.length > 1"
                                  [offerBundles]="offerBundlesSummary?.locOfferBundles"
                                  [currentOfferBundle]="offerBundlesSummary?.currentOfferBundle"
                                  (changeOffer)="onChangeOffer($event)">
  </ifc-offer-repayment-navigation>

  <ng-container *ngTemplateOutlet="offerDetailsHeading"></ng-container>

  <ifc-offer-loc-details-content [calculator]="calculator">
  </ifc-offer-loc-details-content>
</ng-template>

<ng-template #term>
  <ifc-offer-product-navigation *ngIf="offerBundlesSummary?.bestLocOfferBundle || offerBundlesSummary?.bestLawOfferBundle"
                                [offerBundlesSummary]="offerBundlesSummary"
                                (changeOffer)="onChangeOffer($event)">
  </ifc-offer-product-navigation>

  <ifc-offer-repayment-navigation *ngIf="offerBundlesSummary?.termOfferBundles?.length > 1"
                                  [offerBundles]="offerBundlesSummary?.termOfferBundles"
                                  [currentOfferBundle]="offerBundlesSummary?.currentOfferBundle"
                                  (changeOffer)="onChangeOffer($event)">
  </ifc-offer-repayment-navigation>

  <ng-container *ngTemplateOutlet="offerDetailsHeading"></ng-container>

  <ifc-offer-term-details-content [calculator]="calculator">
  </ifc-offer-term-details-content>
</ng-template>

<ng-template #law>
  <ifc-offer-product-navigation *ngIf="offerBundlesSummary?.bestLocOfferBundle || offerBundlesSummary?.bestTermOfferBundle"
                                [offerBundlesSummary]="offerBundlesSummary"
                                (changeOffer)="onChangeOffer($event)">
  </ifc-offer-product-navigation>

  <ifc-offer-repayment-navigation *ngIf="offerBundlesSummary?.lawOfferBundles?.length > 1"
                                  [offerBundles]="offerBundlesSummary?.lawOfferBundles"
                                  [currentOfferBundle]="offerBundlesSummary?.currentOfferBundle"
                                  (changeOffer)="onChangeOffer($event)">
  </ifc-offer-repayment-navigation>

  <ng-container *ngTemplateOutlet="offerDetailsHeading"></ng-container>

  <ifc-offer-law-details-content [calculator]="calculator">
  </ifc-offer-law-details-content>
</ng-template>


<ng-template #offerDetailsHeading>
  <ifc-offer-details-heading [businessName]="currentOfferBundle?.businessName"
                             [companyData]="companyData"
                             [canSelectOffer]="canSelectOffer"
                             [isAlreadySelected]="currentOfferBundle?.status === OfferBundleStatus.Accepted"
                             [advisorName]="application.advisorName"
                             [advisorPhone]="application.advisorPhone"
                             (viewAllOffers)="onViewAllOffers()">
  </ifc-offer-details-heading>

  <ng-container *ngIf="selectOfferRef">
    <ng-container *ngTemplateOutlet="selectOfferRef"></ng-container>
  </ng-container>
</ng-template>
