<div class="filter-toolbar">
  <div fxLayout="row"
       fxLayoutAlign="space-between center"
       class="table-top">
    <ifc-filters-active-list class="layout margin-sm"
                             [filters]="activeFilters"
                             (filterRemoved)="activeFilterRemoved($event)"></ifc-filters-active-list>
    <div class="layout padding-right-md">
      <button *ngIf="canUpload"
              mat-icon-button
              class="secondary-text"
              matTooltip="Upload files"
              (click)="upload()">
        <mat-icon>note_add</mat-icon>
      </button>
      <button mat-icon-button
              (click)="filterDialog()"
              class="secondary-text"
              matTooltip="Filter">
        <mat-icon>filter_list</mat-icon>
      </button>
    </div>
  </div>
</div>

<ifc-app-scroll-container [layout2ListFiltered]="true">

  <div class="message-box info"
       *ngIf="!data?.documentQueryResult?.totalCount"
       fxLayout="row"
       fxLayoutGap="12px"
       fxLayoutAlign="start center">
    <mat-icon class="icon">info</mat-icon>
    <span>No data found</span>
  </div>

  <table mat-table
         matSort
         *ngIf="data?.documentQueryResult?.totalCount"
         [dataSource]="data.documentQueryResult.values"
         (matSortChange)="sortData($event)"
         aria-describedby="document list">
    <ng-container matColumnDef="createdOn">
      <th nowrap
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header="createdOn"> Date </th>
      <td mat-cell
          *matCellDef="let document"
          class="table-cell">
        <span>{{document.uploadDate | date:"mediumDate"}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="filename">
      <th nowrap
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header="filename"> Name </th>
      <td mat-cell
          *matCellDef="let document"
          class="table-cell">
        <span>{{document.filename}} ({{document.length | filesize}})</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="category">
      <th nowrap
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header="category"> Type </th>
      <td mat-cell
          *matCellDef="let document"
          class="table-cell">
        {{getCategory(document.category)}} </td>
    </ng-container>

    <ng-container matColumnDef="actions"
                  stickyEnd>
      <th mat-header-cell
          *matHeaderCellDef
          mat-sort-header="actions"
          style="width: 40px"></th>
      <td mat-cell
          *matCellDef="let row"
          class="table-cell"
          ngClass.gt-md="action-icon actions-menu"
          ngClass.lt-lg="actions-menu-xs">
        <div fxLayout="row"
             fxLayoutAlign="center start">
          <button mat-icon-button
                  class="action"
                  (click)="download(row.id)">
            <mat-icon>get_app</mat-icon>
          </button>
          <button *ngIf="row.canDelete"
                  mat-icon-button
                  class="action"
                  (click)="delete(row.id, row.filename)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: true"
        class="table-header"></tr>
    <tr mat-row
        *matRowDef="let row; columns: displayedColumns;"
        class="table-row"></tr>
  </table>

</ifc-app-scroll-container>

<mat-paginator #paginator
               *ngIf="data?.documentQueryResult?.totalCount"
               [hidePageSize]="hidePageSize"
               [pageSizeOptions]="[10, 20, 50]"
               [pageSize]="pageSize"
               (page)="setPage($event)"
               [length]="data.documentQueryResult.totalCount"
               showFirstLastButtons></mat-paginator>
