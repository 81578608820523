/* eslint-disable @angular-eslint/component-class-suffix */
/* eslint-disable @angular-eslint/component-selector */
import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";

@Component({
    selector: 'dialog-html',
    templateUrl: './dialog-html.component.html'
})
export class DialogHtml {
    constructor(public dialogRef: MatDialogRef<DialogHtml>,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    static show(dialog: MatDialog, message: string): Observable<boolean> {
        return dialog.open(DialogHtml, {
            data: { message },
            closeOnNavigation: true,
            autoFocus: false
        }).afterClosed();
    }
}