import { LiquidityEvent, StateService } from "common";
import { LiquidityEventState } from "../models/liquidity-event-state.model";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";

const initialState: LiquidityEventState = null;

@Injectable({
    providedIn: "root",
})
export class LiquidityEventStateService extends StateService<LiquidityEventState> {
    constructor() {
      super(initialState);
    }

    getLoanId$(): Observable<number> {
      return this.select((state: LiquidityEventState) => state?.loanId);
    }

    setLoanId(loanId: number): void {
      const newState = {
        ...this.state,
        loanId: loanId,
      };
      this.setState(newState);
    }

    getLiquidityEvent$(): Observable<LiquidityEvent> {
      return this.select((state: LiquidityEventState) => state?.liquidityEvent);
    }

    setLiquidityEvent(liquidityEvent: LiquidityEvent): void {
      const newState = {
        ...this.state,
        liquidityEvent: liquidityEvent,
      };
      this.setState(newState);
    }

    isLiquidityEventStateLoaded(): boolean {
      return !!this.state?.liquidityEvent?.litigationId;
    }

    clearState(): void {
      this.initState(initialState);
    }
}