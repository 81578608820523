import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SamlFormComponent } from './saml-form/saml-form.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [SamlFormComponent]
})
export class SamlModule { }
