<div id="app-toolbar" fxLayout="column" fxLayoutAlign="center center">
  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="38px">
    <div *ngFor="let item of toolbarMenuItems">
      <a mat-button [routerLink]="[item.url]" routerLinkActive="active {{brandingData.colorSchema}}">
        <div fxLayout="column" fxLayoutAlign="center center" class="layout padding-top-xs">
          <mat-icon>{{item.icon}}</mat-icon>
          <span class="title">{{item.label}}</span>
        </div>
      </a>
    </div>
  </div>
</div>
