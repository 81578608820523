import { CurrencyPipe, PercentPipe } from "@angular/common";
import { OfferCalculatorService } from "./offer-calculator.service";
import { LoanCard } from "../models/loan-card";
import {
  ApplicationBaseData,
  ApplicationOffer,
  ApplicationOfferBundleData,
  FeeType,
  PaymentFrequency,
  PaymentFrequencyLabel,
} from "../../application/application.model";
import {
  ProductCodeLabel,
  ProductCodeShort,
} from "../../product/product.model";
import { EnumHelper } from "../../helpers/enum.helper";

// TODO[offers] moved from app - refactor to service with functions
export abstract class OfferCalculator {
  private _amount: number;
  protected _sampleDraw: number;
  sampleDurationIndex = 0;
  brokerInitialDrawCommision = 0;

  constructor(
    public application: ApplicationBaseData,
    public applicationOffer: ApplicationOffer,
    public applicationOfferBundle: ApplicationOfferBundleData,
    private percentPipe: PercentPipe,
    private currencyPipe: CurrencyPipe,
    protected offerCalculatorService: OfferCalculatorService
  ) {
    this.amount = applicationOffer.amount;
    this.initSampleDraw();
    this.sampleDurationIndex = this.cards.length - 1;
  }

  get productName(): string {
    return ProductCodeLabel.get(this.applicationOffer.productCode);
  }

  get productShortName(): string {
    return ProductCodeShort.get(this.applicationOffer.productCode);
  }

  get express(): boolean {
    return this.applicationOfferBundle.expressCheckout;
  }
  get hasDisclosures(): boolean {
    return this.applicationOffer.hasDisclosures;
}

  get rate(): number {
    return this.applicationOffer.interestRate;
  }

  get annualRate(): number {
    return this.getAnnualRate();
  }

  protected abstract getAnnualRate(): number;

  get monthlyRate(): number {
    return this.getMonthlyRate();
  }

  protected abstract getMonthlyRate(): number;

  get term(): number {
    return this.applicationOffer.repaymentTerm;
  }

  get fee(): number {
    return this.applicationOffer.drawDownFee;
  }

  get feeType(): FeeType {
    return this.applicationOffer.drawDownFeeType;
  }

  get amount() {
    return this._amount;
  }

  set amount(value: number) {
    this._amount = value;
    if (this._sampleDraw > this._amount) {
      this._sampleDraw = this._amount;
    }
  }

  get sampleDraw(): number {
    return this._sampleDraw;
  }

  set sampleDraw(value: number) {
    if (value < 5000) this._sampleDraw = 5000;
    else if (value > this.amount) this._sampleDraw = this.amount;
    else this._sampleDraw = value;
  }

  protected abstract initSampleDraw(): void;

  get drawFeeFormatted(): string {
    return this.formatFee(this.fee, this.feeType);
  }

  protected formatFee(fee?: number, feeType?: FeeType): string {
    return feeType === FeeType.Percentage
      ? this.percentPipe.transform(fee / 100, "1.2-2")
      : this.currencyPipe.transform(fee, "USD", "symbol", "1.2-2");
  }

  get drawFee(): number {
    return this.offerCalculatorService.calculateFeeAmount(
      this.sampleDraw,
      this.fee,
      this.feeType
    );
  }

  get disbursedAmount(): number {
    return this.sampleDraw - this.drawFee;
  }

  get originationFee(): number {
    return this.applicationOffer.originationFee;
  }

  get originationFeeType(): FeeType {
    return this.applicationOffer.originationFeeType;
  }

  get originationFeeAmount(): number {
    return this.offerCalculatorService.calculateFeeAmount(
      this.amount,
      this.originationFee,
      this.originationFeeType
    );
  }

  get uccFilingFee(): number {
    return this.applicationOffer.uccFilingFee;
  }

  get uccFilingFeeType(): FeeType {
    return this.applicationOffer.uccFilingFeeType;
  }

  get uccFilingFeeAmount(): number {
    return this.offerCalculatorService.calculateFeeAmount(
      this.amount,
      this.uccFilingFee,
      this.uccFilingFeeType
    );
  }

  get maintenanceFee(): number {
    return this.applicationOffer.maintenanceFee;
  }

  get maintenanceFeeType(): FeeType {
    return this.applicationOffer.maintenanceFeeType;
  }

  get maintenanceFeeAmount(): number {
    return this.offerCalculatorService.calculateFeeAmount(
      this.amount,
      this.maintenanceFee,
      this.maintenanceFeeType
    );
  }

  get maintenanceFeeFrequency(): PaymentFrequency {
    return this.applicationOffer.maintenanceFeeFrequency;
  }

  get dailyInterestRate(): number {
    return this.offerCalculatorService.getDailyInterestRate(this.rate / 100);
  }

  get termPayment(): number {
    return this.getTermPayment();
  }

  protected abstract getTermPayment(): number;

  get paymentFrequency(): PaymentFrequency {
    return this.applicationOffer.paymentFrequency;
  }

  get paymentFrequencyName(): string {
    return EnumHelper.getMappedNameFromValue(
      PaymentFrequency,
      PaymentFrequencyLabel,
      this.paymentFrequency
    );
  }

  get maintenanceFeeFrequencyName(): string {
    return EnumHelper.getMappedNameFromValue(
      PaymentFrequency,
      PaymentFrequencyLabel,
      this.maintenanceFeeFrequency
    );
  }

  get totalInterest(): number {
    return this.getTotalInterest(this.term);
  }

  protected abstract getTotalInterest(term: number): number;

  get totalNumberOfPayments(): number {
    return this.offerCalculatorService.getTotalNumberOfPayments(
      this.paymentFrequency,
      this.term
    );
  }

  get numberOfPaymentsPerAnnum(): number {
    return this.offerCalculatorService.getNumberOfPaymentsPerAnnum(
      this.paymentFrequency
    );
  }

  get totalPayback(): number {
    return this.amount + this.totalInterest;
  }

  get cards(): LoanCard[] {
    let cards = [];
    for (let cardTerm = 1; cardTerm <= this.term; cardTerm++) {
      const interest = this.getTotalInterest(cardTerm);

      cards.push({
        duration: cardTerm,
        interest: interest,
        payback: this.sampleDraw + interest,
        rate: interest / this.sampleDraw / this.term,
      });

      if (cardTerm >= this.term) break;
    }

    return cards;
  }

  get noPaymentTerms(): number {
    return this.applicationOffer.noPaymentTerms;
  }

  get noPaymentTermsValue(): number {
    return this.getTermsValue(this.noPaymentTerms);
  }

  get noPaymentTermsName(): string {
    return this.getTermsName(this.noPaymentTerms);
  }

  get interestOnlyPaymentTerms(): number {
    return this.applicationOffer.interestOnlyPaymentTerms;
  }

  get interestOnlyPaymentTermsValue(): number {
    return this.getTermsValue(this.interestOnlyPaymentTerms);
  }

  get interestOnlyPaymentTermsName(): string {
    return this.getTermsName(this.interestOnlyPaymentTerms);
  }

  private getTermsValue(terms?: number): number {
    if (this.paymentFrequency === PaymentFrequency.BiWeekly) {
      return terms * 2;
    }

    return terms;
  }

  private getTermsName(terms?: number): string {
    if (this.paymentFrequency === PaymentFrequency.Monthly) {
      return this.getTermsValue(terms) < 2 ? "month" : "months";
    }
    return this.getTermsValue(terms) < 2 ? "week" : "weeks";
  }

  get sampleDuration(): LoanCard {
    return this.cards[this.sampleDurationIndex];
  }

  get sampleDurationDisplay(): string {
    if (this.sampleDuration.duration === 1) {
      return "1 month";
    }

    return `${this.sampleDuration.duration} months`;
  }

  get commision(): number {
    return this.getCommision();
  }

  protected abstract getCommision(): number;
}
