import { DashboardOptions } from "@uppy/dashboard";
import { Uppy } from "@uppy/core";
import XHRUpload from "@uppy/xhr-upload";

export const getUppyInstance = (): Uppy => {
  const uppy = new Uppy({
    debug: false,
    autoProceed: false,
    restrictions: {
      maxFileSize: 20971520, // 20MB
      maxNumberOfFiles: 10,
      allowedFileTypes: ["application/pdf"],
    },
  });

  return uppy;
};

export const useUppyXHRUploadPlugin = (
  uppy: Uppy,
  endpoint: string,
  accessToken: string
): void => {
  uppy.use(XHRUpload, {
    endpoint,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const getUppyDashboardOptions = (): DashboardOptions => {
  return {
    id: "Dashboard",
    height: 312,
    plugins: [],
    hideUploadButton: true,
    hideRetryButton: true,
    hidePauseResumeButton: true,
    proudlyDisplayPoweredByUppy: false,
  };
};
