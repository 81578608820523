import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ConfigService } from '../config/config.service';
import { FeatureFlag, FeatureFlagCode } from './feature-flag.data';
import { CacheHeaders } from '../cache-interceptor/cache-headers';
import { BrandingService } from '../../core/branding/branding.service';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {
  private readonly baseUrl = `${this.configService.environment.companies.url}/api`;

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private brandingService: BrandingService
  ) { }

  list(): Observable<FeatureFlag[]> {
    return this.http.get<FeatureFlag[]>(`${this.baseUrl}/featureflags`, {
      headers: this.configService.environment.cache.longLived
    });
  }

  listByTenant(tenantId: string): Observable<FeatureFlag[]> {
    return this.http.get<FeatureFlag[]>(`${this.baseUrl}/tenants/${tenantId}/featureflags`, {
      headers: this.configService.environment.cache.longLived
    });
  }

  update(id: number, newValue: boolean): Observable<FeatureFlag> {
    return this.http.put<FeatureFlag>(`${this.baseUrl}/featureflags/${id}`, newValue, {
      headers: {
        [CacheHeaders.Invalidate]: `${this.baseUrl}/tenants/*`
      }
    });
  }

  updateByTenant(tenantId: number, featureFlags: FeatureFlag[]): Observable<FeatureFlag> {
    return this.http.put<FeatureFlag>(`${this.baseUrl}/tenants/${tenantId}/featureflags`, featureFlags);
  }

  private features(): Observable<FeatureFlag[]> {
    return this.brandingService.getBranding().pipe(switchMap(branding => {
      if (branding.id) {
        return this.listByTenant(branding.id?.toString());
      }
      return this.list();
    }))
  }

  activated(requiredFeatureCode: FeatureFlagCode): Observable<boolean> {
    return this.features().pipe(
      map(features => {
        if (!features || !features.length || !requiredFeatureCode)
          return false;
        const feature = features.find(_ => _.code === requiredFeatureCode);
        return feature?.override ? feature?.valueForTenant : feature?.value;
      })
    );
  }
}
