import { Component, OnInit, AfterViewInit, Input } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { MessageService, AppBarTitleService } from "common";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  ConfirmNewPhoneNumberResponse,
  CustomerContactDataDetails,
} from "../../customer/domain/models/customer.model";
import { ContactDataFormComponent } from "../contact-data-form-component";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { CustomerFacade } from "../../customer/domain/+state/customer.facade";

@UntilDestroy()
@Component({
  selector: "ifp-customer-contact-data-form",
  templateUrl: "./customer-contact-data-form.component.html",
  styleUrls: ["./customer-contact-data-form.component.scss"],
})
export class CustomerContactDataFormComponent
  extends ContactDataFormComponent
  implements OnInit
{
  @Input()
  currentCustomerData: CustomerContactDataDetails;

  constructor(
    formBuilder: UntypedFormBuilder,
    route: ActivatedRoute,
    router: Router,
    messageService: MessageService,
    private customerFacade: CustomerFacade,
    snackBar: MatSnackBar,
    appBarTitleService: AppBarTitleService
  ) {
    super(
      formBuilder,
      route,
      router,
      messageService,
      snackBar,
      appBarTitleService
    );
    this.formInit();
  }

  ngOnInit() {
    this.route.data
      .pipe(untilDestroyed(this))
      .subscribe(this.dataInit.bind(this));

    this.appBarTitleService.title = "Profile";
  }

  private dataInit(data: any): void {
    if (!data) return;

    this.currentCustomerData = data.customer;

    this.form.patchValue({
      mobilePhone: this.currentCustomerData?.mobilePhone,
      preferredPhone: this.currentCustomerData?.preferredPhone,
      homePhone: this.currentCustomerData?.homePhone,
      address: {
        line1: this.currentCustomerData?.address?.line1,
        line2: this.currentCustomerData?.address?.line2,
        city: this.currentCustomerData?.address?.city,
        state: this.currentCustomerData?.address?.state,
        zip: this.currentCustomerData?.address?.zip,
      },
    });
  }

  private formInit(): void {
    this.form = this.formBuilder.group({
      mobilePhone: ["", this.phoneValidator],
      preferredPhone: ["", this.phoneValidator],
      homePhone: ["", this.phoneValidator],
      address: this.getAddressFormGroup(),
    });
  }

  submit() {
    if (!this.form.valid) return;
    const data = {
      address: Object.assign({}, this.form.value.address),
      mobilePhone: this.form.value.mobilePhone,
      preferredPhone: this.form.value.preferredPhone,
      homePhone: this.form.value.homePhone,
    };

    this.submitSub = this.customerFacade
      .updateCustomerContactDetails(this.currentCustomerData?.id, data)
      .subscribe(this.submitSuccess, this.submitError);
  }

  submitSuccess = () => {
    this.submitSubClear();
    this.messageService.success("Your data has been updated");
    this.customerFacade
      .confirmMobilePhoneNumberChange({
        customerId: this.currentCustomerData?.id,
        oldPhoneNumber: this.currentCustomerData.mobilePhone,
      })
      .subscribe((data: ConfirmNewPhoneNumberResponse) => {
        if (!data?.hasError) {
          this.customerFacade.redirectToDashboard();
        }
      });
  };
}
