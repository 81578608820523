import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import {
  AppPageComponent,
  AppBarComponent,
  AppBarCloseAction,
  AppBarTitleComponent,
  AppBarContactComponent,
} from "common";
import { CustomerContactDetailsResolver } from "../customer/infrastructure/customer.resolver";
import { OAuthGuard } from "../../guards/oauth.guard";
import { EntitiesResolver, EntityQueryResolver } from "../entity/infrastructure/entity.resolver";
import { ContactDataDetailsComponent } from "./contact-data-details/contact-data-details.component";
import { PersonalCompanyFormType } from "./model/personal-company-form-type";
import { CompanyContactDataFormGuard } from "./company-contact-data-form/company-contact-data-form.guard";

const routes: Routes = [
  {
    path: "contact-data",
    component: AppPageComponent,
    resolve: {
      entity: EntityQueryResolver,
      entities: EntitiesResolver,
    },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    data: {
      appPage: {
        contentClass: "simple",
      },
      appBar: {
        closeAction: AppBarCloseAction.HOME,
        logoHidden: true,
        stickyDisabled: true,
      },
    },
    canActivate: [OAuthGuard],
    children: [
      {
        path: "",
        outlet: "app-bar",
        component: AppBarComponent,
        children: [
          {
            path: "",
            outlet: "title",
            component: AppBarTitleComponent,
          },
          {
            path: "",
            outlet: "right",
            component: AppBarContactComponent,
          },
        ],
      },
      {
        path: "personal/change",
        component: ContactDataDetailsComponent,
        resolve: {
          customer: CustomerContactDetailsResolver,
        },
        data: {
          personalCompanyFormType: PersonalCompanyFormType.Personal,
        },
      },
      {
        path: "company/change",
        canActivate: [CompanyContactDataFormGuard],
        component: ContactDataDetailsComponent,
        data: {
          personalCompanyFormType: PersonalCompanyFormType.Company,
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ContactDataRoutingModule {}
