
export class TimeHelper {

    static getGreetingTime(currentTime) {
        const splitAfternoon = 12;
        const splitEvening = 17;
        const currentHour = parseFloat(currentTime.format('HH'));
        if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
            return 'Good afternoon';
        } else if (currentHour >= splitEvening) {
            return 'Good evening';
        }
        return 'Good morning';
    }
}
