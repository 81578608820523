import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { LoanWithRenewalData } from '../../../loan/domain/models/loan.model';
import { AppBarTitleService, EnumHelper, AppBarActionsService, AppBarAction, SubtransactionData, TransactionType, TransactionStatus, SubtransactionType, AccountTransaction, SubtransactionTypeConsumerLabel, TransactionStatusLabel } from 'common';
import * as _ from 'lodash';
import { AccountTransactionCancelDialogComponent } from '../account-transaction-cancel-dialog/account-transaction-cancel-dialog.component';
import { AccountTransactionService } from '../account-transaction.service';

@Component({
  selector: 'ifp-account-transaction-detail',
  templateUrl: './account-transaction-detail.component.html',
  styleUrls: ['./account-transaction-detail.component.scss']
})
export class AccountTransactionDetailComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;

  transaction: AccountTransaction;
  subtransactions: SubtransactionData[];
  loan: LoanWithRenewalData;
  form: UntypedFormGroup;

  get TransactionType() { return TransactionType; }

  constructor(
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    private appBarTitleService: AppBarTitleService,
    private appBarActionsService: AppBarActionsService,
    private accountTransactionService: AccountTransactionService
  ) {
    this._unsubscribeAll = new Subject();
    this.formInit();
  }

  ngOnInit() {
    this.route.data
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.dataInit.bind(this));
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  formInit(): void {
    this.form = this.formBuilder.group({
      loanNumber: { value: undefined, disabled: true },
      businessName: { value: undefined, disabled: true },
      status: { value: undefined, disabled: true }
    });
  }

  private dataInit(data: any): void {
    if (!data)
      return;

    this.loan = data.loan;
    this.transaction = data.transaction;
    this.subtransactions = _.filter(data.transaction.subtransactions, { display: true });

    this.form.patchValue({
      loanNumber: this.transaction.loanNumber,
      businessName: this.transaction.businessName,
      status: this.getTransactionStatusLabel(this.transaction.status)
    });

    this.appBarTitleService.title = _.startCase(EnumHelper.getNameFromValue(TransactionType, this.transaction.type));
    this.appBarActionsService.actions = [
      { id: 'cancelTransaction', label: 'Cancel Transaction', buttonType: 'button', disabled: !this.transaction.cancellable }
    ];

    this.appBarActionsService
      .invoking
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.actionDispatch.bind(this));
  }

  get transferFrom(): string {
    if (!this.loan || !this.loan.loanInfo)
      return undefined;

    if (this.transaction.type === TransactionType.Draw || this.transaction.type === TransactionType.Premium)
      return `${this.transaction.loanProductName}: ${this.transaction.loanNumber}`;
    else if (this.transaction.type === TransactionType.BalanceTransfer) {
      if (this.transaction.amount > 0)
        return `${this.loan.renewalForProductName}: ${this.loan.renewalForLoanNumber}`;
      else
        return `${this.transaction.loanProductName}: ${this.transaction.loanNumber}`;
    }
    else
      return this.loan.loanInfo.disbursementBankName && this.loan.loanInfo.disbursementBankAccount
        ? `${this.loan.loanInfo.disbursementBankName} : ${this.loan.loanInfo.disbursementBankAccount}`
        : 'Your bank account on file';
  }

  get transferTo(): string {
    if (!this.loan || !this.loan.loanInfo)
      return undefined;

    if (this.transaction.type === TransactionType.Draw)
      return this.loan.loanInfo.disbursementBankName && this.loan.loanInfo.disbursementBankAccount
        ? `${this.loan.loanInfo.disbursementBankName} : ${this.loan.loanInfo.disbursementBankAccount}`
        : 'Your bank account on file';
    else if (this.transaction.type === TransactionType.BalanceTransfer) {
      if (this.transaction.amount > 0)
        return `${this.loan.productName}: ${this.loan.loanNumber}`;
      else
        return this.loan.renewedBy
          ? `${this.loan.renewedByProductName}: ${this.loan.renewedByLoanNumber}`
          : `${this.loan.renewalForProductName}: ${this.loan.renewalForLoanNumber}`;
    }
    else if (this.transaction.type === TransactionType.Premium)
      return 'External account';
    else
      return `${this.transaction.loanProductName}: ${this.transaction.loanNumber}`;
  }

  getTypeName(type: SubtransactionType): string {
    return SubtransactionTypeConsumerLabel.get(type);
  }

  getTransactionStatusLabel(status?: TransactionStatus) {
    if(status === TransactionStatus.OnHold) {
      status = TransactionStatus.Pending;
    }

    return EnumHelper.getMappedNameFromValue(TransactionStatus, TransactionStatusLabel, status);
  }

  actionDispatch(action: AppBarAction) {
    const actionHandler: (action: AppBarAction) => void = this[action.id].bind(this);
    actionHandler(action);
  }

  cancelTransaction() {
    AccountTransactionCancelDialogComponent
      .show(this.dialog)
      .subscribe(r => {
        if (r)
          this
            .accountTransactionService
            .cancelTransaction(this.transaction.id)
            .subscribe(e => {
              location.reload();
            });
      });
  }
}
