<ifc-app-scroll-container class="ifc-app-scroll-container-center">
  <div id="main" fxLayout="column" fxLayoutAlign="center center">

    <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="48px" fxFlexFill.lt-lg ngClass.lt-lg="fit-content">

      <div class="layout padding-horizontal align-center">
        <ng-container *ngIf="!data.loan.description">
          <span class="title">How much would you like to draw today?</span>
        </ng-container>
        <ng-container *ngIf="!!data.loan.description">
          <div class="title">{{data.loan.description}}</div>
          <div class="secondary-text align-center" >Select or enter an amount to draw below.</div>
        </ng-container>
      </div>

      <div fxLayout.gt-md="row" fxLayoutAlign.gt-md="start start" fxLayoutGap.gt-md="48px" fxLayout="column"
        fxLayoutAlign="start center">

        <form [formGroup]="form">

          <div fxLayout="column" fxLayoutAlign="start start" fxLayoutAlign.lt-md="start center" fxLayoutGap="24px">
            <div [ngClass.xl]="'form-field-lg'" [ngClass.gt-xs]="'form-field-md'" [ngClass.xs]="'form-field-xs'" *ngIf="!isLcf">
              <button mat-stroked-button  type="button"
                [ngClass]="{'selected': form.controls.drawAmount.value === data.accountTransfer.amountMaximum}"
                (click)="setAmount(data.accountTransfer.amountMaximum)">
                <span class="color-primary">{{data.accountTransfer.amountMaximum | currency:'$'}}</span>
              </button>
              <div class="mat-caption sub-title">Maximum amount</div>
            </div>
            <div [ngClass.xl]="'form-field-lg'" [ngClass.gt-xs]="'form-field-md'" [ngClass.xs]="'form-field-xs'" *ngIf="!isLcf">
              <button mat-stroked-button type="button"
                [ngClass]="{'selected': form.controls.drawAmount.value === data.accountTransfer.suggestedDrawAmount}"
                (click)="setAmount(data.accountTransfer.suggestedDrawAmount)">
                <span class="color-primary">{{data.accountTransfer.suggestedDrawAmount | currency:'$'}}</span>
              </button>
              <div class="mat-caption sub-title">Suggested amount</div>
            </div>
            <div [ngClass.xl]="'form-field-lg'" [ngClass.gt-xs]="'form-field-md'" [ngClass.xs]="'form-field-xs'">
              <mat-form-field appearance="outline" id="otherAmount">
                <mat-label>Other amount</mat-label>
                <input matInput autocomplete="off" placeholder="Other amount" currencyMask formControlName="drawAmount">
                <mat-hint class="mat-caption sub-title">Amount between {{data.accountTransfer.amountMinimum | currency:'$'}} and {{data.accountTransfer.amountMaximum | currency:'$'}}</mat-hint>
                <mat-error class="sub-title">
                  Enter an amount between {{data.accountTransfer.amountMinimum | currency:'$'}} and
                  {{data.accountTransfer.amountMaximum | currency:'$'}}
                </mat-error>
              </mat-form-field>
            </div>

            <div [ngClass.xl]="'form-field-lg'" [ngClass.gt-xs]="'form-field-md'" [ngClass.xs]="'form-field-xs'">
              <mat-form-field appearance="outline">
                <mat-label>Add a note</mat-label>
                <input matInput autocomplete="off" placeholder="Add a note" formControlName="note">
              </mat-form-field>
            </div>

            <ifp-account-transfer-promo-code></ifp-account-transfer-promo-code>
            <div [ngClass.xl]="'form-field-lg'" [ngClass.gt-xs]="'form-field-md'" [ngClass.xs]="'form-field-xs'" *ngIf="isLcf"
            fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="12px" >
              <mat-checkbox color="primary" name="lcfAcknowledgement" formControlName="lcfAcknowledgement" type="check"></mat-checkbox>
              <span class="secondary-text">I acknowledge that the amount being drawn is the exact amount of expenses related to this case since last draw or opening of the loan, whichever is the latest.</span>
            </div>
          </div>

          <footer class="flex row end-center">
            <button mat-button type="button" class="layout margin margin-right-0" (click)="cancel()">Cancel</button>
            <button mat-raised-button type="button" color="primary" class="layout margin margin-right-0"
              (click)="save()" [disabled]="continueDisabled">Continue</button>
          </footer>

        </form>

        <ifp-account-transfer-details></ifp-account-transfer-details>

      </div>
    </div>
  </div>
</ifc-app-scroll-container>
