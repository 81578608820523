<div id="navbar" fxLayout="row" fxLayoutAlign="space-between center">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
    <button type="button" mat-icon-button (click)="back()">
      <mat-icon class="secondary-text">keyboard_arrow_left</mat-icon>
    </button>
    <ifp-loan-select *ngIf="accountMode"></ifp-loan-select>
    <ifp-application-select *ngIf="!accountMode"></ifp-application-select>
  </div>
  <ifp-user-menu></ifp-user-menu>
</div>
