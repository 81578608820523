<mat-card class="sample-details"
          ngClass.lt-md="sample-details-sm"
          ngClass.lt-sm="sample-details-xs">

  <mat-card-content fxLayout="row"
                    fxLayoutAlign="space-between"
                    fxLayout.lt-md="column">
    <section fxFlex="48%">
      <span class="mat-title">Details</span>
      <mat-list>
        <mat-list-item>
          <span class="item-title secondary-text"
                mat-line>Credit limit</span>
          <span class="item-content">{{offerCalculator.amount | currency:'USD':'symbol':'1.2-2'}}</span>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item *ngIf="showTerm">
          <span class="item-title secondary-text"
                mat-line>Term</span>
          <span class="item-content">{{offerCalculator.term}} months</span>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item>
          <span class="item-title secondary-text"
                mat-line>Sample Draw</span>
          <span class="item-content">{{offerCalculator.sampleDraw | currency:'USD':'symbol':'1.2-2'}}</span>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item>
          <span class="item-title secondary-text"
                mat-line>Fee ({{offerCalculator.drawFeeFormatted}})</span>
          <span class="item-content">{{offerCalculator.drawFee | currency:'USD':'symbol':'1.2-2'}}</span>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item *ngIf="offerCalculator.originationFeeAmount">
          <span class="item-title secondary-text"
                mat-line>Origination fee</span>
          <span class="item-content">{{offerCalculator.originationFeeAmount | currency:'USD':'symbol':'1.2-2'}}</span>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item *ngIf="offerCalculator.maintenanceFeeAmount">
          <span class="item-title secondary-text"
                mat-line>Maintenance fee</span>
          <span class="item-content">{{offerCalculator.maintenanceFeeAmount | currency:'USD':'symbol':'1.2-2'}}</span>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item *ngIf="offerCalculator.maintenanceFeeAmount">
          <span class="item-title secondary-text"
                mat-line>Maintenance fee frequency</span>
          <span class="item-content">{{offerCalculator.maintenanceFeeFrequencyName}}</span>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item>
          <span class="item-title secondary-text"
                mat-line>Disbursed amount</span>
          <span class="item-content">{{offerCalculator.disbursedAmount | currency:'USD':'symbol':'1.2-2'}}</span>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item>
          <span class="item-title secondary-text"
                mat-line>Daily interest rate</span>
          <span class="item-content">{{offerCalculator.dailyInterestRate | percent:'1.4-4'}}</span>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item>
          <span class="item-title secondary-text"
                mat-line>{{offerCalculator.paymentFrequencyName}} payment</span>
          <span class="item-content">{{offerCalculator.termPayment | currency:'USD':'symbol':'1.2-2'}}</span>
          <mat-divider *ngIf="offerCalculator.noPaymentTermsValue"></mat-divider>
        </mat-list-item>
        <mat-list-item *ngIf="offerCalculator.noPaymentTermsValue">
          <span class="item-title secondary-text"
                mat-line>No payment period</span>
          <span class="item-content">{{offerCalculator.noPaymentTermsValue}} {{offerCalculator.noPaymentTermsName}}</span>
          <mat-divider *ngIf="offerCalculator.interestOnlyPaymentTermsValue"></mat-divider>
        </mat-list-item>
        <mat-list-item *ngIf="offerCalculator.interestOnlyPaymentTermsValue">
          <span class="item-title secondary-text"
                mat-line>Interest only period</span>
          <span class="item-content">{{offerCalculator.interestOnlyPaymentTermsValue}} {{offerCalculator.interestOnlyPaymentTermsName}}</span>
          <mat-divider *ngIf="showCommision"></mat-divider>
        </mat-list-item>
        <mat-list-item *ngIf="showCommision">
          <span class="item-title secondary-text"
                mat-line>Commissions ({{offerCalculator.brokerInitialDrawCommision | percent:'1.2-2'}})</span>
          <span class="item-content">{{offerCalculator.commision | currency:'USD':'symbol':'1.2-2'}}</span>
        </mat-list-item>
      </mat-list>
    </section>
    <section fxFlex="48%">
      <span class="mat-title">Cost of capital</span>
      <mat-list>
        <mat-list-item>
          <span class="item-title secondary-text"
                mat-line>Interest paid</span>
          <span class="item-content">{{ offerCalculator.sampleDuration.interest | currency:'USD':'symbol':'1.2-2' }}</span>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item>
          <span class="item-title secondary-text"
                mat-line>Payback</span>
          <span class="item-content">{{ offerCalculator.sampleDuration.payback | currency:'USD':'symbol':'1.2-2' }}</span>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item>
          <span class="item-title secondary-text"
                mat-line>Monthly rate*</span>
          <span class="item-content">{{ offerCalculator.monthlyRate | percent:'1.2-2'}}</span>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item>
          <span class="item-title secondary-text"
                mat-line>Annual rate*</span>
          <span class="item-content">{{ offerCalculator.annualRate | percent:'1.2-2'}}</span>
        </mat-list-item>
        <mat-list-item>
          <span class="info secondary-text">
            * The rates are calculated based on the total interest paid over the life of the loan, assuming an organic repayment.
          </span>
        </mat-list-item>
        <mat-list-item *ngIf="offerCalculator.hasDisclosures">
          <a (click)="onClickDisclosures()"
             class="disclosure-link">Click here to read disclosures required by law.</a>
        </mat-list-item>
      </mat-list>
    </section>
  </mat-card-content>
</mat-card>
