import { LoanFacade } from "./../loan/domain/+state/loan.facade";
import { Injectable } from "@angular/core";
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable, of } from "rxjs";
import {
  OfferbundleStatus,
  LoanData,
} from "../loan/domain/models/loan.model";
import { RenewalService } from "./renewal.service";
import { switchMap, mergeMap, tap, map } from "rxjs/operators";
import * as _ from "lodash";
import { LoanStatus, RouteHelper, parseNumber } from "common";
import { OfferBundleInfo } from "../agreement/agreement.data";

@Injectable({
  providedIn: "root",
})
export class OfferBundlesInfoResolver implements Resolve<OfferBundleInfo> {
  constructor(
    private renewalService: RenewalService,
    private loanFacade: LoanFacade
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): OfferBundleInfo | Observable<OfferBundleInfo> {
    const accountId = parseNumber(RouteHelper.getParamValueByName(route, "accountId"));
    return this.loanFacade.getLoan$(accountId).pipe(
      map((loan: LoanData) => loan),
      switchMap((loan) =>
        loan && loan.status === LoanStatus.PendingRenewal
          ? this.renewalService.getOfferBundles(loan.id).pipe(
              map((offerBundles) => ({
                acceptedOfferBundle: _.find(offerBundles, {
                  status: OfferbundleStatus.Accepted,
                }),
                loanId: loan.id,
              }))
            )
          : of(null)
      ),
      mergeMap((data) =>
        data?.acceptedOfferBundle
          ? this.renewalService
              .getNextOffer(data.loanId, data.acceptedOfferBundle.id)
              .pipe(
                map((nextOffer) => ({
                  offerBundleId: data.acceptedOfferBundle
                    ? data.acceptedOfferBundle.id
                    : null,
                  selectedOffer: nextOffer ? nextOffer : null,
                }))
              )
          : of(null)
      ),
      tap((result) => result)
    );
  }
}
