import { NgModule } from '@angular/core';
import { CommonModule, NgxCurrencyModule } from 'common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SidenavContentComponent } from './sidenav-content/sidenav-content.component';
import { LocLawChartContentComponent } from './loc-law-card-chart/loc-law-card-chart.component';
import { AdvisorWidgetComponent } from './advisor-widget/advisor-widget.component';
import { RouterModule } from '@angular/router';
import { AccountSummaryComponent } from './account-summary/account-summary.component';
import { EntitySelectComponent } from './entity-select/entity-select.component';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { TopToolbarComponent } from './top-toolbar/top-toolbar.component';
import { MatIconChipComponent } from './material/mat-icon-chip/mat-icon-chip.component';
import { NavbarComponent } from './navbar/navbar.component';
import { BottomToolbarComponent } from './bottom-toolbar/bottom-toolbar.component';
import { BankAccountLinkStatusIconChipComponent } from './bank-account-link-status-icon-chip/bank-account-link-status-icon-chip.component';
import { AccountStatusIconChipComponent } from './account-status-icon-chip/account-status-icon-chip.component';
import { NgChartsModule } from 'ng2-charts';
import { LoanSelectComponent } from './loan-select/loan-select.component';
import { NotificationDialogComponent } from './notification-dialog/notification-dialog.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { LoanDescriptionDialogComponent } from '../loan/loan-description-dialog/loan-description-dialog.component';
import { ApplicationStatusIconChipComponent } from './application-status-icon-chip/application-status-icon-chip.component';
import { ApplicationSelectComponent } from './application-select/application-select.component';
import { ApplicationStatusStepperComponent } from './application-status-stepper/application-status-stepper.component';
import { ApplicationSummaryComponent } from './application-summary/application-summary.component';
import { ReferralCodeEmailComponent } from './referral-code-email/referral-code-email.component';
import { ReferralCodeSocialMediaComponent } from './referral-code-social-media/referral-code-social-media.component';
import { ReferralCodeEmailDialogComponent } from './referral-code-email/referral-code-email-dialog/referral-code-email-dialog.component';
import { SpinnerLoadingComponent } from './spinner-loading/spinner-loading.component';
import { LoansSelectDialogComponent } from './loans-select-dialog/loans-select-dialog.component';
import { SidenavLocLawChartContentComponent } from './loc-law-card-chart/sidenav/sidenav-loc-law-card-chart.component';
import { SidenavTermLoanChartContentComponent } from './term-loan-card-chart/sidenav/sidenav-term-loan-card-chart.component';
import { TermLoanChartContentComponent } from './term-loan-card-chart/term-loan-card-chart.component';
import { SideNavApplicationStatusStepperComponent } from './application-status-stepper/sidenav/sidenav-application-status-stepper.component';
import { BankLinkingComponent } from './bank-linking/bank-linking.component';
import { LoanFileTemplateDialogComponent } from '../loan/loan-file-template-dialog/loan-file-template-dialog.component';
import { SidenavLawCardChartOptionsComponent } from './sidenav-content/components/sidenav-law-card-chart-options/sidenav-law-card-chart-options.component';
import { DateAdapter } from '@angular/material/core';
import { FedDateAdapter } from '../../../../common/src/lib/ui/datepicker/date-adapter.component';

@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        RouterModule,
        NgxCurrencyModule.forRoot({
            align: "left",
            allowNegative: false,
            allowZero: true,
            decimal: ".",
            precision: 2,
            prefix: "$",
            suffix: "",
            thousands: ",",
            nullable: true
        }),
        NgChartsModule
    ],
    exports: [
        CommonModule,
        FlexLayoutModule,
        LocLawChartContentComponent,
        TermLoanChartContentComponent,
        AdvisorWidgetComponent,
        AccountSummaryComponent,
        EntitySelectComponent,
        LoanSelectComponent,
        ApplicationSelectComponent,
        UserMenuComponent,
        TopToolbarComponent,
        MatIconChipComponent,
        NavbarComponent,
        BottomToolbarComponent,
        BankAccountLinkStatusIconChipComponent,
        AccountStatusIconChipComponent,
        ApplicationStatusIconChipComponent,
        NotificationDialogComponent,
        ApplicationStatusStepperComponent,
        ApplicationSummaryComponent,
        ReferralCodeEmailComponent,
        ReferralCodeSocialMediaComponent,
        SpinnerLoadingComponent,
        LoansSelectDialogComponent,
        BankLinkingComponent,
        LoanFileTemplateDialogComponent
    ],
    declarations: [
        SidenavContentComponent,
        LocLawChartContentComponent,
        TermLoanChartContentComponent,
        AdvisorWidgetComponent,
        AccountSummaryComponent,
        EntitySelectComponent,
        LoanSelectComponent,
        ApplicationSelectComponent,
        UserMenuComponent,
        TopToolbarComponent,
        MatIconChipComponent,
        NavbarComponent,
        BottomToolbarComponent,
        BankAccountLinkStatusIconChipComponent,
        AccountStatusIconChipComponent,
        ApplicationStatusIconChipComponent,
        NotificationDialogComponent,
        ConfirmDialogComponent,
        LoanDescriptionDialogComponent,
        ApplicationStatusStepperComponent,
        ApplicationSummaryComponent,
        ReferralCodeEmailComponent,
        ReferralCodeSocialMediaComponent,
        ReferralCodeEmailDialogComponent,
        SpinnerLoadingComponent,
        LoansSelectDialogComponent,
        SidenavLocLawChartContentComponent,
        SidenavTermLoanChartContentComponent,
        SideNavApplicationStatusStepperComponent,
        BankLinkingComponent,
        LoanFileTemplateDialogComponent,
        SidenavLawCardChartOptionsComponent
    ],
    providers: [
        {
            provide: DateAdapter,
            useClass: FedDateAdapter
        }
    ]
})
export class SharedModule { }
