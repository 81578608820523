import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { CaseResolutionDialogData } from './case-resolution-dialog.model';

@Component({
  selector: 'ifc-case-resolution-dialog',
  templateUrl: './case-resolution-dialog.component.html',
  styleUrls: ['./case-resolution-dialog.component.scss']
})
export class CaseResolutionDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CaseResolutionDialogData,
    private dialogRef: MatDialogRef<CaseResolutionDialogComponent>) {
  }

  public onOkClick(){
    this.dialogRef.close();
  }

  public static show(dialog: MatDialog, data: CaseResolutionDialogData): Observable<any> {
    return dialog
      .open(CaseResolutionDialogComponent, {data, closeOnNavigation: true, minWidth: 300, maxWidth: 500})
      .afterClosed();
  }
}
