import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { ActivatedRoute, Router } from "@angular/router";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { AccountTransactionService } from "../account-transaction.service";
import { LoanData } from "../../../loan/domain/models/loan.model";
import { AccountTransactionFilterService } from "../account-transaction-filter.service";
import { AccountTransactionCancelDialogComponent } from "../account-transaction-cancel-dialog/account-transaction-cancel-dialog.component";
import { AccountTransaction, AccountTransactionQueryResult, TransactionStatus, TransactionStatusChips, TransactionType } from "common";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { LoanFacade } from "../../../loan/domain/+state/loan.facade";

@UntilDestroy()
@Component({
  selector: "ifp-account-transaction-table",
  templateUrl: "./account-transaction-table.component.html",
  styleUrls: ["./account-transaction-table.component.scss"],
})
export class AccountTransactionTableComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  get TransactionStatus() {
    return TransactionStatus;
  }
  get Status() {
    return TransactionStatusChips;
  }
  get pageSize() {
    return this.accountTransactionService.pageSize;
  }

  data: AccountTransactionTableComponentData;
  hidePageSize: boolean;

  displayedColumns: string[] = [
    "type-icon",
    "date",
    "amount",
    "status",
    "initiator",
    "type",
    "notes",
    "closingBalance",
    "totalPayback",
    "actions",
  ];

  constructor(
    private accountTransactionService: AccountTransactionService,
    private _accountTransactionFilterService: AccountTransactionFilterService,
    private loanFacade: LoanFacade,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    breakpointObserver: BreakpointObserver
  ) {
    breakpointObserver.observe([Breakpoints.XSmall]).subscribe((result) => {
      if (result.matches) this.hidePageSize = true;
      else this.hidePageSize = false;
    });
  }

  ngOnInit() {
    this.route.data
      .pipe(untilDestroyed(this))
      .subscribe((res: AccountTransactionTableComponentData) => {
        this.data = res;
      });
  }

  sortData(sort: Sort) {
    this.filter();
  }

  setPage(event: PageEvent) {
    this.accountTransactionService.pageSize = event.pageSize;
    this.filter();
  }

  filter(): void {
    this._accountTransactionFilterService
      .filter(this.data.loan.id, this.paginator, this.sort)
      .subscribe(
        (result) => (this.data.accountTransactionQueryResult = result)
      );
  }

  transactionType(type: TransactionType) {
    switch (type) {
      case TransactionType.Draw:
        return "Draw";
      case TransactionType.Fee:
        return "Fee";
      case TransactionType.Payment:
        return "Payment";
      case TransactionType.BalanceTransfer:
        return "Balance transfer";
      case TransactionType.Premium:
        return "Premium";
    }
  }

  transactionDetailLink(txId: string): void {
    const path = `account/${this.data.loan.id}/transaction/${txId}`;
    this.router.navigate([path]);
  }

  transactionCancel(transaction: AccountTransaction): void {
    if (!transaction.cancellable) return;
    AccountTransactionCancelDialogComponent.show(this.dialog).subscribe((r) => {
      if (r)
        this.accountTransactionService
          .cancelTransaction(transaction.id)
          .subscribe((e) => {
            this.loanFacade.refreshLoan(this.data.loan.id).subscribe()
            this.filter();    
          });
    });
  }

  getTransactionPrimaryAmount(transaction: AccountTransaction) {
    return transaction.type === TransactionType.Payment
      ? transaction.principalAmount
      : transaction.disbursedAmount;
  }

  getTransactionSecondaryAmount(transaction: AccountTransaction) {
    return transaction.type === TransactionType.Payment
      ? transaction.interestAmount
      : transaction.deferredFeeAmount;
  }

  getAvatarIcon(type: number) {
    switch (type) {
      case TransactionType.Draw:
        return "D";
      case TransactionType.Payment:
        return "P";
      case TransactionType.BalanceTransfer:
        return "B";
      case TransactionType.Fee:
        return "F";
      case TransactionType.Premium:
        return "$";
    }
  }

  public downloadCsv(): void {
    this.accountTransactionService.downloadCsv(this.data.loan.id);
  }
}

export interface AccountTransactionTableComponentData {
  accountTransactionQueryResult: AccountTransactionQueryResult;
  loan: LoanData;
}
