import { Injectable } from "@angular/core";
import { MfaActionsInternalService } from "./mfa-actions.internal.service";
import { Observable } from "rxjs";
import { CloseMfaData } from "../const/models/close-mfa-data.model";
import { VerifyMfaCodeData } from "../const/models/verify-mfa-code-data.model";

@Injectable()
export class MfaActionsService {
  constructor(private internalService: MfaActionsInternalService) {}

  invalidCode(): void {
    this.internalService.invalidCode();
  }

  get close$(): Observable<CloseMfaData> {
    return this.internalService.close$;
  }

  get verifyMfaCode$(): Observable<VerifyMfaCodeData> {
    return this.internalService.verifyMfaCode$;
  }
}
