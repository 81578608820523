import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { AppSettings } from "projects/portal/src/app.settings";
import { UserService } from "../../user.service";
import { BrandingService } from "common";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "ifp-user-sign-out-form",
  templateUrl: "./user-sign-out-form.component.html",
  styleUrls: ["./user-sign-out-form.component.scss"],
})
export class UserSignOutFormComponent implements OnInit {
  registerUrl: string;
  loginUrl: string;

  constructor(
    private dialogRef: MatDialog,
    private appSettings: AppSettings,
    private userService: UserService,
    private brandingService: BrandingService
  ) { }

  ngOnInit(): void {
    this.initBranding();
    this.dialogRef.closeAll();
    this.userService.logout();
    this.loginUrl = this.getLoginUrl();
  }

  private initBranding() {
    this.brandingService.getBranding$()
      .pipe(untilDestroyed(this))
      .subscribe((branding) => {
        this.registerUrl = branding?.loginUrl + this.appSettings.auth.register;
      });
  }

  private getLoginUrl(): any {
    const backTo = new URLSearchParams(window.location.search).get('backTo');

    if (!backTo) {
      return '/sign-in';
    }

    return new URL('/sign-in', backTo)?.href;
  }
}
