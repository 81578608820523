import { parseNumber } from "../helpers/parse.helper";

export interface QueryParams {
    active?: boolean;
    limit?: number;
    skip?: number;
    sort?: string;
}

export interface QueryResult<TData> {
    values: TData[];
    totalCount: number;
}

export class QueryParams {
    active?: boolean;
    limit?: number = 20;
    skip?: number = 0;
    sort?: string;

    constructor(params?) {
        params = params || {};
        this.active = params.active == null ? undefined : params.active;
        this.limit = parseNumber(params.limit) || undefined;
        this.skip = parseNumber(params.skip) || undefined;
        this.sort = params.sort || undefined;
    }
}
