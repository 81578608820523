import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppBarComponent } from './app-bar/app-bar.component';
import { AppBarContactComponent } from './app-bar-contact/app-bar-contact.component';
import { AppFooterComponent } from './app-footer/app-footer.component';
import { AppLogoComponent } from './app-logo/app-logo.component';
import { AppPageComponent } from './app-page/app-page.component';
import { RouterModule } from '@angular/router';
import { SvgsModule } from '../svgs/svgs.module';
import { AppLoaderComponent } from './app-loader/app-loader.component';
import { MaterialModule } from '../material/material.module';
import { AppBarTitleComponent } from './app-bar-title/app-bar-title.component';
import { AppSidenavComponent } from './app-sidenav/app-sidenav.component';
import { AppBarExpandedTabsComponent } from './app-bar-expanded-tabs/app-bar-expanded-tabs.component';
import { AppBarActionsComponent } from './app-bar-actions/app-bar-actions.component';
import { AppCardComponent } from './app-card/app-card.component';
import { AppScrollContainerComponent } from './app-scroll-container/app-scroll-container.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AppDetailLayout2Component } from './app-detail-layout-2/app-detail-layout-2.component';
import { PipesModule } from '../pipes/pipes.module';
import { AppLogoIconComponent } from './app-logo-icon/app-logo-icon.component';
import { AppCardOneColumnComponent } from './app-card-one-column/app-card-one-column.component';
import { AppBarExpandedComponent } from './app-bar-expanded/app-bar-expanded.component';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    RouterModule,
    MaterialModule,
    SvgsModule,
    PipesModule
  ],
  declarations: [
    AppBarActionsComponent,
    AppBarComponent,
    AppBarContactComponent,
    AppBarExpandedTabsComponent,
    AppBarTitleComponent,
    AppCardComponent,
    AppFooterComponent,
    AppLoaderComponent,
    AppLogoComponent,
    AppPageComponent,
    AppDetailLayout2Component,
    AppSidenavComponent,
    AppScrollContainerComponent,
    AppLogoIconComponent,
    AppCardOneColumnComponent,
    AppBarExpandedComponent
  ],
  exports: [
    AppBarActionsComponent,
    AppBarComponent,
    AppBarContactComponent,
    AppBarExpandedTabsComponent,
    AppBarTitleComponent,
    AppCardComponent,
    AppFooterComponent,
    AppLoaderComponent,
    AppLogoComponent,
    AppPageComponent,
    AppDetailLayout2Component,
    AppSidenavComponent,
    AppScrollContainerComponent,
    AppLogoIconComponent,
    AppCardOneColumnComponent,
    AppBarExpandedComponent
  ]
})
export class AppModule { }
