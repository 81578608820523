import { Component, Inject, ViewEncapsulation } from "@angular/core";
import { UntypedFormControl, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { EntityOtherInfoUpdateDto } from "common";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { EntityFacade } from "../../entity/domain/+state/entity.facade";

@UntilDestroy()
@Component({
  selector: "ifp-more-info-dialog",
  templateUrl: "./more-info-dialog.component.html",
  styleUrls: ["./more-info-dialog.component.scss"],
  encapsulation: ViewEncapsulation.Emulated,
})
export class MoreInfoDialogComponent {
  establishedDate: UntypedFormControl;
  today = new Date();

  constructor(
    public dialogRef: MatDialogRef<MoreInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private entityFacade: EntityFacade
  ) {
    this.establishedDate = new UntypedFormControl(null, {
      validators: Validators.required,
      updateOn: "blur",
    });
  }

  submit(): void {
    if (this.establishedDate.valid) {
      const updateCommand: EntityOtherInfoUpdateDto = {
        establishedDate: this.establishedDate.value,
      };
      this.entityFacade
        .updateOtherInfo(this.data.entityId, updateCommand)
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.dialogRef.close(true);
        });
    }
  }
}
