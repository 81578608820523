import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  AppBarTitleService,
  AppBarActionsService,
  ConfigService,
  OAuthService,
  AppBarAction,
  DocumentCategory,
  CacheService,
} from "common";
import { takeUntil } from "rxjs/operators";
import { DocumentUploadBaseComponent } from "./document-upload-base.component";
import * as _ from "lodash";
import {
  getUppyDashboardOptions,
  getUppyInstance,
} from "./document-upload-uppy-config";

@Component({
  selector: "ifp-application-document-upload",
  templateUrl: "./document-upload.component.html",
  styleUrls: ["./document-upload.component.scss"],
})
export class ApplicationDocumentUploadComponent
  extends DocumentUploadBaseComponent
  implements OnInit
{
  protected readonly baseUrl: string;

  private applicationId: number;
  private applicationUniqueId: string;
  private allowedCategories: DocumentCategory[];
  public applicationCategories: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private configService: ConfigService,
    private authService: OAuthService,
    appBarTitleService: AppBarTitleService,
    appBarActionsService: AppBarActionsService,
    private cacheService: CacheService
  ) {
    super(appBarTitleService, appBarActionsService);
    this.allowedCategories = [
      DocumentCategory.Other,
      DocumentCategory.ApplicationSupporting,
      DocumentCategory.InsurancePolicy,
      DocumentCategory.BankStatement,
      DocumentCategory.TaxReturn,
    ];
    this.selectedCategory = DocumentCategory.BankStatement;
    this.baseUrl = `${this.configService.environment.applications.url}/api/application`;
  }

  ngOnInit() {
    this.route.params
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((params) => {
        this.applicationId = params.appId;
        this.applicationUniqueId = params.uniqueId;
        this.setupUppy();
      });

    this.appBarActionsService.invoking
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.actionDispatch.bind(this));

    this.applicationCategories = _.flatMap(
      this.categories.filter((i) => _.includes(this.allowedCategories, i.value))
    );
  }

  actionDispatch(action: AppBarAction) {
    const actionHandler: (action: AppBarAction) => void =
      this[action.id].bind(this);
    actionHandler(action);
  }

  cancel(): void {
    this.navigateToDocuments();
  }

  onUploadResult(event: any): void {
    this.submitting = false;

    if (event.failed && event.failed.length > 0) {
      return;
    }

    //we need to explicitly invalidate cache here because file uploading does not go through CacheInterceptor, thus no implicit cache invalidation
    this.cacheService.invalidateUrl(
      `${this.baseUrl}/sf/${this.applicationUniqueId}/documents/${this.selectedCategory}`
    );
    this.cacheService.invalidateUrl(
      `${this.baseUrl}/${this.applicationId}/documents/${this.selectedCategory}`
    );

    this.navigateToDocuments();
  }

  private navigateToDocuments() {
    this.router.navigate([`application/${this.applicationId}/documents`]);
  }

  setupUppy(): void {
    const endpoint = this.prepareUppyEndpoint();

    this.uppy = getUppyInstance(endpoint, this.authService.accessToken);
    this.uppyDashboardOptions = getUppyDashboardOptions();
  }

  protected override updateUppyEndpoint(): void {
    this.updateUppyEndpointBase(this.prepareUppyEndpoint());
  }

  private prepareUppyEndpoint(): string {
    if (this.applicationUniqueId) {
      return (
        this.baseUrl +
        `/sf/${this.applicationUniqueId}/documents/${this.selectedCategory}`
      );
    }
    return (
      this.baseUrl + `/${this.applicationId}/documents/${this.selectedCategory}`
    );
  }
}
