<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px" fxFlex>
  <span class="mat-body-2 layout margin-left-sm secondary-text">Filters: </span>
  <span *ngIf="!filters.length" class="mat-caption">None</span>
  <span *ngIf="filters.length" fxHide fxShow.xs class="mat-caption">Applied</span>
  <mat-chip-list #chipList fxHide.xs>
    <mat-chip *ngFor="let filter of filters" [selectable]="selectable" [removable]="removable" (removed)="remove(filter)">
      {{filter.name}}: {{filter.value}}
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip>
  </mat-chip-list>
  <button *ngIf="filters.length" mat-button (click)="clear()">CLEAR</button>
</div>