<div class="requirements">
  <div *ngFor="let checkoutRequirement of checkoutRequirements; let i = index">
    <ifp-offer-checkout-requirement-item
      [applicationId]="applicationId"
      [checkoutRequirement]="checkoutRequirement"
      (checkoutFilesChanged)="onCheckoutRequirementChanged(checkoutRequirement, $event)"
    ></ifp-offer-checkout-requirement-item>
    <hr *ngIf="checkoutRequirements.length - 1 > i" class="line" />
  </div>
</div>
