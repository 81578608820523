import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from "@angular/router";
import {
  AccountQueryParams,
  LoanDocumentService,
  LoanFileTemplateData,
  RouteHelper,
  parseNumber,
} from "common";
import { Observable } from "rxjs";
import { LoanData, LoanDataSummary, LoanWithRenewalData } from "../domain/models/loan.model";
import { map, switchMap, take } from "rxjs/operators";
import { QueryParamsService } from "projects/common/src/lib/query/query-params.service";
import { LoanFacade } from "../domain/+state/loan.facade";
import { EntityFacade } from "../../entity/domain/+state/entity.facade";

@Injectable({
  providedIn: "root",
})
export class LoanResolver implements Resolve<LoanData> {
  constructor(private loanFacade: LoanFacade) {}

  resolve(
    route: ActivatedRouteSnapshot
  ): LoanData | Observable<LoanData> | Promise<LoanData> {
    const accountId = parseNumber(RouteHelper.getParamValueByName(route, "accountId"));
    return this.loanFacade.getLoan$(accountId);
  }
}

@Injectable({
  providedIn: "root",
})
export class LoansResolver implements Resolve<LoanData[]> {
  constructor(
    private entityFacade: EntityFacade,
    private loanFacade: LoanFacade
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<LoanData[]> {
    const companyId = parseNumber(RouteHelper.getQueryParamValueByName(route, "companyId"));
    return this.entityFacade.getEntity$(companyId).pipe(
      take(1),
      switchMap((entity) => this.loanFacade.getByEntity(entity.id)),
      map((results) => results?.values)
    );
  }
}

@Injectable({
  providedIn: "root",
})
export class LoanWithRenewalsResolver implements Resolve<LoanWithRenewalData> {
  constructor(private loanFacade: LoanFacade) {}

  resolve(route: ActivatedRouteSnapshot): Observable<LoanData> {
    const accountId = parseNumber(RouteHelper.getParamValueByName(route, "accountId"));
    return this.loanFacade.getWithRenewal(accountId);
  }
}

@Injectable({
  providedIn: "root",
})
export class LoanAllByProductCodeResolver implements Resolve<LoanDataSummary> {
  constructor(
    private entityFacade: EntityFacade,
    private loanFacade: LoanFacade,
    private queryParamsService: QueryParamsService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<LoanDataSummary> {
    const companyId = parseNumber(RouteHelper.getQueryParamValueByName(route, "companyId"));
    let params = new AccountQueryParams(route.queryParams);
    params.productCode = route.data["productCode"];
    params.excludedProductCode = route.data["excludedProductCode"];
    params = this.queryParamsService.init(params);

    return this.entityFacade.getEntity$(companyId).pipe(
      take(1),
      switchMap((entity) => {
        return this.loanFacade.getSummaryByEntity(entity.id, params);
      })
    );
  }
}

@Injectable({
  providedIn: "root",
})
export class LoanFileTemplatesResolver
  implements Resolve<LoanFileTemplateData[]>
{
  constructor(private loanDocumentService: LoanDocumentService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<LoanFileTemplateData[]> {
    const accountId = parseNumber(RouteHelper.getParamValueByName(route, "accountId"));
    return this.loanDocumentService.getLoanFileTemplates(accountId);
  }
}
