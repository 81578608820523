import { NgModule } from '@angular/core';
import { CommonModule } from 'common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { LoginDirective } from './login/login.directive';
import { CallbackComponent } from './callback/callback.component';
import { OidcRoutingModule } from './oidc-routing.module';

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    RouterModule,
    OidcRoutingModule
  ],
  declarations: [
    LoginDirective,
    CallbackComponent
  ]
})
export class OidcModule { }

