import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerCheckoutModule } from './customer-checkout/customer-checkout.module';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    CustomerCheckoutModule
  ],
  declarations: []
})
export class CustomerModule { }
