import { Directive, ElementRef, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import * as textMask from 'vanilla-text-mask/dist/vanillaTextMask.js';
import { ObjectHelper } from "../../helpers/object.helper";

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: `date-mask, [date-mask], [dateMask]`
})
export class DateMaskDirective implements OnInit, OnDestroy {
  @HostBinding('class.date-mask') compClass = true;

  @Input()
  dateMask = {
    mask: [],
    showMask: false,
    guide: true,
    placeholderChar: '_'
  };

  maskedInputController;

  constructor(private element: ElementRef) {}

  ngOnInit(): void {
    if (ObjectHelper.isEmpty(this.dateMask, false)) {
      return;
    }
    this.maskedInputController = textMask.maskInput({
      inputElement: this.element.nativeElement,
      ...this.dateMask
    });
  }

  ngOnDestroy() {
    this.maskedInputController?.destroy();
  }
}
