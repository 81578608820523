import { Component, Input } from '@angular/core';

@Component({
  selector: 'ifp-application-status-icon-chip',
  templateUrl: './application-status-icon-chip.component.html',
  styleUrls: ['./application-status-icon-chip.component.scss']
})
export class ApplicationStatusIconChipComponent {

  @Input() status: string;

  constructor() { }

  statusLabels = {
    'Application Created': { color: 'mat-grey-bg', icon: 'assignment', label: 'Application created' },
    'Application Incomplete': { color: 'mat-orange-bg', icon: 'error', label: 'Application incomplete' },
    'Processing': { color: 'mat-grey-bg', icon: 'assignment', label: 'Processing' },
    'Underwriting': { color: 'mat-darkblue-bg', icon: 'find_in_page', label: 'Underwriting' },
    'Underwriting Missing Info': { color: 'mat-orange-bg', icon: 'error', label: 'Underwriting missing info' },
    'Underwriting Review': { color: 'mat-darkblue-bg', icon: 'find_in_page', label: 'Underwriting' },
    'Conditional Offer': { color: 'mat-darkblue-bg', icon: 'find_in_page', label: 'Conditional Offer' },
    'Approved': { color: 'mat-green-bg', icon: 'check_circle', label: 'Approved' },
    'Accepted': { color: 'mat-green-bg', icon: 'check_circle', label: 'Approved' },
    'Underwriting Call': { color: 'mat-orange-bg', icon: 'error', label: 'Underwriting call' },
    'Closing': { color: 'mat-green-bg', icon: 'assignment_turned_in', label: 'Closing' },
    'Closing Incomplete': { color: 'mat-orange-bg', icon: 'error', label: 'Closing incomplete' },
    'Pending Signature': { color: 'mat-green-bg', icon: 'offline_pin', label: 'Pending signature' },
    'Application Complete': { color: 'mat-green-bg', icon: 'fact_check', label: 'Application complete' },
    'Declined': { color: 'mat-orange-bg', icon: 'info', label: 'Unable to fund' },
    'Other': { color: 'mat-grey-bg', icon: 'assignment', label: 'Other' },
    'Closing Review': { color: 'mat-green-bg', icon: 'find_in_page', label: 'Closing review' },
    'Dormant': { color: 'mat-red-bg', icon: 'snooze', label: 'Dormant' }
  };
}
