import { Injectable } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import * as moment from 'moment';
import { MatTableHelper, EnumHelper, DocumentCategoryLabel, DocumentFilters, DocumentQueryParams, DocumentCategory, FilterParameter, LoanDocumentService, ApplicationDocumentService } from 'common';
import { DocumentBaseService } from 'common/lib/documents/document-base.service';

export abstract class DocumentFilterService<T extends DocumentBaseService> {

    constructor(protected _documentService: T) { }

    filter(filters: DocumentFilters, paginator?: MatPaginator, sort?: MatSort): DocumentQueryParams {
        const query = new DocumentQueryParams(filters);

        // Set default to first page
        query.skip = undefined;

        query.fromDate = filters.fromDate ? moment(filters.fromDate).format('L') : undefined;
        query.toDate = filters.toDate ? moment(filters.toDate).format('L') : undefined;

        if (paginator) {
            query.limit = paginator.pageSize;
            query.skip = paginator.pageIndex * paginator.pageSize;
        }

        if (sort && sort.active) {
            query.sort = MatTableHelper.getSort(query.sort, sort);
        }

        this._documentService.queryParams = query;

        return query;
    }

    addFilterParameters(query: DocumentQueryParams): FilterParameter[] {
        // Refresh filters chip list
        const filters: FilterParameter[] = [];

        if (query.text) {
            filters.push({
                id: 'text', name: 'Name', value: query.text.trim()
            });
        }
        if (query.category) {
            filters.push({
                id: 'category', name: 'Type',
                value: EnumHelper.getMappedNameFromValue(DocumentCategory, DocumentCategoryLabel, query.category)
            });
        }
        if (query.fromDate || query.toDate) {
            filters.push({
                id: 'fromDate', name: 'Date',
                value: `${query.fromDate || moment().subtract(1, 'year').format('L')} - ${query.toDate}`
            });
        }

        return filters;
    }
}


@Injectable({
    providedIn: 'root'
})
export class LoanDocumentFilterService extends DocumentFilterService<LoanDocumentService>
{
    constructor(
        _documentService: LoanDocumentService
    ) {
        super(_documentService);
    }
}

@Injectable({
    providedIn: 'root'
})
export class ApplicationDocumentFilterService extends DocumentFilterService<ApplicationDocumentService>
{
    constructor(
        _documentService: ApplicationDocumentService
    ) {
        super(_documentService);
    }
}
