<a [title]="title">
  <svg *ngIf="!logo" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 150 103.1" style="enable-background:new 0 0 150 103.1;" xml:space="preserve">
    <g [ngClass]="['svg-logo-icon', logoClass]">
      <g>
        <path class="logo1" d="M131.9,49.9c-0.5-1.7-1.4-3.5-1.2-5.6c6.5,4.2,13,6.5,21.6,4.1c-1.5-0.7-1.9-1-2.3-1.1    c-10.3-1.1-17.2-7.2-21.9-15.8c-1.9-3.4-3.6-5.2-7.7-5.7c-8.9-1.2-24.3-5.9-32.2-10c-8.7-4.6-17-10.3-26.3-13.7    C51.8-1.6,42.1-0.2,33,5c-1.9,1.1-4.4,2.5-3.7,5c0.7,2.4,3.5,1.7,5.5,1.7c5.7,0,11.4,0.1,16.7,2.5c1.9,0.9,2.6,0.5,3.8-1    c3.9-4.6,8.8-5.9,13.5-3.9c2.1,0.9,3,1.7,0.5,3.3c-3.6,2.4-7.1,5-10.6,7.5c-0.9,0.6-1.9,1.1-2,2.4C56.1,34.7,66.7,44,75.9,42.2    c5.5-1.1,10.2-3.6,14.3-7.3c1.3,1.9,1.1,3.9,1.4,5.9c2.7,19-7.7,36-25.2,40.9c-2.2,0.6-4.6,1-6.9,1.3c-2.3,0.3-3.7,1.1-4,2.7    c0,0,0,0,0,0l0,0.1c-0.1,0.7-0.1,1.4,0.1,2.4c0.2,1,0.4,2,0.6,3l1.5,11.1l5.2-0.1c0.2,0,0.4,0,0.7,0c8.5,0.1,17.1,0.1,25.6,0    c0.1,0,0.2,0,0.3,0c1.4-0.1,5.9-0.1,5.9-0.1l-3.1-14.4c18.9-1,21.5,9.1,25.5,13.4l0.4,1.1l4.4,0.1c0.6,0.1,1.2,0.1,1.8,0.1    c0.7,0,1.5,0,2.2,0l1.3,0l0,0c3.8,0,7.6,0,11.4,0l0,0h9.1L136.8,66C135.1,60.6,133.5,55.2,131.9,49.9z"></path>
        <path class="logo1" d="M36.8,80.9C23.1,74.5,17,62.7,19,47.7c0.8-6.1,1.9-12.2,1.6-18.4c-0.5-13-8.9-18.5-20.6-12.9    c4.4,3.3,8.4,6.4,12.5,9.3c1.6,1.1,2,2.2,1.7,4.3C12.1,45.3,9.8,60.7,8,76.2C7.3,82,6.6,87.9,6.1,93.8c0,0.3,0,0.5,0,0.8l-0.7,7.1    l6.6,0.2c0.2,0,0.4,0.1,0.6,0.1c0.5,0,0.9,0,1.4,0l1.6,0.1l0,0c5.5,0.1,10.9,0.1,16.4,0l0,0.1l7.6-0.4l1.1-10.4l-0.1,0.1    c0.1-1,0.2-2,0.5-3C41.7,84.6,40.3,82.6,36.8,80.9z"></path>
        <path class="logo1" d="M76.1,68.7c0.5,0.6,1,1.2,1.8,0.8c0.6-0.3,0.5-1,0.5-1.9c-0.3-1.5-1.2-2.9-2.9-3.6c-1.2-0.5-2.7-0.7-3.3,0.4    c-1,1.6,1.1,1.3,1.7,2C74.6,67.1,75.4,67.8,76.1,68.7z"></path>
        <path class="logo1" d="M40.3,49.3c-1.9-0.1-3.6,0.4-4.3,1.6c-3.1,5.1,2,12.3,8.6,12.7c2.8,0.1,4.8-0.6,4.6-3.4    C49.2,56.1,46.3,49.7,40.3,49.3z"></path>
      </g>
    </g>
  </svg>
  <img *ngIf="logo" [src]="logo">
</a>