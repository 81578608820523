import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { OfferBundle } from '../loan/domain/models/loan.model';
import { AppSettings } from '../../app.settings';
import { RenewalEligibilityDto, OptInResponseDto, CacheHeaders, ConfigService } from 'common';
import { OfferInfo } from '../agreement/agreement.data';

@Injectable({
  providedIn: 'root'
})
export class RenewalService {

  private readonly loansBaseUrl = `${this.settings.loans.url}/api/renewal`;
  private readonly appsBaseUrl = this.settings.applications.url;

  renewalEligibilityChanged: ReplaySubject<RenewalEligibilityDto>;
  obsCurrentrenewalEligibility: Observable<RenewalEligibilityDto>;

  optInChanged: ReplaySubject<OptInResponseDto>;
  obsCurrentOptIn: Observable<OptInResponseDto>;

  constructor(
    private http: HttpClient,
    private settings: AppSettings,
    private configService: ConfigService
    ) {
    this.renewalEligibilityChanged = new ReplaySubject<RenewalEligibilityDto>(1);
    this.obsCurrentrenewalEligibility = this.renewalEligibilityChanged.asObservable();

    this.optInChanged = new ReplaySubject<OptInResponseDto>(1);
    this.obsCurrentOptIn = this.optInChanged.asObservable();
  }

  getOfferBundles(renewalForLoanId: number): Observable<OfferBundle> {
    const url = `${this.appsBaseUrl}/api/offer-bundle?renewalForLoanId=${renewalForLoanId}`;
    return this.http.get<OfferBundle>(url);
  }

  acceptOfferBundle(renewalForLoanId: number, offerBundleId: number): Observable<boolean> {
    const url = `${this.appsBaseUrl}/api/offer-bundle/${offerBundleId}/renewal-for-loan/${renewalForLoanId}/accept-offer`;
    return this.http.put<boolean>(url, null);
  }

  getNextOffer(renewalForLoanId: number, offerBundleId: number): Observable<OfferInfo> {
    const url = `${this.appsBaseUrl}/api/offer?renewalForLoanId=${renewalForLoanId}&offerBundleId=${offerBundleId}`;
    return this.http.get<OfferInfo>(url);
  }

  putOptIn(loanId: number): Observable<OptInResponseDto> {
    const url = `${this.loansBaseUrl}/${loanId}/opt-in`;
    return this.http.put<OptInResponseDto>(url, { optIn: true },
      {
        headers: {
          [CacheHeaders.Invalidate]: [
            `${this.appsBaseUrl}/api/application`
          ]
        }
      });
  }

  getEligible(): Observable<RenewalEligibilityDto[]> {
    const url = `${this.loansBaseUrl}/eligible`;
    return this.http.get<RenewalEligibilityDto[]>(url, {
      headers: this.configService.environment.cache.shortLived
    });
  }

  confirmRenewal(loanId: number) {
    const url = `${this.loansBaseUrl}/${loanId}/confirm-renewal`;
    return this.http.post<number>(url, null);
  }
}
