import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BankAccountRole, FinancialInstitution } from "../bank-account.model";
import { CustomerData } from "../../customer/domain/models/customer.model";
import { LoanData, LoanBankInfo } from "../../loan/domain/models/loan.model";
import { EntityData } from "common";
import { ApplicationData } from "../../application/application.model";
import { BankingService } from "../banking.service";
import { BankAccountService } from "../bank-account.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "ifp-bank-account-list",
  templateUrl: "./bank-account-list.component.html",
  styleUrls: ["./bank-account-list.component.scss"],
})
export class BankAccountListComponent implements OnInit {
  get BankAccountRole() {
    return BankAccountRole;
  }

  data: BankAccountListComponentData;
  disbursementBankAccount: LoanBankInfo;
  paymentBankAccount: LoanBankInfo;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private bankingService: BankingService,
    private bankAccountService: BankAccountService
  ) {}

  ngOnInit() {
    this.route.data
      .pipe(untilDestroyed(this))
      .subscribe(this.initData.bind(this));

    this.bankingService.obsInstitutionLinkStatus
      .pipe(untilDestroyed(this))
      .subscribe(() => this.fetchData());
  }

  private initData(data: BankAccountListComponentData) {
    this.data = data;
    this.setTransferAccounts();
  }

  fetchData() {
    if (!this.data) return;
    const entityId = this.data.loan
      ? this.data.loan.entityId
      : this.data.application.entityId;
    this.bankAccountService
      .getBanks(entityId)
      .subscribe((res) => (this.data.financialInstitutions = res));
    this.setTransferAccounts();
  }

  setTransferAccounts() {
    if (this.data?.loan) {
      this.paymentBankAccount = this.data.loan.paymentBankAccountData;
      this.disbursementBankAccount = this.data.loan.disbursementBankAccountData;
    }
  }

  link() {
    if (!this.data) return;
    this.router.navigate(["/bank-link-reminder"]);
  }
}

export interface BankAccountListComponentData {
  customer: CustomerData;
  loan: LoanData;
  application: ApplicationData;
  financialInstitutions: FinancialInstitution[];
  entity: EntityData;
}
