<h1 mat-dialog-title>{{data?.title}}</h1>
<mat-dialog-content class="mat-typography">
  <mat-form-field appearance="outline">
    <mat-label>Loan</mat-label>
    <mat-select matInput [(ngModel)]="selected" name="Loan">
      <mat-select-trigger>{{ selected?.label }}</mat-select-trigger>
      <mat-option *ngFor="let loanOption of loanOptions" [value]="loanOption">
        <mat-icon *ngIf="loanOption.iconName" [ngClass]="loanOption.iconClass"
        [matTooltipClass]="'mat-tooltip status-tooltip-'+loanOption.iconName"
        [matTooltip]="loanOption.iconTooltip">{{loanOption.iconName}}</mat-icon>
        <span #label class="loan-description" matTooltipClass="mat-tooltip"
        [matTooltipDisabled]="shouldShowTooltip(label)" [matTooltip]="loanOption.tooltip">{{ loanOption.label }}</span>
      </mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>
<div mat-dialog-actions class="flex row end-center">
  <button mat-button [mat-dialog-close]="undefined">Cancel</button>
  <button mat-button color="primary" (click)="save()">Ok</button>
</div>
