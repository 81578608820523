export enum CustomerKbaStatus {
    None = 'none',
    Success = 'success',
    Failed = 'failed'
}

export interface CustomerKbaConversation {
    conversationId: string;
    questionSet: CustomerKbaQuestionSet;
}

export interface CustomerKbaQuestionSet {
    QuestionSetId: number;
    Questions: CustomerKbaQuestion[];
}

export interface CustomerKbaQuestion {
    Choices: CustomerKbaQuestionChoice[];
    HelpText: CustomerKbaQuestionText;
    Key: number;
    QuestionId: number;
    Text: CustomerKbaQuestionText;
    Type: CustomerKbaQuestionType;
}

export enum CustomerKbaQuestionType {
    SingleChoice = "singlechoice"
}

export interface CustomerKbaQuestionChoice {
    ChoiceId: number;
    Text: CustomerKbaQuestionText;
}

export interface CustomerKbaQuestionText {
    Statement: string;
}