import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { StatusConfig, StatusConfigItem } from './status-label.model';

@Component({
  selector: 'ifc-status-label',
  templateUrl: './status-label.component.html',
  styleUrls: ['./status-label.component.scss']
})
export class StatusLabelComponent implements OnChanges {
  
  @Input() status: StatusConfig;
  @Input() value: string;
  @Input() data: any;

  currentStatus: StatusConfigItem;

  ngOnChanges(_: SimpleChanges): void {
    this.currentStatus = this.calculateCurrentStatus();
  }

  calculateCurrentStatus() {
    if(!this.status || (this.value === undefined || this.value === null))
      return null;

    let currentStatus = this.status[this.value];
    if(currentStatus instanceof Array && this.data) {
      currentStatus = currentStatus.find((status) => status.condition(this.data));
      
    }
    return currentStatus;
  }
}
