import { Component, Input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { LoanDocumentService, LoanFileTemplateData, GetFileData } from "common";
import { LoanFileTemplateDialogComponent } from "projects/portal/src/app/loan/loan-file-template-dialog/loan-file-template-dialog.component";
import { LoanFileTemplateDialogData } from "projects/portal/src/app/loan/loan-file-template-dialog/models/loan-file-template-dialog-data.model";
import { LoanData } from "projects/portal/src/app/loan/domain/models/loan.model";
import { Router } from "@angular/router";

@Component({
  selector: "ifp-sidenav-law-card-chart-options",
  templateUrl: "./sidenav-law-card-chart-options.component.html",
  styleUrls: ['./sidenav-law-card-chart-options.component.scss']
})
export class SidenavLawCardChartOptionsComponent {
  @Input()
  currentLoanFileTemplates: LoanFileTemplateData[];

  @Input()
  currentLoan: LoanData;

  constructor(private loanDocumentService: LoanDocumentService, private dialog: MatDialog, private router: Router) {}

  onClickLoanTemplateOption(loanTemplate: LoanFileTemplateData) {
    this.loanDocumentService.generateFileFromTemplate(this.currentLoan?.id, loanTemplate.id, true).subscribe((fileData: GetFileData) => {
      const data: LoanFileTemplateDialogData = {
        loanDescription: this.currentLoan?.description,
        loanNumber: this.currentLoan?.loanNumber,
        loanTemplate,
        fileData
      };
      LoanFileTemplateDialogComponent.show(this.dialog, data).subscribe();
    })
  }

  onClickPayBalance() {
    this.router.navigate([`account/${this.currentLoan.id}/transfer/payment`]);
  }
}
