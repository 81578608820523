import { Injectable } from "@angular/core";
import { ApplicationOffer } from "projects/common/src/lib/application/application.model";
import { ProductCode } from "projects/common/src/lib/product/product.model";
import { OfferType } from "../../../../models/offer.model";
import { OfferSummaryWidgetData } from "./models/offer-summary-widget-data.model";

@Injectable()
export class OfferSummaryWidgetFacade {
  initData(offers: ApplicationOffer[]): OfferSummaryWidgetData {
    if (!offers || offers.length === 0) {
      return null;
    }

    const offer = offers?.[0];
    const offerType = this.getOfferType(offer.productCode);

    return {
      offerType: this.getOfferType(offer.productCode),
      amountTitle: this.getAmountTitle(offerType),
      amount: this.getAmount(offers),
      productName: offer.productName,
      repaymentTerm: offer.repaymentTerm,
    };
  }

  private getOfferType(productCode: ProductCode): OfferType {
    switch (productCode) {
      case ProductCode.LOC:
        return OfferType.LOC;
      case ProductCode.Term:
        return OfferType.Term;
      case ProductCode.Law:
        return OfferType.Law;
    }
  }

  private getAmountTitle(offerType: OfferType): string {
    switch (offerType) {
      case OfferType.Term:
        return "Loan amount";
      case OfferType.LOC:
      case OfferType.Law:
        return "Credit limit";
    }
  }

  private getAmount(offers: ApplicationOffer[]) {
    return offers.reduce((acc, item) => {
      return (acc += item.amount);
    }, 0);
  }
}
