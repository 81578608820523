<div class="ifc-app-bar-actions" *ngIf="actions"
  [ngClass]="{'menu flex row': true, 'hidden': menuMobileAllowed && data && data.appBarActions && data.appBarActions.menuMobile}">
  <ng-template ngFor let-action [ngForOf]="actions">
    <button
      *ngIf="action.buttonType !== 'link' && action.buttonType !== 'download' && action.buttonType !== 'icon'  && !action.hidden && !action.buttonAppearance"
      mat-button [disabled]="action.disabled" [color]="action.buttonColor" [type]="action.buttonType"
      (click)="click(action)">{{action.label}}
    </button>
    <button
      *ngIf="action.buttonType !== 'link' && action.buttonType !== 'download' && action.buttonType !== 'icon'  && !action.hidden && action.buttonAppearance === 'flat'"
      mat-flat-button [disabled]="action.disabled" [color]="action.buttonColor" [type]="action.buttonType"
      (click)="click(action)">{{action.label}}
    </button>
    <a *ngIf="action.buttonType === 'link' && !action.hidden" target="_blank" rel="noreferrer" href="{{action.link}}"
      mat-button [disabled]="action.disabled">
      {{action.label}}
    </a>
    <a *ngIf="action.buttonType === 'download' && !action.hidden" [href]="action.link" download="{{action.name}}">
      <mat-icon class="secondary-text icon-only">{{action.icon}}</mat-icon>
    </a>
    <a *ngIf="action.buttonType === 'icon' && !action.hidden" (click)="click(action)" class="action-icon">
      <mat-icon class="secondary-text icon-only">{{action.icon}}</mat-icon>
    </a>
  </ng-template>
</div>

<div
  [ngClass]="{'menu-mobile': true, 'visible': menuMobileAllowed && data && data.appBarActions && data.appBarActions.menuMobile && actions?.length}">
  <button mat-icon-button [matMenuTriggerFor]="menu" (click)="fixDisappearIOSBug()">
    <mat-icon class="secondary-text">more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <ng-template ngFor let-action [ngForOf]="actions">
      <button *ngIf="!action.hidden && action.buttonType !== 'link' && action.buttonType !== 'download'" mat-menu-item
        [disabled]="action.disabled" [type]="action.buttonType" (click)="click(action)">
        {{action.label}}
      </button>
      <a *ngIf="!action.hidden && action.buttonType === 'link'" target="_blank" href="{{action.link}}" rel="noreferrer">
        <button *ngIf="!action.hidden && action.buttonType === 'link'" mat-menu-item [disabled]="action.disabled"
          [type]="action.buttonType" (click)="click(action)">
          {{action.label}}
        </button>
      </a>
      <a *ngIf="!action.hidden && action.buttonType === 'download'" [href]="action.link" mat-menu-item
        download="{{action.name}}">
        {{action.label}}
      </a>
    </ng-template>
  </mat-menu>
</div>