import { Component, Input } from "@angular/core";
import { ApplicationOfferDisclosure } from "../../../application/application.model";

@Component({
  selector: "ifc-offer-disclosures",
  templateUrl: "./offer-disclosures.component.html",
  styleUrls: ["./offer-disclosures.component.scss"],
})
export class OfferDisclosuresComponent {
  @Input()
  disclosures: ApplicationOfferDisclosure[] = [];

  disclosureIndex: number = 0;

  close(): void {
    window.close();
  }

  back(): void {
    if (this.disclosureIndex > 0) this.disclosureIndex -= 1;
  }

  next(): void {
    if (this.disclosureIndex < this.disclosures.length - 1)
      this.disclosureIndex += 1;
  }
}
