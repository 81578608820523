import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { forkJoin, Observable } from "rxjs";
import { ConfigService } from "../../config/config.service";
import { OfferBundleCheckoutRequirement, OfferBundleCheckoutRequirementsSummary } from "../models/offer.model";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class OfferBundleCheckoutRequirementService {
  protected readonly baseUrl: string;

  constructor(protected http: HttpClient, protected configService: ConfigService) {
    this.baseUrl = `${this.configService.environment.applications.url}/api/offer-bundle`;
  }

  getList(offerBundleId: number): Observable<OfferBundleCheckoutRequirement[]> {
    return this.http.get<OfferBundleCheckoutRequirement[]>(`${this.baseUrl}/${offerBundleId}/checkout-requirements`, {
      headers: this.configService.environment.cache.noCache,
    });
  }

  getListSummary(offerBundleIds: number[]): Observable<OfferBundleCheckoutRequirementsSummary[]> {
    const requests = offerBundleIds.map(offerBundleId => {
      return this.getList(offerBundleId)
        .pipe(map((checkoutRequirements: OfferBundleCheckoutRequirement[]) => ({ offerBundleId, checkoutRequirements })))
    });
    return forkJoin(requests)
  }
}
