<div id="sidenav-term-loan-card-chart"
     fxLayout="column"
     fxFlexAlign="center center">
    <div *ngIf="shouldDisplayLoanBarChart; else noChart">
      <ifc-bar-chart *ngIf="!hasAvailableRenewalToSign && loan.status !== loanStatus.Pending && kbaVerified"
                    [data]="barChartData"
                    [options]="barChartOptions"
                    [fxHide.xs]="showAvailableFunds || loan.status === loanStatus.PendingRenewal"
                    fxFlexAlign="center"
                    [style.width]="'80%'"
                    [style.height]="'120px'">
      </ifc-bar-chart>
    </div>
    <ng-template #noChart>
      <div fxLayout="row" fxLayoutAlign="center center">
        <mat-icon class="info-icon">info</mat-icon>
        <span>No data available</span>
      </div>
    </ng-template>
  <div fxLayout="row wrap"
       fxLayoutAlign="center start"
       fxLayoutGap="8px"
       class="layout padding-top"
       *ngIf="payEnabled"
       >
    <button mat-raised-button
            class="color-primary"
            (click)="pay()"
            [fxHide.lt-sm]="showAvailableFunds"
            [fxHide.lt-md]="widgetLayout">
      <span class="avatar medium action-icon">P</span>
      <span>PAY</span>
    </button>
  </div>
</div>
