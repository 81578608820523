<mat-card class="disclosures-wrapper">
  <mat-card-content>
    <div [innerHTML]="disclosures[disclosureIndex]?.html | safeHtml" class="disclosures-content"></div>
  </mat-card-content>
  <mat-card-actions class="disclosures-actions">
    <div class="disclosures-actions-close">
      <button mat-raised-button (click)="close()" color="primary">Close</button>
    </div>
    <button mat-raised-button (click)="back()" color="primary" [disabled]="disclosureIndex <= 0">Back</button>
    <button mat-raised-button (click)="next()" color="primary" [disabled]="disclosureIndex >= disclosures.length - 1">Next</button>
  </mat-card-actions>
</mat-card>
