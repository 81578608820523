import { Injectable } from "@angular/core";
import {
  MfaActionsService,
  MfaErrorCodeType,
  MfaService,
  VerifyMfaCodeData,
} from "common";
import {
  MfaTokenPayload,
  PasswordResetRequest,
  PasswordResetResponse,
} from "../../user/user-password-reset/user-password-reset-form/user-password-reset-form.model";
import { UserPasswordResetService } from "../../user/user-password-reset/user-password-reset.service";

@Injectable()
export class MfaResetPasswordService {
  constructor(
    private mfaActions: MfaActionsService,
    private userPasswordService: UserPasswordResetService,
    private mfaService: MfaService
  ) {}

  verify(data: VerifyMfaCodeData) {
    const request: PasswordResetRequest = {
      token: data.mfaData.contextData?.token,
      confirmPassword: data.mfaData.contextData?.confirmPassword,
      password: data.mfaData.contextData?.password,
    };
    const token: MfaTokenPayload = {
      mfaToken: data.mfaData.mfaToken,
      mfaCode: data.mfaCode,
    };
    return this.userPasswordService
      .submit(request, token)
      .subscribe((data: PasswordResetResponse) => {
        this.handleMfaChangePasswordResponse(data);
      });
  }

  private handleMfaChangePasswordResponse(data: PasswordResetResponse) {
    if (!data?.hasError) {
      this.mfaService.handleMfaSuccess();
      return;
    }

    if (data?.message === MfaErrorCodeType.InvalidMfaCode) {
      this.mfaActions.invalidCode();
      return;
    }

    this.mfaService.handleMfaError();
  }
}
