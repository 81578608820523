import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { ErrorsService } from "common";
import { OidcService } from "../oidc.service";

@Injectable({
  providedIn: "root",
})
export class CallbackGuard implements CanActivate {
  constructor(
    private oidcService: OidcService,
    private router: Router,
    private errorsService: ErrorsService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    this.oidcService.handleCallback().subscribe((res) => {
      if (res) {
        this.handleRedirect();
        return;
      }

      this.errorsService.showGeneralErrorPage();
    });

    return false;
  }

  private handleRedirect() {
    const excludedPaths = ["sign-out", "error"];
    const historyQuery = sessionStorage.getItem("navigation.historyQuery");
    const history = sessionStorage.getItem("navigation.history");
    let navigateTo = "home";

    if (historyQuery && !this.hasExcludedPath(excludedPaths, historyQuery)) {
      navigateTo = historyQuery;
    } else if (history && !this.hasExcludedPath(excludedPaths, history)) {
      navigateTo = history;
    }

    sessionStorage.removeItem("navigation.historyQuery");
    sessionStorage.removeItem("navigation.history");
    this.router.navigateByUrl(navigateTo, { replaceUrl: true });
  }

  private hasExcludedPath(excludePaths: string[], targetPath: string) {
    return excludePaths.some((path) => targetPath.includes(path));
  }
}
