import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import { BrandingStateService } from './branding.state.service';
import { ConfigService } from '../../lib/config/config.service';
import { CompanyBranding } from '../../lib/branding/branding.model';

@Injectable({ providedIn: 'root' })
export class BrandingService {
  constructor(
    private readonly stateService: BrandingStateService,
    private readonly http: HttpClient,
    private readonly configService: ConfigService,
  ) { }

  getBranding(): Observable<CompanyBranding> {
    return this.getBranding$().pipe(take(1));
  }

  getBranding$(): Observable<CompanyBranding> {
    if (this.stateService.isBrandingStateLoaded()) {
      return this.stateService.getBranding$();
    }
    return this.refreshBranding$();
  }

  private refreshBranding$(): Observable<CompanyBranding> {
    return ConfigService.isConfigReady$.pipe(
      switchMap(() => this.getBrandingRequest().pipe(
        tap((branding: CompanyBranding) => this.stateService.setBranding(branding)),
        switchMap(() => this.stateService.getBranding$()),
      )))
  }

  private getBrandingRequest(): Observable<CompanyBranding> {
    return this.http.get<CompanyBranding>(`${this.configService.environment.companies.url}/api/companies/branding`);
  }
}
