<mat-dialog-content class="mat-typography">
    <div class="container app-card-content">
        <span class="container__header">Case resolution</span>
        <div class="container__body">
            <p *ngIf="data.outcome">
                <ng-container *ngTemplateOutlet="caseWon"></ng-container>
            </p>
            <p *ngIf="!data.outcome && data.hasInsurance">
                <ng-container *ngTemplateOutlet="caseLostInsured"></ng-container>
            </p>
            <p *ngIf="!data.outcome && !data.hasInsurance">
                <ng-container *ngTemplateOutlet="caseLostNotInsured"></ng-container>
            </p>
            <p>
                Let us know how we did! Choose any of the platforms below to leave a review.
            </p>
        </div>
        <div class="reviews">
            <a [href]="data.googleReviewUrl">
                <img class="reviews__button" src="../../../assets/logos/google-logo.svg" alt="Google Review"/>
            </a>
            <a [href]="data.trustpilotReviewUrl">
                <img class="reviews__button" src="../../../assets/logos/trustpilot-logo.svg" alt="Trustpilot Review"/>
            </a>
        </div>
        <div class="container__actions">
            <button mat-button (click)="onOkClick()" color="primary">Ok</button>
        </div>
    </div>

    <ng-template #caseWon>
        <span> {{ data.caseName }} has been moved to case resolution. The case loan balance of {{ data?.balance ?? 0 | currency:'$' }} must be paid in full within
            {{ undefined | daysBetween : data?.fullRepaymentDue }} days, {{ data?.fullRepaymentDue | date: 'mediumDate' }}.
        </span>
    </ng-template>

    <ng-template #caseLostInsured>
        <span> {{ data.caseName }} has been moved to case resolution. We will continue to collect payments until insurance claim is settled.
        </span>
    </ng-template>

    <ng-template #caseLostNotInsured>
        <span> {{ data.caseName }} has been moved to case resolution.
            <span *ngIf="data.nextPayment">
                If full payoff has not been received by {{ data.nextPayment | date: 'mediumDate' }}, remaining balance will be amortized over 48 months and the monthly payment converted
                to principal and interest effective on {{ data.nextPayment | date: 'mediumDate' }}.
            </span>
        </span>
    </ng-template>
    
</mat-dialog-content>
