import { Injectable } from "@angular/core";
import { AccountPaymentService } from "./account-payment.service";
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { AccountPayment } from "./account-payment.data";
import { Observable } from "rxjs";
import { RouteHelper, parseNumber } from "common";

@Injectable({
  providedIn: "root",
})
export class AccountPaymentsResolver implements Resolve<AccountPayment[]> {
  constructor(private accountPaymentService: AccountPaymentService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<AccountPayment[]>
    | Promise<AccountPayment[]>
    | AccountPayment[] {
    const accountId = parseNumber(RouteHelper.getParamValueByName(route, "accountId"));
    return this.accountPaymentService.getPayments(accountId);
  }
}
