import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ConfigService } from "../config/config.service";
import {
  ApplicationBaseData,
  ApplicationQueryParams,
  ApplicationQueryResult,
} from "./application.model";

@Injectable({
  providedIn: "root",
})
export class ApplicationBaseService<TData extends ApplicationBaseData> {
  protected readonly baseUrl: string;

  constructor(
    protected http: HttpClient,
    protected configService: ConfigService
  ) {
    this.baseUrl = `${this.configService.environment.applications.url}/api/application`;
  }

  getUrl(id: number): string {
    return `${this.baseUrl}/${id}`;
  }

  get(id: number): Observable<TData> {
    return this.http.get<TData>(this.getUrl(id), {
      headers: this.configService.environment.cache.mediumLived,
    });
  }

  query(
    applicationQueryParams: ApplicationQueryParams
  ): Observable<ApplicationQueryResult> {
    return this.http.get<ApplicationQueryResult>(`${this.baseUrl}`, {
      headers: this.configService.environment.cache.mediumLived,
      params: <any>applicationQueryParams,
    });
  }
}
