<mat-card class="mat-elevation-z0">
  <div>
    <mat-card-title>Verify your identity</mat-card-title>
  </div>

  <mat-card-content>
    <section class="content-title">
      We need to confirm your identity before providing you with your funds.
    </section>

    <section class="content-title">
      These questions help us protect you from fraud and identity theft.
      Avoid closing your browser, hitting the refresh button, or leaving the screen before clicking on continue.
    </section>

    <mat-divider></mat-divider>

    <form *ngIf="form" [formGroup]="form.controls.answers" (ngSubmit)="submit()">
      <div formArrayName="Questions"
        *ngFor="let question of data.kbaConversation.questionSet.Questions; let questionIndex = index"
        class="layout padding-bottom">
        <h3 class="customer-kba-form-question">{{question.Text.Statement}}</h3>
        <h4 *ngIf="question.HelpText && question.HelpText.Statement" class="customer-kba-form-question-hint">
          {{question.HelpText.Statement}}
        </h4>
        <div [formGroupName]="questionIndex">
          <mat-radio-group [formControl]="form.get('answers.Questions.' + questionIndex + '.Choices.0.Choice')" class="flex column">
            <mat-radio-button *ngFor="let choice of question.Choices" color="primary" [value]="choice.ChoiceId" class="customer-kba-form-answer">
              {{choice.Text.Statement}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <footer class="flex row end-center">
        <button mat-raised-button type="submit" color="primary" [disabled]="!form.valid || submitSub || !submitEnabled">Continue</button>
      </footer>
    </form>
  </mat-card-content>

</mat-card>
