import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { filter } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AppPageService {
  constructor(private router: Router) {}

  public initUrlsHistory() {
    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        if (!this.isForbidden(e.urlAfterRedirects))
          this.addNavigationHistory(e.urlAfterRedirects);
      });
  }

  sidenavOpen() {
    this.sidenavOpenRequestSubject.next(true);
  }

  sidenavClose() {
    this.sidenavOpenRequestSubject.next(false);
  }

  itemSelectedSubject = new Subject();

  private sidenavOpenRequestSubject = new Subject<boolean>();

  get sidenavOpenRequest(): Observable<boolean> {
    return this.sidenavOpenRequestSubject.asObservable();
  }

  get sidenavOpened(): boolean {
    const storageValue = localStorage.getItem("AppPageService.sidenavOpened");
    if (!storageValue || !storageValue.length) {
      localStorage.setItem("AppPageService.sidenavOpened", "true");
      return true;
    }
    return "true" === storageValue;
  }
  set sidenavOpened(value: boolean) {
    localStorage.setItem(
      "AppPageService.sidenavOpened",
      value ? "true" : "false"
    );
    this.sidenavOpenedSubject.next(value);
  }

  private sidenavOpenedSubject = new Subject<boolean>();
  get sidenavOpenedChange(): Observable<boolean> {
    return this.sidenavOpenedSubject;
  }

  onContentScrolled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );
  obsContentScrolled: Observable<boolean> =
    this.onContentScrolled.asObservable();

  onAppBarExpanded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );
  obsAppBarExpanded: Observable<boolean> = this.onAppBarExpanded.asObservable();

  private _advisorName: string;
  private _advisorNameSubject = new Subject<string>();

  get advisorName(): string {
    return this._advisorName;
  }

  hasAnyHistory(targetPath?: string): boolean {
    const navigationHistory = this.navigationHistory;
    if (navigationHistory?.length) {
      return navigationHistory?.some((url) =>
        this.hasEligiblePreviousUrl(url, targetPath)
      );
    }
    return false;
  }

  private hasEligiblePreviousUrl(url: string, targetPath?: string) {
    return (
      !this.isForbidden(url) &&
      !(window.location.pathname == url.split("?")[0]) &&
      (!targetPath || url.split("?")[0] === targetPath)
    );
  }

  set advisorName(value: string) {
    this._advisorName = value;
    this._advisorNameSubject.next(value);
  }

  get advisorNameChanged(): Observable<string> {
    return this._advisorNameSubject;
  }

  private _phoneNumber: string;
  private _phoneNumberSubject = new Subject<string>();

  get phoneNumber(): string {
    return this._phoneNumber;
  }

  set phoneNumber(value: string) {
    this._phoneNumber = value;
    this._phoneNumberSubject.next(value);
  }

  get phoneNumberChanged(): Observable<string> {
    return this._phoneNumberSubject;
  }

  public isBackAvailable(): boolean {
    return !!(window.location.search
      ? new URLSearchParams(window.location.search)?.get("backTo")
      : undefined);
  }

  public backToIfPossible(omitHistory = false): boolean {
    const redirectTo = window.location.search
      ? new URLSearchParams(window.location.search)?.get("backTo")
      : undefined;

    if (!redirectTo) {
      return false;
    }

    if (omitHistory || !this.hasAnyHistory()) {
      this.navigationHistory = [];
      window.location.href = redirectTo;
      return true;
    }

    return false;
  }

  back(failoverRedirectLink: string = "/", targetPath?: string) {
    const result = this.backToIfPossible();
    if (result) {
      return;
    }

    if (!this.hasAnyHistory(targetPath)) {
      this.navigationHistory = [];
      this.router.navigate([failoverRedirectLink]);
    }

    const history = this.navigationHistory;
    let url = history.pop();
    while (url) {
      if (this.hasEligiblePreviousUrl(url, targetPath)) {
        this.navigationHistory = history;
        this.router.navigateByUrl(url);
        return;
      }
      url = history.pop();
    }
  }

  private isForbidden(url: string): boolean {
    return ["/callback", "/bank-link-reminder", "/mfa"].some(
      (forbiddenAddress) => {
        return url.startsWith(forbiddenAddress);
      }
    );
  }

  private get navigationHistory(): string[] {
    return JSON.parse(sessionStorage.getItem("navigationHistory"));
  }

  private set navigationHistory(history: string[]) {
    sessionStorage.setItem("navigationHistory", JSON.stringify(history));
  }

  private addNavigationHistory(url: string) {
    let navigationHistory = this.navigationHistory;
    if (!navigationHistory) {
      navigationHistory = [];
    }

    navigationHistory.push(url);

    if (navigationHistory.length > 10) {
      navigationHistory.shift();
    }

    this.navigationHistory = navigationHistory;
  }
}
