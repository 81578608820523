import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { OAuthService } from "./oauth.service";
import { take, tap } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { ErrorsService } from "./../../errors/errors.service";

@Injectable({
  providedIn: "root",
})
export class OAuthInterceptor implements HttpInterceptor {
  constructor(
    private oauthService: OAuthService,
    private router: Router,
    private route: ActivatedRoute,
    private errorsService: ErrorsService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let targetRequest = req;

    if (this.oauthService.authenticated) {
      targetRequest = req.clone({
        headers: req.headers.set(
          "Authorization",
          `Bearer ${this.oauthService.accessToken}`
        ),
      });
    }

    return next
      .handle(targetRequest)
      .pipe(tap(() => {}, this.interceptError.bind(this)));
  }

  interceptError(response: HttpErrorResponse | any) {
    if (response instanceof HttpErrorResponse) {
      this.interceptHttpErrorResponse(response);
    } else {
      throw new Error(response);
    }
  }

  interceptHttpErrorResponse(response: HttpErrorResponse) {
    console.log("error from interceptor: " + response.status);
    if (response.status !== 401) {
      return;
    }

    // TODO[403] error case, when user dont have access to some resource, should be refactor and changed from 401 -> 403 with error_code, will be fixed RYNO-9160
    if (this.oauthService.authenticated) {
      this.errorsService.showForbiddenErrorPage();
      return;
    }
    this.route.queryParams.pipe(take(1)).subscribe((res) => {
      console.log("navigateTo: ['sign-in']");
      sessionStorage.setItem("navigation.history", window.location.pathname);
      this.router.navigate(["sign-in"], { queryParams: res });
    });
  }
}
